import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {TranslateService} from '@ngx-translate/core';
import {RouterService} from '@platform/services/router.service';
import {ProductService} from '@app/platform/services/product.service';
import {ProductType} from '@products/product-type.enum';
import {QuickPredictService} from '@products//quick-predict/quick-predict.service';
import {QuickScreenService} from '@products/quick-screen/quick-screen.service';
import {QuickQuestionService} from '@products/quick-question/quick-question.service';
import {ProductServiceFactory} from '@platform/services/product-factory.service';
import {RestageService} from '@products/restage/restage.service';
import {ForecastingService} from '@platform/services/forecasting.service';
import {PriceAdvisorService} from '@products/price-advisor/price-advisor.service';
import {InnovationScreenerService} from '@products/innovation-screener/innovation-screener.service';
import {NielsenOtherService} from '@products/nielsen-other/nielsen-other.service';

export class ProductServiceFactoryImpl extends ProductServiceFactory {

    constructor(private routerService: RouterService,
                private translate: TranslateService,
                private deliverableInfoService: DeliverableInfoService,
                private forecastingService: ForecastingService) {
        super();
    }

    public getService(): ProductService {
        let productType = this.routerService.getPathParam('productType');
        let productService: ProductService = null;
        if (!productType) {
            const url = this.routerService.getCurrentURL();
            productType = url.substr(1, url.indexOf('/', 1) - 1);
        }
        switch (productType) {
            case ProductType.QUICK_PREDICT.type:
                productService = new QuickPredictService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
            case ProductType.QUICK_SCREEN.type:
                productService = new QuickScreenService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
            case ProductType.QUICK_QUESTION.type:
                productService = new QuickQuestionService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
            case ProductType.RESTAGE.type:
                productService = new RestageService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
            case ProductType.PRICE_ADVISOR.type:
                productService = new PriceAdvisorService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
            case ProductType.INNOVATION_SCREENER.type:
                productService = new InnovationScreenerService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
            case ProductType.NIELSEN_OTHER.type:
                productService = new NielsenOtherService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
            case ProductType.DESIGN_DIAGNOSTICS.type:
                productService = new NielsenOtherService(this.translate, this.deliverableInfoService, this.forecastingService);
                break;
        }
        return productService;
    }

}
