import { Component, OnDestroy, OnInit } from '@angular/core';

import {combineLatest, Observable, Subscription} from 'rxjs';

import { ApplicationBarModuleKey } from '@basesstudio/ngx-components';
import { AppConfigService } from '@app/core/config/app-config.service';
import { UserInfo } from '@platform/models/user-info.model';
import { IntegrationService } from '@platform/services/integration.service';
import { ReportService } from '@platform/services/report.service';
import { UserService } from '@platform/services/user.service';
import {HeaderService} from "@platform/services/header.service";

@Component({
  selector: 'ns-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.scss']
})
export class HeaderSectionComponent implements OnInit, OnDestroy {

  /**
   * Currently logged-in user.
   *
   * @type {UserInfo}
   * @memberof HeaderSectionComponent
   */
  public userInfo: UserInfo;

  /**
   * Studio API base URL.
   *
   * @type {string}
   * @memberof HeaderSectionComponent
   */
  public studioURL: string;

  /**
   * Project navigation api path for toolbar.
   *
   * @type {string}
   * @memberof HeaderSectionComponent
   */
  public projectNavigationAPI: string;

  /**
   * User navigation url for navbar.
   *
   * @type {string}
   * @memberof HeaderSectionComponent
   */
  public userNavigationServiceURL: string;

  /**
   * Logout url.
   *
   * @type {string}
   * @memberof HeaderSectionComponent
   */
  public logoutURL: string;

  /**
   * Reporting module key.
   *
   * @type {string}
   * @memberof HeaderSectionComponent
   */
  public applicationBarModuleKey: string;

  /**
   * RxJS subscriptions created by the component instance.
   *
   * @private
   * @type {Array<Subscription>}
   * @memberof HeaderSectionComponent
   */
  private subscriptions: Array<Subscription>;

  /**
   * Constructor
   *
   * @param reportService
   * @param appConfigService
   * @param userService
   * @param integrationService
   */
  constructor(
    private reportService: ReportService,
    private appConfigService: AppConfigService,
    private userService: UserService,
    private headerService: HeaderService,
    private integrationService: IntegrationService) {
      this.subscriptions = [];
      this.applicationBarModuleKey = ApplicationBarModuleKey.INTERACTIVE_RESULTS;
  }

  /**
   * Initialize the Header component view.
   *
   * @memberof HeaderSectionComponent
   */
  ngOnInit(): void {
    const report$ = this.reportService.get();
    const userInfo$ = this.userService.getUser();
    this.userNavigationServiceURL = this.integrationService.getUserNavigationURL();
    this.studioURL = this.appConfigService.config.studio.url;
    this.logoutURL = this.integrationService.getLogoutURL();
    const subscription = combineLatest(
      [report$, userInfo$]
    ).pipe(
    ).subscribe(result => {
      const projectId = result[0].projectId;
      this.userInfo = result[1];
      this.setNavigationConfigURL(projectId);
    });
    this.subscriptions.push(subscription);
  }

  get showBrandBar(): Observable<boolean> {
    return this.headerService.showBrandBar$;
  }

  get showApplicationBar(): Observable<boolean> {
    return this.headerService.showApplicationBar$;
  }

  /**
   * Sets project navigation API.
   *
   * @param projectId Studio project ID.
   */
  setNavigationConfigURL(projectId: number): void {
    this.projectNavigationAPI = this.integrationService.getProjectNavigationAPI(projectId);
  }

  /**
   * Cleanup hook.
   *
   * @member HeaderSectionComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}

