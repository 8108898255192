<div class="filter-wrapper">
  <div ngClass="['menu-content']">
    <button class="dropdown-facet-button" mat-button [matMenuTriggerFor]="menu">
      <span>{{filterLabel}}</span>
    </button>
    <mat-menu #menu="matMenu">
      <section class="dropdown-filter-menu">
        <ul class="dropdown-menu-list">
          <div>
            <h3 class="menu-title">Decimals</h3>
            <mat-radio-group [ngModel]="getSelectedDecimalPlace()" (change)="changeDecimalPlace($event)">
              <div class="menu-list-content">
                <mat-radio-button color="primary" class="list-item" [value]="filterNames.noDecimals">
                  <li class="list-item">{{ 'shared.deliverables.survey-question.filter.label.no.decimals' | translate }}</li>
                </mat-radio-button>
                <mat-radio-button color="primary" class="list-item" [value]="filterNames.oneDecimalPlace">
                  <li class="list-item">{{ 'shared.deliverables.survey-question.filter.label.one.decimal' | translate }}</li>
                </mat-radio-button>
                <mat-radio-button color="primary" class="list-item" [value]="filterNames.twoDecimalPlace">
                  <li class="list-item">{{ 'shared.deliverables.survey-question.filter.label.two.decimal' | translate }}</li>
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </ul>
      </section>
    </mat-menu>
  </div>
</div>
