<div class="carousel-btn-container" >
  <button class="carousel-btn icon-btn left-carousel-btn" mat-button id="left-carousel-btn" role="button" rel="tooltip" matTooltipPosition="above"
          matTooltip=" {{'platform.carousel.table.previous.arrow' | translate: {value: tooltipDescription} }}"
          (click)="previousPage($event)" *ngIf="isPreviousEnabled">
    <span class="sif sif-chevron-w"></span>
  </button>
  <button class="carousel-btn icon-btn right-carousel-btn" mat-button id="right-carousel-btn" role="button" rel="tooltip" matTooltipPosition="above"
          matTooltip=" {{'platform.carousel.table.next.arrow' | translate: {value: tooltipDescription} }}"
          (click)="nextPage($event)" *ngIf="isNextEnabled">
    <span class="sif sif-chevron-e"></span>
  </button>
</div>
