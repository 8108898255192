import {Subgroup} from '@platform/models/subgroup.model';
import {FilterService} from '@platform/services/filter.service';
import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {switchMap} from 'rxjs/operators';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {ImagePath, PriceMentionsDeliverableView} from '../models/price-mentions.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {FilterItem, PriceMentionsFilter} from '../models/filter.model';
import {defaultPriceMentionsFilter} from '../models/default-price-mentions-filter';
import {AppConfigService} from '@app/core/config/app-config.service';
import {Report} from '@platform/models/report.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {ReachAnalysisDeliverableView} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';

/**
 * `PriceMentionsService` has all data operations
 * for fetching the price mentions filter data
 *
 * @export
 * @class PriceMentionsService
 */

@Injectable({
    providedIn: 'root'
})
export class PriceMentionsService {

    /**
     * Creates an instance of PriceMentionsService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @param productDeliverableViewService
     * @param {AppConfigService} appConfigService
     * @memberof PriceMentionsService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private appConfigService: AppConfigService) {
    }

    /**
     * Returns an observable of `PriceMentionsDeliverableView` data.
     *
     * @returns {Observable<PriceMentionsDeliverableView>}
     * @memberof PriceMentionsService
     */
    public getPriceMentions(): Observable<PriceMentionsDeliverableView> {
        const deliverableType = DeliverableType.PRICE_MENTIONS.type;
        const filter$ = this.getPriceMentionsFilter();
        const priceMentions$: Observable<PriceMentionsDeliverableView> = filter$.pipe(
            switchMap(filter => {
                return this.deliverableViewService.filter<PriceMentionsDeliverableView, PriceMentionsFilter>(
                    filter.deliverableViewType,
                    deliverableType,
                    this.filter.bind(this)
                );
            })
        );
        return priceMentions$;
    }

    /**
     * Filter for filtering the price mentions data and returns
     * filtered `PriceMentionsDeliverableView` data.
     *
     * @private
     * @param {PriceMentionsFilter} filter
     * @param {PriceMentionsDeliverableView} data
     * @returns {PriceMentionsDeliverableView}
     * @memberof PriceMentionsService
     */
    private filter(filter: PriceMentionsFilter, data: PriceMentionsDeliverableView): PriceMentionsDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const filteredData = data.mentionPriceFiles.filter(file => selectedConcepts.includes(file.conceptId) && selectedSubgroups.includes(file.subgroupId));
        const filteredView = Object.assign({}, data, {mentionPriceFiles: filteredData});
        return filteredView;
    }

    /**
     * Returns observable of Price Mentions filter data.
     *
     * @returns {Observable<PriceMentionsFilter>}
     * @memberof PriceMentionsService
     */
    public getPriceMentionsFilter(): Observable<PriceMentionsFilter> {
        const deliverableType = DeliverableType.PRICE_MENTIONS.type;
        const filter$ = this.filterService.get<PriceMentionsFilter>(deliverableType);
        return filter$;
    }

    /**
     * Returns an observable of `PriceMentionsDeliverableView` data.
     *
     * @returns {Observable<PriceMentionsDeliverableView>}
     */
    public getDefaultPriceMentions(deliverableViews: Array<DeliverableView>): Observable<PriceMentionsDeliverableView> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                const viewId = deliverableViews[0].id;
                return this.deliverableViewService.fetch<PriceMentionsDeliverableView>(
                    viewId,
                    report.id
                );
            })
        );
    }

    /**
     * Returns an observable that resolves to default filter for the Price Mentions deliverable.
     */
    public getDefaultFilterData(deliverableViews ?: Array<DeliverableView> , concept ?: Concept ): Observable<PriceMentionsFilter> {
        const defaultFilter: PriceMentionsFilter = Object.assign({}, defaultPriceMentionsFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.PRICE_MENTIONS.type);
        const subgroups$ = this.subgroupService.getReportSubgroups();
        const deliverableView$ = this.getDefaultPriceMentions(deliverableViews);
        return zip(report$, concepts$, subgroups$, deliverableView$).pipe(switchMap(result => {
            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            defaultFilter.concepts = this.getConceptFilters(concepts as Array<Concept>, result[2] as Array<Subgroup>, result[3]);
            defaultFilter.subgroups = this.getSubgroupFilters(result[2] as Array<Subgroup>, defaultFilter.concepts, result[3]);
            return of(defaultFilter);
        }));
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @param subgroups
     * @param deliverableView
     * @returns {Array<FilterItem>}
     * @memberof PriceMentionsService
     */
    private getConceptFilters(concepts: Array<Concept>, subgroups: Array<Subgroup>, deliverableView: PriceMentionsDeliverableView): Array<FilterItem> {
        const conceptFilterItems = [];
        const conceptSubgroupsMap = new Map();
        deliverableView.metaInfo.concepts.forEach(conceptId => {
            conceptSubgroupsMap.set(conceptId, []);
        });
        deliverableView.mentionPriceFiles.forEach(file => {
            const conceptId = file.conceptId;
            const subgroupId = file.subgroupId;
            if (conceptSubgroupsMap.has(conceptId)) {
                if (!conceptSubgroupsMap.get(conceptId).includes(subgroupId)) {
                    conceptSubgroupsMap.get(conceptId).push(subgroupId);
                }
            }
        });

        concepts.forEach(concept => {
            if (conceptSubgroupsMap.has(concept.exerciseConceptId)) {
                const relevantSubgroupIds = conceptSubgroupsMap.get(concept.exerciseConceptId);
                const filteredSubgroup = subgroups
                    .filter(item => relevantSubgroupIds.includes(item.segmentId))
                    .map(subgroup => ({
                        name: subgroup.name,
                        id: subgroup.segmentId,
                        isSelected: false,
                        position: subgroup.position
                    }));
                if (filteredSubgroup.length > 0) {
                    filteredSubgroup[0].isSelected = true;
                    conceptFilterItems.push({
                        name: concept.name,
                        id: concept.exerciseConceptId,
                        isSelected: false,
                        position: concept.position,
                        subgroups: filteredSubgroup
                    });
                }
            }
        });
        conceptFilterItems[0].isSelected = true;
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @param concepts
     * @param deliverableView
     * @returns {Array<FilterItem>}
     * @memberof PriceMentionsService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>, concepts: any, deliverableView: PriceMentionsDeliverableView): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        const selectedSubgroup = concepts.find(concept => concept.isSelected).subgroups.find(subgroup => subgroup.isSelected);
        const filteredSubgroups = subgroups.filter(s => deliverableView.metaInfo.subgroups.includes(s.segmentId));
        for (const item of filteredSubgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position,
            });
        }
        subgroupFilterItems.forEach(item => {
            item.isSelected = item.id === selectedSubgroup.id;
        });
        return subgroupFilterItems;
    }

    /**
     * Returns image file path.
     *
     * @returns {ImagePath}
     */
    public getImagePath(priceMentionsDeliverableView: PriceMentionsDeliverableView): ImagePath {
        if (priceMentionsDeliverableView.mentionPriceFiles.length === 0) {
            return;
        }
        const imagePath = {
            fileName: priceMentionsDeliverableView.mentionPriceFiles[0].name,
            name: `${this.appConfigService.config.reporting.url}/reports/${priceMentionsDeliverableView.reportId}/inputs/${priceMentionsDeliverableView.mentionPriceFiles[0].name}?imageFiles=true`
        };
        return imagePath;
    }
}
