import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Report } from '@platform/models/report.model';
import { DeliverableViewService } from '@platform/services/deliverable-view.service';
import { FilterService } from '@platform/services/filter.service';
import { ReportService } from '@platform/services/report.service';
import { DeliverableType } from '@products/restage/deliverable-type.enum';
import { DataType, DropdownData, DropdownItem } from '@products/shared/dropdown/dropdown.data.model';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { DeliverableViewType } from '../models/deliverable-view-type-enum';
import { PerformanceFilter } from '../models/filter.model';
import { PerformanceDeliverableView } from '../models/performance.model';
import { PerformanceService } from '../services/performance.service';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';

/**
 * `FilterComponent` creates the performance filter options like concepts,
 * country, subgroups.
 *
 * @example
 *   <ns-performance-filter></ns-performance-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */

@Component({
  selector: 'ns-performance-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  /**
   * Performance filter object.
   *
   * @property
   * @type {PerformanceFilter}
   * @memberof FilterComponent
   */
   public filter: PerformanceFilter;

  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
   public conceptsListData: DropdownData<string>;

  /**
   * List of countries on the report.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
   public countryListData: DropdownData<string>;

   /**
   * List of subgroup dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public subgroupListData: DropdownData<string>;

   /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberof FilterComponent
   */
   private subscriptions: Array<Subscription>;
  /**
   * Mixpanel label for the deliverable
   *
   * @property
   * @private
   * @type {string}
   * @memberof FilterComponent
   */
  private deliverableLabel: string;

  constructor(
    private performanceService: PerformanceService,
    private filterService: FilterService,
    private reportService: ReportService,
    private translate: TranslateService,
    private deliverableViewService: DeliverableViewService,
    private mixpanelService: MixpanelService
    ) {
      this.subscriptions = [];
     }

  ngOnInit(): void {
    this.deliverableLabel = MixpanelLabel.performance;
    const deliverableType = DeliverableType.PERFORMANCE.type;
    const filter$ = this.performanceService.getPerformanceFilter();
    const report$ = this.reportService.get();
    const deliverableView$: Observable<PerformanceDeliverableView> = this.deliverableViewService.get(DeliverableViewType.CONCEPT, deliverableType);
    const subscription = combineLatest([filter$, report$, deliverableView$]).subscribe(([filter, report, deliverableView]) => {
      this.filter = { ...filter, concepts: this.getRestageConcepts(filter, deliverableView)};
      this.setFilters(this.filter, report);
    });
    this.performanceService.loadDefaultFilter();
    this.subscriptions.push(subscription);
  }

  /**
   * Event listener for concept selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} concepts
   * @memberof FilterComponent
   */
   selectConcept(allConcepts: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: allConcepts.find(selectedConcept => selectedConcept.value === concept.name).selected
        };
      })
    });
  }

  /**
   * Event listener for subgroup selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} subgroups
   * @memberof FilterComponent
   */
   selectSubgroup(subgroups: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
        };
      })
    });
  }

  /**
   * Sets the filter options for concepts, countries, subgroups.
   *
   * @private
   * @param {PerformanceFilter} filter
   * @param {Report} report
   * @memberof FilterComponent
   */
   private setFilters(filter: PerformanceFilter, report: Report): void {
    this.conceptsListData = this.getConceptFilterOptions(filter);
    this.subgroupListData = this.getSubGroupFilterOptions(filter);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
   * Returns restage related concepts filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
   private getConceptFilterOptions(filter: PerformanceFilter): DropdownData<string> {
    const  filteredConcept = filter.concepts.sort((a, b) => a.position - b.position);
    const dropdownData: DropdownItem<string>[] = filteredConcept.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('restage.deliverable.performance.filter.concepts.label'),
      dataType: DataType.CHECKBOX,
      contentTitle: this.translate.instant('restage.deliverable.performance.filter.concepts.itemsTitle'),
      groupSelect: this.translate.instant('restage.deliverable.performance.filter.concepts.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns sub groups filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
   private getSubGroupFilterOptions(filter: PerformanceFilter): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('restage.deliverable.performance.filter.subgroups.label'),
      dataType: DataType.CHECKBOX,
      contentTitle: this.translate.instant('restage.deliverable.performance.filter.subgroups.itemsTitle'),
      groupSelect: this.translate.instant('restage.deliverable.performance.filter.subgroups.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }



  /**
   * Returns the country filter options.
     *
   * @private
   * @param {string} country
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
   private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('restage.deliverable.performance.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('restage.deliverable.performance.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }

  /**
   * Returns restage related concepts filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @param {PerformanceDeliverableView} data
   * @memberof FilterComponent
   */
   getRestageConcepts(filter: PerformanceFilter, data: PerformanceDeliverableView) {
    return data.subgroups[0].conceptData.reduce((acc: any, ele) => {
      const filteredConcept = filter.concepts.filter(item => item.id === ele.conceptId);
      return [...acc, ...filteredConcept];
    }, []);
  }

  /**
   * Emits event of the selected filter menu item.
   */
   selectedEvent(event): void {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    console.log(event);
  }


  /**
   * Cleanup the component on removing from the UI.
   *
   * @memberof FilterComponent
   */
   ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
