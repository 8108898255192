<div class="save-views-wrapper">
    <div class="dropdown-wrapper" #saveViewSection>
        <ns-dropdown-menu rel="tooltip" matTooltipPosition="above" matTooltip=" {{'save.view.label' | translate: { viewName: selectedView ? selectedView.name : 'Default View' } }}"
                          [isDisable]="!userViews.length" [label]="selectedView ? selectedView.name : 'shared.deliverable.header.save.view.default.name' | translate" class="save-dropdown">
            <ul *ngIf="userViews" [style.min-width.px]="saveViewSection.offsetWidth">
                <li (click)="selectView(view)" *ngFor="let view of userViews">{{view.name}}</li>
            </ul>
        </ns-dropdown-menu>
        <ns-kebab-menu class="kebab-dropdown" [isDisable]="!userViews.length">
          <ul *ngIf="userViews.length && selectedView">
              <ng-container *ngIf="!isDefaultView()">
                <li (click)="renameView()">{{ 'shared.deliverable.header.save.view.label.rename' | translate }}</li>
                <li (click)="deleteView()">{{ 'shared.deliverable.header.save.view.label.delete' | translate }}</li>
              </ng-container>
              <li>
                <div>
                  <mat-checkbox (click)="setShowOnLoad()" [disabled]="isShowOnLoadSelected()" color="primary" [checked]="isShowOnLoadSelected()">{{ 'shared.deliverable.header.save.view.label.show.on.load' | translate }}
                  </mat-checkbox>
                </div>
              </li>
          </ul>
        </ns-kebab-menu>

    </div>
    <span (click)="openDialog()" matTooltip="Save as New View" matTooltipPosition="above" class="save-icon sif sif-save"></span>
</div>

<ng-template #dialog>
    <div class="deliverables-header-save-modal">
        <h2 mat-dialog-title>{{ 'shared.deliverable.header.save.view.new.title' | translate }}</h2>
        <mat-dialog-content class="save-modal-content">
            <label for="save">{{ 'shared.deliverable.header.save.view.new.name' | translate }}</label>
            <input id="save" [(ngModel)]="currentViewName" placeholder="{{ 'shared.deliverable.header.save.view.new.placeholder' | translate }}" type="text"/>
        </mat-dialog-content>
        <mat-dialog-actions  class="save-modal-actions" align="end">
            <button class="cancel-button" mat-button (click)="cancelView()" mat-dialog-close>{{ 'shared.deliverable.header.save.view.new.cancel' | translate }}</button>
            <button class="save-button" mat-button (click)="saveView(currentViewName)">{{ 'shared.deliverable.header.save.view.new.save' | translate }}</button>
        </mat-dialog-actions>
    </div>
</ng-template>
