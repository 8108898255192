import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Concept} from '@platform/models/concept.model';
import {MeasureDescription, Measures} from '../models/activationProfile.model';
import {TreeNodeData} from '../models/tree-node-data.interface';
import {BroadPotentialService} from '@app/deliverables/activation-profile/services/broad-potential.service';
import {TargetPotentialService} from '@app/deliverables/activation-profile/services/target-potential.service';
import {BrandIncrementalService} from '@app/deliverables/activation-profile/services/brand-incremental.service';
import {IndustryDistinctionService} from '@app/deliverables/activation-profile/services/industry-distinction.service';
import {CategoryDistinctionService} from '@app/deliverables/activation-profile/services/category-distinction.service';
import {EnduranceService} from '@app/deliverables/activation-profile/services/endurance.service';
import {ExecutionalPotentialService} from '@app/deliverables/activation-profile/services/executional-potential.service';
import {RestaurantIncrementalService} from '@app/deliverables/activation-profile/services/restaurant-incremental.service';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import {Benchmark} from '@platform/models/benchmark.model';

@Component({
  selector: 'activation-profile-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() concept: Concept;
  @Input() benchmarks: Benchmark[];
  @Input() headerList: Array<string>;
  @Input() basesLabel: string;
  @Input() measureDescription: MeasureDescription;
  @Output() nodeToggled: EventEmitter<any> = new EventEmitter(null);
  @Input() locale: Array<string>;

  broadPotential: TreeNodeData[];
  targetPotential: TreeNodeData[];
  brandIncrementality: TreeNodeData[];
  restaurantIncrementality: TreeNodeData[];
  categoryDistinction: TreeNodeData[];
  industryDistinction: TreeNodeData[];
  endurance: TreeNodeData[];
  executionalPotential: TreeNodeData[];
  activationProfile: String;
  activationProfileTooltip: String;
  yearOneVolumne: String;
  yearOneVolumneTooltip: String;
  launchVolumne: String;
  launchVolumneTooltip: String;

  constructor(
    private translate: TranslateService,
    private activationProfileService: ActivationProfileService,
    private broadPotentialService: BroadPotentialService,
    private targetPotentialService: TargetPotentialService,
    private brandIncrementalService: BrandIncrementalService,
    private industryDistinctionService: IndustryDistinctionService,
    private categoryDistinctionService: CategoryDistinctionService,
    private enduranceService: EnduranceService,
    private executionalPotentialService: ExecutionalPotentialService,
    private restaurantIncrementalService: RestaurantIncrementalService
  ) {
  }

  ngOnInit(): void {
    const headerList = this.headerList;
    const measureDescription = this.measureDescription;
    if (!measureDescription) {
      return;
    } else {
      this.setActivationProfile(headerList, measureDescription);
      this.broadPotential = this.broadPotentialService.setBroadPotential(headerList, measureDescription, this.broadPotential, this.basesLabel);
      this.targetPotential = this.targetPotentialService.setTargetPotential(headerList, measureDescription, this.targetPotential);
      this.brandIncrementality = this.brandIncrementalService.setBrandIncrementality(headerList, measureDescription, this.brandIncrementality);
      this.industryDistinction = this.industryDistinctionService.setIndustryDistinction(headerList, measureDescription, this.industryDistinction);
      this.restaurantIncrementality = this.restaurantIncrementalService.setRestaurantIncrementality(headerList, measureDescription, this.restaurantIncrementality);
      this.categoryDistinction = this.categoryDistinctionService.setCategoryDistinction(headerList, measureDescription, this.categoryDistinction);
      this.endurance = this.enduranceService.setEndurance(headerList, measureDescription, this.endurance);
      this.setYearOneVolume(headerList, measureDescription);
      this.setLaunchVolume(headerList, measureDescription);
      this.executionalPotential = this.executionalPotentialService.setExecutionalPotential(headerList, measureDescription, this.executionalPotential);    }
  }

  /**
   * set activation profile tree node.
   *
   * @returns {TreeNodeData}
   * @memberof ItemComponent
   */
  private setActivationProfile(headerList: string | string[], measureDescription: MeasureDescription): void {
    if (headerList.indexOf('activationProfile') > -1) {
      this.activationProfile = measureDescription.activationProfile;
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.activationProfileTooltip = this.activationProfileService.getTooltip(basesLabel + measureDescription.activationProfile);
    }
  }

  /**
   * set year one volumne tree node.
   *
   * @returns {TreeNodeData}
   * @memberof ItemComponent
   */
  private setYearOneVolume(headerList: string | string[], measureDescription: MeasureDescription): void {
    if (headerList.indexOf('yearOneVolume') > -1) {
      this.yearOneVolumne = measureDescription.yearOneVolume;
      this.yearOneVolumneTooltip = this.activationProfileService.getTooltip('year.' + measureDescription.yearOneVolume);
    }
  }

  /**
   * set launch volumne tree node.
   *
   * @returns {TreeNodeData}
   * @memberof ItemComponent
   */
  private setLaunchVolume(headerList: string | string[], measureDescription: MeasureDescription): void {
    if (headerList.indexOf('launchVolume') > -1) {
      this.launchVolumne = measureDescription.launchVolume;
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.launchVolumneTooltip = this.activationProfileService.getTooltip(basesLabel + 'launchVolume.' + measureDescription.launchVolume);
    }
  }

  toggleNode(concept) {
    this.nodeToggled.emit(concept);
  }
}
