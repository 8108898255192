/**
 * Filter name keys and values.
 *
 * @export
 * @property
*/
export const filterNames = {
  noDecimals: 'no-decimals',
  oneDecimalPlace: 'one-decimal-place',
  twoDecimalPlace: 'two-decimal-place',
  statTesting: 'stat-testing',
  aggregate: 'aggregate',
  mention: 'mention'
};
