import { createAction, props } from '@ngrx/store';
import { Insight } from '@platform/models/insight.model';

/**
 * Action for adding a Insight.
 *
 * @export
 */
export const addInsight = createAction(
  '[Insight] Add Insight',
  props<{ insight: Insight }>()
);

/**
 * Action for an getting an array of Insights.
 *
 * @export
 */
export const addInsights = createAction(
  '[Insight] Add Insights',
  props<{ insights: Insight[] }>()
);

/**
 * Action for updating an Insight.
 *
 * @export
 */
export const updateInsight = createAction(
  '[Insight] Update Insight',
  props<{ insight: Insight }>()
);

/**
 * Action for updating Insights.
 *
 * @export
 */
export const updateInsights = createAction(
  '[Insight] Update Insights',
  props<{ insights: Insight[] }>()
);

/**
 * Action for Deleting an Insight.
 *
 * @export
 */
export const deleteInsight = createAction(
  '[Insight] Delete Insight',
  props<{ id: string }>()
);

/**
 * Action for Deleting an Insight.
 *
 * @export
 */
export const deleteInsights = createAction(
  '[Insight] Delete Insights',
  props<{ id: string[] }>()
);

/**
 * Action for Resetting Insight.
 *
 * @export
 */
 export const resetInsights = createAction(
  '[Insight] Reset Insights'
);
