import {Injectable} from '@angular/core';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {Observable, of} from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import {ActivationProfileDeliverableView} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';
import { TranslateService } from '@ngx-translate/core';
import {FilterService} from '@platform/services/filter.service';
import {ActivationProfileFilter} from '@app/deliverables/activation-profile/models/filter.model';
import {defaultActivationProfileFilter} from '@app/deliverables/activation-profile/models/default-attributes-filter';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {ActivationProfileMetaInfo} from '@app/deliverables/activation-profile/models/view-meta-info.model';
import * as numeral from 'numeral';
import {AttributesFilter} from '@app/deliverables/attributes/models/filter.model';
import {AttributesDeliverableView} from '@app/deliverables/attributes/models/attributes.model';

@Injectable({
  providedIn: 'root'
})
export class ActivationProfileService {

  activationProfile: TreeNodeData[];
  broadPotential: TreeNodeData[];
  targetPotential: TreeNodeData[];
  brandIncrementality: TreeNodeData[];
  restaurantIncrementality: TreeNodeData[];
  categoryDistinction: TreeNodeData[];
  industryDistinction: TreeNodeData[];
  endurance: TreeNodeData[];
  yearOneVolume: TreeNodeData[];
  launchVolume: TreeNodeData[];
  executionalPotential: TreeNodeData[];
  basesLabel: string;
  treeControl = new NestedTreeControl<TreeNodeData>(node => node.children);


   readonly concept: TreeNodeData[] = [{
    name: 'CONCEPT',
    type: 'Parent',
    key: 'concept',
    class: 'concept',
    readOnly: false,
    level: 1,
    children: [{
      name: ''
    }, {
      name: ''
    }, {
      name: ''
    }]
  }];

  constructor(
    private translate: TranslateService,
    private deliverableViewService: DeliverableViewService,
    private filterService: FilterService,
    private viewMetaInfoService: ViewMetaInfoService,
  ) {
  }

  /**
   * Returns an observable of `ActivationProfileDeliverableView` data.
   *
   * @returns {Observable<ActivationProfileDeliverableView>}
   * @memberof ActivationProfileService
   */
  public getActivationProfileService(): Observable<ActivationProfileDeliverableView> {
    const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
    return this.deliverableViewService.get<ActivationProfileDeliverableView>(
      'concept',
      deliverableType
    );
  }

  /**
   * Returns observable of attributes filter data.
   *
   * @returns {Observable<AttributesFilter>}
   * @memberof AttributesService
   */
  public getActivationProfileFilter(): Observable<ActivationProfileFilter> {
    const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
    return this.filterService.get<ActivationProfileFilter>(deliverableType);
  }


  /**
   * Loads default attributes filter data.
   *
   * @memberof AttributesService
   */
  public loadDefaultFilter(): void {
    const defaultFilter: ActivationProfileFilter = Object.assign({}, defaultActivationProfileFilter);
    this.filterService.update(defaultFilter);
  }

  isViewMetaInfoExpanded(key: string): Observable<boolean> {
    let expandedValue: Observable<boolean>;
    const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
    this.viewMetaInfoService.get<ActivationProfileMetaInfo>(deliverableType)
    .subscribe( viewMetaInfo => {
        if (Object.keys(viewMetaInfo).length > 1 && key) {
          expandedValue = of(viewMetaInfo[key]);
        } else {
          expandedValue = of(false);
        }
      }
    );
    return expandedValue;
  }

  /**
   * build tree node measures
   * *
   * @returns {TreeNodeData[]}
   * @memberof ActivationProfileService
   * */
  buildMeasures(name: string|number, type: string, key: string, level: number, children: TreeNodeData[], classValue: string, tooltip: string, image: boolean, bar:number|string = ''): TreeNodeData[] {
    return [{
      name: name,
      type: type,
      key: key,
      level: level,
      children: children,
      class: classValue,
      tooltip: tooltip,
      image: image,
      bar: bar
    }];
  }

  buildAPMeasures(name: any, type: any, key: any, level: any, children: any, classValue: any, tooltip: any): TreeNodeData[] {
    return [{
      name: name,
      type: type,
      key: key,
      level: level,
      children: children,
      class: classValue,
      tooltip: tooltip
    }];
  }

  /**
   * get tooltip for each tree node
   * @returns {string>}
   * @memberof ActivationProfileService
   * */
   getTooltip(text: string): string {
    return this.translate.instant('quick-predict.deliverable.activation.profile.' + text.replace(/\s/g, '').toLowerCase());
  }

  /**
   * Returns formatted value.
   *
   * @private
   * @returns {(number | string)}
   * @memberof ActivationProfileService
   */
  getFormattedValue(value: number | string): number | string {
    return value ? (numeral(value).format("0") + '%') : value;
  }

  private getAPTooltip(text: string) {
    return this.translate.instant('quick-predict.deliverable.activation.profile.' + text);
  }

    setActivationProfile(headerList): void {
    if (headerList.indexOf('activationProfile') > -1) {
      this.activationProfile = this.buildAPMeasures('ACTIVATION PROFILE', null, null, null, null, 'activationProfile', null);
    }
  }

  setbroadPotential(headerList: string | string[]): void {
    if (headerList.indexOf('broadPotential') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.broadPotential = this.buildAPMeasures('BROAD POTENTIAL', 'parent', 'broadPotential', 1, [], null, this.getAPTooltip(basesLabel +'braodPotential'));
      if (headerList.indexOf('WPIQuintile') > -1) {
        const trialInterest =  this.buildAPMeasures('Trial Interest', 'Parent', 'trialInterest', 2, [], null, null);
        this.broadPotential[0].children.push(trialInterest[0]);
        const definitely = this.buildAPMeasures('Definitely Would Buy', 'Leaf', 'definitely', 3, null, null, null);
        const probably = this.buildAPMeasures('Probably Would Buy', 'Leaf', 'probably', 3, null, null, null);
        const might = this.buildAPMeasures('Might or Might Not Buy', 'Leaf', 'might', 3, null, null, null);
        const probablyNot = this.buildAPMeasures('Probably Would Not Buy', 'Leaf', 'probablyNot', 3, null, null, null);
        const definitelyNot = this.buildAPMeasures('Definitely Would Not Buy', 'Leaf', 'definitelyNot', 3, null, null, null);
        trialInterest[0].children.push(definitely[0]);
        trialInterest[0].children.push(probably[0]);
        trialInterest[0].children.push(might[0]);
        trialInterest[0].children.push(probablyNot[0]);
        trialInterest[0].children.push(definitelyNot[0]);
      }
      if (headerList.indexOf('needDesireFactorColor') > -1) {
        const needDesire = this.buildAPMeasures('Need/Desire', 'Leaf', 'desire', 2, null, null, null);
        this.broadPotential[0].children.push(needDesire[0]);
      }
      if (headerList.indexOf('desireFactorColor') > -1) {
        const desire = this.buildAPMeasures('Desire', 'Leaf', 'desire', 2, null, null, null);
        this.broadPotential[0].children.push(desire[0]);
      }
      if (headerList.indexOf('acceptableCostsFactorColor') > -1) {
        const costs = this.buildAPMeasures('Acceptable Costs', 'Leaf', 'costs', 2, null, null, null);
        this.broadPotential[0].children.push(costs[0]);
      }
    }
  }

  setTargetPotential(headerList: string | string[]): void {
    if (headerList.indexOf('targetedPotential') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
        this.targetPotential = this.buildAPMeasures('TARGETED POTENTIAL', 'Parent', 'targetPotential', 1, [], null, this.getAPTooltip(basesLabel +'targetedPotential'));
        if (headerList.indexOf('tgName') > -1) {
            const tgName = this.buildAPMeasures('Target Group', 'Leaf', 'group.name', 2, null, null, null);
            this.targetPotential[0].children.push(tgName[0]);
        }
        if (headerList.indexOf('tgWPITertile') > -1) {
          const tgWPITertile = this.buildAPMeasures('Trial Interest', 'Leaf', 'targetTrialInterest', 2, [], null, null);
          this.targetPotential[0].children.push(tgWPITertile[0]);

          //S2-1707 AP: Missed requirement - temporarily suppress TG trial interest dropdown from AP deliverable
          // const definitely = this.buildAPMeasures('Definitely Would Buy', 'Leaf', 'targetDefinitely', 3, null, null, null);
          // const probably = this.buildAPMeasures('Probably Would Buy', 'Leaf', 'targetProbably', 3, null, null, null);
          // const might = this.buildAPMeasures('Might or Might Not Buy', 'Leaf', 'targetMight', 3, null, null, null);
          // const probablyNot = this.buildAPMeasures('Probably Would Not Buy', 'Leaf', 'targetProbablyNot', 3, null, null, null);
          // const definitelyNot = this.buildAPMeasures('Definitely Would Not Buy', 'Leaf', 'targetDefinitelyNot', 3, null, null, null);
          // tgWPITertile[0].children.push(definitely[0]);
          // tgWPITertile[0].children.push(probably[0]);
          // tgWPITertile[0].children.push(probablyNot[0]);
          // tgWPITertile[0].children.push(might[0]);
          // tgWPITertile[0].children.push(definitelyNot[0]);
        }
        if (headerList.indexOf('tgNeedDesireTertile') > -1) {
          const needDesire = this.buildAPMeasures('Need/Desire', 'Leaf', 'targetDesire', 2, null, null, null);
          this.targetPotential[0].children.push(needDesire[0]);
        }
        if (headerList.indexOf('tgDesireTertile') > -1) {
          const desire = this.buildAPMeasures('Desire', 'Leaf', 'targetDesire', 2, null, null, null);
          this.targetPotential[0].children.push(desire[0]);
        }
        if (headerList.indexOf('tgAcceptableCostsTertile')) {
          const costs = this.buildAPMeasures('Acceptable Costs', 'Leaf', 'targetCosts', 2, null, null, null);
          this.targetPotential[0].children.push(costs[0]);
        }
    }
  }

  setBrandIncrementality(headerList): void {
    if (headerList.indexOf('brandIncrementality') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.brandIncrementality = this.buildAPMeasures('BRAND INCREMENTALITY', 'Parent', 'brandIncrementality', 1, [], null, this.getAPTooltip(basesLabel +'brandIncrementality'));
      if (headerList.indexOf('brandIncrementalityCompetitorSourcing') > -1) {
          const brandIncrementalitySourcing = this.buildAPMeasures('Claimed Competitor Sourcing', 'Leaf', 'competitorSourcing', 2, null, null, null);
          this.brandIncrementality[0].children.push(brandIncrementalitySourcing[0]);
      }
      if (headerList.indexOf('brandIncrementalityBrandPremium') > -1) {
        const brandIncrementalityBrandPremium = this.buildAPMeasures('Brand Premium', 'Leaf', 'brandPremium', 2, null, null, null);
        this.brandIncrementality[0].children.push(brandIncrementalityBrandPremium[0]);
      }
    }
  }

  setRestaurantIncrementality(headerList): void {
    if (headerList.indexOf('restaurantIncrementality') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.restaurantIncrementality = this.buildAPMeasures('RESTAURANT INCREMENTALITY', 'Parent', 'restaurantIncrementality', 1, [], null, this.getAPTooltip(basesLabel +'restaurantIncrementality'));
      if (headerList.indexOf('restaurantIncrementalityTripIncrementality') > -1) {
          const restaurantIncrementalityTripIncrementality = this.buildAPMeasures('Trip Incrementality', 'Leaf', 'tripIncrementality', 2, null, null, []);
          this.restaurantIncrementality[0].children.push(restaurantIncrementalityTripIncrementality[0]);
      }
      if (headerList.indexOf('restaurantIncrementalityItemPremium') > -1) {
        const restaurantIncrementalityItemPremium = this.buildAPMeasures('Item Premium', 'Leaf', 'itemPremium', 2, null, null, []);
        this.restaurantIncrementality[0].children.push(restaurantIncrementalityItemPremium[0]);
      }
    }
  }

  setCategoryDistinction(headerList): void {
    if (headerList.indexOf('categoryDistinction') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.categoryDistinction = this.buildAPMeasures('CATEGORY DISTINCTION', 'Parent', 'categoryDistinction', 1, [], null, this.getAPTooltip(basesLabel +'categoryDistinction'));
      if (headerList.indexOf('advantageFactorColor') > -1) {
        const advantageFactorColor = this.buildAPMeasures('Advantage', 'Leaf', 'advantage', 2, null, null, null);
        this.categoryDistinction[0].children.push(advantageFactorColor[0]);
      }
      if (headerList.indexOf('distinctPropositionFactorColor') > -1) {
          const distinctPropositionFactorColor = this.buildAPMeasures('Distinct Proposition', 'Leaf', 'distinctBenefits', 2, null, null, null);
          this.categoryDistinction[0].children.push(distinctPropositionFactorColor[0]);
      }
      if (headerList.indexOf('categoryDistinctionCrossCategoryInteraction') > -1) {
        const categoryDistinctionInteraction = this.buildAPMeasures('Cross Category Interaction', 'Leaf', 'crossCategoryInteraction', 2, null, null, null);
        this.categoryDistinction[0].children.push(categoryDistinctionInteraction[0]);
      }
      if (headerList.indexOf('categoryDistinctionCategoryPremium') > -1) {
        const categoryDistinctionCategoryPremium = this.buildAPMeasures('Category Premium', 'Leaf', 'categoryPremium', 2, null, null, null);
        this.categoryDistinction[0].children.push(categoryDistinctionCategoryPremium[0]);
      }
    }
  }

  setIndustryDistinction(headerList): void {
    if (headerList.indexOf('industryDistinction') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.industryDistinction = this.buildAPMeasures('INDUSTRY DISTINCTION', 'Parent', 'industryDistinction', 1, [], null, this.getAPTooltip(basesLabel +'industryDistinction'));
      if (headerList.indexOf('advantageFactorColor') > -1) {
        const advantageFactorColor = this.buildAPMeasures('Advantage', 'Leaf', 'advantage', 2, null, null, null);
        this.industryDistinction[0].children.push(advantageFactorColor[0]);
      }
      if (headerList.indexOf('distinctPropositionFactorColor') > -1) {
          const distinctPropositionFactorColor = this.buildAPMeasures('Distinct Proposition', 'Leaf', 'distinctBenefits', 2, null, null, null);
          this.industryDistinction[0].children.push(distinctPropositionFactorColor[0]);
      }
    }
  }

  setEndurance(headerList: string | string[]): void {
    if (headerList.indexOf('endurance') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.endurance = this.buildAPMeasures('ENDURANCE', 'Parent', 'endurance', 1, [], 'endurance', this.getAPTooltip(basesLabel +'endurance'));
      if (headerList.indexOf('productWPIQuintile') > -1) {
          const productWPIQuintile = this.buildAPMeasures('Repeat Interest', 'Parent', 'repeatInterest', 2, [], null, null);
          this.endurance[0].children.push(productWPIQuintile[0]);
          const definitely = this.buildAPMeasures('Definitely Would Buy', 'Leaf', 'repeatInterestDefinitely', 3, null, null, null);
          const probably = this.buildAPMeasures('Probably Would Buy', 'Leaf', 'repeatInterestProbably', 3, null, null, null);
          const might = this.buildAPMeasures('Might or Might Not Buy', 'Leaf', 'repeatInterestMight', 3, null, null, null);
          const probablyNot = this.buildAPMeasures('Probably Would Not Buy', 'Leaf', 'repeatInterestProbablyNot', 3, null, null, null);
          const definitelyNot = this.buildAPMeasures('Definitely Would Not Buy', 'Leaf', 'repeatInterestDefinitelyNot', 3, null, null, null);
          productWPIQuintile[0].children.push(definitely[0]);
          productWPIQuintile[0].children.push(probably[0]);
          productWPIQuintile[0].children.push(probablyNot[0]);
          productWPIQuintile[0].children.push(might[0]);
          productWPIQuintile[0].children.push(definitelyNot[0]);
      }
      if (headerList.indexOf('productDeliveryFactorColor') > -1) {
        const productDeliveryFactorColor = this.buildAPMeasures('Product Delivery', 'Leaf', 'productDelivery', 2, null, null, null);
        this.endurance[0].children.push(productDeliveryFactorColor[0]);
      }
      if (headerList.indexOf('productLoyaltyFactorColor') > -1) {
        const productLoyaltyFactorColor = this.buildAPMeasures('Product Loyalty', 'Leaf', 'productLoyalty', 2, null, null, null);
        this.endurance[0].children.push(productLoyaltyFactorColor[0]);
      }
    }
  }

  setYearOneVolume(headerList): void {
    if (headerList.indexOf('yearOneVolume') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.yearOneVolume = this.buildAPMeasures('YEAR 1 VOLUME', 'Parent', null, 1, null, 'year', this.getAPTooltip(basesLabel +'yearVolume'));
    }
  }

  setLaunchVolume(headerList): void {
    if (headerList.indexOf('launchVolume') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.launchVolume = this.buildAPMeasures('LAUNCH VOLUME', 'Parent', null, 1, null, 'year', this.getAPTooltip(basesLabel +'launchvolume'));
    }
  }

  setexecutionalPotential(headerList): void {
    if (headerList.indexOf('executionalElements') > -1) {
      const basesLabel = this.basesLabel ? this.basesLabel?.toLowerCase() + '.' : '';
      this.executionalPotential = this.buildAPMeasures('EXECUTIONAL ELEMENTS', 'Parent', 'executionalPotential', 1, [], 'executionalPotential', this.getAPTooltip(basesLabel +'executionalElements'));
      if (headerList.indexOf('acceptableCostsFactorColor') > -1) {
          const acceptableCostsFactorColor = this.buildAPMeasures('Acceptable Costs', 'Leaf', 'acceptableCosts', 2, null, null, null);
          this.executionalPotential[0].children.push(acceptableCostsFactorColor[0]);
      }
      if (headerList.indexOf('attentionCatchingFactorColor') > -1) {
        const attentionCatchingFactorColor = this.buildAPMeasures('Attention Catching', 'Leaf', 'attentionCatching', 2, null, null, null);
        this.executionalPotential[0].children.push(attentionCatchingFactorColor[0]);
      }
      if (headerList.indexOf('credibilityFactorColor') > -1) {
        const credibilityFactorColor = this.buildAPMeasures('Credibility', 'Leaf', 'credibility', 2, null, null, null);
        this.executionalPotential[0].children.push(credibilityFactorColor[0]);
      }
      if (headerList.indexOf('messageConnectionFactorColor') > -1) {
        const messageConnectionFactorColor = this.buildAPMeasures('Message Connection', 'Leaf', 'messageConnection', 2, null, null, null);
        this.executionalPotential[0].children.push(messageConnectionFactorColor[0]);
      }
      if (headerList.indexOf('clearConciseMessageFactorColor') > -1) {
        const clearConciseMessageFactorColor = this.buildAPMeasures('Clear, Concise Message', 'Leaf', 'clearConciseMessage', 2, null, null, null);
        this.executionalPotential[0].children.push(clearConciseMessageFactorColor[0]);
      }
      if (headerList.indexOf('findabilityFactorColor') > -1) {
        const findabilityFactorColor = this.buildAPMeasures('Findability', 'Leaf', 'findability', 2, null, null, null);
        this.executionalPotential[0].children.push(findabilityFactorColor[0]);
      }
    }
  }
}
