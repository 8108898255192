/**
 * Filter name keys and values.
 *
 * @export
 * @property
*/
export const filterNames = {
  allFactors: 'show-all-factors',
  allThresholds: 'show-all-thresholds',
  distinctProposition: 'distinct-proposition',
  attentionCatching: 'attention-catching',
  needDesire: 'need-desire',
  advantage: 'advantage',
  credibility: 'credibility',
  acceptableCosts: 'acceptableCosts',
  outstanding: 'outstanding',
  ready: 'ready',
  risky: 'risky',
  highRisk: 'high-risk',
  noDecimals: 'no-decimals',
  oneDecimalPlace: 'one-decimal-place',
  twoDecimalPlace: 'two-decimal-place',
  statTesting: 'stat-testing',
};
