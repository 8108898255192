import {
    Component,
    Input,
    OnDestroy,
    OnInit
} from "@angular/core";
import { StrengthWatchoutsConcept } from "@app/deliverables/strengths-watchouts/models/strength-watchouts.model";
import { StrengthWatchoutsFilter } from "@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model";

@Component({
    selector: "carousel-concept-image",
    templateUrl: "./carousel-concept-image.component.html",
    styleUrls: ["./carousel-concept-image.component.scss"],
})
export class CarouselConceptImageComponent implements OnInit, OnDestroy {

    @Input() concepts: StrengthWatchoutsConcept[];

    @Input() filter: StrengthWatchoutsFilter;

    @Input() index: number;

    concept: StrengthWatchoutsConcept;

    currentIndex: number;

    showNavigation: boolean = false;

    imageList: any[];

    conceptImageUrl: string;

    localeList: any[];

    isNavigation: boolean = false;

    isNextEnabled: boolean = false;

    isPreviousEnabled: boolean = false;

    constructor() {}

    ngOnInit() {
        this.currentIndex = 0;
        this.concept = this.concepts[0];
        this.localeList = this.filter.show.languages.filter(
            (it) => it.id != "all"
        );
        this.imageList = JSON.parse(JSON.stringify(this.localeList));   ;
        this.conceptImageUrl = this.replaceQueryParam(
            "locale",
            this.localeList[0].id,
            this.concept.imagePath
        );
        this.setSelectedLocale();
    }

    /**
     *  Method provide ability to replace parameters passed in a url string
     * @param param
     * @param newval
     * @param search
     * @returns
     */
    replaceQueryParam(param, newval, search) {
        var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
        var query = search.replace(regex, "$1").replace(/&$/, "");
        return (
            (query.length > 2 ? query + "&" : "?") +
            (newval ? param + "=" + newval : "")
        );
    }

   nextImage() {
        if (this.currentIndex < this.localeList.length - 1) {
            this.currentIndex++;
        }
        this.conceptImageUrl = this.replaceQueryParam(
            "locale",
            this.localeList[this.currentIndex].id,
            this.concept.imagePath
        );
        this.setSelectedLocale();
    }

    previousImage() {
        if (this.currentIndex < this.localeList.length - 1 && this.currentIndex > 0 ) {
            this.currentIndex--;
        } else {
            this.currentIndex = 0;
        }
        this.conceptImageUrl = this.replaceQueryParam(
            "locale",
            this.localeList[this.currentIndex].id,
            this.concept.imagePath
        );
        this.setSelectedLocale();
    }

    setSelectedLocale(){
        this.imageList.forEach((img)=>{
            img.isSelected = this.imageList[this.currentIndex].id === img.id ? true : false;
        });
        if(this.currentIndex === 0){
            this.isPreviousEnabled = false;
            this.isNextEnabled = true;
        }else if(this.currentIndex === this.localeList.length - 1){
            this.isPreviousEnabled = true;
            this.isNextEnabled = false;
        }
    }

    /**
     * Set onHover to enable/disable navigation
    */
    onHoverImage(value:boolean){
        this.isNavigation = value;
    }

    ngOnDestroy() {}
}
