<div class="sticky-container facet-container" *ngIf="filter">
    <div class="air-filters-bar clearfix">
      <div class="float-left">
        <ng-container *ngIf="!concept">
          <ns-dropdown id="compareFilter" class="filter-item" [dropDownData]="compareListData"
            (selectionChange)="selectCompare($event.data)">
          </ns-dropdown>
          <ns-dropdown id="conceptsFilter" class="filter-item" [dropDownData]="conceptsListData"
            (selectionChange)="selectedConcept($event.data)">
          </ns-dropdown>
          <ns-dropdown id="countryFilter" class="filter-item" [dropDownData]="countryListData"
            (selectionChange)="selectedEvent($event)">
          </ns-dropdown>
          <ns-dropdown id="subgroupsFilter" class="filter-item" [dropDownData]="subgroupsListData"
            (selectionChange)="selectedSubgroup($event.data)">
          </ns-dropdown>
        </ng-container>
      </div>
    </div>
  </div>