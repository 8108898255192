<a class="deliverable-card-link" [routerLink]="deliverableInfo?.path">
  <mat-card class="deliverable-card-wrapper" [ngClass]="{'internal-user-false': !isInternalUser}" [routerLink]="deliverableInfo?.path">
    <mat-card-header class="deliverable-card-header">
      <mat-card-title class="deliverable-card-title">
        <span *ngIf="deliverablesInReport.length > 1" class="drag-icon sif sif-drag-handler ellipsis-icon"></span>
        {{deliverableInfo?.label}}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="deliverable-card-content">
      <div class="deliverable-card-image">
        <img mat-card-image src="{{deliverableInfo.imagePath}}" alt="Icon">
      </div>
      <p>{{ deliverableInfo.description }}</p>
    </mat-card-content>
  </mat-card>
</a>
