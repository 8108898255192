import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ns-deliverable-configuration-header',
    templateUrl: './deliverable-configuration-header.component.html',
    styleUrls: ['./deliverable-configuration-header.component.scss']
})
export class DeliverableConfigurationHeaderComponent {

    @Input() headerText: string;

    @Input() showBackButton: boolean = true;

    @Output() closeBtnClickEvent = new EventEmitter();

    @Output() backBtnClickEvent = new EventEmitter();

    returnBack(): void {
        this.backBtnClickEvent.emit();
    }

    close(): void {
        this.closeBtnClickEvent.emit();
    }
}
