import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DeliverableViewMatTreeService {
    
    forecastVolumeExpandedRows={};
    forecastMarketingPlanExpandedRows={};
    forecastVolumeEstimateConfigurationExpandedTableRows={};
    forecastVolumeEstimateConfigurationExpandedEstimateRows={};
    forecastMarketingPlanConfigurationExpandedTableRows={};
    forecastMarketingPlanConfigurationExpandedEstimateRows={};
}