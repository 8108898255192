import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Click, ClickZone, StrengthWatchoutsConcept} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';
import {StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {Subscription} from 'rxjs';
import {LocaleService} from '@platform/services/locale.service';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {TranslateService} from '@ngx-translate/core';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';
import {Verbatim} from '@products/shared/verbatims-list/verbatim.model';

@Component({
    selector: 'sw-verbatims-search',
    templateUrl: './verbatims-search.component.html',
    styleUrls: ['./verbatims-search.component.scss']
})
export class VerbatimsSearchComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * The strength and watchout concept data.
     * @type {StrengthWatchoutsConcept}
     * @memberOf VerbatimsSearchComponent
     */
    @Input() concept: StrengthWatchoutsConcept;

    @Input() selectedZone: ClickZone;

    /**
     * Event for hovering over verbatims.
     * @type {EventEmitter<Click>}
     * @memberOf VerbatimsSearchComponent
     */
    @Output() hoverEvent: EventEmitter<Click> = new EventEmitter<Click>();

    public filter: StrengthWatchoutsFilter;

    /**
     * The value entered the search box for verbatims.
     * @type {string}
     */
    public searchText = '';

    public verbatimStringArray: String[] = [];

    public displayedVerbatimStringArray = [];

    /**
     * All the clicks for the concept.
     * @type {Array<Click>}
     * @memberOf VerbatimsSearchComponent
     */
    public clicks: Array<Click>;

    /**
     * The clicks filtered to only the likes.
     * @type {Array<Click>}
     * @memberOf VerbatimsSearchComponent
     */
    public likeClicks: Array<Click>;

    /**
     * The clicks filtered to only the dislikes.
     * @type {Array<Click>}
     * @memberOf VerbatimsSearchComponent
     */
    public dislikeClicks: Array<Click>;

    /**
     * The clicks filtered to only the neutral.
     * @type {Array<Click>}
     * @memberOf VerbatimsSearchComponent
     */
    public neutralClicks: Array<Click>;

    /**
     * The currently selected clicks, either likes or dislikes.
     * @type {Array<Click>}
     * @memberOf VerbatimsSearchComponent
     */
    public displayedClicks: Array<Click>;

    private subscriptions: Array<Subscription> = [];

    public translationsListData: DropdownData<string>;

    public searchSizes: { dislikes: number, likes: number, neutral: number };

    public selectedTranslation: string;

    public currentLocale: string;

    @Input() deselectZones: boolean;

    public translationLocales: Array<string>;

    dataViewList: boolean;

    constructor(private strengthWatchoutsService: StrengthWatchoutsService,
                private localeService: LocaleService,
                private translate: TranslateService,
                private mixpanelService: MixpanelService) {
    }

    /**
     * ngOnInit is the first state of landing on verbatims
     */
    ngOnInit() {
        this.clicks = this.concept.clicks;
        this.currentLocale = this.concept.locale;
        this.verbatimStringArray = this.getVerbatimArray(this.clicks);
        const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
        const filterSubscription = filter$.subscribe((filter) => {
            this.filter = this.strengthWatchoutsService.updateFilterOptions(filter);
            this.dataViewList = this.filter.show.dataView.find(it => it.id === 'list').isSelected;
            this.clicks = this.concept.clicks;
            // this.setTranslationLocales(this.clicks, this.currentLocale);
            // this.setClicks(filter, this.concept.clicks);
            this.setDataOnFilterOrConceptChange();
        });
        this.subscriptions.push(filterSubscription);
    }

/**
 * setting data upon filter changes or concept changes
 */
setDataOnFilterOrConceptChange() {
    this.clicks =  this.filter.show.activatedZoneImageLabel ?
        this.clicks.filter(click => click.zoneLabel === this.filter.show.activatedZoneImageLabel) : this.concept.clicks;
    this.currentLocale = this.concept.locale;
    this.selectedTranslation = this.currentLocale;
    this.setTranslationLocales(this.clicks, this.currentLocale);
    this.setClicks(this.filter, this.clicks);
    this.setDisplayData(this.likeClicks.concat(this.neutralClicks).concat(this.dislikeClicks));
}


  ngOnChanges(changes: SimpleChanges) {
      if (changes.concept && changes.concept.previousValue) {
          this.setDataOnFilterOrConceptChange();
      }
      this.clicks = this.concept.clicks;
      this.clicks = this.filter?.show?.activatedZoneImageLabel ? this.clicks?.filter(click => click.zoneLabel === this.filter.show.activatedZoneImageLabel) : this.clicks;
      this.setClicks(this.filter, this.clicks);
      this.setSearchTextCount();
      this.getVerbatimDataByClickOption();
    }


    /**
     * This is method is to set translation locales for dropDown in verbatim likes/dislikes view
     * @param clicks
     * @param selectedFilterLocale
     */
    setTranslationLocales(clicks: Array<Click>, selectedFilterLocale: string) {
        if (clicks && clicks.length !== 0) {
            const translations = clicks.find((click) => click.translations)?.translations;
            this.translationLocales = translations && translations.length !== 0 ? Object.keys(translations) : [selectedFilterLocale];
        }
    }

    /**
     * Filters the clicks into likes and dislikes.  Sets the current clicks to likes.
     */
    setClicks(filter: StrengthWatchoutsFilter, clicks: Array<Click>): void {
        this.likeClicks = StrengthWatchoutsService.getLikes(filter, clicks);
        this.dislikeClicks = StrengthWatchoutsService.getDislikes(filter, clicks);
        this.neutralClicks = StrengthWatchoutsService.getNeutral(filter, clicks);
    }

    /**
     * setting likes or dislikes clicks to display on init and view selection
     * setting translation drop down data on init and view selection
     * @param displayedClicks likes or dislikes based on view selection
     */
    setDisplayData(displayedClicks) {
        this.displayedClicks = displayedClicks;
        this.verbatimStringArray = this.getVerbatimArray(this.concept.clicks);
        this.displayedVerbatimStringArray = this.getVerbatimObjectArray(this.displayedClicks);
        const dropDownLocales = this.translationLocales;
        const selectedVerbatimDropDownLocale = this.selectedTranslation ? this.selectedTranslation : this.currentLocale;
        this.translationsListData = this.getTranslationsDropDown(dropDownLocales, selectedVerbatimDropDownLocale);
    }

    /**
     * get verbatims array
     * @param clicks
     */
    getVerbatimArray(clicks: Array<Click>) {
        const verbatimArray = [];
        const selectedVerbatimDropDownLocale = this.selectedTranslation ? this.selectedTranslation : this.currentLocale;
        clicks.forEach((click) => {
            if (click.translations) {
                verbatimArray.push(click?.translations[selectedVerbatimDropDownLocale]);
            } else {
                verbatimArray.push(click?.verbatim === undefined ? '' : click.verbatim);
            }
        });
        return verbatimArray;
    }

    /**
     * get verbatims Object Array
     * @param clicks
     */
    getVerbatimObjectArray(clicks: Array<Click>) {
        const verbatimObjectArray = [];
        const selectedVerbatimDropDownLocale = this.selectedTranslation ? this.selectedTranslation : this.currentLocale;
        clicks?.forEach((click) => {
            const verbatimObject: Verbatim = {id: parseInt('' + click.x + click.y), value: ''};
            if (click.translations) {
                verbatimObject.value = click.translations[selectedVerbatimDropDownLocale];
                verbatimObjectArray.push(verbatimObject);
            } else {
                verbatimObject.value = click?.verbatim;
                verbatimObjectArray.push(verbatimObject);
            }
        });
        return verbatimObjectArray;
    }

    /**
     * get Translations Dropdown
     * @param locales
     * @param selectedVerbatimLocale
     */
    public getTranslationsDropDown(locales, selectedVerbatimLocale): DropdownData<string> {
        this.selectedTranslation = selectedVerbatimLocale;
        const languages: Array<DropdownItem<string>> = [];
        locales?.forEach((locale) => {
            const localeDisplay = this.localeService.getLocaleLabel(locale);
            languages.push({
                value: locale,
                label: localeDisplay,
                selected: locale === selectedVerbatimLocale
            });
        });
        return {
            dropdownLabel: this.translate.instant('shared.deliverables.strengthsWatchouts.verbatims.filter.translations.label'),
            dataType: DataType.RADIO,
            data: languages
        };
    }

    /************************ Change Translations in Verbatims List View **************************************************************/

    /**
     * Emits event of the selected filter menu item.
     */
    selectTranslation(translations: Array<DropdownItem<string>>) {
        this.selectedTranslation = translations.find((translation) => translation.selected).value;
        this.setDisplayData(this.displayedClicks);
        this.setSearchTextCount();
        this.getVerbatimDataByClickOption();
    }

    /************************ Search in Verbatims List View **************************************************************/

    /**
     * When user clicks search filter out the clicks to only show the verbatims containing the search text.
     * The likes and dislikes need to be reset.  The displayed clicks also needs to be reset.
     */
    onSearchClick(): void {
        this.mixpanelService.track(MixpanelLabel.strengthsWatchouts, MixpanelEvent.verbatimSearch);
        this.setSearchTextCount();
        this.getVerbatimDataByClickOption(true);
    }

    setSearchTextCount(): void {
        const selectedVerbatimDropDownLocale = this.selectedTranslation ? this.selectedTranslation : this.currentLocale;
        if (this.searchText || this.searchText === '') {
            const regex = new RegExp(this.searchText, 'gi');
            const searchTextClicks = this.clicks.filter((click: Click) => {
                if (click.translations) {
                    return click.translations[selectedVerbatimDropDownLocale]?.match(regex);
                }
                return click?.verbatim?.match(regex);
            });
            this.setClicks(this.filter, searchTextClicks);
            this.searchSizes = this.searchText === '' ? null : {
                dislikes: this.dislikeClicks.length,
                likes: this.likeClicks.length,
                neutral: this.neutralClicks.length
            };
        }
    }

    onSearchDataEvent(filteredList): void {
        const selectedVerbatimDropDownLocale = this.selectedTranslation ? this.selectedTranslation : this.currentLocale;
        if (filteredList) {
            const searchTextClicks = this.clicks.filter((click: Click) => {
                if (click.translations) {
                    return filteredList.includes(click.translations[selectedVerbatimDropDownLocale]);
                }
                return filteredList.includes(click.verbatim);
            });
            this.setClicks(this.filter, searchTextClicks);
            this.searchSizes = filteredList.length === this.verbatimStringArray.length ? null : {
                dislikes: this.dislikeClicks.length,
                likes: this.likeClicks.length,
                neutral: this.neutralClicks.length
            };
            this.getVerbatimDataByClickOption(true);
        }
    }

    onMouseEnter(click: Click): void {
        this.hoverEvent.emit(click);
    }

    onMouseLeave(): void {
        this.hoverEvent.emit(null);
    }

    /**
     * Sets the list of clicks to be displayed to the user based on sentiment and search selection
     */
    getVerbatimDataByClickOption(onSearch:boolean=false): void {
        const selectedClickOption = this.filter?.show?.options.filter(it => it.isSelected && it.id !== 'zones');
        const dataToShow = this.likeClicks.concat(this.neutralClicks).concat(this.dislikeClicks);
        if (selectedClickOption?.length > 1) {
            this.displayedClicks = onSearch ? dataToShow : (dataToShow.length > 0 ? dataToShow : this.clicks);
        } else {
            const clickSelected = selectedClickOption ? selectedClickOption[0]?.id : null;
            switch (clickSelected) {
                case '1':
                    this.displayedClicks = this.likeClicks;
                    break;
                case '0':
                    this.displayedClicks = this.dislikeClicks;
                    break;
                case '2':
                    this.displayedClicks = this.neutralClicks;
                    break;
                default:
                    this.displayedClicks = null;
            }
        }
        this.displayedVerbatimStringArray = this.getVerbatimObjectArray(this.displayedClicks);
    }
    /**
     * getCountsLikesDislikesNeutral helps to get counts for different sentimentfrom displayedClicks data.
     * @param displayedClicks
     * @returns counts array eith list of zones and respective counts of clicks
     */

    getCountsLikesDislikesNeutral(displayedClicks) {
        let counts = [];
        this.filter?.show?.options?.forEach((opt)=> {
            if(opt.id != 'zones' && opt.isSelected == true) {
                counts.push({
                   id : opt.id,
                   name: opt.name,
                   count : displayedClicks.filter((it) => it.like == Number(opt.id) && it.verbatim).length
                })
            }
        });
        return counts;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

}
