<div class="empty-container">
  <div class="empty-page-container-large flex align-items-center" *ngIf="pageSize === 'large'">
    <div class="info-block">
      <div class="circle" *ngIf="type != 'empty'">
        <span class="sif sif-{{ icon }}"></span>
      </div>
      <div *ngIf="type == 'empty'" class="info-image">
        <img src="assets/images/broaden-search.svg" alt="{{ leadText | uppercase }}"/>
      </div>
  
      <div class="lead-text">
        <h3>{{ leadText | uppercase }}</h3>
      </div>
  
      <div class="support-text">
        <p>{{ supportText }}</p>
        <p *ngIf="editText">Click '<span class="sif sif-edit"></span>' to get started.</p>
      </div>
    </div>
  </div>
  <div class="empty-page-container-medium"  *ngIf="pageSize === 'medium'">
    <div class="circle" [class]="type === 'error' ? 'error' : ''" *ngIf="type != 'empty'">
      <span class="sif sif-{{ icon }}"></span>
    </div>
    <div *ngIf="type == 'empty'" class="info-image">
      <img src="assets/images/broaden-search.svg" alt="{{ leadText | uppercase }}"/>
    </div>
    <div class="info-block">
      <div class="lead-text" [class]="type === 'error' ? 'error' : ''">
        <h3>{{ leadText | uppercase }}</h3>
      </div>
  
      <div class="support-text">
        <p>{{ supportText }}</p>
        <p *ngIf="editText">Click '<span class="sif sif-edit"></span>' to get started.</p>
      </div>
    </div>
  </div>
  <div class="empty-page-container-small flex align-items-center"  *ngIf="pageSize === 'small'">
    <div class="circle" [class]="type === 'error' ? 'error' : ''" *ngIf="type != 'empty'">
      <span class="sif sif-{{ icon }}"></span>
    </div>
    <div *ngIf="type == 'empty'" class="info-image">
      <img src="assets/images/broaden-search.svg" alt="{{ leadText | uppercase }}"/>
    </div>
    <div class="info-block">
      <div class="lead-text" [class]="type === 'error' ? 'error' : ''">
        <h3>{{ leadText | uppercase }}</h3>
      </div>
  
      <div class="support-text">
        <p>{{ supportText }}</p>
        <p *ngIf="editText">Click '<span class="sif sif-edit"></span>' to get started.</p>
      </div>
    </div>
  </div>
</div>
