import { Subgroup } from '@platform/models/subgroup.model';
import { FilterService } from '@platform/services/filter.service';
import { Injectable } from '@angular/core';
import { Observable, zip, of } from 'rxjs';
import { DeliverableViewService } from '@platform/services/deliverable-view.service';
import { take, switchMap } from 'rxjs/operators';
import { ReportService } from '@platform/services/report.service';
import { SubgroupService } from '@platform/services/subgroup.service';
import { ConceptService } from '@platform/services/concept.service';
import { Concept } from '@platform/models/concept.model';
import { FilterItem, InteractionsFilter } from '../models/filter.model';
import { InteractionsDeliverableView } from '../models/interactions.model';
import { defaultInteractionsFilter } from '../models/default-interactions-filter.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableView} from "@platform/models/deliverable-view.model";
import {ReachAnalysisFilter} from "@app/deliverables/reach-analysis/models/filter.model";

/**
 * `InteractionsService` has all data operations for fetching interactions deliverables
 * and interactions deliverables filter data.
 *
 * @export
 * @class InteractionsService
 */
@Injectable({
    providedIn: 'root'
})
export class InteractionsService {

    /**
     * Creates an instance of InteractionsService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @memberof InteractionsService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService) { }

    public getConceptsForFilter(): Observable<any> {
        const deliverableType = DeliverableType.INTERACTIONS.type;
        const filter$ = this.getInteractionsFilter();
        return filter$.pipe(
            switchMap(filter => {
                return this.deliverableViewService.filter<InteractionsDeliverableView, InteractionsFilter>(
                    filter.deliverableViewType,
                    deliverableType,
                    this.setConceptsForFilter.bind(this)
                );
            })
        );

    }

    public setConceptsForFilter(filter: InteractionsFilter, data: InteractionsDeliverableView): any {
        let defCompetitorConceptIds = data.subgroups[0].competitors.map(concept => concept.conceptId);
        let defRegularConceptIds = filter.concepts.filter(fConcept => !defCompetitorConceptIds.includes(fConcept.id)).map(concept => concept.id);
        return [defRegularConceptIds, defCompetitorConceptIds];
    }
    /**
     * Returns an observable of `InteractionsDeliverableView` data.
     *
     * @returns {Observable<InteractionsDeliverableView>}
     * @memberof InteractionsService
     */
    public getInteractions(): Observable<InteractionsDeliverableView> {
        const deliverableType = DeliverableType.INTERACTIONS.type;
        const filter$ = this.getInteractionsFilter();
        const Interactions$: Observable<InteractionsDeliverableView> = filter$.pipe(
            switchMap(filter => {
                return this.deliverableViewService.filter<InteractionsDeliverableView, InteractionsFilter>(
                    filter.deliverableViewType,
                    deliverableType,
                    this.filter.bind(this)
                );
            })
        );
        return Interactions$;
    }

    /**
     * Filter projection function for filtering the interactions data and returns
     * filtered `InteractionsDeliverableView` data.
     *
     * @private
     * @param {InteractionsFilter} filter
     * @param {InteractionsDeliverableView} data
     * @returns {InteractionsDeliverableView}
     * @memberof InteractionsService
     */
    private filter(filter: InteractionsFilter, data: InteractionsDeliverableView): InteractionsDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const filteredSubgroups = [];
        let filteredSubgroup;
        let filteredShareIndices;
        let filteredView;
        for (const subgroup of data.subgroups) {
            if (selectedSubgroups.includes(subgroup.segmentId)) {
                const filteredCompetitor = [];
                for (const competitor of subgroup.competitors) {
                    if (selectedConcepts.includes(competitor.conceptId)) {
                      filteredShareIndices = competitor.shareIndices.filter(d => selectedConcepts.includes(d.conceptId));
                        filteredCompetitor.push({
                            conceptId: competitor.conceptId,
                            shareIndices: filteredShareIndices
                        });
                    }
                }
                filteredSubgroup = {
                    competitors: filteredCompetitor,
                    segmentId: subgroup.segmentId,
                };
                filteredSubgroups.push(filteredSubgroup);
            }
        }
        filteredView = Object.assign({}, data, { subgroups: filteredSubgroups });
        return filteredView;
    }

    /**
     * Returns observable of interactions filter data.
     *
     * @returns {Observable<InteractionsFilter>}
     * @memberof InteractionsService
     */
    public getInteractionsFilter(): Observable<InteractionsFilter> {
        const deliverableType = DeliverableType.INTERACTIONS.type;
        const filter$ = this.filterService.get<InteractionsFilter>(deliverableType);
        return filter$;
    }

    /**
     * Loads default Interactions filter data.
     *
     * @memberof InteractionsService
     */
    public loadDefaultFilter(deliverableViews: Array<DeliverableView>, concept?: Concept): Observable<InteractionsFilter>  {
        const defaultFilter: InteractionsFilter = Object.assign({}, defaultInteractionsFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getInteractionConceptAndCompetitors();
        const subgroups$ = this.subgroupService.getReportSubgroups();
        return zip(report$, concepts$, subgroups$).pipe(switchMap(result => {
            defaultFilter.countries = [result[0].country];
            defaultFilter.concepts = this.getConceptFilters(result[1]);
            defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            this.getSelectedShowFilter(defaultFilter);
            this.filterService.update(defaultFilter);
            return of(defaultFilter);
        }));
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @returns {Array<FilterItem>}
     * @memberof InteractionsService
     */
    private getConceptFilters(concepts: Array<Concept>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: true,
                position: item.position
            });
        }
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @returns {Array<FilterItem>}
     * @memberof InteractionsService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position
            });
        }
        subgroupFilterItems[0].isSelected = true;
        return subgroupFilterItems;
    }

    /**
     * Returns updated map of Selected filter items for insights.
     *
     * @private
     * @param filter
     * @returns {Array<FilterItem>}
     * @memberof InteractionsService
     */
    public getSelectedFilterItem(filterItem) {
        const selectedFilterItems = [];
        for (const item of filterItem) {
            selectedFilterItems.push({
                name: item.name,
                id: item.id,
            });
        }
        return selectedFilterItems;
    }

    /**
     * Returns Selected show filter items for insights.
     *
     * @private
     * @param filter
     * @returns {Array<FilterItem>}
     * @memberof InteractionsService
     */
    public getSelectedShowFilter(filterItem) {
        const insight = {};
        for (const [k, v] of Object.entries(filterItem)) {
            if (k === 'percents' && v['isSelected'] === true) {
                for (const [key, val] of Object.entries(v)) {
                    if (val === true) {
                        insight[key] = val;
                    }
                }
            }
            if (v === true) {
                insight[k] = v;
            }
        }
        return insight;
    }

}
