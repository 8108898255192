import { BarChartType } from "@products/shared/chart-horizontal-bar/bar-chart.model";

/**
 * Default bar chart options.
 *
 * @export
 * @property
*/
export const defaultBarChartOptions = {
  margin: {
    left: 0,
    right: 20,
    top: 7.5,
    bottom: 7.5
  },
  height: 25,
  width: 180,
  type: BarChartType.HORIZONTAL,
  aggregateLabel: {
    enabled: true,
    margin: {
      left: 5,
      top: 25
    },
    style: {
      'font-weight': 'bold',
      'font-size': '16px'
     },
    fill: '#009dd9'
  },
  bar: {
    range: {
      min: 0,
      max: 220
    },
    domain: {
      min: 0,
      max: 150
    },
    margin: {
      top: 7.5,
      right: 0.5,
      bottom: 7.5
    },
    height: 25,
    tooltip: true,
    dataLabel: false,
    dataLabelLines: false
  }
};
