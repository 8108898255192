<ns-deliverable-configuration-header
        [headerText]="'Deliverable Configuration'"
        [showBackButton]=true
        (backBtnClickEvent)="returnBack()"
        (closeBtnClickEvent)="onCloseButtonClick()"></ns-deliverable-configuration-header>
<div class="menu-list" *ngFor="let deliverableInfo of deliverablesInReport;let i=index">
    <li class="main-menu-item" [attr.deliverable-type]="deliverableInfo.type">
        <span class="deliverableView-text cursor-pointer"
              (click)="onClick(deliverableInfo.type)">{{getLabel(deliverableInfo.type)}}</span>
        <div class="last-span">
            <mat-slide-toggle
                    [id]="'deliverableViewToggle'+i"
                    [(ngModel)]="deliverableInfo.showDeliverable"
                    [disabled]=false
                    (ngModelChange)="onChangeOfShowHideIndicator(deliverableInfo)">
            </mat-slide-toggle>
        </div>
        <button mat-icon-button *ngIf="isChildMenuAvailable(deliverableInfo.type)"
                (click)="onClick(deliverableInfo.type)">
            <span class="sif sif-chevron-e sif-gray cursor-pointer"></span>
        </button>
        <span class="icon-placeholder" *ngIf="!isChildMenuAvailable(deliverableInfo.type)"></span>
    </li>
</div>

