<div class="archive-settings-header">
  <div class="title-left">
    <span class="title">{{ 'platform.report.archive.settings.title' | translate }} {{'"'}} {{title}} {{"!"}} {{'"'}}</span>
  </div>
</div>

<table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container class="archival-row">
    <!-- Project Name Column -->
    <ng-container matColumnDef="projectName">
      <th mat-header-cell *matHeaderCellDef> {{ 'platform.report.archive.settings.table.header.project' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.projectName}} </td>
    </ng-container>

    <!-- Archive Column -->
    <ng-container matColumnDef="toArchive">
      <th mat-header-cell *matHeaderCellDef> {{ 'platform.report.archive.settings.table.header.archive' | translate }} </th>
      <td mat-cell *matCellDef="let element">
          <div >
            <mat-checkbox class="archive-checkbox"  color="primary" [checked]="element.toArchive" [disabled]="archived" (change)="setArchiveStatus($event.checked)"></mat-checkbox>
          </div>
      </td>
    </ng-container>

    <!-- Archival Date Column -->
    <ng-container matColumnDef="archivalDate">
      <th mat-header-cell *matHeaderCellDef>{{ 'platform.report.archive.settings.table.header.date' | translate }}

      </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field>
          <input matInput [matDatepicker]="picker1" autocomplete="off" [min]="todayDate"  [value]="element.archivalDate" (dateChange)="setArchiveDateChange($event)" [disabled]="archived">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 color="primary"></mat-datepicker>
        </mat-form-field>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row [ngClass]="{'make-row-disabled' : archived }" *matRowDef="let row; columns: displayedColumns; "></tr>
</table>

<div class="btn-right">
  <div class="save-modal-actions" align="end">
    <button class="cancel-button"  mat-dialog-close mat-button>{{ 'platform.report.archive.settings.button.cancel' | translate }}</button>
    <button  class="save-button" mat-button [disabled]="archived" (click)="onSave()">{{ 'platform.report.archive.settings.button.save' | translate }}</button>
  </div>
</div>
