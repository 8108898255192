import { debounceTime, take } from 'rxjs/operators';
import { Subscription, combineLatest, of, Observable } from 'rxjs';
import { UserView } from '@platform/models/user-view.model';
import { UserViewService } from '@platform/services/user-view.service';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Insight} from '@platform/models/insight.model';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelViews } from '@src/assets/utils/mixpanel-enum';

@Component({
  selector: 'ns-save-views',
  templateUrl: './save-views.component.html',
  styleUrls: ['./save-views.component.scss']
})
export class SaveViewsComponent implements OnInit, OnDestroy {

  @Input() deliverableType: string;
  deliverableLabel: string;
  @ViewChild('dialog') modal;
  userViews: UserView[];
  currentViewName: string;
  defaultView: UserView;
  selectedView: UserView;
  insightView: UserView;
  isRename = false;
  insight: Insight;
  private subscriptions: Subscription[];

  /**
   * Constructor
   *
   * @param dialog
   * @param filterService
   * @param userViewService
   */
  constructor(
    private dialog: MatDialog,
    private userViewService: UserViewService,
    private mixpanelService: MixpanelService
  ) {
      this.subscriptions = [];
  }

  /**
   * Initialize the component.
   */
  ngOnInit(): void {
    this.userViews = [];
    let selectedView: UserView;
    this.deliverableLabel = this.mixpanelService.getMixpanelLabel(this.deliverableType);
    const userViews$ = this.userViewService.getByDeliverable(this.deliverableType);
    const defaultView$ = this.userViewService.getDefaultView(this.deliverableType);
    const insightUserView$: Observable<UserView> = this.userViewService.getInsightUserView();
    const viewsSubscription = combineLatest([defaultView$, userViews$, insightUserView$])
      .pipe(debounceTime(10))
      .subscribe(([defaultView, userViews, insightUserView]) => {
        this.defaultView = defaultView;
        this.userViews = userViews;
        this.userViews.unshift(defaultView);
        if (insightUserView && !this.selectedView) {
          this.userViewService.setFilterAndMetaInfo(insightUserView);
          this.selectedView = insightUserView;
        } else {
          selectedView = this.userViewService.getSelectedView(userViews);
          if (!this.selectedView && selectedView) {
            this.userViewService.setFilterAndMetaInfo(selectedView);
            this.selectedView = selectedView;
          } else if (!this.selectedView && !selectedView) {
            this.selectedView = defaultView;
          }
        }
    });
    const insightsSubscription = insightUserView$.subscribe( insightUserView => {
      if (insightUserView && !this.selectedView) {
        this.userViewService.setFilterAndMetaInfo(insightUserView);
        this.selectedView = insightUserView;
      }
    })

    this.subscriptions.push(viewsSubscription,insightsSubscription);
  }

  /**
   * Opens Save Dialog
   */
  openDialog() {
    this.dialog.open(this.modal);
  }

  /**
   * Saves the view name
   */
  saveView(userViewName: string) {
    this.dialog.closeAll();
    let userView: UserView;
    if (!userViewName || userViewName.length < 0) {
        return;
    }
    if (this.isRename) {
      userView = Object.assign({}, this.selectedView);
      userView.name = userViewName;
      this.userViewService.update(userView).subscribe((savedView) => {
        this.isRename = false;
        this.selectedView = savedView;
      });
      this.mixpanelService.track(this.deliverableLabel, MixpanelViews.renameView);
    } else {
        this.userViewService.save(userViewName, this.deliverableType)
          .pipe(take(1))
          .subscribe((savedView) => {
            this.selectedView = savedView;
          });
      this.mixpanelService.track(this.deliverableLabel, MixpanelViews.createView);
    }
    this.currentViewName = this.currentViewName ? this.currentViewName : '';
  }

  /**
   * Cancel modal.
   */
  cancelView() {
    this.isRename = false;
    this.currentViewName = '';
  }

  /**
   * Selects an user view.
   *
   * @param  { UserView } the selected user view.
   */
  selectView(userView: UserView) {
    if (!userView || this.selectedView.id === userView.id) {
      return;
    }
    this.userViewService.setFilterAndMetaInfo(userView);
    this.selectedView = userView;
    this.mixpanelService.track(this.deliverableLabel, MixpanelViews.changeView);
  }

  /**
   * Deletes a View
   */
  deleteView() {
    const selectedView = this.selectedView;
    if (selectedView && selectedView.id && !this.isDefaultView()) {
        this.userViewService.delete(selectedView).subscribe(() => {
          this.selectView(this.defaultView);
          this.mixpanelService.track(this.deliverableLabel, MixpanelViews.deleteView);
        });
    }
  }

  /**
   * Rename View
   */
  renameView() {
    if (this.selectedView && !this.isDefaultView()) {
        this.isRename = true;
        this.dialog.open(this.modal);
        this.currentViewName = this.selectedView.name;
    }
  }

  /**
   * Sets selected view.
   */
  setShowOnLoad() {
    if (this.selectedView && !this.isShowOnLoadSelected()) {
      this.userViewService.setSelectedView(this.selectedView, this.userViews);
      this.mixpanelService.track(this.deliverableLabel, MixpanelViews.updateView);
    }
  }

  /**
   * Returns true if the selected view is the default view.
   *
   * @returns {boolean} true if default view selected.
   */
  isDefaultView(): boolean {
    const selectedView = this.selectedView;
    const defaultView = this.defaultView;
    return selectedView?.id === defaultView?.id;
  }

  isShowOnLoadSelected(): boolean {
    const selectedView = this.userViewService.getSelectedView(this.userViews);
    const current = this.selectedView;
    /**
     * selectedView = null & current = default
     * selectedView = not null & current = default
     * selectedView = null & current = not default
     * selectedView = not null & current = not default
     * selectedView = null & current = selectedView
     * selectedView = not null & current = selectedView
     */
    const isSelected = current?.id === selectedView?.id;
    return isSelected;
  }

  /**
   * Cleanup component.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
