<div class="ap-header" *ngIf="headerList.length">
    <ap-header-section
        [headerData]="activationProfileService.concept"
        (nodeToggled)="toggleNode($event)" >
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.activationProfile"
    [bold]="true">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.broadPotential"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.targetPotential"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.brandIncrementality"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.restaurantIncrementality"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.categoryDistinction"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.industryDistinction"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.endurance"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.yearOneVolume">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.launchVolume">
    </ap-header-section>

    <ap-header-section
    [headerData]="activationProfileService.executionalPotential"
    (nodeToggled)="toggleNode($event)">
    </ap-header-section>
</div>
