import { Insight } from '@platform/models/insight.model';
import { InsightState } from './../state/insight.state';
import { Dictionary } from '@ngrx/entity';
import { State } from '@platform/store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { insightAdapter } from '../adapters/insight.adapter';

/**
 * Default selectors from adapter.
 *
 * @property
*/
const {
  selectEntities,
  selectAll,
  selectIds,
  selectTotal,
} = insightAdapter.getSelectors();

// Insight Selectors
/**
 * Insight feature selector.
 *
 * @export
 * @property
*/
export const selectInsightsFeature = createFeatureSelector< InsightState>('insights');
/**
 * Insight selector.
 *
 * @export
 * @property
*/
export const selectInsightsState = createSelector(
  selectInsightsFeature,
  (state: InsightState) => state
);
/**
 * Insight entity selector.
 *
 * @export
 * @property
*/
export const selectInsightEntities = createSelector(
  selectInsightsState,
  selectEntities
);
/**
 * Selector for selecting Insights by id.
 *
 * @export
 * @property
*/
export const selectInsightById = createSelector(
  selectInsightEntities,
  (entities: Dictionary<Insight>, props: { id: number }) => {
    return entities[props.id];
  }
);
/**
 * Selector for selecting all Insights.
 *
 * @export
 * @property
*/
export const selectAllInsights = createSelector(
  selectInsightsState,
  selectAll
);
/**
 * Selector for selecting Insights for a report object.
 *
 * @export
 * @property
*/
export const selectReportInsights = createSelector(
  selectAllInsights,
  (insight: Insight[], props: { reportId: string }) => {
    return insight.filter(c => c.reportId === props.reportId);
  }
);
