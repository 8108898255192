import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewType} from './deliverable-view-type.enum';
import {CompareView} from './compare-view-enum';

/**
 * Default filter values for Factors For Success
 *
 * @export
 * @property
 */
export const defaultFactorsFilter = {
    deliverableType: DeliverableType.FACTORS.type,
    deliverableViewType: DeliverableViewType.CONCEPT,
    compare: [
        {
            name: 'Concepts',
            id: CompareView.CONCEPT,
            isSelected: true,
            position: 1
        },
        {
            name: 'Subgroups',
            id: CompareView.SUBGROUP,
            isSelected: false,
            position: 1
        }],

    show: {
        thresholdOptions: [
            {
                name: 'Outstanding',
                isSelected: true,
            },
            {
                name: 'Ready',
                isSelected: true,
            },
            {
                name: 'Risky',
                isSelected: true,
            },
            {
                name: 'High Risk',
                isSelected: true,
            },
        ],

        factorsOptions: [
            {
                name: 'Distinct Proposition',
                isSelected: true,
                id: 'distinctProposition',
            },
            {
                name: 'Attention Catching',
                isSelected: true,
                id: 'attentionCatching',
            },
            {
                name: 'Message Connection',
                isSelected: true,
                id: 'messageConnection',
            },
            {
                name: 'Clear, Concise Message',
                isSelected: true,
                id: 'clearConciseMessage',
            },
            {
                name: 'Need/Desire',
                isSelected: true,
                id: 'needDesire',
            },
            {
                name: 'Desire',
                isSelected: true,
                id: 'desire',
            },
            {
                name: 'Advantage',
                isSelected: true,
                id: 'advantage',
            },
            {
                name: 'Credibility',
                isSelected: true,
                id: 'credibility',
            },
            {
                name: 'Acceptable Costs',
                isSelected: true,
                id: 'acceptableCosts',
            },
        ],
        decimals: {
            noDecimal: false,
            oneDecimal: true,
            twoDecimal: false
        },
        statTesting: true
    }
};
