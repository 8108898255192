import { SurveyQuestionService } from '../services/survey-question.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SurveyQuestionFilter } from '../models/filter.model';
import { Subscription } from 'rxjs';
import { StringHelper } from '@platform/services/string-helper.service';

/**
 * `TableHeaderComponent` creates survey Question column header with title,
 * size and range.
 *
 * @example
 *   <qs-survey-question-table-header
 *     [title]="title"
 *     [index]="columnIndex"
 *     [size]="nSize" >
 *   </qs-survey-question-table-header>
 *
 * @param {string} title - The title of the column header.
 * @param {number} index - The index of the column.
 * @param {number} size - The n size.
 *
 * @export
 * @class TableHeaderComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ns-survey-question-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit, OnDestroy {

  /**
   * The title of the column header.
   *
   * @property
   * @type {string}
   * @memberof TableHeaderComponent
   */
  @Input() title: string;

  /**
   * Index of the current column.
   *
   * @property
   * @type {number}
   * @memberof TableHeaderComponent
   */
  @Input() index: number;

  /**
   * N size.
   *
   * @property
   * @type {number}
   * @memberof TableHeaderComponent
   */
  @Input() size: number;

  /**
   * Survey Question filter object.
   *
   * @property
   * @type {SurveyQuestionFilter}
   * @memberof TableHeaderComponent
   */
  public filter: SurveyQuestionFilter;

    /**
   * Array of subscriptions for cleanup.
   *
   * @private
   * @type {Array<Subscription>}
   * @memberof TableHeaderComponent
   */
  private subscriptions: Array<Subscription>;

    /**
   * Creates an instance of ChartHeaderComponent.
   *
   * @constructor
   * @param {StringHelper} stringHelper
   * @param {SurveyQuestionService} surveyQuestionService
   * @memberof TableHeaderComponent
   */

  constructor(
    private stringHelper: StringHelper,
    private surveyQuestionService: SurveyQuestionService
  ) {
    this.subscriptions = [];
  }

  /**
   * Returns label alphabet from index.
   *
   * @returns {string}
   * @memberof TableHeaderComponent
   */
  getColumnLabel(): string {
    const index = this.index;
    return this.stringHelper.alphabetSeries(index);
  }

  /**
   * Initialize Table Header component.
   *
   * @memberof TableHeaderComponent
   */
  ngOnInit(): void {
    const filter$ = this.surveyQuestionService.getSurveyQuestionFilter();
    const subscription = filter$.subscribe(filter => {
      this.filter = filter;
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Clean up hook
  */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
