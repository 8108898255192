import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {PriceElasticityComponent} from '@app/deliverables/price-elasticity/price-elasticity.component';
import {PriceMentionsComponent} from '@app/deliverables/price-mentions/price-mentions.component';
import {ConfigurableComponents} from '@app/deliverables/configurable-components.enum';

const deliverableComponents: { [type: string]: any } = Object.assign({}, ConfigurableComponents);
deliverableComponents[DeliverableType.PRICE_MENTIONS.type] = PriceMentionsComponent;
deliverableComponents[DeliverableType.PRICE_ELASTICITY.type] = PriceElasticityComponent;

export {deliverableComponents};
