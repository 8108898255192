import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FilterComponent} from "@app/deliverables/factors/filter/filter.component";
import {
    StrengthWatchoutsFilter
} from "@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model";
import {FilterService} from "@platform/services/filter.service";
import {MixpanelService} from "@platform/services/mixpanel.service";
import {MixpanelEvent, MixpanelLabel} from "@src/assets/utils/mixpanel-enum";
import {stopPropagation} from "ngx-scrollbar/lib/scrollbar/common";

@Component({
    selector: 'ns-chart-options',
    templateUrl: './chart-options.component.html',
    styleUrls: ['./chart-options.component.scss']
})
export class ChartOptionsComponent implements OnInit, OnChanges {
    @ViewChild(FilterComponent) filterComponent: FilterComponent;
    @Input() viewName: string|number;
    @Input() filter: StrengthWatchoutsFilter;
    deliverableLabel: string;
    zoneSelected = false;

    @Input() isAISummaryFeatureEnabled: boolean = false;
    @Input() aiConceptSummaries: boolean = false;
    @Input() aiSubgroupSummaries: any = null;
    public isSubgroupSummaryVisible: boolean = false;
    constructor(
        private filterService: FilterService,
        private mixpanelService: MixpanelService
    ) {
    }

    ngOnInit() {
        this.deliverableLabel = this.filter?.concepts ? MixpanelLabel.concepts : MixpanelLabel.strengthsWatchouts;
        this.setZone();
        this.updateAISubgroupSummaryflag();
    }

    ngOnChanges() {
        this.viewName = this.setViewName();
        this.updateAISubgroupSummaryflag();
    }

    /**
     * To Find out Compare by view change & set changed value.
     */
    setViewName(){
        return this.filter?.compare.find((view) => view.isSelected).id;
    }

    setZone(): void {
        this.zoneSelected = this.filter?.show.options.find(option => option.id === 'zones').isSelected;
    }

    /**
     * To Find out Zone option from AlwaysPresent or OnHover
     */
    getSelectedZones(): string | number {
        return this.filter?.show.zone.find((zone) => zone.isSelected).id;
    }

    /**
     * To Change zone view in between AlwaysPresent or OnHover
     * @param event
     */
    changeZones(event): void {
        const selected = event.value;
        const filter = JSON.parse(JSON.stringify(this.filter));
        filter.show.zone.forEach((zone) => {
            zone.isSelected = (zone.id === selected);
        });
        this.filterService.update(filter);
    }

    /**
     * To get select dataView from Verbetisms view
     */
    getSelectedDataView(): string | number {
        return this.filter?.show.dataView.find((dataView) => dataView.isSelected).id;
    }

    /**
     * To Change dataView of Verbetisms view in between list & summary
     * @param event
     */
    changeDataView(event): void {
        const selected = event.value;
        const filter = JSON.parse(JSON.stringify(this.filter));
        filter.show.dataView.forEach((view) => {
            view.isSelected = (view.id === selected);
        });
        this.filterService.update(filter);
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    }

    /**
     * To get select chartMetrics from CLICKDATA view
     */
    getSelectedchartMetrics(): string | number {
        return this.filter?.show.chartMetric.find((metric) => metric.isSelected).id;
    }

    /**
     * To Change chartMetrics of CLICKDATA view in between list & summary
     * @param event
     */
    changeChartMetric(event): void {
        const selected = event.value;
        const filter = JSON.parse(JSON.stringify(this.filter));
        filter.show.chartMetric.forEach((metric) => {
            metric.isSelected = (metric.id === selected);
        });
        this.filterService.update(filter);
    }

    /*
    * This Function checks whether zone summary visibility
    * allowed for selected subgroup by aiSubgroupSummaries flag
    * */
    updateAISubgroupSummaryflag(){
        this.isSubgroupSummaryVisible = this.aiSubgroupSummaries!==null && this.filter?.subgroups?.length>0 ?
            this.aiSubgroupSummaries[this.filter.subgroups.find((it)=>it.isSelected).id]:true;
    }
}
