<div class="menu-list-content">
    <ul class="menu-list">
        <li class="list-item">
            <div class="slider-content top-slider-content">
                <span class="left">{{leftLabel}}</span><span class="right">{{rightLabel}}</span>
            </div>
            <mat-slider (input)="sliderValueChanged($event)" class="sorting-matrix-weight-slider" [step]="5" [value]="defaultValue" [max]="maxValue"(change)="onWeightChange($event)"></mat-slider>
            <div class="slider-content bottom-slider-content">
                <span class="left">{{leftVal}}%</span><span class="right">{{rightVal}}%</span>
            </div>
        </li>
    </ul>
</div>
