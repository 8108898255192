<div id="financial-potential-legend" class="footer" *ngIf="filter">
  <div id="legend-percents"  class="section legend-percents" *ngIf="isConceptView && !areRankScoresNull">
    <div class="legend financial-potential-legend">
      <strong class="c-gray-3 quintile-text">{{ 'quick.predict.deliverables.financial-potential.legend.row1.top' | translate }}</strong>
      <span class="c-gray-3 quintile-range">{{ 'quick.predict.deliverables.financial-potential.legend.row1.top.value' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend">
      <strong class="c-gray-3 quintile-text">{{ 'quick.predict.deliverables.financial-potential.legend.row1.aboveAverage' | translate }}</strong>
      <span class="c-gray-3 quintile-range">{{ 'quick.predict.deliverables.financial-potential.legend.row1.aboveAverage.value' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend">
      <strong class="c-gray-3 quintile-text">{{ 'quick.predict.deliverables.financial-potential.legend.row1.average' | translate }}</strong>
      <span class="c-gray-3 quintile-range">{{ 'quick.predict.deliverables.financial-potential.legend.row1.average.value' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend">
      <strong class="c-gray-3 quintile-text">{{ 'quick.predict.deliverables.financial-potential.legend.row1.belowAverage' | translate }}</strong>
      <span class="c-gray-3 quintile-range">{{ 'quick.predict.deliverables.financial-potential.legend.row1.belowAverage.value' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend">
      <strong class="c-gray-3 quintile-text">{{ 'quick.predict.deliverables.financial-potential.legend.row1.bottom' | translate }}</strong>
      <span class="c-gray-3 quintile-range">{{ 'quick.predict.deliverables.financial-potential.legend.row1.bottom.value' | translate }}</span>
    </div>
  </div>

  <div id="legend-description"  class="section legend-description">
    <div class="legend financial-potential-legend">
      <span class="rounded-square definitely-buy"></span>
      <span class="space-left-sm">{{ 'quick.predict.deliverables.financial-potential.legend.row2.definitelyWouldBuy' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend" *ngIf="filter.show.topTwoBox || filter.show.allFiveBoxes">
      <span class="rounded-square probably-buy"></span>
      <span class="space-left-sm">{{ 'quick.predict.deliverables.financial-potential.legend.row2.probablyWouldBuy' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend" *ngIf="filter.show.allFiveBoxes">
      <span class="rounded-square might"></span>
      <span class="space-left-sm">{{ 'quick.predict.deliverables.financial-potential.legend.row2.might' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend" *ngIf="filter.show.allFiveBoxes">
      <span class="rounded-square probably-not-buy"></span>
      <span class="space-left-sm">{{ 'quick.predict.deliverables.financial-potential.legend.row2.probablyWouldNotBuy' | translate }}</span>
    </div>
    <div class="legend financial-potential-legend" *ngIf="filter.show.allFiveBoxes">
      <span class="rounded-square definitely-not-buy"></span>
      <span class="space-left-sm">{{ 'quick.predict.deliverables.financial-potential.legend.row2.definitelyWouldNotBuy' | translate }}</span>
    </div>
  </div>

  <div id="factors-footnote" class="section footer-legend-text" *ngIf="isConceptView && categoriesData.length !== 0">
    <small>{{ 'quick.predict.deliverables.financial-potential.legend.footnote.comparisons' | translate }}</small>
    <small *ngFor="let category of categoriesData; let i = index">{{ (i >= 1) ? ', ': ''}} {{i + 1}}. {{category}}</small>
  </div>

  <div id="bottom-text" class="section footer-legend-text" *ngIf="filter.show.statTesting">
    <small>{{ 'quick.predict.deliverables.financial-potential.legend.footnote.stat.testing' | translate }}</small>
  </div>
</div>
