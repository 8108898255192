/**
 * Deliverable Configuration Model
 *
 * @export
 * @interface DeliverableConfiguration
 */
export class DeliverableConfiguration {
  /**
   * deliverable configuration object identifier.
   */
  id: string;

  /**
   * Report id to which deliverable configuration is associated
   */
  reportId: string;

  /**
   * deliverable type to which deliverable configuration is associated
   */
  name: string;

  /**
   * holds configuration information
   */
  config: any;
}
