import {createAction, props} from '@ngrx/store';
import {Benchmark} from "@platform/models/benchmark.model";

/**
 * Action for adding benchmark to application state.
 *
 * @export
 */
export const addBenchmark = createAction(
  '[Benchmark] Add Benchmark',
  props<{ benchmark: Benchmark }>()
);

export const addBenchmarks = createAction(
  '[Benchmark] Add Benchmarks',
  props<{ benchmarks: Benchmark[] }>()
);

export const updateBenchmark = createAction(
  '[Benchmark] Update Benchmark',
  props<{ benchmark: Benchmark }>()
);

export const deleteBenchmark = createAction(
  '[Benchmark] Delete Benchmark',
  props<{ reportId: string; benchmarkId:string}>()
);