<div *ngIf="show && (secondary || hero || tertiary); then prompt"></div>

<ng-template #prompt>
  <div class="border-box">
    <div *ngIf="hero" class="border-outer top">
      <div class="border-line top"></div>
    </div>
    <div class="border-row">
      <div *ngIf="hero" class="border-outer left">
        <div class="border-line left"></div>
      </div>
      <div
        class="prompt"
        [ngClass]="{
          'primary-prompt': primary,
          'secondary-prompt': secondary,
          'hero-prompt': hero,
          'tertiary-prompt': tertiary,
          'success-prompt': isSuccess,
          'error-prompt': isError,
          'info-prompt': isInformation,
          'warn-prompt': isWarn,
          'default-prompt': isDefault
        }"
      >
        <div class="icon-container" *ngIf="!hero">
          <span
            class="icon sif"
            [ngClass]="{ 'sif-checkmark': isSuccess, 'sif-info-filled': isInformation, 'sif-warning': isError || isWarn }"
          ></span>
        </div>
        <div class="prompt-content-container">
          <div class="prompt-content-row">

            <div class="message-container" *ngIf="messageList && messageList.length > 0; else content">
              <ng-container *ngIf="cachedHeaderText != ''; else singleLine">
                <div class="multipleMessageContainer cursor-pointer" (click)="toggleExpansion()">
                  <div class="expand-collapse-button">
                    <span class="sif" [ngClass]="isExpanded ? 'sif-chevron-s' : 'sif-chevron-e'"></span>
                  </div>
                  <span class="message-header" [innerHtml]="cachedHeaderText | sanitizeHtml"></span>
                </div>
              </ng-container>

              <ng-template #singleLine>
                <span [innerHtml]="messageList[0] | sanitizeHtml"></span>
              </ng-template>
            </div>
            <ng-template #content>
              <div class="message-container">
                <ng-content></ng-content>
              </div>
            </ng-template>
            <div class="action-container">
              <ng-content select="[actions]" *ngIf="secondary || hero || tertiary"></ng-content>
              <button mat-button color="secondary" small (click)="closePrompt()" class="close-btn" *ngIf="!hideDismiss">
                <span class="sif sif-close"></span>
              </button>
            </div>
          </div>
          <div class="prompt-content-row prompt-content-ul" *ngIf="isExpanded && cachedHeaderText != ''">
            <ul>
              <li *ngFor="let message of messageList">
                <span [innerHtml]="message | sanitizeHtml"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="hero" class="border-outer right">
        <div class="border-line right"></div>
      </div>
    </div>
    <div *ngIf="hero" class="border-outer bottom">
      <div class="border-line bottom"></div>
    </div>
  </div>
</ng-template>
