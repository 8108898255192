export enum InsightType {
  general = 'GENERAL',
  concept = 'CONCEPT',
  deliverable = 'DELIVERABLE',
  forecast= 'FORECAST'
}

export enum InsightOrientation {
  repeat = 'REPEAT',
  resize = 'RESIZETOFIT'
}

/**
 * Image Interface for Insight Model
 */
export interface Attachment {
  /**
   * Name of the image
   *
   * @type {string}
   * @memberOf Attachment
   */
  name: string;
  /**
   * Path of the image
   *
   * @type {string}
   * @memberOf Attachment
   */
  dataPath: string | ArrayBuffer;

  /**
   * Url to the image.
   * @type string
   * @memberOf Attachment
   */
  imageURL?: string;
}

/**
 * Insight Model
 *
 * @export
 * @interface Insight
 */
export interface Insight {
  /**
   * Type of Insight
   *
   * @type {string};
   * @memberof Insight
   */
  type: string;
  /**
   * Id of the Insight.
   *
   * @type {number}
   * @memberof Insight
   */
  id?: string;
  /**
  * Title of the Insight.
  *
  * @type {string}
  * @memberof Insight
  */
  title: string;
  /**
  * Description of the Insight.
  *
  * @type {string}
  * @memberof Insight
  */
  description: string;
  /**
  * index of the Insight
  *
  * @type {number}
  * @memberof Insight
  */
  position: number;
  /**
  * Height of the Insight
  *
  * @type {number}
  * @memberof Insight
  */
  height?: number;
  /**
  * Width of the Insight
  *
  * @type {number}
  * @memberof Insight
  */
  width: number;
  /**
  * Concepts Insight.
  *
  * @type {Array<string>}
  * @memberof Insight
  */
  concepts?: Array<string>;
  /**
  * Deliverable Insight.
  *
  * @type {Array<string>}
  * @memberof Insight
  */
  deliverable?: any;

  /**
  * Images source of the Insight
  *
  * @type {Array<Attachment>}
  * @memberof Insight
  */
  attachments?: Array<Attachment>;
  /**
  * Orientation of the Insight
  *
  * @type {string}
  * @memberof Insight
  */
  orientation?: string;
  /**
  * Last updated date and time
  *
  * @type {string}
  * @memberof Insight
  */
  lastUpdated?: string;
  /**
  * Report ID
  *
  * @type {string}
  * @memberof Insight
  */
  reportId: string;

  /**
   * True if this insight is editable. For legacy insights, data
   * for regenerating insights is not available so they are not
   * editable.
   */
  isEditable: boolean;

  /**
  * True if the insight is
  * in edit state currently
  *
  * @type {boolean}
  * @memberof Insight
  */
  isEditing?: boolean;
}
