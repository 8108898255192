import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import Quill from 'quill';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {DeliverableImage} from '@platform/deliverable-insight/deliverable-image.model';
import {Insight, InsightType} from '@platform/models/insight.model';
import {ToasterService} from '@platform/services/toaster.service';
import {HttpErrorResponse} from '@angular/common/http';
import {combineLatest, Subscription} from 'rxjs';
import {SpinnerService} from '@platform/services/spinner.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {UserService} from "@platform/services/user.service";

const icons = Quill.import('ui/icons');

@Component({
    selector: 'ns-deliverable-insight',
    templateUrl: './deliverable-insight.component.html',
    styleUrls: ['./deliverable-insight.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('slideDown', style({})),
            transition(':enter', [
                style({transform: 'translateY(-5%)'}),
                animate('.6s ease-in', style({transform: 'translateY(0%)'}))
            ]),
            transition(':leave', [
                animate('.3s ease-out', style({transform: 'translateY(-60%)'}))
            ])
        ])
    ]
})
export class DeliverableInsightComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * deliverable data from deliverable.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    @Input() deliverableData: DeliverableInsight;

    /**
     * If this component is enabled or disabled.
     * @memberOf {boolean} enabled
     */
    @Input() enabled = true;

    /**
     * close model from deliverable.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    @Output() close = new EventEmitter<boolean>();

    /**
     * display message from product.
     * @memberOf {string} displayMessage
     */
    @Input() displayMessage = '';

    /**
     * remove flyout class to fix the deliverables export.
     * @memberOf {boolean} removeFlyoutClass
     */
     @Input() removeFlyoutClass = false;

    /**
     * deliverable data from deliverable.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    @Input() isHTMLInsight = false;

    /**
     * insightHeader for deliverable insight.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    insightHeader: string;
    /**
     * insightDescription for deliverable insight.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    insightDescription: string;
    /**
     * show hide description box.
     *
     * @type {boolean}
     * @memberOf DeliverableInsightComponent
     */
    show: boolean;
    /**
     * title Border Class.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    titleBorderClass: string;
    /**
     * title Text Class.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    titleTextClass: string;
    /**
     * description Border Class.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    desBorderClass: string;
    /**
     * description Text Class.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    desTextClass: string;
    /**
     * description length
     *
     * @type {number}
     * @memberof DeliverableInsightComponent
     */
    descriptionLength: number;

    /**
     * All subscriptions created in the component.
     *
     * @private
     * @type {Array<Subscription>}
     * @memberOf DeliverableInsightComponent
     */
    private subscriptions: Array<Subscription>;
    /**
     * display progress spinner
     * @type {boolean} displayProgressSpinner
     * @memberOf DeliverableInsightComponent
     */
    public displayProgressSpinner = false;

    /**
     * display AH Headline header on enabled flag
     * @type {boolean} displayProgressSpinner
     * @memberOf DeliverableInsightComponent
     */
    public isAHFeatureflagActive: boolean = false;

    noticeText =[
        "Approaching text character limit",
        "Text character limit reached",
        "Text character limit exceeded",
        "Insight Headline is a required field",
        "*Enter headline text"
    ]

    constructor(
        private deliverableInsightService: DeliverableInsightService,
        private spinnerService: SpinnerService,
        private toasterService: ToasterService,
        private mixpanelService: MixpanelService,
        private userService: UserService
    ) {
        this.subscriptions = [];
        icons['bold'] = '<span class="sif sif-text-bold"></span>';
        icons['italic'] = '<span class="sif sif-text-italic"></span>';
        icons['underline'] = '<span class="sif sif-text-underline"></span>';
        icons['list'] = '<span class="sif sif-bullet-list-1"></span>';
    }

    /**
     * Quill editor config.
     *
     * @type {string}
     * @memberOf DeliverableInsightComponent
     */
    editorConfig = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline'],
                [{'list': 'bullet'}],
            ]
        }
    };

    ngOnInit(): void {
        const subscription = this.spinnerService.getSpinnerObs().subscribe(spinner => {
                this.displayProgressSpinner = spinner;
            }
        );
        this.subscriptions.push(combineLatest([this.userService.getUser()]).subscribe(([user]) => {
           this.isAHFeatureflagActive = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
        }));
        this.subscriptions.push(subscription);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['deliverableData']) {
            this.deliverableData = changes['deliverableData'].currentValue;
        }
        this.insightHeader = this.deliverableData?.title;
    }
    /**
     * Cleans the component objects on destroy event.
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * Toggle additional info.
     * @memberOf DeliverableInsightComponent
     */
    toggleInfo() {
        this.show = !this.show;
    }

    /**
     * Save the deliverable insights.
     * @param title: Insights header
     * @param insightDescription: Insights Additional Text
     * @memberOf DeliverableInsightComponent
     */
    saveView(title: string, insightDescription: string): void {
        this.spinnerService.setSpinnerObs();
        if (this.isHTMLInsight && this.deliverableData?.type !== 'FORECAST') {
            this.deliverableData.deliverable.insightHTML = this.deliverableData.deliverable.filter.deliverableViewType == 'SortingMatrix'
            ? this.deliverableInsightService.getInsightHTML(this.updateNodeForSortingMatrix)
            : this.deliverableInsightService.getInsightHTML();
        }
        const deliverableData: DeliverableInsight = this.deliverableData;
        const insight: DeliverableInsight = {
            title: title,
            type: deliverableData.type ? deliverableData.type : InsightType.deliverable,
            description: insightDescription,
            deliverable: deliverableData.deliverable,
        };
        if (deliverableData.selectors) {
            const htmlSrc = this.deliverableInsightService.getCleanHTML(this.removeFlyoutClass);
            insight.image = {
                imageSelectors: deliverableData.selectors,
                sourceHTML: htmlSrc,
                host: window.location.origin
            } as DeliverableImage;
        }
        this.deliverableInsightService.createInsight(insight as DeliverableInsight).subscribe(
            (responseInsight: Insight) => {
                this.spinnerService.spinnerObs$.next(false);
                console.log(`Insight created successfully with id: ${responseInsight.id}`);
                this.toasterService.openSnackBar('Insight Created successfully', 'success');
                const deliverableLabel = this.mixpanelService.getCurrentFeatureLabel();
                this.mixpanelService.track(deliverableLabel, MixpanelEvent.createInsight);
            },
            (err: HttpErrorResponse) => {
                this.spinnerService.spinnerObs$.next(false);
                console.log(`Failed to create insight with error: ${err.message}`);
                this.toasterService.openSnackBar('Error creating Insights', 'error');
            });
        this.disableForm();
    }

    /*
    * updates insightHTML node by adding <style>
    */
    updateNodeForSortingMatrix(node) {
        // changing the style of SM chart to fix chart not rendering completely - S2-914
        node.getElementsByClassName('pr-10')[0].classList.add('p-0');
        // appending style tag as node's firstChildElement - to fix style issues of sorting matrix & attributes DOM - S2-910
        node.insertAdjacentHTML('afterbegin', `<style>.mat-sort-header-container{display:flex;cursor:pointer;align-items:center}.mat-sort-header-disabled .mat-sort-header-container{cursor:default}.mat-sort-header-position-before{flex-direction:row-reverse}.mat-sort-header-button{border:none;background:0 0;display:flex;align-items:center;padding:0;cursor:inherit;outline:0;font:inherit;color:currentColor;position:relative}[mat-sort-header].cdk-keyboard-focused .mat-sort-header-button,[mat-sort-header].cdk-program-focused .mat-sort-header-button{border-bottom:solid 1px currentColor}.mat-sort-header-button::-moz-focus-inner{border:0}.mat-sort-header-arrow{height:12px;width:12px;min-width:12px;position:relative;display:flex;opacity:0}.mat-sort-header-arrow,[dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow{margin:0 0 0 6px}.mat-sort-header-position-before .mat-sort-header-arrow,[dir=rtl] .mat-sort-header-arrow{margin:0 6px 0 0}.mat-sort-header-stem{background:currentColor;height:10px;width:2px;margin:auto;display:flex;align-items:center}.cdk-high-contrast-active .mat-sort-header-stem{width:0;border-left:solid 2px}.mat-sort-header-indicator{width:100%;height:2px;display:flex;align-items:center;position:absolute;top:0;left:0}.mat-sort-header-pointer-middle{margin:auto;height:2px;width:2px;background:currentColor;transform:rotate(45deg)}.cdk-high-contrast-active .mat-sort-header-pointer-middle{width:0;height:0;border-top:solid 2px;border-left:solid 2px}.mat-sort-header-pointer-left,.mat-sort-header-pointer-right{background:currentColor;width:6px;height:2px;position:absolute;top:0}.cdk-high-contrast-active .mat-sort-header-pointer-left,.cdk-high-contrast-active .mat-sort-header-pointer-right{width:0;height:0;border-left:solid 6px;border-top:solid 2px}.mat-sort-header-pointer-left{transform-origin:right;left:0}.mat-sort-header-pointer-right{transform-origin:left;right:0}
        </style>`);
    }

    /**
     * Triggered when title input is changed/added
     *
     * @memberOf DeliverableInsightComponent
     */
    validateTitle() {
        const title = this.insightHeader ? this.insightHeader.length : '';
        switch (true) {
            case (title > 80):
                this.titleBorderClass = 'max-text';
                this.titleTextClass = 'red-color';
                break;
            case (title > 39):
                this.titleBorderClass = 'min-text';
                this.titleTextClass = 'yellow-color';
                break;
            case (title > 0):
                this.titleBorderClass = 'highlight-border';
                this.titleTextClass = 'blue-color';
                break;
            default:
                this.titleBorderClass = '';
                this.titleTextClass = '';
        }
    }

    /**
     * Triggered when description of insight is changed/added
     *
     * @memberof DeliverableInsightComponent
     */
    validateDescription() {
        const text = this.getPlainText(this.insightDescription);
        this.descriptionLength = text.trim().length;
        switch (true) {
            case (this.descriptionLength > 215):
                this.desBorderClass = 'max-text';
                this.desTextClass = 'red-color';
                break;
            case (this.descriptionLength > 99):
                this.desBorderClass = 'min-text';
                this.desTextClass = 'yellow-color';
                break;
            case (this.descriptionLength > 0):
                this.desBorderClass = 'highlight-border';
                this.desTextClass = 'blue-color';
                break;
            default:
                this.desBorderClass = '';
                this.desTextClass = '';
        }
    }

    /**
     * Takes html content and returns string value
     *
     * @param text {string}
     * @returns string
     */
    getPlainText(text: string): string {
        if (text && text.length) {
            return text.replace(/(<([^>]+)>)/ig, '');
        } else {
            return '';
        }
    }

    /**
     * Disable insight creation Form.
     *
     * @memberOf DeliverableInsightComponent
     */
    disableForm() {
        this.show = false;
        this.insightHeader = '';
        this.insightDescription = '';
        this.close.emit(false);
        this.validateTitle();
        this.validateDescription();
    }

}
