import {Concept} from '@platform/models/concept.model';
import {Deliverable} from '@platform/models/deliverable.model';
import {
    FilterItem,
    StrengthWatchoutsFilter
} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {Report} from '@platform/models/report.model';
import {FilterService} from '@platform/services/filter.service';
import {StrengthWatchoutsService} from '../strength-watchouts.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReportService} from '@platform/services/report.service';
import {Subscription} from 'rxjs';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {TranslateService} from '@ngx-translate/core';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';

/**
 * `FilterComponent` creates the attributes filter options like compare,
 * concepts, country, subgroups, and show filter.
 *
 * @example
 *   <qs-strength-watchouts-filter></qs-strength-watchouts-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'strength-watchouts-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  /**
   * Set the concept to set single concept filter.
   *
   * @memberof FilterComponent
   */
  @Input() concept: Concept;

  /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberof FilterComponent
   */
  private subscriptions: Array<Subscription>;

  public filter: StrengthWatchoutsFilter;

  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public conceptsListData: DropdownData<string>;

  /**
   * List of subgroup dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public subgroupListData: DropdownData<string>;

  /**
   * List of deliverable view dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public compareListData: DropdownData<string>;

  /**
   * List of countries on the report.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public countryListData: DropdownData<string>;

  public disableConcepts = false;

  public disableSubgroups = false;
  /**
   * Mixpanel label for the deliverable
   *
   * @property
   * @private
   * @type {string}
   * @memberof FilterComponent
   */
  private deliverableLabel: string;

  /**
   * Creates an instance of FilterComponent.
   *
   * @constructor
   * @param {StrengthWatchoutsService} strengthWatchoutsService
   * @param {FilterService} filterService
   * @param {ReportService} reportService
   * @param {TranslateService} translate
   * @param {MixpanelService} mixpanelService
   * @memberof FilterComponent
   */
  constructor(
    private strengthWatchoutsService: StrengthWatchoutsService,
    private filterService: FilterService,
    private reportService: ReportService,
    private translate: TranslateService,
    private mixpanelService: MixpanelService
  ) {
    this.subscriptions = [];
  }

  /**
   * Initialize the filter component. Sets filter and report object
   * for the template.
   *
   * @memberof FilterComponent
   */
  ngOnInit(): void {
    this.deliverableLabel = this.concept ? MixpanelLabel.concepts : MixpanelLabel.strengthsWatchouts;
    const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
    const subscription = filter$.subscribe(filter => {
      this.filter = filter;
      this.setFilters(filter);
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Sets the filter options for concepts, subgroups, compareList, countries.
   *
   * @private
   * @param {StrengthWatchoutsFilter} filter
   * @param {Report} report
   * @memberof FilterComponent
   */
  private setFilters(filter: StrengthWatchoutsFilter): void {
    if (filter.compare.find(option => option.isSelected).id === 'elementEngagements') {
          this.disableConcepts = true;
          this.disableSubgroups = true;
    } else {
          this.disableConcepts = false;
          this.disableSubgroups = false;
    }
    this.conceptsListData = this.getConceptFilterOptions(filter.concepts);
    this.subgroupListData = this.getSubGroupFilterOptions(filter.subgroups);
    this.compareListData = this.getCompareOptions(filter);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
   * Returns the compare filter options.
   *
   * @private
   * @param {Array<Deliverable>} deliverables
   * @param {StrengthWatchoutsFilter} filter
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getCompareOptions(filter: StrengthWatchoutsFilter): DropdownData<string> {
    const compareListItemData: Array<DropdownItem<string>> = [];

    filter.compare.forEach((option: FilterItem) => {
        compareListItemData.push(
          {
            value: option.id.toString(),
            label: option.name,
            selected: option.isSelected
          }
        );

    });

    const compareList: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.compare.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.compare.itemsTitle'),
      data: compareListItemData
    };

    return compareList;
  }

  /**
   * Returns the country filter options.
   *
   * @private
   * @param {string} country
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }

  /**
   * Returns Concepts filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getConceptFilterOptions(items: Array<FilterItem>): DropdownData<string> {
    const dropDownData: DropdownItem<string>[] = items.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: this.disableConcepts? true:item.isSelected
      };
    });
    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.concepts.label'),
      dataType: this.disableConcepts?DataType.CHECKBOX:DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.concepts.itemsTitle'),
      groupSelect: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.concepts.allSelectedLabel'),
      data: dropDownData
    };
    return dropdownItems;
  }

  /**
   * Returns Subgroup filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getSubGroupFilterOptions(items: Array<FilterItem>): DropdownData<string> {
    const dropDownData: DropdownItem<string>[] = items.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });
    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.subgroups.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.strengthsWatchouts.filter.subgroups.itemsTitle'),
      data: dropDownData
    };
    return dropdownItems;
  }

  /**
   * Sets the new filter entries when changes are made to compare drop down.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>}compare The list of compare options.
   */
  selectCompare(compare: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    const selectedCompare: DropdownItem<string> = compare.find((option: DropdownItem<string>) => {
      return option.selected;
    });
    this.filterService.update({
      ...this.filter,
      deliverableViewType: selectedCompare.value.toString(),
      compare: this.filter.compare.map((item: FilterItem) => {
        return {
          ...item,
          isSelected: compare.find(option => option.value === item.id).selected
        };
      })
    });
  }

  /**
   * Event listener for concept selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} concepts
   * @memberof FilterComponent
   */
  selectConcept(concepts: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: concepts.find(selectedConcept => selectedConcept.value === concept.name).selected
        };
      })
    });
  }

  /**
   * Event listener for subgroup selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} subgroups
   * @memberof FilterComponent
   */
  selectSubgroup(subgroups: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
        };
      })
    });
  }

  /**
   * Emits event of the selected filter menu item.
   */
  selectedEvent(event): void {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

  /**
   * Cleanup the component on removing from the UI.
   *
   * @memberof FilterComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
