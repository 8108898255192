<ng-container *ngIf="oldView">
<!--  <div class="sticky-swipe-table-container">-->
<!--    <div class="swipe-container">-->
<!--      <ns-swipe-table class="pull-right" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"></ns-swipe-table>-->
<!--    </div>-->
<!--  </div>-->

  <div *ngIf="oldView" class="tga-table-container tga-old-table-container deliverable-insight-attributes insight-html-root carousel-container">
    <ns-carousel-table class="carousel-panel" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"
                       (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>

    <table id="tga-old-table" class="factors-new-tga col-12 highlighted-row-container deliverable-insight-attributes-table" mat-table matSortStart="asc" matSortDisableClear matSort [dataSource]="dataSource">
      <!--Create dynamic columns from colHeaders-->
      <ng-container *ngFor="let colHeader of colHeaders; let i = index; trackBy: trackItem" matColumnDef="{{colHeader.name}}">
        <ng-container *ngIf="!colHeader.id">
          <th mat-header-cell *matHeaderCellDef disabled="true" class="statement-header deliverable-insight-attributes-table-head"></th>
          <td mat-cell *matCellDef="let element" class=" deliverable-insight-attributes-table-data"><span class="answer-text">{{element.statement}}</span></td>
        </ng-container>
        <ng-container *ngIf="colHeader.id">
          <th mat-header-cell [width]="200" *matHeaderCellDef class="padding-0 text-center deliverable-insight-attributes-table-head" >
            <ns-target-group-header [element]="colHeader"></ns-target-group-header>
          </th>
          <td mat-cell *matCellDef="let element" class="padding-0 text-center deliverable-insight-attributes-table-data">
            <ns-target-group-label *ngIf="element[colHeader.name]" [element]="element[colHeader.name]"></ns-target-group-label>
          </td>
        </ng-container>
      </ng-container>
      <tr class="deliverable-insight-attributes-table-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="answer-row deliverable-insight-attributes-table-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <!--Footer legend-->
  <div class="row">
    <ns-target-group-legend class="col-12 legend-container"></ns-target-group-legend>
  </div>
</ng-container>
