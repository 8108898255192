<div class="sticky-container facet-container reach-filters" *ngIf="filter">
  <div class="air-filters-bar clearfix">
    <div class="float-left">
      <ng-container *ngIf="!concept">
        <ns-dropdown id="compareFilter"
          class="filter-item"
          [dropDownData]="compareListData"
          (selectionChange) = "selectCompare($event.data)">
        </ns-dropdown>
        <ns-dropdown id="conceptsFilter"
          class="filter-item"
          [dropDownData]="conceptsListData"
          (selectionChange) = "selectConcept($event.data)">
        </ns-dropdown>
        <ns-dropdown id="countryFilter"
          class="filter-item"
          [dropDownData]="countryListData"
          (selectionChange) = "selectedEvent($event)">
        </ns-dropdown>
        <ns-dropdown id="subgroupFilter"
          class="filter-item"
          [dropDownData]="subgroupListData"
          (selectionChange) = "selectSubgroup($event.data)">
        </ns-dropdown>
      </ng-container>
      <ns-variety-filter id="varietyFilter" class="filter-item" [disable] = "compareView!='alternative-combination' " [dropDownData]="varietiesListData" (selectionChange) = "selectVarieties($event.data)" [deliverableLabel]="deliverableLabel"></ns-variety-filter>
      <ns-reach-analysis-show id="showFilter" class="filter-item" [deliverableLabel]="deliverableLabel"></ns-reach-analysis-show>
    </div>
  </div>
</div>
