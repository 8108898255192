import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '@platform/services/filter.service';
import { DataType, DropdownData, DropdownItem } from '@products/shared/dropdown/dropdown.data.model';
import { combineLatest, Subscription } from 'rxjs';
import { InteractionsFilter } from '../models/filter.model';
import { InteractionsService } from '../services/interactions.service';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';

@Component({
  selector: 'ns-interactions-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  /**
   * Interactions filter object.
   *
   * @property
   * @type {InteractionsFilter}
   * @memberof FilterComponent
   */
  public filter: InteractionsFilter;

  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public conceptsListData: DropdownData<string>;
  /**
     * List of concept dropdown items.
     *
     * @property
     * @type {Array<DropdownData>}
     * @memberof FilterComponent
     */
  public compConceptsListData: DropdownData<string>;
  /**
     * List of concept dropdown items.
     *
     * @property
     * @type {Array<DropdownData>}
     * @memberof FilterComponent
     */
  public regConceptsListData: DropdownData<string>;

  /**
   * List of countries on the report.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public countryListData: DropdownData<string>;

  /**
  * List of subgroup dropdown items.
  *
  * @property
  * @type {DropdownData}
  * @memberof FilterComponent
  */
  public subgroupListData: DropdownData<string>;

  /**
  * Array of subscriptions for cleanup.
  *
  * @property
  * @private
  * @type {Array<Subscription>}
  * @memberof FilterComponent
  */
  private subscriptions: Array<Subscription>;
  /**
   * Mixpanel label for the deliverable
   *
   * @property
   * @private
   * @type {string}
   * @memberof FilterComponent
   */
  private deliverableLabel: string;


  constructor(
    private interactionsService: InteractionsService,
    private filterService: FilterService,
    private translate: TranslateService,
    private mixpanelService: MixpanelService
  ) {
    this.subscriptions = [];
  }

  private conceptIds: Array<number>;
  private competitorIds: Array<number>;

  ngOnInit(): void {
    this.deliverableLabel = MixpanelLabel.interactions;
    const filter$ = this.interactionsService.getInteractionsFilter();
    const subscription = combineLatest([filter$, this.interactionsService.getConceptsForFilter()]).subscribe(([filter, [concepts, competitors]]) => {
      this.filter = filter;
      this.conceptIds = concepts;
      this.competitorIds = competitors;
      this.setFilters(this.filter);
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Event listener for concept selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} concepts
   * @memberof FilterComponent
   */
  selectConcept(allConcepts: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: (allConcepts.find(selectedConcept => selectedConcept.value === concept.name)?.selected)??concept.isSelected
        };
      })
    });
  }

  /**
   * Event listener for subgroup selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} subgroups
   * @memberof FilterComponent
   */
  selectSubgroup(subgroups: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
        };
      })
    });
  }

  /**
   * Sets the filter options for concepts, countries, subgroups.
   *
   * @private
   * @param {InteractionsFilter} filter
   * @param {Report} report
   * @memberof FilterComponent
   */
  private setFilters(filter: InteractionsFilter): void {
    [this.compConceptsListData, this.regConceptsListData] = this.getConceptFilterOptions(filter);
    this.subgroupListData = this.getSubGroupFilterOptions(filter);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
   * Returns restage and competitor related concepts filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getConceptFilterOptions(filter: InteractionsFilter): Array<DropdownData<string>> {
    const compDropdownData: DropdownItem<string>[] = filter.concepts.filter(fConcept => this.competitorIds.includes(fConcept.id)).map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });
    const regDropdownData: DropdownItem<string>[] = filter.concepts.filter(fConcept => this.conceptIds.includes(fConcept.id)).map(item => {
        return {
            value: item.name,
            label: item.name,
            selected: item.isSelected
        };
    });
    const compDropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('restage.deliverable.interactions.filter.competitors.label'),
      dataType: DataType.CHECKBOX,
      contentTitle: this.translate.instant('restage.deliverable.interactions.filter.competitors.itemsTitle'),
      groupSelect: this.translate.instant('restage.deliverable.interactions.filter.competitors.allSelectedLabel'),
      data: compDropdownData
    };
    const regDropdownItems: DropdownData<string> = {
        dropdownLabel: this.translate.instant('restage.deliverable.interactions.filter.concepts.label'),
        dataType: DataType.CHECKBOX,
        contentTitle: this.translate.instant('restage.deliverable.interactions.filter.concepts.itemsTitle'),
        groupSelect: this.translate.instant('restage.deliverable.interactions.filter.concepts.allSelectedLabel'),
        data: regDropdownData
      };

    return [compDropdownItems, regDropdownItems];
  }

  /**
   * Returns sub groups filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getSubGroupFilterOptions(filter: InteractionsFilter): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('restage.deliverable.interactions.filter.subgroups.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('restage.deliverable.interactions.filter.subgroups.itemsTitle'),
      groupSelect: this.translate.instant('restage.deliverable.interactions.filter.subgroups.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns the country filter options.
     *
   * @private
   * @param {string} country
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('restage.deliverable.interactions.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('restage.deliverable.interactions.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }

  /**
   * Cleanup the component on removing from the UI.
   *
   * @memberof FilterComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


}
