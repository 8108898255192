import { RouterUrlState } from '@platform/store/state/router.state';
import { State } from '@platform/store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as Router from '@ngrx/router-store';

/**
 * Router feature selector.
 *
 * @export
 * @property
*/
export const selectRouterFeature = createFeatureSelector< Router.RouterReducerState<RouterUrlState>>('router');
/**
 * Router selector.
 *
 * @export
 * @property
*/
export const selectRouter = createSelector(
  selectRouterFeature,
  (state: Router.RouterReducerState<RouterUrlState>) => state.state
);
