import { createAction, props } from '@ngrx/store';
import { Concept } from '@platform/models/concept.model';

/**
 * Action for adding a concept.
 * 
 * @export
 */
export const addConcept = createAction(
  '[Concept] Add Concept',
  props<{ concept: Concept }>()
);

/**
 * Action for an array concepts.
 * 
 * @export
 */
export const addConcepts = createAction(
  '[Concept] Add Concepts',
  props<{ concepts: Concept[] }>()
);

/**
 * Action for updating a concept.
 * 
 * @export
 */
export const updateConcept = createAction(
  '[Concept] Update Concept',
  props<{ concept: Concept }>()
);

/**
 * Action for Deleting a concept from store.
 * 
 * @export
 */
export const deleteConcept = createAction(
  '[Concept] Delete Concept',
  props<{ id: string }>()
);
