import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DeliverableInsight } from '@platform/deliverable-insight/deliverable-insight.model';
import { DeliverableView } from '@platform/models/deliverable-view.model';
import { DeliverableInsightService } from '@platform/services/deliverable-insight.service';
import { ExportPngService } from '@platform/services/export-png.service';
import { SpinnerService } from '@platform/services/spinner.service';
import { UserService } from '@platform/services/user.service';
import { ViewMetaInfoService } from '@platform/services/view-meta-info.service';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import { DeliverableType } from '../deliverable-type.enum';
import { InteractionsFilter } from './models/filter.model';
import { InteractionsMetaInfo } from './models/interactions-view-meta-info.model';
import { InteractionsDeliverableView } from './models/interactions.model';
import { InteractionsChartDataService } from './services/interactions-chart-data.service';
import { InteractionsService } from './services/interactions.service';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent } from '@src/assets/utils/mixpanel-enum';
import {DeliverableInfo} from "@platform/models/deliverable-info.model";
import {UserView} from "@platform/models/user-view.model";
import {SubgroupService} from "@platform/services/subgroup.service";
import {RouterService} from "@platform/services/router.service";
import {DeliverableInfoService} from "@platform/services/deliverable-info.service";
import {UserViewService} from "@platform/services/user-view.service";
import {InsightService} from "@platform/insights/insights.service";
import {ReportService} from "@platform/services/report.service";
import {DeliverableViewService} from "@platform/services/deliverable-view.service";
import {Report} from "@platform/models/report.model";
import {FilterService} from "@platform/services/filter.service";
import {TranslateService} from '@ngx-translate/core';
import {Concept} from '@platform/models/concept.model';

/**
 * `<ns-interactions>` component builds interactions deliverable for both
 * concepts and subgroups deliverable views.
 *
 * @example
 * <ns-interactions></ns-interactions>
 *
 * @export
 * @class InteractionsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss']
})
export class InteractionsComponent implements OnInit, OnDestroy {

  public report: Report;
  public deliverableInfos: Array<DeliverableInfo>;
  public userViews: Array<UserView>;
  public deliverableType = DeliverableType.INTERACTIONS.type;
  public deliverableViews: Array<DeliverableView>;


  /**
   * Interactions deliverable view data.
   *
   * @type {InteractionsDeliverableView}
   * @member InteractionsComponent
   */
  public interactionsDeliverableView: DeliverableView;

  /**
  * Interactions deliverable view filter object.
  *
  * @type {InteractionsFilter}
  * @member InteractionsComponent
  */
  public filter: InteractionsFilter;

  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member InteractionsComponent
   */
  public subscriptions: Array<Subscription>;

  /**
   * Datasource object for the angular material table.
   *
   * @member InteractionsComponent
   */
  public dataSource: MatTableDataSource<any>;

  /**
   * Array of displayed columns keys.
   *
   * @type {Array<string>}
   * @member InteractionsComponent
   */
  public displayedColumns: Array<string>;

  /**
   * toggle insight btn
   * @type {Boolean} isInsightEnable
   * @member InteractionsComponent
   */
  public isInsightEnable = false;

  /**
   * The deliverable insight data when creating insight.
   * @type {DeliverableInsight} deliverableData
   * @member InteractionsComponent
   */
  public deliverableData: DeliverableInsight;

  /**
   * All column headers for the Interactions table.
   *
   * @type {Array<any>}
   * @member InteractionsComponent
   */
  public colHeaders: Array<any>;

  /**
   * Spinner.
   *
   * @type {Boolean}
   * @member InteractionsComponent
   */
  public displayProgressSpinner = false;

  /**
   * Disable Insights Button.
   *
   * @type {Boolean}
   * @member InteractionsComponent
   */
  public disableBtn: boolean;

  /**
   * Meta info for Interactions
   *
   * @type {InteractionsDeliverableView}
   * @memberOf InteractionsComponent
   */
  public viewInteractionsMetaInfo: InteractionsMetaInfo;

  /**
   * Internal User
   *
   * @type {Boolean}
   * @member InteractionsComponent
   */
  public isInternalUser: Boolean;

  /**
   * View child for angular material table sorting.
   *
   * @type {MatSort}
   * @member InteractionsComponent
   */
  @ViewChild(MatSort) sort: MatSort;

  /**
   * Display bar chart.
   *
   * @type {Boolean}
   * @member InteractionsComponent
   */
  public displayBarchart: Boolean = false;

  /**
  * View children for angular material table header cell.
  *
  * @type {MatSort}
  * @member InteractionsComponent
  */
  @ViewChildren('columnHeader') columnHeaders: QueryList<any>;

  /**
   * Feature FLAG for Automatic Headlines.
   *
   * @type {Boolean}
   */
  public isAutomatedHeadlinesEnabled: boolean;
  public addHTMLToInsight = true;

    /**
     * ScoreCard Concept object for factors.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;



  /**
   * Creates an instance of InteractionsComponent and initialize
   * the component data.
   *
   * @constructor
   * @param {InteractionsService} interactionsService
   * @param exportPNGService
   * @param spinnerService
   * @param deliverableInsightService
   * @param userService
   * @param interactionsChartDataService
   * @param ViewMetaInfoService
   * @param  {MixpanelService} mixpanelService
   * @member InteractionsComponent
   */

  constructor(
    private deliverableInsightService: DeliverableInsightService,
    private exportPNGService: ExportPngService,
    private spinnerService: SpinnerService,
    private viewMetaInfoService: ViewMetaInfoService,
    private userService: UserService,
    private interactionsService: InteractionsService,
    private interactionsChartDataService: InteractionsChartDataService,
    private cdr: ChangeDetectorRef,
    private mixpanelService: MixpanelService,
    private subgroupService: SubgroupService,
    private routeService: RouterService,
    private deliverableInfoService: DeliverableInfoService,
    private userViewService: UserViewService,
    private insightService: InsightService,
    private reportService: ReportService,
    private deliverableViewService: DeliverableViewService,
    private filterService: FilterService,
    private translate: TranslateService,
  ) {
    this.displayedColumns = [];
    this.subscriptions = [];
    this.userViews = [];
  }

  ngOnInit(): void {
    const insightId = this.routeService.getQueryParam('insightId');
    const deliverableType = DeliverableType.INTERACTIONS.type;
    const viewMetaInfo$ = this.viewMetaInfoService.get<InteractionsMetaInfo>(deliverableType);
      const subscription = combineLatest([
          this.reportService.get(),
          this.userService.getUser(),
          this.deliverableViewService.getDeliverableViews(DeliverableType.INTERACTIONS.type),
      ]).subscribe(([report, user, deliverableViews]) => {
          this.report = report;
          this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
          this.isInternalUser = user.isInternalUser;
          this.deliverableViews = deliverableViews;
          this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
          forkJoin([
              this.userViewService.fetchReportUserViewsFromAPI(report.id),
              this.insightService.getInsightFilterData<InteractionsFilter>(report.id, insightId),
              this.interactionsService.loadDefaultFilter(this.deliverableViews)
          ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
              this.userViews = this.userViewService.setupUserViews(this.report.id, deliverableType, userViews, defaultViewFilters, insightFilter);
              const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
              this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
              /**
               * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
               * */
              this.subscriptions.push(combineLatest([
                  this.interactionsService.getInteractionsFilter(),
                  this.interactionsService.getInteractions(),
                  this.subgroupService.getReportSubgroups(),
                  viewMetaInfo$
              ]).subscribe(([filters, interactions, subgroups, viewMetaInfo]) => {
                  this.interactionsDeliverableView = interactions;
                  this.filter = filters;
                  this.viewInteractionsMetaInfo = viewMetaInfo;
                  const [colHeaders, chartData] = this.interactionsChartDataService.getChartData(filters, interactions);
                  this.dataSource = (Object.keys(viewMetaInfo).length > 1) ? this.columnSortingOnSavedView(chartData, viewMetaInfo) : this.columnSorting(chartData, colHeaders);
                  this.displayedColumns = this.getColHeaders(colHeaders);
                  this.colHeaders = colHeaders;
                  this.modifyBarChartData(chartData);
                  this.disableInsightButton(interactions);
                  this.cdr.detectChanges();
                  this.deliverableData = {
                      title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Interactions') : '',
                      deliverable: {
                          deliverableViewId: this.interactionsDeliverableView.id,
                          filter: this.filter,
                          metaInfo: this.viewInteractionsMetaInfo
                      }
                  };
              }));
          });
      });
    this.subscriptions.push(subscription);
  }

  /**
   * display Columns must have the same order as colHeaders
   * @param colHeaders
   * @private
   */
  private getColHeaders(colHeaders: any[]) {
    const columns = [];
    colHeaders.forEach((element) => {
      columns.push(element.name);
    });
    return columns;
  }

  /**
   * disables insight button if no competitor or restage concept is selected
   * @param colHeaders
   * @private
   */
   private disableInsightButton(interactions: InteractionsDeliverableView) {
    interactions.subgroups[0].competitors.length === 0 ? this.disableBtn = true : this.disableBtn = false;
    interactions.subgroups[0].competitors.every(competitor => competitor.shareIndices.length === 0) ? this.disableBtn = true : this.disableBtn = false;
  }

  /**
   * modifies bar chart data
   * @param chartData
   */
  private modifyBarChartData(chartData: any) {
    if (this.columnHeaders) {
      const subscription = this.columnHeaders.changes.subscribe(header => {
        this.dataSource.data = this.setBarChartWidth(chartData);
        this.cdr.detectChanges();
        this.displayBarchart = true;
      });
      if (this.columnHeaders.first) {
        this.dataSource.data = this.setBarChartWidth(chartData);
      }
      this.displayBarchart = true;
      this.subscriptions.push(subscription);
    }
  }

  /**
   * sets the bar chart width and max range from the column header's cell width
   * @param chartData
   */
  private setBarChartWidth(chartData: any) {
    this.displayBarchart = false;
    const modifiedChartData = chartData.map(data => {
      for (const key in data) {
        if (this.columnHeaders.first && key !== 'competitor') {
          data[key].barChart.options.width = this.columnHeaders.first._elementRef.nativeElement.offsetWidth - 20;
          data[key].barChart.options.bar.range.max = this.columnHeaders.first._elementRef.nativeElement.offsetWidth - 40;
        }
      }
      return data;
    });
    return modifiedChartData;
  }

  /**
   * Default sorting behavior if there is no view meta info
   * Default sorted by  first row in Interactions deliverable
   * @param chartData
   * @param colHeaders
   */
  private columnSorting(chartData: any, colHeaders: any) {
    const dataSource = new MatTableDataSource(chartData);
    dataSource.sortingDataAccessor = this.matSortingDataAccessor;
    if (colHeaders[1] !== undefined && this.sort) {
      const sortState: Sort = { active: colHeaders[1].name, direction: '' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
    if (this.sort) {
      dataSource.sort = this.sort;
    }
    return dataSource;
  }

  /**
   * Upon navigation to saved view sort the data based on existing view meta info
   * @param chartData
   * @param viewMetaInfo
   */
  private columnSortingOnSavedView(chartData: any, viewMetaInfo: InteractionsMetaInfo) {
    const dataSource = new MatTableDataSource(chartData);
    dataSource.sortingDataAccessor = this.matSortingDataAccessor;
    if (Object.keys(viewMetaInfo).length > 1 && viewMetaInfo.sortInfo && Object.keys(viewMetaInfo.sortInfo).length > 0) {
      const sortState: Sort = { active: viewMetaInfo.sortInfo.columnHeaderName, direction: viewMetaInfo.sortInfo.sortDirection };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      dataSource.sort = this.sort;
    }
    return dataSource;
  }

  /**
   * Angular material sorting data accessor function for interactions.
   *
   * @param {*} data
   * @param {string} sortHeaderId
   * @returns {(string | number)}
   * @member InteractionsComponent
   */
  public matSortingDataAccessor(data: any, sortHeaderId: string): string | number {
    const cellData = data[sortHeaderId];
    if (!cellData) {
      return 0;
    }
    return cellData.fairShareIndexExactValue;
  }

  /**
   * on Click of Column headers for sorting
   * @param event
   * @param colHeaderId
   * @param colHeaderName
   */
  onClickHeader(event: MouseEvent, colHeaderId: any, colHeaderName: string) {
    event.preventDefault();
    this.setUserViewSorting(colHeaderId, colHeaderName);
  }

  /**
   * Updates the sorting info in the ViewMetaInfo store
   * @param colHeaderId
   * @param colHeaderName
   */
  setUserViewSorting(colHeaderId: any, colHeaderName: string) {
    this.mixpanelService.track(MixpanelLabel.interactions, MixpanelEvent.tableSort);
    const deliverableType = DeliverableType.INTERACTIONS.type;
    const viewInfo = { deliverableType, sortInfo: {} };
    viewInfo.sortInfo['columnHeaderId'] = colHeaderId;
    viewInfo.sortInfo['columnHeaderName'] = colHeaderName;
    viewInfo.sortInfo['sortDirection'] = this.sort.direction;
    this.viewMetaInfoService.update(viewInfo, deliverableType);
  }

    getSortOrder(headerName: string) {
        let sortOrder = this.translate.instant('sort.lowest.to.highest');
        if (this.sort && this.sort.active === headerName) {
            if (this.sort.direction === 'asc') {
                sortOrder = this.translate.instant('sort.highest.to.lowest');
            } else if (this.sort.direction === 'desc') {
                sortOrder = this.translate.instant('sort.reset.to.default.order');
            }
        }
        return sortOrder;
    }

  /**
   * Returns unique id for the loop to be refreshed.
   *
   * @param {number} index the interactions table column for loop index
   * @param {any} item the colHeader object
   */
  public trackItem(index: number, item: any): string {
    return `${index}-${item.id ? item.id : 0}`;
  }

  /**
   * Set Views
   * @param evt { String }
   */
  setViews(evt: any) {
    /**
     * ToDo:
     * Create a data model for views which has all details (sorting, highlights, filters, Insights ... )
     * Store the view in the DB
     * Create a Default View (set to defaults)
     * Set the view data on load
     */
    console.log(evt);
  }

  /**
   * capture screen layout and export as png.
   *
   */
  exportAsPNG() {
    this.mixpanelService.track(MixpanelLabel.interactions, MixpanelEvent.exportAsPNG);
    this.displayProgressSpinner = true;
    this.exportPNGService.exportPNG();
    this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
  }

  /**
   * toggle between headers and insight creation form.
   *
   */
  openInsightCreationForm() {
    this.isInsightEnable = true;
  }

  /**
   * Close insight form
   */
  closeInsight() {
    this.isInsightEnable = false;
  }
  /**
  * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
  * */
  selectUserView(userView: UserView): void {
              this.filter = userView.filter as InteractionsFilter;
              this.filterService.update(userView.filter);
  }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }


    /**
   * Cleanup hook.
   *
   * @member InteractionsComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
