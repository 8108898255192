/**
 * Factors for success mapping
 * @export
 * @property
 */
export const FactorsMapping = {

  DISTINCT_PROPOSITION: {
    id: 'distinctProposition',
    code: 'DP',
    factorText: 'quick.predict.deliverables.factors.label.distinct.proposition'
  },
  ATTENTION_CATCHING: {
    id: 'attentionCatching',
    code: 'AC',
    factorText: 'quick.predict.deliverables.factors.label.attention.catching'
  },
  MESSAGE_CONNECTION: {
    id: 'messageConnection',
    code: 'MC',
    factorText: 'quick.predict.deliverables.factors.label.message.connection'
  },
  CLEAR_CONCISE_MESSAGE: {
    id: 'clearConciseMessage',
    code: 'CM',
    factorText: 'quick.predict.deliverables.factors.label.clear.concise.message'
  },
  NEED_DESIRE: {
    id: 'needDesire',
    code: 'ND',
    factorText: 'quick.predict.deliverables.factors.label.need.desire'
  },
  DESIRE: {
    id: 'desire',
    code: 'D',
    factorText: 'quick.predict.deliverables.factors.label.desire'
  },
  ADVANTAGE: {
    id: 'advantage',
    code: 'Ad',
    factorText: 'quick.predict.deliverables.factors.label.advantage'
  },
  CREDIBILITY: {
    id: 'credibility',
    code: 'Cr',
    factorText: 'quick.predict.deliverables.factors.label.credibility'
  },
  ACCEPTABLE_COSTS: {
    id: 'acceptableCosts',
    code: 'A$',
    factorText: 'quick.predict.deliverables.factors.label.acceptable.costs'
  }
};
