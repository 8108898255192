import {Action, createReducer, on} from '@ngrx/store';
import {deliverableConfigurationAdapter, initialState} from '@platform/store/deliverable-configuration/deliverable-configuration.adapter';
import {DeliverableConfigurationState} from '@platform/store/deliverable-configuration/deliverable-configuration.state';
import {
    addDeliverableConfiguration,
    addDeliverableConfigurations,
    updateDeliverableConfiguration
} from '@platform/store/deliverable-configuration/deliverable-configuration.actions';

/**
 * Initial state.
 */
export const initialDeliverableConfigurationState: DeliverableConfigurationState = deliverableConfigurationAdapter.getInitialState();

/**
 * Reducer for all concept actions.
 */
const deliverableConfigurationReducer = createReducer(
    initialState,
    on(addDeliverableConfiguration, (state, {deliverableConfiguration}) => {
        return deliverableConfigurationAdapter.addOne(deliverableConfiguration, state);
    }),
    on(addDeliverableConfigurations, (state, {deliverableConfigurations}) => {
        return deliverableConfigurationAdapter.addMany(deliverableConfigurations, state);
    }),
    on(updateDeliverableConfiguration, (state, {deliverableConfiguration}) => {
        return deliverableConfigurationAdapter.upsertOne(deliverableConfiguration, state);
    })
);

/**
 * DeliverableConfiguration `NgRx` reducer.
 *
 * @export
 * @param {DeliverableConfigurationState} state
 * @param {Action} action
 * @returns {DeliverableConfigurationState}
 */
export function reducer(state: DeliverableConfigurationState | undefined, action: Action): DeliverableConfigurationState {
    return deliverableConfigurationReducer(state, action);
}
