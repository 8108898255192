import { Subgroup } from '@platform/models/subgroup.model';
import { SubgroupState } from '@platform/store/state/subgroup.state';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

/**
 * Subgroups entity adapter.
 *
 * @export
 * @property {EntityAdapter<Subgroup>} adapter - The subgroup adapter.
*/
export const subgroupAdapter: EntityAdapter<Subgroup> = createEntityAdapter<Subgroup>();

/**
 * Initial state.
 */
export const initialState: SubgroupState = subgroupAdapter.getInitialState({});
