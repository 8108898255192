import {deliverableComponents} from '@products/restage/deliverable-component.enum';
import {ProductService} from '@platform/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {combineLatest, Observable} from 'rxjs';
import {Feature} from '@platform/models/feature.model';
import { switchMap, take } from 'rxjs/operators';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import { DeliverableType } from "@app/deliverables/deliverable-type.enum";
import {DeliverableType as ProductDeliverableTypes} from '@app/deliverables/deliverable-type.enum';
import {ProductType} from '@products/product-type.enum';
import {ForecastingService} from '@platform/services/forecasting.service';

export class RestageService implements ProductService {

  constructor(
    private translate: TranslateService,
    private deliverableInfoService: DeliverableInfoService,
    private forecastingService: ForecastingService) { }

  getModule() {
    return import('@products/restage/restage.module').then(m => m.RestageModule);
  }

  public getFeatures(): Observable<Feature[]> {
    let features: Feature[];
    const isDeliverableExists$ = this.deliverableInfoService.isDeliverableExists();
      const isForecastingExists$ = this.forecastingService.isForecastingExists();
      return combineLatest([isDeliverableExists$, isForecastingExists$]).pipe(switchMap(([isDeliverableExists, isForecastingExists]) => {
      features = [];
      if (isDeliverableExists) {
        features.push({
          name: this.translate.instant('product.feature.insights'),
          path: 'insights'
        }, {
          name: this.translate.instant('product.feature.deliverables'),
          path: 'deliverables'
        }, {
          name: this.translate.instant('product.feature.files'),
          path: 'report-files'
        });
      } else {
        features.push({
          name: this.translate.instant('product.feature.files'),
          path: 'report-files'
        });
      }
      if (isForecastingExists) {
          features.splice(2, 0, {
              name: this.translate.instant('product.feature.forecasting'),
              path: 'forecasting'
          });
      }
      return [features];
    }));
  }
  public getDeliverableInfos (isDefault: boolean): Observable<DeliverableInfo[]> {
    const infos$ = this.deliverableInfoService
      .mapFromType(DeliverableType, isDefault)
      .pipe(take(1));
    return infos$;
  }

  public getProductName(): string {
    return this.translate.instant(ProductType.RESTAGE.labelKey);
  }

  public getDeliverableComponents(): { [type: string]: any} {
    return deliverableComponents;
  }
}
