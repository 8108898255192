import { ArchiveState} from "@platform/store/state/archive.state";
import { Archive} from "@platform/models/archive.model";
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

/**
 * Archive entity adapter.
 *
 * @export
 * @property {EntityAdapter<Archive>} archiveAdapter - The archive adapter.
*/
export const archiveAdapter: EntityAdapter<Archive> = createEntityAdapter<Archive>();

/**
 * Initial state.
 */
export const initialState: ArchiveState = archiveAdapter.getInitialState({});
