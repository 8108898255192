import { InsightState } from './../state/insight.state';
import { Action, createReducer, on } from '@ngrx/store';
import { initialState, insightAdapter } from '../adapters/insight.adapter';
import * as fromInsightActions from '../actions/insight.action'

/**
 * Reducer for all Insight actions.
 */
const InsightReducer = createReducer(
  initialState,
  on(fromInsightActions.addInsight, (state, {insight}) => {
    return insightAdapter.addOne(insight, state);
  }),
  on(fromInsightActions.addInsights, (state, { insights }) => {
    return insightAdapter.addMany(insights, state);
  }),
  on(fromInsightActions.updateInsight, (state, { insight }) => {
    return insightAdapter.upsertOne( insight, state);
  }),
  on(fromInsightActions.deleteInsight, (state, { id }) => {
    return insightAdapter.removeOne( id, state);
  }),
  on(fromInsightActions.deleteInsights, (state, { id }) => {
    return insightAdapter.removeMany( id, state);
  }),
  on(fromInsightActions.updateInsights, (state, { insights }) => {
    return insightAdapter.upsertMany( insights, state);
  }),
  on(fromInsightActions.resetInsights, (state) => {
    return insightAdapter.removeAll(state);
  })
);

/**
 * Insights `NgRx` reducer.
 *
 * @export
 * @param {InsightState} state
 * @param {Action} action
 * @returns {InsightState}
 */
export function reducer(state: InsightState | undefined, action: Action): InsightState {
  return InsightReducer(state, action);
}
