import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {
  Click,
  ClickZone,
  StrengthWatchoutsConcept
} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import { StrengthWatchoutsFilter } from '../../models/strength-watchouts-filter.model';

@Component({
  selector: 'sw-verbatims-concept-row',
  templateUrl: './concept-row.component.html',
  styleUrls: ['./concept-row.component.scss']
})
export class VerbatimsConceptRowComponent implements OnInit {

  @Input() concept: StrengthWatchoutsConcept;
  @Input() concepts: StrengthWatchoutsConcept[];
  @Input() selectedView: number|string;
  @Input() filter: StrengthWatchoutsFilter;

  /**
   * Current hovered click.
   * @type {Click}
   * @memberOf VerbatimsConceptRowComponent
   */
  public currentClick: Click;
  public currentZoneClicked: ClickZone;
  public deselectAllZones = false;
  wideConcept = false;

  isCarouselDisplay: boolean = false;
  imageHeight: string;

  constructor() { }

  ngOnInit() {
    this.wideConcept = this.concept && this.concept.imageWidth > 600;
    this.imageHeight = this.concept && this.concept.imageHeight == 450 ? "Landscape":(this.concept && this.concept.imageHeight == 540 ? "Widescreen" : "Portrait");
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isCarouselDisplay = this.filter.show.languages.filter(x => x.id === 'all' && x.isSelected === true).length  > 0;
  }

  /**
   * Sets current hovered click.
   * @param $event The click that is currently being hovered over.
   */
  receiveClickHover($event: Click) {
    this.currentClick = $event;
  }

  receiveZoneClick($event: ClickZone) {
    this.currentZoneClicked = $event;
    this.deselectAllZones = false;
  }

  receiveZoneDeselect($event: boolean) {
    if (!this.deselectAllZones) {
      this.deselectAllZones = $event;
      this.currentZoneClicked = null;
    }
  }

}
