import { ViewMetaInfo } from '@platform/models/view-meta-info.model';
import { ViewMetaInfoState } from '@platform/store/state/view-meta-info.state';
import { State } from '@platform/store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * ViewMetaInfo feature selector.
 *
 * @export
 * @property
*/
export const selectViewMetaInfoFeature = createFeatureSelector< ViewMetaInfoState>('viewMetaInfo');
/**
 * ViewMetaInfo selector.
 *
 * @export
 * @property
*/
export const selectViewMetaInfo = createSelector(
  selectViewMetaInfoFeature,
  (state: ViewMetaInfoState) => state
);

/**
 * Selector for selecting viewMetaInfo by deliverable view type.
 *
 * @export
 * @property
*/
export const selectViewMetaInfoByType = <T extends ViewMetaInfo>() => {
  return createSelector(
    selectViewMetaInfo,
    (state: ViewMetaInfoState, props: { type: string }) => {
      return <T>state[props.type];
    }
  );
};
