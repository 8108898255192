import { updateManyUserViews } from './../actions/user-view.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { UserViewState } from '@platform/store/state/user-view.state';
import { userViewAdapter, initialState } from '@platform/store/adapters/user-view.adapter';
import { addUserView, addUserViews, deleteUserView, updateUserView } from '@platform/store/actions/user-view.actions';

/**
 * Reducer for all userView actions.
 */
const userViewReducer = createReducer(
  initialState,
  on(addUserView, (state, { userView }) => {
    return userViewAdapter.addOne(userView, state);
  }),
  on(addUserViews, (state, { userViews }) => {
    return userViewAdapter.addMany(userViews, state);
  }),
  on(updateUserView, (state, { userView }) => {
    return userViewAdapter.upsertOne(userView, state);
  }),
  on(updateManyUserViews, (state, { userViews }) => {
    return userViewAdapter.updateMany(userViews, state);
  }),
  on(deleteUserView, (state, { userView }) => {
    return userViewAdapter.removeOne(userView.id, state);
  })
);

/**
 * UserViews `NgRx` reducer.
 *
 * @export
 * @param {UserViewState} state
 * @param {Action} action
 * @returns {UserViewState}
 */
export function reducer(state: UserViewState | undefined, action: Action): UserViewState {
  return userViewReducer(state, action);
}
