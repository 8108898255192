import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {SpinnerService} from '@platform/services/spinner.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {CorrelationsDeliverableView} from './models/correlations.model';
import {UserService} from '@platform/services/user.service';
import {CorrelationsService} from './services/correlation.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {CorrelationsFilter, FilterItem} from './models/filter.model';
import {CorrelationsMetaInfo} from './models/view-meta-info.model';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {ReportService} from '@platform/services/report.service';
import {UserView} from '@platform/models/user-view.model';
import {UserViewService} from '@platform/services/user-view.service';
import {RouterService} from '@platform/services/router.service';
import {Report} from '@platform/models/report.model';
import {InsightService} from '@platform/insights/insights.service';
import {FilterService} from '@platform/services/filter.service';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {take} from 'rxjs/operators';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';
import {DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {CompareView} from '@app/deliverables/correlations/models/compare-view-enum';

/**
 * `<ns-correlations>` component builds correlations deliverable for both
 * concepts and subgroups deliverable views.
 *
 * @example
 * <ns-correlations></ns-correlations>
 *
 * @export
 * @class CorrelationsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-correlations',
    templateUrl: './correlations.component.html',
    styleUrls: ['./correlations.component.scss']
})

export class CorrelationsComponent implements OnInit, OnDestroy, ScoreCardView {

    /**
     * Report document
     */
    public report: Report;
    /**
     * Correlations deliverable view data.
     *
     * @type {CorrelationsDeliverableView}
     * @member CorrelationsComponent
     */
    public correlations: CorrelationsDeliverableView;
    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member CorrelationsComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @memberOf CorrelationsComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Correlations deliverable view filter object.
     *
     * @type {CorrelationsFilter}
     * @member CorrelationsComponent
     */
    public filter: CorrelationsFilter;

    public defaultViewFilter: Record<string, Array<FilterItem>>;

    public deliverableViewType = 'dataTable';

    /**
     * The CorrelationsDeliverableView.
     * @type {CorrelationsDeliverableView} correlationsDeliverableView
     * @memberOf CorrelationsComponent
     */
    public correlationsDeliverableView: CorrelationsDeliverableView;

    public deliverableViews: Array<DeliverableView>;
    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @memberOf CorrelationsComponent
     */
    public isInsightEnable = false;

    public isInternalUser: Boolean;

    /**
     * Meta info for Correlations
     *
     * @type {CorrelationsDeliverableView}
     * @memberOf CorrelationsComponent
     */
    public viewCorrelationsMetaInfo: CorrelationsMetaInfo;

    /**
     * Spinner.
     *
     * @type {Array<any>}
     * @member CorrelationsComponent
     */
    public displayProgressSpinner = false;

    public disableBtn: boolean;

    /**
     * ScoreCard Concept object for correlations.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    public deliverableInfos: Array<DeliverableInfo>;

    public userViews: Array<UserView>;

    public configChanged = false;

    public configs: DeliverableConfiguration;

    public deliverableType = DeliverableType.CORRELATIONS.type;

    /**
     * Take the insight in the HTML format for this Deliverable
     *
     * @type {Boolean}
     */
    public addHTMLToInsight = false;

    /**
     * Creates an instance of CorrelationsComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {CorrelationsService} correlationsService
     * @param deliverableViewService
     * @param exportPNGService
     * @param viewMetaInfoService
     * @param spinnerService
     * @param deliverableInsightService
     * @param userService
     * @param routeService
     * @param userViewService
     * @param deliverableInfoService
     * @param filterService
     * @param reportService
     * @param insightService
     * @member CorrelationsComponent
     */
    constructor(private deliverableInsightService: DeliverableInsightService,
                private correlationsService: CorrelationsService,
                private deliverableViewService: DeliverableViewService,
                private exportPNGService: ExportPngService,
                private spinnerService: SpinnerService,
                private viewMetaInfoService: ViewMetaInfoService,
                private userService: UserService,
                private routeService: RouterService,
                private userViewService: UserViewService,
                private deliverableInfoService: DeliverableInfoService,
                private filterService: FilterService,
                private reportService: ReportService,
                private insightService: InsightService,
                private productDeliverableViewService: ProductDeliverableViewService) {
        this.subscriptions = [];
        this.userViews = [];
        this.configs = null;
    }

    /**
     * Initialize the correlations component view.
     *
     * @member CorrelationsComponent
     */
    ngOnInit(): void {
        const subscription = this.loadComponentData();
        this.subscriptions.push(subscription);
        this.subscriptions.push(this.reportService.correlationConfigChanged$.subscribe((configs) => {
            this.productDeliverableViewService.clearCache();
            if ( !this.filter.show.keyMeasures['dataTable'].length || !this.filter.show.keyMeasures['quadMap'].length ) {
                window.location.reload();
            } else if (configs.config.measures.find(it => it.dataTable) && configs.config.measures.find(it => it.quadMap)) {
                this.configChanged = true;
                this.configs = configs;
                this.loadComponentData();
            } else {
                window.location.reload();
            }
        }));
    }

    updateFiltersAsPerConfig(configs) {
        if (configs) {
            const filter: CorrelationsFilter = JSON.parse(JSON.stringify(this.filter));
            const newKeyMeasuresFilter = filter.show.keyMeasures;
            const keyMeasures = {dataTable: [], quadMap: []};
            if (filter && filter.show && filter.show.keyMeasures) {
                configs.config.measures.forEach((measure) => {
                    if (measure.dataTable && !newKeyMeasuresFilter['dataTable'].find(it => it.id === measure.basesLabel && it.dataTable)) {
                        keyMeasures.dataTable.push({
                            id: measure.basesLabel,
                            isSelected: true,
                            position: measure.position,
                            name: measure.correlationLabel
                        });
                    } else if (measure.dataTable && newKeyMeasuresFilter['dataTable'].find(it => it.id === measure.basesLabel)) {
                        keyMeasures.dataTable.push({
                            id: measure.basesLabel,
                            isSelected: newKeyMeasuresFilter['dataTable'].find(it => it.id === measure.basesLabel).isSelected,
                            position: measure.position,
                            name: measure.correlationLabel
                        });
                    }
                    if (measure.quadMap && !newKeyMeasuresFilter['quadMap'].find(it => it.id === measure.basesLabel && it.quadMap)) {
                        keyMeasures.quadMap.push({
                            id: measure.basesLabel,
                            isSelected: true,
                            position: measure.position,
                            name: measure.correlationLabel
                        });
                    } else if (measure.quadMap && newKeyMeasuresFilter['quadMap'].find(it => it.id === measure.basesLabel)) {
                        keyMeasures.quadMap.push({
                            id: measure.basesLabel,
                            isSelected: newKeyMeasuresFilter['quadMap'].find(it => it.id === measure.basesLabel).isSelected,
                            position: measure.position,
                            name: measure.correlationLabel
                        });
                    }
                });
                this.filterService.update({
                    ...this.filter,
                    deliverableType: DeliverableType.CORRELATIONS.type,
                    deliverableViewType: this.deliverableViewType,
                    show: {
                        ...this.filter.show,
                        keyMeasures: {
                            dataTable: keyMeasures.dataTable.sort((a, b) => {
                                return a.position - b.position;
                            }),
                            quadMap: keyMeasures.quadMap.sort((a, b) => {
                                return a.position - b.position;
                            }),
                        }
                    }
                });
                this.filter = filter;
            }
        }
    }
    loadComponentData() {
        const insightId = this.routeService.getQueryParam('insightId');
        return combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.CORRELATIONS.type)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.deliverableViews = deliverableViews;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            // load saved user views(filter) if they exist
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<CorrelationsFilter>(report.id, insightId),
                this.correlationsService.loadDefaultFilter(null, this.deliverableViews)
            ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, DeliverableType.CORRELATIONS.type, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                if (!this.configChanged) {
                    this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
                }
                combineLatest([
                    this.correlationsService.getCorrelationsFilter(),
                    this.viewMetaInfoService.get<CorrelationsMetaInfo>(DeliverableType.CORRELATIONS.type),
                    this.correlationsService.getCorrelations(),
                ]).subscribe(([filters, viewMetaInfo, correlation]) => {
                    this.isInternalUser = user.isInternalUser;
                    this.correlationsDeliverableView = correlation;
                    this.viewCorrelationsMetaInfo = viewMetaInfo;
                    this.filter = filters;
                    if (this.configChanged) {
                        this.updateFiltersAsPerConfig(this.configs);
                    }
                    const hasDataTableItems = Object.keys(this.deliverableViews[0].metaInfo.dataTable).length > 0;
                    const hasQuadMapItems = Object.keys(this.deliverableViews[0].metaInfo.quadMap).length > 0;
                    this.deliverableViewType = hasDataTableItems && hasQuadMapItems ? this.filter.deliverableViewType : hasDataTableItems ? 'dataTable' : 'quadMap';
                    this.correlationsService.getKeyMeasures(this.deliverableViewType, this.deliverableViews);
                    this.configChanged = false;
                    if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                        this.openInsightCreationForm();
                    }
                });
            });
        });
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        if (userView && userView.filter && userView.filter['show']) {
            this.filter = this.updateFilterMeasuresAsPerConfiguration(userView) as CorrelationsFilter;
        } else {
            this.filter = userView.filter as CorrelationsFilter;
        }
        this.disableBtn = this.isAnyKeyMeasureSelected(this.filter);
        this.filterService.update(this.filter);
    }

    private updateFilterMeasuresAsPerConfiguration(userView): CorrelationsFilter {
        this.defaultViewFilter = this.correlationsService.getKeyMeasures(null, this.deliverableViews);
        const filter = userView.filter;
        let newKeyMeasuresFilter = {...filter.show.keyMeasures};
        const newDatatableMeasures = [...newKeyMeasuresFilter.dataTable];
        const newQuadMapMeasures = [...newKeyMeasuresFilter.quadMap];
        const newTertileMeanFilter = {...filter.show.tertileMean};
        this.defaultViewFilter['dataTable'].forEach((measure) => {
            if (!newDatatableMeasures.find((it) => it.id === measure.id)) {
                newDatatableMeasures.push(measure);
            }
        });
        this.defaultViewFilter['quadMap'].forEach((measure) => {
            if (!newQuadMapMeasures.find((it) => it.id === measure.id)) {
                newQuadMapMeasures.push(measure);
            }
        });
        const filteredDaTableIds = this.defaultViewFilter['dataTable'].map(it => it.id);
        const unselectedDataTableConfigs = newDatatableMeasures.filter(it => filteredDaTableIds.includes(it.id));
        const filteredQuadMapIds = this.defaultViewFilter['quadMap'].map(it => it.id);
        const unselectedQuadMapConfigs = newQuadMapMeasures.filter(it => filteredQuadMapIds.includes(it.id));
        newKeyMeasuresFilter = {
            dataTable: unselectedDataTableConfigs.sort((a, b) => {
                return a.position - b.position;
            }),
            quadMap: unselectedQuadMapConfigs.sort((a, b) => {
                return a.position - b.position;
            })
        };
        const newShowFilter = {...filter.show, tertileMean: newTertileMeanFilter, keyMeasures: newKeyMeasuresFilter};
        const newFilter = {...filter, show: newShowFilter};
        return newFilter;
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        setTimeout(() => {
        this.isInsightEnable = true;
        const compareFilter = this.filter.deliverableViewType;
        this.addHTMLToInsight = true;
        this.deliverableData = {
            title: this.isAutomatedHeadlinesEnabled ?
                this.deliverableInsightService.generateInsightTitle('Correlations',true) : '',
            deliverable: {
                metaInfo: this.viewCorrelationsMetaInfo,
                deliverableViewId: this.deliverableViews.length > 0 ? this.deliverableViews[0].id : this.correlationsDeliverableView.id,
                filter: this.filter,
                insightHTML: ''
            }
        };
        if (compareFilter === 'quadMap') {
            this.deliverableData['selectors'] = ['#svg-container'];
        }
        },100);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    setViews(event) {
        console.log('setViews', event);
    }

    update(event) {
        console.log('update', event);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG([], true);
        this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
    }

    /**
     * Cleanup hook.
     *
     * @member CorrelationsComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * check key measures count
     * */
    isAnyKeyMeasureSelected(filter: CorrelationsFilter): boolean {
        const keyMeasuresCount = filter.show.keyMeasures[this.correlationsService.viewName].filter(it => it.isSelected === true).length;
        return (keyMeasuresCount === 0);
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }
}
