<div class="section text-center">
  <div>
    <div class="legend">
      <span class="quintile-range sif sif-circle-fill"></span>
      <span class=" space-left-sm quintile-text legend-text">{{'shared.deliverables.tgpa.legend.top' | translate}}</span>
    </div>
    <div class="legend">
      <span class="quintile-range sif sif-18 sif-circle-half"></span>
      <span class="space-left-sm quintile-text legend-text">{{'shared.deliverables.tgpa.legend.middle' | translate}}</span>
    </div>
    <div class="legend">
      <span class="quintile-range sif sif-18 sif-circle-outline"></span>
      <span class="space-left-sm quintile-text legend-text">{{'shared.deliverables.tgpa.legend.bottom' | translate}}</span>
    </div>
  </div>
</div>
