/**
 * CSS Node Declaration object for managing node styles along with styles for
 * `:before` and `:after` pseudo classes.
 */
export class CSSNodeDeclaration {
  /**
   * Computed styles at a node.
   *
   * @type {Map<string, string>}
   */
  declaration: Map<string, string>;
  /**
   * Computed `:before` pseudo class styles at a node.
   *
   * @type {Map<string, string>}
   */
  beforeDeclaration: Map<string, string>;
  /**
   * Computed `:after` pseudo class styles at a node.
   *
   * @type {Map<string, string>}
   */
  afterDeclaration: Map<string, string>;

  /**
   * Initialize.
   */
  constructor() {
    this.declaration = new Map<string, string>();
    this.beforeDeclaration = new Map<string, string>();
    this.afterDeclaration = new Map<string, string>();
  }
}
