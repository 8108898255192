/**
 * Deliverable view types for Gap Analysis.
 *
 * @export
 * @enum {string}
 */

export enum DeliverableViewType {
    /**
   * Concept deliverable view type.
   */
    CONCEPT = 'concept'
  }
