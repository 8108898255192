import { Injectable } from '@angular/core';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';

@Injectable({
  providedIn: 'root'
})
export class RestaurantIncrementalService {

  constructor(private activationProfileService: ActivationProfileService) { }

  /**
   * set restaurant incrementality tree
   *
   * @returns {TreeNodeData}
   * @memberof RestaurantIncrementalService
   */
  setRestaurantIncrementality(headerList: string | string[], measureDescription: MeasureDescription, restaurantIncrementality: TreeNodeData[]): TreeNodeData[] {
    if (headerList.indexOf('restaurantIncrementality') > -1) {
      restaurantIncrementality = this.activationProfileService.buildMeasures(
        measureDescription.restaurantIncrementality,
        'Parent', 'restaurantIncrementality', 1, [], null,
        this.activationProfileService.getTooltip('ri.' + measureDescription.restaurantIncrementality),
        true);
      if (headerList.indexOf('restaurantIncrementalityTripIncrementality') > -1) {
        const restaurantIncrementalityTripIncrementality = this.activationProfileService.buildMeasures(
          measureDescription.restaurantIncrementalityTripIncrementality,
          'Leaf', 'tripIncrementality', 2, null, null, null, true, 2);
        restaurantIncrementality[0].children.push(restaurantIncrementalityTripIncrementality[0]);
      }
      if (headerList.indexOf('restaurantIncrementalityItemPremium') > -1) {
        const restaurantIncrementalityItemPremium = this.activationProfileService.buildMeasures(
          measureDescription.restaurantIncrementalityItemPremium, 'Leaf',
          'itemPremium', 2, null, null, null, true, 2);
        restaurantIncrementality[0].children.push(restaurantIncrementalityItemPremium[0]);
      }
    }
    return restaurantIncrementality;
  }
}
