import {FactorsDeliverableView, Subgroup} from '@app/deliverables/factors/models/factors.model';
import {Injectable} from '@angular/core';
import * as numeral from 'numeral';
import {FactorMeasuretypeMapping} from '@app/deliverables/factors/models/factor-measuretype-mapping-enum';
import {TranslateService} from '@ngx-translate/core';
import {ThresholdsMapping} from '../models/thresholds-mapping';
import {StringHelper} from '@platform/services/string-helper.service';
import {Subject} from 'rxjs';
import {FactorsFilter, FilterItem} from '../models/filter.model';
import {FactorsMapping} from '../models/factors-mapping';
import {FactorsService} from '@app/deliverables/factors/services/factors.service';

/**
 * `SubgroupMeansService` has all data operations
 * for fetching the factors for success filter data
 *
 * @export
 * @class SubgroupMeansService
 */

@Injectable({
    providedIn: 'root'
})
export class SubgroupMeansService {

    public columnWidthsSubject = new Subject<any[]>();

    /**
     * Creates an instance of SubgroupMeansService.
     *
     * @constructor
     * @param {TranslateService} translate
     * @param {StringHelper} stringHelper
     * @param factorService
     * @memberof SubgroupMeansService
     */
    constructor(
        private stringHelper: StringHelper,
        private translate: TranslateService,
        private factorService: FactorsService) {
    }

    /**
     * Returns a tuple of column headers and factors-subgroup means view data for MatTable
     *
     * @param {FactorsFilter} filter
     * @param {FactorsDeliverableView} factors
     * @param isAlcoholStudy
     * @param isCannabisStudy
     * @returns  [Array<FilterItem>, Array<any>] - FFS table data for subgroup means view
     */
    // {concept: '*Distinct Proposition', generalPopulation: 3.38, parentsKids: 4.12, categoryBuyers: 2.78, competitiveBrandBuyers: 2.12, walmartBuyers: 0},
    public fetchRecordsForSubgroupMeansView(filter: FactorsFilter, factors: FactorsDeliverableView, isAlcoholStudy: boolean, isCannabisStudy: boolean): [Array<FilterItem>, Array<any>] {
        const selectedSubgroups = this.selectedFilterItems(filter.subgroups);
        const subgroupsMap: { [key: number]: FilterItem } = this.getFilterItemMap(selectedSubgroups);
        const colHeaders: Array<FilterItem> = [...selectedSubgroups];
        return this.setTableDataForSubgroupMeansView(factors, filter, subgroupsMap, colHeaders, isAlcoholStudy, isCannabisStudy);
    }

    /**
     * Returns a tuple of column headers and factors-subgroup means view data for MatTable
     *
     * @param subgroupFactors
     * @param {FactorsFilter} filter
     * @param { [key: number]: FilterItem } subgroupsMap
     * @param {Array<FilterItem>} colHeaders
     * @param isAlcoholStudy
     * @param isCannabisStudy
     * @returns  [Array<FilterItem>, Array<any>] - FFS table data for subgroup means view
     */
    private setTableDataForSubgroupMeansView(subgroupFactors: FactorsDeliverableView, filter: FactorsFilter, subgroupsMap: { [key: number]: FilterItem }, colHeaders: Array<FilterItem>, isAlcoholStudy: boolean, isCannabisStudy: boolean): [Array<FilterItem>, Array<any>] {
        const tableData = [];
        let rowData, cellData, cellDataKey, colHeader;
        const nColHeaders = [];
        const pattern = this.getFormatPattern(filter);
        const selectedConcepts = this.factorService.selectedFilterItems(filter.concepts);
        const selectedFactors = filter.show.factorsOptions.filter(c => c.isSelected).map(c => c.name);
        const factorItems = subgroupFactors.factors.filter(data => {
            if ((isAlcoholStudy || isCannabisStudy) && data.measureType.toUpperCase() === 'REL3MT') {
                FactorMeasuretypeMapping[data.measureType.toUpperCase()] = FactorsMapping.DESIRE.factorText;
            }
            if (selectedFactors.includes(this.translate.instant(FactorMeasuretypeMapping[data.measureType.toUpperCase()])) &&
            selectedConcepts[0].id === data.conceptId) {
                return data;
            }
        });
        for (const factorName of selectedFactors) {
            factorItems.forEach((factorData, index) => {
                if (factorName === this.translate.instant(FactorMeasuretypeMapping[factorData.measureType.toUpperCase()])) {
                    rowData = {};
                    rowData.factor = factorName;
                    factorData.subgroups.forEach((subgroupData) => {
                        cellData = {};
                        cellDataKey = subgroupsMap[subgroupData.segmentId];
                        cellData.mean = numeral(subgroupData.mean).format(pattern);
                        cellData.exactMean = (subgroupData.mean);
                        cellData.localReported = subgroupData.localReported;
                        cellData.color = subgroupData.localReported && this.addColor(subgroupData.localReported);
                        cellData.statTesting = this.getStatTestingValue(filter, subgroupData);
                        rowData[cellDataKey?.name] = cellData;
                        if (index === 0 && !nColHeaders.find(col => col.id === cellDataKey?.id)) {
                            colHeader = colHeaders.find(ch => ch.id === cellDataKey?.id);
                            const subgroupBaseSize = subgroupFactors.baseSizes.find(basesize => basesize.conceptId === factorData.conceptId);
                            const baseSize = subgroupBaseSize.subgroups.find(subgroup => subgroup.segmentId === subgroupData.segmentId).baseSize;
                            if (colHeader) {
                                const newValue = Object.assign({}, colHeader, {size: baseSize});
                                nColHeaders.push(newValue);
                            }
                        }
                    });
                    tableData.push(rowData);
                }
            });
        }
        nColHeaders.sort(function (a, b) {
            return a.position - b.position;
        });
        nColHeaders.unshift({name: 'factor'});
        return [nColHeaders, tableData];
    }

    /**
     * Returns numeral js display pattern for the numeric value.
     *
     * @private
     * @param {FactorsFilter} filter
     * @returns {string}
     * @member SubgroupMeansService
     */
    private getFormatPattern(filter: FactorsFilter): string {
        let pattern = '';
        pattern = filter.show.decimals.oneDecimal ? '0.0' : pattern;
        pattern = filter.show.decimals.twoDecimal ? '0.00' : pattern;
        pattern = pattern;
        return pattern;
    }

    /**
     * adds color to the data point.
     *
     * @private
     * @param {string} threshold
     * @member SubgroupMeansService
     */
    private addColor(threshold: string) {
        for (const key in ThresholdsMapping) {
            if (this.translate.instant(ThresholdsMapping[key].id) === threshold.toLowerCase()) {
                return ThresholdsMapping[key].color;
            }
        }
    }

    /**
     * Returns the stat testing label for the data item.
     *
     * @private
     * @param {FactorsFilter} filter
     * @param {Subgroup} subgroupData
     * @returns {string}
     * @memberof SubgroupMeansService
     */
    private getStatTestingValue(filter: FactorsFilter, subgroupData: Subgroup): string {
        const statTestingFilter = filter.show.statTesting;
        let ids = [];
        let alphabets: Array<string>;
        const selectedSubgroups = filter.subgroups.filter(subgroup => subgroup.isSelected).map(subgroup => subgroup.id);
        ids = statTestingFilter && subgroupData.subgroupStat ? subgroupData.subgroupStat : ids;
        alphabets = ids
            .map(id => selectedSubgroups.indexOf(id))
            .filter(index => index >= 0)
            .map(index => this.stringHelper.alphabetSeries(index));
        return this.stringHelper.sortAlphabeticSeries(alphabets).join('');
    }

    /**
     * Returns selected filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {Array<FilterItem>}
     * @memberof SubgroupMeansService
     */
    private selectedFilterItems(items: Array<FilterItem>): Array<FilterItem> {
        const selectedItems = items ? items.filter(item => item.isSelected) : [];
        return selectedItems;
    }

    /**
     * Returns map of filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {{[key: number]: FilterItem}}
     * @memberof SubgroupMeansService
     */
    private getFilterItemMap(items: Array<FilterItem>): { [key: number]: FilterItem } {
        const itemsMap: { [key: number]: FilterItem } = {};
        items.forEach(item => {
            itemsMap[item.id] = item;
        });
        return itemsMap;
    }

}
