import { DeliverableType } from "@app/deliverables/deliverable-type.enum";
import { DeliverableViewType } from './deliverable-view-type.enum';
import { CompareView } from './compare-view-enum';
/**
 * Default filter values for attributes.
 *
 * @export
 * @property
*/
export const defaultAttributesFilter = {
  deliverableType: DeliverableType.ATTRIBUTES.type,
  deliverableViewType: DeliverableViewType.CONCEPT,
  compare: [
    {
      name: 'Concepts',
      id: CompareView.CONCEPT,
      isSelected: true,
      productViewId: null,
      position: 1
    },
    {
      name: 'Subgroups',
      id: CompareView.SUBGROUP,
      isSelected: false,
      productViewId: null,
      position: 1
    }],

  show: {
    mean: true,
    percents: {
      isSelected: false,
      topBox: false,
      topTwoBox: false,
      bottomThreeBox: false,
      all: false
    },
    noDecimal: false,
    oneDecimal: true,
    twoDecimal: false,
    statTesting: true,
    dataLabels: false,
    barCharts: true
  }
};
