
/**
 * Probability of success mapping
 *
 * @export
 * @property
 */
export const ProbabilityOfSuccessMapping = {
  /**
   * Different probability of success options for FFS
   */
  OUTSTANDING: {
    id: 'outstanding',
    minimum: 85,
    maximum: 100
  },
  READY: {
    id: 'ready',
    minimum: 67,
    maximum: 84
  },
  RISKY: {
    id: 'risky',
    minimum: 33,
    maximum: 66
  },
  HIGH_RISK: {
    id: 'failure',
    minimum: 0,
    maximum: 32
  }
};
