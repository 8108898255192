<div class="sticky-container facet-container" *ngIf="filter">
  <div class="air-filters-bar clearfix">
    <div class="float-left">
        <ns-dropdown id="compareFilter"
          class="filter-item"
          [dropDownData]="compareListData"
          (selectionChange) = "selectCompare($event.data)">
        </ns-dropdown>

        <ng-container *ngIf="!concept">
          <ns-dropdown id="conceptsFilter"
            class="filter-item"
            [dropDownData]="conceptsListData"
            (selectionChange) = "selectConcept($event.data)"
            [disable]="disableConcepts">
          </ns-dropdown>
          <ns-dropdown id="countryFilter"
            class="filter-item"
            [dropDownData]="countryListData"
            (selectionChange) = "selectedEvent($event)">
          </ns-dropdown>
          <ns-dropdown id="subgroupFilter"
            class="filter-item"
            [dropDownData]="subgroupListData"
            (selectionChange) = "selectSubgroup($event.data)"
            [disable]="disableSubgroups">
          </ns-dropdown>
        </ng-container>

        <sw-filter-show id="showFilter" class="filter-item" [deliverableLabel]="deliverableLabel"></sw-filter-show>
    </div>
  </div>
</div>

<!--<ng-template #itemsTemplate let-outletContext="outletContext">
  <div class="filter-content">
    <mat-radio-group class="dropdown-menu-list" [selected]="outletContext.getFirstSelectedItem().value" (change)="outletContext.toggleSelection($event, true)">
      <ng-container class="list-item" *ngFor="let item of outletContext.items">
        <mat-radio-button class="list-item" [name]="item.value" [value]="item.value" >{{item.label}}</mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
</ng-template>-->

