<!--<ns-dropdown-menu *ngIf="filter" [label]="filterLabel" rel="tooltip" matTooltipPosition="above" matTooltip="{{tooltipLabel}}">-->
<!--  <div class="dropdown-filter-menu">-->
<!--    <ul class="dropdown-menu-list">-->
<!--        <div *ngIf="showOptions">-->
<!--        <h3 class="menu-title">{{showText.options}}</h3>-->
<!--        <li class="list-item group-list-item">-->
<!--          <mat-checkbox color="primary" rounded flat class="list-item menu-list-button" [label]="showText.showAll" [value]="showText.showAll" [checked]="allOptionsChecked" (change)="changeAllOptions($event.checked)" noink>{{showText.showAll}}</mat-checkbox>-->
<!--        </li>-->
<!--        <ul [ngClass]="'dropdown-menu-sublist'">-->
<!--            <div class="menu-list-content" *ngFor="let option of filter.show.options" (click)="$event.stopPropagation()">-->
<!--              <mat-checkbox color="primary" rounded flat class="list-item" [label]="option.id" [value]="option.name" [checked]="option.isSelected" (change)="changeOption(option)" noink>{{option.name}}</mat-checkbox>-->
<!--            </div>-->
<!--        </ul>-->
<!--        </div>-->
<!--        <h3 class="menu-title">{{showText.languages}}</h3>-->
<!--        <mat-radio-group [ngModel]="getSelectedLanguage()" (change)="changeLanguage($event)">-->
<!--            <div class="menu-list-content" *ngFor="let language of filter.show.languages">-->
<!--              <mat-radio-button color="primary" class="list-item" [value]="language.id">-->
<!--                <li class="list-item">{{language.name}}</li>-->
<!--              </mat-radio-button>-->
<!--            </div>-->
<!--        </mat-radio-group>-->
<!--    </ul>-->
<!--  </div>-->
<!--</ns-dropdown-menu>-->
<!--<ns-dropdown-menu *ngIf="filter" [label]="filterLabel" rel="tooltip" matTooltipPosition="above" matTooltip="{{tooltipLabel}}">-->
<ns-dropdown-menu *ngIf="filter" [label]="filterLabel" rel="tooltip" matTooltipPosition="above" matTooltip="{{tooltipLabel}}" class="filter-item" id="languageFilter">
    <div class="dropdown-filter-menu">
        <div>
            <h3 class="menu-title">Filter by {{showText.languages}}</h3>
            <mat-radio-group [ngModel]="getSelectedLanguage()" (change)="changeLanguage($event)">
                <div class="menu-list-content language-options" *ngFor="let language of filter.show.languages">
                    <mat-radio-button color="primary" class="list-item" [value]="language.id">
                        <li class="list-item">{{language.name}}</li>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>
</ns-dropdown-menu>
<span style="color: #dedede; padding: 0 5px">|</span>

<ns-dropdown-menu *ngIf="filter" [label]="sentimentFilterLabel" rel="tooltip" matTooltipPosition="above" matTooltip="{{sentimenttooltipLabel}}" class="filter-item" id="sentimentFilter">
    <div class="dropdown-filter-menu">
        <ul class="dropdown-menu-sublist">
            <h3 class="menu-title">Filter by Sentiment</h3>
            <div  *ngFor="let option of filter.show.options" (click)="$event.stopPropagation()">

                <mat-checkbox color="primary" rounded flat class="list-item" [label]="option.id" [value]="option.name" [checked]="option.isSelected" (change)="changeOption(option)" *ngIf="option.name!='Zones'" noink>{{option.name}}</mat-checkbox>

            </div>
        </ul>
    </div>
</ns-dropdown-menu>
