import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    SurveyQuestionAnswerConfiguration,
    SurveyQuestionConfiguration
} from "@platform/models/survey-question-configuration.model";
import {moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
    selector: 'ns-survey-question-answers-configuration',
    templateUrl: './survey-question-answers-configuration.component.html',
    styleUrls: ['./survey-question-answers-configuration.component.scss']
})
export class SurveyQuestionAnswersConfigurationComponent {

    @Input() surveyQuestionConfiguration: SurveyQuestionConfiguration;

    answerPositions: Array<number>;

    /**
     * Event that is triggered when configuration values changes
     * */
    @Output() configurationChangedEvent = new EventEmitter<SurveyQuestionAnswerConfiguration>();

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize(): void {
        this.surveyQuestionConfiguration.answers = [...this.surveyQuestionConfiguration.answers].sort((a, b) => {
            return a.position - b.position;
        });
        this.answerPositions = this.surveyQuestionConfiguration.answers.map(it => it.position);
    }

    displayNameChange(event, surveyQuestionAnswerConfiguration: SurveyQuestionAnswerConfiguration): void {
        if (event.target && event.target.value) {
            surveyQuestionAnswerConfiguration.editedAnswerText = event.target.value.trim();
        } else {
            surveyQuestionAnswerConfiguration.editedAnswerText = surveyQuestionAnswerConfiguration.answerText;
        }
        this.configurationChangedEvent.emit(surveyQuestionAnswerConfiguration);
    }

    /**
     * Toggle visibility of question
     * */
    toggleVisibility(surveyQuestionAnswerConfiguration: SurveyQuestionAnswerConfiguration): void {
        surveyQuestionAnswerConfiguration.selected = !surveyQuestionAnswerConfiguration.selected;
        this.configurationChangedEvent.emit(surveyQuestionAnswerConfiguration);
    }

    dropAnswer(event): void {
        moveItemInArray(this.surveyQuestionConfiguration.answers, event.previousIndex, event.currentIndex);
        this.surveyQuestionConfiguration.answers.forEach((it, index) => {
            it.position = this.answerPositions[index];
        })
        this.configurationChangedEvent.emit(null);
    }
}
