import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewType} from '@app/deliverables/interactions/models/deliverable-view-type-enum';

/**

/**
 * Default filter values for interactions.
 *
 * @export
 * @property
*/
export const defaultInteractionsFilter = {
    deliverableType: DeliverableType.INTERACTIONS.type,
    deliverableViewType: DeliverableViewType.CONCEPT,
};
