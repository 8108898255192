import { DeliverableType } from "@products/restage/deliverable-type.enum";
import { DeliverableViewType } from "./deliverable-view-type-enum";

/**
 * Default filter values for performance.
 *
 * @export
 * @property
*/
export const defaultPerformanceFilter = {
  deliverableType: DeliverableType.PERFORMANCE.type,
  deliverableViewType: DeliverableViewType.CONCEPT,
};
