<div class="deliverables-containers">
    <ng-container *ngIf="!scoreCardConcept">
        <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
            <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos"
                                       (deliverableChanged)="onDeliverableChange($event)">
                <ns-kebab-menu class="kabab-menu">
                    <ul class="kebab-menu-list">
                        <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
                        <li *ngIf="isVerbatimsView"><span
                            (click)="download($event)">{{ 'export.as.csv' | translate }}</span></li>
                    </ul>
                </ns-kebab-menu>
                <span *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" rel="tooltip"
                      matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}"
                      class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
                <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
            </ns-deliverable-nav-header>
        </div>
        <ns-deliverable-insight [displayMessage]="getConceptCountMessage()" [enabled]="isInsightEnable"
                                [deliverableData]="deliverableData" (close)="closeInsight()" [isHTMLInsight]="addHTMLToInsight"></ns-deliverable-insight>
    </ng-container>

    <div id="deliverable-container">
        <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
            <!--strength-watchouts filter-->
            <strength-watchouts-filter [concept]="scoreCardConcept"></strength-watchouts-filter>
            <ns-chart-options class="chart-option" *ngIf="filter?.deliverableViewType !== 'elementEngagements'" [filter]="filter" [isAISummaryFeatureEnabled]="isAISummaryFeatureEnabled"
                              [aiConceptSummaries]="aiConceptSummaries" [aiSubgroupSummaries]="aiSubgroupSummaries"
                              [viewName]="strengthWatchoutsDeliverableView?.viewName"></ns-chart-options>
        </div>
        <div id="strength-and-watchouts-content" class="strength-and-watchouts-content">
            <ng-container *ngIf="filter">
                <ng-container [ngSwitch]="filter.deliverableViewType">
                    <sw-click-data [aiZoneSummaries]="aiZoneSummaries" [aiSubgroupSummaries]="aiSubgroupSummaries"
                                   *ngSwitchDefault></sw-click-data>
                    <sw-verbatims *ngSwitchCase="'verbatims'"></sw-verbatims>
                    <sw-element-engagement *ngSwitchCase="'elementEngagements'"></sw-element-engagement>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
