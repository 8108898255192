import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Subscription } from 'rxjs';
import { TreeNodeData } from '../../models/tree-node-data.interface';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {ActivationProfileMetaInfo} from '@app/deliverables/activation-profile/models/view-meta-info.model';

@Component({
  selector: 'activation-profile-item-tree',
  templateUrl: './activation-profile-item-tree.component.html',
  styleUrls: ['./activation-profile-item-tree.component.scss']
})
export class ActivationProfileItemTreeComponent implements OnInit, OnDestroy {

  @Input() items: TreeNodeData[] = [];

  treeControl = new NestedTreeControl<TreeNodeData>(node => node.children);
  datasource = new MatTreeNestedDataSource<TreeNodeData>();
  private subscriptions: Subscription[] = [];

  constructor(private viewMetaInfoService: ViewMetaInfoService) { }

  ngOnInit(): void {
      this.datasource.data = this.items;
      this.treeControl.dataNodes = this.items;
      this.setupExpansionSubscription(this.treeControl.dataNodes);
  }

  /**
   * set Expand/collapse Subscription
   *
   * @returns {TreeNodeData}
   * @memberof ActivationProfileItemTreeComponent
   */
  setupExpansionSubscription(nodes: TreeNodeData[]) {
    const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
    let subscription: Subscription;
    nodes?.forEach(node => {
      if (!!node.children && node.children.length > 0) {
        subscription = this.viewMetaInfoService.get<ActivationProfileMetaInfo>(deliverableType)
        .subscribe(viewMetaInfo => {
          if (viewMetaInfo[node.key]) {
            this.treeControl.expand(node);
          } else {
            this.treeControl.collapse(node);
          }
        });
        this.subscriptions.push(subscription);
        this.setupExpansionSubscription(node.children);
      }
    });
  }

  /**If expandable node has children then return true*/
  hasChild(_: number, node: TreeNodeData) {
    return !!node.children && node.children.length > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
