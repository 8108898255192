import {Injectable} from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {ReportService} from '@platform/services/report.service';
import {switchMap, take} from 'rxjs/operators';
import {Observable, of, zip} from 'rxjs';
import {UserService} from '@platform/services/user.service';
import {PrivilegeService} from '@platform/services/privilege.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';

@Injectable()
export class ForecastingGuard  {

    constructor(
        private router: Router,
        private reportService: ReportService,
        private userService: UserService,
        private privilegeService: PrivilegeService,
        private deliverableInfoService: DeliverableInfoService
    ) {
    }

    /**
     * User who is Logged in will be verified if there are privileges to see Deliverables and Insights
     * Based on the user privileges will be directed to the Deliverables or Insights route.
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return zip([this.reportService.get(), this.userService.getUser().pipe(take(1))]).pipe(switchMap(val => {
            const report = val[0];
            const user = val[1];
            let canViewDeliverable = (user.roles.includes('ROLE_SYSTEM_ADMINISTRATOR') || user.roles.includes('ROLE_Nielsen') || user.roles.includes('ROLE_All Project Access'));

            if (!canViewDeliverable) {
                const collaboratedUser = this.privilegeService.getUserRole(user, report.collaborators);
                // Get the collaborator's shared privileges information who is logged in
                if (collaboratedUser) {
                    if (!collaboratedUser.isClient) {
                        canViewDeliverable = true;
                    } else if (collaboratedUser.isClient && collaboratedUser.privileges.includes('FORECAST_SHARE')) {
                        canViewDeliverable = true;
                    } else if (collaboratedUser.isClient && !(collaboratedUser.privileges.includes('FORECAST_SHARE')) && !(route.routeConfig.path === 'insights')) {
                        canViewDeliverable = false;
                    } else if (collaboratedUser.isClient && !(collaboratedUser.privileges.includes('FORECAST_SHARE')) && route.routeConfig.path === 'insights') {
                        canViewDeliverable = true;
                    }
                }
            }
            const currentDeliverableToView = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.length);
            const deliverableByPath = this.deliverableInfoService.getDeliverableTypeByPath(currentDeliverableToView);
            const isValidUrl = currentDeliverableToView === 'insights' || currentDeliverableToView === 'score-cards' || currentDeliverableToView === 'forecasting' || report.deliverables.find((d) => d.isComplete && (d.showDeliverable || undefined === d.showDeliverable) && (deliverableByPath ? d.type === deliverableByPath.type : true) ) ;

            // Based on canViewDeliverable rights, the redirection will be authorized to view else will be navigated to the report-files
            if (canViewDeliverable && report.deliverables.filter(forecastDeliverable => forecastDeliverable.isComplete && forecastDeliverable.type.includes('forecast')).length && isValidUrl) {
                return of(true);
            } else {
                let urlString = state.url.substring(0, state.url.lastIndexOf('/'));
                const inDepthForecastingDeliverablesRoute: boolean = urlString.includes('forecasting');
                if (inDepthForecastingDeliverablesRoute) {
                    urlString = urlString.substring(0, urlString.lastIndexOf('/'));
                }
                this.router.navigate([urlString + '/report-files']);
                return of(false);
            }
        }));
    }

}
