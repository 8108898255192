import {State} from "@platform/store/state/app.state";
import {AppConfigService} from "@app/core/config/app-config.service";
import {Report} from "@platform/models/report.model";
import {Injectable} from "@angular/core";
import {Store, select} from "@ngrx/store";
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {concatMap} from "rxjs/operators";
import {selectAllOrganizations, selectOrganizationById} from "@platform/store/organization/organization.selectors";
import {Organization} from "@platform/models/organization.model";
import {addOrganizations} from "@platform/store/organization/orgaization.actions";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  /**
   * Creates an instance of ReportService.
   *
   * @constructor
   * @param {Store<State>} store The application state
   * @param {HttpClient} httpClient The HttpClient.
   * @memberof ReportService
   */
  constructor(
    private store: Store<State>,
    private httpClient: HttpClient,
    private cs: AppConfigService) {
  }

  getById(id: string): Observable<Organization> {
    return this.store.pipe(select(selectOrganizationById(id)));
  }

  /**
   * Fetched report object from the API.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Report>} The report observable
   * @memberof ReportService
   */
  fetchAll(): Observable<Organization[]> {
    const url = `${this.cs.config.reporting.url}/organizations`;
    return this.httpClient.get<Organization[]>(url).pipe(
      concatMap(organizations => {
        this.store.dispatch(addOrganizations({organizations}));
        return this.fetchAllFromStore();
      })
    );
  }

  /**
   * Fetches a report object from the store.
   *
   * @private
   * @returns {Observable<Report>} The report observable.
   * @memberof ReportService
   */
  fetchAllFromStore(): Observable<Organization[]> {
    return this.store.pipe(select(selectAllOrganizations));
  }

}
