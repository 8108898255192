import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {Concept} from '@platform/models/concept.model';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {UserService} from '@platform/services/user.service';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {PriceElasticityFilter} from './models/filter.model';
import {ImagePath, PriceElasticityDeliverableView} from './models/price-elasticity.model';
import {PriceElasticityService} from './services/price-elasticity.service';
import {UserView} from '@platform/models/user-view.model';
import {UserViewService} from '@platform/services/user-view.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {ReportService} from '@platform/services/report.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {Report} from '@platform/models/report.model';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {RouterService} from '@platform/services/router.service';
import {InsightService} from '@platform/insights/insights.service';
import {FilterService} from '@platform/services/filter.service';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';

@Component({
    selector: 'ns-qpa-price-elasticity',
    templateUrl: './price-elasticity.component.html',
    styleUrls: ['./price-elasticity.component.scss']
})
export class PriceElasticityComponent implements OnInit, OnDestroy {

    /**
     * ScoreCard Concept object for Price Elasticity.
     *
     * @type {Concept}
     * @member PriceElasticityComponent
     */
    public scoreCardConcept: Concept;

    public report: Report;
    public deliverableInfos: Array<DeliverableInfo>;


    public deliverableViews: Array<DeliverableView>;
    public deliverableType = DeliverableType.PRICE_ELASTICITY.type;


    /**
     * Price Elasticity deliverable view filter object.
     *
     * @type {PriceElasticityFilter}
     * @member PriceElasticityComponent
     */
    public filter: PriceElasticityFilter;

    public userViews: Array<UserView>;

    /**
     * The PriceElasticityDeliverableView.
     * @type {PriceElasticityDeliverableView} PriceElasticityDeliverableView
     * @member PriceElasticityComponent
     */
    public priceElasticityDeliverableView: PriceElasticityDeliverableView;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member PriceElasticityComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @member PriceElasticityComponent
     */
    public isInsightEnable = false;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @member PriceElasticityComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Spinner.
     *
     * @type {Boolean}
     * @member PriceElasticityComponent
     */
    public displayProgressSpinner = false;

    /**
     * Disable Insights Button.
     *
     * @type {Boolean}
     * @member PriceElasticityComponent
     */
    public disableBtn: boolean;

    /**
     * Internal User
     *
     * @type {Boolean}
     * @member PriceElasticityComponent
     */
    public isInternalUser: Boolean;

    /**
     * Image path
     *
     * @member PriceElasticityComponent
     */
    public imagePath: ImagePath;
    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;
    public addHTMLToInsight = true;


    constructor(
        private deliverableInsightService: DeliverableInsightService,
        private exportPNGService: ExportPngService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private reportService: ReportService,
        private userViewService: UserViewService,
        private routeService: RouterService,
        private deliverableViewService: DeliverableViewService,
        private filterService: FilterService,
        private insightService: InsightService,
        private deliverableInfoService: DeliverableInfoService,
        private priceElasticityService: PriceElasticityService
    ) {
        this.subscriptions = [];
        this.userViews = [];
    }

    /**
     * Initialize component
     *
     * @member PriceElasticityComponent
     */
    ngOnInit(): void {
        const insightId = this.routeService.getQueryParam('insightId');
        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.PRICE_ELASTICITY.type)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.deliverableViews = deliverableViews;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            forkJoin([
                this.priceElasticityService.getDefaultFilterData(deliverableViews),
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<PriceElasticityFilter>(report.id, insightId),

            ]).subscribe(([defaultViewFilters, userViews, insightFilter]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, DeliverableType.PRICE_ELASTICITY.type, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));

                this.subscriptions.push(combineLatest([
                    this.priceElasticityService.getPriceElasticityFilter(),
                    this.priceElasticityService.getPriceElasticity(),
                ]).subscribe(([filter, priceElasticity]) => {
                    this.filter = filter;
                    this.priceElasticityDeliverableView = priceElasticity;
                    this.imagePath = this.priceElasticityService.getImagePath(this.priceElasticityDeliverableView);
                    this.filterService.update(this.filter);
                    this.deliverableData = {
                        title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Price Elasticity') : '',
                        deliverable: {
                            deliverableViewId: this.priceElasticityDeliverableView.id,
                            filter: this.filter
                        }
                    };
                }));

            });

        });
        this.subscriptions.push(subscription);
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as PriceElasticityFilter;
        this.filterService.update(userView.filter);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        setTimeout(() => {
        this.isInsightEnable = true;
        this.deliverableData = {
            title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Price Elasticity') : '',
            deliverable: {
                deliverableViewId: this.priceElasticityDeliverableView.id,
                filter: this.filter
            }
        };
        },100);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Cleanup hook.
     *
     * @member PriceElasticityComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }


}
