export enum DataType {
  LIST = 'list',
  CHECKBOX = 'checkbox',
  RADIO = 'radio'
}

export interface DropdownItem<T> {
  label: string;
  value: T;
  selected: boolean;
}

export interface DropdownData<T> {
  dropdownLabel: string | (() => string);
  dataType: DataType.LIST | DataType.CHECKBOX | DataType.RADIO;
  contentTitle?: string;
  groupSelect?: string;
  data: Array<DropdownItem<T>>;
}

export interface DropdownTree<T> {
    dropdownLabel?: string | (() => string);
    dropdownSubLabel?: string;
    dataType?: DataType.LIST | DataType.CHECKBOX | DataType.RADIO;
    contentTitle?: string;
    groupSelect?: string;
    data?: Array<TreeItem<T>>;
    dropdownName?: string;
}

export interface TreeItem<T> {
    label?: string;
    data?: Array<TreeItem<T>>;
    value?: T;
    selected?: boolean;
}
