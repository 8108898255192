<ns-benchmark-concepts
    [report]="report"
    [concepts]="concepts"
    [organization]="reportOrg"
    [benchmark]="benchmarks"
    [subgroup]="dbSubgroup"
    [activationProfileDeliverableView]="activationProfileDeliverableView"
    [factorsDeliverableView]="factorsDeliverableView"
    [financialPotentialDeliverableView]="financialPotentialDeliverableView">
</ns-benchmark-concepts>
