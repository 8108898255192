import { Component, Input, OnInit } from '@angular/core';
import { StringHelper } from '@platform/services/string-helper.service';

/**
 * `TableHeaderComponent` creates factors subgroup means column header with title,
 * size and range.
 *
 * @example
 *   <qp-factors-subgroup-means-table-header
 *     [title]="title"
 *     [index]="columnIndex"
 *     [size]="nSize" >
 *   </qp-factors-subgroup-means-table-header>
 *
 * @param {string} title - The title of the column header.
 * @param {number} index - The index of the column.
 * @param {number} size - The n size.
 *
 * @export
 * @class TableHeaderComponent
 * @implements {OnInit}
 */

@Component({
  selector: 'qp-factors-subgroup-means-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  /**
   * The title of the column header.
   *
   * @property
   * @type {string}
   * @memberof TableHeaderComponent
   */
  @Input() title: string;

  /**
   * Index of the current column.
   *
   * @property
   * @type {number}
   * @memberof TableHeaderComponent
   */
  @Input() index: number;

  /**
   * N size.
   *
   * @property
   * @type {number}
   * @memberof TableHeaderComponent
   */
  @Input() size: number;


  /**
 * Creates an instance of ChartHeaderComponent.
 *
 * @constructor
 * @param {StringHelper} stringHelper
 * @param {SurveyQuestionService} surveyQuestionService
 * @memberof TableHeaderComponent
 */


  constructor(
    private stringHelper: StringHelper
  ) { }

  ngOnInit(): void {
  }

  /**
   * Returns label alphabet from index.
   *
   * @returns {string}
   * @memberof TableHeaderComponent
   */
  getColumnLabel(): string {
    const index = this.index;
    return this.stringHelper.alphabetSeries(index);
  }

}
