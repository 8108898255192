import {combineLatest, Subscription} from 'rxjs';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {Component, OnInit, Output, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductServiceFactory} from '@platform/services/product-factory.service';
import {EventEmitter} from '@angular/core';
import {
    MatLegacyPaginator as MatPaginator,
    MatLegacyPaginatorIntl as MatPaginatorIntl
} from '@angular/material/legacy-paginator';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {RouterService} from '@platform/services/router.service';
import {NavigationService} from '@platform/services/navigation.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {ReportService} from '@platform/services/report.service';

@Component({
    selector: 'ns-deliverables-header',
    templateUrl: './deliverables-header.component.html',
    styleUrls: ['./deliverables-header.component.scss']
})
export class DeliverablesHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

    @Output() selectionChange = new EventEmitter<string>();
    public deliverableLabel: string;
    public deliverableInfos: Array<DeliverableInfo>;
    public currentDeliverableInfo: DeliverableInfo;
    public url: string;
    public length: number;
    public currentPage: number;
    public nextLabel = '';
    public prevLabel = '';
    private subscriptions: Subscription[];

    @ViewChild('MatPaginator', {static: false}) paginator: MatPaginator;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private routerService: RouterService,
                private productServiceFactory: ProductServiceFactory,
                private navigationService: NavigationService,
                private mixpanelService: MixpanelService,
                private reportService: ReportService,
                private deliverableInfoService: DeliverableInfoService) {
    }

    ngOnInit(): void {
        this.subscriptions = [];
        const deliverableInfos$ = this.productServiceFactory.getService().getDeliverableInfos(false);
        this.url = this.routerService.getEndPath();
        const infoSubscription = combineLatest([this.reportService.fetchReportFromStore(), deliverableInfos$]).subscribe(([report, deliverableInfos]) => {
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverableInfos(deliverableInfos).filter(it => (undefined === it.showDeliverable || null === it.showDeliverable || it.showDeliverable));
            this.length = this.deliverableInfos.length;
            this.deliverableInfos.forEach(
                (deliverableInfo, index) => {
                    if (this.url.toString().includes(deliverableInfo.path)) {
                        this.currentDeliverableInfo = deliverableInfo;
                        this.deliverableLabel = this.mixpanelService.getMixpanelLabel(this.currentDeliverableInfo?.type);
                        this.currentPage = index;
                        if (this.currentPage + 1 < this.length) {
                            this.nextLabel = this.deliverableInfos[this.currentPage + 1].label;
                        }
                        if (this.currentPage > 0) {
                            this.prevLabel = this.deliverableInfos[this.currentPage - 1].label;
                        }
                    }
                }
            );
        });

        this.subscriptions.push(infoSubscription);
    }

    /**
     * After view init hook.
     */
    ngAfterViewInit() {
        const paginationIntl = new MatPaginatorIntl();
        paginationIntl.nextPageLabel = this.nextLabel;
        paginationIntl.previousPageLabel = this.prevLabel;
        this.paginator._intl = paginationIntl;
    }

    /**
     * Navigate back to deliverables landing page
     */
    public toHome() {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.navigateCard);
        this.navigationService.goToDeliverablesRoute();
    }

    /**
     * Navigate to next and previous deliverables
     * @param evt
     */
    public navigate(evt: { pageIndex: number; }) {
        this.deliverableInfos.forEach(
            (item, index) => {
                if (index === evt.pageIndex) {
                    let url = this.routerService.getCurrentURL().split('/');
                    url.splice(url.length - 1, 1, item.path);
                    this.router.navigate([url.join("/")]);
                    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.navigateArrows);
                }
            }
        );
    }

    /**
     * Navigate to deliverables via dropdown
     */
    navigateToDeliverable(path): void {
        let url = this.routerService.getCurrentURL().split('/');
        url.splice(url.length - 1, 1, path);
        this.router.navigate([url.join("/")]);
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.navigateDropdown);
    }

    /**
     * View Change
     * @param evt { string }
     */
    changedView(evt: string) {
        this.selectionChange.emit(evt);
    }

    /**
     * Get full path to redirect to deliverable page.
     * @param deliverablePath { string }
     */
    getDeliverableRedirectPath(deliverablePath: string) {
        return this.deliverableInfoService.getDeliverableRedirectPath(deliverablePath);
    }

    /**
     * Clean up the component.
     */
    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }
}
