import {State} from '@platform/store/state/app.state';
import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import {environment} from '@src/environments/environment';
import * as fromReport from '@platform/store/reducers/report.reducer';
import * as fromDeliverableView from '@platform/store/reducers/deliverable-view.reducer';
import * as fromRouter from '@ngrx/router-store';
import * as fromFilter from '@platform/store/reducers/filter.reducer';
import * as fromConcept from '@platform/store/reducers/concept.reducer';
import * as fromSubgroup from '@platform/store/reducers/subgroup.reducer';
import * as fromUserInfo from '@platform/store/reducers/user-info.reducer';
import * as fromReportFile from '@platform/store/reducers/report-file.reducer';
import * as fromUserView from '@platform/store/reducers/user-view.reducer';
import * as fromInsight from '@platform/store/reducers/insight.reducer';
import * as fromViewMetaInfo from '@platform/store/reducers/view-meta-info.reducer';
import * as fromArchive from '@platform/store/reducers/archive.reducer';
import * as fromOrganization from '@platform/store/organization/organization.reducer';
import * as fromBenchmark from '@platform/store/benchmark/benchmark.reducer';
import * as fromDeliverableConfigurations from '@platform/store/deliverable-configuration/deliverable-configuration.reducer';

/**
 * `ActionReducerMap` for all reducers for the `State`.
 *
 * @export
 * @property {ActionReducerMap<State>} reducers
 */
export const reducers: ActionReducerMap<State> = {

    /**
     * UserInfo reducer.
     */
    userInfo: fromUserInfo.reducer,

    /**
     * Report reducer.
     */
    report: fromReport.reducer,

    /**
     * Router reducer.
     */
    router: fromRouter.routerReducer,

    /**
     * Deliverable view reducer.
     */
    deliverableViews: fromDeliverableView.reducer,

    /**
     * Filter reducer.
     */
    filter: fromFilter.reducer,

    /**
     * Concepts reducer.
     */
    concepts: fromConcept.reducer,

    /**
     * Subgroups reducer.
     */
    subgroups: fromSubgroup.reducer,

    /**
     * Report files reducer.
     */
    reportFiles: fromReportFile.reducer,

    /**
     * UserViews reducer.
     */
    userViews: fromUserView.reducer,

    /**
     * Insight reducer.
     */
    insights: fromInsight.reducer,

    /**
     * View meta info reducer.
     */
    viewMetaInfo: fromViewMetaInfo.reducer,

    /**
     * Archive reducer.
     */
    archive: fromArchive.reducer,

    /**
     * Organization reducer.
     */
    organizations: fromOrganization.reducer,

    /**
     * Benchmark reducer.
     */
    benchmarks: fromBenchmark.reducer,
    /**
     * DeliverableConfiguration reducer
     */
    deliverableConfiguration: fromDeliverableConfigurations.reducer,
};

/**
 * Exports `NgRx` logger object.
 *
 * @export
 * @param {ActionReducer<State>} reducer
 * @returns {ActionReducer<State>}
 */
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function (state: State, action: any): State {
        // console.log('state', state);
        // console.log('action', action);
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 *
 * @export
 * @property
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];

