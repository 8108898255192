/**
 * Deliverable view types for Survey Question.
 *
 * @export
 * @enum {string}
 */
export enum DeliverableViewType {
 /**
   * Concept deliverable view type.
   */
  TARGETGROUP = 'target-group',

  /**
   * subgroup deliverable view type.
   */
  OLD = 'old-target-group'
}
