/**
 * Filter name keys and values.
 *
 * @export
 * @property
 */
export const filterNames = {
    keyMeasures: 'keyMeasures',
    credibility: 'credibility',
    advantage: 'advantage',
    uniqueness: 'uniqueness(Trial)',
    purchaseIntent: 'purchase-intent',
    priceValue: 'price/value',
    needDesire: 'need/desire',
    tertile: 'tertile',
    tertileMean: 'tertile-mean',
    noDecimals: 'no-decimals',
    oneDecimalPlace: 'one-decimal-place',
    twoDecimalPlace: 'two-decimal-place',
    avg: 'average',
    aboveAvg: 'above-average',
    belowAverage: 'below-average'
};

/**
 * KeyMeasures Map to map property keys of value and rank for all key measures available
 * the key is the id of each key measure in the show drop down
 * id of show dropdown is the lowercase first word of the respective key measure name coming from api call
 *
 * @export
 * @property
 */
export const keyMeasureMap = {
    CADV3: {
        value: 'advantageValue',
        rank: 'advantageRank'
    },
    CBEL: {
        value: 'credibilityValue',
        rank: 'credibilityRank'
    },
    CNAD: {
        value: 'uniquenessValue',
        rank: 'uniquenessRank'
    },
    CPI: {
        value: 'purchaseIntentValue',
        rank: 'purchaseIntentRank'
    },
    CPI1: {
        value: 'purchaseIntentRetailValue',
        rank: 'purchaseIntentRetailRank'
    },
    CPI2: {
        value: 'purchaseIntentPublicValue',
        rank: 'purchaseIntentPublicRank'
    },
    CPV: {
        value: 'priceValue',
        rank: 'priceRank'
    },
    CREL3: {
        value: 'needDesireValue',
        rank: 'needDesireRank'
    }
};

export const KeyMeasureConfig = {
    'Advantage': {
        value: 'CADV3Value'
    },
    'Credibility': {
        value: 'CBELValue'
    },
    'Uniqueness (Trial)': {
        value: 'CNADValue'
    },
    'Purchase Intent (Trial)': {
        value: 'CPIValue'
    },
    'Purchase Intent (Trial, Retail)': {
        value: 'CPI1Value'
    },
    'Purchase Intent (Trial, Public)': {
        value: 'CPI2Value'
    },
    'Price / Value (Trial)': {
        value: 'CPVValue'
    },
    'Need / Desire': {
        value: 'CREL3Value'
    },
};


export enum PurchaseEnum {
    RETAIL = 'Retail',
    PUBLIC = 'Public'
}
