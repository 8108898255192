import { DeliverableType} from '@app/deliverables/deliverable-type.enum';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Subscription } from 'rxjs';
import { TreeNodeData } from '../../models/tree-node-data.interface';
import { ActivationProfileService } from '../../services/activation-profile.service';
import {ActivationProfileMetaInfo} from '@app/deliverables/activation-profile/models/view-meta-info.model';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';

export interface NodeToggleEvent {
  node: TreeNodeData;
  isExpanded: boolean;
}

@Component({
  selector: 'ap-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.scss']
})
export class HeaderSectionComponent implements OnInit, OnDestroy {

  @Input() headerData: TreeNodeData[] = [];
  @Output() nodeToggled = new EventEmitter<NodeToggleEvent>();
  @Input() bold: string;

  datasource = new MatTreeNestedDataSource<TreeNodeData>();

  private subscriptions: Subscription[] = [];

  constructor(
    public activationProfileService: ActivationProfileService,
    private viewMetaInfoService: ViewMetaInfoService,
  ) { }

  ngOnInit(): void {
    this.datasource.data = this.headerData;
    this.activationProfileService.treeControl.dataNodes = this.headerData;
    this.setupExpansionSubscription(this.activationProfileService.treeControl.dataNodes || []);
  }

  setupExpansionSubscription(nodes: TreeNodeData[]) {
    const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
    let subscription: Subscription;
    nodes.forEach(node => {
      subscription = this.viewMetaInfoService.get<ActivationProfileMetaInfo>(deliverableType)
      .subscribe(viewMetaInfo => {
        if (!!node.children && node.children.length > 0) {
          if (viewMetaInfo[node.key]) {
            this.activationProfileService.treeControl.expand(node);
          } else {
            this.activationProfileService.treeControl.collapse(node);
          }
          this.setupExpansionSubscription(node.children);
        }
      });
      this.subscriptions.push(subscription);
    });
  }

  hasChild = (_: number, node: TreeNodeData) => !!node.children && node.children.length > 0;

  toggleNode(node: TreeNodeData) {
    const isExpanded = this.activationProfileService.treeControl.isExpanded(node);
    this.nodeToggled.emit({node, isExpanded});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( sub => sub.unsubscribe());
  }

  getTruncateWidth(node: TreeNodeData): string {
    return (220 - node.level * 10) + 'px';
  }
}
