import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {CorrelationsService} from '@app/deliverables/correlations/services/correlation.service';
import { UserService } from '@platform/services/user.service';
import { ViewMetaInfoService } from '@platform/services/view-meta-info.service';
import {CorrelationsMetaInfo} from '@app/deliverables/correlations/models/view-meta-info.model';
import {CorrelationsDeliverableView} from '@app/deliverables/correlations/models/correlations.model';
import {CorrelationsFilter} from '@app/deliverables/correlations/models/filter.model';
import {KeyMeasureConfig} from '@app/deliverables/correlations/filter/show/filter-names';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {TranslateService} from '@ngx-translate/core';
import {MathService} from '@src/assets/utils/math.service';
import * as numeral from 'numeral';
@Component({
  selector: 'ns-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements OnInit, OnDestroy {
  /**
   * Correlations deliverable view data.
   *
   * @type {CorrelationsDeliverableView}
   * @member DataTableComponent
   */
   public correlations: CorrelationsDeliverableView;
  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member DataTableComponent
   */
   public subscriptions: Array<Subscription>;
  /**
   * Datasource object for the angular material table.
   *
   * @member DataTableComponent
   */
   public dataSource: MatTableDataSource<any>;
  /**
   * Array of displayed columns keys.
   *
   * @type {Array<string>}
   * @member DataTableComponent
   */
   public displayedColumns: Array<string>;

  /**
   * View child for angular material table sorting.
   *
   * @type {MatSort}
   * @member DataTableComponent
   */
   @ViewChild(MatSort) sort: MatSort;

  /**
   * Array of static column indexes passed to `<ns-swipe-table>`
   * component.
   *
   * @type {number[]}
   * @member DataTableComponent
   */
   public staticColumns: number[];

  /**
   * All column headers for the Correlations table.
   *
   * @type {Array<any>}
   * @member DataTableComponent
   */
   public colHeaders: Array<any>;

  /**
   * Correlations deliverable view filter object.
   *
   * @type {CorrelationsFilter}
   * @member DataTableComponent
   */
   public filter: CorrelationsFilter;

  /**
   * The CorrelationsDeliverableView.
   * @type {CorrelationsDeliverableView} correlationsDeliverableView
   * @memberOf DataTableComponent
   */
  public correlationsDeliverableView: CorrelationsDeliverableView;
  /**
   * toggle insight btn
   * @type {Boolean} isInsightEnable
   * @memberOf DataTableComponent
   */
   public isInsightEnable = false;

   public isInternalUser: Boolean;

   public correlationsData: Array<any>;

   public baseSize: Number;

   public keyMeasureConfig: any;

  /**
   * Meta info for Correlations
   *
   * @type {CorrelationsDeliverableView}
   * @memberOf DataTableComponent
   */
   public viewCorrelationsMetaInfo: CorrelationsMetaInfo;

  /**
   * Spinner.
   *
   * @type {Array<any>}
   * @member DataTableComponent
   */
   public displayProgressSpinner = false;

  /**
   * disable a button
   */
  public disableBtn: boolean;

  public colHeader: string;

  public sortDirection: string;

  /**
   * Array of all column widths passed to `<ns-swipe-table>`
   * component.
   *
   * @type {number[]}
   * @member DataTableComponent
   */
  public columnWidths: number[];

  /**
   *
   * @param correlationsService
   * @param viewMetaInfoService
   * @param userService
   * @param mixpanelService
   */
  constructor(private correlationsService: CorrelationsService,
              private viewMetaInfoService: ViewMetaInfoService,
              private userService: UserService,
              private translate: TranslateService,
              private mixpanelService: MixpanelService,
              private mathservice: MathService) {
    this.displayedColumns = [];
    this.subscriptions = [];
    this.staticColumns = [];
    this.keyMeasureConfig = KeyMeasureConfig;
  }

  /**
   * Initialize the correlations component view.
   *
   * @member DataTableComponent
   */
  ngOnInit(): void {
    const user$ = this.userService.getUser();
    const filter$ = this.correlationsService.getCorrelationsFilter();
    const deliverableType = DeliverableType.CORRELATIONS.type;
    const viewMetaInfo$ = this.viewMetaInfoService.get<CorrelationsMetaInfo>(deliverableType);
    const correlation$ = this.correlationsService.getCorrelations();
    const subscription = combineLatest([correlation$, filter$, viewMetaInfo$, user$])
      .pipe(debounceTime(1))
      .subscribe(([correlation, filters, viewMetaInfo, user]) => {
        this.isInternalUser = user.isInternalUser;
        this.correlationsDeliverableView = correlation;
        this.viewCorrelationsMetaInfo = viewMetaInfo;
        this.filter = filters;
        this.baseSize = this.checkBaseLabel() ? correlation.statements[0].statementData[0].baseSize : null;
        this.correlationsData = this.correlationsService.fetchRecords(filters, correlation);
        this.colHeaders = filters.show.keyMeasures[this.correlationsService.viewName].filter(measure => measure.isSelected);
        if (this.colHeaders.length) {
          this.colHeaders.unshift({name: 'statement'}, {name: 'attribute', id: 2, value: 1});
        }
        this.displayedColumns = this.colHeaders.map(header => header.name);
        if (Object.keys(viewMetaInfo).length > 0 && viewMetaInfo.sortInfo && Object.keys(viewMetaInfo.sortInfo).length > 0) {
          this.setSortState(viewMetaInfo);
          if (!(this.colHeader === viewMetaInfo.sortInfo.columnHeaderName && this.sortDirection === viewMetaInfo.sortInfo.sortDirection)) {
              this.setSortedDataForDataTable(this.correlationsData, viewMetaInfo.sortInfo.columnHeaderName, viewMetaInfo.sortInfo.sortDirection, false);
          }
        } else {
          this.dataSource = new MatTableDataSource(this.correlationsData);
        }
        this.staticColumns = [0];
        this.columnWidths = this.getColumnWidths(filters, this.colHeaders);
      }
    );
    this.subscriptions.push(subscription);
  }

  /**
   * sorting change triggered - sorting functionality for datatable view
   */
  public sortData(sort: Sort): void {
    const data = this.correlationsData.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      const deliverableType = DeliverableType.CORRELATIONS.type;
      const viewInfo = { deliverableType, sortInfo: {}};
      this.viewMetaInfoService.update(viewInfo, deliverableType);
      return;
    }
    this.colHeader = sort.active;
    this.sortDirection = sort.direction;
    this.setSortedDataForDataTable(data, sort.active, sort.direction, true);
  }

  /**
   * setSortedDataForDataTable
   * @param data
   * @param sortHeaderName
   * @param sortDirection
   * @param isSortEvent
   */
  public setSortedDataForDataTable(data, sortHeaderName, sortDirection, isSortEvent) {
    const isAsc = sortDirection === 'asc';
    let headerDetails;
    this.colHeaders.forEach(header => {
      data.sort((a, b) => {
        if (sortHeaderName === header.name) {
          headerDetails = header;
          if (header.name === 'attribute') {
            return this.compare(a[header.name], b[header.name], !isAsc);
          } else {
            return this.compare(a[this.keyMeasureConfig[header.name].value], b[this.keyMeasureConfig[header.name].value], isAsc);
          }
        }
      });
      if (header.name === 'attribute') {
        data.sort((a, b) => {
         return this.rearrangeDecimalData(a, b, header.name, isAsc);
        });
      }
    });

    /* to update metainfo on sort */

    if (isSortEvent) {
      this.setUserViewSorting(headerDetails.id, headerDetails.name, sortDirection, data);
    }

    const dataSource = new MatTableDataSource(data);
    if (!isSortEvent) {
      dataSource.sort = this.sort;
    }
    this.dataSource = new MatTableDataSource(data);
  }

  /**
   * resort attribute with decimal values
   * @param a
   * @param b
   * @param header.name
   * @param isAsc
   * **/
  private rearrangeDecimalData(a, b, header, isAsc) {
    if (a.attribute.indexOf('-') === -1) { return; }
    if (a.attribute.substr(0, a.attribute.indexOf('-') - 1) === b.attribute.substr(0, b.attribute.indexOf('-') - 1)) {
      return this.compare(a[header], b[header], isAsc);
    }
  }

  /**
   * setSortState
   * @param viewMetaInfo
   */
  public setSortState(viewMetaInfo) {
    const sortState: Sort = {active: viewMetaInfo.sortInfo.columnHeaderName, direction: viewMetaInfo.sortInfo.sortDirection};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    // this.sort.sortChange.emit(sortState);
  }

  /**
   * compare
   * @param a
   * @param b
   * @param isAsc
   */
  public compare(a: number | string, b: number | string, isAsc: boolean): number {
      const MAX_INT = Number.MAX_SAFE_INTEGER;

      // Replace undefined with MAX_INT for sorting purposes
      const valueA = (a === undefined) ? MAX_INT : a;
      const valueB = (b === undefined) ? MAX_INT : b;

      return (valueA < valueB ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Updates the sorting info in the ViewMetaInfo store
   * @param colHeaderId
   * @param colHeaderName
   */
  setUserViewSorting(colHeaderId: any, colHeaderName: string, sortDirection, correlationData: Array<any>) {
    const deliverableType = DeliverableType.CORRELATIONS.type;
    const viewInfo = { deliverableType, sortInfo: {}};
    viewInfo.sortInfo['columnHeaderId'] = colHeaderId;
    viewInfo.sortInfo['columnHeaderName'] = colHeaderName;
    viewInfo.sortInfo['sortDirection'] = sortDirection;
    const statements = [];
    correlationData.forEach(data => statements.push(data.statement));
    viewInfo.sortInfo['statements'] = statements;
    this.viewMetaInfoService.update(viewInfo, deliverableType);
  }

  /**
   * getCorrelationRank
   * @param rank
   */
  getCorrelationRank(rank): string {
    switch (rank) {
        case 1:
            return 'weak'; // below avg
        case 2:
            return 'moderate'; // avg
        case 3:
            return 'strong'; // above avg
        default:
            return '';
    }
  }

  /**
   * getAttributeRank
   * @param rank
   */
  getAttributeRank(rank): string {
    switch (rank) {
      case 1:
        return 'Below Avg.';
      case 2:
        return 'Average';
      case 3:
        return 'Above Avg.';
      default:
        return '';
    }
  }

  /**
   * getAttributeRatingClassName
   * @param value
   */
  getAttributeRatingClassName(value): string {
    if (value.includes('Above Avg.')) {
      return 'above';
    } else if (value.includes('Average')) {
      return '';
    } else {
      return 'below';
    }
  }

  /**
   * Updates displayed columns.
   *
   * @param {number[]} visibleColumns array of visible column indexes.
   */
  public update(visibleColumns: number[]): void {
    const columns = [];
    this.colHeaders.forEach((item, index) => {
      if (visibleColumns.indexOf(index) >= 0) {
        columns.push(item.name);
      }
    });
    this.displayedColumns = columns;
  }

  /**
   * Triggers mixpanel event on clicking 'Next' button in table
   *
   * @param none
   */
  nextPage(): void {
    this.mixpanelService.track(MixpanelLabel.correlations, MixpanelEvent.nextPage);
  }

  /**
   * Triggers mixpanel event on clicking 'Previous' button in table
   *
   * @param none
   */
  previousPage(): void {
    this.mixpanelService.track(MixpanelLabel.correlations, MixpanelEvent.previousPage);
  }

  /**
   * Return an array of column widths for all columns in the table.
   *
   * @param {CorrelationsFilter} The correlations filter
   * @param {any[]} colHeaders The array of Headers
   */
  private getColumnWidths(filter: CorrelationsFilter, colHeaders: any[]): number[] {
    const minWidth = 70;
    const columnWidths = Array(colHeaders.length).fill(minWidth);
    columnWidths[0] = 140;
    return columnWidths;
  }

  private checkBaseLabel(): Boolean {
    const keyMeasure = this.filter.show.keyMeasures[this.correlationsService.viewName].find((key) => key.isSelected === true);
      return (keyMeasure && keyMeasure.isSelected) && (this.filter.show.average || this.filter.show.belowAverage || this.filter.show.aboveAverage);
  }

    getSortOrder(headerName: string) {
        let sortOrder = this.translate.instant('sort.lowest.to.highest');
        if (this.sort && this.sort.active === headerName) {
            if (this.sort.direction === 'asc') {
                sortOrder = this.translate.instant('sort.highest.to.lowest');
            } else if (this.sort.direction === 'desc') {
                sortOrder = this.translate.instant('sort.reset.to.default.order');
            }
        }
        return sortOrder;
    }

    roundAttributeMean(num: number, pattern: string) {
        const roundedValue = this.mathservice.round(num, pattern);
        const value = numeral(roundedValue).format(pattern);
        return value;
    }

    formatAttribute(value: string): string {
        if (!value) {
            return value;
        }
        const parts = value.split('-').map(part => part.trim());

        // Case 1: Handling this.filter.show.tertile
        if (this.filter.show.tertile) {
            return parts[0];
        }

        // Case 2: Handling this.filter.show.tertileMean.isSelected
        if (this.filter.show.tertileMean && this.filter.show.tertileMean.isSelected) {
            let attributeRatingValue: string;
            const ratingValue = parseFloat(parts[1]);
            if (this.filter.show.tertileMean.noDecimal) {
                attributeRatingValue = this.roundAttributeMean(ratingValue, '0');
            } else if (this.filter.show.tertileMean.twoDecimal) {
                attributeRatingValue = this.roundAttributeMean(ratingValue, '0.00');
            } else {
                attributeRatingValue = this.roundAttributeMean(ratingValue, '0.0');
            }
            return `${parts[0]} - ${attributeRatingValue}`;
        }
    }

  /**
   * Cleanup hook.
   *
   * @member DataTableComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }



}
