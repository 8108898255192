import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlyoutMenuComponent } from './flyout-menu.component';
import {MatSidenavModule} from "@angular/material/sidenav";

@NgModule({
    declarations: [
        FlyoutMenuComponent
    ],
    exports: [
        FlyoutMenuComponent
    ],
    imports: [
        CommonModule,
        MatSidenavModule
    ]
})
export class FlyoutMenuModule { }
