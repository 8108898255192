import { Action, createReducer, on } from '@ngrx/store';
import { UserInfoState } from '@platform/store/state/user-info.state';
import { addUserInfo } from '@platform/store/actions/user-info.actions';

/**
 * Reducer for all concept actions.
 */
const userInfoReducer = createReducer(
  null,
  on(addUserInfo, (state, { userInfo }) => {
    const newUserInfo = {...userInfo};
    return newUserInfo;
  })
);

/**
 * Report `NgRx` reducer.
 *
 * @export
 * @param {UserInfoState} state
 * @param {UserInfoActions} action
 * @returns {UserInfoState}
 */
export function reducer(state: UserInfoState | undefined, action: Action): UserInfoState {
  return userInfoReducer(state, action);
}
