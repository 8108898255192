import {createAction, props} from '@ngrx/store';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';

/**
 * Action for adding deliverableConfiguration to application state.
 *
 * @export
 */
export const addDeliverableConfiguration = createAction(
    '[DeliverableConfiguration] Add Deliverable Configuration',
    props<{ deliverableConfiguration: DeliverableConfiguration }>()
);
export const addDeliverableConfigurations = createAction(
    '[DeliverableConfiguration] Add DeliverableConfigurations',
    props<{ deliverableConfigurations: DeliverableConfiguration[] }>()
);
export const updateDeliverableConfiguration = createAction(
    '[DeliverableConfiguration] Update Deliverable Configuration',
    props<{ deliverableConfiguration: DeliverableConfiguration }>()
);
