/**
 * Default colors for all stat types.
 *
 * @export
 * @property
 */
export const defaultFinancialPotentialColors = {
  definitelyWouldBuy: '#A43795',
  probablyWouldBuy: '#B65FAA',
  mightOrMightNotBuy: '#6F7176',
  probablyWouldNotBuy: '#989898',
  definitelyWouldNotBuy: '#CBCBCB',
  trialUnitsAmongFavs: '#A43795',
  frequencyAmongFavs: '#A43795'
};
