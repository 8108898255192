import { ChartOption } from "../forecast-variety-split-deliverable/forecast-variety-split-deliverable-data-map";

export enum TreeNodeDataType {
    number,
    string
}

export interface TreeNode {
    name: string;
    key: string;
    children?: TreeNode[];
    value?: any;
    level: number;
    dataType: TreeNodeDataType;
    class?: string;
    showCurrency?: boolean;
    showPercentage?: boolean;
    fractionDigits?: number;
}

export const forecastVolumeEstimateMapData: TreeNode[] = [
    {
        key: 'marketingPlan',
        name: 'MARKETING PLAN',
        level: 0,
        dataType: TreeNodeDataType.string,
        showCurrency: false,
        showPercentage: false,
        fractionDigits: 0,
        children: [],
        value: ""
    },
    {
        key: 'totalYearOneSpend',
        name: 'TOTAL YEAR 1 SPEND',
        level: 0,
        dataType: TreeNodeDataType.number,
        showCurrency: true,
        showPercentage: false,
        fractionDigits: 0,
        children: [
            {
                key: 'advertisingSpend',
                name: 'Year 1 Advertising Spend',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: true,
                showPercentage: false,
                fractionDigits: 1,
                children: [
                    {
                        key: 'yearOneTVBudget',
                        name: 'TV Year 1 Budget',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'yearOneDigitalBudget',
                        name: 'Digital Year 1 Budget',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'yearOneOtherBudget',
                        name: 'Other Year 1 Budget',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    }
                ],
                value: ""
            },
            {
                key: 'consumerPromotionSpend',
                name: 'Year 1 Consumer Promo Spend',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: true,
                showPercentage: false,
                fractionDigits: 1,
                children: [
                    {
                        key: 'yearOneCouponsBudget',
                        name: 'Coupons Year 1 Budget',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'yearOneSamplesBudget',
                        name: 'Samples Year 1 Budget',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    }
                ],
                value: ""
            },
            {
                key: 'tradePromotionSpend',
                name: 'Year 1 Trade Promo Spend',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: true,
                showPercentage: false,
                fractionDigits: 1,
                children: [],
                value: ""
            }
        ],
        value: ""
    },
    {
        key: 'Assumptions',
        name: 'ASSUMPTIONS',
        level: 0,
        dataType: TreeNodeDataType.string,
        showCurrency: false,
        showPercentage: false,
        fractionDigits: 0,
        children: [
            {
                key: 'productPerformance',
                name: 'Product Performance Assumption',
                level: 1,
                dataType: TreeNodeDataType.string,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            },
            {
                key: 'consumerPromotionLevel',
                name: 'Consumer Promo Assumption',
                level: 1,
                dataType: TreeNodeDataType.string,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            },
            {
                key: 'tradePromotionLevel',
                name: 'Trade Promo Assumption',
                level: 1,
                dataType: TreeNodeDataType.string,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            }
        ],
        value: ""
    },
    {
        key: 'volumeComponents',
        name: 'VOLUME COMPONENTS',
        level: 0,
        dataType: TreeNodeDataType.number,
        showCurrency: false,
        showPercentage: false,
        fractionDigits: 0,
        children: [
            {
                key: 'houseHolds',
                name: 'Households',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            },
            {
                key: 'trailRate',
                name: 'Trial Rate',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: true,
                fractionDigits: 1,
                children: [],
                value: ""
            },
            {
                key: 'trailUnits',
                name: 'Trial Units',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 1,
                children: [],
                value: ""
            },
            {
                key: 'repeatRate',
                name: 'Repeat Rate',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: true,
                fractionDigits: 1,
                children: [],
                value: ""
            },
            {
                key: 'repeatsPerRepeater',
                name: 'Repeats per Repeater',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 1,
                children: [],
                value: ""
            },
            {
                key: 'repeatUnits',
                name: 'Repeat Units',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 1,
                children: [],
                value: ""
            }
        ],
        value: ""
    },
]

export const volumeEstimateChartOptionMap: ChartOption[] = [
    {
        "id": 1,
        "displayName": "Density",
        "elementType": "radioBtn",
        "originalName": "density",
        "isVisible": true,
        "children": [{
            "id": 1,
            "displayName": "Default",
            "isVisible": true,
            "checked": true,
            "value": "default"
        }, {
            "id": 2,
            "displayName": "Large",
            "isVisible": true,
            "checked": false,
            "value": "large"
        }],
    },
]