import { Component, EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

export const FILTER_NAME_LIST = {
  projects: 'Project Associations',
  solutions: 'Solution Types',
  statuses: 'Statuses',
  country: 'Countries',
  locales: 'Locales'
};

export class CheckboxArray {
  name: string;
  subtasks: Array<SubTasks>;
  selected: boolean;
  allComplete: boolean;
  selectedOptionsCount?: number;
  singleSelectedOptionObject?: SubTasks;
  tooltipText?: string;
}

export class SubTasks {
  name: string;
  selected: boolean;
}
@Component({
  selector: 'ns-benchmark-dropdown',
  templateUrl: './benchmark-dropdown.component.html',
  styleUrls: ['./benchmark-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BenchmarkDropdownComponent implements OnInit {

  @Input() displayType: string = 'regular';
  @Input() displayOptions: Array<any> = [];
  @Input() arrayName: string = '';
  @Input() childKey: string = 'subtasks';
  @Input() flagKey: string = 'selected';
  @Input() displayKey: string = '';
  @Input() displayChildKey: string = '';
  @Input() addRadioIndent: boolean = false;

  // emitting the updated values
  @Output() userSelections = new EventEmitter();
  @Output() nullSelections = new EventEmitter();
  @Output() ddMenuClosed = new EventEmitter();

  selectedOption: string = '';
  toggle = false;
  copyOriginal: Array<any> = [];
  typeAheadText: string = '';
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public originalSize: number = 0;
  public parentCheckBoxTobeDisabled = false;  // flag to identify if country filter populated using search box and disable parent list item (All Countries)

  constructor() {}

  public ngOnInit(): void {
    this.originalSize = this.displayOptions[0].length;
    this.copyOriginal = JSON.parse(JSON.stringify(this.displayOptions));

    if (this.displayType === 'checkbox') {
        this.setSelectedValue(this.displayOptions);
    } else {
        if (this.displayOptions.length === 0) {
            return;
        }
        this.selectedOption =
            this.displayOptions[
                this.displayOptions.findIndex((ele) => ele[this.flagKey])
            ][this.displayKey];
        this.userSelections.emit(this.displayOptions);
    }
}

/**
 * Method to mark all parent node based on child node selections.
 *
 * @method updateAllComplete
 * @param parentObj
 * @type  {any}
 * @returns {void}
 */
public updateAllComplete(parentObj: CheckboxArray): void {
    if (!this.parentCheckBoxTobeDisabled) {
        parentObj.selectedOptionsCount = parentObj[this.childKey].filter(
            (t: any) => t[this.flagKey]
        ).length;
        parentObj.allComplete =
            parentObj[this.displayKey] !== null &&
            parentObj[this.childKey].every((t: any) => t[this.flagKey]);
            this.setSelectedValue(this.displayOptions);
    }
    else {
        this.setSelectedValue(this.copyOriginal);
    }
}

/**
 *  Method to know whether or not all the child node of a parent node is selected.
 *
 * @method partialComplete
 * @param parentObj
 * @type  {any}
 * @returns {boolean}
 *
 */
public partialComplete(parentObj: any): boolean {
    if (!parentObj[this.childKey] || parentObj[this.childKey] === null) {
        return false;
    }
    return (
        parentObj[this.childKey].filter((t: any) => t[this.flagKey]).length > 0 &&
        !parentObj.allComplete
    );
}

/**
 * Method to mark all child node of a parent node.
 *
 * @method partialComplete
 * @param {selected, parentObj}
 * @type  {boolean, any}
 * @returns {void}
 *
 */
public setAll(selected: boolean, parentObj: any): void {
    parentObj.allComplete = selected;
    if (!parentObj[this.childKey] || parentObj[this.childKey] === null) {
        return;
    }
    parentObj[this.childKey].forEach((t: any) => (t[this.flagKey] = selected));
    parentObj.selectedOptionsCount = selected
        ? parentObj[this.childKey].length
        : 0;

    this.setSelectedValue(this.displayOptions);
}

/**
 * Set Value for regular dropdown
 * @param arrList
 * @param option
 */
public setValue(arrList: Array<any>, option: any): void {
    arrList.forEach((ele) => {
    if (ele[this.displayKey] === option[this.displayKey]) {
        ele[this.flagKey] = true;
        this.selectedOption = ele[this.displayKey];
    } else {
        ele[this.flagKey] = false;
    }
    });
    this.userSelections.emit(arrList);
}

/**
 * Set Value for dropdown with checkboxs
 * @param data
 */
public setSelectedValue(data: any): void {
    this.selectedOption = '';
    const subArray: Array<any> = data[0][this.childKey];
    data[0].tooltipText = '';
    data[0].selectedOptionsCount = 0;
    subArray.forEach((element) => {
      if (element[this.flagKey]) {
        data[0].tooltipText = data[0].tooltipText
          ? `${data[0].tooltipText}, ${element[this.displayChildKey]}`
          : element[this.displayChildKey];
        data[0].selectedOptionsCount++;
        data[0].singleSelectedOptionObject =
          data[0].selectedOptionsCount === 1 ? element : '';
      }
    });

    if ( !this.parentCheckBoxTobeDisabled && data[0].selectedOptionsCount === data[0].subtasks.length){
        data[0].tooltipText = this.selectedOption = `All ${FILTER_NAME_LIST[this.arrayName]}`;
        data[0].selectedOptionsCount = data[0][this.childKey].length;
    }
    else {
        data[0].allComplete = false;
        if (data[0].selectedOptionsCount > 0) {
            this.selectedOption =
                data[0].selectedOptionsCount > 1
                    ? `${data[0].selectedOptionsCount} ${FILTER_NAME_LIST[this.arrayName]
                    }`
                    : data[0].singleSelectedOptionObject[this.displayChildKey];
        }
    }

    if (data[0].selectedOptionsCount !== 0) {
        this.userSelections.emit(data);
        this.nullSelections.emit(false);
    }
    else if (data[0].selectedOptionsCount == 0 && this.copyOriginal[0].selectedOptionsCount > 0 && this.parentCheckBoxTobeDisabled) {
        this.userSelections.emit(data);
        this.nullSelections.emit(false);
    }
    else {
        this.nullSelections.emit(true);
    }
}

/**
 * Emit Menu Closed
 * @param flag - true if dropdown was close by user/ false if dropdown was open by the user
 */
public emitMenuClosed(flag: boolean): void {
    if (
      flag &&
      this.displayOptions &&
      this.displayOptions[0].selectedOptionsCount === 0 &&
      this.displayType === 'checkbox' &&
      !this.parentCheckBoxTobeDisabled
    ) {
       this.setAll(true, this.displayOptions[0]);
    }
    this.filterData(this.typeAheadText);
    this.ddMenuClosed.emit(flag);

    //clean the search text bar when the dropdown is open and restore all the possible options
    if(!flag){
      this.typeAheadText = '';
      this.filterData(this.typeAheadText);

      if(this.copyOriginal[0].selectedOptionsCount != null && (this.copyOriginal[0].selectedOptionsCount == 0 || this.copyOriginal[0].selectedOptionsCount == this.originalSize)){
        this.arrayName === 'countries' ? this.selectedOption = 'All Countries' : this.selectedOption = 'All Clients'
        this.setAll(true, this.displayOptions[0]);
      }
    }

    //set select Option to All Countries if the user search for some country but did not select any option (all the option are available to be selected) and close the dropdown
    if(flag && this.copyOriginal[0].selectedOptionsCount != null && (this.copyOriginal[0].selectedOptionsCount == 0 || this.copyOriginal[0].selectedOptionsCount == this.originalSize)){
      this.arrayName === 'countries' ? this.selectedOption = 'All Countries' : this.selectedOption = 'All Clients'
    }
  }


/**
 * Filter the dropdown list with the text introduced by the user on the search text box
 * @param inpVal - search text used to filter the original dropdown list
 * @returns
 */
// typeahead search
public filterData(inpVal: string): void {

    for (const ele of this.copyOriginal) {
        if (!ele.subtasks) {
            break;
        }

        const displayObj = this.displayOptions.find(
          (obj) => obj.name === ele.name
        );
        ele.allComplete = displayObj.allComplete;
        if (displayObj && displayObj.subtasks) {
          displayObj.subtasks.forEach((subEle) => {
            const originalSubEle = ele.subtasks.find(
              (obj) => obj.name === subEle.name
            );
            if (originalSubEle) {
              originalSubEle[this.flagKey] = subEle[this.flagKey];
            }
          });
        }
    };

    // to avoid performance problems on the load of the dropdown list
    if (this.originalSize != this.displayOptions.length) {
        this.displayOptions = this.copyOriginal;
        if (this.displayType !== 'regular') {
            this.setSelectedValue(this.displayOptions);
        }
    }

    if (!inpVal.length) {
        this.parentCheckBoxTobeDisabled = false;
        return;
    }

    //to be improve to use event action
    else{
      var button = document.getElementsByName("search-delete-icon")[0] as HTMLInputElement;
      button.hidden = false
    }

    if (this.displayType === 'checkbox') {
        if (this.arrayName === 'countries') {
            if (inpVal.length && inpVal.length > 0) {
                this.parentCheckBoxTobeDisabled = true;
                if (this.selectedOption === 'All Countries') {
                    this.setAll(false, this.displayOptions[0]);
                }
            }
            else {
                this.parentCheckBoxTobeDisabled = false;
            }
        }
    } else {
        this.parentCheckBoxTobeDisabled = false;
    }

    if (this.displayType === 'regular') {
      this.displayOptions = this.displayOptions.filter((ele) =>
        ele[this.displayKey].toLowerCase().includes(inpVal.toLocaleLowerCase())
      );
    }  else if (this.parentCheckBoxTobeDisabled){
        this.displayOptions = this.displayOptions.map((element) => {
            return {
                ...element,
                [this.childKey]: element[this.childKey].filter((subElement) =>
                    subElement[this.displayChildKey]
                        .toLowerCase()
                        .includes(inpVal.toLocaleLowerCase())
                ),
            };
        });
    } else {
      this.displayOptions = this.displayOptions.map((element) => {
        return {
          ...element,
        [this.childKey]: element[this.childKey].filter((subElement) =>
            subElement[this.displayChildKey]
              .toLowerCase()
              .includes(inpVal.toLocaleLowerCase())
          ),
        };
      });
    }
}

  @HostListener('document:keydown', ['$event'])
  closeDDOptions(kbEvnt: KeyboardEvent): void {
    if (kbEvnt.key === 'Escape') {
      this.trigger.closeMenu();
    }
  }

}
