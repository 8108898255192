import { Component } from '@angular/core';

@Component({
  selector: 'ns-deliverable-configuration-body',
  templateUrl: './deliverable-configuration-body.component.html',
  styleUrls: ['./deliverable-configuration-body.component.scss']
})
export class DeliverableConfigurationBodyComponent {

}
