/**
 * Compare view for Price Elasticity.
 *
 * @export
 * @enum {string}
 */
export enum CompareView {
    /**
      * Concept deliverable view type.
      */
    CONCEPT = 'concept',

    /**
     * subgroup deliverable view type.
     */
    SUBGROUP = 'subgroup'
}
