import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UserViewState } from '@platform/store/state/user-view.state';
import { UserView } from '@platform/models/user-view.model';

/**
 * UserViews entity adapter.
 *
 * @export
 * @property {EntityAdapter<UserView>} userViewAdapter - The userView adapter.
*/
export const userViewAdapter: EntityAdapter<UserView> = createEntityAdapter<UserView>();

/**
 * Initial state.
 */
export const initialState: UserViewState = userViewAdapter.getInitialState({});
