import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelEvent } from '@src/assets/utils/mixpanel-enum';

/**
 * `SearchComponent` creates the dropdown filter options for compare,
 * concepts, country, subgroups.
 *
 * @example
 *   <ns-search></ns-search>
 *
 * @export
 * @class SearchComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'ns-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent {

  @Input() mixpanelLabel: string;
  @Input() arrayOfStrings: String[];
  @Output() searchedData = new EventEmitter<String[]>();
  @Output() searchText = new EventEmitter<String>();

  /**
   * The value entered in the search box for verbatims.
   * @type {string}
   */
  public searchValue = '';

  constructor(private mixpanelService: MixpanelService) {
  }

  /**
   * When user clicks search filter out the clicks to only show the verbatims containing the search text.
   * The likes and dislikes need to be reset.  The displayed clicks also needs to be reset.
   */
  onSearchClick(): void {
    this.mixpanelService.track(this.mixpanelLabel, MixpanelEvent.verbatimSearch);
    if (this.searchValue || this.searchValue === '') {
      const regex = new RegExp(this.searchValue, 'gi');
      const filteredArray = this.arrayOfStrings.filter((eachString: String) => {
        return eachString && eachString.match(regex);
      });
     this.searchedData.emit(filteredArray);
    }
  }

  onSearchTextChange(): void {
    if(this.searchValue || this.searchValue === '') {
      this.searchText.emit(this.searchValue);
    }
  }
}
