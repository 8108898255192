import { Concept } from '@platform/models/concept.model';
import { AttributesFilter, FilterItem } from '../models/filter.model';
import { AttributesService } from '../services/attributes.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { FilterService } from '@platform/services/filter.service';
import { ReportService } from '@platform/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import { Report } from '@platform/models/report.model';
import { DropdownData, DataType, DropdownItem } from '@products/shared/dropdown/dropdown.data.model';
import { take } from 'rxjs/operators';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent } from '@src/assets/utils/mixpanel-enum';

/**
 * `FilterComponent` creates the attributes filter options like compare,
 * concepts, country, subgroups, and show filter.
 *
 * @example
 *   <ns-attributes-filter></ns-attributes-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-attributes-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  /**
   * Set this concept if only one concept needs to be filtered.
   *
   * @type {Concept}
   * @memberof FilterComponent
   */
  @Input() concept: Concept;

  /**
   * Attributes filter object.
   *
   * @property
   * @type {AttributesFilter}
   * @memberof FilterComponent
   */
  public filter: AttributesFilter;
  public defaultFilter: AttributesFilter;

  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberof FilterComponent
   */
  public conceptsListData: DropdownData<string>;

  /**
   * List of subgroup dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public subgroupListData: DropdownData<string>;

  /**
   * List of deliverable view dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public compareListData: DropdownData<string>;

  /**
   * List of countries on the report.
   *
   * @property
   * @type {DropdownData}
   * @memberof FilterComponent
   */
  public countryListData: DropdownData<string>;

  /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberof FilterComponent
   */
  private subscriptions: Array<Subscription>;
  /**
   * Mixpanel label for the deliverable
   *
   * @property
   * @private
   * @type {string}
   * @memberof FilterComponent
   */
  private deliverableLabel: string;

  /**
   * Creates an instance of FilterComponent.
   *
   * @constructor
   * @param {AttributesService} attributesService
   * @param {FilterService} filterService
   * @param {ReportService} reportService
   * @memberof FilterComponent
   */
  constructor(
    private attributesService: AttributesService,
    private filterService: FilterService,
    private reportService: ReportService,
    private translate: TranslateService,
    private mixpanelService: MixpanelService
  ) {
    this.subscriptions = [];
  }

  /**
   * Event listener for concept selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} concepts
   * @memberof FilterComponent
   */
  selectConcept(allConcepts: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: allConcepts.find(selectedConcept => selectedConcept.value === concept.name).selected
        };
      })
    });
  }

  /**
   * Event listener for subgroup selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropdownItem>} subgroups
   * @memberof FilterComponent
   */
  selectSubgroup(subgroups: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
        };
      })
    });
  }

  /**
   * Initialize the filter component. Sets filter and report object
   * for the template.
   *
   * @memberof FilterComponent
   */
  ngOnInit(): void {
    this.deliverableLabel = this.concept ? MixpanelLabel.concepts : MixpanelLabel.attributes;
    const filter$ = this.attributesService.getAttributesFilter();
    const report$ = this.reportService.get();
    const subscription = combineLatest([filter$, report$]).subscribe(([filter, report]) => {
      this.filter = filter;
      this.setFilters(filter, report);
    });
    const filterSubscription = filter$.pipe(take(1))
      .subscribe((filter: AttributesFilter) => this.defaultFilter = filter);
    this.attributesService.loadDefaultFilter(this.concept);
    this.subscriptions.push(subscription, filterSubscription);
  }

  /**
   * Cleanup the component on removing from the UI.
   *
   * @memberof FilterComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Sets the filter options for concepts, subgroups, compareList, countries.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {Report} report
   * @memberof FilterComponent
   */
  private setFilters(filter: AttributesFilter, report: Report): void {
    this.conceptsListData = this.getConceptFilterOptions(filter);
    this.subgroupListData = this.getSubGroupFilterOptions(filter);
    this.compareListData = this.getCompareOptions(filter.compare);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
   * Returns the compare filter options.
   *
   * @private
   * @returns {DropdownData}
   * @memberof FilterComponent
   * @param items
   */
  private getCompareOptions(items: Array<FilterItem>): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
        dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.compare.label'),
        dataType: DataType.RADIO,
        contentTitle: this.translate.instant('shared.deliverables.attributes.filter.compare.itemsTitle'),
        data: dropdownData
      };
    return dropdownItems;
  }

  /**
   * Returns the country filter options.
     *
   * @private
   * @param {string} country
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }

  /**
   * Returns concepts filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getConceptFilterOptions(filter: AttributesFilter): DropdownData<string> {
    const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.CHECKBOX : DataType.RADIO;
    const dropdownData: DropdownItem<string>[] = filter.concepts.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.concepts.label'),
      dataType: dataType,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.concepts.itemsTitle'),
      groupSelect: this.translate.instant('shared.deliverables.attributes.filter.concepts.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns sub groups filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getSubGroupFilterOptions(filter: AttributesFilter): DropdownData<string> {
    const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.RADIO : DataType.CHECKBOX;
    const groupSelect = (dataType === DataType.RADIO ? '' : this.translate.instant('shared.deliverables.attributes.filter.subgroups.allSubgroups'));
    const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.subgroups.label'),
      dataType: dataType,
      groupSelect: groupSelect,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.subgroups.itemsTitle'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Emits event of the selected filter menu item.
   */
  selectedEvent(event): void {
    console.log(event);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

  /**
   * Sets the new filter entries when changes are made to compare drop down.
   *
   * @listens event:selectionChange
   * @param {Array<DropDownItem>}compare The list of compare options.
   */
  selectCompare(compare: any) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      compare: this.filter.compare.map((item: FilterItem) => {
        return {
          ...item,
          isSelected: compare.find(selected => selected.value === item.id).selected
        };
      }),
      concepts: this.filter.concepts.map((concept, index) => {
        return {
          ...concept,
          isSelected: compare.find(selected => selected.selected === true && selected.value === 'subgroup') ? (index === 0 ? true : false ) : true
        };
      }),
      subgroups: this.filter.subgroups.map((subgroup, index) => {
        return {
          ...subgroup,
          isSelected: compare.find(selected => selected.selected === true && selected.value === 'concept') ? (index === 0 ? true : false ) : true
        };
      }),
      show: {
        ...this.defaultFilter.show
      }
    });
  }

}
