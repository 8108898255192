import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '@platform/store/state/app.state';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ReportService} from '@platform/services/report.service';
import {AppConfigService} from '@app/core/config/app-config.service';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {SpinnerService} from "@platform/services/spinner.service";

@Injectable({
  providedIn: 'root'
})
export class StrengthWatchoutsFileService {

  private baseURI: string;

  constructor(private store: Store<State>,
              private httpClient: HttpClient,
              private reportService: ReportService,
              private spinnerService: SpinnerService,
              private cs: AppConfigService) {
    this.baseURI = `${this.cs.config.reporting.url}`;
  }

  /**
   * Downloads the file from api.
   *
   * @param {string} reportFileId the report file id.
   */
  public download(): void {
    this.spinnerService.setSpinnerObs();
    const report$ = this.reportService.get();
    report$.subscribe(report => {
      // use - instead of . for the type.  if . is used everything after . is removed.
        const uri = `${this.baseURI}/reports/${report.id}/deliverables/${DeliverableType.STRENGTH_WATCHOUTS.type.replace('.', '-')}`;
        const contentType = 'application/octet-stream';

      const headers = new HttpHeaders({
        Accept: contentType
      });
      const options = {
        responseType: 'blob' as 'blob',
        headers: headers
      };
      this.httpClient.get(uri, options).subscribe(data => {
        this.spinnerService.spinnerObs$.next(false);
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `Verbatims ${report.name}.csv`;
        link.click();
        link.remove();
      }, (err: HttpErrorResponse) => {
        if (err instanceof Error) {
          console.log(`An error occurred ${err.error.message}`);
        } else {
          console.log(`${err.status}, body was: ${err.message}`);
        }
        this.spinnerService.spinnerObs$.next(false);
      });
    });
  }

}
