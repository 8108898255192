import { createAction, props } from '@ngrx/store';
import { DeliverableView } from '@platform/models/deliverable-view.model';

/**
 * Action for adding a deliverableView.
 *
 * @export
 */
export const addDeliverableView = createAction(
  '[DeliverableView] Add Deliverable View',
  props<{ deliverableView: DeliverableView }>()
);

/**
 * Action for updating a deliverableView.
 *
 * @export
 */
export const updateDeliverableView = createAction(
  '[DeliverableView] Update Deliverable View',
  props<{ deliverableView: DeliverableView }>()
);
