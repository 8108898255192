import {Component, OnChanges, Input, ViewChild, ElementRef} from '@angular/core';
import {QuadMapPlotData, PlotDataPoint} from "@app/deliverables/correlations/models/quad-map-plot-data.model";
import {TranslateService} from '@ngx-translate/core';
import {StringHelper} from '@platform/services/string-helper.service';
import * as d3 from 'd3';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';

@Component({template: ''})
/**
 * BasePlotComponent
 */
export class BasePlotComponent {

    @Input() scatterPlotData: QuadMapPlotData;
    @Input() plotOptions: any;

    labelArray = [];
    anchorArray = [];

    @Input() selectedKeyMeasureName: string;
    @Input() selectedQuad: string;

    /**
     * `svgContainer` for adding the d3 chart.
     *
     * @type {ElementRef}
     * @memberof BasePlotComponent
     */
    @ViewChild('svgContainer', {static: true}) svgContainer: ElementRef;

    constructor(public translate: TranslateService,
                public stringHelper: StringHelper,
                public viewMetaInfoService: ViewMetaInfoService) {
    }

    public setLabelsAndAnchors(labels: any, links: any) {
        let self = this;
        let labelArray: any[] = this.labelArray;

        function ticked() {
            labels.attr("x", function (d) {
                return d.x;
            })
                .attr("y", function (d) {
                    return d.y;
                });
        }

        function simulationEnd() {
            links.attr("x1", function (d) {
                let bbox = (document.getElementById(d.textId) as any)?.getBBox();
                return bbox ? bbox.x + (bbox.width / 2) : null;
            })
                .attr("y1", function (d) {
                    let bbox = (document.getElementById(d.textId) as any)?.getBBox();
                    return bbox ? bbox.y + bbox.height : null;
                });
        }

        let repelForce = d3.forceManyBody().strength(-200).distanceMax(160).distanceMin(60);
        let attractForce = d3.forceManyBody().strength(200).distanceMax(200).distanceMin(200);
        let simulation = d3.forceSimulation(labelArray)
            .alphaDecay(0.3)
            .force("collide", d3.forceCollide().radius(50))
            .force('charge', d3.forceManyBody())
            .force("repelForce", repelForce)
            .on("tick", ticked)
            .on("end", simulationEnd);

    }

    public getScatterPlotDataSet(axisScale) {
        let labelArray = [],
            x = axisScale.x,
            y = axisScale.y,
            anchorArray = [];
        let dataPointsArray = this.scatterPlotData.dataPoints;
        dataPointsArray.forEach((dataPoint) => {
            dataPoint.consequence = +dataPoint.x;
            dataPoint.value = +dataPoint.y;
            this.pushToLabelArray(labelArray, x, y, dataPoint);
            this.pushToAnchorArray(anchorArray, x, y, dataPoint);
        });
        this.setLabelArray(labelArray);
        this.setAnchorArray(anchorArray);
        return dataPointsArray;
    }

    private pushToLabelArray(labelArray, x, y, dataPoint: PlotDataPoint) {
        labelArray.push({
            x: (x(dataPoint.consequence)) + 0,
            y: (y(dataPoint.value)) + 0,
            label: dataPoint.label,
            width: 0.0,
            height: 0.0,
            consequence: dataPoint.consequence,
            value: dataPoint.value,
            id: dataPoint.id
        });
    }

    private pushToAnchorArray(anchorArray, x, y, dataPoint: PlotDataPoint) {
        anchorArray.push({x: (x(dataPoint.consequence)) + 20, y: (y(dataPoint.value)) + 10, r: 7});
    }

    private setLabelArray(labelArray) {
        this.labelArray = labelArray;
    }

    private setAnchorArray(anchorArray) {
        this.anchorArray = anchorArray;
    }

    public defineScatterPlotQuadrants(g, width, height, scale) {
        let margin = this.plotOptions.margin;

        let domainWidth = width - margin.left - margin.right,
            domainHeight = height - margin.top - margin.bottom;

        let xValue = this.scatterPlotData.axisPlotData.xDividerAxis,
            yValue = this.scatterPlotData.axisPlotData.yDividerAxis,
            x = scale.x,
            y = scale.y;

        let quad1 = this.plotOptions.quad1,
            quad2 = this.plotOptions.quad2,
            quad3 = this.plotOptions.quad3,
            quad4 = this.plotOptions.quad4;

        g.append("rect")
            .attr("class", "quad-1 quad")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", x(xValue))
            .attr("height", y(yValue))
            .style("margin-left", quad1.margin.left)
            .attr("fill", quad1.fillColor);

        g.append("rect")
            .attr("class", "quad-2 quad")
            .attr("x", 0)
            .attr("y", y(yValue))
            .attr("width", x(xValue))
            .attr("height", domainHeight - y(yValue))
            .style("margin-left", quad2.margin.left)
            .attr("fill", quad2.fillColor);

        g.append("rect")
            .attr("class", "quad-3 quad")
            .attr("x", x(xValue))
            .attr("y", 0)
            .attr("width", domainWidth - (x(xValue)))
            .attr("height", y(yValue) - 1)
            .style("margin-left", quad3.margin.left)
            .attr("fill", quad3.fillColor);

        g.append("rect")
            .attr("class", "quad-4 quad")
            .attr("x", x(xValue))
            .attr("y", y(yValue))
            .attr("width", domainWidth - (x(xValue)))
            .attr("height", domainHeight - y(yValue))
            .style("margin-left", quad4.margin.left)
            .attr("fill", quad4.fillColor);

        g.append("rect")
            .attr("class", "click-4-text-rect text-rect")
            .attr("x", width - 255)
            .attr("y", height - 69)
            .attr("width", "186")
            .attr("height", "26")
            .attr("fill", quad4.clickColor);

        g.append("rect")
            .attr("class", "click-3-text-rect text-rect")
            .attr("x", width - 204)
            .attr("y", -25)
            .attr("width", "135")
            .attr("height", "26")
            .attr("fill", quad3.clickColor);

        g.append("rect")
            .attr("class", "click-2-text-rect text-rect")
            .attr("x", 0)
            .attr("y", height - 69)
            .attr("width", "125")
            .attr("height", "26")
            .attr("fill", quad2.clickColor);

        g.append("rect")
            .attr("class", "click-1-text-rect text-rect")
            .attr("x", -1)
            .attr("y", -25)
            .attr("width", "125")
            .attr("height", "26")
            .attr("fill", quad1.clickColor);
    }

    public createAndGetDataPointLabels(g, data, scale) {
        let x = scale.x,
            y = scale.y,
            xValue = this.scatterPlotData.axisPlotData.xDividerAxis,
            yValue = this.scatterPlotData.axisPlotData.yDividerAxis;
        let labelArray = this.labelArray;
        let labels = g.selectAll(".label")
            .data(labelArray)
            .enter().append("text")
            .attr("class", function (d) {
                let c = "";
                if (d.value >= yValue && d.consequence <= xValue) {
                    c = "label basic";
                } // Top Left
                else if (d.value >= yValue && d.consequence >= xValue) {
                    c = "label key";
                } // Top Right
                else if (d.value <= yValue && d.consequence >= xValue) {
                    c = "label aop";
                } // Bottom right
                else {
                    c = "label less";
                } //Bottom left
                return c;
            })
            .attr("x", function (d) {
                return d.x;
            })
            .attr("y", function (d) {
                return d.y;
            })
            .attr("id", function (d) {
                d.textId = "text" + d.id;
                return "text" + d.id;
            })
            .style("font-size", "11px")
            .style("font-weight", "normal")
            .attr("font-family", "sans-serif")
            .style("text-anchor", "middle")
            .attr("width", 100)
            .attr("cursor", "pointer")
            .style("display", "inline-block")
            .text(function (d) {
                return "\u00A0\u00A0" + d.label + "\u00A0\u00A0";
            })
            .attr("title", function (d) {
                return d.label;
            })
            .attr("fill", function (d) {
                if (d.value >= yValue && d.consequence <= xValue) {
                    return "#999999";
                } // Top Left
                else if (d.value >= yValue && d.consequence >= xValue) {
                    return "#85c63f";
                } // Top Right
                else if (d.value <= yValue && d.consequence >= xValue) {
                    return "#d60037";
                } // Bottom right
                else {
                    return "#b8b9bb";
                } //Bottom left
            })
            .attr("class", function (d) {
                if (d.value >= yValue && d.consequence <= xValue) {
                    return "label q1-outline";
                } // Top Left
                else if (d.value >= yValue && d.consequence >= xValue) {
                    return "label q2-outline";
                } // Top Right
                else if (d.value <= yValue && d.consequence >= xValue) {
                    return "label q3-outline";
                } // Bottom right
                else {
                    return "label q4-outline";
                } //Bottom left
            })
            .on("click", function () {
                let selectedText = d3.select(this),
                    textLength = selectedText.node().getComputedTextLength(),
                    textTitle = selectedText.attr("title");
                let text = selectedText.text();
                selectedText.text("\u00A0\u00A0" + textTitle + "\u00A0\u00A0");
                if (this.classList.contains("expand")) {
                    while (textLength > (250) && textTitle.length > 0) {
                        text = text.slice(0, -1);
                        selectedText.text(text + "..." + "\u00A0\u00A0");
                        textLength = selectedText.node().getComputedTextLength();
                    }
                }
            })
            .on("mouseover", function () {
                let selectedText = d3.select(this);
                selectedText.style("font-size", "13px");
            })
            .on("mouseout", function () {
                let selectedText = d3.select(this);
                selectedText.style("font-size", "11px");
            });

        return labels;
    }

    public createAndGetDataPointLinks(g, width, height, scale) {
        let xValue = this.scatterPlotData.axisPlotData.xDividerAxis,
            yValue = this.scatterPlotData.axisPlotData.yDividerAxis,
            x = scale.x,
            y = scale.y,
            margin = this.plotOptions.margin,
            domainWidth = width - margin.left - margin.right,
            domainHeight = height - margin.top - margin.bottom;
        let labelArray = this.labelArray;
        let anchorArray = this.anchorArray;
        let links = g.selectAll(".link")
            .data(labelArray)
            .enter()
            .append("line")
            .attr("class", "link")
            .attr("x1", function (d) {
                return (d.x);
            })
            .attr("y1", function (d) {
                return (d.y);
            })
            .attr("x2", function (d) {
                return (d.x);
            })
            .attr("y2", function (d) {
                return (d.y);
            })
            .attr("stroke-width", 1)
            .attr("stroke", "gray")
            .attr("stroke", function (d) {
                if (d.value >= yValue && d.consequence <= xValue) {
                    return "#999999";
                } // Top Left
                else if (d.value >= yValue && d.consequence >= xValue) {
                    return "#85c63f";
                } // Top Right
                else if (d.value <= yValue && d.consequence >= xValue) {
                    return "#d60037";
                } // Bottom right
                else {
                    return "#b8b9bb";
                } //Bottom left
            })
            .attr("id", function (d) {
                return d.id;
            });

        return links;
    }

    public scatterDataPointsOnChart(g, height, data, scale) {
        let xValue = this.scatterPlotData.axisPlotData.xDividerAxis,
            yValue = this.scatterPlotData.axisPlotData.yDividerAxis,
            x = scale.x,
            y = scale.y;

        let penaltyCircleGroup = g.selectAll("circle")
            .data(data);
        let penaltyCircleGroupEnter = penaltyCircleGroup
            .enter().append("circle")
            .attr("class", function (d) {
                let c = "";
                if (d.value >= yValue && d.consequence <= xValue) {
                    c = "dot basic";
                } // Top Left
                else if (d.value >= yValue && d.consequence >= xValue) {
                    c = "dot key";
                } // Top Right
                else if (d.value <= yValue && d.consequence >= xValue) {
                    c = "dot aop";
                } // Bottom right
                else {
                    c = "dot less";
                } //Bottom left
                return c;
            });
        penaltyCircleGroupEnter
            .attr("test-selector", function (d) {
                return `${d.questionName}-${d.label}`;
            })
            .attr("r", 6)
            .attr("opacity", 1)
            .attr("cx", function (d) {
                return x(d.consequence);
            })
            .attr("cy", function (d) {
                return y(d.value);
            })
            .style("fill", function (d) {
                if (d.value >= yValue && d.consequence <= xValue) {
                    return "#999999";
                } // Top Left
                else if (d.value >= yValue && d.consequence >= xValue) {
                    return "#85c63f";
                } // Top Right
                else if (d.value <= yValue && d.consequence >= xValue) {
                    return "#d60037";
                } // Bottom right
                else {
                    return "#b8b9bb";
                } //Bottom left
            });
        penaltyCircleGroup.exit().remove();
    }

    public addDataPointTooltip(g, data, width, height, scale) {
        let renderPlotComponent = this;
        let main_x = scale.x,
            main_y = scale.y;
        d3.select("#scatter-plot-chart .tooltip").remove();
        let tooltip = d3.select("#scatter-plot-chart").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        g.selectAll("circle").on("mouseover", function () {
            let currentCircle = this;
            let correlationText = renderPlotComponent.fetchCorrelationStatus(currentCircle.__data__.rank),
                y = currentCircle.__data__.y,
                x = currentCircle.__data__.x,
                type = currentCircle.__data__.type;
            //let yDecimalValue = quadMapPlotComponent.numeralFormat(y, '0.0', '0');
            let yDecimalValue = renderPlotComponent.stringHelper.formatNumber(y, '0.0', '0');
            let html = "<b>" + correlationText + " </b><br>" + "<span><i>" + "\"" + currentCircle.__data__.label + "\"" + "</i></span>" +
                "<p>" + type + " Mean Rating : " + "<b>" + yDecimalValue + "</b></p>";
            if (currentCircle.getAttribute("opacity") > 0.5) {
                currentCircle.setAttribute("r", 8);
                tooltip.html(html)
                    .style("left", (main_x(x) + 0) + "px")
                    .style("top", (main_y(y) - 60) + "px")
                    .transition()
                    .duration(200) // ms
                    .style("opacity", 0.9); // started as 0!
            }
        });

        g.selectAll("circle").on("mouseout", function () {
            this.setAttribute("r", 6);
            tooltip.transition()
                .duration(300) // ms
                .style("opacity", 0);
        });
    }


    public renderQuadrantLabels(g, width, height) {
        let self = this;
        d3.selectAll('#svg-container .label').call(this.wrap.bind(this));

        g.append("text")
            .style('fill', '#999999')
            .style('font-weight', 'bold')
            .style('font-size', '14px')
            .style('color', '#fff')
            .attr("text-anchor", "start")
            .attr("x", 6)
            .attr("y", -4)
            .attr("class", "legends basic-benefit click-1-text")
            .attr("cursor", "pointer")
            .text(this.translate.instant('shared.deliverables.correlations.quadMap.chart.quad1.label'))
            .on("click", function () {
                let f1 = d3.select(".quad-1").attr("fill");
                if (f1 === "#f7f7f7") {
                    self.updateQuadAndText(this, ".quad-1", ".click-1-text-rect", '#999999', '.basic');
                    self.setQuadMapMetaInfo(self.translate.instant('shared.deliverables.correlations.quadMap.chart.quad1.label'));
                } else {
                    self.revertQuadText(this, ".quad-1", ".click-1-text-rect", '#999999');
                }
            });

        g.append("text")
            .style('fill', '#b8b9bb')
            .style('font-weight', 'bold')
            .style('font-size', '14px')
            .style('color', '#fff')
            .attr("text-anchor", "start")
            .attr("x", 5)
            .attr("y", height - 50)
            .attr("cursor", "pointer")
            .attr("class", "legends click-2-text less-relevent")
            .text(this.translate.instant('shared.deliverables.correlations.quadMap.chart.quad2.label'))
            .on("click", function () {
                let f2 = d3.select(".quad-2").attr("fill");
                if (f2 === "#f7f7f7") {
                    self.updateQuadAndText(this, ".quad-2", ".click-2-text-rect", '#b8b9bb', '.less');
                    self.setQuadMapMetaInfo(self.translate.instant('shared.deliverables.correlations.quadMap.chart.quad2.label'));
                } else {
                    self.revertQuadText(this, ".quad-2", ".click-2-text-rect", '#b8b9bb');
                }
            });

        g.append("text")
            .style('fill', '#26bb10')
            .style('font-weight', 'bold')
            .style('font-size', '14px')
            .style('color', '#fff')
            .attr("text-anchor", "end")
            .attr("x", width - 78)
            .attr("y", -4)
            .attr("cursor", "pointer")
            .attr("class", "legends click-3-text key-strength")
            .text(this.translate.instant('shared.deliverables.correlations.quadMap.chart.quad3.label'))
            .on("click", function () {
                let f3 = d3.select(".quad-3").attr("fill");
                if (f3 === "#f7f7f7") {
                    self.updateQuadAndText(this, ".quad-3", ".click-3-text-rect", '#26bb10', ".key");
                    self.setQuadMapMetaInfo(self.translate.instant('shared.deliverables.correlations.quadMap.chart.quad3.label'));
                } else {
                    self.revertQuadText(this, ".quad-3", ".click-3-text-rect", '#26bb10');
                }
            });

        g.append("text")
            .style('fill', '#d60037')
            .style('font-weight', 'bold')
            .style('font-size', '14px')
            .style('color', '#fff')
            .attr("text-anchor", "start")
            .attr("x", width - 240)
            .attr("y", height - 50)
            .attr("cursor", "pointer")
            .attr("class", "legends areaOfOpp click-4-text")
            .text(this.translate.instant('shared.deliverables.correlations.quadMap.chart.quad4.label'))
            .on("click", function () {
                let f4 = d3.select(".quad-4").attr("fill");
                if (f4 === "#f7f7f7") {
                    self.updateQuadAndText(this, ".quad-4", ".click-4-text-rect", '#d60037', ".aop");
                    self.setQuadMapMetaInfo(self.translate.instant('shared.deliverables.correlations.quadMap.chart.quad4.label'));
                } else {
                    self.revertQuadText(this, ".quad-4", ".click-4-text-rect", '#d60037');
                }
            });

        this.renderHighLightQuadOnLoadAndClick();

    }

    renderHighLightQuadOnLoadAndClick() {
        if (this.selectedQuad) {
            const highLightQuad = this.getHighLightQuadrantPropertyMap();
            const highLightQuadClass = '.quad-' + highLightQuad.quadNumber;
            const clickTextClass = '.click-' + highLightQuad.quadNumber + '-text';
            const clickBackgroundClass = '.click-' + highLightQuad.quadNumber + '-text-rect';
            const circleClass = highLightQuad.circleClass;
            const highLightColorArray = ['#999999', '#b8b9bb', '#26bb10', '#d60037'];
            const highLightColor = highLightColorArray[highLightQuad.quadNumber - 1];
            this.updateQuadAndText(clickTextClass, highLightQuadClass, clickBackgroundClass, highLightColor, circleClass);
        }
    }

    getHighLightQuadrantPropertyMap() {
        const quadLabelText = this.selectedQuad;
        let quadMap = {quadNumber: 0, circleClass: ''};
        switch (quadLabelText) {
            case 'BASIC BENEFITS':
                quadMap.quadNumber = 1;
                quadMap.circleClass = '.basic';
                break;
            case 'LESS RELEVANT':
                quadMap.quadNumber = 2
                quadMap.circleClass = '.less';
                break;
            case 'KEY STRENGTHS':
                quadMap.quadNumber = 3
                quadMap.circleClass = '.key';
                break;
            case 'AREA OF OPPORTUNITY':
                quadMap.quadNumber = 4
                quadMap.circleClass = '.aop';
                break;
        }
        return quadMap;
    }

    private updateQuadAndText(selectedText, selectedQuad, selectedTextBackground, selectedColor, selectedCircles) {
        d3.selectAll(".key").attr("opacity", 0.5);
        d3.selectAll(".less").attr("opacity", 0.5);
        d3.selectAll(".aop").attr("opacity", 0.5);
        d3.selectAll(".basic").attr("opacity", 0.5);
        d3.selectAll(".quad").attr("fill", "#f7f7f7");
        d3.selectAll(".quad").attr("stroke-width", 0);
        d3.selectAll(".text-rect").attr("fill", "transparent");

        d3.select(".key-strength").style('fill', '#26bb10');
        d3.select(".less-relevent").style('fill', '#b8b9bb');
        d3.select(".areaOfOpp").style('fill', '#d60037');
        d3.select(".basic-benefit").style('fill', '#999999');

        d3.select(selectedQuad).attr("fill", "#ffffff");
        d3.selectAll(selectedCircles).attr("opacity", 1);
        d3.select(selectedQuad).attr("stroke", selectedColor);
        d3.select(selectedQuad).attr("stroke-width", 2);
        d3.select(selectedTextBackground).attr("fill", selectedColor);
        d3.select(selectedText).style("fill", '#ffffff');
    }

    private revertQuadText(ref, selectedQuad, selectedText, selectedColor) {
        d3.selectAll(".key").attr("opacity", 1);
        d3.selectAll(".less").attr("opacity", 1);
        d3.selectAll(".aop").attr("opacity", 1);
        d3.selectAll(".basic").attr("opacity", 1);
        d3.select(selectedQuad).attr("fill", "#f7f7f7");
        d3.select(selectedQuad).attr("stroke-width", 0);
        d3.select(selectedText).attr("fill", "transparent");
        d3.select(ref).style('fill', selectedColor);
    }

    wrap(text): void {
        text.each(function () {
            let self = d3.select(this);
            let node = self.node();
            let textLength = node.getComputedTextLength();
            let text = self.text();

            while (textLength > (200) && text.length > 0) {
                text = text.slice(0, -1);
                self.text(text + "..." + "\u00A0\u00A0");
                textLength = self.node().getComputedTextLength();
            }
        });
    }

    fetchCorrelationStatus(rank) {
        let result;
        switch (rank) {
            case 1: {
                result = "WEAK CORRELATION";
                break;
            }
            case 2: {
                result = "MODERATE CORRELATION";
                break;
            }
            case 3: {
                result = "STRONG CORRELATION";
                break;
            }
        }
        return result;
    }

    public setQuadMapMetaInfo(selectedQuad: string) {
        const deliverableType = DeliverableType.CORRELATIONS.type;
        const viewInfo = {deliverableType, selectedQuad: selectedQuad};
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

}
