import { Component, Input, OnInit } from '@angular/core';
import { TreeNodeData } from '../../models/tree-node-data.interface';

@Component({
  selector: 'ap-item-types',
  templateUrl: './item-types.component.html',
  styleUrls: ['./item-types.component.scss']
})
export class ItemTypesComponent{
  @Input() node: TreeNodeData;
}
