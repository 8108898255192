import {ProductServiceFactory} from '@platform/services/product-factory.service';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Feature} from '@platform/models/feature.model';
import {ReportService} from '@platform/services/report.service';
import {UserService} from '@platform/services/user.service';
import {PrivilegeService} from '@platform/services/privilege.service';
import {Report} from '@platform/models/report.model';
import {UserInfo} from '@platform/models/user-info.model';
import {Collaborator} from '@platform/models/collaborator.model';
import {IntegrationService} from '@platform/services/integration.service';
import {HeaderService} from '@platform/services/header.service';
import {PendoService} from '@platform/services/pendo.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { BenchmarkService } from '@platform/import-benchmark-results/services/benchmark.service';
import {ForecastingService} from "@platform/services/forecasting.service";

@Component({
    selector: 'ns-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

    /**
     * List of feature available for this product report.
     *
     * @type {Feature[]}
     * @memberof ReportComponent
     */
    public features: Feature[];

    /**
     * The report object for the component.
     *
     * @type {Report}
     * @memberof ReportComponent
     */
    public report: Report;

    /**
     * Currently logged in user.
     *
     * @type {UserInfo}
     * @memberof ReportComponent
     */
    public userInfo: UserInfo;

    /**
     * Comment service url.
     *
     * @type {string}
     * @memberof ReportComponent
     */
    public commentsServiceURL: string;
    /**
     * Collaborator service url.
     *
     * @type {string}
     * @memberof ReportComponent
     */
    public collaboratorsServiceURL: string;
    /**
     * userInfo studio service url.
     *
     * @type {string}
     * @memberof ReportComponent
     */
    public userInfoServiceURL: string;

    /**
     * Currently logged in user whose role is a collaborator.
     *
     * @type {Collaborator}
     * @memberof ReportComponent
     */
    public collaboratedUser: Collaborator;

    /**
     * Returns `true` is the loggedin user can view the report.
     *
     * @type {boolean}
     * @memberof ReportComponent
     */
    public canView: boolean;

    /**
     * Returns `true` is the loggedin user can share the report with
     * other collaborators.
     *
     * @type {boolean}
     * @memberof ReportComponent
     */
    public canShare: boolean;

    /**
     * Returns `true` if user can import benchmark
     *
     * @type {boolean}
     * @memberof ReportComponent
     */
    public canImportBenchmark: boolean;

    /**
     * Returns `true` if default cards view exists.
     * This property is set when visiting deliverables route via subscribe mechanism.
     *
     * @type {boolean}
     * @memberof ReportComponent
     */
    public defaultCardsViewExist: boolean = true;

    public defaultForecastingCardsViewExist: boolean = true;

    /**
     * Return `true` if results modal should be opened.
     *
     * @type {boolean}
     * @memberof ReportComponent
     */
    public showResultsModal: boolean;

    public forecastingExists: boolean;

    /**
     * RxJS subscriptions created by the component instance.
     *
     * @private
     * @type {Array<Subscription>}
     * @memberof ReportComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * internal user check.
     *
     * @type {boolean}
     * @memberOf DeliverablesComponent
     */
    public isInternalUser: boolean;

    /**
     * Property to keep track if the user is in deliverables route or not.
     *
     * @type {boolean}
     * @memberOf DeliverablesComponent
     */
    public isDeliverablesRoute: boolean = false;

    public isForecastingRoute: boolean = false;

    /**
     * Property to keep track if the user is in deliverables with sub route or not.
     *
     * @type {boolean}
     * @memberOf DeliverablesComponent
     */
    public isDeliverablesSubRoute: boolean = false;

    /**
     * Property to keep track if the user is in import benchmark page.
     *
     * @type {boolean}
     * @memberOf DeliverablesComponent
     */
    public isImportedBenchmarkRoute: boolean = false;

    /**
     * Handle to toast child component.
     *
     * @type {ElementRef}
     * @memberof ReportComponent
     */
    @ViewChild('successToast') successToast: ElementRef;

    loggedInCollaborator: Collaborator;

    showCloseButton = true;
    blockFlyoutclose = false;
    showMainMenu = true;
    reportingSettingsFlyout: any;
    flyoutEvents = new Subject<string>();

    /**
     * Feature Flag for Reporting Import Benchmark Results
     * flag: REPORTING_IMPORT_BENCHMARK_RESULTS
     * @type {boolean}
     * @memberOf ReportComponent
     */
    private canImportBenchmarkResultWithFlag: boolean = false;

    /**
     * Constructor.
     *
     * @param productServiceFactory
     * @param reportService
     * @param userService
     * @param privilegeService
     * @param integrationService
     * @param headerService
     * @param pendoService
     */
    constructor(
        private productServiceFactory: ProductServiceFactory,
        private reportService: ReportService,
        private userService: UserService,
        private privilegeService: PrivilegeService,
        private integrationService: IntegrationService,
        private headerService: HeaderService,
        private benchmarkService: BenchmarkService,
        private pendoService: PendoService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService,
        private router: Router,
        private route: ActivatedRoute,
        private forecastingService: ForecastingService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize the Report component view.
     * - Displays the Header Section
     *
     * @memberof ReportComponent
     */
    ngOnInit(): void {
        this.headerService.showApplicationHeaders();
        const report$ = this.reportService.get();
        const userInfo$ = this.userService.getUser();
        this.showResultsModal = false;
        this.isDeliverablesRoute = this.isInDeliverablesRoute(this.router.url);
        this.isForecastingRoute = this.isInForecastingRoute(this.router.url);
        this.isDeliverablesSubRoute = this.isInDeliverablesWithSubRoute(this.router.url);
        this.isImportedBenchmarkRoute = this.isImportedBenchmarkRouteCheck(this.router.url);
        const features$ = this.productServiceFactory.getService().getFeatures();
        this.commentsServiceURL = this.integrationService.getCommentsURL();
        this.collaboratorsServiceURL = this.integrationService.getCollaboratorsURL();
        this.userInfoServiceURL = this.integrationService.getUserInfoURL();

        this.subscriptions.push(combineLatest(
            [report$, userInfo$, features$])
            .pipe()
            .subscribe(result => {
                this.report = result[0];
                this.userInfo = result[1];
                this.forecastingExists = this.report.deliverables.filter(it => it.type.includes('forecast')).length > 0;

                if (this.userInfo) {
                    this.pendoService.initializePendo(this.userInfo);
                    this.mixpanelService.initialize(this.userInfo);
                    this.collaboratedUser = this.privilegeService.getUserRole(this.userInfo, this.report.collaborators);
                    this.canView = this.privilegeService.canViewReport(this.userInfo, this.collaboratedUser);
                    this.canShare = this.privilegeService.canShareReport(this.userInfo, this.collaboratedUser);
                    this.features = this.privilegeService.canViewFeaturesBasedOnPermissions(this.report, this.userInfo, result[2]);
                    this.canImportBenchmarkResultWithFlag = this.userInfo.featureFlags.includes('REPORTING_IMPORT_BENCHMARK_RESULTS');
                    this.canImportBenchmark = this.reportService.canImportBenchmark(this.report);
                    this.isInternalUser = this.userInfo.isInternalUser;
                }
            }));
        this.subscriptions.push(this.reportService.defaultCardsViewExist$.subscribe((it) => {
            this.defaultCardsViewExist = it;
        }));
        this.subscriptions.push(this.forecastingService.defaultCardsViewExist$.subscribe((it) => {
            this.defaultForecastingCardsViewExist = it;
        }));
        this.subscriptions.push(this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isDeliverablesRoute = this.isInDeliverablesRoute(event.url);
                this.isForecastingRoute = this.isInForecastingRoute(event.url);
                this.isDeliverablesSubRoute = this.isInDeliverablesWithSubRoute(event.url);
                this.isImportedBenchmarkRoute = this.isImportedBenchmarkRouteCheck(event.url);
            }
        }));
    }

    /**
     * Returns true if the user is within deliverables route.
     *
     * @param {string} url current route url
     * @memberof ReportComponent
     */
    isInDeliverablesRoute(url: string): boolean {
        const regex = /(deliverables)$/g;
        const found = url.match(regex);
        return !!found;
    }

    /**
     * Returns true if the user is within deliverables with sub route.
     *
     * @param {string} url current route url
     * @memberof ReportComponent
     */
    isInDeliverablesWithSubRoute(url: string): boolean {
        const regex = /(deliverables)/g;
        const found = url.match(regex);
        return !!found;
    }
    /**
     * Returns true if the user is within deliverables with sub route.
     *
     * @param {string} url current route url
     * @memberof ReportComponent
     */
    isImportedBenchmarkRouteCheck(url: string): boolean {
        const regex = /(import-benchmark-results)/g;
        const found = url.match(regex);
        return !!found;
    }

    /**
     * Returns true if the user is within deliverables route.
     *
     * @param {string} url current route url
     * @memberof ReportComponent
     */
    isInForecastingRoute(url: string): boolean {
        const regex = /(forecasting)$/g;
        const found = url.match(regex);
        return !!found;
    }

    /**
     * Open share-results component on click of 'share' button
     * @param {boolean} display display value of share-results modal
     *
     * @memberof ReportComponent
     */
    viewShareModal(display: boolean): void {
        this.showResultsModal = display;
    }

    /**
     * Open toast message upon sharing the results successfully
     *
     * @memberof ReportComponent
     */
    showShareSuccessToast(): void {
        this.successToast.nativeElement.open();
    }

    /**
     * Track when user navigates to feature tab
     * @param feature
     *
     * @memberof ReportComponent
     */
    trackNavigation(feature): void {
        const currentFeature = this.mixpanelService.getCurrentFeatureLabel();
        const newFeature = feature.charAt(0).toUpperCase() + feature.slice(1);
        this.mixpanelService.track(currentFeature, `Navigate to ${newFeature}`);
    }

    /**
     * Emits an event when reset card button is clicked.
     * This event is in turn subscribed in the deliverables route/component to take proper action.
     *
     * @memberof ReportComponent
     */
    resetCards(): void {
        this.reportService.resetDeliverableCards$.next();
    }

    /**
     * Emits an event when reset card button is clicked in forecasting page.
     * This event is in turn subscribed in the forecasting route/component to take proper action.
     *
     * @memberof ReportComponent
     */
    resetForecastingCards(): void {
        this.forecastingService.resetCards$.next();
    }

    navigateToImportBenchmark(): void {
        this.router.navigate(['import-benchmark-results'], {relativeTo: this.route});
    }

    isCloseBlocked() {

    }

    showFlyoutMenu() {
        this.flyoutEvents.next('open');
    }

    flyoutCloseBlocked() {
    }

    onFlyoutMenuClick(event) {
        this.showMainMenu = false;
    }

    onFlyoutMenuClose() {
        this.showMainMenu = true;
        this.flyoutEvents.next('close');
    }

    toggleChanged () {

    }
}
