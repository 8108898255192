export const SummaryColumns = ['Consumer preference', '% of Line Distribution', 'Unit Contribution', 'Revenue Contribution'];

export const CHARTCONFIG = {
    displayName: 'BAR CHART',
    isVisible: true,
    isExpanded: true,
    displayMessage: 'Select the chart metric.',
    unitContribution: {
        displayName: 'Units',
        isSelected: true,
        isEnabled: true
    },
    revenueContribution: {
        displayName: 'Revenue',
        isSelected: false,
        isEnabled: true
    }
};

export const SUMMARYCONFIG = {
    displayName: 'CONTRIBUTION BY VARIETY',
    isVisible: true,
    isExpanded: true,
    rowsConfig: [],
    columnsConfig: []
};
