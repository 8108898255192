import { Component, OnInit, OnDestroy } from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ViewMetaInfoService } from '@platform/services/view-meta-info.service';
import {CorrelationsFilter} from '@app/deliverables/correlations/models/filter.model';
import {CorrelationsDeliverableView} from '@app/deliverables/correlations/models/correlations.model';
import {CorrelationsService} from '@app/deliverables/correlations/services/correlation.service';
import {QuadMapPlotService} from '@app/deliverables/correlations/services/quad-map-plot.service';
import {QuadMapPlotData} from '@app/deliverables/correlations/models/quad-map-plot-data.model';
import {quadMapPlotOptions} from '@app/deliverables/correlations/models/quad-map-plot-options';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {CorrelationsMetaInfo} from '@app/deliverables/correlations/models/view-meta-info.model';

@Component({
  selector: 'ns-quad-map',
  templateUrl: './quad-map.component.html',
  styleUrls: ['./quad-map.component.scss']
})
export class QuadMapComponent implements OnInit, OnDestroy {
  /**
   * Correlations deliverable view filter object.
   *
   * @type {CorrelationsFilter}
   * @member QuadMapComponent
   */
  public filter: CorrelationsFilter;

  /**
   * The CorrelationsDeliverableView.
   * @type {CorrelationsDeliverableView}
   * @memberOf QuadMapComponent
   */
  public correlationsDeliverableView: CorrelationsDeliverableView;

  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member QuadMapComponent
   */
  public subscriptions: Array<Subscription>;

  public quadMapPlotData: QuadMapPlotData;

  public baseSize: number;

  public quadMapPlotOptions: any;

  public selectedKeyMeasureName: string

  /**
   * Meta info for Correlations
   *
   * @type {CorrelationsDeliverableView}
   * @memberOf QuadMapComponent
   */
  public viewCorrelationsMetaInfo: CorrelationsMetaInfo;

  public selectedQuad: string;

  constructor(private correlationsService: CorrelationsService,
              private quadMapPlotService: QuadMapPlotService,
              private viewMetaInfoService: ViewMetaInfoService) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    const filter$ = this.correlationsService.getCorrelationsFilter();
    const deliverableType = DeliverableType.CORRELATIONS.type;
    const viewMetaInfo$ = this.viewMetaInfoService.get<CorrelationsMetaInfo>(deliverableType);
    const correlations$ = this.correlationsService.getCorrelations();
    this.quadMapPlotOptions = Object.assign({}, quadMapPlotOptions);
    const subscription = combineLatest([correlations$, filter$, viewMetaInfo$])
      .pipe(debounceTime(1))
      .subscribe(([correlations, filters, viewMetaInfo]) => {
          this.correlationsDeliverableView = correlations;
          this.filter = filters;
          this.viewCorrelationsMetaInfo = viewMetaInfo;
          this.quadMapPlotData = this.quadMapPlotService.prepareQuadMapPlotData(correlations, filters);
          this.baseSize = this.quadMapPlotData.dataPoints[0].baseSize;
          this.selectedKeyMeasureName = this.filter.show.keyMeasures[this.correlationsService.viewName].find(keyMeasure => keyMeasure.isSelected).name
          this.selectedQuad = viewMetaInfo.selectedQuad;
        }
      );
    this.subscriptions.push(subscription);
  }

  /**
   * Cleanup hook.
   *
   * @memberOf QuadMapComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
