import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Concept } from '@platform/models/concept.model';
import { Subscription } from 'rxjs';
import { ActivationProfileMetaInfo } from '@app/deliverables/activation-profile/models/view-meta-info.model';
import { ViewMetaInfoService } from '@platform/services/view-meta-info.service';
import {MeasureDescription} from '@app/deliverables/activation-profile/models/activationProfile.model';

@Component({
  selector: 'activation-profile-item-name',
  templateUrl: './activation-profile-item-name.component.html',
  styleUrls: ['./activation-profile-item-name.component.scss']
})
export class ActivationProfileItemNameComponent implements OnInit, OnDestroy {
  @Input() concept: Concept;
  @Input() measureDescription: MeasureDescription;
  @Input() locale: Array<string>;
  datasetName: string;
  expansion: boolean;
  private subscriptions: Subscription[] = [];
  tooltipText: string
  constructor(private viewMetaInfoService: ViewMetaInfoService) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.tooltipText = this.measureDescription.Database
    this.datasetName = this.concept.name;
    const deliverableType = DeliverableType.ACTIVATION_PROFILE.type;
    const subscription = this.viewMetaInfoService.get<ActivationProfileMetaInfo>(deliverableType)
    .subscribe(viewMetaInfo => {
      this.expansion = viewMetaInfo['concept'];
    });
    this.subscriptions.push(subscription);
  }
}
