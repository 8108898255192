import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewType} from '@app/deliverables/gap-analysis/models/deliverable-view-type-enum';

/**
 * Default filter values for performance.
 *
 * @export
 * @property
*/
export const defaultGapAnalysisFilter = {
  deliverableType: DeliverableType.GAP_ANALYSIS.type,
  deliverableViewType: DeliverableViewType.CONCEPT,
  show: {
    meanIndex: true,
    topBoxIndex: false,
    topTwoBoxIndex: false,
  }
};
