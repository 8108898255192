<div class="data-table-view">
<!--  <div class="sticky-swipe-table-container">-->
<!--    &lt;!&ndash;Swipe &ndash;&gt;-->
<!--    <div class="swipe-container">-->
<!--      <ns-swipe-table class="pull-right" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"></ns-swipe-table>-->
<!--    </div>-->
<!--  </div>-->
  <!-- Table Content-->
  <div class="table-wrapper correlations-table-container carousel-container">
    <ns-carousel-table class="carousel-panel" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"
                       (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>
    <table id="correlations-table" (matSortChange)="sortData($event)" matSort mat-table matSortStart="asc" [dataSource]="dataSource" class="col-12 deliverable-insight-correlations mat-elevation-z8">
      <ng-container *ngFor="let colHeader of colHeaders; let i = index;" matColumnDef="{{colHeader.name}}">
        <!-- rendering statement column as colHeader doesn't have id. Statement colHeader won't have id-->
        <ng-container *ngIf="!colHeader.id">
          <th mat-sort-header class="statement-header" mat-header-cell *matHeaderCellDef disabled="true"></th>
          <td mat-cell *matCellDef="let element"class="statement value">{{element.statement}}</td>
        </ng-container>
        <!--rendering Attribute Rating column as attribute colHeader will have value-->
        <ng-container *ngIf="colHeader.value">
          <th rel="tooltip" matTooltipPosition="above" matTooltip = "{{this.getSortOrder(colHeader.name)}}" mat-header-cell *matHeaderCellDef mat-sort-header class="statement-header">{{'shared.deliverables.correlations.measure.attribute' | translate}} <span *ngIf="viewCorrelationsMetaInfo.sortInfo" class="title-sort-arrow"></span></th>
          <td mat-cell *matCellDef="let element" class="value attribute-column" [ngClass]="getAttributeRatingClassName(element[colHeader.name])">  {{ formatAttribute(element.attribute) }}</td>
        </ng-container>
        <ng-container *ngIf="colHeader.id">
          <th rel="tooltip" matTooltipPosition="above" matTooltip = "{{this.getSortOrder(colHeader.name)}}" mat-sort-header class="statement-header" mat-header-cell *matHeaderCellDef > {{colHeader.name}}<span *ngIf="viewCorrelationsMetaInfo.sortInfo" class="title-sort-arrow"></span></th>
          <td class="value" mat-cell *matCellDef="let element"><span [ngClass]="(element[colHeader.id] === 3) ? 'sif sif-chevron-n above' : (element[colHeader.id] === 1) ? 'sif sif-chevron-s below' : 'moderate'" class="icon"></span></td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!-- Footer -->
    <div class="footer-text">
      <div class="footer-top">
        <p>{{'shared.deliverables.correlations.footerText' | translate}}</p>
        <p *ngIf="baseSize; else elseBlock"> {{'shared.deliverables.correlations.footerText2' | translate : {baseSize:baseSize} }}</p>
        <ng-template #elseBlock>{{'shared.deliverables.correlations.footerText2' | translate : {baseSize:''} }}</ng-template>
      </div>
      <div class="footer-bottom">
        <p>{{'shared.deliverables.correlations.rating.text' | translate}}</p>
        <div class="legends">
          <span><i class="icon above sif sif-chevron-n"></i> {{'shared.deliverables.correlations.strong' | translate}}</span>
          <span><i class="icon below sif sif-chevron-s"></i> {{'shared.deliverables.correlations.weak' | translate}}</span>
          <span><i class="moderate"></i> {{'shared.deliverables.correlations.moderate' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
