<div class="deliverable-container attributes-content">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
      <div class="sticky-header-container">
        <!--Swipe -->
          <ns-deliverables-header (selectionChange)="setViews($event)">
            <ns-kebab-menu>
              <ul class="kebab-menu-list">
                <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
              </ul>
            </ns-kebab-menu>
            <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
          </ns-deliverables-header>
        </div>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData"  [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()"></ns-deliverable-insight>
  </ng-container>

  <div id="deliverable-container">
    <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
      <!--Attributes filter-->
      <ns-attributes-filter [concept]="scoreCardConcept"></ns-attributes-filter>
    </div>
<!--    <div class="sticky-swipe-table-container">-->
<!--      &lt;!&ndash;Swipe &ndash;&gt;-->
<!--      <div class="swipe-container">-->
<!--        <ns-swipe-table class="pull-right" [tooltipDescription]="selectedView === 'Concepts' ? 'Concept' : 'Subgroup' " [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"-->
<!--        (next)="nextPage()" (previous)="previousPage()"></ns-swipe-table>-->
<!--      </div>-->
<!--    </div>-->
    <div class="attributes-table-container deliverable-insight-attributes insight-html-root carousel-container">
      <ns-carousel-table class="carousel-panel" [tooltipDescription]="selectedView === 'Concepts' ? 'Concept' : 'Subgroup' " [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"
                         (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>
      <!--Attributes chart table-->
      <table id="attributes-table" class="col-12 highlighted-row-container deliverable-insight-attributes-table" [class.table-bar-chart]="filter && filter.show.barCharts" mat-table matSortStart="asc" matSort [dataSource]="dataSource">
        <!--Create dynamic columns from colHeaders-->
        <ng-container *ngFor="let colHeader of colHeaders; let i = index; trackBy: trackItem" matColumnDef="{{colHeader.name}}">
          <!--Show empty header and statement cell if statement object. Statement colHeader won't have id-->
          <ng-container *ngIf="!colHeader.id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disabled="true" class="statement-header deliverable-insight-attributes-table-head"></th>
            <td mat-cell *matCellDef="let element" class="statement deliverable-insight-attributes-table-data" [class.bar-chart]="filter.show.barCharts"><span class="border-highlight"></span><span class="answer-text">{{element.statement}}</span></td>
          </ng-container>
          <!--Show chart header and chart cell if not statement object-->
          <ng-container *ngIf="colHeader.id">
            <!--Chart header-->
            <th rel="tooltip" matTooltipPosition="above" matTooltip = "{{this.getSortOrder(colHeader.name)}}" mat-header-cell *matHeaderCellDef mat-sort-header class="statement-header deliverable-insight-attributes-table-head" (click)="onClickHeader($event,colHeader.id,colHeader.name)">
              <ns-attributes-chart-header [title]="colHeader.name" [index]="i-1" [size]="colHeader.size" ></ns-attributes-chart-header>
            </th>
            <td mat-cell *matCellDef="let element" class="statement-chart deliverable-insight-attributes-table-data" [class.bar-chart]="filter.show.barCharts">
              <!--If bar chart-->
              <div class="bar-chart" *ngIf="filter.show.barCharts">
                <div class="stat-testing" *ngIf="filter.show.statTesting">
                  <span class="small margin-top-0-point-5 margin-bottom-0-point-5">{{element[colHeader.name].statTesting}}</span>
                </div>
                <ns-chart-horizontal-bar [barChart]="element[colHeader.name].barChart"></ns-chart-horizontal-bar>
              </div>
              <!--If not bar chart-->
              <div *ngIf="!filter.show.barCharts" class="data-label">
                <span class="pr-2">{{element[colHeader.name].sum}}</span>
                <div class="label-sub-text text-muted small" *ngIf="filter.show.statTesting">{{element[colHeader.name].statTesting}}</div>
              </div>
            </td>
          </ng-container>
        </ng-container>
        <!--Row highlight-->
        <tr class="deliverable-insight-attributes-table-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="answer-row deliverable-insight-attributes-table-row" mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="row.color"
            (contextmenu)="onContextMenu($event, row)"
            (click)="rowHighlight($event, row)"></tr>
      </table>
    </div>
    <!--Footer legend-->
    <div class="row">
      <ns-attributes-legend class="col-12 legend-container"></ns-attributes-legend>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
