import { TagDeclarationList } from './tag-declaration-list';

export class CaptureOptions {

  /**
   * Configuration for processing nodes.
   *
   * @type {{
   *      remove: {
   *        classes: string[];
   *      }
   *   }}
   * @memberof CaptureOptions
   */
  public node: {
    /**
     * Remove node configuration.
     */
     remove: string[];
  };

  /**
   * Configuration for processing css classes.
   *
   * @type {{
   *     root: string[];
   *     ignore: string[];
   *   }}
   * @memberof CaptureOptions
   */
  public cssClass: {
    /**
     * Classes need to be added to the root.
     */
    root: string[];
    /**
     * Ignore classes from cleanup.
     */
    ignore: string[];
  };

  /**
   * Configuration for processing node attributes.
   *
   * @type {{
   *     remove: string[]
   *   }}
   * @memberof ProcessorOptions
   */
  public attribute: {
    remove: string[]
  };

  /**
   * Tag declaration list for the processor.
   *
   * @private
   * @type {TagDeclarationList}
   */
  private tagDeclarationList: TagDeclarationList;

  /**
   * constructor.
   */
  constructor() {
    this.node = { remove: [] };
    this.cssClass = {
      root: [],
      ignore: []
    };
    this.attribute = { remove: ['id'] };
    this.tagDeclarationList = new TagDeclarationList();
  }

  /**
   * Returns TagDeclarationList.
   *
   * @returns the TagDeclarationList.
   */
  public getTagDeclarationList(): TagDeclarationList {
    return this.tagDeclarationList;
  }
}
