import { Action, createReducer, on } from '@ngrx/store';
import { conceptAdapter, initialState } from '@platform/store/adapters/concept.adapter';
import { ConceptState } from '@platform/store/state/concept.state';
import { addConcept, addConcepts, updateConcept, deleteConcept } from '@platform/store/actions/concept.actions';

/**
 * Reducer for all concept actions.
 */
const conceptReducer = createReducer(
  initialState,
  on(addConcept, (state, { concept }) => {
    return conceptAdapter.addOne(concept, state);
  }),
  on(addConcepts, (state, { concepts }) => {
    return conceptAdapter.addMany(concepts, state);
  }),
  on(updateConcept, (state, { concept }) => {
    return conceptAdapter.upsertOne(concept, state);
  }),
  on(deleteConcept, (state, { id }) => {
    let removed = conceptAdapter.removeOne( id, state);
    return removed;
  })
);

/**
 * Concepts `NgRx` reducer.
 *
 * @export
 * @param {ConceptState} satte
 * @param {Action} action
 * @returns {ConceptState}
 */
export function reducer(state: ConceptState | undefined, action: Action): ConceptState {
  return conceptReducer(state, action);
}
