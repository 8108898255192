import { Component, Input, OnInit } from '@angular/core';

/**
 *Creates Dynamic kebab menu
 *
 * @example
 *   <ns-kebab-menu></ns-kebab-menu>
 *
 * @export
 * @class KebabMenuComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'ns-kebab-menu',
  templateUrl: './kebab-menu.component.html',
  styleUrls: ['./kebab-menu.component.scss']
})

export class KebabMenuComponent implements OnInit {
  @Input() isDisable = false;
  ngOnInit(): void {}
}
