import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
import { DeliverableViewType } from './deliverable-view-type.enum';
import { CompareView } from './compare-view-enum';
/**
 * Default filter values for financial potential.
 *
 * @export
 * @property
*/
export const defaultFinancialPotentialFilter = {
  deliverableType: DeliverableType.FINANCIAL_POTENTIAL.type,
  deliverableViewType: DeliverableViewType.CONCEPT,
  compare: [
    {
      name: 'Concepts',
      id: CompareView.CONCEPT,
      isSelected: true,
      position: 1
    },
    {
      name: 'Subgroups',
      id: CompareView.SUBGROUP,
      isSelected: false,
      position: 1
    }],

  show: {
    topOneBox: false,
    topTwoBox: false,
    allFiveBoxes: true,
    statTesting: true,
    dataLabels: true
  }
};
