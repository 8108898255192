import {Injectable} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Location} from "@angular/common";

/**
 * This service provides operations for navigation history
 *
 *
 * @export
 * @see https://nils-mehlhorn.de/posts/angular-navigate-back-previous-page
 * @class NavigationService
 */
@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private history: string[] = []
    private currentRouterUrl: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private location: Location) {
        this.initialize();
    }

    initialize(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
                this.currentRouterUrl = this.router.url;
            }
        });
    }

    get currentUrl(): string {
        return this.currentRouterUrl;
    }

    back(defaultUrl: string): void | Promise<boolean> {
        this.history.pop()
        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.router.navigate([defaultUrl]);
        }
    }

    /**
     * Navigates the user to the deliverables root route from a deliverable route
     * */
    goToDeliverablesRoute(): void | Promise<boolean> {
        let currentRoute = this.currentRouterUrl;
        let routeParts = currentRoute.split('/');
        routeParts.splice(routeParts.length - 1, 1);
        return this.router.navigate([routeParts.join('/')]);
    }
}
