import {combineLatest, Subscription, take} from 'rxjs';
import {ProductServiceFactory} from '@platform/services/product-factory.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {CdkDragDrop, CdkDragEnter, CdkDragStart, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {ReportService} from '@platform/services/report.service';
import {Report} from '@platform/models/report.model';
import {Deliverable} from '@platform/models/deliverable.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {UserService} from '@platform/services/user.service';

@Component({
    selector: 'ns-deliverables',
    templateUrl: './deliverables.component.html',
    styleUrls: ['./deliverables.component.scss']
})
export class DeliverablesComponent implements OnInit, OnDestroy {

    /**
     * List of deliverable card model.
     *
     * @type {DeliverableInfo[]}
     * @memberof DeliverablesComponent
     */
    currentDeliverableInfos: DeliverableInfo[];

    defaultDeliverableInfos: DeliverableInfo[];

    deliverablesInReport: Deliverable[];

    report: Report;
    /**
     * Subscription for deliverableInfo
     *
     * @type {Subscription}
     * @memberof DeliverablesComponent
     */
    subscriptions: Array<Subscription>;
    /**
     * internal user check.
     *
     * @type {boolean}
     * @memberOf DeliverablesComponent
     */
    public isInternalUser: boolean;

    /**
     * Capture if the cards view is default position based or not
     */
    defaultCardsViewExist: boolean;

    public dragContainer: CdkDropList;

    public dropContainer: CdkDropList;

    public index: number;

    /**
     * @param productServiceFactory
     * @param reportService
     * @param deliverableInfoService
     * @param userService
     */
    constructor(
        private productServiceFactory: ProductServiceFactory,
        private reportService: ReportService,
        private deliverableInfoService: DeliverableInfoService,
        private userService: UserService,
    ) {
        this.subscriptions = [];
    }

    /**
     * Sets the value of property "defaultCardsViewExist".
     * Emits event on defaultCardsViewExist$ subject so report route can be notified of this value change,
     * and it then can change the state of the "reset" button on the navigation menu item.
     *
     * @param exists
     * */
    public setDefaultCardsViewExist(exists: boolean): void {
        this.defaultCardsViewExist = exists;
        this.reportService.defaultCardsViewExist$.next(exists);
    }

    /**
     * Initialize
     */
    ngOnInit() {
        const deliverableInfos$ = this.deliverableInfoService.mapFromType(this.deliverableInfoService.getNonForecastDeliverableTypes()).pipe(take(1));
        const report$ = this.reportService.get();
        const userInfo$ = this.userService.getUser();
        this.currentDeliverableInfos = [];
        const subscription = combineLatest([report$, deliverableInfos$, userInfo$]).pipe().subscribe(([report, deliverableInfos, userInfo]) => {
            this.currentDeliverableInfos = this.deliverableInfoService.getNonForecastDeliverableInfos(deliverableInfos);
            this.deliverablesInReport = report.deliverables;
            this.report = report;
            this.isInternalUser = userInfo.isInternalUser;
            this.defaultCardsViewExist = this.deliverableInfoService.deliverablesAreInDefaultOrder(this.currentDeliverableInfos);
            this.reportService.defaultCardsViewExist$.next(this.defaultCardsViewExist);
        });
        const defaultDataSubscription = this.productServiceFactory.getService().getDeliverableInfos(true).subscribe(result => {
            this.defaultDeliverableInfos = result;

        });

        this.subscriptions.push(this.reportService.resetDeliverableCards$.subscribe(this.resetCards.bind(this)));
        this.subscriptions.push(subscription);
        this.subscriptions.push(defaultDataSubscription);
        const reportUpdateSubscription = this.reportService.fetchReportFromStore().subscribe(result => {
            this.report = result;
            this.defaultCardsViewExist = this.deliverableInfoService.deliverablesAreInDefaultOrder(this.currentDeliverableInfos);
            this.reportService.defaultCardsViewExist$.next(this.defaultCardsViewExist);
            this.productServiceFactory.getService().getDeliverableInfos(this.defaultCardsViewExist).subscribe(info => {
                this.defaultDeliverableInfos = info;
                this.currentDeliverableInfos = this.deliverableInfoService.getNonForecastDeliverableInfos(info);
            });
        });
        this.subscriptions.push(reportUpdateSubscription);
    }

    /**
     * clean up
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * Drag and drop the Deliverable cards to new positions
     * @param event
     */
    public dragDropCards(event: CdkDragDrop<any>) {
        let startPosition = 1;
        event.previousContainer.data.index > event.container.data.index ? this.dragCardFromRightoLeft(event) : this.dragCardFromLeftoRight(event);
        this.currentDeliverableInfos.forEach(it => it.position = startPosition++);
        this.reportService.updateReportDeliverablePosition(this.report.id, this.currentDeliverableInfos).subscribe();
        this.setDefaultCardsViewExist(this.deliverableInfoService.deliverablesAreInDefaultOrder(this.currentDeliverableInfos));
    }

    /**
     * Invoked when card is dragged from right to left direction
     * @param event
     */
    private dragCardFromRightoLeft(event: CdkDragDrop<any>) {
        if (this.index !== event.currentIndex) {
            event.currentIndex === 1 ? moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, this.dropContainer.data.index + 1) :
                moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, event.container.data.index);
        } else {
            event.currentIndex === event.previousIndex ? moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, event.container.data.index) :
                moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, this.dropContainer.data.index + 1);
        }
    }

    /**
     * Invoked when card is dragged from left to right direction
     * @param event
     */
    private dragCardFromLeftoRight(event: CdkDragDrop<any>) {
        if (this.index !== event.currentIndex) {
            event.currentIndex === event.previousIndex ? moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, this.dropContainer.data.index - 1) :
                moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, event.container.data.index);
        } else {
            this.index === 1 ? moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, event.container.data.index) :
                moveItemInArray(this.currentDeliverableInfos, event.previousContainer.data.index, this.dropContainer.data.index - 1);
        }
    }

    /**
     * Event Listener when drag started
     * @param event
     */
    public dragStarted(event: CdkDragStart) {
        this.dragContainer = event.source.dropContainer;
    }

    /**
     * Event Listener when drag entered new container
     * @param event
     */
    public dragEntered(event: CdkDragEnter) {
        this.index = event.currentIndex;
        this.dropContainer = event.container;
    }

    /**
     * Resets the deliverable cards and sends an update to store
     */
    public resetCards(): void {
        this.currentDeliverableInfos.forEach(it => it.position = this.deliverableInfoService.getDeliverableTypeByTypeName(it.type)?.position);
        this.reportService.updateReportDeliverablePosition(this.report.id, this.currentDeliverableInfos).subscribe(report => {
            this.productServiceFactory.getService().getDeliverableInfos(true).subscribe(deliverableInfos => {
                this.currentDeliverableInfos = this.deliverableInfoService.getNonForecastDeliverableInfos(deliverableInfos);
                this.setDefaultCardsViewExist(true);
            });
        });
    }

}
