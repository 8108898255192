import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ns-empty-page',
    templateUrl: './empty-page.component.html',
    styleUrls: ['./empty-page.component.scss'],
})
export class EmptyPageComponent implements OnInit {
  @Input() public icon: string = 'search';
  @Input() public leadText: string = 'there are no results';
  @Input() public supportText: string =
      'Additional Supporting text aiding the user in understanding the page';
  @Input() public editText: boolean = false;
  @Input() public pageSize: string = 'large';
  @Input() public type: string = 'default';

  constructor() {}

  public ngOnInit(): void {
      if (this.type === 'error') {
          this.icon = 'warning-2';
      }
  }
}
