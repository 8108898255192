<div class="insight-creation-form" [@slideInOut]="'slideDown'" *ngIf=enabled>
  <div class="insight-creation">
    <div class="save-modal-actions" align="end">
      <button class="cancel-button" mat-button (click)="disableForm()">Cancel</button>
        <ng-container *ngIf="!isAHFeatureflagActive">
            <button [disabled]="!insightHeader?.length || descriptionLength>215"
                    class="save-button" mat-button
                    (click)="saveView(insightHeader, insightDescription)">Save
            </button>
        </ng-container>
        <ng-container *ngIf="isAHFeatureflagActive">
            <button [disabled]="!insightHeader?.length || insightHeader?.length > 80 || descriptionLength>215"
                    class="save-button" mat-button
                    (click)="saveView(insightHeader, insightDescription)">Save
            </button>
        </ng-container>

    </div>
    <div class="insight-create-modal">
      <h2 mat-dialog-title>{{ 'platform.deliverable.insight.title' | translate }}</h2>
      <span *ngIf="displayMessage.length">
        <span class="sif sif-info-filled info-text"><p>{{ 'platform.deliverable.insight.info.text' | translate }}</p></span>
      </span>
      <div class="save-modal-content">
        <ng-container *ngIf="!isAHFeatureflagActive">
              <div class="header-text">
              <label for="save">{{ 'platform.deliverable.insight.headline' | translate }}</label>
              <input [(ngModel)]="insightHeader" (keyup)="validateTitle()" placeholder="{{ 'platform.insight.placeholder.headline' | translate }}" type="text"
                     maxlength="80" required [ngClass]="titleBorderClass"/>
              </div>
              <p [ngClass]="titleTextClass" class="text-count">{{insightHeader?.length ? insightHeader?.length : 0}} / 80</p>
        </ng-container>
        <ng-container *ngIf="isAHFeatureflagActive">
            <ns-input-headers-headlines
                [label]=  "'platform.insight.headline' | translate"
                [(value)]= "insightHeader"
                [placeholder]="'platform.insight.placeholder.headline' | translate"
                [maxLength]="80"
            ></ns-input-headers-headlines>
        </ng-container>
        <span class="additional-text-btn" *ngIf="!show"
              (click)="toggleInfo()">{{ 'platform.deliverable.insight.additional.text' | translate }}</span>
        <div *ngIf="show" class="description-content">
          <div class="additional-info">
            <label
              class="additional-text">{{ 'platform.deliverable.insight.additional.text.small' | translate }}</label>
            <quill-editor
              style="width:100%"
              [ngClass]="desBorderClass"
              [minLength]="0"
              [maxLength]="215"
              [(ngModel)]="insightDescription"
              [modules]="editorConfig"
              [format]="'html'"
              (keyup)="validateDescription()"
              #noteInput="ngModel"
              placeholder="Enter supporting details(optional)">
            </quill-editor>
          </div>
          <p [ngClass]="desTextClass"
             class="desc-text-count">{{descriptionLength ? descriptionLength : 0}} / 215</p>
          <!-- <span (click)="toggleInfo()" class="sif sif-delete"></span> -->
          <span class="error-msg" *ngIf="descriptionLength>215">
            {{'platform.insight.description.error.text' | translate : {maxLength:'215'} }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
