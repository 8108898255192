import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathService {
    // BUG FIX FOR S2-6.1.1-3924: creating function for rounding values properly in javascript
    // if num is 0.145 function will return 0.15 after rounding it
    // reference : https://stackoverflow.com/questions/15118629/math-round-rounding-error
    round(num: number, pattern: any): number {
        return (pattern === '0' || pattern === '0%') ?
            Math.round(num * 100 + 0.1 ** (17 - 2 - (Math.round(num * 100) / 100).toString().length)) / 100 :
            num;
    }
}
