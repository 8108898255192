import {environment} from '@src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from './app-config.model';
import {Injectable} from '@angular/core';

@Injectable()
export class AppConfigService {
  /**
   * Configuration object for the app.
   *
   * @static
   * @type {AppConfig}
   * @memberof AppConfigService
   */
  public config: AppConfig;

  /**
   * Constructor for initializing the service.
   *
   * @param http
   */
  constructor(private http: HttpClient) {
  }

  /**
   * Loads the config file and returns a void promise.
   *
   * @returns {Promise<void>}
   */
  public load(): Promise<void> {
    const path = `assets/config/config.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get<AppConfig>(path).toPromise().then((appConfig: AppConfig) => {
        this.config = {...environment, ...appConfig};
        resolve();
      }).catch(() => {
        console.log(`COULD NOT LOAD EXTERNAL CONFIG FILE: '${path}'. FALLING BACK TO USING JUST ENVIRONMENT FOR CONFIGURATION.`);
        this.config = {...environment};
        resolve();
      });
    });
  }
}
