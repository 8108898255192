<div class="element-engagement-zone">
  <table class="table table-total-clicks table-hover table-fixed element-engagement-zones">
    <thead>
    <tr>
      <th class="table-head tier-two table-head-zone table-col-clicks">Zone</th>
      <th class="table-head tier-two table-head-zone">
        <div class="text-center">Aggregate share of clicks (across all concepts)</div>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let zone of elementEngagement.zones">
      <tr class="" (mouseenter)="onMouseEnter(zone.label)" (mouseleave)="onMouseLeave()">
        <td class="table-col table-col-zone table-col-clicks">
          <div [class.not-visible]="zone.label==='Unassigned'" class="circle space-right">{{zone.label}}</div>
          <span class="zone-name">{{zone.name}}</span>
        </td>
        <td class="table-col">
            <sw-horizontal-bar [maxPercentage]="maxTotalClicks" [reverse]="false" [percentage]="zone.totalClicks"
                               [barText]="'elementEngagement'" class="element-zone-bar">
            </sw-horizontal-bar>
            <h3 class="pull-right counter-value">{{zone.percentageClicks | percent}}</h3>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
