<div *ngIf="showKeyMeasuresOnly && filter.show.keyMeasures[correlationsService.viewName].length">
  <ns-dropdown id="showKeyFilter" class="filter-item" [dropDownData]="keyMeasureListData" (selectionChange)="selectKeyMeasure($event.data)"></ns-dropdown>
</div>
<div *ngIf="!showKeyMeasuresOnly">
<ns-dropdown-menu [label]="filterLabel">
  <section *ngIf="filter.show.keyMeasures[correlationsService.viewName].length" class="dropdown-filter-menu">
    <h3 class="menu-title">{{ 'shared.deliverables.correlations.filter.label.keyMeasures' | translate }}</h3>
    <ul class="dropdown-menu-list">
      <li class="group-list-item">
        <mat-checkbox id="allMeasures" color="primary" rounded flat class="list-item" [value]="filterNames.keyMeasures" [checked]="filter.show.allKeyMeasures" (change)="toggleKeyMeasures(filter.show.allKeyMeasures)" noink>{{ 'shared.deliverables.correlations.filter.label.allMeasures' | translate }}</mat-checkbox>
      </li>
      <ul class="dropdown-menu-sublist">
        <li class="list-item" (click)="$event.stopPropagation()" *ngFor="let measure of filter.show.keyMeasures[correlationsService.viewName]"><mat-checkbox color="primary" rounded flat [value]="filterNames.keyMeasures" [checked]="measure.isSelected" (change)="toggleKeyMeasures(measure)" noink>{{ measure.name }}</mat-checkbox></li>
      </ul>
    </ul>
  </section>
  <section class="dropdown-filter-menu">
    <ul class="dropdown-menu-list">
      <h3 class="menu-title">{{'shared.deliverables.correlations.filter.label.attributeView' | translate}}</h3>
      <mat-radio-group [ngModel]="metric" (change)="changeMetricType($event)">
        <div class="menu-list-content">
          <mat-radio-button color="primary" class="list-item" [value]="filterNames.tertile"><li class="list-item">{{ 'shared.deliverables.correlations.filter.label.tertile' | translate }}</li>
          </mat-radio-button>
          <mat-radio-button color="primary" (click)="$event.stopPropagation()" class="group-list-item" [value]="filterNames.tertileMean"><li class="list-item">{{ 'shared.deliverables.correlations.filter.label.tertileMean' | translate }}</li>
          </mat-radio-button>
          <mat-radio-group [ngModel]="getSelectedTertileMeanType()" (change)="changeTertileMeanType($event)">
            <mat-radio-button color="primary" class="list-item padding-left-2" [value]="filterNames.noDecimals" [disabled]="!filter.show.tertileMean.isSelected"><li class="list-item">{{ 'shared.deliverables.correlations.filter.label.no.decimal' | translate }}</li></mat-radio-button>
            <mat-radio-button color="primary" class="list-item padding-left-2" [value]="filterNames.oneDecimalPlace" [disabled]="!filter.show.tertileMean.isSelected"><li class="list-item">{{ 'shared.deliverables.correlations.filter.label.one.decimal' | translate }}</li></mat-radio-button>
            <mat-radio-button color="primary" class="list-item padding-left-2" [value]="filterNames.twoDecimalPlace" [disabled]="!filter.show.tertileMean.isSelected"><li class="list-item">{{ 'shared.deliverables.correlations.filter.label.two.decimal' | translate }}</li></mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-radio-group>
    </ul>
  </section>
  <section class="dropdown-filter-menu">
      <h3 class="menu-title">{{ 'shared.deliverables.correlations.filter.label.attributeRankings' | translate }}</h3>
      <div (click)="$event.stopPropagation()">
        <mat-checkbox id="aboveAvg" color="primary" rounded flat class="list-item" [value]="filterNames.aboveAvg" [checked]="filter.show.aboveAverage" (change)="toggleSelection(filterNames.aboveAvg)" noink>{{ 'shared.deliverables.correlations.filter.label.above.avg' | translate }}</mat-checkbox>
        <mat-checkbox id="avg" color="primary"  rounded flat class="list-item"
                      [value]="filterNames.avg" [checked]="filter.show.average" (change)="toggleSelection(filterNames.avg)" noink>{{ 'shared.deliverables.correlations.filter.label.avg' | translate }}</mat-checkbox>
        <mat-checkbox id="belowAvg" color="primary" rounded flat class="list-item" [value]="filterNames.belowAvg" [checked]="filter.show.belowAverage" (change)="toggleSelection(filterNames.belowAvg)" noink>{{ 'shared.deliverables.correlations.filter.label.below.avg' | translate }}</mat-checkbox>
      </div>
  </section>
</ns-dropdown-menu>
</div>
