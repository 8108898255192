<ns-deliverable-configuration-header
        [headerText]="'platform.purchase.preference.share.label' | translate"
        [showBackButton]=true
        (backBtnClickEvent)="returnBack()"
        (closeBtnClickEvent)="onCloseButtonClick()"></ns-deliverable-configuration-header>
<div id="purchase-preference-config">
    <div>
        <div id="purchasePreferenceAnalyticsOptions" class="chart-section">
            <div class="summary-list-header">Analytics Options</div>

            <div *ngIf="purchasePreferenceShareConfiguration">
                <mat-radio-group [(ngModel)]="purchasePreferenceShareConfiguration.analyticsOptionSelected">
                    <mat-radio-button (click)="valueChanged('purchasePreference')" class="radio-item" value="purchasePreference" color="primary"
                    >Purchase Preference</mat-radio-button>
                    <mat-radio-button (click)="valueChanged('indexUtilities')" class="radio-item" value="indexUtilities" color="primary"
                    >Index Utilities</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</div>
