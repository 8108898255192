import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

import { AppConfigService } from '@app/core/config/app-config.service';
import { UserInfo } from '@platform/models/user-info.model';
import { mixpanelPathType} from '@src/assets/utils/mixpanel-enum';

/**
 * This service has operations for Mixpanel implementation
 *
 * @example
 * constructor(private mixpanelService: MixpanelService) { }
 *
 * @export
 * @class MixpanelService
 */

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  mixpanelKey: string;
  userInfo: UserInfo;

  constructor(private appConfigService: AppConfigService) {
    this.mixpanelKey =  this.appConfigService.config?.mixpanelKey;
  }

  /**
   * Initialize mixpanel.
   *
   * @param {UserInfo} user
   * @memberOf MixpanelService
   */
  initialize(user: UserInfo): void {
    mixpanel.init(this.mixpanelKey);
    mixpanel.identify(user.fullName);
    this.userInfo = user;
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} eventLabel Origin of the action to track.
   * @param {string} eventName Name of the action to track.
   * @param {*} [details={}] Action Details object with custom properties.
   * @memberOf MixpanelService
   */
  track(eventLabel: string, eventName: string, details?: {}): void {
    details = Object.assign(
      {
        distinct_id: this.userInfo.userId,
        userId: this.userInfo.userId,
        userName: this.userInfo.fullName,
        isInternalUser: this.userInfo.isInternalUser,
        organizationId: this.userInfo.organizationId
      },
      details
    );
    mixpanel.track(`${eventLabel} - ${eventName}`, details || {});
  }

  /**
   * Get mixpanel label based on feature.
   *
   * @param {string} feature
   * @memberOf MixpanelService
   */
  getMixpanelLabel(feature: string): string {
    return mixpanelPathType[feature];
  }

  /**
   * Get current feature/tab label
   *
   * @memberof MixpanelService
   */
  getCurrentFeatureLabel(): string {
    let featureLabel: string;
    const urlArray = window.location.href.split("/");
    const feature = urlArray.pop();
    const featureType = urlArray[urlArray.length-1];
    featureLabel = featureType.toLowerCase() === 'deliverables' ?
      this.getMixpanelLabel(feature) || 'Deliverable': this.getMixpanelLabel(featureType) || this.getMixpanelLabel(feature);
    return featureLabel;
  }
}
