import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Concept} from "@platform/models/concept.model";
import {Report} from "../../../../models/report.model";
import {ConceptService} from "../../../../services/concept.service";
import {ToasterService} from '@platform/services/toaster.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig} from "@angular/material/legacy-dialog";
import {Benchmark} from '@platform/models/benchmark.model';
import {BenchmarkService} from '@platform/import-benchmark-results/services/benchmark.service';
import {ConceptDataItem, FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {
    FinancialPotentialDeliverableView,
    Measures as FpMeasures
} from '@app/deliverables/financial-potential/models/financial-potential.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {Deliverable} from '@platform/models/deliverable.model';
import {ConfirmationDialogComponent} from '@app/common/confirmation-dialog/confirmation-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationDialogService} from '@app/common/confirmation-dialog/confirmation-dialog.service';
import {ActivationProfileDeliverableView, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';

@Component({
    selector: 'ns-benchmark-concept-card',
    templateUrl: './benchmark-concept-card.component.html',
    styleUrls: ['./benchmark-concept-card.component.scss']
})
export class BenchmarkConceptCardComponent implements OnInit, OnChanges {

    @Input() report: Report;
    @Input() concept: Concept;
    @Input() benchmark: Benchmark;
    @Input() activationProfileDeliverableView: ActivationProfileDeliverableView;
    @Input() factorsDeliverableView: FactorsDeliverableView;
    @Input() financialPotentialDeliverableView: FinancialPotentialDeliverableView;
    @Input() subgroup: Subgroup;
    @Input() editMode: boolean = false;
    @Input() isHovered: boolean;
    /**
     * Maximum length displayName can be
     *
     * @type {number}
     */
    maxTitleLength: number = 50;
    @ViewChild('fullSizeConceptImageDialog') fullSizeConceptImageDialog;
    fullSizeConceptImageDialogRef: MatDialogRef<BenchmarkConceptCardComponent>;

    conceptImageUrl: string;

    activationProfileDataForConcept: Measures ;
    factorsDataForConcept: ConceptDataItem;
    fpMeasureDataForConcept: FpMeasures;
    fpShowPurchaseIntent = false;
    fpShowUnits = false;
    fpShowFrequency = false;
    showFinancialPotential = false;
    /**
     * Activation profile deliverable associated with the report if it exists.
     * */
    activationProfileDeliverable: Deliverable;

    constructor(private conceptService: ConceptService,
                private dialog: MatDialog,
                private benchmarkService: BenchmarkService,
                private toasterService: ToasterService,
                private translateService: TranslateService,
                private confirmationDialogService: ConfirmationDialogService) {
    }

    ngOnInit(): void {
        this.initialize();
        this.isHovered = false;
        this.editMode = false;
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize() {
        const primaryLocale = this.report.locales[0];
        this.conceptImageUrl = this.conceptService.getConceptImageURI(this.concept, primaryLocale);
        // this.activationProfileDeliverable = this.report.deliverables.find(it => it.type === 'activation.profiles');
        const activationProfileShowDeliverable = this.report.deliverables.find(it => it.type === 'activation.profiles')?.showDeliverable ?? true;
        const factorsShowDeliverable = this.report.deliverables.find(it => it.type === 'factors')?.showDeliverable ?? true;
        const fpShowDeliverable = this.report.deliverables.find(it => it.type === 'financial.potential')?.showDeliverable ?? true;
        this.activationProfileDataForConcept = activationProfileShowDeliverable ? this.activationProfileDeliverableView?.measures.find(it => it.conceptId === this.concept.exerciseConceptId) ?? null : null;
        this.factorsDataForConcept = factorsShowDeliverable ? this.factorsDeliverableView?.concepts.find(it => it.conceptId === this.concept.exerciseConceptId && it.segmentId === this.subgroup.segmentId) ?? null : null;
        this.fpMeasureDataForConcept = fpShowDeliverable ? this.financialPotentialDeliverableView?.measures.find(it => it.conceptId === this.concept.exerciseConceptId && it.segmentId === this.subgroup.segmentId) ?? null : null;
        const meta = this.financialPotentialDeliverableView?.metaInfo?.columnHeaders;
        if (this.fpMeasureDataForConcept) {
            this.fpShowPurchaseIntent = meta?.purchaseIntent;
            this.fpShowUnits = meta?.trialUnits;
            this.fpShowFrequency = meta?.frequency;
        }
        this.showFinancialPotential = this.fpMeasureDataForConcept ? this.fpShowPurchaseIntent || this.fpShowUnits || this.fpShowFrequency : false;
    }

    showFullSizeConcept(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.hasBackdrop = false;
        this.fullSizeConceptImageDialogRef = this.dialog.open(this.fullSizeConceptImageDialog, dialogConfig);
    }

    closeFullSizeConcept(): void {
        this.fullSizeConceptImageDialogRef.close();
    }

    onBlurConceptName() {
        if (this.concept.name.length > 0) {
            this.benchmark = {
                id: this.benchmark.id,
                reportId: this.benchmark.reportId,
                benchmarkReportId: this.benchmark.benchmarkReportId,
                benchmarkReportName: this.benchmark.benchmarkReportName,
                benchmarkConceptId: this.benchmark.benchmarkConceptId,
                displayName: this.concept.name,
                benchmarkExerciseConceptId: this.benchmark.benchmarkExerciseConceptId,
                benchmarkDbSubgroupId: this.benchmark.benchmarkDbSubgroupId
            };

            this.benchmarkService.update(this.benchmark.benchmarkReportId, this.benchmark.id, this.benchmark).subscribe(() => {
                this.toasterService.openSnackBar(this.translateService.instant('platform.benchmark.concept.name.updated.success'), 'success');
                this.benchmarkService.benchmarkEvents.emit('UPDATED');
            });
            this.editMode = false;
            this.isHovered = false;
        }
    }

    /**
     * Deletes the benchmark(Imported Concept).
     *
     * @param event
     */
    delete(benchmarkId: string, conceptId: string): void {
        this.dialog.closeAll();
        this.benchmarkService.delete(this.benchmark.reportId, benchmarkId, conceptId).subscribe(() => {
            this.toasterService.openSnackBar(this.translateService.instant('platform.benchmark.remove.success'), 'success');
            this.benchmarkService.benchmarkEvents.emit('DELETED');
        });
    }

    /**
     * ConfirmationDialog to Delete Benchmark(Imported Concept).
     *
     * @param event
     */
    deleteConfirmationDialog(concept: Concept, benchmark: Benchmark) {
        const dialogConfig = this.confirmationDialogService.getDeleteConfirmationConfig(
                                this.translateService.instant('platform.benchmark.concept.delete.title'),
                                this.translateService.instant('platform.benchmark.concept.delete.description'));
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(value => {
            if (value == 'DELETE') {
                this.delete(benchmark.id, concept.id);
            }
        });
    }
}
