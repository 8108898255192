import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';
import {StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {
    StrengthWatchoutsConcept,
    StrengthWatchoutsDeliverableView
} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {ElementEngagementService} from '@app/deliverables/strengths-watchouts/element-engagement/element-engagement.service';
import {ConceptService} from '@platform/services/concept.service';

@Component({
    selector: 'sw-element-engagement',
    templateUrl: './element-engagement.component.html',
    styleUrls: ['./element-engagement.component.scss']
})
export class ElementEngagementComponent implements OnInit, OnDestroy {

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberof ElementEngagementComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * Strength and Watch outs filter object.
     *
     * @type {StrengthWatchoutsFilter}
     * @memberof ElementEngagementComponent
     */
    public filter: StrengthWatchoutsFilter;

  public hideThisView: Boolean;

  /**
   * The deliverable view object.
   *
   * @type {StrengthWatchoutsDeliverableView}
   * @memberOf ElementEngagementComponent
   */
  public strenghtWatchouts: StrengthWatchoutsDeliverableView;

    /**
     * Currrent zone that the user is hovering on.
     * @type {string} currentZone
     */
    currentZone: string;

    currentConceptName: string;

    /**
     * Creates an instance of AttributesComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {StrengthWatchoutsService} strengthWatchoutsService
     * @param {ElementEngagementService} elementEngagementService
     * @param {ConceptService} conceptService
     * @memberof ElementEngagementComponent
     */
    constructor(
        private strengthWatchoutsService: StrengthWatchoutsService,
        private elementEngagementService: ElementEngagementService,
        private conceptService: ConceptService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize component and load data
     *
     * @memberof ElementEngagementComponent
     */
    ngOnInit(): void {
        const strengthWatchouts$ = this.strengthWatchoutsService.getStrengthWatchouts();
        const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
        const concepts$ = this.conceptService.getReportConcepts();
        const subscription = combineLatest([strengthWatchouts$, filter$, concepts$]).subscribe(([strengthWatchouts, filter, concepts]) => {
            // update the strengthWatchoutsDeliverableView to ensure only concepts with the selected locale are displayed.
            const filteredStrengthWatchouts = this.strengthWatchoutsService.updateConceptsByLocale(filter, strengthWatchouts);
            this.strenghtWatchouts = this.elementEngagementService.getElementEngagementData(filteredStrengthWatchouts, concepts);
            this.hideThisView = this.strenghtWatchouts.metaInfo ? this.strenghtWatchouts.metaInfo['hideElementEngagement'] : false;
            this.filter = filter;
        });

        this.subscriptions.push(subscription);
    }

    /**
     * Notification that a zone is hovered on.  Will be zone letter or blank.
     * @param zone The zone that was hovered on.
     */
    receiveZoneHover(zone: string) {
        this.currentZone = zone;
    }

    /**
     * Notification that a zone is hovered on.  Will be zone letter or blank.
     * @param concept
     */
    receiveConcept(concept: StrengthWatchoutsConcept) {
        this.currentConceptName = concept.label;
    }

    /**
     * Cleanup hook.
     *
     * @memberof ElementEngagementComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
