<div class="row margin-0 title-container" >
  <span id="{{'badge-' + index}}" class="badge black mini margin-right-0-point-5">{{getColumnLabel()}}</span>
  <span class="title"><strong class="bold" id="{{'title-' + index}}">{{title}}</strong></span>
  <span class="title-sort-arrow"></span>
</div>
<div id="{{'size-' + index}}" class="row margin-0 n-size" [class.table-head-bar-chart]="filter.show.barCharts">
  <span class="text-muted">n={{size}}</span>
</div>
<div class="row margin-0 table-head-range-indicator" *ngIf="filter.show.barCharts">
  <svg class="chart" width="270" height="20">
    <g class="chart-main" transform="translate(0 0)">
      <line class="dashed-border-right" stroke-dasharray="2, 2" x1="202" y1="0" x2="202" y2="20" stroke-width="0.2"
        stroke="black"></line>
      <line class="dashed-border-left" stroke-dasharray="2, 2" x1="1" y1="0" x2="1" y2="20" stroke-width="0.2"
        stroke="black"></line>
      <text id="{{'lower-bound-' + index}}" class="start-range-text" x="4" y="16" text-anchor="start" font-size="10" font-weight="700"
        fill="#B1B2B4">{{bound.lower}}</text>
      <text id="{{'higher-bound-' + index}}" class="end-range-text" x="196" y="16" text-anchor="end" font-size="10" font-weight="700"
        fill="#B1B2B4">{{bound.upper}}</text>
    </g>
  </svg>
</div>
