<ns-deliverable-configuration-header
        [headerText]="'Survey Questions'"
        (backBtnClickEvent)="returnBack()"
        (closeBtnClickEvent)="onCloseButtonClick()">
</ns-deliverable-configuration-header>
<ns-deliverable-configuration-body>
    <div cdkDropList (cdkDropListDropped)="dropQuestion($event)">
        <div class="item-row-dragndrop" *ngFor="let surveyQuestionConfiguration of surveyQuestionConfigurations" cdkDrag>
            <div class="cdk-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="item-row">
                <button mat-icon-button cdkDragHandle class="drag-n-drop-button">
                    <span class="sif sif-drag-handler sif-12 sif-gray"></span>
                </button>
                <button mat-icon-button (click)="showAnswers(surveyQuestionConfiguration)" class="toggle-button">
                    <span class="sif sif-12 expand-collapse sif-gray cursor-pointer" [ngClass]="expandedSurveyQuestionConfiguration.has(surveyQuestionConfiguration) ? 'sif-chevron-s':'sif-chevron-e'"></span>
                </button>
                <input matInput class="name-input" (change)="displayNameChange($event,surveyQuestionConfiguration)" [value]="surveyQuestionConfiguration.questionName" matTooltipPosition="above" [matTooltip]="surveyQuestionConfiguration.questionName" placeholder="Enter question name"/>
                <button mat-icon-button class="visibility-button" (click)="toggleVisibility(surveyQuestionConfiguration)">
                    <span class="sif sif-gray cursor-pointer" [ngClass]="surveyQuestionConfiguration.selected? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
                </button>
            </div>
            <ns-survey-question-answers-configuration
                    *ngIf="expandedSurveyQuestionConfiguration.has(surveyQuestionConfiguration)"
                    [surveyQuestionConfiguration]="surveyQuestionConfiguration"
                    (configurationChangedEvent)="questionAnswerConfigurationChanged($event)">
            </ns-survey-question-answers-configuration>
        </div>
    </div>
</ns-deliverable-configuration-body>
<ns-deliverable-configuration-footer
        [enableReset]="enableReset"
        [enableSave]="enableSave"
        (resetBtnClickEvent)="resetToDefault()"
        (saveBtnClickEvent)="onSave()"
        (saveAndCloseBtnClickEvent)="onSaveAndClose()"></ns-deliverable-configuration-footer>