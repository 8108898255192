import {Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { TreeNode, currency, distributionValue, forecastMarketingPlanSummaryHeaderMapData, forecastMarketingPlanSummaryMapData, tradePromotion } from '@app/deliverables/forecast-marketing-plan-summary/forecast-marketing-plan-summary-map-data';
import { DeliverableConfiguration } from '@platform/models/deliverable-configuration.model';
import { DeliverableConfigurationService } from '@platform/services/deliverable-configuration.service';
import { DeliverableViewMatTreeService } from '@platform/services/deliverable-view-mat-tree.service';
import { DeliverablesListItem } from '@products/shared/deliverables-list/deliverables-list-item.data.model';

@Component({
  selector: 'ns-marketing-plan-configuration',
  templateUrl: './marketing-plan-configuration.component.html',
  styleUrls: ['./marketing-plan-configuration.component.scss']
})
export class MarketingPlanConfigurationComponent implements OnInit {

  @Input() deliverablesConfig: DeliverableConfiguration[];
  @Input() reportId: string;
  @Input() dataList: DeliverablesListItem[];
  @Input() headerTableData: DeliverablesListItem[];
  @Input() tableData: DeliverablesListItem[];
  @Input() marketingPlanConfig: any;
  @Output() closeFlyoutMenuEvent = new EventEmitter();
  @Output() moveToPreviousMenuEvent = new EventEmitter();

  expandedRowsStoreCollection: any;
  estimateExpandedRowsStoreCollection: any;
  currencyValue: string;

  originalAssumptionOptions = ['Top', 'Above Average', 'Average', 'Below Average', 'Bottom'];
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  formGroups: any[] = [];

  assumptionOptions = [...this.originalAssumptionOptions]
  assumptionMapData = [
    {
      name: 'consumerAssumption',
      label: 'report.settings.deliverables.marketing.plan.config.estimate.consumer.assumption',
    },
    {
      name: 'tradeAssumption',
      label: 'report.settings.deliverables.marketing.plan.config.estimate.trade.assumption',
    },
  ];
    varietySplitDeliverableConfig: any;

  constructor(private deliverableConfigurationService: DeliverableConfigurationService,
    private deliverableViewMatTreeService: DeliverableViewMatTreeService) { }

  ngOnInit(): void {
    this.expandedRowsStoreCollection = this.deliverableViewMatTreeService.forecastMarketingPlanConfigurationExpandedTableRows;
    this.estimateExpandedRowsStoreCollection = this.deliverableViewMatTreeService.forecastMarketingPlanConfigurationExpandedEstimateRows;
    this.currencyValue = `${this.deliverableConfigurationService.getSharedCurency()}`;
    if (!this.tableData) {
      this.tableData = this.initializeTableData(forecastMarketingPlanSummaryMapData);
    }
    this.appendMPConfigValue();
    if (!this.headerTableData) {
      this.headerTableData = this.initializeTableData(forecastMarketingPlanSummaryHeaderMapData);
    }
    let deliverableConfigurationsInReport: any[];
    if (!(this.deliverablesConfig && this.deliverablesConfig.length > 0)) {
      this.deliverablesConfig = [];
    }
    deliverableConfigurationsInReport = JSON.parse(JSON.stringify(this.deliverablesConfig));
    if (!deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY)) {
      deliverableConfigurationsInReport.push({
        reportId: this.reportId,
        name: this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY,
        config: { 'headerTableData': this.headerTableData, 'tableData': this.tableData, 'estimates': [], 'decimals': '1' }
      });
      this.deliverableConfigurationService.isDeliverablesConfigReGenerated = false;
    }
    this.deliverableConfigurationService.checkForDecimalsInConfig(this.marketingPlanConfig, deliverableConfigurationsInReport, this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY, '1');
    this.deliverableConfigurationService.updateConfigForForecastEstimate(deliverableConfigurationsInReport, this.reportId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataList && this.dataList.length > 0) {
      this.formGroups = [];
      const allConsumerAssumption = this.dataList.map(e => {
        return e.data.consumerAssumption;
      });
      const allTradeAssumption = this.dataList.map(e => {
        return e.data.tradeAssumption;
      });
      const customAssumptions = [...new Set([...allConsumerAssumption, ...allTradeAssumption])]
        .filter(d => d && d.trim() != "")
        .sort((a, b) => { return a > b ? 1 : (b > a ? -1 : 0) });
      this.assumptionOptions = [...new Set([...this.originalAssumptionOptions, ...customAssumptions])];
      this.dataList.forEach(estimate => {
        const formGroup = new FormGroup({
          consumerAssumption: new FormControl(this.getValue(estimate?.data?.consumerAssumption), [Validators.required]),
          tradeAssumption: new FormControl(this.getValue(estimate?.data?.tradeAssumption), [Validators.required]),
        });

        this.formGroups.push(
          {
            id: estimate.id,
            formGroup: formGroup,
            filteredAssumptionOptions: {
              consumerAssumption: [...this.assumptionOptions],
              tradeAssumption: [...this.assumptionOptions]
            }
          }
        );

        this.assumptionMapData.forEach(assumption => {
          formGroup.controls[assumption.name].valueChanges.subscribe(value => {
            const filteredAssumptionOptions = this.filterListForAutoComplete(
              value || '',
              this.assumptionOptions,
            );
            this.setfilteredAssumptionOptions(assumption.name, estimate, filteredAssumptionOptions)
          });
        });

      });
    }
  }

  getValue(value: string) {
    return value ? value : '--';
  }

  appendMPConfigValue() {
    this.tableData.forEach((tableElement) => {
      switch (tableElement.id) {
        case 'totalYearOneSpend.value':
          if (tableElement.children.length > 0 && tableElement.children[0].id !== currency.id)
            tableElement.children.unshift(currency);
          break;
        case 'distributionEndOfYearValue':
          if (tableElement.children.length > 0 && tableElement.children[0].id !== distributionValue.id) {
            tableElement.children.unshift(distributionValue);
          }else if (tableElement.children.length > 0 && tableElement.children[0].data.length != distributionValue.data.length){
            let radioGroup = JSON.parse(JSON.stringify(distributionValue.data));
            let selectedElement = tableElement.children[0].data.find(e=>e.checked);
            let newSelectedElement = radioGroup.find(e=>e.id== selectedElement.id)
            if(newSelectedElement){
              radioGroup[0].checked = false;
              newSelectedElement.checked = true;
            }
            tableElement.children[0].data = radioGroup           }
          break;
        case 'tradePromotionDistribution':
          if (tableElement.children.length > 0 && tableElement.children[0].id !== tradePromotion.id)
            tableElement.children.unshift(tradePromotion);
          break;
      }
    });
  }
  filterListForAutoComplete(value: string, filterFromData: string[]): string[] {
    const filterValue = value.toLowerCase();
    return filterFromData.filter(option => option.toLowerCase().includes(filterValue));
  }

  setfilteredAssumptionOptions(formControlName: string, item: DeliverablesListItem, filteredAssumptionOptions) {
    this.formGroups.find(fg => fg.id == item.id).filteredAssumptionOptions[formControlName] = filteredAssumptionOptions;
  }

  initializeTableData(mapData: TreeNode[]): DeliverablesListItem[] {
    const tableData: DeliverablesListItem[] = []
    mapData.forEach((element, index) => {
      const tableElement: DeliverablesListItem = {
        id: element.key,
        displayName: element.name,
        originalName: element.name,
        isVisible: true,
        position: index,
        originalPosition: index,
        children: [],
      };
      if (element.children && element.children.length > 0) {
        tableElement.children = this.initializeTableData(element.children);
      }
      tableData.push(tableElement);
    });
    return tableData;
  }

  isDeliverableFromForecasting() {
    return this.deliverableConfigurationService.isMarketingPlanDeliverableFromForecasting?.toString();
  }

  getFormGroup(item: DeliverablesListItem): FormGroup {
    return this.formGroups.find(fg => fg.id == item.id)?.formGroup;
  }

  clearData(formControlName: string, item: DeliverablesListItem) {
    const formGroup = this.getFormGroup(item);
    formGroup.controls[formControlName].setValue(null);
  }

  assumptionSelected(event: any, formControlName: string, item: DeliverablesListItem) {
    let assumption;
    const formGroup = this.getFormGroup(item);
    if (event) {
      if (event.option && event.option.value) {
        assumption = event.option.value.trim();
      } else if (event.target && event.target.value) {
        assumption = event.target.value.trim();
      }
    }
    if (formGroup) {
      formGroup.controls[formControlName].setValue(assumption);
      if (!item.data) {
        item.data = {};
      }
      if (assumption)
        item.data[formControlName] = assumption;
    }
  }

  getfilteredAssumptionOptions(formControlName: string, item: DeliverablesListItem) {
    if (this.formGroups?.find(fg => fg.id == item.id)?.filteredAssumptionOptions && this.formGroups.find(fg => fg.id == item.id)?.filteredAssumptionOptions[formControlName])
      return this.formGroups.find(fg => fg.id == item.id).filteredAssumptionOptions[formControlName];
    return [...this.assumptionOptions];
  }

  assumptionAutocompleteOpened(assumption, item, trigger: any) {
    const filteredAssumptionOptions = [...this.assumptionOptions];
    this.setfilteredAssumptionOptions(assumption, item, filteredAssumptionOptions)
  }

  getFormControl(formControlName: string, item: DeliverablesListItem) {
    const formGroupControls = this.getFormGroup(item)?.controls;
    return formGroupControls ? formGroupControls[formControlName] : null;
  }

  onCloseButtonClick(): void {
    this.closeFlyoutMenuEvent.emit();
  }

  returnBack(): void {
    this.moveToPreviousMenuEvent.emit();
  }

  @HostListener('document:click', ['$event']) clickOut(event) {
    if (event.target.className.includes('mat-drawer-backdrop')) {
      event.stopPropagation();
      event.preventDefault();
      this.closeFlyoutMenuEvent.emit();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
    if (event.keyCode === 27) {
      this.closeFlyoutMenuEvent.emit();
    }
  }
}
