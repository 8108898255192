import { createAction, props } from '@ngrx/store';
import { ReportFile } from '@platform/report-files/report-file.model';

/**
 * Action for adding a reportFile.
 * 
 * @export
 */
export const addReportFile = createAction(
  '[ReportFile] Add ReportFile',
  props<{ reportFile: ReportFile }>()
);

/**
 * Action for an array of reportFiles.
 * 
 * @export
 */
export const addReportFiles = createAction(
  '[ReportFile] Add ReportFiles',
  props<{ reportFiles: ReportFile[] }>()
);

/**
 * Action for deleting a reportFile.
 * 
 * @export
 */
export const deleteReportFile = createAction(
  '[ReportFile] Delete ReportFile',
  props<{ id: string }>()
);
