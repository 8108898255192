import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {Concept} from '@platform/models/concept.model';
import {DeliverableType} from '@products/quick-predict/deliverable-type.enum';
import {Component, OnInit} from '@angular/core';
import {FilterService} from "@platform/services/filter.service";
import {ActivationProfileLegacyFilter} from "@products/quick-predict/activation-profile-legacy/models/filter.model";
import {defaultActivationProfileLegacyFilter} from "@products/quick-predict/activation-profile-legacy/models/default-ap-legacy-filter";

@Component({
  selector: 'ns-activation-profile-legacy',
  templateUrl: './activation-profile-legacy.component.html',
  styleUrls: ['./activation-profile-legacy.component.scss']
})
export class ActivationProfileLegacyComponent implements OnInit, ScoreCardView {

  /**
   * Deliverable type.
   *
   * @type {string}
   */
  deliverableType: string;

  /**
   * ScoreCard Concept object for image view.
   *
   * @type {Concept}
   */
   public scoreCardConcept: Concept;

  constructor(private filterService: FilterService) { }

  ngOnInit(): void {
    this.deliverableType = DeliverableType.ACTIVATION_PROFILE_LEGACY.type;
    this.loadDefaultFilter()
  }

  /**
   * Loads default ActivationProfileLegacy filter data.
   *
   * @memberof ActivationProfileLegacyComponent
   */
  public loadDefaultFilter(): void {
    const defaultFilter: ActivationProfileLegacyFilter = Object.assign({}, defaultActivationProfileLegacyFilter);
    this.filterService.update(defaultFilter);
  }
}
