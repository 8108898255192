<div class="concept-data-container clearfix">
    <div class="verbatims-concept-container concept-imageheight-{{imageHeight}} dropdown-facet"
         *ngIf="summaryText; else noData"
         style="background: #F9F9F9 url(assets/images/deliverables/ai-summaries-enabled-pattern.svg) no-repeat right;background-size: 40%;background-position-y: 99%;background-position-x: 99%;">
        <div
            class="summary-title-text">{{ "shared.deliverables.strengthsWatchouts.verbatims.concept.summary" | translate}}</div>
        <div class="summary-data">
            <div [innerHTML]="summaryText"></div>
        </div>
        <div class="footer-text" *ngIf="summaryText">
            {{"shared.deliverables.strengthsWatchouts.summaries.powered.text" | translate}}
        </div>

    </div>
    <ng-template #noData>
        <div class="noData concept-imageheight-{{imageHeight}}">
            <ns-empty-page type="empty" pageSize="medium" [leadText]="mainEmptyStateMessage"
                           [supportText]="secondaryEmptyStateMessage"></ns-empty-page>
        </div>
    </ng-template>

</div>



