import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '@platform/services/filter.service';
import { ReportService } from '@platform/services/report.service';
import { Subscription } from 'rxjs';
import { GapAnalysisFilter, ShowFilter } from '../../models/filter.model';
import { GapAnalysisService } from '../../services/gap-analysis.service';
import { filterNames as filterNameSource } from './filter-names';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';

/**
 * `<ns-gap-analysis-filter-show>` component builds show filter for the
 * `ShowComponent`.
 *
 * @example
 * <ns-gap-analysis-filter-show>
 * </ns-gap-analysis-filter-show>
 *
 * @export
 * @class ShowComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */


@Component({
  selector: 'ns-gap-analysis-filter-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {

  @Input() deliverableLabel: string;

  /**
    * Gap Analysis filter object.
    *
    * @property
    * @type {GapAnalysisFilter}
    * @memberOf ShowComponent
    */
  public filter: GapAnalysisFilter;

  /**
   * Filter label.
   *
   * @property
   * @type {string}
   * @memberOf ShowComponent
   */
  public filterLabel: string;

  /**
    * Filter name object.
    *
    * @property
    * @type {*}
    * @memberOf ShowComponent
    */
  public filterNames: any;

  /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberOf ShowComponent
   */
  private subscriptions: Array<Subscription>;

  constructor(
    private filterService: FilterService,
    private translate: TranslateService,
    private reportService: ReportService,
    private gapAnalysisService: GapAnalysisService,
    private mixpanelService: MixpanelService
  ) {
    this.filterNames = filterNameSource;
    this.subscriptions = [];
  }

  ngOnInit(): void {
    const filter$ = this.gapAnalysisService.getGapAnalysisFilter();
    const filterSubscription = filter$.subscribe(filter => {
      this.filter = filter;
      this.setFilterLabel();
    });
    this.subscriptions.push(filterSubscription);
  }
  /**
    * Sets filter label for show filter.
    *
    * @private
    * @memberOf ShowComponent
    */
  private setFilterLabel(): void {
    const showFilter: ShowFilter = this.filter.show;
    this.filterLabel = showFilter.meanIndex ? this.translate.instant('restage.deliverable.gap.analysis.filter.show.mean.label') : showFilter.topBoxIndex ?
      this.translate.instant('restage.deliverable.gap.analysis.filter.show.top.box.label') : this.translate.instant('restage.deliverable.gap.analysis.filter.show.top.two.box.label');
  }

  /**
   * Returns selected Index.
   *
   * @returns {string}
   * @memberOf ShowComponent
   */
  public getSelectedIndex(): string {
    const filterNames = this.filterNames;
    const showFilter: ShowFilter = this.filter.show;
    let index = '';
    index = showFilter.meanIndex ? filterNames.meanIndex : index;
    index = showFilter.topBoxIndex ? filterNames.topBoxIndex : index;
    index = showFilter.topTwoBoxIndex ? filterNames.topTwoBoxIndex : index;
    return index;
  }

  /**
* Event listener for index filter change.
*
* @listens event: change
* @param {*} event
* @memberOf ShowComponent
*/
  public changeIndex(event): void {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    const filterNames = this.filterNames;
    const index = event.value;
    const filter: GapAnalysisFilter = JSON.parse(JSON.stringify(this.filter));
    filter.show.meanIndex = index === filterNames.meanIndex;
    filter.show.topBoxIndex = index === filterNames.topBoxIndex;
    filter.show.topTwoBoxIndex = index === filterNames.topTwoBoxIndex;
    this.filterService.update(filter);
  }

}
