<div *ngIf="filter && !displaySpinner; else progressSpinner">
  <div class="sticky-dropdown deliverable-content hide">
    <div class="set-number-dropdown-field">
      <mat-paginator (page)="pageChanged($event)" #pagination class="pagination" [hidePageSize]="true" [pageSize]="20"></mat-paginator>
    </div>
  </div>
  <div class="table-responsive">
    <div class="table" *ngIf="filteredVarieties.length > 0 && this.combinations.length > 0; else noData">
      <div class="table-container container-varieties row" *ngIf="!setNumberIsSame; else progressSpinner">
        <div class="col-varieties col-set col-md-5">
          <div></div>
          <div></div>
            <div class="variety-name" *ngFor="let variety of trimmedNames">
                <div matTooltipPosition="above" [matTooltip]="variety.fullName" class="cropped-name" [attr.full-name]="variety.fullName">
                    <p>{{variety.name}}</p>
                </div>
            </div>
        </div>
        <div class="col-md-5 combination-sets">
          <div class="top-legend-section" [ngStyle]="{'width.px': topLegendWidth}">
            <div class="seperators">
              <span class="line"></span>
              <div class="separator" >{{ percentageText | translate }}</div>
            </div>
          </div>
          <div class="col-set" *ngFor="let combination of combinations$ |async">
            <div></div>
            <div class="weights">{{combination.weight}}</div>
            <div #box *ngFor="let variety of filteredVarieties; let i = index">
              <ns-highlight-cell [combination] = "combination" [variety]=variety [index]="i" [numbers]="true" [filter]="filter"></ns-highlight-cell>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noData>
    <div class="no-data">
      <span class="data sif sif-no-results"></span>
      <h3>There are no Results</h3>
      <p>Try refining the filters to populate data.</p>
    </div>
  </ng-template>
</div>

<ng-template #progressSpinner>
  <mat-progress-spinner style="margin:0 auto;" color="secondary" diameter="100" mode="indeterminate" value="50"></mat-progress-spinner>
</ng-template>
