import {filterNames as filterNameSource} from './filter-names';
import {FilterService} from '@platform/services/filter.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {FinancialPotentialFilter} from "@app/deliverables/financial-potential/models/filter.model";
import {FinancialPotentialService} from "@app/deliverables/financial-potential/services/financial-potential.service";

/**
 * `<ns-fp-filter-show>` component builds show filter for the
 * `FilterComponent`.
 *
 * @example
 * <ns-fp-filter-show>
 * </ns-fp-filter-show>
 *
 * @export
 * @class ShowComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-fp-filter-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

    @Input() deliverableLabel: string;
    /**
     * Financial Potential filter object.
     *
     * @property
     * @type {FinancialPotentialFilter}
     * @memberof ShowComponent
     */
    public filter: FinancialPotentialFilter;

    public disableDataLabel: boolean;
    /**
     * Filter name object.
     *
     * @property
     * @type {*}
     * @memberof ShowComponent
     */
    public filterNames: any;

    /**
     * Filter label.
     *
     * @property
     * @type {string}
     * @memberof ShowComponent
     */
    public filterLabel: string;

    /**
     * Filter subscription for cleanup.
     *
     * @private
     * @property
     * @type {Subscription}
     * @memberof ShowComponent
     */
    private filterSubscription: Subscription;

    /**
     * metric object
     *
     * @property
     * @type {string}
     * @memberof ShowComponent
     */
    public metric: string;

    tooltipLabel: string;

    /**
     * Creates an instance of ShowComponent.
     *
     * @constructor
     * @param {FinancialPotentialService} financialPotentialService
     * @param {FilterService} filterService
     * @param translate
     * @param {MixpanelService} mixpanelService
     * @memberof ShowComponent
     */
    constructor(
        private financialPotentialService: FinancialPotentialService,
        private filterService: FilterService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService
    ) {
        this.filterNames = filterNameSource;
    }

    /**
     * Initializes the show filter component.
     *
     * @memberof ShowComponent
     */
    ngOnInit() {
        const filter$ = this.financialPotentialService.getFinancialPotentialFilter();
        this.filterSubscription = filter$.subscribe(filter => {
            this.filter = filter;
            this.setFilterLabel();
        });

    }

    /**
     * Cleans up the show filter component on removing from UI.
     *
     * @memberof ShowComponent
     */
    ngOnDestroy(): void {
        this.filterSubscription.unsubscribe();
    }

    /**
     * Event listener to change the Show Measure type
     * @param event
     */
    public changeAndUpdateType(event): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const filterNames = this.filterNames;
        const selectionType = event.value;
        const filter = this.cloneFilter();
        filter.show.topOneBox = selectionType === filterNames.topBox;
        filter.show.topTwoBox = selectionType === filterNames.topTwoBox;
        filter.show.allFiveBoxes = selectionType === filterNames.allFiveBoxes;
        this.filterService.update(filter);
    }

    /**
     * Returns the selected percents type value.
     *
     * @returns {string}
     * @memberof ShowComponent
     */
    public getSelectedMeasureTypeName(): string {
        const filterNames = this.filterNames;
        const filter = this.filter;
        let selectionType = '';
        selectionType = filter.show.topOneBox ? filterNames.topBox : selectionType;
        selectionType = filter.show.topTwoBox ? filterNames.topTwoBox : selectionType;
        selectionType = filter.show.allFiveBoxes ? filterNames.allFiveBoxes : selectionType;
        return selectionType;
    }

    /**
     * Toggles the selection for checkboxes.
     *
     * @param {string} filterName
     * @memberof ShowComponent
     */
    public toggleSelection(filterName: string): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const filterNames = this.filterNames;
        const filter = this.cloneFilter();
        const show = filter.show;
        switch (filterName) {
            case filterNames.statTesting:
                show.statTesting = !show.statTesting;
                break;
            case filterNames.dataLabels:
                show.dataLabels = !show.dataLabels;
                break;
        }
        this.filterService.update(filter);
    }

    /**
     * Sets filter value property.
     *
     * @private
     * @memberof ShowComponent
     */
    private setFilterLabel(): void {
        const showFilter = this.filter.show;
        let label = '';
        const options: string[] = [];
        if (showFilter.topOneBox) {
            label = this.translate.instant('quick.predict.deliverables.financial-potential.filter.label.top.box');
        }
        if (showFilter.topTwoBox) {
            label = this.translate.instant('quick.predict.deliverables.financial-potential.filter.label.top.two.box');
        }
        if (showFilter.allFiveBoxes) {
            label = this.translate.instant('quick.predict.deliverables.financial-potential.filter.label.all.five.boxes');
        }
        if (showFilter.statTesting) {
            options.push(this.translate.instant('quick.predict.deliverables.financial-potential.filter.label.stat.testing'));
        }
        if (showFilter.dataLabels && !this.disableDataLabel) {
            options.push(this.translate.instant('quick.predict.deliverables.financial-potential.filter.label.data.labels'));
        }
        if (options.length) {
            label = `${label}; ${options.join(', ')}`;
        }
        this.filterLabel = label;
        this.tooltipLabel = this.translate.instant('tooltip.filter', {
            viewComparison: 'Options selected',
            viewComparisonName: this.filterLabel
        });

    }

    /**
     * Clones filter component.
     */
    private cloneFilter(): FinancialPotentialFilter {
        const filter = this.filter;
        const newShowFilter = {...filter.show};
        const newFilter = {...filter, show: newShowFilter};
        return newFilter;
    }

}
