import { Injectable } from '@angular/core';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {Observable} from 'rxjs';
import {
  ImageView,
  ImageFile
} from '@products/shared/deliverables/image-view/image-view.model';
import {AppConfigService} from '@app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ImageViewService {

  constructor(
    private appConfigService: AppConfigService,
    private deliverableViewService: DeliverableViewService,
  ) { }

  /**
   * Returns an observable of `ImageView` data.
   *
   * @returns {Observable<ImageView>}
   */
  public getImageView(deliverableType: string): Observable<ImageView> {
    return this.deliverableViewService.get<ImageView>(
      'image',
      deliverableType
    );
  }

  /**
   * Returns array of image file path.
   *
   * @returns {Array<ImageFile>}
   */
  public getImagePath(imageView: ImageView): Array<ImageFile> {
    const imagesPath = [];
    for (let imagePath of imageView.imageFile as Array<ImageFile>) {
      const updatePath = {
        fileName: imagePath.name,
        name: `${this.appConfigService.config.reporting.url}/reports/${imageView.reportId}/inputs/${imagePath.name}?imageFiles=true`
      };
      imagePath = Object.assign({}, imagePath, updatePath);
      imagesPath.push(imagePath);
    }
    return imagesPath;
  }
}
