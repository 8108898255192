<div *ngIf="activationProfile">
  <h1 class="activation-profile-title">Activation Profile</h1>
  <div class="card-image">
    <img *ngIf="activationProfileSvg" class="padding-1"
         src="assets/images/deliverables/ActivationProfile/{{activationProfileSvg}}.svg" alt="Icon">
  </div>
  <div class="bold text-center">{{activationProfile}}</div>
</div>


