import {ProductServiceFactory} from '@platform/services/product-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ReportService} from '@platform/services/report.service';
import {CollaboratorService} from '@platform/services/collaborator.service';
import {InvitationService} from '@platform/services/invitation.service';
import {Report} from '@platform/models/report.model';
import {Collaborator} from '@platform/models/collaborator.model';
import {UserInfo} from '@platform/models/user-info.model';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {MatLegacyCheckbox as MatCheckbox} from '@angular/material/legacy-checkbox';
import {ToasterService} from '@platform/services/toaster.service';
import {Archive} from '@platform/models/archive.model';
import {ArchiveService} from '@platform/services/archive.service';
import {switchMap, take} from 'rxjs/operators';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {forkJoin, Observable, of} from "rxjs";
import {SpinnerService} from '@platform/services/spinner.service';
import {PrivilegeService} from "@platform/services/privilege.service";

@Component({
    selector: 'ns-share-results',
    templateUrl: './share-results.component.html',
    styleUrls: ['./share-results.component.scss']
})
export class ShareResultsComponent implements OnInit {

    productName: string;
    collaborators: Array<Collaborator>;
    clientUsers: Array<Collaborator>;
    internalUsers: Array<Collaborator>;
    deliverableSharedUsers: Set<Collaborator>;
    forecastSharedUsers: Set<Collaborator>;
    notifiedUsers: Array<number>;
    showNotifiersList = false;
    viewCollaborators = true;
    selectionLabel: string;
    permissionChanges: Array<{ collaborator: Collaborator, permission: string, add: boolean }>;

    @Input() report: Report;
    @Input() userInfo: UserInfo;
    @Input() collaboratedUser: Collaborator;
    @Input() isAdmin: boolean;
    @Output() hideModal = new EventEmitter<boolean>();
    @Output() showToast = new EventEmitter<boolean>();

    @ViewChild('notifyAllElement') notifyAllElement: MatCheckbox;
    @ViewChildren('clientList') clientList: QueryList<any>;
    @ViewChildren('forecastClientList') forecastClientList: QueryList<any>;
    @ViewChildren('internalUsersList') internalUsersList: QueryList<any>;
    @ViewChild('shareResultsDialog') shareResultsDialog: TemplateRef<any>;

    private readonly noCollabLocaleKey: string;
    private readonly allNielsenCollabLocaleKey: string;
    public archiveData: Archive;
    public showForecastColumn: boolean;
    public showDeliverableColumn: boolean;

    constructor(
        private reportService: ReportService,
        private productServiceFactory: ProductServiceFactory,
        private collaboratorService: CollaboratorService,
        private invitationService: InvitationService,
        private toasterService: ToasterService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private archiveService: ArchiveService,
        private mixpanelService: MixpanelService,
        private spinnerService: SpinnerService,
        private privilegeService: PrivilegeService
    ) {
        this.noCollabLocaleKey = 'platform.report.share.label.no.collaborators';
        this.allNielsenCollabLocaleKey = 'platform.report.share.label.all.nielsen.collaborators';
    }

    /**
     * Initialize the Share Results component view.
     *
     * >> If there are no collaborators, show a message quoting the same.
     * >> If collaborators exist, show list of collaborators to share results.
     * @memberof ShareResultsComponent
     */
    ngOnInit(): void {
        this.productName = this.productServiceFactory.getService().getProductName();
        const deliverablesExists = this.reportService.nonForecastDeliverableExists(this.report);
        const forecastingExists = this.reportService.forecastDeliverableExists(this.report);
        const hasForecastPermission = this.privilegeService.hasForecastSharePermission(this.report, this.userInfo);
        const hasDeliverablePermission = this.privilegeService.hasDeliverableSharePermission(this.report, this.userInfo);

        this.showForecastColumn = forecastingExists && hasForecastPermission;
        this.showDeliverableColumn = deliverablesExists && hasDeliverablePermission;

        this.initializeCollaborators(this.report.projectId).subscribe();
        const archive$ = this.archiveService.getReportArchive(this.report.id);
        archive$.pipe(take(1)).subscribe(archive => {
            this.archiveData = archive;
        });
        this.isAdmin = this.userInfo.roles.includes('ROLE_SYSTEM_ADMINISTRATOR');
    }

    initializeCollaborators(projectId: number): Observable<Array<Collaborator>> {
        return forkJoin([this.collaboratorService.fetchReportCollaborators(this.report.id), this.collaboratorService.get(projectId)]).pipe(switchMap((results) => {
            const studioCollaborators = results[1];
            const collaborators = results[0];
            this.report = {...this.report, ...collaborators};

            this.collaborators = [];
            this.permissionChanges = [];
            this.syncStudioCollaborator(studioCollaborators);
            this.setCollaboratorModelParam();
            return of(studioCollaborators);
        }));
    }

    /**
     * set parameters for Share Results component view.
     *
     * @memberOf ShareResultsComponent
     */
    public setCollaboratorModelParam(): void {
        if (this.collaborators.length === 0) {
            this.viewCollaborators = false;
        } else {
            this.clientUsers = this.collaborators.filter(collaborator => collaborator.isClient);
            const clientUser = this.clientUsers.find(user => user.userManagementId === this.userInfo.userId);
            if (this.clientUsers.length < 1 || (this.clientUsers.length === 1 && clientUser)) {
                this.viewCollaborators = false;
            }
            this.internalUsers = this.collaborators.filter(collaborator => !collaborator.isClient);
            this.deliverableSharedUsers = new Set(this.collaborators.filter(collaborator => collaborator.privileges?.includes(CollaboratorService.DELIVERABLE_SHARE_PERMISSION)));
            this.forecastSharedUsers = new Set(this.collaborators.filter(collaborator => collaborator.privileges?.includes(CollaboratorService.FORECAST_SHARE_PERMISSION)));
            this.notifiedUsers = [];
            if (this.collaboratedUser && !this.collaboratedUser.isClient) {
                this.notifiedUsers.push(this.collaboratedUser.userId);
            }
            this.setSelectionLabel();
        }
    }

    /**
     * set selection label for notified users for Share Results component view.
     *
     * @memberof ShareResultsComponent
     */
    public setSelectionLabel(): void {
        const loggedInUser = this.collaboratedUser && this.collaborators.find(collaborator => collaborator.userId === this.collaboratedUser.userId);
        if (loggedInUser) {
            this.selectionLabel = loggedInUser.firstName ? loggedInUser.firstName : loggedInUser.displayName;
        } else {
            this.selectionLabel = this.translateNoCollab();
        }
    }

    /**
     * sync studio and reporting collaborator for Share Results component view.
     *
     * @memberof ShareResultsComponent
     */
    public syncStudioCollaborator(studioCollaborators: { collaborators: any[]; }): void {
        studioCollaborators.collaborators.forEach(displayCollaborator => {
            const reportCollaborator = this.report.collaborators.find(collaborator => collaborator.userId === displayCollaborator.userId);
            if (reportCollaborator) {
                const updateCollaborator = {
                    userName: displayCollaborator.username,
                    displayName: displayCollaborator.displayName,
                    firstName: displayCollaborator.firstName,
                    isOwner: reportCollaborator.isOwner,
                    isClient: reportCollaborator.isClient,
                    userId: reportCollaborator.userId,
                    role: displayCollaborator.role,
                    userManagementId: reportCollaborator.userManagementId,
                    privileges: []
                };
                if (reportCollaborator.privileges) {
                    reportCollaborator.privileges.forEach((type: string, index: number) => {
                        updateCollaborator['privileges'][index] = type;
                    });
                }
                this.collaborators.push(updateCollaborator);
            }
        });
    }

    /**
     * Function to check/uncheck all the entries to share Core Deliverables
     *
     * @param {*} selectAllElement element that selects/unselects all underneath elements
     * @param {any[]} itemList array of shared user elements
     * @memberof ShareResultsComponent
     */
    public selectShareAllCoreDeliverables(selected: boolean): void {
        this.collaborators.forEach(collaborator => {
            if (collaborator.isClient && collaborator.userManagementId !== this.userInfo.userId) {
                this.toggleSharePermission(selected, collaborator);
            }
        });
    }

    /**
     * Function to check/uncheck all the entries to share Forecast
     *
     * @memberof ShareResultsComponent
     * @param shareForecastWithAllElement
     */
    public selectShareAllForecastDeliverables(selected: boolean): void {
        this.collaborators.forEach(collaborator => {
            if (collaborator.isClient && collaborator.userManagementId !== this.userInfo.userId) {
                this.toggleForecastPermission(selected, collaborator);
            }
        });
    }

    public togglePermission(shared: boolean, collaborator: Collaborator, permission: string) {
        const sharedUsers = permission === CollaboratorService.DELIVERABLE_SHARE_PERMISSION ? this.deliverableSharedUsers : this.forecastSharedUsers;
        if (shared) {
            if (!sharedUsers.has(collaborator)) {
                sharedUsers.add(collaborator);
                let index = this.permissionChanges.findIndex(it => it.collaborator == collaborator && it.permission === permission && !it.add);
                if (index !== -1) {
                    this.permissionChanges.splice(index, 1);
                } else {
                    this.permissionChanges.push({collaborator, permission, add: true});
                }
            }
        } else {
            if (sharedUsers.has(collaborator)) {
                sharedUsers.delete(collaborator);
                let index = this.permissionChanges.findIndex(it => it.collaborator == collaborator && it.permission === permission && it.add);
                if (index !== -1) {
                    this.permissionChanges.splice(index, 1);
                } else {
                    this.permissionChanges.push({collaborator, permission, add: false});
                }
            }
        }

    }

    public toggleSharePermission(shared: boolean, user: Collaborator) {
        this.togglePermission(shared, user, CollaboratorService.DELIVERABLE_SHARE_PERMISSION);
    }

    public toggleForecastPermission(shared: boolean, user: Collaborator) {
        this.togglePermission(shared, user, CollaboratorService.FORECAST_SHARE_PERMISSION);
    }

    /**
     * Function to check/uncheck all the entries to notify
     *
     * @param {*} selectAllElement element that selects/unselects all underneath elements
     * @param {any[]} itemList array of notify user elements
     * @memberof ShareResultsComponent
     */
    public selectNotifyAll(selectAllElement: any, itemList: QueryList<any>): void {
        const selection = selectAllElement.checked;
        itemList.forEach(item => {
            item.checked = !selection;
        });
        this.notifiedUsers = [];
        this.notifiedUsers = !selection ? this.internalUsers.map(user => user.userId) : [];
        this.selectionLabel = !selection ? this.translateAllNielsenCollab() : this.translateNoCollab();
    }

    /**
     * Update the list of notified users and selection label
     * based on individual checkbox selection
     *
     * @param none
     * @memberof ShareResultsComponent
     */
    public toggleNotifySelection(): void {
        this.selectionLabel = '';
        this.notifiedUsers = [];
        this.internalUsersList.forEach(fieldElement => {
            if (fieldElement.checked) {
                const collaborator: Collaborator = this.collaborators.find((user: Collaborator) => {
                    return user.userName === fieldElement.value;
                });
                this.notifiedUsers.push(collaborator.userId);
                this.selectionLabel = this.notifiedUsers.length === 1 ? fieldElement.name : `${this.selectionLabel}, ${fieldElement.name}`;
            }
        });
        this.handleNotifyAll();
    }

    /**
     * Update the 'notifyAll' checkbox
     * based on checkbox selection
     *
     * @param none
     * @memberof ShareResultsComponent
     */
    private handleNotifyAll(): void {
        const selectedUsers = this.internalUsersList.filter(fieldElement => fieldElement.checked);
        switch (selectedUsers.length) {
            case 0:
                this.selectionLabel = this.translateNoCollab();
                this.notifyAllElement.checked = false;
                break;
            case this.internalUsers.length:
                this.selectionLabel = this.translateAllNielsenCollab();
                this.notifyAllElement.checked = true;
                break;
            default:
                this.notifyAllElement.checked = false;
                break;
        }
    }

    /**
     * Close the share-results modal and update parent component
     *
     * @param none
     * @memberof ShareResultsComponent
     */
    public closeModal(): void {
        this.selectionLabel = this.translateNoCollab();
    }

    /**
     * Share the results and notify to the selected users
     *
     * @param none
     * @memberof ShareResultsComponent
     */
    public shareProject(): void {
        this.mixpanelService.track(this.mixpanelService.getCurrentFeatureLabel(), MixpanelEvent.shareResults);
        this.collaboratorService.updatePermissions(this.report.id, this.permissionChanges, this.notifiedUsers).subscribe(result => {
            this.toasterService.openSnackBar(this.translate.instant('platform.share.result.success'), 'success');
            this.report = {...this.report, collaborators: result};
            this.reportService.update({...this.report});
        });
        this.selectionLabel = this.translateNoCollab();
        this.dialog.closeAll();
    }

    /**
     * Translates no collaborator label.
     *
     * @returns {string} the translation
     */
    private translateNoCollab(): string {
        return this.translate.instant(this.noCollabLocaleKey);
    }

    /**
     * Translates all nielsen collaborator label.
     *
     * @returns {string} the translation
     */
    private translateAllNielsenCollab(): string {
        return this.translate.instant(this.allNielsenCollabLocaleKey);
    }

    /**
     * Dialog for shareResult.
     *
     * @returns
     */
    public showShareResultsDialog(): void {
        this.spinnerService.showSpinner();
        this.showNotifiersList = false;
        this.initializeCollaborators(this.report.projectId).subscribe(() => {
            this.spinnerService.hideSpinner();
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = '800px';
            this.dialog.open(this.shareResultsDialog, dialogConfig);
        });
    }


    /**
     * Dialog for manage insights.
     */
    enlargeArchiveSettingsDialog(archiveSettingsModal: TemplateRef<any>): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        this.dialog.open(archiveSettingsModal, dialogConfig);
    }

    /**
     * close model dialog and
     * reloads insights
     */
    closeDialog(event: boolean): void {
        this.dialog.closeAll();
    }

    /**
     * Used to export Report
     */
    downloadReportZipFile() {
        this.reportService.downloadFile(this.report.id);
    }
}

