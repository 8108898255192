import {DeliverablesModule} from '@platform/deliverables/deliverables.module';
import {DeliverablesGuard} from '@platform/guards/deliverables.guard';
import {ForecastingGuard} from '@platform/guards/forecasting.guard';
import {FeatureFlagGuard} from '@platform/guards/featureflag.guard';
import {NodeCaptureModule} from '@platform/node-capture/node-capture.module';
import {FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

// Library import
import {ApplicationBarModule, BrandBarModule, CommentModule, ErrorModule, FlyoutModule} from '@basesstudio/ngx-components';
import {ReportComponent} from '@app/platform/report/report.component';
import {ShareResultsComponent} from '@app/platform/report/share-results/share-results.component';
import {ArchiveSettingsComponent} from '@platform/report/share-results/archive-settings/archive-settings.component';

// Store
import {metaReducers, reducers} from '@platform/store/reducers';
import {CustomRouterSerializer} from '@platform/store/reducers/router.serializer';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {QuillModule} from 'ngx-quill';
import {ProgressSpinnerModule} from './progress-spinner/progress-spinner.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ImportBenchmarkResultsRoutingModule} from '@platform/import-benchmark-results/import-benchmark-results-routing.module';
import {HeaderSectionComponent} from '@platform/components/header-section/header-section.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {ConfirmationDialogModule} from '@app/common/confirmation-dialog/confirmation-dialog.module';
import {
    DeliverableConfigurationComponent
} from '@platform/report/report-settings/deliverable-configuration/deliverable-configuration.component';
import {ReportSettingsComponent} from '@platform/report/report-settings/report-settings.component';
import {ForecastEstimateComponent} from './report/report-settings/forecast-estimate/forecast-estimate.component';
import {DeliverablesListModule} from '@products/shared/deliverables-list/deliverables-list.module';
import {DeliverablesEmptyStateModule} from '@products/shared/deliverables-empty-state/deliverables-empty-state.module';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {
    VolumeEstimateConfigurationComponent
} from './report/report-settings/deliverable-configuration/volume-estimate-configuration/volume-estimate-configuration.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {
    MarketingPlanConfigurationComponent
} from './report/report-settings/deliverable-configuration/marketing-plan-configuration/marketing-plan-configuration.component';
import {
    VarietySplitDeliverableConfigurationComponent
} from './report/report-settings/deliverable-configuration/variety-split-deliverable-configuration/variety-split-deliverable-configuration.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {
    SurveyQuestionsConfigurationComponent
} from './report/report-settings/deliverable-configuration/survey-questions-configuration/survey-questions-configuration.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    SurveyQuestionAnswersConfigurationComponent
} from './report/report-settings/deliverable-configuration/survey-questions-configuration/survey-question-answers-configuration/survey-question-answers-configuration.component';
import {FlyoutMenuModule} from '@app/common/flyout-menu/flyout-menu.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {
    ReportSettingsConfigurationComponent
} from './report/report-settings/deliverable-configuration/report-settings-configuration/report-settings-configuration.component';
import {
    DeliverableConfigurationHeaderComponent
} from './report/report-settings/deliverable-configuration/deliverable-configuration-header/deliverable-configuration-header.component';
import {
    PurchasePreferenceComponent
} from './report/report-settings/deliverable-configuration/purchase-preference/purchase-preference.component';
import {
    CorrelationsConfigurationComponent
} from '@platform/report/report-settings/deliverable-configuration/correlations-configuration/correlations-configuration.component';
import { FactorsConfigurationComponent } from './report/report-settings/deliverable-configuration/factors-configuration/factors-configuration.component';
import {
    FinancialPotentialConfigurationComponent
} from '@platform/report/report-settings/deliverable-configuration/financial-potential-configuration/financial-potential-configuration.component';
import {KeyTitleCasePipe} from '@platform/pipes/key-title-case.pipe';
import { DeliverableConfigurationFooterComponent } from './report/report-settings/deliverable-configuration/deliverable-configuration-footer/deliverable-configuration-footer.component';
import { DeliverableConfigurationBodyComponent } from './report/report-settings/deliverable-configuration/deliverable-configuration-body/deliverable-configuration-body.component';


const materialModules: any = [
    MatButtonModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatDialogModule,
    MatSliderModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatIconModule,
];

const libraryModules: any = [
    ApplicationBarModule,
    BrandBarModule,
    CommentModule,
    ErrorModule
];

/**
 * Platform module.
 *
 * @export
 * @class PlatformModule
 */
@NgModule({
    imports: [
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreRouterConnectingModule.forRoot({
            serializer: FullRouterStateSerializer,
            stateKey: 'router',
        }),
        CommonModule,
        HttpClientModule,
        RouterModule,
        TranslateModule,
        ProgressSpinnerModule,
        QuillModule.forRoot(),
        ...materialModules,
        ...libraryModules,
        NodeCaptureModule,
        DeliverablesModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ImportBenchmarkResultsRoutingModule,
        FlyoutModule,
        ConfirmationDialogModule,
        DeliverablesListModule,
        DeliverablesEmptyStateModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        ReactiveFormsModule,
        DragDropModule,
        FlyoutMenuModule,
        MatToolbarModule
    ],
    exports: [
        HeaderSectionComponent,
        KeyTitleCasePipe
    ],
    declarations: [
        ReportComponent,
        ShareResultsComponent,
        ArchiveSettingsComponent,
        HeaderSectionComponent,
        ReportSettingsComponent,
        DeliverableConfigurationComponent,
        ForecastEstimateComponent,
        VolumeEstimateConfigurationComponent,
        MarketingPlanConfigurationComponent,
        VarietySplitDeliverableConfigurationComponent,
        SurveyQuestionsConfigurationComponent,
        SurveyQuestionAnswersConfigurationComponent,
        DeliverableConfigurationHeaderComponent,
        ReportSettingsConfigurationComponent,
        PurchasePreferenceComponent,
        CorrelationsConfigurationComponent,
        FactorsConfigurationComponent,
        FinancialPotentialConfigurationComponent,
        KeyTitleCasePipe,
        DeliverableConfigurationFooterComponent,
        DeliverableConfigurationBodyComponent
    ],
    providers: [
        DeliverablesGuard,
        ForecastingGuard,
        FeatureFlagGuard
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PlatformModule {
    /**
     * `forRoot()` method returns a `ModuleWithProviders` object which provides
     * `root` modules with the shared service instances.
     *
     * @static
     * @returns {ModuleWithProviders}
     * @memberof PlatformModule
     */
    static forRoot(): ModuleWithProviders<PlatformModule> {
        return {
            ngModule: PlatformModule,
            providers: [
                {provide: RouterStateSerializer, useClass: CustomRouterSerializer}
            ]
        };
    }
}
