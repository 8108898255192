import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";

@Injectable()
export class WordCloudFileService {

    private baseURI: string;

    constructor(
        private httpClient: HttpClient,
        private reportService: ReportService,
        private spinnerService: SpinnerService,
        private cs: AppConfigService) {
        this.baseURI = `${this.cs.config.reporting.url}`;
    }

    /**
     * Downloads the file from api.
     *
     */
    public download(): void {
        this.spinnerService.setSpinnerObs();
        const report$ = this.reportService.get();
        report$.subscribe(report => {
            // use - instead of . for the type.  if . is used everything after . is removed.
            const uri = `${this.baseURI}/reports/${report.id}/deliverables/${DeliverableType.WORD_CLOUD.type.replace('.', '-')}`;
            const contentType = 'application/octet-stream';

            const headers = new HttpHeaders({
                Accept: contentType
            });
            const options = {
                responseType: 'blob' as 'blob',
                headers: headers
            };
            this.httpClient.get(uri, options).subscribe(data => {
                this.spinnerService.spinnerObs$.next(false);
                const downloadURL = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = `Verbatims ${report.name}.csv`;
                link.click();
                link.remove();
            }, (err: HttpErrorResponse) => {
                if (err instanceof Error) {
                    // client-side error
                    console.log(`An error occurred ${err.error.message}`);
                } else {
                    console.log(`${err.status}, body was: ${err.message}`);
                }
                this.spinnerService.spinnerObs$.next(false);
            });
        });
    }
}
