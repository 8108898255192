import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ns-deliverables-empty-state',
  templateUrl: './deliverables-empty-state.component.html',
  styleUrls: ['./deliverables-empty-state.component.scss']
})
export class DeliverablesEmptyStateComponent implements OnInit {

  @Input() sifIcon: string = '';
  @Input() message: string = '';
  @Input() message2: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
