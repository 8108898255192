import {State} from '@platform/store/state/app.state';
import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {Observable, of} from 'rxjs';
import {skipWhile, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Concept} from '@platform/models/concept.model';
import {addConcepts} from '@platform/store/actions/concept.actions';
import {selectAllConcepts, selectReportConcepts} from '@platform/store/selectors/concept.selectors';

/**
 * This service has operations for fetching and loading report concepts.
 *
 * @example
 * constructor(private conceptService: ConceptService) { }
 *
 * @export
 * @class ConceptService
 */
@Injectable({
  providedIn: 'root'
})
export class ConceptService {

  /**
   * Loading flag to prevent multiple server calls.
   *
   * @private
   */
  private isLoading = false;

  /**
   * Creates an instance of ConceptService.
   *
   * @constructor
   * @param {Store<State>} store
   * @param {HttpClient} httpClient
   * @param {ReportService} reportService
   * @param {AppConfigService} appConfigService
   * @memberof ConceptService
   */
  constructor(
    private store: Store<State>,
    private httpClient: HttpClient,
    private reportService: ReportService,
    private appConfigService: AppConfigService) {
  }

  getConcepts(reportId: string): Observable<Concept[]> {
    return this.fetchReportConceptsFromStore(reportId).pipe(switchMap((concepts) => {
      if (concepts.length) {
        return of(concepts);
      } else {
        return this.fetchFromAPI(reportId).pipe(switchMap((concepts) => {
          this.loadOnStore(concepts);
          return of(concepts);
        }));
      }
    }));
  }

  /**
   * Returns observable of concepts for the current report object
   * from the store if available else fetch it using API.
   *
   * @example
   * const concepts$ = conceptService.getReportConcepts();
   *
   * @returns {Observable<Array<Concept>>}
   * @memberof ConceptService
   */
  public getReportConcepts(): Observable<Array<Concept>> {
    const report$ = this.reportService.get();
    return report$.pipe(
      switchMap(report => {
        this.load(report.id);
        return this.fetchReportConceptsFromStore(report.id);
      }),
      skipWhile(concepts => concepts.length === 0)
    );
  }

  public getInteractionConceptAndCompetitors(): Observable<Array<Concept>> {
      const report$ = this.reportService.get();
      return report$.pipe(
          switchMap(report => {
              return this.fetchFromAPI(report.id);
          }),
          skipWhile(concepts => concepts.length === 0)
      );
  }

    /**
     * Returns list of concepts for deliverable with excluded concepts removed.
     *
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    public getReportDeliverableConcepts(deliverableType: string): Observable<Array<Concept>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                this.load(report.id, deliverableType);
                return this.fetchReportConceptsFromStore(report.id);
            }),
            skipWhile(concepts => concepts.length === 0)
        );
    }

    /**
     * Returns list of concepts for deliverable with Benchmarks.
     *
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    public getReportDeliverableConceptsWithBenchmarks(deliverableType: string): Observable<Array<Concept>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                // this.load(report.id, deliverableType);
                // return this.fetchAllConceptsFromStore();
                return this.fetchFromAPI(report.id, deliverableType);
            }),
            skipWhile(concepts => concepts.length === 0)
        );
    }

  /**
   * Return the concept image uri for the locale. If locale is not available
   * then en_US locale image is returned.
   *
   * @param {Concept} concept
   * @param {string} locale code
   * @returns {string} Concept image uri
   */
  getConceptImageURI(concept: Concept, locale?: string): string {
    const image = concept?.images?.find(ci => (locale && ci.locale === locale) || (!locale && ci.locale.startsWith('en_')));
    if (image) {
      const uri = `${this.appConfigService.config.reporting.url}/reports/${concept.reportId}/concepts/${concept.id}?imageFiles=true&locale=${image.locale}`;
      return uri;
    }
    return null;
  }

  /**
   * Loads all concepts associated with the current report into
   * the store.
   *
   * @example
   * conceptService.load('1');
   *
   * @param {string} reportId The report id
   * @param deliverableType
   * @memberof ConceptService
   */
  public load(reportId: string, deliverableType?: string): void {
      const concepts$ = this.fetchReportConceptsFromStore(reportId);
      concepts$.pipe(take(1)).subscribe(concepts => {
        if (this.isLoading !== true && concepts.length === 0) {
            this.isLoading = true;
            this.fetchFromAPI(reportId, deliverableType).subscribe(result => {
                this.loadOnStore(result);
                this.isLoading = false;
            });
        }
    });
  }

   /**
   * Returns an observable of concepts fetched using the API.
   *
   * @private
   * @param {string} reportId The report id.
   * @param deliverableType
   * @returns {Observable<Array<Concept>>}
   * @memberof ConceptService
   */
  public fetchFromAPI(reportId: string, deliverableType?: string ): Observable<Array<Concept>> {
      let url = '';
      if (deliverableType) {
          url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/concepts?deliverableType=${deliverableType}`;
      } else {
          url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/concepts`;
      }
      return this.httpClient.get<Array<Concept>>(url);
  }

  /**
   * Loads a list of concepts into the store.
   *
   * @private
   * @param {Array<Concept>} concepts Array of concepts.
   * @memberof ConceptService
   */
  public loadOnStore(concepts: Array<Concept>): void {
    this.store.dispatch(addConcepts({concepts}));
  }

  /**
   * Fetch all concepts associated with the current report
   * from the store.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Array<Concept>>}
   * @memberof ConceptService
   */
  private fetchReportConceptsFromStore(reportId: string): Observable<Array<Concept>> {
    return this.store.select(selectReportConcepts(reportId));
  }

    /**
     * Fetch all concepts associated with the current report and any associated benchmarks
     * from the store.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<Concept>>}
     * @memberof ConceptService
     */
    private fetchAllConceptsFromStore(): Observable<Array<Concept>> {
        return this.store.select(selectAllConcepts);
    }

}
