import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Report} from '@platform/models/report.model';
import {Concept} from '@platform/models/concept.model';
import {ConceptService} from '@platform/services/concept.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Deliverable} from '@platform/models/deliverable.model';
import {ActivationProfileDeliverableView, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {Subgroup} from '@platform/models/subgroup.model';
import {ConceptDataItem, FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {FinancialPotentialDeliverableView, Measures as FpMeasures} from '@app/deliverables/financial-potential/models/financial-potential.model';
import {TranslateService} from '@ngx-translate/core';
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';

@Component({
    selector: 'ns-add-benchmark-concept-card',
    templateUrl: './add-benchmark-concept-card.component.html',
    styleUrls: ['./add-benchmark-concept-card.component.scss']
})
export class AddBenchmarkConceptCardComponent implements OnInit, OnChanges {

    @Input() report: Report;
    @Input() concept: Concept;
    @Input() importedConceptIds: [];
    @Input() conceptAdded: boolean;
    @Input() activationProfileDeliverableView: ActivationProfileDeliverableView;
    @Input() factorsDeliverableView: FactorsDeliverableView;
    @Input() financialPotentialDeliverableView: FinancialPotentialDeliverableView;
    @Input() subgroup: Subgroup;
    @Output() toggleConceptAdded: EventEmitter<Concept> = new EventEmitter();
    @ViewChild('fullSizeConceptImageDialog') fullSizeConceptImageDialog;

    fullSizeConceptImageDialogRef: MatDialogRef<AddBenchmarkConceptCardComponent>;

    conceptImageUrl: string;

    measureDataForConcept: Measures;
    factorsDataForConcept: ConceptDataItem;
    fpMeasureDataForConcept: FpMeasures;
    activationProfileShowDeliverable = true;
    factorsShowDeliverable = true;
    fpShowDeliverable = true;
    fpShowPurchaseIntent = false;
    fpShowUnits = false;
    fpShowFrequency = false;
    showFinancialPotential = false;
    isFPConfigVisible = true;
    isFactorConfigVisible = true;
    /**
     * Activation profile deliverable associated with the report if it exists.
     * */

    disabledCheckboxIncompleteDetailsTooltip: string;
    isCardDisabled = false;

    constructor(private conceptService: ConceptService,
                private dialog: MatDialog,
                private translateService: TranslateService,
                private deliverableConfigurationService: DeliverableConfigurationService) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize() {
        const primaryLocale = this.report.locales[0];
        this.conceptImageUrl = this.conceptService.getConceptImageURI(this.concept, primaryLocale);
        this.activationProfileShowDeliverable = this.report.deliverables.find(it => it.type === 'activation.profiles')?.showDeliverable ?? true;
        this.factorsShowDeliverable = this.report.deliverables.find(it => it.type === 'factors')?.showDeliverable ?? true;
        this.fpShowDeliverable = this.report.deliverables.find(it => it.type === 'financial.potential')?.showDeliverable ?? true;
        this.measureDataForConcept = this.activationProfileDeliverableView ? this.activationProfileDeliverableView.measures.find(it => it.conceptId === this.concept.exerciseConceptId) : null;
        this.factorsDataForConcept = this.factorsDeliverableView ? this.factorsDeliverableView.concepts.find(it => it.conceptId === this.concept.exerciseConceptId && it.segmentId === this.subgroup.segmentId) : null;
        this.fpMeasureDataForConcept = this.financialPotentialDeliverableView ? this.financialPotentialDeliverableView.measures.find(it => it.conceptId === this.concept.exerciseConceptId && it.segmentId === this.subgroup.segmentId) : null;
        const meta = this.financialPotentialDeliverableView?.metaInfo?.columnHeaders;
        this.fpShowPurchaseIntent = meta?.purchaseIntent;
        this.fpShowUnits = meta?.trialUnits;
        this.fpShowFrequency = meta?.frequency;
        this.showFinancialPotential = this.fpShowPurchaseIntent || this.fpShowUnits || this.fpShowFrequency;
        this.deliverableConfigurationService.fetchAll(this.report.id).subscribe(deliverableConfiguration => {
            const factorConfiguration = deliverableConfiguration.find(it => it.name === 'factors')?.config;
            const fpConfiguration = deliverableConfiguration.find(it => it.name === 'fp')?.config;
            this.isFPConfigVisible = this.checkIfFpConfigEnabled(fpConfiguration);
            this.isFactorConfigVisible = this.checkIfFactorConfigEnabled(factorConfiguration);
            this.disabledCheckboxIncompleteDetailsTooltip = !this.isAllDataAvailable() ? this.translateService.instant('platform.benchmark.concept.import.disabled.checkbox.incomplete.message') : '';
            if (!this.isAllDataAvailable() && this.conceptAdded) {
                this.toggleSelect();
            }
            this.isCardDisabled = this.isImported(this.importedConceptIds, this.concept.exerciseConceptId) || !this.isAllDataAvailable();
        });
    }

    showFullSizeConcept(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.hasBackdrop = false;
        this.fullSizeConceptImageDialogRef = this.dialog.open(this.fullSizeConceptImageDialog, dialogConfig);
    }

    isAllDataAvailable() {
        const isActivationProfileValid = this.activationProfileShowDeliverable && this.measureDataForConcept;
        const isFPValid = this.fpShowDeliverable && this.fpMeasureDataForConcept && !this.isFPConfigVisible;
        const isFactorsValid = this.factorsShowDeliverable && this.factorsDataForConcept && !this.isFactorConfigVisible;

        return isActivationProfileValid || isFPValid || isFactorsValid;
    }

    checkIfFpConfigEnabled(fpConfig: any){
        return (!fpConfig.purchaseIntent && !fpConfig.frequency && !fpConfig.trialUnits);
    }

    checkIfFactorConfigEnabled(factorConfig: any){
        return (!Object.entries(factorConfig)
            .some(([key, value]) => value && key !== 'messagingCommunicationsFactors' && key !== 'conceptPotentialScore'));
    }

    closeFullSizeConcept(): void {
        this.fullSizeConceptImageDialogRef.close();
    }

    toggleSelect(): void {
        this.toggleConceptAdded.emit(this.concept);
    }

    isImported(listOfImported, reportId){
        return listOfImported.indexOf(reportId) !== -1;
    }
}
