/**
 * Deliverable view types for attributes.
 *
 * @export
 * @enum {string}
 */
export enum DeliverableViewType {
  /**
   * Concept deliverable view type.
   */
  CLICK_DATA = 'clickData',

  /**
   * Verbatims deliverable view type.
   */
  VERBATIMS = 'verbatims',

  /**
   * Subgroups deliverable view type.
   */
  ELEMENT_ENGAGEMENT = 'elementEngagement'
}
