import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {GapAnalysisDeliverableView} from '@products/restage/gap-analysis/models/gap-analysis.model';

import * as d3 from 'd3';
import {GapAnalysisChartData} from '@products/restage/gap-analysis/models/gap-analysis-chart-data.model';

@Component({
  selector: 'ns-gap-analysis-chart',
  templateUrl: './gap-analysis-chart.component.html',
  styleUrls: ['./gap-analysis-chart.component.scss']
})
export class GapAnalysisChartComponent implements OnChanges {

  /**
   * `BarChart` object for constructing the d3 bar chart.
   *
   * @type {GapAnalysisDeliverableView}
   * @memberOf ChartHorizontalBarComponent
   */
  @Input() chartData: GapAnalysisChartData;

  @Input() chartOptions: any;
  /**
   * `groupedBarChartContainer` for adding the d3 chart.
   *
   * @type {ElementRef}
   * @memberOf ChartHorizontalBarComponent
   */
  @ViewChild('groupedBarChartContainer', { static: true }) groupedBarChartContainer: ElementRef;

  public groupNames;

  public subGroupNames;

  constructor() { }

  /**
   * Observe gapAnalysisChartData input changes.
   *
   * @memberOf GapAnalysisChartComponent
   * @param {SimpleChanges} changes all changes detected by angular
   */
  ngOnChanges(changes: SimpleChanges): void {
    const container: HTMLElement = this.groupedBarChartContainer.nativeElement;

    if (!this.chartData) {
      return;
    }

    this.groupNames = this.chartData.statementGroups;
    this.subGroupNames = this.chartData.restageSubGroups;
    // Clean any existing children.
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
    if(this.groupNames.length > 0 && this.subGroupNames.length > 0){
      this.buildGroupedBarChart(container);
    }
  }

  public buildGroupedBarChart(container: any): void {
    const gapAnalysisChartSvgContainer = this.getSVGContainer(container, this.chartOptions);
    const margin = this.chartOptions.margin;
    const gapAnalysisChartGroupElement: any = gapAnalysisChartSvgContainer.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')').attr('class', 'gap-chart-content');

    // width and height of the svg container created
    const width = +gapAnalysisChartSvgContainer.attr('width'),
      height = +gapAnalysisChartSvgContainer.attr('height');

    // the axes scale for both x, y and subgroup
    const axisScale = this.getAxisScale(width, height);

    // defining the scatter chart axes
    this.defineChartAxes(gapAnalysisChartGroupElement, width, height, axisScale);

    this.formatXAxislabelToAppendTicks();

    // bar colors
    const barColors = this.getBarColors();

    this.renderBars(gapAnalysisChartGroupElement, axisScale, height, barColors);

    this.renderStaticHorizontalLine(gapAnalysisChartGroupElement, axisScale);

    this.renderStatementGroupLines(gapAnalysisChartGroupElement, axisScale);
  }

  public getSVGContainer(container: any, options: any) {
    const containerWidth = container.offsetWidth - 140;
    let svg, margin, marginLeft, marginRight,
      marginTop, marginBottom;
    margin = options.margin;
    marginLeft = margin && margin.left ? margin.left : 0;
    marginRight = margin && margin.right ? margin.right : 0;
    marginTop = margin && margin.top ? margin.top : 0;
    marginBottom = margin && margin.bottom ? margin.bottom : 0;
    svg = d3.select(container)
      .append('svg')
      .attr('width', containerWidth + marginLeft + marginRight)
      .attr('height', options.height + marginTop + marginBottom);
    return svg;
  }

  public getAxisScale(width: number, height: number) {

    const margin = this.chartOptions.margin;
    const yAxisData = this.chartData.yAxisRange;
    const scale = {},
      domainHeight = height - margin.top - margin.bottom;

    // x axis scale for groups
    const x = d3.scaleBand()
      .domain(this.groupNames)
      .range([0, this.groupNames.length * 125])
      .padding(0.2);

    // x axis scale for subgroups position
    const xSubgroup = d3.scaleBand()
      .domain(this.subGroupNames)
      .range([0, x.bandwidth()])
      .padding(0.05);

    // y axis scale
    const yRangeMinValue = yAxisData.yMinValue,
      yRangeMaxValue = yAxisData.yMaxValue > 100 ? yAxisData.yMaxValue : 120;
    const y = d3.scaleLinear()
      .domain([yRangeMinValue, yRangeMaxValue]) // static scale 120 if max less than 100 else maximum value for a bar chart
      .range([domainHeight, 0]);

    scale['x'] = x;
    scale['y'] = y;
    scale['xSubgroup'] = xSubgroup;

    return scale;
  }

  public defineChartAxes(gapAnalysisChartGroupElement: any, width: number, height: number, scale: any) {
    const yAxisData = this.chartData.yAxisRange;
    const xScale = scale.x,
      yScale = scale.y;

    const margin = this.chartOptions.margin,
      xAxis = this.chartOptions.xAxis,
      yAxis = this.chartOptions.yAxis;

    const domainHeight = height - margin.top - margin.bottom;

    gapAnalysisChartGroupElement.append('g')
      .attr('class', 'x axis x-axis-class')
      .attr('fill', xAxis.fillColor)
      .attr('color', xAxis.color)
      .attr('stroke-width', xAxis.strokeWidth)
      .style('font-size', '13px')
      .style('text-anchor', 'start')
      .attr('transform', 'translate(-50,' + (domainHeight + 5) + ')')
      .call(d3.axisBottom(xScale).tickSize(0).tickValues(this.groupNames)); // changed this line

    gapAnalysisChartGroupElement.append('g')
      .attr('class', 'y axis')
      .attr('stroke-width', yAxis.strokeWidth)
      .attr('stroke', yAxis.stroke)
      .attr('color', yAxis.color)
      .attr('fill', yAxis.fillColor)
      .call(d3.axisLeft(yScale).tickValues([yAxisData.yMaxValue, yAxisData.yStaticValue, yAxisData.yCurrentMinValue, yAxisData.yMinValue])); // changed this line
  }

  private getBarColors() {
    const barColorsRange = this.chartOptions.barColorsRange;

    const colors = d3.scaleOrdinal()
      .domain(this.subGroupNames)
      .range(barColorsRange);
    return colors;
  }

  private renderBars(gapAnalysisChartGroupElement: any, scale: any, height: number, barColors) {

    const data = this.chartData.statementGroupsChartData;
    const subgroups = this.subGroupNames;

    const xScale = scale.x,
      yScale = scale.y,
      xSubgroupScale = scale.xSubgroup;

    const margin = this.chartOptions.margin;
    const domainHeight = height - margin.top - margin.bottom;

    gapAnalysisChartGroupElement.append('g')
      .selectAll('g')
      // Enter in data = loop group per group
      .data(data)
      .join('g')
      .attr('transform', function (d) {
        return `translate(${xScale(d.statementGroupName)},0)`;
      })
      .selectAll('rect')
      .data(function (d) {
        return subgroups.map(function (key) {
          let value = (d.restageGroupsData.find((eachValue) => eachValue.restageGroupPosition === key)).value;
          let name = (d.restageGroupsData.find((eachValue) => eachValue.restageGroupPosition === key)).barName;
          return { key: key, value: value, name: name };
        });
      })
      .join('rect')
      .attr('x', d => xSubgroupScale(d.key))
      .attr('y', d => yScale(Math.round(d.value)))
      .attr('width', xSubgroupScale.bandwidth())
      .attr('height', d => domainHeight - yScale(Math.round(d.value)))
      .attr("fill", d => barColors(d.key))
      .on('mouseover', () => {
        d3.select('body').append('div')
          .classed('chart-tooltip', true)
          .style('display', 'none')
          .style('padding', '4px 10px')
          .style('background', 'rgba(0,0,0,.8)')
          .style('color', '#fff')
          .style('-webkit-border-radius', '4px')
          .style('border-radius', '4px')
          .style('font-size', '1rem')
          .style('z-index', '999999')
          .style('margin-top', '-10px')
        d3.select('.chart-tooltip').style('display', null);
      })
      .on('mouseout', () => {
        d3.select('.chart-tooltip').style('display', 'none');
        d3.select('.chart-tooltip').remove();
      })
      .on('mousemove', (event: MouseEvent, d) => {
        const xPosition = d.key === 2 ? 45 : 58;
        d3.select('.chart-tooltip')
          .style('position', 'absolute')
          .style('left', event.pageX - xPosition + 'px')
          .style('top', event.pageY - 30 + 'px')
          .text(`${d.name}: ${Math.round(d.value)}`);
      });

    gapAnalysisChartGroupElement.selectAll('bar-text')
      .data(data[0].restageGroupsData)
      .enter()
      .append('text')
      .attr('class', function (d, index) {
        switch (index) {
          case 0:
            return `bar-text bar-text1`;
          case 1:
            return 'bar-text bar-text2';
          case 2:
            return 'bar-text bar-text3';
          case 3:
            return 'bar-text bar-text4';
        }
      })
      .attr('style', function (d, index) {
        const numberOfBars = data[0].restageGroupsData.length;
        const widthOfBar = xSubgroupScale.bandwidth();
        const xValue = ((numberOfBars === 1) ? widthOfBar/2 : widthOfBar/numberOfBars) - 25;
        const secondLabelXValue = xValue + widthOfBar;
        const thirdLabelXValue = secondLabelXValue + widthOfBar;
        const fourthLabelXvalue = thirdLabelXValue + widthOfBar;
        switch (index) {
          case 0:
            return `fill: #fff; transform-origin: left; transform: translate(${xValue}px, 250px) rotate(-90deg)`;
          case 1:
            return `fill: #fff; transform-origin: left; transform: translate(${secondLabelXValue+1}px, 250px) rotate(-90deg)`;
          case 2:
            return `fill: #fff; transform-origin: left; transform: translate(${thirdLabelXValue+2}px, 250px) rotate(-90deg)`;
          case 3:
            return `fill: #fff; transform-origin: left; transform: translate(${fourthLabelXvalue+4}px, 250px) rotate(-90deg)`;

        }
      })
      .text(function (d) { return d.barName; })
      .attr('x', 123)
      .attr('y', 295);
  }

  private renderStaticHorizontalLine(gapAnalysisChartGroupElement: any, scale: any) {
    const statementGroups = this.chartData.statementGroups,
      lineChartValue = this.chartData.lineChartValue;

    const staticLineOptions = this.chartOptions.staticLine,
      backgroundLineOptions = this.chartOptions.backgroundLine;

    const xScale = scale.x,
      yScale = scale.y;

    gapAnalysisChartGroupElement.append('line')
      .attr('class', 'background-line')
      .attr('x1', function () { return xScale(statementGroups[0]) - 5; })
      .attr('y1', function () { return yScale(lineChartValue); })
      .attr('x2', function () { return xScale(statementGroups[statementGroups.length - 1]) + xScale.bandwidth() + 5; })
      .attr('y2', function () { return yScale(lineChartValue); })
      .attr('stroke', backgroundLineOptions.stroke)
      .attr('stroke-width', backgroundLineOptions.strokeWidth);

    gapAnalysisChartGroupElement.append('line')
      .attr('class', 'static-line')
      .attr('x1', function () { return xScale(statementGroups[0]) - 5; })
      .attr('y1', function () { return yScale(lineChartValue); })
      .attr('x2', function () { return xScale(statementGroups[statementGroups.length - 1]) + xScale.bandwidth() + 5; })
      .attr('y2', function () { return yScale(lineChartValue); })
      .attr('stroke', staticLineOptions.stroke)
      .attr('stroke-width', staticLineOptions.strokeWidth)
      .attr('stroke-dasharray', '5, 3');
  }

  private renderStatementGroupLines(gapAnalysisChartGroupElement: any, scale: any) {
    const data = this.chartData.statementGroupsChartData;
    const lineChartValue = this.chartData.lineChartValue;

    const currentLineOptions = this.chartOptions.currentLine;
    const xScale = scale.x,
      yScale = scale.y;

    const subDataForLesserCurrentValues = data.filter((statement) => statement.statementValue < 100);
    gapAnalysisChartGroupElement.selectAll('.statement-group-line')
      .data(data)
      .enter()
      .append('line')
      .attr('class', 'statement-group-line')
      .attr('x1', function (d) { return xScale(d.statementGroupName) - 5; })
      .attr('y1', function (d) { return yScale(d.statementValue); })
      .attr('x2', function (d) { return xScale(d.statementGroupName) + xScale.bandwidth() + 5; })
      .attr('y2', function (d) { return yScale(d.statementValue); })
      .attr('stroke-width', currentLineOptions.strokeWidth)
      .attr('stroke', currentLineOptions.stroke);

    gapAnalysisChartGroupElement.selectAll('.rect-group-line')
      .data(subDataForLesserCurrentValues)
      .enter()
      .append('line')
      .attr('class', 'rect-group-line')
      .attr('x1', function (d) { return xScale(d.statementGroupName) - 5; })
      .attr('y1', function (d) { return yScale((lineChartValue + d.statementValue) / 2); })
      .attr('x2', function (d) { return xScale(d.statementGroupName) + xScale.bandwidth() + 5; })
      .attr('y2', function (d) { return yScale((lineChartValue + d.statementValue) / 2); })
      .attr('stroke-width', function (d) { return yScale(d.statementValue) - yScale(lineChartValue); })
      .attr('stroke', 'rgba(239,99,2,0.25)');

  }

  /**
   * This method is to wrap statement labels on x-axis
   */
  formatXAxislabelToAppendTicks() {
    d3.selectAll('.x-axis-class .tick text').call(this.wrap.bind(this));
  }

  /**
   * Creates statement labelToAppends
   *
   * @param text
   */
  public wrap(text) {
    const currentComponent = this;
    text.each(function () {
      const self = d3.select(this),
        statementlabelToAppend = self.text();
      const maxNoOfCharsPerLine = 16;
      currentComponent.appendNewLines(self, statementlabelToAppend, maxNoOfCharsPerLine);
    });
  }

  /**
   * Appends new lines based on
   * length of statement and
   * available space aligned with
   * bars
   *
   * @param textElm
   * @param statementlabelToAppend
   * @param maxNoOfCharsPerLine
   */
  public appendNewLines(textElm: any, statementlabelToAppend: string, maxNoOfCharsPerLine: number) {
      const statementWordsArr = statementlabelToAppend.split(' ');
      let lineIndex = 0,
          yLineFrequency = 27 / 3;
      textElm.text('');
      let currentText = '';
      let labelToAppend = '';

      if (statementlabelToAppend.length > maxNoOfCharsPerLine) {
          statementWordsArr.forEach((item, index) => {
              labelToAppend = currentText;
              currentText = currentText ? currentText + ' ' + item : item;
              let showYValue = null;

              if (!(currentText.length <= maxNoOfCharsPerLine)) {
                  yLineFrequency = lineIndex > 1 ? yLineFrequency + 5 : yLineFrequency;
                  showYValue = lineIndex === 0 ? 3 : lineIndex === 1 ? 27 : yLineFrequency * 3;
                  const tspan = textElm.append('tspan')
                      .attr('class', 'statement-labelToAppend-tspan')
                      .attr('x', 0)
                      .attr('y', showYValue)
                      .text(labelToAppend)
                      .on('mouseover', () => {
                          d3.select('body').append('div')
                              .classed('chart-tooltip', true)
                              .style('padding', '4px 10px')
                              .style('background', 'rgba(0,0,0,.8)')
                              .style('color', '#fff')
                              .style('-webkit-border-radius', '4px')
                              .style('border-radius', '4px')
                              .style('font-size', '1rem')
                              .style('z-index', '999999')
                              .style('margin-top', '-10px');
                          d3.select('.chart-tooltip').style('display', null);
                      })
                      .on('mouseout', () => {
                          d3.select('.chart-tooltip').style('display', 'none');
                          d3.select('.chart-tooltip').remove();
                      })
                      .on('mousemove', (event: MouseEvent, d) => {
                          d3.select('.chart-tooltip')
                              .style('position', 'absolute')
                              .style('left', event.pageX - 75 + 'px')
                              .style('top', event.pageY - 30 + 'px')
                              .text(statementlabelToAppend);
                      });
                  currentText = item;
                  labelToAppend = item;
                  lineIndex = lineIndex + 1;
                  if (lineIndex > 2) {                    // for adding ellipsis to truncated text
                      const truncatedWidth = tspan.node().getComputedTextLength(); // Get the width of the truncated text
                      const ellipsisX = truncatedWidth + 1;
                      textElm.append('tspan')
                          .attr('class', 'statement-labelToAppend-tspan')
                          .attr('x', ellipsisX)
                          .attr('y', showYValue)
                          .text('...')
                          .on('mouseover', () => {
                              d3.select('body').append('div')
                                  .classed('chart-tooltip', true)
                                  .style('padding', '4px 10px')
                                  .style('background', 'rgba(0,0,0,.8)')
                                  .style('color', '#fff')
                                  .style('-webkit-border-radius', '4px')
                                  .style('border-radius', '4px')
                                  .style('font-size', '1rem')
                                  .style('z-index', '999999')
                                  .style('margin-top', '-10px');
                              d3.select('.chart-tooltip').style('display', null);
                          })
                          .on('mouseout', () => {
                              d3.select('.chart-tooltip').style('display', 'none');
                              d3.select('.chart-tooltip').remove();
                          })
                          .on('mousemove', (event: MouseEvent, d) => {
                              d3.select('.chart-tooltip')
                                  .style('position', 'absolute')
                                  .style('left', event.pageX - 75 + 'px')
                                  .style('top', event.pageY - 30 + 'px')
                                  .text(statementlabelToAppend);
                          });
                  }
              }

              if (!statementWordsArr[index + 1]) {
                  yLineFrequency = yLineFrequency + 5;
                  showYValue = lineIndex === 0 ? 3 : lineIndex === 1 ? 27 : yLineFrequency * 3;
                  textElm.append('tspan')
                      .attr('class', 'statement-labelToAppend-tspan')
                      .attr('x', 0)
                      .attr('y', showYValue)
                      .text(currentText)
                      .on('mouseover', () => {
                      d3.select('body').append('div')
                          .classed('chart-tooltip', true)
                          .style('padding', '4px 10px')
                          .style('background', 'rgba(0,0,0,.8)')
                          .style('color', '#fff')
                          .style('-webkit-border-radius', '4px')
                          .style('border-radius', '4px')
                          .style('font-size', '1rem')
                          .style('z-index', '999999')
                          .style('margin-top', '-10px');
                      d3.select('.chart-tooltip').style('display', null);
                  })
                      .on('mouseout', () => {
                          d3.select('.chart-tooltip').style('display', 'none');
                          d3.select('.chart-tooltip').remove();
                      })
                      .on('mousemove', (event: MouseEvent, d) => {
                          d3.select('.chart-tooltip')
                              .style('position', 'absolute')
                              .style('left', event.pageX - 75 + 'px')
                              .style('top', event.pageY - 30 + 'px')
                              .text(statementlabelToAppend);
                      });
              }
          });
      } else {
          textElm.append('tspan')
              .attr('class', 'statement-labelToAppend-tspan')
              .attr('x', 0)
              .attr('y', 3)
              .text(statementlabelToAppend);
      }
  }
}
