import { Component, Input } from '@angular/core';

@Component({
  selector: 'ns-factors-concepts-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})

export class ConceptsTooltipComponent {

  /**
   * tooltip text to be displayed.
   *
   * @type {string}
   * @member ConceptsTooltipComponent
   */
  @Input() text: string;

  /**
   * factor code in concepts view
   *
   * @type {string}
   * @member ConceptsTooltipComponent
   */
  @Input() code: string;

  /**
   * factor name
   *
   * @type {string}
   * @member ConceptsTooltipComponent
   */
  @Input() factorName: string;

  /**
   * color of the threshold
   *
   * @type {string}
   * @member ConceptsTooltipComponent
   */
  @Input() color: string;

  constructor() { }

}
