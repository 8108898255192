import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DropdownFilterService } from '../dropdown-filter.service';
import { DropdownData, DropdownItem, DataType } from './dropdown.data.model';

/**
 * `DropdownComponent` creates the dropdown filter options for compare,
 * concepts, country, subgroups.
 *
 * @example
 *   <ns-dropdown></ns-dropdown>
 *
 * @export
 * @class DropdownComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'ns-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent<T> implements OnChanges {

  @Input() dropDownData: DropdownData<T>;
  @Input() disable = false;
  @Output() selectionChange = new EventEmitter<DropdownData<T>>();
  @Input() truncateLabelCount: number;

  /**
  * Individual item selection inside a group checkbox.
  * @memberof DropdownComponent
  */
  isAllCompleted: boolean;
  newLabel: string;
  tooltipLabel: string;
  isDropDownData = false;
  isListItems = false;
  isCheckboxes = false;
  isRadioButtons = false;
  defaultLabel: string;
  selectedRadioItem: DropdownItem<T>;
  tooltipText: string;
  constructor(
    private dropdownService: DropdownFilterService<T>,
    private translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
    const dropDownData = changes.dropDownData;
    let current: DropdownData<T>;
    if (dropDownData && dropDownData.currentValue && dropDownData.currentValue.data) {
      current = dropDownData.currentValue;
      this.isDropDownData = true;
      this.defaultLabel = this.translate.instant('shared.dropdown.menu.no.selection', { value: current.dropdownLabel});
      switch (current.dataType) {
        case DataType.LIST:
          this.isListItems = true;
          this.isRadioButtons = false;
          this.isCheckboxes = false;
          const selectedListItem = current.data.filter(itemDataValue => {
            return itemDataValue.selected === true;
          });
          if (selectedListItem.length === 1) {
            this.newLabel = selectedListItem[0].label;
            this.tooltipLabel = selectedListItem[0].label;
          }
          break;
        case DataType.CHECKBOX:
          this.isCheckboxes = true;
          this.isListItems = false;
          this.isRadioButtons = false;
          if (current.groupSelect) {
            this.setCheckboxGroupDefaultLabels(current);
          } else {
            this.setLabels(current);
            this.setTooltipLabels(current);
          }
          break;
        case DataType.RADIO:
          this.isRadioButtons = true;
          this.isCheckboxes = false;
          this.isListItems = false;
          const selectedRadioItem = current.data.find(itemDataValue => {
            return itemDataValue.selected === true;
          });
          if (selectedRadioItem) {
            this.selectedRadioItem = selectedRadioItem;
            this.newLabel = selectedRadioItem.label;
            this.tooltipLabel = selectedRadioItem.label;
          }
          break;
      }
      this.tooltipText = this.translate.instant('tooltip.filter', { viewComparison: this.dropDownData.dropdownLabel, viewComparisonName: this.tooltipLabel ? this.tooltipLabel : this.defaultLabel });

    }
  }

  /**
   * Sets the filter label and emits the selected item.
   *
   * @param {DropdownData} dataset
   * @param {DropdownItem} item
   */
  select(dataset: DropdownData<T>, item: DropdownItem<T>) {
    if (dataset.dataType === DataType.CHECKBOX) {
      this.setLabels(dataset);
    } else {
      dataset.data.forEach(dataItem => {
        dataItem.selected = false;
      });
      item.selected = true;
      this.newLabel = item.label;
      this.tooltipLabel = item.label;
    }
    this.selectionChange.emit(dataset);
  }

  updateAllSelectCheckboxes() {
    this.setCheckboxGroupDefaultLabels(this.dropDownData);
    this.selectionChange.emit(this.dropDownData);
  }

  /**
  * Select All of group checkbox
  * @param {boolean} isCompleted
  */
  selectAllCheckboxes(isCompleted: boolean) {
    this.isAllCompleted = isCompleted;
    if (this.dropDownData == null) {
      return;
    }
    this.dropDownData.data.forEach(i => {
      i.selected = isCompleted;
    });
    this.setLabels(this.dropDownData);
    this.selectionChange.emit(this.dropDownData);
  }

  /**
   * Setting the default labels for the checkbox group dropdown
   * @param { DropdownData } item
   * @memberof DropdownComponent
   */
  setCheckboxGroupDefaultLabels(item: DropdownData<T>) {
    this.isAllCompleted = item != null && item.data.every(t => t.selected);
    this.setLabels(item);
    this.setTooltipLabels(item);
  }

  /**
   * Setting the labels for the dropdown
   * @param { DropdownData } item
   * @memberof DropdownComponent
   */
  setLabels(item: DropdownData<T>) {
    this.newLabel = this.dropdownService.getLabels(item);
  }

  setTooltipLabels(item: DropdownData<T>) {
    this.tooltipLabel = this.dropdownService.getTooltipLabels(item);
  }
}
