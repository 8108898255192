<div *ngIf=dataViewList>
<div class="concept-data-container clearfix">

  <div class="search-input-container">
    <input matInput placeholder="Search by word(s) or phrase"  icon="nlsn:search" class="verbatims-search-bar filter-verbatims-btn"
           raised tertiary noink [(ngModel)]="searchText" (keyup.enter)="onSearchClick()" (keyup)="onSearchDataEvent($event)">
    <button class="mat-icon-button" mat-button slot="suffix" toggles icon="nlsn:search" class="verbatims-search-button filter-verbatims-btn" (click)="onSearchClick()"><span class="sif sif-search"></span></button>
  </div>

</div>

<div class="concept-data-container clearfix">
  <div class="btn-group dropdown-facet pull-left verbatims-sentiment-label margin-bottom-20">
      <div class="verbatims-sentiment-label-content" *ngIf="displayedClicks && getCountsLikesDislikesNeutral(displayedClicks); else nosentiment">
        <ng-container *ngFor="let item of getCountsLikesDislikesNeutral(displayedClicks); let i = index">
          <span class="verbatims-sentiment-label-text verbatims-sentiment-label-type-{{item.name | lowercase}}">
            <ng-container *ngIf="i > 0">, </ng-container>{{ item.count }} {{ item.name }}</span>
        </ng-container>
      </div>
      <ng-template #nosentiment>
        <span class="verbatims-sentiment-label-text verbatims-sentiment-label-type-none">--</span>
      </ng-template>
  </div>
  <div *ngIf="displayedClicks" class="btn-group dropdown-facet pull-right translation-locale-filter margin-bottom-20">
      <span class="translations-label-text">{{ "shared.deliverables.strengthsWatchouts.verbatims.filter.translations.label" | translate}}:</span>
      <ns-dropdown id="countryFilter" class="float-left verbatims-translation-dropdown"
                  [dropDownData]="translationsListData"
                  (selectionChange) = "selectTranslation($event.data)">
      </ns-dropdown>
  </div>
</div>
<div class="verbatims-search-container">
  <div class="tabbable tabs-top space-bottom">
    <div class="tab-content">
    <div class="tab-pane active">
        <ul class="list-unstyled">
          <div class="default-text" *ngIf="!displayedClicks">
            {{ "shared.deliverables.strengthsWatchouts.verbatims.empty.message.text" | translate}}
          </div>
          <ng-scrollbar class="verbatims-translations-scroll" [autoHeightDisabled]="false">
            <div style="max-height: 318px;">
              <ng-container *ngFor="let clickOption of displayedClicks">
                <li *ngIf="clickOption.verbatim" (mouseenter)="onMouseEnter(clickOption)" (mouseleave)="onMouseLeave()">
                  <span *ngIf="clickOption.translations; else elseBlock" class="insight-text" [innerHTML]="clickOption.translations[selectedTranslation] | highlight : searchText"></span>
                  <ng-template #elseBlock>
                    <span class="insight-text" [innerHTML]="clickOption.verbatim | highlight : searchText"></span>
                  </ng-template>
                </li>
              </ng-container>
            </div>
          </ng-scrollbar>
        </ul>
      </div>
    </div>
  </div>
</div>
</div>
<div *ngIf=!dataViewList>
    <ns-empty-page></ns-empty-page>
</div>

<!--<ng-template #itemsTemplate let-outletContext="outletContext">
  <div class="filter-content">
    <mat-radio-group class="dropdown-menu-sublist" [selected]="outletContext.getFirstSelectedItem().value"
                    (change)="outletContext.toggleSelection($event, true)">
      <ng-container class="list-item" *ngFor="let item of outletContext.items">
        <mat-radio-button class="list-item" [name]="item.value" [value]="item.value">{{item.label}}</mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
</ng-template>-->
