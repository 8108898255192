import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'keyTitleCase'
})
/* Custom Pipe made to transform key values into displayable titles.
 * example: 'conceptPotentialScore' becomes 'Concept Potential Score'.
 */
export class KeyTitleCasePipe implements PipeTransform {
    transform(value: string): string {
        const formattedValue = value.replace(/([A-Z])/g, ' $1').trim();
        return formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
    }

}
