/**
 * Filter name keys and values.
 *
 * @export
 * @property
*/
export const filterNames = {
  mean: 'mean',
  percents: 'percents',
  topBox: 'top-box',
  topTwoBox: 'top-two-box',
  bottomThreeBox: 'bottom-three-box',
  allFiveBoxes: 'all-five-boxes',
  noDecimals: 'no-decimals',
  oneDecimalPlace: 'one-decimal-place',
  twoDecimalPlace: 'two-decimal-place',
  statTesting: 'stat-testing',
  dataLabels: 'data-labels',
  barCharts: 'bar-charts'
};
