export enum MeasureMetric {
  PURCHASE_INTENT = 'purchaseIntent',
  DEFINITELY_WOULD_BUY = 'definitelyWouldBuy',
  PROBABLY_WOULD_BUY = 'probablyWouldBuy',
  MIGHT_OR_MIGHT_NOT_BUY = 'mightOrMightNotBuy',
  PROBABLY_WOULD_NOT_BUY = 'probablyWouldNotBuy',
  DEFINITELY_WOULD_NOT_BUY = 'definitelyWouldNotBuy',
  TRIAL_UNITS_AMONG_FAVS = 'trialUnitsAmongFavs',
  FREQUENCY_AMONG_FAVS = 'frequencyAmongFavs',
  CONCEPT_POTENTIAL_SCORE = 'conceptPotentialScore'
}
