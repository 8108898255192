<ns-dropdown-menu [label]="filterLabel" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'tooltip.filter' | translate: { viewComparison: 'Index', viewComparisonName: filterLabel } }}">
    <section class="dropdown-filter-menu">
        <ul class="dropdown-menu-list">
            <h3 class="menu-title">{{ 'restage.deliverable.gap.analysis.filter.show.index.label' | translate }}</h3>
            <mat-radio-group [ngModel]="getSelectedIndex()" (change)="changeIndex($event)">
                <li class="list-item">
                    <mat-radio-button color="primary" class="list-item" [value]="filterNames.meanIndex">
                        {{ 'restage.deliverable.gap.analysis.filter.show.mean.label' | translate }}
                    </mat-radio-button>
                </li>
                <li class="list-item">
                    <mat-radio-button color="primary" class="list-item" [value]="filterNames.topBoxIndex">
                        {{ 'restage.deliverable.gap.analysis.filter.show.top.box.label' | translate }}
                    </mat-radio-button>
                </li>
                <li class="list-item">
                    <mat-radio-button color="primary" class="list-item" [value]="filterNames.topTwoBoxIndex">
                        {{ 'restage.deliverable.gap.analysis.filter.show.top.two.box.label' | translate }}
                    </mat-radio-button>
                </li>
            </mat-radio-group>
        </ul>
      </section>
</ns-dropdown-menu>
