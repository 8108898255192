import {EventEmitter, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {State} from '@platform/store/state/app.state';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from '@app/core/config/app-config.service';
import {Observable} from 'rxjs';
import {concatMap, take} from 'rxjs/operators';
import {selectRouter} from '@platform/store/selectors/router.selectors';
import {Benchmark} from '@platform/models/benchmark.model';
import {addBenchmarks, deleteBenchmark, updateBenchmark} from '@platform/store/benchmark/benchmark.actions';
import {selectReportBenchmarks} from '@platform/store/benchmark/benchmark.selector';
import {FilterModel} from '@platform/import-benchmark-results/models/filter.model';
import {deleteConcept} from '@platform/store/actions/concept.actions';
import {selectReportConcepts} from '@platform/store/selectors/concept.selectors';
@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {

  benchmarkEvents = new EventEmitter<any>();
  importBenchmarkActiveView: string;

  /**
   * Creates an instance of BenchmarkService.
   */
  constructor(
    private store: Store<State>,
    private httpClient: HttpClient,
    private cs: AppConfigService) {
  }

  /**
   * Returns the benchmarks for given report as observable.
   */
  get(): Observable<Benchmark[]> {
    const routerState$ = this.store.pipe(select(selectRouter));
    return routerState$.pipe(
      concatMap(result => {
        const reportId = result.params.reportId;
        return this.fetchAllFromAPI(reportId);
      }),
      take(1)
    );
  }

  /**
   * Fetch benchmark objects from the API.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Report>} The report observable
   * @memberof ReportService
   */
  fetchAllFromAPI(reportId: string): Observable<Benchmark[]> {
    const url = `${this.cs.config.reporting.url}/reports/${reportId}/benchmarks`;
    return this.httpClient.get<Benchmark[]>(url).pipe(concatMap(benchmarks => {
      this.store.dispatch(addBenchmarks({benchmarks}));
      return this.store.select(selectReportBenchmarks(reportId));
    }));
  }

  findReports(selectedFilters: FilterModel, reportId: string){
    const url = `${this.cs.config.reporting.url}/reports/search`;
    return this.httpClient.post(`${url}`, selectedFilters);
  }

  save(benchmarkList, reportId:string): Observable<Benchmark[]>{
    const url = `${this.cs.config.reporting.url}/reports/${reportId}/benchmarks`;
    return this.httpClient.post<Benchmark[]>(`${url}`, {benchmarks: benchmarkList}).pipe(concatMap(benchmarks => {
      this.store.dispatch(addBenchmarks({benchmarks}));
      return this.store.select(selectReportBenchmarks(reportId));
    }));
  }

  update(reportId: string, benchmarkId: string, benchmark: Benchmark): Observable<any> {
    const url = `${this.cs.config.reporting.url}/reports/${reportId}/benchmarks/${benchmarkId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.httpClient.put<Benchmark>(`${url}`, benchmark, {headers}).pipe(concatMap(benchmark => {
      this.store.dispatch(updateBenchmark({benchmark}));
      return this.store.select(selectReportBenchmarks(reportId));
    }));
  }

  delete(reportId: string, benchmarkId: string, conceptId: string): Observable<any>{
    const url = `${this.cs.config.reporting.url}/reports/${reportId}/benchmarks/${benchmarkId}`;
    return this.httpClient.delete(url).pipe(concatMap(benchmark => {
      this.store.dispatch(deleteBenchmark({reportId,benchmarkId}));
      this.store.dispatch(deleteConcept({id: conceptId}));      
      return [this.store.select(selectReportBenchmarks(reportId)), this.store.select(selectReportConcepts(reportId))];
    }));
  }
}
