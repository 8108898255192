import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';
import {DeliverablesListItem} from '@products/shared/deliverables-list/deliverables-list-item.data.model';

@Component({
    selector: 'ns-forecast-estimate',
    templateUrl: './forecast-estimate.component.html',
    styleUrls: ['./forecast-estimate.component.scss']
})
export class ForecastEstimateComponent implements OnInit {

    @Input() deliverablesConfig: DeliverableConfiguration[];
    @Input() reportId: string;
    @Input() dataList: DeliverablesListItem[];
    @Output() closeFlyoutMenuEvent = new EventEmitter();
    @Output() moveToPreviousMenuEvent = new EventEmitter();

    constructor(private deliverableConfigurationService: DeliverableConfigurationService,) {
    }

    ngOnInit(): void {
        let deliverableConfigurationsInReport: DeliverableConfiguration[];
        if (!(this.deliverablesConfig && this.deliverablesConfig.length > 0)) {
            this.deliverablesConfig = [];
        }
        deliverableConfigurationsInReport = JSON.parse(JSON.stringify(this.deliverablesConfig));
        if (!deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_ESTIMATE)) {
            deliverableConfigurationsInReport.push({
                id: "",
                reportId: this.reportId,
                name: this.deliverableConfigurationService.FORECAST_ESTIMATE,
                config: {estimates: []}
            });
        }
        this.deliverableConfigurationService.updateConfigForForecastEstimate(deliverableConfigurationsInReport, this.reportId);
    }

    isDeliverableFromForecasting() {
        return this.deliverableConfigurationService.isDeliverableFromForecasting?.toString();
    }

    onCloseButtonClick(): void {
        this.closeFlyoutMenuEvent.emit();
    }

    returnBack(): void {
        this.moveToPreviousMenuEvent.emit();
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.closeFlyoutMenuEvent.emit();
        }
    }
}
