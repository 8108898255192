<div class="filter-wrapper dropdown-component">
  <ns-dropdown-menu [isDisable]="disable" [label]="disabled ? onloadDefaultLabel : newLabel ? newLabel : defaultLabel" [contentTitle]="dropDownData.contentTitle">
  <div class="dropdown-filter-menu">
    <ul class="dropdown-menu-list">
      <div *ngIf="isCheckboxes && dropDownData.groupSelect" #checkboxgroup>
        <li *ngIf="dropDownData.groupSelect" class="group-list-item">
          <mat-checkbox [checked]="isAllCompleted"
                        (change)="selectAllCheckboxes($event.checked)"
                        (click)="$event.stopPropagation()"
                        color="primary">
                          {{dropDownData.groupSelect}}
          </mat-checkbox>
        </li>
        <ul [ngClass]="'dropdown-menu-sublist'">
          <li class="list-item" *ngFor="let item of dropDownData.data" (click)="$event.stopPropagation()">
            <div [ngClass]="{'lis-selected' : item.selected, 'lis-unselected': item.isLocked }" class="lis-item-checkbox-wrapper">
              <mat-checkbox [disabled]="item.isLocked" color="primary" [(ngModel)]="item.selected" (ngModelChange)="updateAllSelectCheckboxes()" [matTooltip]="item.label" >{{item.label}}</mat-checkbox>
            </div>
            <mat-slide-toggle [(ngModel)]="item.isLocked" (ngModelChange)="updateLockedVarieties()"></mat-slide-toggle>
          </li>
        </ul>
      </div>
    </ul>
  </div>
  </ns-dropdown-menu>
</div>
