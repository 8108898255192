import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { BarChartDataItem } from './bar-chart.model';
import { BarChart, BarChartOptions, BarChartData } from './bar-chart.model';

import * as d3 from 'd3';
import * as numeral from 'numeral';
import {MathService} from '@src/assets/utils/math.service';

/**
 * `ChartHorizontalBarComponent` creates `d3` bar chart with the
 * `BarChart` object.
 *
 * @example
 *   <ns-attributes-chart-horizontal-bar
 *     [barChart]="barChart" >
 *   </ns-attributes-chart-horizontal-bar>
 *
 * @param {BarChart} barchart - The barchart object for the d3 chart.
 *
 * @export
 * @class ChartHorizontalBarComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ns-chart-horizontal-bar',
  templateUrl: './chart-horizontal-bar.component.html'
})
export class ChartHorizontalBarComponent implements OnChanges {

  /**
   * `BarChart` object for constructing the d3 bar chart.
   *
   * @type {BarChart}
   * @memberof ChartHorizontalBarComponent
   */
  @Input() barChart: BarChart;
  /**
   * `barChartContainer` for adding the d3 chart.
   *
   * @type {ElementRef}
   * @memberof ChartHorizontalBarComponent
   */
  @ViewChild('barChartContainer', {static: true}) barChartContainer: ElementRef;

  /**
   * Creates an instance of ChartHorizontalBarComponent.
   *
   * @constructor
   * @memberof ChartHorizontalBarComponent
   */
  constructor(private utilsService: MathService) {}


  /**
   * Observe barChart input changes.
   *
   * @memberof ChartHorizontalBarComponent
   * @param {SimpleChanges} changes all changes detected by angular
   */
  ngOnChanges(changes: SimpleChanges): void {
    const container: HTMLElement = this.barChartContainer.nativeElement;
    const barChart = this.barChart;
    const change = changes['barChart'];
    const current = change ? JSON.stringify(change.currentValue) : null;
    const previous = change ? JSON.stringify(change.previousValue) : null;
    if (change && previous !== current) {
      // Clean any existing children.
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
      if (barChart) {
        this.buildChart(container, barChart);
      }
    }
  }

  /**
   * Builds the bar chart inside the selector node and with the bar
   * chart options.
   *
   * @param {*} selector
   * @param {BarChart} barChart
   * @memberof ChartHorizontalBarComponent
   */
  public buildChart(selector: any, barChart: BarChart): void {
    const options: BarChartOptions = barChart.options;
    const range: any = options.bar.range;
    const domain: any = options.bar.domain;
    const scale: Function = this.getLinerScale([range.min, range.max], [domain.min, domain.max]);
    const svg = this.getSVGContainer(selector, options);
    const container = this.getContainer(svg, barChart);
    svg.attr('class', 'stacked-bar-chart');
    this.addStackedBars(container, scale, barChart);
    this.addDataLabelLines(container, scale, barChart);
    this.addDataLabels(container, scale, barChart);
    this.addAggregateLabel(container, scale, options);
  }

  /**
   * Returns a linear D3 scale function for the range and domain.
   *
   * @param {[number, number]} range - The tuple of minRange and maxRange
   * @param {[number, number]} domain - The tuple of minDomain and maxDomain
   * @returns {Function} - The d3 scale function
   * @memberof D3Service
   */
  public getLinerScale(range: [number, number], domain: [number, number]): Function {
    const scale = d3.scaleLinear()
                    .rangeRound(range)
                    .domain(domain);
    return scale;
  }

  /**
   * Returns the SVG container for the selector.
   *
   * @param {*} selector
   * @param {number} [width=0]
   * @param {number} [height=0]
   * @param {*} [margin={}]
   * @returns
   * @memberof ChartHorizontalBarComponent
   */
  public getSVGContainer(selector: any, options: BarChartOptions) {
    let svg, margin, marginLeft, marginRight,
        marginTop, marginBottom, dataLabel;
    margin = options.margin;
    marginLeft = margin && margin.left ? margin.left : 0;
    marginRight = margin && margin.right ? margin.right : 0;
    marginTop = margin && margin.top ? margin.top : 0;
    marginBottom = margin && margin.bottom ? margin.bottom : 0;
    dataLabel = options.bar.dataLabel ? options.bar.height : 0;
    svg = d3.select(selector)
      .append('svg')
      .attr('width', options.width + marginLeft + marginRight)
      .attr('height', options.height + marginTop + marginBottom + dataLabel);
    return svg;
  }

  /**
   * Returns the d3 svg container object for single bar node.
   *
   * @private
   * @param {*} svg
   * @param {BarChart} barChart
   * @returns
   * @memberof ChartHorizontalBarComponent
   */
  private getContainer(svg: any, barChart: BarChart) {
    const graphics = svg.selectAll('.stacked-bars')
      .data(barChart.series)
      .enter()
        .append('g')
        .attr('class', 'chart-graphics');
    return graphics;
  }

  /**
   * Adds aggregate label if the option is set to true.
   *
   * @private
   * @param {*} container
   * @param {Function} scale
   * @param {BarChartOptions} options
   * @memberof ChartHorizontalBarComponent
   */
  private addAggregateLabel(container: any, scale: Function, options: BarChartOptions): void {
    const styles: any = options.aggregateLabel.style;
    const label = container.append('text')
      .attr('class', 'chart-aggregate-label')
      .attr('x', d => scale(this.dataPointsSum(d)) + options.aggregateLabel.margin.left)
      .attr('y', options.aggregateLabel.margin.top)
      .attr('fill', options.aggregateLabel.fill)
      .text(d => {
        const sum = this.dataPointsSum(d);
        if (sum == null) { return; }
        const roundedValue = this.utilsService.round(sum , options.bar.formatPattern);
        const formattedValue = numeral(roundedValue).format(options.bar.formatPattern);
        return formattedValue;
      });
    for (const key in styles) {
      if (styles.hasOwnProperty(key)) {
        label.style(key, styles[key]);
      }
    }
  }

  /**
   * Adds stacked bar chart graphics node.
   *
   * @private
   * @param {*} container
   * @param {Function} scale
   * @param {BarChart} barChart
   * @memberof ChartHorizontalBarComponent
   */
  private addStackedBars(container: any, scale: Function, barChart: BarChart): void {
    const options: BarChartOptions = barChart.options;
    const bars = container.selectAll('rect')
      .data(d => Object.entries(d))
      .enter()
      .append('rect')
      .attr('id', ([key, value]) => value.id)
      .attr('class', 'chart-bar')
      .attr('fill', ([key, value]) => barChart.colors[key])
      .attr('x', options.bar.margin.right)
      .attr('y', options.bar.margin.top)
      .attr('height', options.bar.height)
      .attr('tooltip-content', ([key, value]) => value.tooltip)
      .attr('width', 0);
      bars.transition()
      .duration(500)
      .attr('width', ([key, value]) => scale(value.value || 0))
      .attr('x', function ([key, value], index) {
        const current: any = this;
        const chartData: any = d3.select(current.parentNode).datum();
        const seriesValues: Array<number> = Object.values(chartData).map(dataItem => dataItem['value']);
        let x = options.margin.left;
        let seriesValue;
        for (let i = 1; i <= index; i++) {
          seriesValue = seriesValues[i - 1] || 0;
          x += scale(seriesValue) + (seriesValue > 0 ? options.bar.margin.right : 0);
        }
        return x;
      });
    if (options.bar.tooltip) {
      bars
        .on('mouseover', (event: MouseEvent, [key, value]) => {
          const element = document.getElementById(value.id);
          const position = element.getBoundingClientRect();
          const elementWidth = scale(value.value || 0);
          const left = position.left;
          const center = elementWidth / 2;
          const topVal = position.top;
          const leftVal = left + center - 80;
          d3.select('body').append('div')
            .classed('chart-tooltip', true)
            .style('display', 'none')
            .style('padding', '4px 10px')
            .style('background', 'rgba(0,0,0,.8)')
            .style('color', '#fff')
            .style('-webkit-border-radius', '4px')
            .style('border-radius', '4px')
            .style('font-size', '0.9rem')
            .style('z-index', '999999')
            .style('margin-top', '-10px')
            .style('width', 160 + 'px')
            .style('position', 'absolute')
            .style('left', leftVal + 'px')
            .style('top', (window.scrollY + topVal) - 18 + 'px')
            .text(value.tooltip);
          d3.select('.chart-tooltip').style('display', null);
        })
        .on('mouseout', () => {
          d3.select('.chart-tooltip').style('display', 'none');
          d3.select('.chart-tooltip').remove();
        })
        .attr('tooltip-inverse', '')
        .attr('tooltip-pointed', '')
        .attr('rel', 'tooltip');
    }
  }

  /**
   * Adds data label lines to the graphics node if the options flag is
   * set to true.
   *
   * @private
   * @param {*} container
   * @param {Function} scale
   * @param {BarChart} barChart
   * @returns {void}
   * @memberof ChartHorizontalBarComponent
   */
  private addDataLabelLines(container: any, scale: Function, barChart: BarChart): void {
    const options: BarChartOptions = barChart.options;
    const labelY1: number = options.bar.height + options.bar.margin.top;
    const labelY2: number = labelY1 + 5;
    const t = d3.transition().duration(500);
    const startFn = function ([key, value], index) {
      const current: any = this;
      const chartData: any = d3.select(current.parentNode).datum();
      const seriesValues: Array<number> = Object.values(chartData).map(dataItem => dataItem['value']);
      let x = options.margin.left;
      let seriesValue;
      for (let i = 1; i <= index; i++) {
        seriesValue = seriesValues[i - 1] || 0;
        x += scale(seriesValue) + (seriesValue > 0 ? options.bar.margin.right : 0);
      }
      x = x + (scale(value.value || 0) / 2);
      return x;
    };
    if (!options.bar.dataLabelLine) {
      return;
    }
    container.selectAll('line')
      .data(d => Object.entries(d))
      .enter()
      .append('line')
        .attr('x1', startFn)
        .attr('x2', startFn)
        .attr('y1', labelY1)
        .attr('y2', labelY1)
        .attr('class', 'chart-bar-data-label-line')
        .attr('stroke', ([key, value]) => barChart.colors[key])
        .transition(t)
        .attr('x2', function ([key, value], index) {
          if (isNaN(value?.value)) { return; }
          return (index + 1) * 40 - 10;
        })
        .attr('y2', labelY2);
  }

  /**
   * Adds data label on the graphics node if the options flag is set to true.
   *
   * @private
   * @param {*} container
   * @param {Function} scale
   * @param {BarChart} barChart
   * @returns {void}
   * @memberof ChartHorizontalBarComponent
   */
  private addDataLabels(container: any, scale: Function, barChart: BarChart): void {
    const options: BarChartOptions = barChart.options;
    const labelY: number = options.bar.height + options.bar.margin.top + 14;
    if (!options.bar.dataLabel) {
      return;
    }
    container.selectAll('text')
      .data(d => Object.entries(d))
      .enter()
      .append('text')
        .attr('x', function ([key, value], index) {
          return (index + 1) * 40;
        })
        .attr('y', labelY)
        .text(([key, value]) => {
            if (isNaN(value?.value)) { return; }
            const roundedValue = this.utilsService.round(value.value, options.bar.formatPattern);
            const formattedValue = numeral(roundedValue).format(options.bar.formatPattern);
            return formattedValue;
        })
        .attr('class', 'chart-bar-data-label')
        .attr('stroke', ([key, value]) => barChart.colors[key]);
  }

  /**
   * Returns the sum of the data points.
   *
   * @private
   * @param {BarChartData} chartData
   * @returns {number}
   * @memberof ChartHorizontalBarComponent
   */
  private dataPointsSum(chartData: BarChartData): number {
    const values = Object.values(chartData);
    if (isNaN(values[0]?.value)) { return; }
    const sum = values.reduce((t: number, n: BarChartDataItem) => t + (n.value || 0), 0);
    return sum;
  }

}
