import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';

// Component imports
import { DeliverablesComponent } from '@platform/deliverables/deliverables.component';
import { DeliverableCardComponent } from '@platform/deliverables/deliverable-card/deliverable-card.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import { DeliverablesRoutingModule } from './deliverables-routing.module';

/**
 * All deliverables module components.
 *
 * @property components
 * @private
 */
const components: Array<any> = [
  DeliverablesComponent,
  DeliverableCardComponent,
];

/**
 * Module exports.
 *
 * @property moduleExports
 * @private
 */
const moduleExports: Array<any> = [];
moduleExports.push(...components);

@NgModule({
    imports: [
      DeliverablesRoutingModule,
        CommonModule,
        RouterModule,
        MatCardModule,
        TranslateModule,
        DragDropModule,
        MatTooltipModule,
        MatButtonModule,
        MatSlideToggleModule
    ],
  declarations: components,
  exports: moduleExports
})
export class DeliverablesModule { }
