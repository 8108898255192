<div class="deliverables-containers word-cloud-content">
    <ng-container *ngIf="!scoreCardConcept">
      <div class="sticky-header-container header-filter-container" [ngClass]="{ 'hide': isInsightEnable }">
          <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
            <ns-kebab-menu class="kabab-menu">
              <ul class="kebab-menu-list">
                <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
              </ul>
            </ns-kebab-menu>
              <span *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
              <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
          </ns-deliverable-nav-header>
      </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()">
    </ns-deliverable-insight>
    </ng-container>
    <div id="deliverable-container" class="deliverable-container">
        <div class="filters-sticky-container" [ngClass]="{ 'hide': isInsightEnable }">
          <!--Price Elasticity filter-->
          <ns-price-elasticity-filter [concept]="scoreCardConcept"></ns-price-elasticity-filter>
        </div>
        <div class="insight-html-root" id="image-view">
          <div class="image-container" id="optimal-price-1">
            <img crossorigin="use-credentials" class="insight-image" [src]="imagePath?.name" />
          </div>
        </div>
    </div>
</div>
