import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {Subscription} from 'rxjs';
import {
    MatLegacyPaginator as MatPaginator,
    MatLegacyPaginatorIntl as MatPaginatorIntl
} from '@angular/material/legacy-paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {ProductServiceFactory} from '@platform/services/product-factory.service';

@Component({
    selector: 'ns-deliverable-nav-header',
    templateUrl: './deliverable-nav-header.component.html',
    styleUrls: ['./deliverable-nav-header.component.scss']
})

export class DeliverableNavHeaderComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    /**
     * Event that is emitted when user changes deliverable from either drop down or pagination.
     * */
    @Output() deliverableChanged = new EventEmitter<DeliverableInfo>();

    /**
     * List of deliverables the user can navigate to.
     * */
    @Input() deliverableInfos: Array<DeliverableInfo>;

    @ViewChild('MatPaginator', {static: false}) paginator: MatPaginator;

    public deliverableLabel: string;

    public currentDeliverableInfo: DeliverableInfo;

    public viewableDeliverableInfos: Array<DeliverableInfo>;

    /**
     * Current deliverable name generated from route url.
     * */
    public deliverableName: string;

    public length: number;
    public currentPage: number;
    public nextLabel = '';
    public prevLabel = '';
    private subscription: Subscription;

    deliverableFromForecastingMap = [
        {
            studioName: 'results',
            forecastingName: 'volume-estimate'
        },
        {
            studioName: 'variety-split',
            forecastingName: 'variety-split-deliverable'
        },
        {
            studioName: 'marketing-summary',
            forecastingName: 'marketing-plan-summary'
        }
    ]

    constructor(private router: Router,
                private route: ActivatedRoute,
                private mixpanelService: MixpanelService,
                private productServiceFactory: ProductServiceFactory,
                private deliverableInfoService: DeliverableInfoService) {
        this.subscription = new Subscription();
        this.viewableDeliverableInfos = [];
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges(): void {
        this.initialize();
    }

    ngAfterViewInit(): void {
        this.setMatPaginator();
    }

    /**
     * Observe route changes so that active deliverable route can be set correctly based on current route path.
     * */
    initialize(): void {
        const deliverableInfos = this.deliverableInfos && this.deliverableInfos.length ? this.deliverableInfos : [];
        this.viewableDeliverableInfos = deliverableInfos.filter(it => it && (undefined === it.showDeliverable || null === it.showDeliverable || it.showDeliverable));
        this.subscription.add(this.route.url.subscribe((urlSegment) => {
            this.onUrlChange(this.router.url);
        }));
    }

    /**
     * It sets the active deliverable in the deliverable dropdown based on application path.
     *
     * @param {string} url - current application url path
     * */
    onUrlChange(url: string): void {
        let deliverableName = url.substr(this.router.url.lastIndexOf('/') + 1);
        const deliverableFromForecastingMapItem = this.deliverableFromForecastingMap.find(it => it.forecastingName === deliverableName);
        if (deliverableFromForecastingMapItem) {
            deliverableName = deliverableFromForecastingMapItem.studioName;
        }
        this.deliverableName = deliverableName.indexOf('?') !== -1 ? deliverableName.substr(0, deliverableName.indexOf('?')) : deliverableName;
        this.setupDeliverableInfoPaging();
    }

    /**
     * Setup navigation/paging for deliverable information
     * */
    setupDeliverableInfoPaging(): void {
        if (this.deliverableInfos?.length) {
            this.length = this.deliverableInfos.length;
            this.deliverableInfos.forEach((deliverableInfo, index) => {
                if (deliverableInfo.path === this.deliverableName) {
                    this.currentDeliverableInfo = deliverableInfo;
                    this.deliverableLabel = this.mixpanelService.getMixpanelLabel(this.currentDeliverableInfo?.type);
                    this.currentPage = index;
                    if (this.currentPage + 1 < this.length) {
                        this.nextLabel = this.deliverableInfos[this.currentPage + 1].label;
                    }
                    if (this.currentPage > 0) {
                        this.prevLabel = this.deliverableInfos[this.currentPage - 1].label;
                    }
                }
            });
            this.setMatPaginator();
        }
    }


    setMatPaginator() {
        const paginationIntl = new MatPaginatorIntl();
        paginationIntl.nextPageLabel = this.nextLabel;
        paginationIntl.previousPageLabel = this.prevLabel;
        this.paginator._intl = paginationIntl;
    }

    /**
     * Navigate back to deliverables landing page
     */
    public toHome() {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.navigateCard);
        const currentURL = this.router.url;
        const finalURL = currentURL.substr(0, currentURL.lastIndexOf('/'));
        this.router.navigate([finalURL]);
    }

    /**
     * Navigate to next and previous deliverables
     * @param evt
     */
    public navigate(evt: { pageIndex: number; }) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.navigateArrows);
        this._navigateToDeliverable(this.deliverableInfos[evt.pageIndex]);
    }

    /**
     * Navigate to deliverables via dropdown
     */
    navigateToDeliverable(deliverableInfo: DeliverableInfo): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.navigateDropdown);
        this._navigateToDeliverable(deliverableInfo);
    }

    _navigateToDeliverable(deliverableInfo: DeliverableInfo): void {
        if (deliverableInfo) {
            this.deliverableChanged.emit(deliverableInfo);
        }
    }

    /**
     * Get full path to redirect to deliverable page.
     * @param deliverablePath { string }
     */
    getDeliverableRedirectPath(deliverablePath: string) {
        return this.deliverableInfoService.getDeliverableRedirectPath(deliverablePath);
    }

    /**
     * Clean up the component.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
