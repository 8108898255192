import { createAction, props } from '@ngrx/store';
import { Filter } from '@platform/models/filter.model';

/**
 * Action for adding a filter.
 *
 * @export
 */
export const addFilter = createAction(
  '[Filter] Add Filter',
  props<{ filter: Filter }>()
);

/**
 * Action for updating a filter.
 *
 * @export
 */
export const updateFilter = createAction(
  '[Filter] Update Filter',
  props<{ filter: Filter }>()
);
