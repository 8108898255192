import { Injectable } from '@angular/core';
import { filter, from, Observable, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from "@app/core/config/app-config.service";
import { AppAsyncTask } from "@platform/models/app-async-task.model";
import { concatMap, map, take } from "rxjs/operators";

/**
 * This service provides operations for interacting with AppAsyncTask.
 *
 * @example
 * constructor(private appAsyncTaskService: AppAsyncTaskService) { }
 *
 * @export
 * @class AppAsyncTaskService
 */
@Injectable({
  providedIn: 'root'
})
export class AppAsyncTaskService {

  readonly baseURI: string;
  readonly STATUS_COMPLETED = 'COMPLETED';
  readonly STATUS_FAILED = 'FAILED';
  readonly EXTENSION_TO_MIME = {
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  };

  constructor(private http: HttpClient, private cs: AppConfigService,) {
    this.baseURI = `${this.cs.config.reporting.url}`;
  }

  /**
   * Returns observable of AppAsyncTask for given report and task id
   *
   * @returns {Observable<AppAsyncTask>}
   * @memberOf AppAsyncTaskService
   */
  getById(id: string, reportId: string): Observable<AppAsyncTask> {
    const url = `${this.baseURI}/reports/${reportId}/appAsyncTasks/${id}`;
    return this.http.get<AppAsyncTask>(url);
  }

  /**
   * Fetches claim check file content for given task
   *
   * @returns {Observable<any>}
   * @memberOf AppAsyncTaskService
   */
  download(asyncTask: AppAsyncTask): Observable<any> {
    const headers = new HttpHeaders({
      Accept: 'application/octet-stream'
    });
    const url = `${this.baseURI}/reports/${asyncTask.metaInfo.reportId}/appAsyncTasks/${asyncTask.id}`;
    return this.http.get(`${url}`, {
        responseType: 'arraybuffer',
        headers: headers
      }
    );
  }

  /**
   * Polls server to get the latest update on the status of async task until the task has completed or failed.
   *
   * @returns {Observable<AppAsyncTask>}
   * @memberOf AppAsyncTaskService
   */
  pollUntilTaskFinished(id: string, reportId: string, interval: number = 2500): Observable<AppAsyncTask> {
    return timer(0, interval)
      .pipe(concatMap(() => from(this.getById(id, reportId)).pipe(map(response => response))))
      .pipe(filter(backendData => backendData.status === this.STATUS_COMPLETED || backendData.status === this.STATUS_FAILED))
      .pipe(take(1))
  }

  /**
   * Fetches claim check file content and converts it to downloadable object
   *
   * @returns {void}
   * @memberOf AppAsyncTaskService
   */
  downloadFile(response: BlobPart, fileName: string, mime: string = null): void {
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    const type = fileExtension && this.EXTENSION_TO_MIME[fileExtension] ? this.EXTENSION_TO_MIME[fileExtension] : (mime ? mime : null);
    const blob = new Blob([response], {type});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}`;
    link.click();
    link.remove();
  }

}
