import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Report} from '@platform/models/report.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {Concept} from '@platform/models/concept.model';
import {Organization} from '@platform/models/organization.model';
import {Benchmark} from '@platform/models/benchmark.model';
import {FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {FinancialPotentialDeliverableView} from '@app/deliverables/financial-potential/models/financial-potential.model';
import {Subgroup} from '@platform/models/subgroup.model';

/**
 * Project/report data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface ReportNode {
  name: string;
  report?: Report;
  concept?: Concept;
  children?: ReportNode[];
  benchmark?: Benchmark;

}

/** Flat node with expandable and level information */
interface ReportFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  report: Report;
}

@Component({
  selector: 'ns-benchmark-concepts',
  templateUrl: './benchmark-concepts.component.html',
  styleUrls: ['./benchmark-concepts.component.scss']
})
export class BenchmarkConceptsComponent implements OnInit, OnChanges {
  @Input() report: Report;
  @Input() concepts: Concept[];
  @Input() organization: Organization;
  @Input() benchmark: Benchmark[];
  @Input() factorsDeliverableView: FactorsDeliverableView;
  @Input() financialPotentialDeliverableView: FinancialPotentialDeliverableView;
  @Input() subgroup: Subgroup;

    public reportCreatedDate: String;


    private _transformer = (node: ReportNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      report: node.report,
      benchmark: node.benchmark,
      concept: node.concept
    };
  };

  treeControl = new FlatTreeControl<ReportFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    if (!!this.report && (!!this.concepts && this.concepts.length)) {
      const reportConcepts: ReportNode[] = this.concepts.map((concept) => {
        return {
          name: concept.name,
          concept: concept,
          report: this.report,
          benchmark: this.benchmark ? this.benchmark.find((benchmark) => benchmark.benchmarkConceptId === concept.id) : undefined
        };
      });
      const treeData: ReportNode[] = [
        {
          name: this.report.productName,
          report: this.report,
          children: reportConcepts
        }
      ];
      if (this.report.dateCreated) {
          const reportCreatedDate = new Date(this.report.dateCreated);
          this.reportCreatedDate = reportCreatedDate.toLocaleString('default', { month: 'long' }) + ' ' + reportCreatedDate.getFullYear();
      }
      this.dataSource.data = treeData;
      this.treeControl.expandAll();
    }
  }

  hasChild = (_: number, node: ReportFlatNode) => node.expandable;
}
