<div class="filter-wrapper">
    <button *ngIf="contentTitle!=='sif-options';else charticon" [disabled]="isDisable" [class.disable]="isDisable"
        class="button mat-button mat-button-wrapper" [matMenuTriggerFor]="menu">
        <span class="label">{{label ? label : noLabel}}</span>
    </button>
    <ng-template #charticon>
        <button [disabled]="isDisable" [class.disable]="isDisable" class="button sif-button" [matMenuTriggerFor]="menu">
            <span class="icon sif sif-gray sif-options"></span>
        </button>
    </ng-template>
    <mat-menu #menu="matMenu">
        <section class="filter-menu">
            <h3 *ngIf="contentTitle!=='sif-options' && contentTitle" class="menu-title">{{contentTitle}}</h3>
            <div *ngIf="subLabel" class="subLabel">{{subLabel}}</div>
            <ng-content></ng-content>
        </section>
    </mat-menu>
</div>