import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DeliverablesListItem } from './deliverables-list-item.data.model';

@Component({
  selector: 'ns-deliverables-list',
  templateUrl: './deliverables-list.component.html',
  styleUrls: ['./deliverables-list.component.scss']
})
export class DeliverablesListComponent implements OnInit, OnChanges {

  @Input() dataList: DeliverablesListItem[] = [];
  @Input() editable: boolean = false;
  @Input() enableDragAndDrop: boolean = false;
  @Input() showVisibilityToggleIcon: boolean = false;
  @Input() showVisibilityToggleSlider: boolean = false;
  @Input() displayChildPageNavigation: boolean = false;
  @Input() expandedRowsStoreCollection: any;
  @Input() hasExpandableData: boolean = false;
  @Output() navigateTo = new EventEmitter<DeliverablesListItem>();
  @Input() currencyType: string;

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  hideDragAndDroplist: string[] = ['Currency', 'Distribution Values', 'Activity Level']

  formGroup = [];
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataList) {
      this.formGroup = this.dataList?.map(e => {
        return {
          id: e.id,
          formControl: new FormControl({ value: e.displayName, disabled: !e.isVisible }, [Validators.required])
        }
      });
    }
  }

  ngOnInit(): void {
  }

  toggleVisibility(item: DeliverablesListItem) {
    item.isVisible = !item.isVisible;
    if (!item.isVisible) {
      this.getFormControl(item).disable();
    } else {
      this.getFormControl(item).enable();
    }
  }

  toggleNode(item: DeliverablesListItem) {
    if (item.isVisible)
      this.expandedRowsStoreCollection[item.id] = !this.isExpanded(item);
  }

  drop(event: CdkDragDrop<DeliverablesListItem[]>) {
    let dragNdropflag = this.dataList?.some(ele => this.hideDragAndDroplist.includes(ele.displayName));
    if (dragNdropflag) {
      moveItemInArray(this.dataList, event.previousIndex + 1, event.currentIndex + 1);
    } else {
      moveItemInArray(this.dataList, event.previousIndex, event.currentIndex);
    }
    this.dataList.forEach((item, index) => {
      item.position = index;
    });
  }

  changeMetricType(event, item) {
    if (item.id === 'distributionEndOfYearValue.distributionValue') {
      item.data.forEach(node => {
        node.checked = node.displayName === event.value;
      });
    }
  }

  anyOneItemHasChild() {
    return this.dataList.some(item => item.children && item.children.length > 0);
  }

  anyChildHasGrandChild(item: DeliverablesListItem) {
    if (this.hasChildren(item))
      return item.children?.some(ele => this.hasChildren(ele));
    return false;
  }

  emitNavigation(item: DeliverablesListItem) {
    this.navigateTo.emit(item);
  }

  isExpanded(item: DeliverablesListItem) {
    if (this.expandedRowsStoreCollection && this.expandedRowsStoreCollection[item.id])
      return this.expandedRowsStoreCollection[item.id] == true;
    return false;
  }

  hasChildren(item: DeliverablesListItem) {
    return (item.children && item.children.length > 0);
  }

  getFormControl(item: DeliverablesListItem) {
    return this.formGroup.find(e => e.id == item.id)?.formControl;
  }

  displayNameChange(event, item: DeliverablesListItem) {
    if (event.target && event.target.value) {
      item.displayName = event.target.value.trim();
    }
    else {
      item.displayName = item.originalName;
    }
  }
}
