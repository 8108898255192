import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root'
})
export class DeliverableConfigurationUtilService {


    constructor() { }

    deepClone(obj: any): any {
        if(obj)
            return JSON.parse(JSON.stringify(obj));
    }

    compareJson(obj1: any, obj2: any): boolean {
        return JSON.stringify(obj1) == JSON.stringify(obj2);
    }

    isDeliverablesVisibilityChanged(initialDeliverablesInReport , alteredDeliverablesInReport) {
        let result = false;
        initialDeliverablesInReport.forEach((deliverable) => {
            const matchedDeliverable = alteredDeliverablesInReport.find((it) => it.type === deliverable.type);
            if (matchedDeliverable && deliverable.showDeliverable !== matchedDeliverable.showDeliverable) {
                result = true;
                return;
            }
        });
        return result;
    }

    sortByPosition(obj: any): any {
        return  obj.sort((a, b) => { return a.position - b.position });
    }

    deepCloneAndSort(obj: any) {
        return this.sortByPosition(this.deepClone(obj));
    }

}
