import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FactorsService} from "@app/deliverables/factors/services/factors.service";
import {DeliverableViewService} from "@platform/services/deliverable-view.service";
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {FactorsDeliverableView} from "@app/deliverables/factors/models/factors.model";
import {ProductDeliverableViewService} from "@platform/services/product-deliverable-view.service";

@Component({
  selector: 'ns-factors-configuration',
  templateUrl: './factors-configuration.component.html',
  styleUrls: ['./factors-configuration.component.scss']
})
export class FactorsConfigurationComponent implements OnInit, OnChanges{

    @Input()    deliverableConfigurationsInReport: any
    @Input()    report: any
    @Input()    factorsConfiguration: any
    @Output()   configUpdated = new EventEmitter();
    @Output()   closeFlyoutMenuEvent = new EventEmitter();
    @Output()   moveToPreviousMenuEvent = new EventEmitter();
    @Output()   setInitialConfigInReportEvent = new EventEmitter();

    public  factorsFlyoutConfiguration: Array<any>
    private factorsConfigFromReport: any
    public  flyoutConfigItems: Array<any>
    private excludedKmaFactors: Array<any>
    constructor(
        private factorsService: FactorsService,
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService
    ) {
        this.factorsFlyoutConfiguration = [];
    }

    ngOnInit(): void {
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.FACTORS.type);
        if(this.factorsService.getExcludedKmaFactors() === undefined) {
            deliverableViews$.subscribe(deliverableViews => {
                this.productDeliverableViewService.get<FactorsDeliverableView>(this.report.id, deliverableViews.find(view => view.viewName === 'concept').productViewId).subscribe(view => {
                    this.factorsService.setFactorsConfiguration(this.report.id);
                    this.factorsService.setExcludedKmaFactors(view.concepts);
                    this.loadFlyoutConfig();
                });
            });
        } else {
            this.loadFlyoutConfig();
        }
    }

    loadFlyoutConfig() {
        this.flyoutConfigItems = this.factorsService.getFlyoutConfigItems();
        this.factorsConfigFromReport = this.factorsService.getFactorsConfiguration();
        this.flyoutConfigItems.find(f => f.id == 'probabilityOfSuccess').isSelected = this.factorsConfigFromReport?.probabilityOfSuccess;
        this.excludedKmaFactors = this.factorsService.getExcludedKmaFactors();
        this.flyoutConfigItems.map(configItem => this.factorsConfigFromReport.hasOwnProperty(configItem.id.toString()) && !this.excludedKmaFactors.includes(configItem.id) && (configItem.isSelected = this.factorsConfigFromReport[configItem.id]));
        if(this.factorsConfigFromReport.messageConnection === undefined || this.factorsConfigFromReport.clearConciseMessage === undefined) {
            this.flyoutConfigItems.find(configItem => configItem.id === 'messageConnection').isSelected =
                this.flyoutConfigItems.find(configItem => configItem.id === 'clearConciseMessage').isSelected =
                    this.factorsConfigFromReport.messagingCommunicationsFactors;
        }
        let initialFlyoutConfig = {}
        this.flyoutConfigItems.forEach(f => {
            initialFlyoutConfig[f.id] = f.isSelected;
        });
        this.excludedKmaFactors.forEach(excludedFactor => {
            this.flyoutConfigItems.find(item => item.id == excludedFactor).showInFlyout = false;
        });
        this.setInitialConfigInReportEvent.emit(initialFlyoutConfig);
    }

    ngOnChanges(changes:SimpleChanges): void {
        if(changes.factorsConfiguration) {
            if (changes.factorsConfiguration.previousValue === undefined) {
                return;
            }
            this.flyoutConfigItems.map(configItem => {
                configItem.isSelected = changes.factorsConfiguration.currentValue[configItem.id]
            });
        }
    }

    toggleVisibility(item: any) {
        let updatedFactorsConfiguration = {};
        this.flyoutConfigItems.find(configItem => configItem.id == item.id).isSelected = !item.isSelected;
        this.flyoutConfigItems.forEach(configItem => {
            updatedFactorsConfiguration[configItem.id] = configItem.isSelected;
        });
        this.configUpdated.emit(item);
    }

    returnBack() {
        this.moveToPreviousMenuEvent.emit();
    }

    onCloseButtonClick() {
        this.closeFlyoutMenuEvent.emit();
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.closeFlyoutMenuEvent.emit();
        }
    }
}
