import { Component, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper } from '@platform/services/string-helper.service';
import {
  BasePlotComponent
} from '@app/deliverables/correlations/quad-map/quad-map-plot/base-plot.component';
import { ViewMetaInfoService } from '@platform/services/view-meta-info.service';

import * as d3 from 'd3';


@Component({
  selector: 'ns-correlations-chart',
  templateUrl: './quad-map-plot.component.html',
  styleUrls: ['./quad-map-plot.component.scss'],
})
export class QuadMapPlotComponent extends BasePlotComponent implements OnChanges {

  constructor(public translate: TranslateService,
              public stringHelper: StringHelper,
              public viewMetaInfoService: ViewMetaInfoService) {
    super(translate, stringHelper, viewMetaInfoService)
  }

  ngOnChanges(): void {
    const container: HTMLElement = this.svgContainer.nativeElement;
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
    this.buildScatterPlotChart(container);
  }

  public buildScatterPlotChart(container: any): void {
    //d3 and svg containers to plot the chart
    const svg = d3.select("#svg-container");
    const margin = this.plotOptions.margin;
    const g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //width and height of the svg container created
    const width = +svg.attr("width"),
      height = +svg.attr("height");

    //the axes scale for both x an y
    const axisScale = this.getAxisScale(width, height);

    //check axisScale before rendering plotdata
    if (axisScale) {
      //dataset to plot scatter chart
      const dataSet = this.getScatterPlotDataSet(axisScale);

      //defining the scatter chart axes
      this.defineScatterPlotAxes(g, width, height, axisScale);

      //defining the scatter chart quadrants
      this.defineScatterPlotQuadrants(g, width, height, axisScale);

      //labels and links(anchor lines) for the data points/dots
      let labels = this.createAndGetDataPointLabels(g, dataSet, axisScale);
      let links = this.createAndGetDataPointLinks(g, width, height, axisScale);

      //rendering the divider line of quadrants
      this.drawChartDividerLines(g, width, height, axisScale);

      //ploting the data points
      this.scatterDataPointsOnChart(g, height, dataSet, axisScale);

      //adding tooltip functionality for datapoints
      this.addDataPointTooltip(g, dataSet, width, height, axisScale);

      //rendering the label Text of axes x and y
      this.renderAxisLabels(g, width, height);

      //rendering the label Text of quadrants
      this.renderQuadrantLabels(g, width, height);

      this.setLabelsAndAnchors(labels, links);
    }
  }

  private getSVGContainer(container: any, options: any) {
    let containerWidth = container.offsetWidth - 140;
    let svg, margin, marginLeft, marginRight,
      marginTop, marginBottom;
    margin = options.margin;
    marginLeft = margin && margin.left ? margin.left : 0;
    marginRight = margin && margin.right ? margin.right : 0;
    marginTop = margin && margin.top ? margin.top : 0;
    marginBottom = margin && margin.bottom ? margin.bottom : 0;
    svg = d3.select(container)
      .append('svg')
      .attr('width', containerWidth + marginLeft + marginRight)
      .attr('height', options.height + marginTop + marginBottom);
    return svg;
  }

  private getAxisScale(width: number, height: number) {
    if (this.scatterPlotData) {
      const axisValue = this.scatterPlotData.axisPlotData.axisValue,
        margin = this.plotOptions.margin;
      let scale = {},
        xMinimum, yMinimum, xMaximum, yMaximum,
        xMin = isFinite(axisValue.xMin) ? (axisValue.xMin) : 0,
        yMin = isFinite(axisValue.yMin) ? (axisValue.yMin) : 0,
        xMax = isFinite(axisValue.xMax) ? (axisValue.xMax) : 0,
        yMax = isFinite((axisValue.yMax)) ? (axisValue.yMax): 0,
        domainWidth = width - margin.left - margin.right,
        domainHeight = height - margin.top - margin.bottom;

      // don't want dots overlapping axis, so add in buffer to data domain
      if (xMin > 0) {
        xMinimum = (xMin <= 0.15) ? xMin - 0.01 : xMin - 0.15;
      } else {
        xMinimum = -0.1;
      }
      if (yMin > 0) {
        yMinimum = (yMin <= 0.05) ? -0.5 : yMin - 0.5;
      } else {
        yMinimum = -0.5;
      }
      xMaximum = (xMax <= 0.25) ? 0.3 : xMax + 0.05;
      yMaximum = yMax + 0.3;
      scale['x'] = d3.scaleLinear().domain([(xMinimum), (xMaximum)]).range([0, domainWidth]);
      scale['y'] = d3.scaleLinear().domain([(yMinimum), (yMaximum)]).range([domainHeight, 0]);

      return scale;
    }
  }

  private defineScatterPlotAxes(g, width, height, scale) {
    let x = scale.x,
      y = scale.y;

    let xAxisOptions = this.plotOptions.xAxis,
      yAxisOptions = this.plotOptions.yAxis;

    g.append("g")
      .attr("class", xAxisOptions.class)
      .attr("fill", xAxisOptions.fillColor)
      .attr("color", xAxisOptions.color)
      .attr("stroke-width", xAxisOptions.strokeWidth)
      .attr("transform", "translate(0," + (height - 70) + ")")
      .call(d3.axisBottom(x).tickSizeOuter(0).ticks(0)); //changed this line

    g.append("g")
      .attr("class", yAxisOptions.class)
      .attr("stroke-width", yAxisOptions.strokeWidth)
      .attr("stroke", yAxisOptions.fillColor)
      .attr("color", yAxisOptions.color)
      .attr("fill", "none")
      .call(d3.axisLeft(y).tickSizeOuter(0).ticks(0)); //changed this line
  }

  private drawChartDividerLines(g, width, height, scale) {
    let xValue = this.scatterPlotData.axisPlotData.xDividerAxis,
      yValue = this.scatterPlotData.axisPlotData.yDividerAxis,
      x = scale.x,
      y = scale.y;

    let xDividerLine = this.plotOptions.xDividerLine,
      yDividerLine = this.plotOptions.yDividerLine;
    g.selectAll('#xLine').remove();
    g.append("line")
      .attr("id", "xLine")
      .attr("x1", x(xValue))
      .attr("y1", 0)
      .attr("x2", x(xValue))
      .attr("stroke", xDividerLine.fillColor)
      .attr("stroke-width", xDividerLine.strokeWidth)
      .attr("y2", height - 70)
      .attr("marker-end", "url(#marker)")
      .attr("marker-start", "url(#marker-start)");

    g.selectAll('#yLine').remove();
    g.append("line")
      .attr("x1", 0)
      .attr("id", "yLine")
      .attr("y1", y(yValue))
      .attr("x2", width - 70)
      .attr("stroke", yDividerLine.fillColor)
      .attr("stroke-width", yDividerLine.strokeWidth)
      .attr("y2", y(yValue))
      .attr("marker-end", "url(#marker)")
      .attr("marker-start", "url(#marker-start)");
  }

  private renderAxisLabels(g, width, height) {
    let self = this;
    let selectedKeyMeasureName = this.selectedKeyMeasureName.toUpperCase();
    let xAxisLabelText = this.translate.instant('shared.deliverables.correlations.quadMap.x.axis.label', { correlationLabel: selectedKeyMeasureName});
    let type = this.translate.instant('shared.deliverables.correlations.quadMap.type.concept.label').toUpperCase();
    let yAxisLabelText = this.translate.instant('shared.deliverables.correlations.quadMap.y.axis.label', {type: type});

    d3.selectAll('#svg-container .label').call(this.wrap.bind(this));

    g.append("text")
      .style('color', '#fff')
      .style('font-size', '13px')
      .attr("text-anchor", "middle")
      .attr("x", (width / 2) - 30)
      .attr("y", height - 25)
      .attr("class", "legends")
      .style("fill", "#666")
      .text(xAxisLabelText)

    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -40)
      .attr("x", 0 - (height / 2) + 30)
      .attr("dy", "1em")
      .style('color', '#fff')
      .style('font-size', '13px')
      .style("text-anchor", "middle")
      .style("fill", "#666")
      .attr("class", "legends")
      .text(yAxisLabelText);
  }

}
