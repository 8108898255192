import {Injectable} from "@angular/core";
import {Observable, of, Subject, switchMap} from "rxjs";
import {ReportService} from "./report.service";


/**
 * This service provides operations for fetching and loading forecasting
 *
 * @example
 * constructor(private forecastingService: ForecastingService) { }
 *
 * @export
 * @class ForecastingService
 */
@Injectable({
    providedIn: 'root'
})
export class ForecastingService {

    /**
     * Observable that is used to track if reset deliverable button was triggered/clicked.
     *
     * @type {Subject<void>}
     */
    readonly resetCards$: Subject<void> = new Subject<void>();

    /**
     * Observable that is used to track if default card view exists.
     *
     * @type {Subject<boolean>}
     */
    readonly defaultCardsViewExist$: Subject<boolean> = new Subject<boolean>();

    /**
     * Creates an instance of ConceptService.
     *
     * @constructor
     * @param {Store<State>} store
     * @param {HttpClient} httpClient
     * @param {ReportService} reportService
     * @param {AppConfigService} appConfigService
     * @memberof ConceptService
     */
    constructor(
        private reportService: ReportService
    ) {
    }

    /**
     * Check if Forecasting Exists
     */
    public isForecastingExists(): Observable<boolean> {
        const report$ = this.reportService.get();
        return report$.pipe(switchMap(report => {
            return of(report.deliverables.some(it => it.type.startsWith('forecast')));
        }));
    }

}
