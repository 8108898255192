<div class="sticky-container facet-container" *ngIf="filter">
<div class="sticky-container facet-container">
  <div class="air-filters-bar clearfix">
    <div class="float-left">
      <ns-dropdown id="conceptsFilter"
        class="filter-item"
        [dropDownData]="conceptsListData"
        (selectionChange)="selectConcept($event.data)">
      </ns-dropdown>
      <ns-dropdown id="countryFilter"
        class="filter-item"
        [dropDownData]="countryListData"
        (selectionChange)="selectedEvent($event)">
      </ns-dropdown>
      <ns-dropdown id="subgroupsFilter"
        class="filter-item"
        [dropDownData]="subgroupListData"
        (selectionChange)="selectSubgroup($event.data)">
      </ns-dropdown>
      <ns-dropdown id="attributesFilter"
                   class="filter-item"
                   [dropDownData]="attributesListData"
                   (selectionChange)="selectAttribute($event.data)"
                   [truncateLabelCount]="10">
      </ns-dropdown>
      <ns-gap-analysis-filter-show class="filter-item" [deliverableLabel]="deliverableLabel">
      </ns-gap-analysis-filter-show>
    </div>
  </div>
</div>
