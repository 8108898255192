import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from "@angular/core";
import {
    ClickZone,
    StrengthWatchoutsConcept,
} from "@app/deliverables/strengths-watchouts/models/strength-watchouts.model";
import {
    FilterItem,
    StrengthWatchoutsFilter,
} from "@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model";
import {StrengthWatchoutsService} from "@app/deliverables/strengths-watchouts/strength-watchouts.service";
import {FilterService} from "@platform/services/filter.service";
import {TranslateService} from '@ngx-translate/core';
import { UserInfo } from "@platform/models/user-info.model";
import { UserService } from "@platform/services/user.service";

@Component({
    selector: "sw-click-data-zone",
    templateUrl: "./zone.component.html",
    styleUrls: ["./zone.component.scss"],
})
export class ZoneComponent implements OnInit, OnChanges {
    @Input() selectedZoneImageLabel: string;
    @Input() activatedZoneImageLabel: string;
    @Input() concept: StrengthWatchoutsConcept;
    @Input() filter: StrengthWatchoutsFilter;
    @Input() aiZoneSummaries: boolean = false;
    @Input() aiSubgroupSummaries: any = null;
    @Input() chartMetricClicks:boolean = true;

    @Output() zoneEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() zoneClicked: EventEmitter<string> = new EventEmitter<string>();

    firstSentiment: FilterItem;

    secondSentiment: FilterItem;

    maxWidth: number = 0;

    maxWidthPercentage: number = 0;

    zoneSummaryindex: number | 2;

    zoneSummary: string = null;

    currentZone: ClickZone;

    public mainEmptyStateMessage = this.translate.instant('shared.deliverables.strengthsWatchouts.verbatims.zone.summary.empty.state');

    public secondaryEmptyStateMessage = this.translate.instant('shared.deliverables.strengthsWatchouts.verbatims.zone.summary.empty.secondary.state');

    public wideConcept: boolean;
    public imageHeight: string;
    public isCarouselDisplay: boolean;

    /**
     * Currently logged in user.
     *
     * @type {UserInfo}
     * @memberof ReportComponent
     */
    public userInfo: UserInfo;

    public isAISummaryFeatureEnabled: boolean = false;

    public isSubgroupSummaryVisible: boolean = false;

    constructor(private strengthWatchoutsService: StrengthWatchoutsService,
                private filterService: FilterService,
                private translate: TranslateService,
                private userService: UserService) {
    }

    ngOnInit(): void {
        this.userService.getUser().subscribe(result => {
            this.userInfo = result;
            this.isAISummaryFeatureEnabled = this.userInfo.featureFlags.includes('REPORTING_AI_SUMMARIES');
        });
        const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
        filter$.subscribe((filter) => {
            this.filter = this.strengthWatchoutsService.updateFilterOptions(filter);
            this.activatedZoneImageLabel = this.filter.show.activatedZoneImageLabel ? this.filter.show.activatedZoneImageLabel : this.activatedZoneImageLabel;
            this.updateAISubgroupSummaryflag();
            this.getClickedZone();
        });
        this.wideConcept = this.concept && this.concept.imageHeight > 450;
        this.imageHeight = this.concept && this.concept.imageHeight == 450 ? "Landscape":(this.concept && this.concept.imageHeight == 540 ? "Widescreen" : "Portrait");
    }

    ngOnChanges() {
        this.activatedZoneImageLabel = this.filter.show.activatedZoneImageLabel;
        const nuetralSelected = this.filter.show.options.find(
            (it) => it.id == 2
        )?.isSelected;
        const likeSelected = this.filter.show.options.find(
            (it) => it.id == 1
        )?.isSelected;
        const dislikeSelected = this.filter.show.options.find(
            (it) => it.id == 0
        )?.isSelected;
        this.zoneSummaryindex =
            (likeSelected && dislikeSelected) ||
            (likeSelected && nuetralSelected) ||
            (dislikeSelected && nuetralSelected)
                ? 3
                : likeSelected
                    ? 1
                    : dislikeSelected
                        ? 2
                        : nuetralSelected
                            ? 0 : -1;
        this.updateAISubgroupSummaryflag();
        this.getChartMetric();
        this.getClickedZone();
        this.calculateIndexWidth();
        this.checkNodata();
        this.isCarouselDisplay = this.filter.show.languages.filter(x => x.id === 'all' && x.isSelected === true).length  > 0;
    }

    onMouseEnter(zoneLabel: string): void {
        if (this.selectedZoneImageLabel) {
            this.zoneEvent.emit("");
            this.selectedZoneImageLabel = '';
        }
        this.zoneEvent.emit(zoneLabel);
    }

    onMouseLeave(): void {
        this.zoneEvent.emit("");
    }

    /**
     * To get maxWidth of sentiments.
     * @param values
     * @param keys
     */
    getMaxWidth(values: ClickZone[], ...keys: string[]) {
        return Math.max(
            ...keys.map((key) => Math.max(...values.map((o) => o[key])))
        );
    }

    /**
     * To set the appropriate summary as per filters
     * @param zone
     */
    filteredZoneSummary(zone: ClickZone) {
        if (this.isSubgroupSummaryVisible) {
            switch (this.zoneSummaryindex) {
                case 1:
                    this.zoneSummary = zone?.likeSummary;
                    break;
                case 2:
                    this.zoneSummary = zone?.dislikeSummary;
                    break;
                case 0:
                    this.zoneSummary = zone?.neutralSummary;
                    break;
                case 3:
                    this.zoneSummary = zone?.totalSummary;
                    break;
                default:
                    this.zoneSummary = null;
                    break;
            }
        }
    }

    roundNearest5(num) {
        return Math.ceil(num / 5) * 5;
    }

    /**
     * To get the rounded values till 3rd decimal points
     */
    roundto3Digit(num : number) {
        const factor = Math.pow(10,3);
        const roundValue = Math.round(num*factor)/factor;
        return Math.round(roundValue*100);
    }

    /**
     * To get current Zones using activateZoneImageLabel.
     */
    getClickedZone() {
        if (this.activatedZoneImageLabel) {
            this.currentZone = this.concept.zones.find(it => it.label === this.activatedZoneImageLabel);
            this.filteredZoneSummary(this.currentZone);
        }
    }

    /**
     * Toggle to get zone summary, also activate the zones.
     * @param zone
     */
    toggleExpansion(zone: ClickZone) {
        this.currentZone = zone;
        this.filteredZoneSummary(this.currentZone);
        if (this.activatedZoneImageLabel) {
            this.activatedZoneImageLabel = this.activatedZoneImageLabel === zone.label ? '' : zone.label;
            this.filter.show.activatedZoneImageLabel = this.activatedZoneImageLabel;
            this.zoneClicked.emit(this.activatedZoneImageLabel);
        } else {
            this.zoneClicked.emit(zone.label);
            this.activatedZoneImageLabel = zone.label;
            this.filter.show.activatedZoneImageLabel = zone.label
        }
        this.filterService.update(this.filter);
    }

    /*
    * This function is responsible for calculating
    * Index width depending upon filter sentiment.
    * */
    calculateIndexWidth() {
        this.firstSentiment = this.filter.show.options[1];
        this.secondSentiment = this.filter.show.options[2];
        this.maxWidth = this.firstSentiment.isSelected && this.secondSentiment.isSelected ?
            this.getMaxWidth(this.concept.uniqueLabelZones, this.firstSentiment.name.toLowerCase(), this.secondSentiment.name.toLowerCase())
            : (this.firstSentiment.isSelected && !this.secondSentiment.isSelected ?
                this.getMaxWidth(this.concept.uniqueLabelZones, this.firstSentiment.name.toLowerCase())
                : (!this.firstSentiment.isSelected && this.secondSentiment.isSelected ?
                    this.getMaxWidth(this.concept.uniqueLabelZones, this.secondSentiment.name.toLowerCase())
                    : 0));
        this.maxWidthPercentage = this.roundto3Digit(this.maxWidth / this.concept.totalClicks);
    }

    /*
    * This function checks
    * if clicks data is available
    * and at least one sentiment selected
    * */
    checkNodata() {
        return this.concept.totalClicks == 0 ? false : (this.filter.show.options[1].isSelected || this.filter.show.options[2].isSelected);
    }

    /*
    * This Function checks whether zone summary visibility
    * allowed for selected subgroup by aiSubgroupSummaries flag
    * */
    updateAISubgroupSummaryflag() {
        const selectedSubgroup = this.filter.subgroups.find((it) => it.isSelected).id;
        if (this.aiSubgroupSummaries && this.aiSubgroupSummaries[selectedSubgroup]) {
            this.isSubgroupSummaryVisible = true;
        }
    }

    getChartMetric(){
        this.chartMetricClicks =this.filter.show.chartMetric.find(chart=> chart.id === 'clicks').isSelected;
    }

    protected readonly Math = Math;
}
