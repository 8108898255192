<div class="sticky-container facet-container" *ngIf="filter">
  <div class="air-filters-bar clearfix">
    <div class="float-left">
        <ng-container *ngIf="!concept">
          <ns-dropdown id="compareFilter"
            class="filter-item"
            [dropDownData]="compareListData"
            (selectionChange)="selectCompare($event.data)">
          </ns-dropdown>
          <ns-dropdown id="conceptsFilter"
            class="filter-item"
            [dropDownData]="conceptsListData"
            (selectionChange)="selectConcept($event.data)">
          </ns-dropdown>
          <ns-dropdown id="countryFilter"
            class="filter-item"
            [dropDownData]="countryListData"
            (selectionChange)="selectedEvent($event)">
          </ns-dropdown>
          <ns-dropdown id="subgroupsFilter"
            class="filter-item"
            [dropDownData]="subgroupListData"
            (selectionChange)="selectSubgroup($event.data)">
          </ns-dropdown>
        </ng-container>
        <ns-attributes-filter-show id="showFilter" class="filter-item" [deliverableLabel]="deliverableLabel"></ns-attributes-filter-show>
    </div>
  </div>
</div>
