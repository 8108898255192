import { Dictionary } from '@ngrx/entity';
import { State } from '@platform/store/state/app.state';
import { UserViewState } from '@platform/store/state/user-view.state';
import { userViewAdapter } from '@platform/store/adapters/user-view.adapter';
import { UserView } from '@platform/models/user-view.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * Default selectors from adapter.
 *
 * @property
*/
const {
  selectEntities,
  selectAll
} = userViewAdapter.getSelectors();

// UserView Selectors
/**
 * UserView feature selector.
 *
 * @export
 * @property
*/
export const selectUserViewsFeature = createFeatureSelector< UserViewState>('userViews');
/**
 * UserView selector.
 *
 * @export
 * @property
*/
export const selectUserViewsState = createSelector(
  selectUserViewsFeature,
  (state: UserViewState) => state
);
/**
 * UserView entity selector.
 *
 * @export
 * @property
*/
export const selectUserViewEntities = createSelector(
  selectUserViewsState,
  selectEntities
);
/**
 * Selector for selecting sserViews by id.
 *
 * @export
 * @property
*/
export const selectUserViewById = createSelector(
  selectUserViewEntities,
  (entities: Dictionary<UserView>, props: { id: string }) => {
    return entities[props.id];
  }
);
/**
 * Selector for selecting all userViews.
 *
 * @export
 * @property
*/
export const selectAllUserViews = createSelector(
  selectUserViewsState,
  selectAll
);
/**
 * Selector for selecting userViews for a report object.
 *
 * @export
 * @property
*/
export const selectReportUserViews = createSelector(
  selectAllUserViews,
  (userViews: UserView[], props: { reportId: string }) => {
    return userViews.filter(uv => uv.reportId === props.reportId);
  }
);

/**
 * Selector for selecting userViews for a report object and by deliverable type.
 *
 * @export
 * @property
*/
export const selectByReportAndDeliverableType = createSelector(
  selectAllUserViews,
  (userViews: UserView[], props: { reportId: string, deliverableType: string }) => {
    const filtered = userViews.filter(uv => uv.reportId === props.reportId
      && uv.filter.deliverableType === props.deliverableType);
    return filtered;
  }
);
