import { filterNames as filterNameSource } from './filter-names';
import { FilterService } from '@platform/services/filter.service';
import { AttributesService } from '../../services/attributes.service';
import { AttributesFilter } from '../../models/filter.model';
import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { Subscription } from 'rxjs';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelEvent } from '@src/assets/utils/mixpanel-enum';

/**
 * `<ns-attributes-filter-show>` component builds show filter for the
 * `FilterComponent`.
 *
 * @example
 * <ns-attributes-filter-show>
 * </ns-attributes-filter-show>
 *
 * @export
 * @class ShowComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-attributes-filter-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

  @Input() deliverableLabel: string;

  /**
   * Attributes filter object.
   *
   * @property
   * @type {AttributesFilter}
   * @memberof ShowComponent
   */
  public filter: AttributesFilter;

  public disableDataLabel: boolean;
  /**
   * Filter name object.
   *
   * @property
   * @type {*}
   * @memberof ShowComponent
   */
  public filterNames: any;

  /**
   * Filter label.
   *
   * @property
   * @type {string}
   * @memberof ShowComponent
   */
  public filterLabel: string;

  /**
   * Filter subscription for cleanup.
   *
   * @private
   * @property
   * @type {Subscription}
   * @memberof ShowComponent
   */
  private filterSubscription: Subscription;

  /**
   * metric object
   *
   * @property
   * @type {string}
   * @memberof ShowComponent
   */
  public metric: string;

  /**
   * Creates an instance of ShowComponent.
   *
   * @constructor
   * @param {AttributesService} attributesService
   * @param {FilterService} filterService
   * @memberof ShowComponent
   */
  constructor(
    private attributesService: AttributesService,
    private filterService: FilterService,
    private mixpanelService: MixpanelService
  ) {
    this.filterNames = filterNameSource;
  }

  /**
   * Event listener for metric type change event.
   *
   * @listens event:change
   * @param {*} event
   * @memberof ShowComponent
   */
  public changeMetricType(event): void {
    const isMean = event.value === this.filterNames.mean;
    const filter = this.cloneFilter();
    filter.show.mean = isMean;
    if (!isMean) {
      filter.show.percents.topTwoBox = true;
      this.disableDataLabel = false;
    } else {
      this.disableDataLabel = true;
      filter.show.dataLabels = false;
      filter.show.percents.topBox = false;
      filter.show.percents.topTwoBox = false;
      filter.show.percents.bottomThreeBox = false;
      filter.show.percents.all = false;
    }
    filter.show.percents.isSelected = !isMean;
    filter.show.noDecimal = !isMean;
    filter.show.oneDecimal = isMean;
    this.filterService.update(filter);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }


  /**
   * Returns the selected metric value.
   *
   * @returns {string}
   * @memberof ShowComponent
   */
  public getSelectedMetric(): string {
    const filterNames = this.filterNames;
    const filter = this.filter;
    const metric = filter.show.mean ? filterNames.mean : filterNames.percents;
    if (metric === filterNames.percents && filter.show.barCharts) {
        this.disableDataLabel = false;
    } else {
      this.disableDataLabel = true;
    }
    return metric;
  }

  /**
   * Event listener for percent type change.
   *
   * @listens event:change
   * @param {*} event
   * @memberof ShowComponent
   */
  public changePercentType(event): void {
    const filterNames = this.filterNames;
    const percentsType = event.value;
    const filter = this.cloneFilter();
    filter.show.percents.topBox = percentsType === filterNames.topBox;
    filter.show.percents.topTwoBox = percentsType === filterNames.topTwoBox;
    filter.show.percents.bottomThreeBox = percentsType === filterNames.bottomThreeBox;
    filter.show.percents.all = percentsType === filterNames.allFiveBoxes;
    this.filterService.update(filter);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

  /**
   * Returns the selected percents type value.
   *
   * @returns {string}
   * @memberof ShowComponent
   */
  public getSelectedPercentsType(): string {
    const filterNames = this.filterNames;
    const percentsFilter = this.filter.show.percents;
    let percentsType = '';
    percentsType = percentsFilter.topBox ? filterNames.topBox : percentsType;
    percentsType = percentsFilter.topTwoBox ? filterNames.topTwoBox : percentsType;
    percentsType = percentsFilter.bottomThreeBox ? filterNames.bottomThreeBox : percentsType;
    percentsType = percentsFilter.all ? filterNames.allFiveBoxes : percentsType;
    return percentsType;
  }

  /**
   * Event listener for decimal place filter change.
   *
   * @listens event:change
   * @param {*} event
   * @memberof ShowComponent
   */
  public changeDecimalPlace(event): void {
    const filterNames = this.filterNames;
    const decimalPlace = event.value;
    const filter = this.cloneFilter();
    filter.show.noDecimal = decimalPlace === filterNames.noDecimals;
    filter.show.oneDecimal = decimalPlace === filterNames.oneDecimalPlace;
    filter.show.twoDecimal = decimalPlace === filterNames.twoDecimalPlace;
    this.filterService.update(filter);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

  /**
   * Returns selected decimal place value.
   *
   * @returns {string}
   * @memberof ShowComponent
   */
  public getSelectedDecimalPlace(): string {
    const filterNames = this.filterNames;
    const showFilter = this.filter.show;
    let decimalPlace = '';
    decimalPlace = showFilter.noDecimal ? filterNames.noDecimals : decimalPlace;
    decimalPlace = showFilter.oneDecimal ? filterNames.oneDecimalPlace : decimalPlace;
    decimalPlace = showFilter.twoDecimal ? filterNames.twoDecimalPlace : decimalPlace;
    return decimalPlace;
  }

  /**
   * Toggles the selection for checkboxes.
   *
   * @param {string} filterName
   * @memberof ShowComponent
   */
  public toggleSelection(filterName: string): void {
    const filterNames = this.filterNames;
    const filter = this.cloneFilter();
    const show = filter.show;
    switch (filterName) {
      case filterNames.statTesting:
        show.statTesting = !show.statTesting;
        break;
      case filterNames.dataLabels:
        show.dataLabels = !show.dataLabels;
        break;
      case filterNames.barCharts:
        show.barCharts = !show.barCharts;
        show.dataLabels = show.barCharts ? show.dataLabels : false;
        this.disableDataLabel = !show.barCharts ? true : false;
        break;
    }
    this.filterService.update(filter);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

  /**
   * Initializes the show filter component.
   *
   * @memberof ShowComponent
   */
  ngOnInit() {
    const filter$ = this.attributesService.getAttributesFilter();
    this.filterSubscription = filter$.subscribe(filter => {
      this.filter = filter;
      this.metric = this.getSelectedMetric();
      this.setFilterLabel();
    });
  }

  /**
   * Sets filter value property.
   *
   * @private
   * @memberof ShowComponent
   */
  private setFilterLabel(): void {
    const showFilter = this.filter.show;
    let label = '';
    const options: string[] = [];
    label = showFilter.mean ? `Mean` : label;
    if (showFilter.percents.isSelected) {
      label = `Percents: `;
      label = showFilter.percents.topBox ? `${label} Top Box` : label;
      label = showFilter.percents.topTwoBox ? `${label} Top 2 Box (default)` : label;
      label = showFilter.percents.bottomThreeBox ? `${label} Bottom 3 Box` : label;
      label = showFilter.percents.all ? `${label} All 5 boxes` : label;
    }
    if (showFilter.statTesting) {
      options.push('Stat Testing');
    }
    if (showFilter.dataLabels && !this.disableDataLabel) {
      options.push('Data Labels');
    }
    if (showFilter.barCharts) {
      options.push('Bar Charts');
    }
    if (options.length) {
      label = `${label}; ${options.join(', ')}`;
    }
    this.filterLabel = label;
  }

  /**
   * Cleans up the show filter component on removing from UI.
   *
   * @memberof ShowComponent
   */
  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  /**
   * Clones filter component.
   */
  private cloneFilter(): AttributesFilter {
    const filter = this.filter;
    const newPercentsFilter = {...filter.show.percents};
    const newShowFilter = {...filter.show, percents: newPercentsFilter};
    const newFilter = {...filter, show: newShowFilter};
    return newFilter;
  }

}
