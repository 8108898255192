import { CaptureOptions } from './capture-options';
import { CSSNode } from './css-node';

/**
 * Processor for css classes.
 */
export class ClassProcessor {

  /**
   * Process class by removing existing classes and adding current nodes class.
   *
   * @param clone the cloned node.
   * @param cssNode the cssNode.
   * @param options the capture options.
   */
  public process(clone: Element, cssNode: CSSNode, options: CaptureOptions): void {
    const ignoreClasses: string[] = [];
    this.clean(clone, options, ignoreClasses);
    // Add current nodes random class.
    clone.classList.add(cssNode.className);
    // Adding ignored class to maintain order.
    ignoreClasses.forEach(name => clone.classList.add(name));
  }

  private clean(clone: Element, options: CaptureOptions, ignoreClasses: string[]): void {
    const classes: string[] = [];
    let ignore: boolean;
    clone.classList.forEach(className => {
      ignore = options.cssClass.ignore.some(prefix => className.indexOf(prefix) === 0);
      // Ignore if its excluded in the configuration.
      if (ignore) {
        ignoreClasses.push(className);
      }
      classes.push(className);
    });
    classes.forEach(className => clone.classList.remove(className));
  }
}
