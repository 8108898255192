<ng-container *ngIf="!oldView">
<!--  <div class="sticky-swipe-table-container">-->
<!--    <div class="swipe-container">-->
<!--      <ns-swipe-table class="pull-right" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"></ns-swipe-table>-->
<!--    </div>-->
<!--  </div>-->

  <div *ngIf="!oldView" class="tga-table-container deliverable-insight-tgpa carousel-container">
    <ns-carousel-table class="carousel-panel" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"
                       (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>

    <table id="tga-table" class="factors-new-tga col-12 highlighted-row-container deliverable-insight-tgpa-table" mat-table matSortStart="asc" matSortDisableClear matSort [dataSource]="dataSource">
      <!--Create dynamic columns from colHeaders-->
      <ng-container *ngFor="let colHeader of colHeaders; let i = index; trackBy: trackItem" matColumnDef="{{colHeader.name}}">
        <ng-container *ngIf="!colHeader.id">
          <th mat-header-cell *matHeaderCellDef disabled="true" class="statement-header deliverable-insight-tgpa-table-head"></th>
          <td mat-cell *matCellDef="let element" class="statement deliverable-insight-tgpa-table-data"><span class="answer-text">{{element.statement}}</span></td>
        </ng-container>
        <ng-container *ngIf="colHeader.id">
          <th mat-header-cell *matHeaderCellDef class="statement-header deliverable-insight-tgpa-table-head" >
            <ns-table-header [title]="colHeader.name"></ns-table-header>
          </th>
          <td mat-cell *matCellDef="let element" class="statement-chart deliverable-insight-tgpa-table-data">
            <div class="subgroup-body">
              <div class="subgroup-body-item">
                <span class="sif avatar-new avatar-new-bg4" *ngIf="element[colHeader.name].qualify">
                  <span class="sif sif-checkmark sif-white">
                  </span>
                </span>
                <span class="sif avatar-new avatar-new-bg5" *ngIf="!element[colHeader.name].qualify">
                  <span class="sif sif-close sif-white">
                  </span>
                </span>
              </div>
              <div class="subgroup-body-item"><span class="sif" [ngClass]="getTargetGroupClass(element[colHeader.name].pi)"></span></div>
              <div class="subgroup-body-item"><span class="sif" [ngClass]="getTargetGroupClass(element[colHeader.name].nd)"></span></div>
              <div class="subgroup-body-item"><span class="sif" [ngClass]="getTargetGroupClass(element[colHeader.name].ac)"></span></div>
            </div>
          </td>
        </ng-container>
      </ng-container>
      <tr class="deliverable-insight-tgpa-table-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="answer-row deliverable-insight-tgpa-table-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <!--Footer legend-->
  <div class="row">
    <ns-target-group-legend class="col-12 legend-container"></ns-target-group-legend>
  </div>

</ng-container>
