import { filterNames as filterNameSource } from './filter-names';
import { FilterService } from '@platform/services/filter.service';
import { CorrelationsService } from '../../services/correlation.service';
import { CorrelationsFilter } from '../../models/filter.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {CompareView} from '@app/deliverables/correlations/models/compare-view-enum';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * `<ns-correlations-filter-show>` component builds show filter for the
 * `FilterComponent`.
 *
 * @example
 * <ns-correlations-filter-show>
 * </ns-correlations-filter-show>
 *
 * @export
 * @class ShowComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-correlations-filter-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

  public keyMeasureListData: DropdownData<string>;

  /**
   * Correlations filter object.
   *
   * @property
   * @type {CorrelationsFilter}
   * @memberof ShowComponent
   */
  public filter: CorrelationsFilter;

  public disableDataLabel: boolean;
  /**
   * Filter name object.
   *
   * @property
   * @type {*}
   * @memberof ShowComponent
   */
  public filterNames: any;

  /**
   * Filter label.
   *
   * @property
   * @type {string}
   * @memberof ShowComponent
   */
  public filterLabel: string;

  /**
   * Filter subscription for cleanup.
   *
   * @private
   * @property
   * @type {Subscription}
   * @memberof ShowComponent
   */
  private filterSubscription: Subscription;

  /**
   * metric object
   *
   * @property
   * @type {string}
   * @memberof ShowComponent
   */
  public metric: string;

  public showKeyMeasuresOnly: Boolean = false;

  /**
   * Creates an instance of ShowComponent.
   *
   * @constructor
   * @param {CorrelationsService} CorrelationsService
   * @param {FilterService} filterService
   * @memberof ShowComponent
   */
  constructor(
    public correlationsService: CorrelationsService,
    private filterService: FilterService,
    private translate: TranslateService
  ) {
    this.filterNames = filterNameSource;
  }

  /**
   * Event listener for metric type change event.
   *
   * @listens event:change
   * @param {*} event
   * @memberof ShowComponent
   */
  public changeMetricType(event): void {
    const isTertile = event.value === this.filterNames.tertile;
    const filter = this.cloneFilter();
    filter.show.tertile = isTertile;
    if (!isTertile) {
      filter.show.tertileMean.oneDecimal = true;
    } else {
      filter.show.tertileMean.noDecimal = false;
      filter.show.tertileMean.oneDecimal = false;
      filter.show.tertileMean.twoDecimal = false;
    }
    filter.show.tertileMean.isSelected = !isTertile;
    this.filterService.update(filter);
  }

  public changeSelectedKeyMeasure(event): void {
    this.filterService.update({
      ...this.filter,
      show: {
          ...this.filter.show,
          keyMeasures: this.filter.show.keyMeasures[this.correlationsService.viewName].map((keyMeasure) => {
            return {
              ...keyMeasure,
              isSelected: keyMeasure.name === event.value.name
            }
          })
        }
    });
  }


  /**
   * Returns the selected metric value.
   *
   * @returns {string}
   * @memberof ShowComponent
   */
  public getSelectedMetric(): string {
    const filterNames = this.filterNames;
    const filter = this.filter;
    const metric = filter.show.tertile ? filterNames.tertile : filterNames.tertileMean;
    return metric;
  }

  /**
   * Event listener for percent type change.
   *
   * @listens event:change
   * @param {*} event
   * @memberof ShowComponent
   */
  public changeTertileMeanType(event): void {
    const filterNames = this.filterNames;
    const tertileMeanType = event.value;
    const filter = this.cloneFilter();
    filter.show.tertileMean.noDecimal = tertileMeanType === filterNames.noDecimals;
    filter.show.tertileMean.oneDecimal = tertileMeanType === filterNames.oneDecimalPlace;
    filter.show.tertileMean.twoDecimal = tertileMeanType === filterNames.twoDecimalPlace;
    this.filterService.update(filter);
  }

  /**
   * Returns the selected tertile+Mean type value.
   *
   * @returns {string}
   * @memberof ShowComponent
   */
  public getSelectedTertileMeanType(): string {
    const filterNames = this.filterNames;
    const tertileMeanFilter = this.filter.show.tertileMean;
    let tertileMeanType = '';
    tertileMeanType = tertileMeanFilter.noDecimal ? filterNames.noDecimals : tertileMeanType;
    tertileMeanType = tertileMeanFilter.oneDecimal ? filterNames.oneDecimalPlace : tertileMeanType;
    tertileMeanType = tertileMeanFilter.twoDecimal ? filterNames.twoDecimalPlace : tertileMeanType;
    return tertileMeanType;
  }

  /**
   * Toggles the selection for checkboxes.
   *
   * @param {string} filterName
   * @memberof ShowComponent
   */
  public toggleSelection(filterName: string): void {
    const filterNames = this.filterNames;
    const filter = this.cloneFilter();
    const show = filter.show;
    switch (filterName) {
      case filterNames.aboveAvg:
        show.aboveAverage = !show.aboveAverage;
        break;
      case filterNames.avg:
        show.average = !show.average;
        break;
      case filterNames.belowAvg:
        show.belowAverage = !show.belowAverage;
        break;
    }
    this.filterService.update(filter);
  }

  /**
   * Toggles the selection for checkboxes.
   *
   * @param any filterName
   * @memberof ShowComponent
  */
  public toggleKeyMeasures(filterName: any) {
    const filter = this.cloneFilter();
    const show = filter.show;
    // deep clone of keyMeasures array of objects
    show.keyMeasures = JSON.parse(JSON.stringify(show.keyMeasures));
    if (filterName instanceof Object) {
      show.keyMeasures[this.correlationsService.viewName].filter(measure => {
        if (measure.name === filterName.name) {
          measure.isSelected = !measure.isSelected;
          return measure;
        }
      });
      show.allKeyMeasures = show.keyMeasures[this.correlationsService.viewName].every(measure => measure.isSelected);
    } else {
      filterName = !filterName;
      show.allKeyMeasures = !show.allKeyMeasures;
      show.keyMeasures[this.correlationsService.viewName].forEach(item => {
        item.isSelected = filterName;
      });
    }
    this.filterService.update(filter);
  }

  /**
   * Initializes the show filter component.
   *
   * @memberof ShowComponent
   */
  ngOnInit() {
    const filter$ = this.correlationsService.getCorrelationsFilter();
    this.filterSubscription = filter$.subscribe(filter => {
      this.filter = filter;
      this.keyMeasureListData = this.getShowFilterOptions(filter);
      const compareOption = filter.compare.find((filterItem) => {
        return filterItem.isSelected;
      });
      this.showKeyMeasuresOnly = compareOption.id === CompareView.QUAD_MAP;
      this.metric = this.getSelectedMetric();
      this.filterLabel = this.getFilterLabel();
    });
  }

  /**
   * Returns show filter options of key measures.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {DropdownData}
   * @memberof FilterComponent
   */
  private getShowFilterOptions(filter: CorrelationsFilter): DropdownData<string> {
    const dataType = DataType.RADIO;
    const dropdownData: DropdownItem<string>[] = filter.show.keyMeasures[this.correlationsService.viewName].map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.correlations.quadMap.filter.show.label'),
      dataType: dataType,
      contentTitle: this.translate.instant('shared.deliverables.correlations.quadMap.filter.show.label'),
      data: dropdownData
    };

    return dropdownItems;
  }

  selectKeyMeasure(allKeyMeasures: Array<DropdownItem<string>>) {
    this.filterService.update({
      ...this.filter,
      show: {
        ...this.filter.show,
        keyMeasures:  {
          dataTable: this.setFilterValue(allKeyMeasures, 'dataTable'),
          quadMap: this.setFilterValue(allKeyMeasures, 'quadMap')
        }
      }
    });
  }

  setFilterValue(allKeyMeasures: Array<DropdownItem<string>>, key) {
    return this.filter.show.keyMeasures[key].map((measure) => {
      return {
        ...measure,
        isSelected: allKeyMeasures.find(selectedMeasure => selectedMeasure.value === measure.id) ? allKeyMeasures.find(selectedMeasure => selectedMeasure.value === measure.id).selected : false
      };
    })
  }

  /**
   * Sets filter value property.
   *
   * @private
   * @memberof ShowComponent
   */
  private getFilterLabel(): string {
    const showFilter = this.filter.show;
    let label = '';
    const options: string[] = [];
    label = showFilter.tertile ? `Tertile` : label;
    if (showFilter.tertileMean.isSelected) {
      label = `Tertile + Mean, `;
      label = showFilter.tertileMean.noDecimal ? `${label} No decimals` : label;
      label = showFilter.tertileMean.oneDecimal ? `${label} 1 decimal place` : label;
      label = showFilter.tertileMean.twoDecimal ? `${label} 2 decimal places` : label;
    }
    if (showFilter.aboveAverage) {
      options.push('Above Average');
    }
    if (showFilter.average) {
      options.push('Average');
    }
    if (showFilter.belowAverage) {
      options.push('Below Average');
    }
    if (options.length === 3) {
      label = `${label}, All Attribute Ranking`;
    } else if (options.length === 2) {
      label = `${label}, 2 Attribute Ranking`;
    } else if (options.length === 1) {
      label = `${label}, ${options.join(', ')}`;
    } else {
      label = `${label}`;
    }

    return label;
  }

  /**
   * Cleans up the show filter component on removing from UI.
   *
   * @memberof ShowComponent
   */
  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  /**
   * Clones filter component.
   */
  private cloneFilter(): CorrelationsFilter {
    const filter = this.filter;
    const newKeyMeasuresFilter = {...filter.show.keyMeasures};
    const newTertileMeanFilter = {...filter.show.tertileMean};
    const newShowFilter = {...filter.show, tertileMean: newTertileMeanFilter, keyMeasures: newKeyMeasuresFilter};
    const newFilter = {...filter, show: newShowFilter};
    return newFilter;
  }

}
