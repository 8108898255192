import {Injectable} from '@angular/core';
import {
  StrengthWatchoutsConcept,
  StrengthWatchoutsDeliverableView
} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {Concept} from '@platform/models/concept.model';
import {AppConfigService} from '@app/core/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ElementEngagementService {

  constructor(private cs: AppConfigService) {
  }

  /**
   * Calculates data for displaying on UI.
   * @param {StrengthWatchoutsDeliverableView} elementEngagementData
   * @param {Array<Concept>} concepts
   * @memberOf ElementEngagementService
   */
  public getElementEngagementData(elementEngagementData: StrengthWatchoutsDeliverableView, concepts: Array<Concept>): StrengthWatchoutsDeliverableView {
    let maxZonePercent = 0;
    const elementEngagementDataConcepts = [];
    for (const dataConcept of elementEngagementData.concepts as Array<StrengthWatchoutsConcept>) {
      const concept: Concept = concepts.find((item: Concept) => item.exerciseConceptId === dataConcept.exerciseConceptId);

      const dataConceptOptionalProperties = {
        label: concept.name,
        imageHeight: concept.imageHeight,
        imageWidth: concept.imageWidth,
        imagePath: `${this.cs.config.reporting.url}/reports/${concept.reportId}/concepts/${concept.id}?imageFiles=true&locale=${dataConcept.locale}`
      };
      const elementEngagementDataConcept = Object.assign({}, dataConcept, dataConceptOptionalProperties);
      elementEngagementDataConcepts.push(elementEngagementDataConcept);
    }
    elementEngagementData.concepts = elementEngagementDataConcepts;

    for (const zone of (elementEngagementData.zones || [])) {
      maxZonePercent = zone.percentageClicks > maxZonePercent ? zone.percentageClicks : maxZonePercent;
    }

    elementEngagementData.maxAggregatePercent = maxZonePercent;

    return elementEngagementData;
  }
}
