import {Subgroup} from '@platform/models/subgroup.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {CorrelationsFilter, FilterItem} from '../models/filter.model';
import {FilterService} from '@platform/services/filter.service';
import {CorrelationsDeliverableView} from '../models/correlations.model';
import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {switchMap, take} from 'rxjs/operators';
import {defaultCorrelationsFilter} from '../models/default-correlations-filter';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {PurchaseEnum} from '@app/deliverables/correlations/filter/show/filter-names';
import {CompareView} from '@app/deliverables/correlations/models/compare-view-enum';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableViewType} from '@app/deliverables/correlations/models/deliverable-view-type.enum';
import {DeliverableView} from '@platform/models/deliverable-view.model';

/**
 * `CorrelationsService` has all data operations for fetching correlations
 * and correlations filter data.
 *
 * @export
 * @class CorrelationsService
 */
@Injectable({
    providedIn: 'root'
})
export class CorrelationsService {

    public viewName;

    /**
     * Creates an instance of CorrelationsService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param {ProductDeliverableViewService} productDeliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @memberof CorrelationsService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService) {
    }

    /**
     * Returns an observable of `CorrelationsDeliverableView` data.
     *
     * @returns {Observable<CorrelationsDeliverableView>}
     * @memberof CorrelationsService
     */
    public getCorrelations(): Observable<CorrelationsDeliverableView> {
        const report$ = this.reportService.get();
        const filter$ = this.getCorrelationsFilter();
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.CORRELATIONS.type);
        return report$.pipe(switchMap(report => {
            return filter$.pipe(
                switchMap(filter => {
                    return deliverableViews$.pipe(switchMap((deliverableViews) => {
                        const deliverableView = deliverableViews.find(it => it.viewName === DeliverableViewType.DATA_TABLE);
                        const views$: Observable<CorrelationsDeliverableView> = this.productDeliverableViewService.get<CorrelationsDeliverableView>(report.id, deliverableView.productViewId);
                        return views$.pipe(switchMap(view => {
                            return of(this.filter(filter, view));
                        }));
                    }));
                })
            );
        }));
    }

    /**
     * Filter projection function for filtering the correlations data and returns
     * filtered `CorrelationsDeliverableView` data.
     *
     * @private
     * @param {CorrelationsFilter} filter
     * @param {CorrelationsDeliverableView} data
     * @returns {CorrelationsDeliverableView}
     * @memberof CorrelationsService
     */
    private filter(filter: CorrelationsFilter, data: CorrelationsDeliverableView): CorrelationsDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const filteredStatements = [];
        let filteredStatement;
        let filteredDataItems;
        let filteredView;
        for (const statement of data.statements) {
            filteredDataItems = statement.statementData.filter(
                d => selectedConcepts.includes(d.conceptId) && selectedSubgroups.includes(d.segmentId)
            );
            filteredStatement = {
                label: statement.label,
                statementData: filteredDataItems
            };
            filteredStatements.push(filteredStatement);
        }
        filteredView = Object.assign({}, data, {statements: filteredStatements});
        return filteredView;
    }

    /**
     * Returns observable of correlations filter data.
     *
     * @returns {Observable<CorrelationsFilter>}
     * @memberof CorrelationsService
     */
    public getCorrelationsFilter(): Observable<CorrelationsFilter> {
        const deliverableType = DeliverableType.CORRELATIONS.type,
            filter$ = this.filterService.get<CorrelationsFilter>(deliverableType);
        return filter$;
    }

    /**
     * Loads default correlations filter data.
     *
     * @memberof CorrelationsService
     */
    public loadDefaultFilter(concept?: Concept, deliverableViews?: Array<DeliverableView>, view ?: String): Observable<CorrelationsFilter> {
        const defaultFilter: CorrelationsFilter = Object.assign({}, defaultCorrelationsFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.CORRELATIONS.type);
        const subgroups$ = this.subgroupService.getReportSubgroups();
        return zip(report$, concepts$, subgroups$).pipe(switchMap(result => {
            const keyMeasures = this.getKeyMeasures( view, deliverableViews);
            const compareQuadMap = defaultFilter.compare.find(it => it.id === CompareView.QUAD_MAP);
            const compareDataTable = defaultFilter.compare.find(it => it.id === CompareView.DATA_TABLE);
            const hasDataTableItems = keyMeasures.dataTable.length > 0;

            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            defaultFilter.concepts = this.getConceptFilters(concepts);
            defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            defaultFilter.show = Object.assign({}, defaultFilter.show, {keyMeasures});
            /**
             * NOTE: analysts can un-select dataTable from deliverable configuration. In such case we would auto
             * select quad map in the filter options in correlations deliverable.
             * */
            let isSelectedDescriptor = Object.getOwnPropertyDescriptor(compareDataTable, 'isSelected') || {};
            if (Boolean(isSelectedDescriptor.writable)) {
                compareDataTable.isSelected = hasDataTableItems;
            }
            isSelectedDescriptor = Object.getOwnPropertyDescriptor(compareQuadMap, 'isSelected') || {};
            if (Boolean(isSelectedDescriptor.writable)) {
                compareQuadMap.isSelected = !hasDataTableItems;
            }
            return of(defaultFilter);
        }));
    }

    /*
    *  Loads keyMeasures based on report view
    *  Correlation data is a single view data 'dataTable', used for both compare views
    */
    getKeyMeasures(viewName, deliverableViews): Record<string, Array<FilterItem>> {
        viewName ? viewName : viewName = 'dataTable';
        const deliverableType = DeliverableType.CORRELATIONS.type;
        const deliverable = deliverableViews.find(d => d.type === deliverableType);
        viewName = this.setDeliverableViewType(deliverable.metaInfo, viewName);
        this.viewName = viewName;
        const measures = deliverable.metaInfo;
        const keys = ['dataTable', 'quadMap'];
        const keyMeasuresPair = {};
        keys.forEach(key => {
            const keyMeasures: Array<FilterItem> = [];
            Object.keys(measures[key]).map((measure, index) => {
                keyMeasures.push(<FilterItem>{
                    name: measures[key][measure],
                    id: measure,
                    isSelected: true,
                    position: index
                });
            });
            keyMeasuresPair[key] = keyMeasures;
        });
        return keyMeasuresPair;
    }

    public fetchCompareData(concept, deliverableViews, view) {
        this.getDefaultFilter(concept, deliverableViews, view);
    }

    private computeKeyMeasureId(measure): string {
        let measureId = measure.split(' ')[0].toLowerCase();
        if (measureId !== 'purchase') {
            return measureId;
        }

        if (measure.includes(PurchaseEnum.PUBLIC)) {
            measureId = measureId + PurchaseEnum.PUBLIC;
        } else if (measure.includes(PurchaseEnum.RETAIL)) {
            measureId = measureId + PurchaseEnum.RETAIL;
        }

        return measureId;
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @returns {Array<FilterItem>}
     * @memberof CorrelationsService
     */
    private getConceptFilters(concepts: Array<Concept>): Array<FilterItem> {
        const conceptFilterItems: Array<FilterItem> = [];
        for (const item of concepts) {
            conceptFilterItems.push({
                name: item.name,
                id: item.exerciseConceptId,
                isSelected: false,
                position: item.position
            });
        }
        conceptFilterItems[0].isSelected = true;
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @returns {Array<FilterItem>}
     * @memberof CorrelationsService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        for (const item of subgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position
            });
        }
        subgroupFilterItems[0].isSelected = true;
        return subgroupFilterItems;
    }

    getAttributeRatingRankValue(rank) {
        switch (rank) {
            case 1:
                return 'Below Avg.';
            case 2:
                return 'Average';
            case 3:
                return 'Above Avg.';
            default:
                return '';
        }
    }

    getAttributeRatingRankFromValue(value) {
        switch (value) {
            case 'Below Avg.':
                return 1;
            case 'Average':
                return 2;
            case 'Above Avg.':
                return 3;
            default:
                return 0;
        }
    }

    /**
     * Returns a tuple of colheaders and correlation data for angular material table based on showFilter.
     *
     * @param {CorrelationsFilter} filter
     * @returns [{<any>}] - filtered data.
     */
    getFilteredData(filter, data) {
        const filteredData = {};
        let extractedData = [];
        // check if any of the filters are changed
        if (!filter.aboveAverage || !filter.average || !filter.belowAverage) {
            filteredData['statements'] = data.statements.map(item => {
                extractedData = item.statementData;
                if (!filter.aboveAverage) {
                    extractedData = extractedData.filter(statement => statement.attributeRank !== 3);
                }
                if (!filter.average) {
                    extractedData = extractedData.filter(statement => statement.attributeRank !== 2);
                }
                if (!filter.belowAverage) {
                    extractedData = extractedData.filter(statement => statement.attributeRank !== 1);
                }
                item.statementData = extractedData;
                return item;
            });
            return filteredData;
        } else {
            return data;
        }
    }

    /**
     * Returns mean value with attribute rating rank.
     */
    setAttribute(data) {
        let attributeRatingValue = '';
        attributeRatingValue = data.attributeMean;
        return this.getAttributeRatingRankValue(data.attributeRank) + ' - ' + attributeRatingValue;
    }

    public getDefaultFilter(concept?: Concept, deliverableViews?: Array<DeliverableView>, view ?: String): void {
        const defaultFilter: CorrelationsFilter = Object.assign({}, defaultCorrelationsFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.CORRELATIONS.type);
        const subgroups$ = this.subgroupService.getReportSubgroups();
        zip(report$, concepts$, subgroups$).pipe(take(1)).subscribe(result => {
            const keyMeasures = this.getKeyMeasures( view, deliverableViews);
            const compareQuadMap = defaultFilter.compare.find(it => it.id === CompareView.QUAD_MAP);
            const compareDataTable = defaultFilter.compare.find(it => it.id === CompareView.DATA_TABLE);
            const hasDataTableItems = keyMeasures.dataTable.length > 0;

            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            defaultFilter.concepts = this.getConceptFilters(concepts);
            defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
            defaultFilter.show = Object.assign({}, defaultFilter.show, {keyMeasures});
            /**
             * NOTE: analysts can un-select dataTable from deliverable configuration. In such case we would auto
             * select quad map in the filter options in correlations deliverable.
             * */
            let isSelectedDescriptor = Object.getOwnPropertyDescriptor(compareDataTable, 'isSelected') || {};
            if (Boolean(isSelectedDescriptor.writable)) {
                compareDataTable.isSelected = hasDataTableItems;
            }
            isSelectedDescriptor = Object.getOwnPropertyDescriptor(compareQuadMap, 'isSelected') || {};
            if (Boolean(isSelectedDescriptor.writable)) {
                compareQuadMap.isSelected = !hasDataTableItems;
            }
            this.filterService.update(defaultFilter);
        });
    }

  /**
   * Returns a tuple of colheaders and correlation data for angular material table.
   *
   * @param {CorrelationsFilter} filter
   * @param {CorrelationsDeliverableView} correlations
   * @returns [{<any>}] - correlation data.
   */
  public fetchRecords(filter: CorrelationsFilter, correlations: CorrelationsDeliverableView) {
    const selectedConcept = filter.concepts.find(concept => concept.isSelected);
    const selectedSubgroup = filter.subgroups.find(subgroup => subgroup.isSelected);
    const data = [];
    let rowData;
    const filteredData = this.getFilteredData(filter.show, correlations);
    filteredData.statements.forEach((statement) => {
      if (statement.statementData.length) {
        const statementData = statement.statementData;
        const statementDataMatch = statementData.find((dataMatch) => dataMatch.conceptId === selectedConcept.id && dataMatch.segmentId === selectedSubgroup.id);
        rowData = {};
        rowData.statement = statement.label;
         if (statementDataMatch) {
          rowData.attribute = this.setAttribute(statementDataMatch);
          rowData.CADV3 = statementDataMatch.advantageRank;
          rowData.CBEL = statementDataMatch.credibilityRank;
          rowData.CNAD = statementDataMatch.uniquenessRank;
          rowData.CPI = statementDataMatch.purchaseIntentRank;
          rowData.CPI1 = statementDataMatch.purchaseIntentRetailRank;
          rowData.CPI2 = statementDataMatch.purchaseIntentPublicRank;
          rowData.CPV = statementDataMatch.priceRank;
          rowData.CREL3 = statementDataMatch.needDesireRank;
          rowData.CADV3Value = statementDataMatch.advantageValue;
          rowData.CBELValue = statementDataMatch.credibilityValue;
          rowData.CNADValue = statementDataMatch.uniquenessValue;
          rowData.CPIValue = statementDataMatch.purchaseIntentValue;
          rowData.CPI1Value = statementDataMatch.purchaseIntentRetailValue;
          rowData.CPI2Value = statementDataMatch.purchaseIntentPublicValue;
          rowData.CPVValue = statementDataMatch.priceValue;
          rowData.CREL3Value = statementDataMatch.needDesireValue;
          rowData.attributeMean = statementDataMatch.attributeMean;
          data.push(rowData);
         }
      }
    });
    return data;
  }

    setDeliverableViewType(metaInfo, viewName) {
        if (Object.keys(metaInfo.dataTable).length !== 0 && Object.keys(metaInfo.quadMap).length !== 0) {
            return viewName;
        } else if (Object.keys(metaInfo.dataTable).length === 0) {
            return 'quadMap';
        } else {
            return 'dataTable';
        }
    }
}
