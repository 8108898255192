import {ProductDeliverableType} from '@platform/models/product-deliverable-type';
import {DeliverableType as AllDeliverableTypes} from '@app/deliverables/deliverable-type.enum';

const deliverables = [ 'ACTIVATION_PROFILE_LEGACY'];
export const DeliverableType: { [key: string]: ProductDeliverableType } = deliverables.reduce((acc, deliverableName) => {
    if (AllDeliverableTypes[deliverableName]) {
        acc[deliverableName] = AllDeliverableTypes[deliverableName];
    }
    return acc;
}, {});
