import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  StrengthWatchoutsConcept
} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
@Component({
  selector: 'sw-click-data-concept-row',
  templateUrl: './concept-row.component.html',
  styleUrls: ['./concept-row.component.scss']
})
export class ConceptRowComponent implements OnInit, OnChanges {

  @Input() concept: StrengthWatchoutsConcept;

  @Input() concepts: StrengthWatchoutsConcept[];

  @Input() index: number;

  @Input() filter: StrengthWatchoutsFilter;

  @Input() aiZoneSummaries: boolean = false;

  @Input() aiSubgroupSummaries: any = null;

  currentZone: string;

  activeZone: string;

  selectedZoneImageLabel: string;

  activatedZoneImageLabel: string;

  wideConcept: boolean = false;

  isCarouselDisplay: boolean = false;

  imageHeight: String;

  constructor() {}

  ngOnInit() {
    this.wideConcept = this.concept && this.concept.imageWidth > 600;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isCarouselDisplay = this.filter.show.languages.filter(x => x.id === 'all' && x.isSelected === true).length  > 0;
    this.imageHeight = this.concept && this.concept.imageHeight == 450 ? "Landscape":(this.concept && this.concept.imageHeight == 540 ? "Widescreen" : "Portrait");
  }

  receiveZoneHover($event: string) {
    this.currentZone = $event;
  }

  receiveZoneClicked($event){
    const clickedZone = $event
    this.activeZone = clickedZone;
  }

  receiveImageZoneClicked($event){
    const activatedZone = $event
    this.activatedZoneImageLabel = activatedZone ? activatedZone.label : "";
  }

  receiveImageHover($event) {
    const zoneHovered = $event
    this.selectedZoneImageLabel = zoneHovered ? zoneHovered.label : "";
  }
}
