import { AppConfigService } from '@app/core/config/app-config.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * Invitation service provides operations to notify users
 *
 * @example
 * constructor(private invitationService: InvitationService) { }
 *
 * @export
 * @class InvitationService
 */
@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  /**
   * Creates an instance of InvitationService.
   *
   * @constructor
   * @param {HttpClient} httpClient The HttpClient.
   * @memberof InvitationService
   */
  constructor(
    private httpClient: HttpClient,
    private cs: AppConfigService) { }

  /**
   * Notify users about the results that are shared
   *
   * @public
   * @param {entityDetails} entityDetails details of invitation
   * @param {string} reportId The report id.
   * @returns {Observable<any>} observable
   * @memberof InvitationService
   */
  public sendInvitation(entityDetails): Observable<any> {
    const url = `${this.cs.config.reporting.url}/shareInvitations/`;
    return this.httpClient.post(url, entityDetails);
  }
  
}