import {Action, createReducer, on} from '@ngrx/store';
import {addOrganizations} from "@platform/store/organization/orgaization.actions";
import {OrganizationState} from "@platform/store/organization/organization.state";
import {
  organizationAdapter,
  initialState
} from "@platform/store/organization/organization.adapter";


/**
 * Reducer for all concept actions.
 */
const organizationReducer = createReducer(
  initialState,
  on(addOrganizations, (state, {organizations}) => {
    return organizationAdapter.addMany(organizations, state);
  })
);

/**
 * Organization `NgRx` reducer.
 *
 * @export
 * @param {OrganizationState} state
 * @param {Action} action
 * @returns {OrganizationState}
 */
export function reducer(state: OrganizationState | undefined, action: Action): OrganizationState {
  return organizationReducer(state, action);
}
