<div class="reporting-container" *ngIf="report">
    <ns-flyout-menu [flyoutEvents]="flyoutEvents">
        <ng-template #sidenavContent>
            <div class="fluid-container air-container padding-0" [ngClass]="{'import-benchmark-page': isImportedBenchmarkRoute, 'import-benchmark-page-results':benchmarkService.importBenchmarkActiveView == 'searchResultContainer'}">
                <div class="" *ngIf="canView">
                    <div class="tabbable tabs-top bricklet-variant bricklet-variant-nested">
                        <ul class="nav nav-tabs">
                            <li *ngFor="let feature of features" class="">
                                <a [routerLink]="[feature.path]" routerLinkActive="active" class="tab-bricklet p-3"
                                   (click)="trackNavigation(feature.name)">{{feature.name}}</a>
                            </li>
                            <li class="report-actions">
                                <ul>
                                    <li *ngIf="canShare">
                                        <ns-share-results [report]="report" [userInfo]="userInfo" [collaboratedUser]="collaboratedUser" (showToast)="showShareSuccessToast()" (hideModal)="viewShareModal(false)"></ns-share-results>
                                    </li>
                                    <li *ngIf="isInternalUser && isDeliverablesRoute" rel="tooltip" matTooltipPosition="above" matTooltip="{{(defaultCardsViewExist ? 'platform.disable.reset' : 'platform.enable.reset') | translate }}">
                                        <button mat-button class="sif sif-reset" [disabled]="defaultCardsViewExist" (click)=resetCards()><span class="sif sif-reset reset-deliverable-order"></span></button>
                                    </li>
                                    <li *ngIf="isInternalUser && isForecastingRoute" rel="tooltip" matTooltipPosition="above" matTooltip="{{(defaultForecastingCardsViewExist ? 'platform.forecasting.disable.reset' : 'platform.forecasting.enable.reset') | translate }}">
                                        <button mat-button class="sif sif-reset" [disabled]="defaultForecastingCardsViewExist" (click)=resetForecastingCards()><span class="sif sif-reset reset-forecasting-order"></span></button>
                                    </li>
                                    <li *ngIf="canImportBenchmark && isInternalUser && canImportBenchmarkResultWithFlag && !isForecastingRoute && isDeliverablesSubRoute" rel="tooltip" matTooltipPosition="above" matTooltip="{{'platform.import.benchmark.results' | translate }}">
                                        <button mat-button class="sif sif-archive" (click)="navigateToImportBenchmark()"><span class="sif sif-archive import-benchmark"></span></button>
                                    </li>
                                    <li>
                                        <button class="sif sif-gear" *ngIf="isInternalUser" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'platform.report.settings' | translate }}" (click)="showFlyoutMenu()" id="report-settings" mat-button><span class="share-btn sif sif-gear"></span></button>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
                <div class="level-1-help" *ngIf=!canView>
                    <div class="sif-container">
                        <span class="sif sif-results level-1-help-icon"></span>
                        <h2>{{ 'platform.level.one.help.title' | translate }}</h2>
                        <p [innerHTML]=" 'platform.level.one.help.content' | translate "></p>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #sidenav>
            <ns-report-settings
                    [report]="report"
                    [userInfo]="userInfo"
                    [showMainMenu]="showMainMenu"
                    [defaultCardsViewExist]="defaultCardsViewExist"
                    (flyoutMenuClose)="onFlyoutMenuClose()"
                    (onFlyoutMenuClick)="onFlyoutMenuClick($event)"
                    [features]="features"></ns-report-settings>
        </ng-template>
    </ns-flyout-menu>
</div>

<!-- Comment Feature -->
<ns-comment-mode *ngIf="report" [userInfoServiceURL]="userInfoServiceURL" [hideLoggedUserInCollaboratorList]="true" [collaboratorsServiceURL]="collaboratorsServiceURL" [showCollaborators]="true"
                 [projectId]="report.projectId" [moduleName]=" 'platform.report.module' | translate " [commentsServiceURL]="commentsServiceURL"></ns-comment-mode>


<!-- Toaster to be displayed after sharing results successfully -->
<nd-toast success id="successToast" [text]=" 'platform.report.shared.successfully' | translate " #successToast></nd-toast>
