<div class="section section_concept col-12 insight-html-root">
  <h3 class="section-title concept-name-container">{{concept?.label}}</h3>
  <div class="row {{wideConcept ? 'wide-concept' : ''}} concept-container">
    <div class="concept-image-container">
      <p class="section-secondary concept-extra-description" *ngIf="isCarouselDisplay">*Zone box overlays are not available for All Languages. Select a language from the Languages filter to see the corresponding concept board and zones.</p>
      <sw-concept-image *ngIf="!isCarouselDisplay" [concept]="concept" [selectedClick]="currentClick" (zoneclick)="receiveZoneClick($event)"
                           (deselectAllZones)="receiveZoneDeselect($event)"></sw-concept-image>
      <carousel-concept-image *ngIf="isCarouselDisplay"  [concepts]="concepts" [filter]="filter" [concept]="concept" [index]="index"></carousel-concept-image>
    </div>
    <div class="concept-data-container {{isCarouselDisplay ? 'have-carousel' : ''}} {{isCarouselDisplay && imageHeight !== 'Widescreen' ? 'smallCarousal':''}}">
        <ng-container [ngSwitch]="selectedView">
            <sw-verbatims-search *ngSwitchDefault [concept]="concept" [selectedZone]="currentZoneClicked" (hoverEvent)="receiveClickHover($event)"
                                 [deselectZones]="deselectAllZones" ></sw-verbatims-search>
            <sw-verbatims-summary *ngSwitchCase="'summary'"[concept]="concept"> </sw-verbatims-summary>
        </ng-container>
    </div>
    <div class="clear-both"></div>
  </div>
</div>
