import { AppConfigService } from '@app/core/config/app-config.service';

/**
 * Initializes AppConfigService.
 *
 * @param appConfigService
 */
export function configFactory(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}
