<div class="deliverable-container survey-questions-content">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
        <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
            <ns-kebab-menu class="kabab-menu">
                <ul class="kebab-menu-list">
                    <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
                    <li *ngIf="isInternalUser"><span (click)="download($event)">{{ 'export.as.excel' | translate }}</span></li>
                </ul>
            </ns-kebab-menu>
            <span *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
            <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
        </ns-deliverable-nav-header>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()"></ns-deliverable-insight>
  </ng-container>


  <div id="deliverable-container">
    <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
      <!--SurveyQuestion filter-->
      <ns-survey-question-filter [concept]="scoreCardConcept"></ns-survey-question-filter>
    </div>
    <div class="sticky-dropdown deliverable-content">
      <div class="survey-dropdown-field">
        <mat-form-field appearance="fill" rel="tooltip" matTooltipPosition="above" matTooltip="{{selectedQuestionTootip}}" class="filtered-selection-dropdown">
          <mat-select [(ngModel)]="questionSelect" disableRipple (selectionChange)="selectQuestion($event.value)" panelClass="full-width-panel" [disableOptionCentering]="true">
            <mat-select-trigger>
              {{textWithEllipsis(selectedQuestionName, 140)}}
            </mat-select-trigger>
            <div class="filter-box">
              <div class="filter-input-container">
                <input matInput class="filter-input" [(ngModel)]="searchValue"
                      (keyup)="search($event.target.value)" placeholder="Enter text to search..."
                      (keydown)="$event.stopPropagation()"/>
                <span *ngIf="!searchValue" class="sif sif-filter filter-close"></span>
                <button class="filter-close" mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
                        (click)="searchClearHandler()">
                    <span class="sif sif-delete"></span>
                </button>
              </div>
              <div class="filtered-list">
                  <!-- Filtered options -->
                      <mat-option *ngFor="let question of filteredQuestionsList" [hidden]="!question.isShow" [value]="question.id" [title]="question.name">
                          {{ question.name }}
                      </mat-option>
                  <!-- Selected option -->
                  <mat-option *ngIf="questionSelect !== null" [value]="questionSelect" [title]="selectedQuestionName" [style.display]="'none'">
                      {{ selectedQuestionName }}
                  </mat-option>
                  <!-- No matching results found -->
                  <ng-container *ngIf="!filteredQuestionsList.length">
                      <mat-option disabled>
                          No matching results found
                      </mat-option>
                  </ng-container>
              </div>
          </div>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="deliverable-content">
      <p class="text-muted question-text" [innerHTML]="selectedQuestionText"></p>
    </div>
<!--    <div class="sticky-swipe-table-container">-->
<!--      &lt;!&ndash;Swipe &ndash;&gt;-->
<!--      <div class="col-12 swipe-container">-->
<!--        <ns-swipe-table class="pull-right" [tooltipDescription]="selectedView === 'Concepts' ? 'Concept' : 'Subgroup' " [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"-->
<!--                        (next)="nextPage()" (previous)="previousPage()"></ns-swipe-table>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row deliverable-content">
      <div class="col-12 sq-table-container deliverable-insight-survey-questions insight-html-root carousel-container">
        <ns-carousel-table class="carousel-panel" [tooltipDescription]="selectedView === 'Concepts' ? 'Concept' : 'Subgroup' " [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"
                           (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>
        <!--table-->
        <table id="survey-questions-table" class="col-12 highlighted-row-container deliverable-insight-survey-questions-table" mat-table matSortStart="asc" matSort [dataSource]="dataSource">
          <tr class="deliverable-insight-survey-questions-table-row" matColumnDef="test">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="statement-header deliverable-insight-survey-questions-table-head"></th>
            <td mat-cell *matCellDef="let element" class="statement deliverable-insight-survey-questions-table-data">{{element}}</td>
          </tr>
          <ng-container *ngFor="let colHeader of colHeaders; let i = index; trackBy: trackItem" matColumnDef="{{colHeader.name}}">
              <ng-container *ngIf="!colHeader.id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="statement-header deliverable-insight-survey-questions-table-head"></th>
                <td mat-cell *matCellDef="let element" class="statement text-left deliverable-insight-survey-questions-table-data"><span class="border-highlight"></span><span class="answer-text">{{element.answerText}}</span></td>
              </ng-container>
              <ng-container *ngIf="filter.show.aggregate && (colHeader.id == -1)">
                <th rel="tooltip" matTooltipPosition="above" matTooltip = "{{this.getSortOrder(colHeader.name)}}"  mat-header-cell *matHeaderCellDef mat-sort-header class="statement-header deliverable-insight-survey-questions-table-head" (click)="onClickHeader($event,colHeader.id,colHeader.name)">
                  <div class="margin-0 title-container margin-right-0-point-5">
                    <span>
                      <strong class="title bold">{{colHeader.name}}</strong>
                      <div class="margin-0 n-size margin-right-0-point-5">
                        <span class="text-muted">n={{colHeader?.size}}</span>
                      </div>
                    </span>
                    <span class="title-sort-arrow"></span>
                  </div>
                </th>
                <td mat-cell *matCellDef="let element" class="statement-chart deliverable-insight-survey-questions-table-data">
                  <span>{{element[colHeader.name]?.value }}</span>
                  <span class="label-sub-text text-muted small stat-value" *ngIf="filter.show.statTesting">{{element[colHeader.name].statTesting}}</span>
                </td>
              </ng-container>

              <ng-container *ngIf="(colHeader.id > 0)">
                <th rel="tooltip" matTooltipPosition="above" matTooltip = "{{this.getSortOrder(colHeader.name)}}" mat-header-cell *matHeaderCellDef mat-sort-header class="statement-header deliverable-insight-survey-questions-table-head" (click)="onClickHeader($event,colHeader.id,colHeader.name)">
                  <ns-survey-question-table-header class="survey-questions-table-header" [title]="colHeader.name" [index]="filter.show.aggregate ? i-2 : i-1" [id]="colHeader.id" [size]="colHeader.size"></ns-survey-question-table-header>
                </th>
                <ng-container *ngIf="filter.show.aggregate && filter.show.statTesting">
                <td mat-cell *matCellDef="let element" class="statement-chart deliverable-insight-survey-questions-table-data" [ngClass]="(element[colHeader.name].allOtherStat === 1) ? getAggregateClass(element['Aggregate'].actualValue, element[colHeader.name].actualValue):''">
                  {{element[colHeader.name]?.value }}
                  <span class="label-sub-text text-muted small stat-value" *ngIf="filter.show.statTesting">{{element[colHeader.name].statTesting}}</span>
                </td>
              </ng-container>
                <ng-container>
                <td mat-cell *matCellDef="let element" class="statement-chart deliverable-insight-survey-questions-table-data">
                  {{element[colHeader.name]?.value }}
                  <span class="label-sub-text text-muted small stat-value" *ngIf="filter.show.statTesting">{{element[colHeader.name].statTesting}}</span>
                </td>
                </ng-container>
              </ng-container>
            </ng-container>
            <!--Row Highlight-->
            <tr class="deliverable-insight-survey-questions-table-row deliverable-insight-survey-questions-row-highlight" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="answer-row deliverable-insight-survey-questions-table-row deliverable-insight-survey-questions-row-higlight-answer"
              mat-row
              *matRowDef="let row; columns: displayedColumns;"
              [ngClass]="row.color"
              (contextmenu)="onContextMenu($event, row)"
              (click)="onClick($event, row)">
            </tr>
        </table>
      </div>
    </div>
    <!--Footer legend-->
    <div class="row">
      <ns-survey-question-legend class="col-12 legend-container"></ns-survey-question-legend>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
