import { Injectable } from "@angular/core";
import { MatLegacyDialogConfig as MatDialogConfig} from "@angular/material/legacy-dialog";
import { ConfirmationDialogData } from "@app/common/confirmation-dialog/confirmation-dialog-data.model";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})
export class ConfirmationDialogService {
    constructor(private translateService: TranslateService) {}

    getDeleteConfirmationConfig(
        header: string,
        body: string
    ): MatDialogConfig<ConfirmationDialogData> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "650px";
        dialogConfig.data = {
            header: header,
            body: body,
            confirmButtonLabel: "DELETE",
            cancelButtonLabel: "CANCEL",
        };
        return dialogConfig;
    }

    getContinueWithoutSavingConfig(): MatDialogConfig<ConfirmationDialogData> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "650px";
        dialogConfig.data = {
            header: "Continue Without Saving?",
            body: "Are you sure you want to continue without saving? This will discard all changes and cannot be undone.",
            confirmButtonLabel: "DISCARD",
            cancelButtonLabel: "CANCEL",
        };
        return dialogConfig;
    }

}
