<div class="click-data-row section section_concept col-12 insight-html-root">
  <h3 class="section-title concept-name-container">{{concept.label}}</h3>

  <div class="row {{wideConcept ? 'wide-concept' : ''}} concept-container">
    <div class="concept-image-container">
      <p class="section-secondary concept-extra-description" *ngIf="isCarouselDisplay">*Zone box overlays are not available for All Languages. Select a language from the Languages filter to see the corresponding concept board and zones.</p>
      <sw-concept-image *ngIf="!isCarouselDisplay" [concept]="concept" [index]="index" [selectedZoneLabel]="currentZone"
                        [clickedActivatedZones]="activeZone" (zonehover)="receiveImageHover($event)" (zoneclick)="receiveImageZoneClicked($event)"></sw-concept-image>
      <carousel-concept-image *ngIf="isCarouselDisplay" [concepts]="concepts" [filter]="filter" [concept]="concept" [index]="index"></carousel-concept-image>
    </div>
    <div class="concept-data-container pr-10 {{isCarouselDisplay ? 'have-carousel': ''}} {{isCarouselDisplay && imageHeight!='Widescreen' ? 'smallCarousal':''}}">
      <sw-click-data-zone [concept]="concept" (zoneEvent)="receiveZoneHover($event)" [aiZoneSummaries]="aiZoneSummaries"
                          (zoneClicked)="receiveZoneClicked($event)" class=" " [selectedZoneImageLabel]="selectedZoneImageLabel"
                          [aiSubgroupSummaries]="aiSubgroupSummaries"
                          [activatedZoneImageLabel]="activatedZoneImageLabel" [filter]="filter"></sw-click-data-zone>
    </div>
  </div>
</div>
