import {BarChart, BarChartData} from '@products/shared/chart-horizontal-bar/bar-chart.model';
import {AttributesFilter, FilterItem} from '../models/filter.model';
import {AttributesDeliverableView, StatementDataItem} from '../models/attributes.model';
import {Injectable} from '@angular/core';
import {defaultBarChartOptions} from '../models/default-barchart-options';
import {defaultAttributeColors} from '../models/default-attribute-colors';
import * as numeral from 'numeral';
import {StringHelper} from '@platform/services/string-helper.service';
import {Metric} from '../models/metric.enum';
import { TranslateService } from '@ngx-translate/core';
import {CompareView} from '../models/compare-view-enum';
import {AttributesMetaInfo} from '../models/view-meta-info.model';


/**
 * `ChartDataService` has operations for data tranformation for
 * attributes chart.
 *
 * @export
 * @class ChartDataService
 */
@Injectable({
  providedIn: 'root'
})
export class ChartDataService {

  /**
   * Creates an instance of ChartDataService.
   *
   * @constructor
   * @param {FormatterService} formatterService
   * @param {TranslateService} translate
   * @memberof ChartDataService
   */
  constructor(
    private stringHelper: StringHelper,
    private translate: TranslateService) { }

  /**
   * Returns a tuple of colheaders and chart data for angular material table.
   *
   * @param {AttributesFilter} filter
   * @param {AttributesDeliverableView} attributes
   * @returns {[Array<any>, Array<any>]} - The tuple of column headers and chart data.
   * @memberof ChartDataService
   */
  public getChartData(filter: AttributesFilter, attributes: AttributesDeliverableView, viewMetaInfo: AttributesMetaInfo): [Array<any>, Array<any>] {
    const data = [];
    const isConcepts = this.selectedCompareFilterItems(filter.compare);
    const selectedConcepts = this.selectedFilterItems(filter.concepts);
    selectedConcepts.sort(function (a, b) {
      return a.position - b.position;
    });
    const selectedSubgroups = this.selectedFilterItems(filter.subgroups);
    const conceptsMap = this.getFilterItemMap(selectedConcepts);
    const subgroupsMap = this.getFilterItemMap(selectedSubgroups);
    let rowData, cellData, cellDataKey, colHeader;
    const nColHeaders = [];
    const colHeaders: Array<any> = isConcepts ? [...selectedConcepts] : [...selectedSubgroups];
    let sum: number;
    let statementId = 0;
    attributes.statements.forEach((statement, index) => {
      rowData = {};
      const filteredStatement = Object.keys(viewMetaInfo).length > 1 ? Object.values(viewMetaInfo.rowHighlights).find(x => x.rowId === statement.label) : null;
      rowData.statement = statement.label;
      statementId = statementId + 1;
      rowData.color = filteredStatement ? 'text-highlight ' + filteredStatement.color : '';
      statement.statementData.forEach((dataItem, i) => {
        cellData = {};
        cellDataKey = isConcepts ? conceptsMap[dataItem.conceptId] : subgroupsMap[dataItem.segmentId];
        cellData.barChart = this.getBarChart(filter, dataItem, statementId);
        sum = this.getDataItemsSum(filter, dataItem, statementId);
        cellData.sum = this.getFormattedValue(sum, filter);
        cellData.originalSum = sum;
        cellData.statTesting = isConcepts ? this.getStatTestingValueConcepts(filter, dataItem) : this.getStatTestingValueSubgroup(filter, dataItem);
        rowData[cellDataKey?.name] = cellData;
        if (index === 0 && !nColHeaders.find(col => col.id === cellDataKey.id)) {
          colHeader = colHeaders.find(ch => ch.id === cellDataKey.id);
          const newValue = Object.assign({}, colHeader, { size: dataItem.baseSize });
          nColHeaders.push(newValue);
        }
      });
      data.push(rowData);
    });
    statementId = 0;
    nColHeaders.sort(function (a, b) {
      return a.position - b.position;
    });
    nColHeaders.unshift({name: 'statement'});
    return [nColHeaders, data];
  }

  private selectedCompareFilterItems(items: Array<FilterItem>): boolean {
    const selectedItems = items ? items.filter(item => item.isSelected) : [];
    return !!selectedItems.find(item => item.id === CompareView.CONCEPT);
  }

  /**
   * Returns the bar chart object for the statement data item.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {StatementDataItem} statementDataItem
   * @returns {BarChart}
   * @memberof ChartDataService
   */
  private getBarChart(filter: AttributesFilter, statementDataItem: StatementDataItem, statementId: number): BarChart {
    const options: any = Object.assign({}, defaultBarChartOptions);
    let barChart: BarChart;
    const min = filter.show.percents.isSelected ? 0 : 1;
    const max = filter.show.percents.isSelected ? 1 : 5;
    const domain: {min: number, max: number} = {min, max};
    options.bar.domain = domain;
    options.bar.formatPattern = this.getFormatPattern(filter);
    options.bar.dataLabel = filter.show.dataLabels && !filter.show.mean;
    options.bar.dataLabelLine = filter.show.dataLabels && !filter.show.mean;
    options.bar.tooltip = filter.show.percents.isSelected && !filter.show.percents.topBox;
    barChart = {
      colors: Object.assign({}, defaultAttributeColors),
      options: options,
      series: [this.filterDataPoints(filter, statementDataItem, statementId)]
    };
    return barChart;
  }

  /**
   * Returns sum for the data item stats processed by the filter.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {StatementDataItem} statementDataItem
   * @returns {(number | string)}
   * @memberof ChartDataService
   */
  private getDataItemsSum(filter: AttributesFilter, statementDataItem: StatementDataItem, statementId: number): number {
    const filterStatement: BarChartData = this.filterDataPoints(filter, statementDataItem, statementId);
    const values = Object.values(filterStatement).map(item => item.value);
    const sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return sum;
  }

  /**
   * Returns formatted value based on the filter.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {boolean} [shouldFormat=true]
   * @returns {(number | string)}
   * @memberof ChartDataService
   */
   private getFormattedValue(sum: number | string, filter: AttributesFilter, shouldFormat: boolean = true): number | string {
    const pattern = this.getFormatPattern(filter);
    const result: number | string = shouldFormat ? numeral(sum).format(pattern) : sum;
    return result;
  }

  /**
   * Returns the stat testing label for the data item for concepts.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {StatementDataItem} dataItem
   * @returns {string}
   * @memberof ChartDataService
   */
  private getStatTestingValueConcepts(filter: AttributesFilter, dataItem: StatementDataItem): string {
    const percentsFilter = filter.show.percents;
    let conceptIds = [];
    let alphabets: Array<string>;
    const conceptFilterIds = filter.concepts
                                    .filter(concept => concept.isSelected)
                                    .map(concept => concept.id);
    conceptIds = filter.show.mean && dataItem.meanStat ? dataItem.meanStat : conceptIds;
    conceptIds = percentsFilter.isSelected && percentsFilter.topBox && dataItem.topStat ? dataItem.topStat : conceptIds;
    conceptIds = percentsFilter.isSelected && percentsFilter.topTwoBox && dataItem.topTwoStat ? dataItem.topTwoStat : conceptIds;
    conceptIds = percentsFilter.isSelected && percentsFilter.bottomThreeBox && dataItem.bottomThreeStat ? dataItem.bottomThreeStat : conceptIds;
    alphabets = conceptIds
                  .map(id => conceptFilterIds.indexOf(id))
                  .filter( index => index >= 0)
                  .map(index => this.stringHelper.alphabetSeries(index));
    return this.stringHelper.sortAlphabeticSeries(alphabets).join('');
  }

  /**
   * Returns the stat testing label for the data item for concepts.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {StatementDataItem} dataItem
   * @returns {string}
   * @memberof ChartDataService
   */
  private getStatTestingValueSubgroup(filter: AttributesFilter, dataItem: StatementDataItem): string {
    const percentsFilter = filter.show.percents;
    let subgroupIds = [];
    let alphabets: Array<string>;
    const subgroupFilterIds = filter.subgroups
      .filter(subgroup => subgroup.isSelected)
      .map(subgroup => subgroup.id);
    subgroupIds = filter.show.mean && dataItem.meanStatSubgroup ? dataItem.meanStatSubgroup : subgroupIds;
    subgroupIds = percentsFilter.isSelected && percentsFilter.topBox && dataItem.topStatSubgroup ? dataItem.topStatSubgroup : subgroupIds;
    subgroupIds = percentsFilter.isSelected && percentsFilter.topTwoBox && dataItem.topTwoStatSubgroup ? dataItem.topTwoStatSubgroup : subgroupIds;
    subgroupIds = percentsFilter.isSelected && percentsFilter.bottomThreeBox && dataItem.bottomThreeStatSubgroup ? dataItem.bottomThreeStatSubgroup : subgroupIds;
    alphabets = subgroupIds
      .map(id => subgroupFilterIds.indexOf(id))
      .filter( index => index >= 0)
      .map(index => this.stringHelper.alphabetSeries(index));
    return this.stringHelper.sortAlphabeticSeries(alphabets).join('');
  }
  /**
   * Filters a statement data item for data points to be added like mean, topThree.
   *
   * @private
   * @param {AttributesFilter} filter
   * @param {StatementDataItem} dataItem
   * @returns {BarChartData}
   * @memberof ChartDataService
   */
  private filterDataPoints(filter: AttributesFilter, dataItem: StatementDataItem, statementId: number): BarChartData {
    const options = filter.show;
    const result = {};
    const id = `${dataItem.conceptId}-${dataItem.segmentId}`;
    this.addDataPoint(Metric.MEAN, id, options.mean, dataItem.mean, result, filter, statementId);
    if (options.percents.isSelected) {
      const shouldAddStronglyAgree = options.percents.topBox || options.percents.topTwoBox || options.percents.all;
      const shouldAddAgree = options.percents.topTwoBox || options.percents.all;
      const shouldAddRest = options.percents.bottomThreeBox || options.percents.all;
      this.addDataPoint(Metric.STRONGLY_AGREE, id, shouldAddStronglyAgree, dataItem.stronglyAgree, result, filter, statementId);
      this.addDataPoint(Metric.AGREE, id, shouldAddAgree, dataItem.agree, result, filter, statementId);
      this.addDataPoint(Metric.NEITHER, id, shouldAddRest, dataItem.neither, result, filter, statementId);
      this.addDataPoint(Metric.DISAGREE, id, shouldAddRest, dataItem.disagree, result, filter, statementId);
      this.addDataPoint(Metric.STRONGLY_DISAGREE, id, shouldAddRest, dataItem.stronglyDisagree, result, filter, statementId);
    }
    return result;
  }

  /**
   * Adds a data point if the `shouldAdd` flag set to true.
   *
   * @private
   * @param {string} key
   * @param {string} id
   * @param {boolean} shouldAdd
   * @param {number} value
   * @param {*} data
   * @param {AttributesFilter} filter
   * @memberof ChartDataService
   */
  private addDataPoint(key: string, id: string, shouldAdd: boolean, value: number, data: any, filter: AttributesFilter, statementId: number): void {
    if (shouldAdd) {
      value = value ? value : 0;
      data[key] = {
        id: `${statementId}-${key}-${id}`,
        value: value,
        tooltip: this.getTooltip(key, value, filter)
      };
    }
  }

  /**
   * Returns tooltip text based on the filter and value.
   *
   * @param {string} metric the metric key
   * @param {number} value the value
   * @param {AttributesFilter} filter the filter
   * @private
   * @method
   */
  private getTooltip(metric: string, value: number, filter: AttributesFilter): string {
    let tooltip: string;
    const pattern: string = this.getFormatPattern(filter);
    const formatted: string = numeral(value).format(pattern);
    switch (metric) {
      case Metric.STRONGLY_AGREE:
       tooltip = this.translate.instant('shared.deliverables.attributes.tooltip.strongly.agree', { value: formatted});
       break;
      case Metric.AGREE:
       tooltip = this.translate.instant('shared.deliverables.attributes.tooltip.agree', { value: formatted});
       break;
      case Metric.NEITHER:
       tooltip = this.translate.instant('shared.deliverables.attributes.tooltip.neither', { value: formatted});
       break;
      case Metric.DISAGREE:
       tooltip = this.translate.instant('shared.deliverables.attributes.tooltip.disagree', { value: formatted});
       break;
      case Metric.STRONGLY_DISAGREE:
       tooltip = this.translate.instant('shared.deliverables.attributes.tooltip.strongly.disagree', { value: formatted});
       break;
      default:
        tooltip = '';
    }
    return tooltip;
  }

  /**
   * Returns numeraljs display pattern for the numeric value.
   *
   * @private
   * @param {AttributesFilter} filter
   * @returns {string}
   * @memberof ChartDataService
   */
  private getFormatPattern(filter: AttributesFilter): string {
    let pattern = '0';
    pattern = filter.show.oneDecimal ? '0.0' : pattern;
    pattern = filter.show.twoDecimal ? '0.00' : pattern;
    pattern = filter.show.percents.isSelected ? pattern + '%' : pattern;
    return pattern;
  }

  /**
   * Returns map of filter items.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {{[key: number]: FilterItem}}
   * @memberof ChartDataService
   */
  private getFilterItemMap(items: Array<FilterItem>): {[key: number]: FilterItem} {
    const itemsMap: {[key: number]: FilterItem } = {};
    items.forEach(item => {
      itemsMap[item.id] = item;
    });
    return itemsMap;
  }

  /**
   * Returns selected filter items.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {Array<FilterItem>}
   * @memberof ChartDataService
   */
  private selectedFilterItems(items: Array<FilterItem>): Array<FilterItem> {
    const selectedItems = items ? items.filter(item => item.isSelected) : [];
    return selectedItems;
  }

}
