import {Params} from '@angular/router';
import {RouterUrlState} from '@platform/store/state/router.state';
import {map, take} from 'rxjs/operators';
import {selectRouter} from '@platform/store/selectors/router.selectors';
import {Observable} from 'rxjs';
import {State} from '@platform/store/state/app.state';
import {Store, select} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    /**
     * RouterService contructor.
     *
     * @param store
     */
    constructor(private store: Store<State>,
                public router: Router) {
    }

    /**
     * Returns current URL from the store.
     *
     * @returns {string} url
     */
    public getCurrentURL(): string {
        let url: string;
        this.getRouterState().pipe(
            take(1),
        ).subscribe(state => {
            url = state.url;
        });
        return url;
    }

    /**
     * Returns end path of the url
     *
     * @returns {string} url
     */
    public getEndPath(): string {
        const url = this.getCurrentURL().split(/\/|\\/);
        return url[url.length - 1];
    }

    /**
     * Returns query param value.
     *
     * @param {string} key
     * @returns {string} value
     */
    public getQueryParam(key: string): string {
        const queryParams = this.getQueryParams();
        return queryParams[key];
    }

    /**
     * Returns path param value.
     *
     * @param {string} key
     * @returns {string} value
     */
    public getPathParam(key: string): string {
        const pathParams = this.getPathParams();
        return pathParams[key];
    }

    /**
     * Returns all query params.
     *
     * @returns {Params} params
     */
    public getQueryParams(): Params {
        const queryParams$ = this.getQueryParams$();
        let queryParams: Params;
        queryParams$.pipe(take(1)).subscribe(params => {
            queryParams = params;
        });
        return queryParams;
    }

    /**
     * Returns query params observable.
     *
     * @returns {Observable<Params>} params observable
     */
    public getQueryParams$(): Observable<Params> {
        const queryParams$ = this.getRouterState().pipe(
            map(state => state.queryParams)
        );
        return queryParams$;
    }

    /**
     * Returns all path params.
     *
     * @returns {Params} params
     */
    public getPathParams(): Params {
        const pathParams$ = this.getPathParams$();
        let pathParams: Params;
        pathParams$.pipe(take(1)).subscribe(params => {
            pathParams = params;
        });
        return pathParams;
    }

    /**
     * Returns path params observable.
     *
     * @returns {Observable<Params>} params observable
     */
    public getPathParams$(): Observable<Params> {
        const pathParams$ = this.getRouterState().pipe(
            map(state => state.params)
        );
        return pathParams$;
    }

    /**
     * Returns router state observable from the store.
     *
     * @returns {Observable<RouterUrlState>} Observable of RouterUrlState
     */
    public getRouterState(): Observable<RouterUrlState> {
        const routerState$ = this.store.pipe(select(selectRouter));
        return routerState$;
    }

    /**
     * Reloads app
     * @see https://javascript.plainenglish.io/angular-how-you-can-reload-refresh-a-single-component-or-the-entire-application-and-reuse-the-logic-c6e975a278c3
     * */
    public reload(self: boolean, urlToNavigateTo ?: string) {
        //skipLocationChange:true means dont update the url to / when navigating
        const url = self ? this.router.url : urlToNavigateTo;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([`/${url}`]);
        });
    }
}
