import { CaptureOptions } from './capture-options';

/**
 * Processor for Element type.
 */
export class TagProcessor {

  /**
   * Process elements.
   *
   * @param clone the cloned node.
   * @param original the original node.
   * @param options the options.
   */
  public process(clone: Element, original: Element, options: CaptureOptions): void {
    this.processImageTag(clone, original);
  }

  /**
   * Process image elements by replacing image path with dataURL.
   *
   * @param clone the clone element.
   * @param original the original element.
   * @private
   */
  private processImageTag(clone: Element, original: Element): void {
    // Check if image element.
    if (original.tagName.toLowerCase() !== 'img') {
      return;
    }
    // Build canvas.
    const imageElement = original as HTMLImageElement;
    const canvas = document.createElement('canvas');
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;
    // Get data URL
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageElement, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    clone.setAttribute('src', dataURL);
  }
}
