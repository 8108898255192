import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ns-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  /**
   * The title of the column header.
   *
   * @property
   * @type {string}
   * @memberof TableHeaderComponent
   */
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
