import { InsightState } from './../state/insight.state';
import { Insight } from '@platform/models/insight.model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

/**
 * Insights entity adapter.
 *
 * @export
 * @property {EntityAdapter<Insight>} insightAdapter - The insight adapter.
*/
export const insightAdapter: EntityAdapter<Insight> = createEntityAdapter<Insight>();

/**
 * Initial state.
 */
export const initialState: InsightState = insightAdapter.getInitialState({});
