import {createFeatureSelector, createSelector} from '@ngrx/store';
import {benchmarkAdapter} from './benchmark.adapter';
import {Benchmark} from "@platform/models/benchmark.model";
import {State} from "@platform/store/state/app.state";
import {BenchmarkState} from "@platform/store/benchmark/benchmark.state";

/**
 * Default selectors from adapter.
 *
 * @property
 */
const {
  selectEntities,
  selectAll
} = benchmarkAdapter.getSelectors();


/**
 * Benchmarks feature selector.
 *
 * @export
 * @property
 */
export const selectBenchmarksFeature = createFeatureSelector< BenchmarkState>('benchmarks');

export const selectBenchmarksState = createSelector(
  selectBenchmarksFeature,
  (state: BenchmarkState) => state
);

export const selectAllBenchmarks = createSelector(
  selectBenchmarksState,
  selectAll
);

export const selectReportBenchmarks = (reportId: string) => createSelector(selectAllBenchmarks, (benchmarks: Benchmark[]) => {
  return benchmarks.filter(it => it.reportId === reportId);
});
