/**
 * Filter name keys and values.
 *
 * @export
 * @property
 */
export const showText = {
  elementEngagement: 'elementEngagements',
  show: 'Show',
  options: 'Options',
  showAll: 'Show All',
  zones: 'Zones',
  likes: 'Likes',
  dislikes: 'Dislikes',
  languages: 'Languages',
  noSentiment: 'No Sentiment Selected'
};
