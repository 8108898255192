import {CompareView} from './compare-view-enum';
import {DeliverableViewType} from '@app/deliverables/reach-analysis/models/deliverable-view-type-enum';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';

/**
 * Default filter values for survey question.
 *
 * @export
 * @property
 */
export const defaultReachAnalysisFilter = {
    deliverableType: DeliverableType.REACH_ANALYSIS.type,
    deliverableViewType: DeliverableViewType.SUMMARY,
    basesLabel: null,
    userView: false,
    compare: [
        {
            name: 'Summary View of Top Combinations',
            id: CompareView.SUMMARY,
            isSelected: true,
            position: 1
        },
        {
            name: 'Detailed View of Top Combinations',
            id: CompareView.DETAILED,
            isSelected: false,
            position: 0
        },
        {
            name: 'Alternative Combinations',
            id: CompareView.ALTERNATIVE,
            isSelected: false,
            position: 2
        }],

    show: {
        noDecimal: true,
        oneDecimal: false,
        twoDecimal: false,
        allCombinations: true,
        combinationSets: [],
        upToTwentyAlternatives: true,
        allAlternatives: false,
        paging: 0,
        reload: true
    }
};
