import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Concept} from '@platform/models/concept.model';
import { Measures } from '@app/deliverables/activation-profile/models/activationProfile.model';
import {Report} from '@platform/models/report.model';

@Component({
  selector: 'ns-benchmark-concept-activation-profile',
  templateUrl: './benchmark-concept-activation-profile.component.html',
  styleUrls: ['./benchmark-concept-activation-profile.component.scss']
})
export class BenchmarkConceptActivationProfileComponent implements OnInit, OnChanges {

  @Input() report: Report;
  @Input() measureDataForConcept: Measures;
  @Input() concept: Concept;
  activationProfile: string;
  activationProfileSvg: string;

  constructor() {
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  initialize() {
    this.activationProfile = this.measureDataForConcept?.measureDescriptions?.activationProfile;
    this.activationProfileSvg = this.activationProfile?.toLowerCase().replace(/\s/g, '');
  }

}
