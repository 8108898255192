import { HeartBeatServiceImpl } from '@app/core/config/heart-beat.service';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService } from '@app/core/config/app-config.service';
import { NgModule, APP_INITIALIZER, ModuleWithProviders, InjectionToken } from '@angular/core';
import { configFactory } from '@app/core/config/app-config.initializer';
import { HeartBeatService } from '@basesstudio/ngx-components';
import { PendoService } from '@platform/services/pendo.service';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { NavigationService } from '@platform/services/navigation.service';
/**
 * Injection token for heartbeart service.
 */
export const HEART_BEAT_TOKEN = new InjectionToken<HeartBeatService>('HEART_BEAT_SERVICE');

@NgModule({
  providers: [
    {
      provide: HEART_BEAT_TOKEN,
      useClass: HeartBeatServiceImpl,
      deps: [ AppConfigService ]
    }
  ]
})
export class CoreModule {

  /**
   * `forRoot()` method returns a `ModuleWithProviders` object which provides
   * `root` modules with the shared service instances.
   *
   * @static
   * @returns {ModuleWithProviders}
   * @memberof CoreModule
   */
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AppConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: configFactory,
          deps: [AppConfigService],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        PendoService,
        MixpanelService,
        NavigationService
      ]
    };
  }
}
