import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfirmationDialogData} from "@app/common/confirmation-dialog/confirmation-dialog-data.model";

/**
 * Wraps common logic for showing generic confirmation dialog.
 * USAGE EXAMPLE:
 *
 * const dialogConfig = this.confirmationDialogService.getDeleteConfirmationConfig(this.translateService.instant('platform.forecasting.assumptions.delete.title'), this.translateService.instant(`platform.forecasting.assumptions.delete.description`));
 *         const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
 *         dialogRef.afterClosed().subscribe(value => {
 *             if (value == 'DELETE') {
 *                 this.removeAssumption(assumption);
 *             }
 *         });
 * */
@Component({
    selector: 'ns-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

    header: string;
    body: string;
    cancelButtonLabel: string;
    confirmButtonLabel: string;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
    }

    ngOnInit(): void {
        this.header = this.data.header ? this.data.header : 'Are you sure?';
        this.body = this.data.body;
        this.cancelButtonLabel = this.data.cancelButtonLabel ? this.data.cancelButtonLabel : 'Cancel';
        this.confirmButtonLabel = this.data.confirmButtonLabel ? this.data.confirmButtonLabel : 'Confirm';
    }

    onActionButtonClick(label: string): void {
        this.dialogRef.close(label);
    }

}
