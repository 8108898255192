import { DeliverableType } from "@products/restage/deliverable-type.enum";
import { DeliverableViewType } from "./deliverable-view-type-enum";

/**
 * Default filter values for interactions.
 *
 * @export
 * @property
*/
export const defaultInteractionsFilter = {
    deliverableType: DeliverableType.INTERACTIONS.type,
    deliverableViewType: DeliverableViewType.CONCEPT,
};
