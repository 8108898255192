import { DeliverableType } from '@products/quick-screen/deliverable-type.enum';
import { DeliverableViewType } from '@app/deliverables/sorting-matrix/models/deliverable-view-type.enum';

/**
 * Default filter values for sorting-matrix.
 *
 * @export
 * @property
*/
export const defaultSortingMatrixFilter = {
  deliverableType: DeliverableType.SORTING_MATRIX.type,
  deliverableViewType: DeliverableViewType.SORTING_MATRIX,
  weight: 65
};

