import { DeliverableType } from "@app/deliverables/deliverable-type.enum";
import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import {createOptions} from '@app/deliverables/reach-analysis/summary-view/chart/chart.options';
import {CombinationDataItem, ReachAnalysisDeliverableView} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';
import { TranslateService } from '@ngx-translate/core';
import {ReachAnalysisService} from '@app/deliverables/reach-analysis/services/reach-analysis.service';
import {FilterItem, ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {combineLatest, Subscription, take} from 'rxjs';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {ReachAnalysisMetaInfo} from '@app/deliverables/reach-analysis/models/view-meta-info.model';
import {FilterService} from '@platform/services/filter.service';
import {skipWhile} from "rxjs/operators";
import {ReportService} from "@platform/services/report.service";

@Component({
  selector: 'ns-reach-analysis-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reachAnalysis: ReachAnalysisDeliverableView;
  @Input() basesLabel: string;

  public totalVarieties: number;
  public legendHeader: { value, index };
  public compareView: string;
  public showLegend = false;
  public setNumberIsSame = false;
  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member ChartComponent
   */
  public subscriptions: Array<Subscription>;
  public filteredVarieties = [];

  public combinations: Array<CombinationDataItem>;
  public Highcharts = Highcharts;
  public chartOptions: any;
  private chart: any;
  public filter: ReachAnalysisFilter;
  public tableTitle: string;
  public hoverStateTableTitle: string;
  /**
   * Meta info for Attributes
   *
   * @type {AttributesDeliverableView}
   * @memberOf ChartComponent
   */
  public viewReachAnalysisMetaInfo: ReachAnalysisMetaInfo;

  constructor(
    private reachAnalysisService: ReachAnalysisService,
    private viewMetaInfoService: ViewMetaInfoService,
    private filterService: FilterService,
    private translate: TranslateService,
    private reportService: ReportService
  ) {this.subscriptions = []; this.filteredVarieties = []; }

  ngOnChanges(changes: SimpleChanges): void {
    const reachAnalysis = changes.reachAnalysis;
    if (reachAnalysis && reachAnalysis.currentValue) {
      this.refresh();
    }
  }

  refresh(): void {
    const filter$ = this.reachAnalysisService.getReachAnalysisFilter();
    const report$ = this.reportService.get();
    const deliverableType = DeliverableType.REACH_ANALYSIS.type;
    const viewMetaInfo$ = this.viewMetaInfoService.get<ReachAnalysisMetaInfo>(deliverableType);
    const subscription = combineLatest([filter$, viewMetaInfo$, report$])
      .pipe(take(1)).subscribe(([filter, viewMetaInfo, report]) => {
        this.combinations = this.reachAnalysisService.getSummeryViewFilteredCombinations(this.reachAnalysis.combinations, filter);
        this.filter = filter;
        this.compareView = filter.compare.find(it => it.isSelected === true).id;
        this.totalVarieties = filter.concepts.find(it => it.isSelected).varieties.length;
        this.filteredVarieties = [];
        this.getFilteredVarieties();
       // const metaInfo = this.reachAnalysisService.getMetaInfo(report, filter);
        this.tableTitle = filter.basesLabel === 'OBC' ? 'shared.deliverables.reach.filter.benefit.label' : 'shared.deliverables.reach.filter.varieties.label';
        this.hoverStateTableTitle = filter.basesLabel === 'OBC' ? 'shared.deliverables.deliverables.reach.tooltip.benefits' : 'shared.deliverables.deliverables.reach.tooltip.varieties';
        this.chartOptions = createOptions(this, filter.basesLabel);
        this.setNumberIsSame = this.combinations.length > 0 ? this.combinations[0].setNumber === this.combinations[1].setNumber : this.setNumberIsSame;
      });
    this.subscriptions.push(subscription);
  }

  ngOnInit(): void {
  }
  /**
   * IMPORTANT: This is the Highchart's callback function.
   * This function is called when the chart is created.
   * This is the function that set's the this.chart which is used by multiple functions within this component.
   * Saving the chart context locally in the component
   */
  chartCallback = chart => this.chart = chart;

  /**
   * Creates x axis
   * This function is called from the chartOptions.
   */
  getXAxisValue(context): number {
    return context.combinations?.length;
  }

  /**
   * Creates series for chart
   * This function is called from the chartOptions.
   */
  getSeriesData(context): Array<number> {
    const seriesData = [context?.combinations[0]];
    context.combinations?.forEach((combination) => {
      const value = parseFloat(String(combination.weight)) * 100;
      seriesData.push(value);
    });
    return seriesData;
  }

  /**
   * Creates tooltip for series
   * This function is called from the chartOptions.
   */
  showLegends(context, value: boolean, yValue: number, index: number): void {
    context.showLegend = value;
    context.filteredVarieties = [];
    const formattedValue = this.reachAnalysisService.getFormattedValue(yValue / 100, context.filter);
    if (value) {
      context.legendHeader = {
        value: formattedValue,
        index : index
      };
      context.getFilteredVarieties(index);
    } else {
      context.getFilteredVarieties();
    }
  }

  /**
   * this method will set Y axis minimum value
   * */
  getYAxisMin(context): number {
    return context.combinations?.reduce((min, p) => p.weight < min ? p.weight : min, context.combinations[0]?.weight) * 100;
  }

  /**
   * this method will filter out varieties according to the sets
   * */
  public getFilteredVarieties(setNumber: number = 0): void {
    let selectedPoint;
    if (setNumber) {
      selectedPoint = this.combinations.find(it => it.setNumber === setNumber)?.varieties;
      const sortedFilteredVarieties = selectedPoint.filter(it => it.position).sort(function (a, b) {
        return a.position - b.position;
      });
      sortedFilteredVarieties.forEach((variety) => {
        const selectedVarieties = this.filter.concepts.find(it => it.isSelected).varieties;
        this.filteredVarieties.push(selectedVarieties.find(it => it.position === variety.position).name);
      });
    } else {
      const filteredVarieties = this.filter.concepts.find(it => it.isSelected).varieties;
      const sortedFilteredVarieties = filteredVarieties.filter(it => it.isSelected === true).sort(function (a, b) {
        return a.position - b.position;
      });
      sortedFilteredVarieties.forEach((variety) => {
        if (this.filteredVarieties.indexOf(variety.name) < 0) {
          this.filteredVarieties.push(variety.name);
        }
      });
    }

  }

  /**
   * this method will load alternative combination view with specific set value
   * */
  public changeViews(index: number) {
    const filter = this.reachAnalysisService.selectSetNumber(this.filter, index);
    this.filterService.update({
      ...this.filter,
      compare: this.filter.compare.map((item: FilterItem) => {
        return {
          ...item,
          isSelected: (item.id === 'alternative-combination')
        };
      }),
      show: {
        ...filter.show,
        reload: true
      },
    });
  }

  /**
   * Cleanup hook.
   *
   * @memberOf SurveyQuestionComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
