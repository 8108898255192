<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <ns-benchmark-concept-card
      class="padding-bottom-1-point-5"
      [concept]="node.concept"
      [report]="report"
      [benchmark]="node.benchmark"
        [subgroup]="subgroup"
        [factorsDeliverableView]="factorsDeliverableView"
        [financialPotentialDeliverableView]="financialPotentialDeliverableView">
    </ns-benchmark-concept-card>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="project-header-bar">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <div matTreeNodeToggle class="project-header">
      <div class="project-name padding-left-1-point-5 pr-2-point-5">{{report.name}}</div>
      <div class="org-name pr-2-point-5 text-muted">{{organization.displayName}}</div>
      <div class="product-name pr-2-point-5 text-muted">{{report.productName}}</div>
      <div class="country pr-2-point-5 text-muted">{{reportCreatedDate}}</div>
    </div>
  </mat-tree-node>
</mat-tree>
