<div class="deliverables-title-block">
    <div class="title-left">
        <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'back.to.deliverables' | translate }}" (click)="toHome()" class="deliverables-home sif sif-card-view"></span>
        <span class="divider"></span>
        <div class="deliverables-pagination">
            <mat-paginator #MatPaginator class="pagination" [length]="length"
                           [hidePageSize]="true"
                           [pageSize]="1"
                           [pageIndex]="currentPage"
                           (page)="navigate($event)">
            </mat-paginator>
        </div>
        <span class="dropdown-section" #dropdownTitleSection>
          <button mat-button [matMenuTriggerFor]="menu"><span class="title" id="deliverable-title">{{currentDeliverableInfo?.label}}</span><span class="icon sif sif-chevron-s"></span></button>
          <mat-menu class="deliverable-header-dropdown" #menu="matMenu">
            <ul class="dropdown-content" [style.min-width.px]="dropdownTitleSection.offsetWidth">
                <li class="item" *ngFor="let deliverableInfo of viewableDeliverableInfos">
                    <a [routerLink]="getDeliverableRedirectPath(deliverableInfo.path)" (click)="navigateToDeliverable(deliverableInfo)">{{deliverableInfo?.label}}</a>
                </li>
            </ul>
          </mat-menu>
        </span>
        <span class="divider"></span>
        <span class="save-views"><ng-content select="[saveUserView]"></ng-content></span>
    </div>
    <div class="title-right">
        <ng-content></ng-content>
    </div>
</div>
