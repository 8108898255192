// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Environment configuration.
*/
export const environment = {
  name                : 'default',
  production          : false,
  projectNavigationServiceURL: '/projects/$projectId/projectNavigation',
  userNavigationURL: '/userNavigation',
  commentsURL: '/comments',
  collaboratorsURL: '/collaborators',
  userInfoURL: '/userinfo',
  reporting: {
    url: ""
  },
  studio: {
    url: ""
  },
  user: {
    url: ""
  },
  logout: {
    url: ""
  },
  heartbeat: {
    url: ""
  },
  pendoAPIKey: "",
  mixpanelKey: ""
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
