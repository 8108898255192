import {Dictionary} from '@ngrx/entity';
import {State} from '@platform/store/state/app.state';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {organizationAdapter} from "@platform/store/organization/organization.adapter";
import {OrganizationState} from "@platform/store/organization/organization.state";
import {Organization} from '@platform/models/organization.model';

/**
 * Default selectors from adapter.
 *
 * @property
 */
const {
  selectEntities,
  selectAll
} = organizationAdapter.getSelectors();

// Concept Selectors
/**
 * Concept feature selector.
 *
 * @export
 * @property
 */
export const selectOrganizationsFeature = createFeatureSelector< OrganizationState>('organizations');

/**
 * Concept selector.
 *
 * @export
 * @property
 */
export const selectOrganizationsState = createSelector(
  selectOrganizationsFeature,
  (state: OrganizationState) => state
);

/**
 * Concept entity selector.
 *
 * @export
 * @property
 */
export const selectOrganizationEntities = createSelector(
  selectOrganizationsState,
  selectEntities
);

/**
 * Selector for selecting organization by id.
 *
 * @export
 * @property
 */
export const selectOrganizationById = (id: string) =>
  createSelector(selectOrganizationEntities, (entities: Dictionary<Organization>) => {
    return entities[id];
  });

/**
 * Selector for selecting all concepts.
 *
 * @export
 * @property
 */
export const selectAllOrganizations = createSelector(
  selectOrganizationsState,
  selectAll
);
