import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {switchMap, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {UserService} from '@platform/services/user.service';

@Injectable()
export class FeatureFlagGuard  {

    constructor(
        private userService: UserService
    ) {
    }

    /**
     * User who is Logged in will be verified if there is flag specific to the feature and navigation will be allowed if have valid flag.
     * @param route
     */
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.userService.getUser().pipe(take(1)).pipe(switchMap(userinfo => {
            const {data: {featureFlag}} = route;
            const isRouteAllowedWithFlag = userinfo.featureFlags.includes(featureFlag);

            // Based on featureFlag user will be allowed to navigate to the route.
            return of(isRouteAllowedWithFlag);
        }));
    }
}
