
/**
 * `QuadMapPlotData`
 *
 * @export
 * @class QuadMapPlotData
 */
export class QuadMapPlotData {
  /**
   * axis plot data for both x and y axes.
   *
   * @type {AxisPlotData>}
   * @memberof QuadMapPlotData
   */
  axisPlotData: AxisPlotData;

  /**
   * coordinate point data for each data point.
   *
   * @type {PlotDataPoint}
   * @memberof PlotDataPoint
   */
  dataPoints: Array<PlotDataPoint>;
}

/**
 * `AxisPlotData`
 *
 * @export
 * @class AxisPlotData
 */
export class AxisPlotData {
  /**
   * average xValue which is mean of correlation mean.
   *
   * @type {number}
   * @memberof AxisPlotData
   */
  xDividerAxis: number;

  /**
   * average yValue which is mean of attribute mean.
   *
   * @type {number}
   * @memberof AxisPlotData
   */
  yDividerAxis: number;

  /**
   * Coordinate values for plotting the chart boundaries.
   *
   * @type {AxisValue}
   * @memberof AxisValue
   */
  axisValue: AxisValue;
}

/**
 * `AxisValue`
 *
 * @export
 * @class AxisValue
 */
export class AxisValue {
  /**
   * minimum x value of all the coordinates
   *
   * @type {number}
   * @memberof AxisValue
   */
  xMin: number;

  /**
   * maximum x value of all the coordinates
   *
   * @type {AxisValue}
   * @memberof AxisValue
   */
  xMax: number;

  /**
   * minimum y value of all the coordinates
   *
   * @type {AxisValue}
   * @memberof AxisValue
   */
  yMin: number;

  /**
   * maximum y value of all the coordinates
   *
   * @type {AxisValue}
   * @memberof AxisValue
   */
  yMax: number;
}

/**
 * `PlotDataPoint`
 *
 * @export
 * @class PlotDataPoint
 */
export class PlotDataPoint {
  /**
   * question name
   *
   * @type {string}
   * @memberof PlotDataPoint
   */
  questionName: string; //name

  /**
   * label for the coordinate value
   * mapped with statement text
   *
   * @type {string}
   * @memberof PlotDataPoint
   */
  label: string; //name

  /**
   * x value of the coordinate
   * mapped with selected key measure value of the statement
   *
   * @type {number}
   * @memberof PlotDataPoint
   */
  x: number;

  /**
   * y value of the coordinate
   * mapped with attribute mean value of the statement
   *
   * @type {number}
   * @memberof PlotDataPoint
   */
  y: number;

  /**
   * optional parameter to be added for chart from x value
   *
   * @type {number}
   * @memberof PlotDataPoint
   */
  consequence?: number;

  /**
   * optional parameter to be added for chart from y value
   *
   * @type {number}
   * @memberof PlotDataPoint
   */
  value?: number;

  /**
   * baseSize of the statement
   *
   * @type {number}
   * @memberof PlotDataPoint
   */
  baseSize: number;

  /**
   * correlation rank of selected key measure
   *
   * @type {number}
   * @memberof PlotDataPoint
   */
  rank: number;

  /**
   * type can be Concept/Product
   *
   * @type {string}
   * @memberof PlotDataPoint
   */
  type: string;

  /**
   * quadrant name in which the statemnent goes
   *
   * @type {number}
   * @memberof PlotDataPoint
   */
  quadrantName: string;
  /**
   * unique id to plot lines
   */
  id: number;
}
