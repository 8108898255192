import { Directive, HostListener, Input, OnInit, ElementRef, ComponentRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConceptsTooltipComponent } from '../concepts-tooltip/tooltip.component';
import { TranslateService } from '@ngx-translate/core';
import { FactorsMapping } from '@app/deliverables/factors/models/factors-mapping';

@Directive({
  selector: '[nsFactorsTooltipDirective]',
})
export class ConceptsTooltipDirective implements OnInit, OnDestroy {

  @Input() conceptsTooltip;

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private translate: TranslateService
    ) {
   }

   ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      }]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    const tooltipRef: ComponentRef<ConceptsTooltipComponent> = this.overlayRef.attach(new ComponentPortal(ConceptsTooltipComponent));
    tooltipRef.instance.code = this.conceptsTooltip.code;
    tooltipRef.instance.text = this.conceptsTooltip.tooltip;
    tooltipRef.instance.color = this.conceptsTooltip.color;
    for(const data in FactorsMapping) {
      if(FactorsMapping[data].code === this.conceptsTooltip.code) {
        tooltipRef.instance.factorName = this.translate.instant(FactorsMapping[data].factorText);
      }
    }
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }

  ngOnDestroy() {
    this.overlayRef.dispose()
  }

}
