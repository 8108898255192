import { ProductService } from '@app/platform/services/product.service';

export abstract class ProductServiceFactory {

  /**
   * Returns product service instance based on the product type.
   *
   * @returns {ProductService}
   */
  public abstract getService(): ProductService;
}
