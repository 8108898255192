<div id="attributes-legend" *ngIf="filter">
  <div id="legend-mean" *ngIf="filter.show.mean" class="section text-center">
    <div class="legend">
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.mean.strongly.disagree' | translate }}</span>
    </div>
    <div class="legend">
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.mean.disagree' | translate }}</span>
    </div>
    <div class="legend">
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.mean.neither' | translate }}</span>
    </div>
    <div class="legend">
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.mean.agree' | translate }}</span>
    </div>
    <div class="legend">
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.mean.strongly.agree' | translate }}</span>
    </div>
  </div>

  <div id="legend-percents" *ngIf="filter.show.percents.isSelected && filter.show.barCharts" class="section text-center">
    <div class="legend" *ngIf="filter.show.percents.topBox || filter.show.percents.topTwoBox || filter.show.percents.all">
      <span class="rounded-square strongly-agree"></span>
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.percents.strongly.agree' | translate }}</span>
    </div>
    <div class="legend" *ngIf="filter.show.percents.topTwoBox || filter.show.percents.all">
      <span class="rounded-square agree"></span>
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.percents.agree' | translate }}</span>
    </div>
    <div class="legend" *ngIf="filter.show.percents.bottomThreeBox || filter.show.percents.all">
      <span class="rounded-square neither"></span>
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.percents.neither' | translate }}</span>
    </div>
    <div class="legend" *ngIf="filter.show.percents.bottomThreeBox || filter.show.percents.all">
      <span class="rounded-square disagree"></span>
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.percents.disagree' | translate }}</span>
    </div>
    <div class="legend" *ngIf="filter.show.percents.bottomThreeBox || filter.show.percents.all">
      <span class="rounded-square strongly-disagree"></span>
      <span class="space-left-sm">{{ 'shared.deliverables.attributes.legend.percents.strongly.disagree' | translate }}</span>
    </div>
  </div>

  <div id="legend-footnote" *ngIf="filter.show.statTesting" class="section text-center footer-legend-text">
    <span>{{ 'shared.deliverables.attributes.legend.footnote.stat.testing' | translate }}</span>
  </div>
</div>
