<mat-card id="{{'word-cloud-' + elementId}}" class="word-cloud-card word-cloud-export-png" [ngClass]="{'deliverable-insight-word-cloud': index === 0, 'insight-html-root' : index === 0 }">
    <mat-card-header class="card-header deliverable-insight-wc-card-header">
        <mat-card-title class="title deliverable-insight-wc-card-title">
            {{header}}
        </mat-card-title>
        <div class="translation-dropdown deliverable-insight-wc-translations">
            <img [src]="imagePath" class="translations-icon" alt="Translations Icon">
            <ns-dropdown class="filter-item" [dropDownData]="translationsListData"
            (selectionChange)="selectTranslation($event.data)">
            </ns-dropdown>
        </div>
    </mat-card-header>
    <mat-card-content class="deliverable-insight-wc-card-content">
        <div class="word-cloud-content deliverable-insight-wc-content" id="{{'word-cloud-container-'+index}}">
            <div class="word-cloud deliverable-insight-wc-map">
                <ns-wordcloud-map
                    [wordCount]="wordCount"
                    [elementId]="elementId">
                </ns-wordcloud-map>
            </div>
            <div class="verbatims-list deliverable-insight-wc-verbatims">
                <ns-search class="verbatims-search deliverable-insight-wc-verbatims-search" [arrayOfStrings]="verbatimsList" [mixpanelLabel]="mixpanelLabel" (searchedData)="getFilteredVerbatims($event)" (searchText)="getSearchText($event)"></ns-search>
                <ns-verbatims-list class="verbatims-table deliverable-insight-wc-verbatims-table" [searchText]="searchText" [verbatims]="filteredVerbatims" [maxHeight]="500"></ns-verbatims-list>
            </div>
        </div>
    </mat-card-content>
</mat-card>
