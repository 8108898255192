import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { AppConfigService } from '@app/core/config/app-config.service';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Event, ResolveStart, Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public currentUrl: string;

  constructor(
    private router: Router, private appConfigService: AppConfigService) {
    router.events.subscribe((event: Event) => {
      if (event instanceof ResolveStart) {
        this.currentUrl = event.url;
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerKey = 'X-Requested-With';
    const headerValue = 'XMLHttpRequest';
    let targetUrl = window.location.href;
    let path = this.currentUrl;
    if (path) {
      path = this.currentUrl.indexOf('/') === 0 ? this.currentUrl.substring(1) : this.currentUrl;  // replace first '/' if exists
    }
    if (window.location.href.endsWith('#/')) {
      targetUrl = `${targetUrl}${path}`;
    }

    console.log('Cloning request in interceptor');
    request = request.clone({
      withCredentials: true,
      headers: request.headers.set(headerKey, headerValue).set('targetUrl', targetUrl).set('Access-Control-Expose-Headers', ['targetUrl'])
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        const currentURL = encodeURI(window.location.href);
        if (error.status === 401) {          
          const casUrl = error.headers.get('casLoginUrl');
          window.location.href = `${casUrl}&service=${currentURL}`;
          return of(error as any);
        } else if (error.status === 403) {
          if (!currentURL.includes('import-benchmark-results'))
            window.location.href = this.appConfigService.config.studio.url;
        } else {
          console.log(`error status == ${error.status}`);
        }
        return throwError(error);
      })
    );
  }
}
