import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ns-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Output() searchText = new EventEmitter();
  @Output() typeAheadText = new EventEmitter();
  @Input() searchInputValue = '';
  @Input() appendPlaceholder = '';
  constructor() {}

  ngOnInit(): void {}

  searchHandler(event): void {
    if (event.relatedTarget?.name !== 'search-delete-icon') {
      this.searchText.emit(this.searchInputValue);
    } else {
      event.target.focus();
      this.searchInputValue = '';
      this.typeAheadText.emit(this.searchInputValue);
    }
  }

  typeAheadHandler(): void {
    this.typeAheadText.emit(this.searchInputValue);
  }

  enterHandler(event: any): void {
    if (event.keyCode === 13) {
      this.searchText.emit(this.searchInputValue);
    }
  }

  clear(): void {
    this.searchInputValue = '';
    this.searchText.emit(this.searchInputValue);
    this.typeAheadText.emit(this.searchInputValue);
  }

}
