import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Report} from "@platform/models/report.model";
import {Archive} from "@platform/models/archive.model";
import {MatLegacyTable as MatTable} from "@angular/material/legacy-table";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {ArchiveService} from "@platform/services/archive.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";


@Component({
  selector: 'ns-archive-settings',
  templateUrl: './archive-settings.component.html',
  styleUrls: ['./archive-settings.component.scss']
})
export class ArchiveSettingsComponent implements OnInit {

  @Input() report: Report;
  @Input() archive: Archive;

  title: string;
  public dataSource: { archivalDate: string; projectName: string; toArchive: boolean }[];
  @ViewChild('table') table: MatTable<Archive>;
  displayedColumns: string[] = ['projectName', 'toArchive', 'archivalDate'];
  public archiveCheckboxValue: boolean;
  public exportDate: Date;
  public todayDate: Date;
  public archived: Boolean;

  constructor(
    private dialog: MatDialog,
    private archiveService: ArchiveService
  ) {
  }

  ngOnInit(): void {
    this.archiveService.fetchReportArchiveFromStore(this.report.id).subscribe(archive => {
      this.archive = archive;
    });
    this.title = this.report.name;
    this.archiveCheckboxValue = this.archive.enabled;
    this.todayDate = new Date();
    this.exportDate = this.archive.archivedDate;
    this.archived = (this.archive.status === 'ARCHIVED' || this.archive.status === 'ARCHIVE_DATA_SENT');
    this.dataSource = [
      {
        projectName: this.report.name,
        toArchive: this.archive.enabled,
        archivalDate: this.archive.scheduledDate.toString()
      }
    ];
  }

  /**
   * set the archive status when Archive checkbox is clicked
   * @param toArchive
   */
  setArchiveStatus(toArchive: boolean) {
    this.archiveCheckboxValue = toArchive;
  }

  /**
   * set the archive date when archive date is changed
   * @param event
   */
  setArchiveDateChange(event: MatDatepickerInputEvent<Date>) {
    this.exportDate = event.value;
  }

  /**
   * update all the archive changes upon Save
   */
  public onSave() {
    if (this.archiveCheckboxValue !== this.archive.enabled || this.exportDate !== this.archive.scheduledDate) {
      const updatedArchiveData = {
        id: this.archive.id,
        reportId: this.archive.reportId,
        enabled: this.archiveCheckboxValue !== null ? this.archiveCheckboxValue : this.archive.enabled,
        scheduledDate: this.exportDate ? this.exportDate : this.archive.scheduledDate
      }
      this.archiveService.updateArchive(updatedArchiveData)

    }
    this.dialog.closeAll();
  }

}
