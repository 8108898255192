import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {StrengthWatchoutsDeliverableView} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';
import {VerbatimsService} from '@app/deliverables/strengths-watchouts/verbatims/verbatims.service';
import {ConceptService} from '@platform/services/concept.service';
import { UserInfo } from '@platform/models/user-info.model';
import { UserService } from '@platform/services/user.service';

@Component({
    selector: 'sw-verbatims',
    templateUrl: './verbatims.component.html',
    styleUrls: ['./verbatims.component.scss']
})
export class VerbatimsComponent implements OnInit, OnDestroy {

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberof VerbatimsComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * Strength and Watch outs filter object.
     *
     * @type {StrengthWatchoutsFilter}
     * @memberof VerbatimsComponent
     */
    public filter: StrengthWatchoutsFilter;

    /**
     * The deliverable view object.
     *
     * @type {StrengthWatchoutsDeliverableView}
     * @memberOf VerbatimsComponent
     */
    public strengthWatchouts: StrengthWatchoutsDeliverableView;

    /**
     * Selected Data View from Chart options
     * Example: list / summary
     */
    public selectedViewFromChartOptions: number|string;

    /**
     * Currently logged in user.
     *
     * @type {UserInfo}
     * @memberof ReportComponent
     */
    public userInfo: UserInfo;

    public isAISummaryFeatureEnabled: boolean = false;

  /**
   * Creates an instance of AttributesComponent and initialize
   * the component data.
   *
   * @constructor
   * @param {StrengthWatchoutsService} strengthWatchoutsService
   * @param {VerbatimsService} verbatimsService
   * @param {ConceptService} conceptService
   * @memberof VerbatimsComponent
   */
  constructor(
    private strengthWatchoutsService: StrengthWatchoutsService,
    private verbatimsService: VerbatimsService,
    private conceptService: ConceptService,
    private userService: UserService
  ) {
    this.subscriptions = [];
  }

  /**
   * Initialize component and load data
   *
   * @memberof StrengthWatchoutsComponent
   */
  ngOnInit(): void {
    const userInfo$ = this.userService.getUser();
    const strengthWatchouts$ = this.strengthWatchoutsService.getStrengthWatchouts();
    const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
    const concepts$ = this.conceptService.getReportConcepts();
    const subscription = combineLatest([strengthWatchouts$, filter$, concepts$, userInfo$]).subscribe(([strengthWatchouts, filter, concepts, userInfo]) => {
      // update the strengthWatchoutsDeliverableView to ensure only concepts with the selected locale are displayed.
      this.isAISummaryFeatureEnabled = userInfo.featureFlags.includes('REPORTING_AI_SUMMARIES');
      const filteredStrengthWatchout = this.strengthWatchoutsService.updateConceptsByLocale(filter, strengthWatchouts);
      this.strengthWatchouts = this.verbatimsService.getVerbatimsData(filteredStrengthWatchout, concepts);
      this.filter = filter;
      this.selectedViewFromChartOptions = !this.isAISummaryFeatureEnabled ? 'list' : this.filter.show.dataView.find(it => it.isSelected).id;
    });
    this.subscriptions.push(subscription);
  }

  /**
   *
   * @param index
   * @param item
   * @returns
   */
  identify(index, item) {
    return item.label;
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
