import { AttributesFilter } from '../models/filter.model';
import { AttributesService } from '../services/attributes.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * `<ns-attributes-legend>` creates the attributes chart legend based
 * on the filter selections.
 *
 * @example
 * <ns-attributes-legend></ns-attributes-legend>
 *
 * @export
 * @class LegendComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-attributes-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit, OnDestroy {

  /**
   * Attributes filter object.
   *
   * @type {AttributesFilter}
   * @memberof LegendComponent
   */
  public filter: AttributesFilter;

  /**
   * List of subscriptions for cleanup.
   *
   * @private
   * @type {Array<Subscription>}
   * @memberof LegendComponent
   */
  private subscriptions: Array<Subscription>;

  /**
   * Creates an instance of LegendComponent.
   *
   * @constructor
   * @param {AttributesService} attributesService
   * @memberof LegendComponent
   */
  constructor(private attributesService: AttributesService) {
    this.subscriptions = [];
  }

  /**
   * Initializes the component with filter data.
   *
   * @memberof LegendComponent
   */
  ngOnInit(): void {
    const filter$ = this.attributesService.getAttributesFilter();
    const subscription = filter$.subscribe(filter => {
      this.filter = filter;
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Cleans up the component on removing from the UI.
   *
   * @memberof LegendComponent
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
