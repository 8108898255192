import {Action, createReducer, on} from '@ngrx/store';
import {BenchmarkState} from "@platform/store/benchmark/benchmark.state";
import {benchmarkAdapter} from "@platform/store/benchmark/benchmark.adapter";
import {addBenchmark, addBenchmarks, deleteBenchmark} from "@platform/store/benchmark/benchmark.actions";

/**
 * Initial state.
 */
export const initialBenchmarksState: BenchmarkState = benchmarkAdapter.getInitialState();

/**
 * Reducer for all concept actions.
 */
const benchmarkReducer = createReducer(
  initialBenchmarksState,
  on(addBenchmark, (state, {benchmark}) => {
    return benchmarkAdapter.addOne(benchmark, state);
  }),
  on(addBenchmarks, (state, {benchmarks}) => {
    return benchmarkAdapter.addMany(benchmarks, state);
  }),
  on(deleteBenchmark, (state, { reportId, benchmarkId }) => {
    return benchmarkAdapter.removeOne( benchmarkId, state);
  })
);

/**
 * Benchmark `NgRx` reducer.
 *
 * @export
 * @param {BenchmarkState} state
 * @param {Action} action
 * @returns {BenchmarkState}
 */
export function reducer(state: BenchmarkState | undefined, action: Action): BenchmarkState {
  return benchmarkReducer(state, action);
}
