
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import {ReachAnalysisService} from '@app/deliverables/reach-analysis/services/reach-analysis.service';
import {ReportService} from '@platform/services/report.service';
import {FilterService} from '@platform/services/filter.service';
import {combineLatest, fromEvent, Observable, Subscription, take} from 'rxjs';
import {ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {
  CombinationDataItem,
  ReachAnalysisDeliverableView
} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Report} from "@platform/models/report.model";

@Component({
  selector: 'ns-detailed-view',
  templateUrl: './detailed-view.component.html',
  styleUrls: ['./detailed-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedViewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() reachAnalysis: ReachAnalysisDeliverableView;
  @Input() displaySpinner: boolean;
  @Input() report: Report;

  public filteredVarieties = [];
  /**
   * ReachAnalysis deliverable view filter object.
   *
   * @type {AttributesFilter}
   * @member SummaryViewComponent
   */
  public filter: ReachAnalysisFilter;
  public trimmedNames = [];

  public combinations: Array<CombinationDataItem>;
  @ViewChild('pagination', {static: false}) paginator: MatPaginator;
  combinations$: Observable<CombinationDataItem[]>;
  dataSource: MatTableDataSource<CombinationDataItem>;
  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member SummaryViewComponent
   */
  public subscriptions: Array<Subscription>;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  @ViewChild('box') box: ElementRef;
  topLegendWidth = 0;
  combinationsCount = 0;
  setNumberIsSame = false;
  public percentageText : string;

  constructor(
    private reachAnalysisService: ReachAnalysisService,
    private reportService: ReportService,
    private filterService: FilterService,
    private changeDetectorRef: ChangeDetectorRef) {
    this.subscriptions = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const reachAnalysis = changes.reachAnalysis;
    if (reachAnalysis && reachAnalysis.currentValue) {
      this.refresh();
    }
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(e => {
      this.onResize();
    });
    this.subscriptions.push(this.resizeSubscription$);
  }

  refresh() {
    const filter$ = this.reachAnalysisService.getReachAnalysisFilter();
    filter$.pipe(take(1)).subscribe(filter => {
     // const metaInfo = this.reachAnalysisService.getMetaInfo(this.report, filter);
      this.percentageText = filter.basesLabel === 'OBC' ? 'shared.deliverables.reach.filter.benefit.chart.label' : 'shared.deliverables.reach.filter.varieties.chart.label';
      this.filter = filter;
      const combinations = this.reachAnalysis.combinations;
      this.combinations = this.reachAnalysisService.getFilteredCombinations(combinations, filter);
      const totalVarieties = this.combinations.reduce((acc, shot) => acc = acc > shot.setNumber ? acc : shot.setNumber, 0);
      this.filteredVarieties = this.reachAnalysisService.getFilteredVarieties(totalVarieties, this.combinations, filter, 'detailedView');
      this.setTrimmedVarietyNames();
      this.dataSource = new MatTableDataSource<CombinationDataItem>(this.combinations);
      this.dataSource.paginator = this.paginator;
      this.setNumberIsSame = this.combinations.length > 0 ? this.combinations[0].setNumber === this.combinations[1].setNumber : this.setNumberIsSame;
      this.combinations$ = this.dataSource.connect();
      this.combinationsCount = this.combinations?.length;
      this.onResize();
    });
  }

  /**
   * Triggered when window is resized
   * Sets the width of top legend section of template
   */
  onResize() {
    this.topLegendWidth = this.box?.nativeElement.offsetWidth * this.combinationsCount;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * After view init hook
   */
   ngAfterViewInit() {
    this.onResize();
  }

  /**
   * Pagination changed
   * @param evt {PageEvent}
   */
  pageChanged(evt: PageEvent) {
    console.log(evt);
  }
  /**
   * Cleanup hook.
   *
   * @memberOf SurveyQuestionComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

    setTrimmedVarietyNames() {
        const trimmedNames = [];
        this.filteredVarieties.forEach(variety => {
            trimmedNames.push({
                fullName: variety.name,
                name: variety.name,
                croppedName: (variety.name.length > 45) ? variety.name.substring(50, variety.name.length) + '...' : ''
            });
        });
        this.trimmedNames = trimmedNames;
    }
}
