import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownData} from '@products/shared/dropdown/dropdown.data.model';
import {UserService} from '@platform/services/user.service';
import {combineLatest} from 'rxjs';
import {FilterItem} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {TranslateService} from '@ngx-translate/core';
import {OrganizationService} from '@platform/services/organization.service';
import {LocaleService} from '@platform/services/locale.service';
import {DropDownModel, FilterModel} from '@platform/import-benchmark-results/models/filter.model';
import {solutionTypes} from '@platform/import-benchmark-results/models/solution-enum';
import {BenchmarkService} from '@platform/import-benchmark-results/services/benchmark.service';
import {ReportService} from '@platform/services/report.service';
import {Report} from '@platform/models/report.model';
import {SpinnerService} from "@platform/services/spinner.service";

export const FILTER_NAME_LIST = {
    solutions: 'Solution Types',
    country: 'Countries',
};

export class DisplayFilters {
    name: string;
    toolTipStr: string;
    data: any;
}

@Component({
    selector: 'ns-benchmark-filters-section',
    templateUrl: './benchmark-filters-section.component.html',
    styleUrls: ['./benchmark-filters-section.component.scss']
})
export class BenchmarkFiltersSectionComponent implements OnInit {
    @Output() benchmarkList = new EventEmitter();
    /**
     * To be used for pushing new list of results to existing results on result page.
     */
    @Output() addToBenchmarkResultList = new EventEmitter();
    /**
     * List of deliverable view dropdown items.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public organizationListData: Array<any>;
    public countryListData: Array<string>;
    selectedFilters: FilterModel;
    public solutionListData: Array<string>;
    filterCountries: DisplayFilters = new DisplayFilters();
    filterOrganization: DisplayFilters = new DisplayFilters();
    filterSolutions: DisplayFilters = new DisplayFilters();
    public isInternalUser: Boolean;
    menuClosed = true;
    isLocalesNull = false;
    searchPhase = '';
    searchTooltipText =
        'Enter project name(s) or study number(s) to search for previous results.';
    limit = 15;
    public totalReportsSearched = 0;
    public report: Report;
    @Output() isLoading = new EventEmitter();
    @Output() resetOffset = new EventEmitter();
    @Output() closeFilter = new EventEmitter();

    page: number = 1;

    constructor(private userService: UserService,
                private translate: TranslateService,
                private organizationService: OrganizationService,
                private localeService: LocaleService,
                private reportService: ReportService,
                private benchmarkService: BenchmarkService,
                private spinnerService: SpinnerService) {
    }


    ngOnInit(): void {
        const user$ = this.userService.getUser();
        const report$ = this.reportService.get();
        const organization$ = this.organizationService.fetchAll();
        combineLatest([user$, report$, organization$]).subscribe(([user, report, organizations]) => {
            this.isInternalUser = user.isInternalUser;
            this.report = report;
            this.setFilters(organizations);
        });
    }

    /**
     * Set Filters for Search
     * @param organizations
     */
    setFilters(organizations) {
        this.solutionListData = this.getSolutionData(solutionTypes);
        this.organizationListData = this.getOrganizationData(organizations, this.report);
        this.countryListData = this.getCountriesData();
    }

    /**
     * Set the Search text
     * @param evnt
     */
    setSearchText(evnt: string): void {
        this.searchPhase = evnt;
    }

    /**
     * Set Solutions Data for Search Filter
     * @param solutionTypes
     * @private
     */
    private getSolutionData(solutionTypes): Array<any> {
        const solutionListItemData: Array<any> = [];
        solutionTypes.forEach((option: FilterItem) => {
            solutionListItemData.push(
                {
                    value: option.name,
                    name: option.name,
                    identifier: option.name,
                    isUserPreference: true
                }
            );
        });
        return this.mapDropDownObj(
            solutionListItemData,
            'All Solutions'
        );
    }

    /**
     * Get Organizations Data for search
     * @param organizationsList
     * @param report
     */
    getOrganizationData(organizationsList, report) {
        const solutionListItemData: Array<any> = [];
        organizationsList.forEach((option) => {
            if (option.id === report.orgId || option.orgName === 'NielsenIQ') {
                solutionListItemData.push(
                    {
                        identifier: option.id,
                        name: option.displayName,
                        value: option.id,
                        isUserPreference: option.id === report.orgId
                    }
                );
            }
        });
        if (solutionListItemData.length > 1) {
            if (solutionListItemData[0].name === 'NielsenIQ') {
                solutionListItemData.reverse();
            }
        } else {
            solutionListItemData[0].isUserPreference = true;
        }
        return solutionListItemData;
    }

    /**
     * Get Countries Data for search
     */
    getCountriesData() {
        const solutionListItemData: Array<any> = [];
        const locales = this.localeService.getLocale();
        for (const key in locales) {
            solutionListItemData.push(
                {
                    value: key,
                    name: locales[key],
                    identifier: key,
                    isUserPreference: true
                }
            );
        }
        return this.mapDropDownObj(
            solutionListItemData,
            'All Locales'
        );
    }

    mapDropDownObj(obj: DropDownModel[], mainStr: string): any[] {
        const multiSelectOptions: any = {};
        multiSelectOptions.tooltipText = '';
        multiSelectOptions.name = mainStr;
        multiSelectOptions.selectedOptionsCount = obj.filter(
            (ele) => ele.isUserPreference
        ).length;
        multiSelectOptions.allComplete = multiSelectOptions.isUserPreference =
            multiSelectOptions.selectedOptionsCount === obj.length;
        multiSelectOptions.singleSelectedOptionObject =
            multiSelectOptions.selectedOptionsCount === 1
                ? obj.find((ele) => ele.isUserPreference)
                : '';
        multiSelectOptions.subtasks = obj;

        if (!multiSelectOptions.allComplete) {
            obj.forEach((ele) => {
                if (ele.isUserPreference) {
                    multiSelectOptions.tooltipText = multiSelectOptions.tooltipText
                        ? multiSelectOptions.tooltipText + ', ' + ele.name
                        : ele.name;
                }
            });
        }

        return [multiSelectOptions];
    }

    /**
     * Set selected Filter Options for search
     * @param event
     * @param filterOption
     */
    setSelectedFilterOption(event: any, filterOption: any): void {
        if (filterOption === 'country'  || filterOption === 'locales') {
            this.filterCountries = this.setFilterObjects(event, filterOption);
        } else if (filterOption === 'solutions') {
            this.filterSolutions = this.setFilterObjects(event, filterOption);
        } else if (filterOption === 'organization') {
            this.filterOrganization.name = this.filterOrganization.toolTipStr =
                event[event.findIndex((ele) => ele.isUserPreference)].name;
            this.filterOrganization.data = event;
        }
    }

    /**
     * Create Filter object for search
     * @param obj
     * @param filterOption
     */
    setFilterObjects(obj: any, filterOption: any): DisplayFilters {
        const mapObject: DisplayFilters = new DisplayFilters();
        if (obj[0].allComplete) {
            mapObject.name =
                mapObject.toolTipStr = `All ${FILTER_NAME_LIST[filterOption]}`;
        } else {
            mapObject.name =
                obj[0].selectedOptionsCount > 1
                    ? `${obj[0].selectedOptionsCount} ${FILTER_NAME_LIST[filterOption]}`
                    : obj[0].singleSelectedOptionObject.name;
            mapObject.toolTipStr = obj[0].tooltipText;
        }
        mapObject.data = obj[0];

        return mapObject;
    }

    /**
     * Create Filter model for search
     */
    getFilterModel(page?:number): FilterModel {
        const filterData: FilterModel = new FilterModel();
        filterData.countriesFilter = this.filterCountries.data.allComplete ? [] : this.filterCountries.data.subtasks.filter(
                (ele) => ele.isUserPreference);
        filterData.solutionsFilter = this.filterSolutions.data.subtasks.filter(
                (ele) => ele.isUserPreference );
        filterData.organizationFilter = this.filterOrganization.data.filter((ele) => ele.isUserPreference);
        filterData.searchPhrase = this.searchPhase;
        filterData.page = page;
        filterData.offset = 20;
        return filterData;
    }

    /**
     * Apply the search Filter
     */
    applyFilters(isOnScoll?: boolean) {
        this.spinnerService.showSpinner();
        this.page = !isOnScoll ? 1 : this.page + 1;
        this.selectedFilters = this.getFilterModel(this.page);
        this.benchmarkService.findReports(this.selectedFilters, this.report.id).subscribe((data: Report[]) => {
            this.spinnerService.hideSpinner();
            const reportData = data['filteredReports'];
            this.totalReportsSearched = data['totalReports'];
            const dataAfterCurrentReportRemoval = reportData.filter(it => it.id !== this.report.id);
            if (dataAfterCurrentReportRemoval.length > 0) {
                if (isOnScoll) {
                    this.addToBenchmarkResultList.emit(dataAfterCurrentReportRemoval);
                } else {
                    this.benchmarkList.emit(dataAfterCurrentReportRemoval);
                }
            } else if (!isOnScoll) {
                this.benchmarkList.emit(dataAfterCurrentReportRemoval);
            }
        });
    }
    /**
     * Close the search Filter Widget
     */
    closeFilterWidget() {
        this.closeFilter.emit(false);
    }
}
