import {
  CSSNodeDeclaration
} from './css-node-declaration';
import {
  iterateStyleDeclaration
} from './style-util';
import {
  CaptureOptions
} from './capture-options';
import {
  CSSNode
} from './css-node';

/**
 * Processor for node styles.
 */
export class StyleProcessor {

  /**
   * Process computed style at the node and builds the CSSNode tree.
   *
   * @param clone the cloned node.
   * @param original the original node.
   * @param cssNode the CSSNode
   * @param options the capture options.
   */
  public process(clone: Element, original: Element, cssNode: CSSNode, options: CaptureOptions): void {
    const styleDeclaration: CSSStyleDeclaration = getComputedStyle(original);
    const beforeDeclaration: CSSStyleDeclaration = getComputedStyle(original, ':before');
    const afterDeclaration: CSSStyleDeclaration = getComputedStyle(original, ':after');
    const cssNodeDeclaration: CSSNodeDeclaration = options.getTagDeclarationList().get(clone.tagName);
    this.addDeclaration(styleDeclaration, cssNode, cssNodeDeclaration);
    this.addDeclaration(beforeDeclaration, cssNode, cssNodeDeclaration, 'before');
    this.addDeclaration(afterDeclaration, cssNode, cssNodeDeclaration, 'after');
  }

  /**
   * Adds style declaration at the node to the CSSNode with pseudo class support.
   *
   * @param cssDeclaration the computed styles
   * @param cssNode the current CSSNode
   * @param defaultDeclaration the Tag default CSS declaration
   * @param pseudoClass the pseudo class.
   */
  private addDeclaration(cssDeclaration: CSSStyleDeclaration,
    cssNode: CSSNode,
    defaultDeclaration: CSSNodeDeclaration,
    pseudoClass: string = null): void {
    iterateStyleDeclaration(cssDeclaration, (property, value) => {
      // Check if processing needs to be skipped.
      if (!property
          || !value
          || this.skip(property, value)) {
        return;
      }
      // Set declaration.
      if (pseudoClass === 'before' &&
        defaultDeclaration.beforeDeclaration.get(property) !== value) {
        cssNode.beforeDeclaration.set(property, value);
      } else if (pseudoClass === 'after' &&
        defaultDeclaration.afterDeclaration.get(property) !== value) {
        cssNode.afterDeclaration.set(property, value);
      } else if (!pseudoClass &&
        defaultDeclaration.declaration.get(property) !== value) {
        cssNode.declaration.set(property, value);
      }
    });
  }

  /**
   * Returns true if the property processing needs to be skipped.
   *
   * @param property the property name.
   * @param value the property value.
   * @returns true if needs to be skipped.
   */
  private skip(property: string, value: string): boolean {
    let skip =  this.skipPosition(property, value);
    return skip;
  }

  /**
   * Returns true if position property needs to be skipped.
   *
   * @param property the property name.
   * @param value the property value
   * @returns true if position needs to be skipped.
   */
  private skipPosition(property: string, value: string): boolean {
    const values = ['sticky'];
    let skip = false;
    if (property === 'position' && values.includes(value)) {
      skip = true;
    }
    return skip;
  }

}
