
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FilterService } from '@platform/services/filter.service';
import { SortingMatrixFilter } from '../models/filter.model';
import { DeliverableType } from '@products/quick-screen/deliverable-type.enum';
import {Subscription, Observable, combineLatest} from 'rxjs';
import { SortingMatrixService } from '../sorting-matrix.service';
import {DropdownData, DataType, DropdownItem } from '@products/shared/dropdown/dropdown.data.model';
import { TranslateService } from '@ngx-translate/core';
import { AxisData } from '../models/sorting-matrix.model';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent } from '@src/assets/utils/mixpanel-enum';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'qs-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  filter: SortingMatrixFilter;
  conceptNamesList: DropdownData<string>;
  conceptIdsList: DropdownData<number>;
  subgroupsList: DropdownData<string>;
  leftVal: number;
  rightVal: number;
  maxValue: number;
  weightFilterLabel;
  weightFilterTooltipLabel;
  axisData: AxisData;
  deliverableLabel: string;
  @Output() public filterUpdates: EventEmitter<any> = new EventEmitter();

  private subscriptions: Array<Subscription>;

  constructor(
    private filterService: FilterService,
    private sortingMatrixService: SortingMatrixService,
    private translate: TranslateService,
    private mixpanelService: MixpanelService
  ) {
    this.subscriptions = [];
  }

    ngOnInit() {
        this.deliverableLabel = MixpanelLabel.sortingMatrix;
        const filter$ = this.filterService.get<SortingMatrixFilter>(DeliverableType.SORTING_MATRIX.type);
        const sortingMatrix$ = this.sortingMatrixService.getFilteredDataWithConceptImage();
        this.maxValue = this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.weighting.default.maxRange');

        const subscription = combineLatest([sortingMatrix$, filter$]).subscribe(
            ([labelData, filter]) => {
                    // First, process the sortingMatrix$ data
                    this.axisData = labelData.axisData;

                    this.filter = filter;
                    this.leftVal = this.filter.weight;
                    this.rightVal = this.maxValue - this.leftVal;
                    this.weightFilterLabel = this.leftVal + ' / ' + this.rightVal;
                    this.weightFilterTooltipLabel = this.leftVal + ' ' + this.axisData.governingQuestionLabel1 + ', ' + this.rightVal + ' ' + this.axisData.governingQuestionLabel2;
                    this.conceptNamesList = this.conceptsListData(filter);
                    this.conceptIdsList = this.conceptIdsListData(filter);
                    this.subgroupsList = this.subgroupListData(filter);
            });
        this.sortingMatrixService.loadDefaultFilter();
        this.subscriptions.push(subscription);
    }



    /**
   * DATA Generation for the Concepts List dropdown
   * @param filter
   * @returns
   */
  private conceptsListData(filter: SortingMatrixFilter) {
    let conceptNamesListItems: Array<DropdownItem<string>> ;
    conceptNamesListItems = filter.concepts.map((concept) => {
      return {
        'value': concept.name,
        'label': concept.name,
        'selected': concept.selected
      };
    });
    return {
      dropdownLabel: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.concepts.label'),
      dataType: DataType.CHECKBOX,
      contentTitle: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.concepts.itemsTitle'),
      groupSelect: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.concepts.allSelectedLabel'),
      data: conceptNamesListItems
    };
  }

  /**
   * DATA Generation for Concept ID's dropdown
   * @param filter
   * @returns
   */
  private conceptIdsListData(filter: SortingMatrixFilter) {
    let conceptIdsListItems: Array<DropdownItem<number>> ;
    conceptIdsListItems = filter.concepts.map((concept) => {
      return {
        'value': concept.position,
        'label': concept.position.toString(),
        'selected': concept.showId
      };
    });
    return {
      dropdownLabel: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.show.subLabel'),
      dataType: DataType.CHECKBOX,
      contentTitle: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.show.itemsTitle'),
      groupSelect: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.show.allSelectedLabel'),
      data: conceptIdsListItems
    };
  }

  /**
   * DATA Generation for Subgroups dropdown
   * @param filter
   * @returns
   */
  private subgroupListData(filter: SortingMatrixFilter) {
    let subgroupListItems: Array<DropdownItem<string>> ;
    subgroupListItems = filter.subgroups.map((subgroup) => {
      return {
        'value': subgroup.name,
        'label': subgroup.name,
        'selected': subgroup.selected
      };
    });
    return {
      dropdownLabel: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.subgroups.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('quick.screen.deliverables.sortingMatrix.filter.subgroups.itemsTitle'),
      data: subgroupListItems
    };
  }

  /**
   * Update the filter store with the selected concepts
   */
  onConceptSelectionChange(concepts: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          selected: concepts.find(selectedConcept => selectedConcept.value === concept.name).selected
        };
      })
    });
    this.filterUpdates.emit('concepts ' + concepts.filter(concept => concept.selected === true).length
    );
  }

  /**
   * Update the filter store with the showId
   */
  onShowIdsSelectionChange(conceptIds: Array<DropdownItem<number>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          showId: conceptIds.find(selectedConceptId => selectedConceptId.value === concept.position).selected
        };
      })
    });
  }

  /**
   * Update the filter store with the new weight
   */
  onWeightChange(evt) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      weight: evt.value
    });
  }

  /**
   * Event listener for subgroup selection change event.
   */
  selectSubgroup(subgroups: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterUpdates.emit('subgroups' + subgroups.filter(subgroup => subgroup.selected === true)[0].label);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          selected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
        };
      })
    });
  }

  /**
   * Emits event of the selected filter menu item.
   */
  selectedEvent(event): void {
    console.log(event);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  resetWeight(weightStatus: any) {
    this.filterUpdates.emit('weight' + weightStatus);
  }

}
