/**
 * `BarChart` value object represents data for constructing
 * an attributes bar.
 *
 * @export
 * @interface BarChart
 */
export interface BarChart {

  /**
   * Bar chart colors for all statistics.
   *
   * @type {{ [key: string]: string }}
   * @memberof BarChart
   */
  colors: { [key: string]: string };

  /**
   * Series data for chart.
   *
   * @type {Array<BarChartData>}
   * @memberof BarChart
   */
  series: Array<BarChartData>;

  /**
   * BarChart options for configuring the chart.
   *
   * @type {BarChartOptions}
   * @memberof BarChart
   */
  options?: BarChartOptions;
}

/**
 * `BarChartData` represents the data for every stat item on the
 * bar chart. Stat type is the key and value with be of type
 * `BarChartDataItem`.
 *
 * @export
 * @interface BarChartData
 */
export interface BarChartData {
  [key: string]: BarChartDataItem;
}

/**
 * `BarChartDataItem` represents the value for the bar chart.
 *
 * @export
 * @interface BarChartDataItem
 */
export interface BarChartDataItem {
  /**
   * Id of the object.
   *
   * @type {string}
   * @memberof BarChartDataItem
   */
  id: string;

  /**
   * Numeric value of the object for the bar chart.
   *
   * @type {number}
   * @memberof BarChartDataItem
   */
  value: number;

  /**
   * Tooltip value for the bar chart.
   *
   * @type {string}
   * @memberof BarChartDataItem
   */
  tooltip?: string;

  /**
   * Label of the bar chart.
   *
   * @type {string}
   * @memberof BarChartDataItem
   */
  label?: string;
}

/**
 * `BarChartOptions` provides the configuration options for the bar chart.
 *
 * @export
 * @interface BarChartOptions
 */
export interface BarChartOptions {
  /**
   * Margin of the bar chart.
   *
   * @type {{
   *     left?: number,
   *     right?: number,
   *     top?: number,
   *     bottom?: number
   *   }}
   * @memberof BarChartOptions
   */
  margin: {
    left?: number,
    right?: number,
    top?: number,
    bottom?: number
  };

  /**
   * Width of the bar chart.
   *
   * @type {number}
   * @memberof BarChartOptions
   */
  width: number;

  /**
   * Height of the bar chart.
   *
   * @type {number}
   * @memberof BarChartOptions
   */
  height: number;

  /**
   * Type of the bar chart.
   *
   * @type {BarChartType}
   * @memberof BarChartOptions
   */
  type: BarChartType;

  /**
   * Agrregate label options.
   *
   * @type {{
   *     enabled: boolean,
   *     margin?: {
   *       left: number,
   *       top: number
   *     },
   *     style?: { [key: string]: string },
   *     fill?: string
   *   }}
   * @memberof BarChartOptions
   */
  aggregateLabel: {
    enabled: boolean,
    margin?: {
      left: number,
      top: number
    },
    style?: { [key: string]: string },
    fill?: string
  };

  /**
   * Bar chart data options.
   *
   * @type {{
   *     range: {
   *       min: number,
   *       max: number
   *     },
   *     domain: {
   *       min: number,
   *       max: number
   *     },
   *     margin: {
   *       top: number,
   *       right: number,
   *     },
   *     height: number,
   *     formatPattern: string,
   *     tooltip: boolean,
   *     dataLabel: boolean,
   *     dataLabelLine: boolean
   *   }}
   * @memberof BarChartOptions
   */
  bar: {
    range: {
      min: number,
      max: number
    },
    domain: {
      min: number,
      max: number
    },
    margin: {
      top: number,
      right: number,
    },
    height: number,
    formatPattern: string,
    tooltip: boolean,
    dataLabel: boolean,
    dataLabelLine: boolean
  };
}

/**
 * Enum for type of the bar chart.
 *
 * @export
 * @enum {number}
 */
export enum BarChartType {

  /**
   * Represents vertical bar chart.
   */
  VERTICAL,

  /**
   * Represents horizontal bar chart.
   */
  HORIZONTAL
}
