import { BarChartType } from '@products/shared/chart-horizontal-bar/bar-chart.model';

/**
 * Default bar chart options.
 *
 * @export
 * @property
*/
export const defaultTrialUnitsBarchartOptions = {
  margin: {
    left: 0,
    right: 20,
    top: 7.5,
    bottom: 7.5
  },
  width: 300,
  height: 15,
  type: BarChartType.HORIZONTAL,
  aggregateLabel: {
    enabled: true,
    margin: {
      left: 5,
      top: 28
    },
    style: {
      'font-weight': 'bold',
      'font-size': '16px'
     },
    fill: '#A43795'
  },
  bar: {
    range: {
      min: 0,
      max: 220
    },
    domain: {
      min: 1,
      max: 5
    },
    margin: {
      top: 15,
      right: 0.5,
    },
    height: 15,
    dataLabel: false,
    dataLabelLine: false,
    tooltip: false
  }
};
