import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {UserService} from '@platform/services/user.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {GapAnalysisService} from '@app/deliverables/gap-analysis/services/gap-analysis.service';
import {GapAnalysisMetaInfo} from '@app/deliverables/gap-analysis/models/gap-analysis-view-meta-info.model';
import {GapAnalysisFilter} from '@app/deliverables/gap-analysis/models/filter.model';
import {GapAnalysisDeliverableView} from '@app/deliverables/gap-analysis/models/gap-analysis.model';
import {gapAnalysisChartOptions} from '@app/deliverables/gap-analysis/models/gap-analysis-chart-options';
import {GapAnalysisChartService} from '@app/deliverables/gap-analysis/services/gap-analysis-chart.service';
import {GapAnalysisChartData} from '@app/deliverables/gap-analysis/models/gap-analysis-chart-data.model';
import {
    LegacyPageEvent as PageEvent,
    MatLegacyPaginator as MatPaginator,
    MatLegacyPaginatorIntl as MatPaginatorIntl
} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {ReportService} from '@platform/services/report.service';
import {UserView} from '@platform/models/user-view.model';
import {UserViewService} from '@platform/services/user-view.service';
import {RouterService} from '@platform/services/router.service';
import {Report} from '@platform/models/report.model';
import {InsightService} from '@platform/insights/insights.service';
import {FilterService} from '@platform/services/filter.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {TranslateService} from '@ngx-translate/core';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {Concept} from '@platform/models/concept.model';

@Component({
    selector: 'ns-gap-analysis',
    templateUrl: './gap-analysis.component.html',
    styleUrls: ['./gap-analysis.component.scss']
})
export class GapAnalysisComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Report document
     */
    public report: Report;
    public viewId: string;

    /**
     * The deliverable view object.
     */
    public deliverableViewType: string;

    public selectors: any;

    public deliverableInfos: Array<DeliverableInfo>;

    public userViews: Array<UserView>;
    public isVerbatimsView = false;
    /**
     * Performance deliverable view data.
     *
     * @type {GapAnalysisDeliverableView}
     * @member GapAnalysisComponent
     */
    public gapAnalysisDeliverableView: GapAnalysisDeliverableView;

    /**
     * Performance deliverable view filter object.
     *
     * @type {GapAnalysisFilter}
     * @member GapAnalysisComponent
     */
    public filter: GapAnalysisFilter;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member GapAnalysisComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @member GapAnalysisComponent
     */
    public isInsightEnable = false;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @member GapAnalysisComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Spinner.
     *
     * @type {Boolean}
     * @member GapAnalysisComponent
     */
    public displayProgressSpinner = false;

    /**
     * Disable Insights Button.
     *
     * @type {Boolean}
     * @member GapAnalysisComponent
     */
    public disableBtn: boolean;

    /**
     * Meta info for Gap Analysis
     *
     * @type {PerformanceDeliverableView}
     * @memberOf GapAnalysisComponent
     */
    public viewGapAnalysisMetaInfo: GapAnalysisMetaInfo;

    /**
     * Internal User
     *
     * @type {Boolean}
     * @member GapAnalysisComponent
     */
    public isInternalUser: Boolean;

    public chartOptions: any;

    public gapAnalysisChartData: GapAnalysisChartData;

    /**
     * Array of pages
     *
     * @property
     * @typedef {Array<number>}
     * @memberOf GapAnalysisComponent
     */
    public gapAnalysisPages: Array<number> = [1, 2];

    public currentPage = 0;

    public pageIndexNum = 0;

    public deliverableType = DeliverableType.GAP_ANALYSIS.type;

    /**
     * pages paginator instance
     *
     * @type {MatPaginator}
     * @memberOf GapAnalysisComponent
     */
    @ViewChild('gapAnalysisPagination', {static: true}) gapAnalysisPagination: MatPaginator;

    /**
     * Datasource object of type ImageFile
     *
     * @property
     * @public
     * @type {number}
     * @memberOf AdditionalInfoComponent
     */
    public dataSource: MatTableDataSource<any>;

    public currentPageChartData: GapAnalysisChartData;

    public groupsPerPage = 12;

    public deliverableViews: Array<DeliverableView>;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    public addHTMLToInsight = true;

    /**
     * ScoreCard Concept object for factors.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    constructor(private deliverableInsightService: DeliverableInsightService,
                private exportPNGService: ExportPngService,
                private spinnerService: SpinnerService,
                private viewMetaInfoService: ViewMetaInfoService,
                private userService: UserService,
                private gapAnalysisService: GapAnalysisService,
                private gapAnalysisChartService: GapAnalysisChartService,
                private mixpanelService: MixpanelService,
                private deliverableViewService: DeliverableViewService,
                private routeService: RouterService,
                private userViewService: UserViewService,
                private filterService: FilterService,
                private deliverableInfoService: DeliverableInfoService,
                private reportService: ReportService,
                private insightService: InsightService,
                private translate: TranslateService) {
        this.chartOptions = gapAnalysisChartOptions;
        this.subscriptions = [];
        this.userViews = [];
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as GapAnalysisFilter;
        this.disableBtn = this.isConceptExists(this.filter);
        this.filterService.update(userView.filter);
    }

    /**
     * check concept count
     * */
    isConceptExists(filter: GapAnalysisFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
        const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
        return (conceptCount == 0 || subgroupCount == 0);
    }

    /**
     * Information for user to select only first 10 concept
     */
    getConceptCountMessage() {
        return this.translate.instant('platform.deliverable.insight.info.text');
    }

    /**
     * download survey questions data.
     *
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

    ngOnInit(): void {
        const deliverableType = DeliverableType.GAP_ANALYSIS.type;
        const insightId = this.routeService.getQueryParam('insightId');
        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.GAP_ANALYSIS.type)
        ]).subscribe(([report, user, deliverableViews]) => {

            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.deliverableViews = deliverableViews;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');

            // load saved user views(filter) if they exist
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<GapAnalysisFilter>(report.id, insightId),
                this.gapAnalysisService.loadDefaultFilter(this.deliverableViews)
            ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
                /**
                 * Add subscription to watch filter changes here so that s&w deliverable data can also be updated as per filter change.
                 * */
                this.subscriptions.push(combineLatest([
                    this.gapAnalysisService.getGapAnalysisFilter(),
                    this.gapAnalysisService.getGapAnalysis(),
                    this.viewMetaInfoService.get<GapAnalysisMetaInfo>(deliverableType)
                ]).subscribe(([filters, gapAnalysis, viewMetaInfo]) => {
                    this.filter = filters;
                    this.gapAnalysisDeliverableView = gapAnalysis;
                    this.gapAnalysisDeliverableView = gapAnalysis;
                    this.filter = filters;
                    this.viewGapAnalysisMetaInfo = viewMetaInfo;

                    gapAnalysis.statements.every(statement => statement.statementData.length === 0) ? this.disableBtn = true : this.disableBtn = false;

                    this.gapAnalysisChartData = this.gapAnalysisChartService.prepareGapAnalysisChartData(gapAnalysis, filters);

                    this.setNoOfGroupsPerPage(window.innerWidth);

                    this.setPaginationParams(this.groupsPerPage);

                    this.setCurrentPageChartData(this.currentPage, this.groupsPerPage);
                    this.deliverableData = {
                        selectors: ['.gap-chart-content'],
                        title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle('Gap Analysis') : '',
                        deliverable: {
                            deliverableViewId: this.gapAnalysisDeliverableView.id,
                            filter: this.filter,
                            metaInfo: this.viewGapAnalysisMetaInfo
                        },
                    };
                }));
            });
        });
        this.subscriptions.push(subscription);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setNoOfGroupsPerPage(event.target.innerWidth);

        this.setPaginationParams(this.groupsPerPage);
        this.setCurrentPageChartData(this.currentPage, this.groupsPerPage);
    }

    /**
     * This method sets no of groups per page for pagination
     * @param innerWidth
     */
    public setNoOfGroupsPerPage(innerWidth: number) {
        const offsetWidth = 100,
            YAxisPadding = 20,
            YAxisWidth = 25,
            firstStatementGroupWidth = 125;
        const contentWidth = (innerWidth - offsetWidth - YAxisPadding - YAxisWidth - this.chartOptions.margin.left - this.chartOptions.margin.right - firstStatementGroupWidth);
        this.groupsPerPage = Math.floor(contentWidth / 125);
    }

    /**
     * This method sets datasource and current page index
     * As gap analysis is using datatable, this is to set explicit pagination and match dataTable styles
     */
    public setPaginationParams(groupsPerPage: number) {
        const numberOfPages = this.getNumberOfPages(this.gapAnalysisChartData.statementGroups.length, groupsPerPage);
        this.gapAnalysisPages = new Array<number>(numberOfPages);
        this.gapAnalysisPagination.pageIndex = 0;
        this.currentPage = this.gapAnalysisPagination.pageIndex;

        this.dataSource = new MatTableDataSource<any>(this.gapAnalysisPages);
        this.dataSource.paginator = this.gapAnalysisPagination;
    }

    /**
     * calculating total number of pages
     * @param totalNumberofGroups
     * @param groupsPerPage
     */
    public getNumberOfPages(totalNumberofGroups: number, groupsPerPage: number): number {
        let numberOfPages = Math.floor(totalNumberofGroups / groupsPerPage);
        if (totalNumberofGroups % groupsPerPage !== 0) {
            numberOfPages = numberOfPages + 1;
        }
        return numberOfPages;
    }

    ngAfterViewInit() {
        const paginationIntl = new MatPaginatorIntl();
        paginationIntl.nextPageLabel = 'Next Attributes';
        paginationIntl.previousPageLabel = 'Previous Attributes';
        this.gapAnalysisPagination._intl = paginationIntl;
    }

    /**
     * Set Views
     * @param evt { String }
     */
    setViews(evt: any) {
        /**
         * TODO:
         * Create a data model for views which has all details (sorting, highlights, filters, Insights ... )
         * Store the view in the DB
         * Create a Default View (set to defaults)
         * Set the view data on load
         */
        console.log(evt);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.mixpanelService.track(MixpanelLabel.gapAnalysis, MixpanelEvent.exportAsPNG);
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.isInsightEnable = true;
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Cleanup hook.
     *
     * @member GapAnalysisComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Pagination changed
     * @param evt {PageEvent}
     */
    public pageChanged(evt: PageEvent) {
        if (evt.pageIndex > this.pageIndexNum) {
            // Clicked on next button
            this.mixpanelService.track(MixpanelLabel.gapAnalysis, MixpanelEvent.nextPage);
        } else {
            // Clicked on previous button
            this.mixpanelService.track(MixpanelLabel.gapAnalysis, MixpanelEvent.previousPage);
        }
        this.currentPage = evt.pageIndex;
        this.setCurrentPageChartData(this.currentPage, this.groupsPerPage);
    }

    /**
     * This method is to set chart data per page
     * @param currentPage
     * @param groupsPerPage
     */
    public setCurrentPageChartData(currentPage: number, groupsPerPage: number) {
        let currentPageStatementGroups: any[];
        let currentPageStatementGroupsChartData: any[];

        const startIndex = groupsPerPage * currentPage;
        const endIndex = startIndex + groupsPerPage;

        currentPageStatementGroups = this.gapAnalysisChartData.statementGroups.slice(startIndex, endIndex);
        currentPageStatementGroupsChartData = this.gapAnalysisChartData.statementGroupsChartData.slice(startIndex, endIndex);

        const currentPageChartData = new GapAnalysisChartData();

        currentPageChartData.lineChartValue = this.gapAnalysisChartData.lineChartValue;
        currentPageChartData.yAxisRange = this.gapAnalysisChartData.yAxisRange;
        currentPageChartData.restageSubGroups = this.gapAnalysisChartData.restageSubGroups;

        currentPageChartData.statementGroups = currentPageStatementGroups;
        currentPageChartData.statementGroupsChartData = currentPageStatementGroupsChartData;

        this.currentPageChartData = currentPageChartData;
    }

}
