import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {ConfirmationDialogService} from "@app/common/confirmation-dialog/confirmation-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@NgModule({
    declarations: [
        ConfirmationDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule
    ],
    providers: [
        ConfirmationDialogService,
        TranslateService
    ]
})
export class ConfirmationDialogModule {
}
