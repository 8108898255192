import { SurveyQuestionService } from '../services/survey-question.service';
import { SurveyQuestionFilter } from '../models/filter.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * `<ns-survey-question-legend>` creates the survey-question footer based
 * on the filter selections.
 *
 * @example
 * <ns-survey-question-legend></ns-survey-question-legend>
 *
 * @export
 * @class LegendComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-survey-question-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit, OnDestroy {

  /**
   * SurveyQuestions filter object.
   *
   * @type {SurveyQuestionsFilter}
   * @memberof LegendComponent
   */
  public filter: SurveyQuestionFilter;


  public isConceptView: boolean;
  /**
   * List of subscriptions for cleanup.
   *
   * @private
   * @type {Array<Subscription>}
   * @memberof LegendComponent
   */
  private subscriptions: Array<Subscription>;

  /**
   * Creates an instance of LegendComponent.
   *
   * @constructor
   * @param {surveyQuestionService} surveyQuestionService
   * @memberof LegendComponent
   */
  constructor(private surveyQuestionService: SurveyQuestionService) {
    this.subscriptions = [];
  }

  /**
   * Initializes the component with filter data.
   *
   * @memberof LegendComponent
   */
  ngOnInit(): void {
    const filter$ = this.surveyQuestionService.getSurveyQuestionFilter();
    const subscription = filter$.subscribe(filter => {
      this.filter = filter;
      this.isConceptView = filter.compare.find(selected => selected.isSelected === true).name === 'Concepts';

    });
    this.subscriptions.push(subscription);
  }

  /**
   * Cleans up the component on removing from the UI.
   *
   * @memberof LegendComponent
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
