import {Component, Input, OnInit} from '@angular/core';
import {ReachAnalysisService} from '@app/deliverables/reach-analysis/services/reach-analysis.service';
import {Subscription, take} from 'rxjs';
import {ReachAnalysisDeliverableView} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';
import {ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';

@Component({
  selector: 'ns-summary-view',
  templateUrl: './summary-view.component.html',
  styleUrls: ['./summary-view.component.scss']
})
export class SummaryViewComponent implements OnInit {

  @Input() reachAnalysis: ReachAnalysisDeliverableView;
  @Input() displaySpinner: boolean;

  /**
   * ReachAnalysis deliverable view filter object.
   *
   * @type {AttributesFilter}
   * @member SummaryViewComponent
   */
  public filter: ReachAnalysisFilter;

  public basesLabel: string;

  public varieties = [];

  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member SummaryViewComponent
   */
  public subscriptions: Array<Subscription>;
  constructor(
    private reachAnalysisService: ReachAnalysisService,
  ) {this.subscriptions = []; }

  ngOnInit(): void {
    const filter$ = this.reachAnalysisService.getReachAnalysisFilter();
    filter$.pipe(take(1)).subscribe(filter => {
      this.basesLabel = filter.basesLabel;
      this.filter = filter;
    });
  }

}
