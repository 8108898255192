
/**
 * Probability of success mapping
 *
 * @export
 * @property
 */
export const ThresholdsMapping = {
  /**
   * Different probability of success options for FFS
   */
  OUTSTANDING: {
    id: 'outstanding',
    color: 'threshold-blue',
    columnText: 'quick.predict.deliverables.factors.filter.thresholds.outstanding',
    name: 'quick.predict.deliverables.factors.filter.thresholds.outstanding',
    tooltip: 'quick.predict.deliverables.factors.tooltip.outstanding',
    DP: 'quick.predict.deliverables.factors.tooltip.outstanding.distinct.proposition',
    AC: 'quick.predict.deliverables.factors.tooltip.outstanding.attention.catching',
    MC: 'quick.predict.deliverables.factors.tooltip.outstanding.message.connection',
    CM: 'quick.predict.deliverables.factors.tooltip.outstanding.clear.concise.message',
    ND: 'quick.predict.deliverables.factors.tooltip.outstanding.need.desire',
    Ad: 'quick.predict.deliverables.factors.tooltip.outstanding.advantage',
    Cr: 'quick.predict.deliverables.factors.tooltip.outstanding.credibility',
    A$: 'quick.predict.deliverables.factors.tooltip.outstanding.acceptable.costs'
  },
  READY: {
    id: 'ready',
    color: 'threshold-green',
    columnText: 'quick.predict.deliverables.factors.filter.thresholds.ready',
    name: 'quick.predict.deliverables.factors.filter.thresholds.ready',
    tooltip: 'quick.predict.deliverables.factors.tooltip.ready',
    DP: 'quick.predict.deliverables.factors.tooltip.ready.distinct.proposition',
    AC: 'quick.predict.deliverables.factors.tooltip.ready.attention.catching',
    MC: 'quick.predict.deliverables.factors.tooltip.ready.message.connection',
    CM: 'quick.predict.deliverables.factors.tooltip.ready.clear.concise.message',
    ND: 'quick.predict.deliverables.factors.tooltip.ready.need.desire',
    Ad: 'quick.predict.deliverables.factors.tooltip.ready.advantage',
    Cr: 'quick.predict.deliverables.factors.tooltip.ready.credibility',
    A$: 'quick.predict.deliverables.factors.tooltip.ready.acceptable.costs'
  },
  RISKY: {
    id: 'risky',
    color: 'threshold-yellow',
    columnText: 'quick.predict.deliverables.factors.filter.thresholds.risky',
    name: 'quick.predict.deliverables.factors.filter.thresholds.risky',
    tooltip: 'quick.predict.deliverables.factors.tooltip.risky',
    DP: 'quick.predict.deliverables.factors.tooltip.risky.distinct.proposition',
    AC: 'quick.predict.deliverables.factors.tooltip.risky.attention.catching',
    MC: 'quick.predict.deliverables.factors.tooltip.risky.message.connection',
    CM: 'quick.predict.deliverables.factors.tooltip.risky.clear.concise.message',
    ND: 'quick.predict.deliverables.factors.tooltip.risky.need.desire',
    Ad: 'quick.predict.deliverables.factors.tooltip.risky.advantage',
    Cr: 'quick.predict.deliverables.factors.tooltip.risky.credibility',
    A$: 'quick.predict.deliverables.factors.tooltip.risky.acceptable.costs'
  },
  HIGH_RISK: {
    id: 'failure',
    color: 'threshold-red',
    columnText: 'quick.predict.deliverables.factors.filter.thresholds.high.risk',
    name: 'quick.predict.deliverables.factors.filter.thresholds.failure',
    tooltip: 'quick.predict.deliverables.factors.tooltip.high.risk',
    DP: 'quick.predict.deliverables.factors.tooltip.high.risk.distinct.proposition',
    AC: 'quick.predict.deliverables.factors.tooltip.high.risk.attention.catching',
    MC: 'quick.predict.deliverables.factors.tooltip.high.risk.message.connection',
    CM: 'quick.predict.deliverables.factors.tooltip.high.risk.clear.concise.message',
    ND: 'quick.predict.deliverables.factors.tooltip.high.risk.need.desire',
    Ad: 'quick.predict.deliverables.factors.tooltip.high.risk.advantage',
    Cr: 'quick.predict.deliverables.factors.tooltip.high.risk.credibility',
    A$: 'quick.predict.deliverables.factors.tooltip.high.risk.acceptable.costs'
  },
};
