<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <ns-add-benchmark-concept-card
      class="padding-bottom-1-point-5"
      (toggleConceptAdded)="toggleConcept($event)"
      [concept]="node.concept"
      [conceptAdded]="node.selected"
      [report]="report"
      [importedConceptIds]="importedConceptIds"
      [subgroup]="dbSubgroup"
      [activationProfileDeliverableView]="activationProfileDeliverableView"
      [factorsDeliverableView]="factorsDeliverableView"
      [financialPotentialDeliverableView]="financialPotentialDeliverableView"></ns-add-benchmark-concept-card>
  </mat-tree-node>

  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="project-header-bar">
    <mat-checkbox class="toggle-concepts padding-left-1-point-5"
                  [disabled]="!treeControl.isExpanded(node)"
                  [checked]="checked"
                  [indeterminate]="indeterminate"
                  (change)="toggleSelectAll($event)"
                  [matTooltip]="!treeControl.isExpanded(node) ? ('platform.benchmark.concept.import.disabled.selectall.checkbox.message' | translate ): ''" [matTooltipClass]="['custom-tooltip', 'pre-tooltip']">
    </mat-checkbox>
    <button mat-icon-button (click)="toggleExpand(node)" [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <div (click)="toggleExpand(node)" class="project-header">
      <div class="project-name padding-left-1-point-5 pr-2-point-5">{{report.name}}</div>
      <div class="org-name pr-2-point-5 text-muted">{{this.reportOrg?.displayName}}</div>
      <div class="product-name pr-2-point-5 text-muted">{{report.productName}}</div>
      <div class="country pr-2-point-5 text-muted">{{reportCreatedDate}}</div>
    </div>
  </mat-tree-node>
</mat-tree>
