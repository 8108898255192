import { EventEmitter, OnChanges, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { FilterService } from '@platform/services/filter.service';
import { DeliverableType } from '@products/quick-screen/deliverable-type.enum';
import { Subscription, Observable } from 'rxjs';
import { SortingMatrixFilter } from '../../models/filter.model';
import { AxisData } from '../../models/sorting-matrix.model';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelEvent } from '@src/assets/utils/mixpanel-enum';

@Component({
  selector: 'ns-dropdown-slider',
  templateUrl: './dropdown-slider.component.html',
  styleUrls: ['./dropdown-slider.component.scss']
})
export class DropdownSliderComponent implements OnInit, OnChanges {
    @Input() deliverableLabel: string;
    @Input() defaultValue: number;
    @Input() maxValue: number;
    @Input() axisData: AxisData;
    @Output() public resetWeight: EventEmitter<any> = new EventEmitter();

    public filter: SortingMatrixFilter;
    public subscription: Subscription;
    public leftVal: number;
    public rightVal: number;
    public leftLabel: string;
    public rightLabel: string;

    constructor( private filterService: FilterService,
                 private mixpanelService: MixpanelService) {}

    ngOnChanges() {
        if(this.axisData) {
            this.leftLabel = this.axisData.governingQuestionLabel1;
            this.rightLabel = this.axisData.governingQuestionLabel2;
        }
    }

    ngOnInit(): void {
        const filter$: Observable<SortingMatrixFilter> = this.filterService.get(DeliverableType.SORTING_MATRIX.type);
        this.subscription = filter$.subscribe( (filter: SortingMatrixFilter) => {
            this.filter = filter;
            this.leftVal = this.filter.weight;
            this.rightVal = this.maxValue - this.leftVal;
        });
    }

    /**
     * Update the filter store with the new weight
     */
    onWeightChange(evt: MatSliderChange) {
        this.resetWeight.emit(evt.value);
        this.filterService.update({
            ...this.filter,
            weight: evt.value
        });
      this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    }

    /**
     * Triggered when the slider value changes
     * @param evt {MatSliderChange}
     * @memberof DropdownSliderComponent
     */
    sliderValueChanged(evt: MatSliderChange) {
      this.leftVal = evt.value;
      this.rightVal = this.maxValue - this.leftVal;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
