import { BarChartType } from "@products/shared/chart-horizontal-bar/bar-chart.model";

/**
 * Default bar chart options.
 *
 * @export
 * @property
*/
export const defaultBarChartOptions = {
    margin: {
        left: 0,
        right: 20,
        top: 7.5,
        bottom: 7.5
    },
    height: 25,
    width: 160,
    type: BarChartType.HORIZONTAL,
    aggregateLabel: {
        enabled: true,
        margin: {
            left: 5,
            top: 26
        },
        style: {
            'font-weight': 'bold',
            'font-size': '16px'
        },
        fill: '#999999'
    },
    bar: {
        range: {
            min: 0,
            max: 200
        },
        domain: {
            min: 0,
            max: 200
        },
        margin: {
            top: 7.5,
            right: 0.5,
            bottom: 7.5
        },
        height: 25,
        tooltip: false,
        dataLabel: false,
        dataLabelLines: false
    }
};
