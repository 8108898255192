import { UserInfoState } from '@platform/store/state/user-info.state';
import { State } from '@platform/store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * UserInfo feature selector.
 *
 * @export
 * @property
*/
export const selectUserInfoFeature = createFeatureSelector< UserInfoState>('userInfo');
/**
 * UserInfo selector.
 *
 * @export
 * @property
*/
export const selectUserInfo = createSelector(
  selectUserInfoFeature,
  (state: UserInfoState) => state
);
