
/**
 * static chart options.
 *
 * @export
 * @property
*/
//yet to add more
export const quadMapPlotOptions = {
  margin: {
    top: 20,
    right: 20,
    bottom: 50,
    left: 50
  },

  width: 1700,
  height: 400,

  axes: ['xAxis', 'yAxis'],
  quadrants: ['quad1', 'quad2', 'quad3', 'quad4'],

  dividerLines: ['xDividerLine', 'yDividerLine'],

  xAxis: {
    class: "x axis",
    color: '#cccccc',
    fillColor: '#dedede',
    strokeWidth: 3,
    axisLabelText: ''
  },
  yAxis: {
    class: "y axis",
    color: '#cccccc',
    fillColor: '#dedede',
    strokeWidth: 3,
    axisLabelText: ''
  },

  xDividerLine: {
    fillColor: '#dedede',
    strokeWidth: 1
  },
  yDividerLine: {
    fillColor: '#dedede',
    strokeWidth: 1
  },

  quad1: {
    quadLabelText: 'shared.deliverables.correlations.quadMap.chart.quad1.label',
    fillColor: '#f7f7f7',
    clickColor: 'transparent',
    dataPointColor: '#999999',
    dataPointFontWeight: 'bold',
    dataPointFontSize: '14px',
    margin: {
      left: 40
    }
  },
  quad2: {
    quadLabelText: 'shared.deliverables.correlations.quadMap.chart.quad2.label',
    fillColor: '#f7f7f7',
    clickColor: 'transparent',
    dataPointColor: '#26bb10',
    dataPointFontWeight: 'bold',
    dataPointFontSize: '14px',
    margin: {
      left: 40
    }
  },
  quad3: {
    quadLabelText: 'shared.deliverables.correlations.quadMap.chart.quad3.label',
    fillColor: '#f7f7f7',
    clickColor: 'transparent',
    dataPointColor: '#d60037',
    dataPointFontWeight: 'bold',
    dataPointFontSize: '14px',
    margin: {
      left: 40
    }
  },
  quad4: {
    quadLabelText: 'shared.deliverables.correlations.quadMap.chart.quad4.label',
    fillColor: '#f7f7f7',
    clickColor: 'transparent',
    dataPointColor: '#b8b9bb',
    dataPointFontWeight: 'bold',
    dataPointFontSize: '14px',
    margin: {
      left: 40
    }
  }
};
