<mat-tree [dataSource]="datasource" [treeControl]="activationProfileService.treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" class="child-node" [class]="node.class">
    <div class="mat-tree-node child-text">
      <span
        [class.bold]= "bold"
        [matTooltip]="node.tooltip" matTooltipShowDelay="500">
        {{node.name}}
      </span>
    </div>
  </mat-tree-node>

  <!-- This is the tree node template for expandable nodes -->
  <!--suppress AngularUndefinedBinding -->
  <mat-nested-tree-node  *matTreeNodeDef="let node; when: hasChild">
    <div class="parent-node-container"  [class]="node.class" >
      <div class="mat-tree-node parent-node">
        <button mat-icon-button matTreeNodeToggle (click)="toggleNode(node)" class="toggle-button">
          <mat-icon class="mat-icon">
            {{activationProfileService.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
          <span
            class="parent-text"
            [matTooltip]="node.tooltip" matTooltipShowDelay="500">
          {{node.name}}
        </span>
        </button>

      </div>
      <div [class.dataset-tree-invisible]="!activationProfileService.treeControl.isExpanded(node)" class="children">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </div>
  </mat-nested-tree-node>

</mat-tree>
