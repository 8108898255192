import { Injectable } from '@angular/core';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoryDistinctionService {

  constructor(private activationProfileService: ActivationProfileService) { }

  /**
   * set category distinction tree
   *
   * @returns {TreeNodeData}
   * @memberof CategoryDistinctionService
   */
  setCategoryDistinction(headerList: string | string[], measureDescription: MeasureDescription, categoryDistinction: TreeNodeData[]): TreeNodeData[] {
    if (headerList.indexOf('categoryDistinction') > -1) {
      categoryDistinction = this.activationProfileService.buildMeasures(measureDescription.categoryDistinction, 'Parent', 'categoryDistinction', 1, [], null, this.activationProfileService.getTooltip('cd.' + measureDescription.categoryDistinction), true);
      if (headerList.indexOf('advantageFactorColor') > -1) {
        const advantageFactorColor = this.activationProfileService.buildMeasures(measureDescription.advantageFactorColor, 'Leaf', 'advantage', 2, null, null, null, true, 4);
        categoryDistinction[0].children.push(advantageFactorColor[0]);
      }
      if (headerList.indexOf('distinctPropositionFactorColor') > -1) {
        const distinctPropositionFactorColor = this.activationProfileService.buildMeasures(measureDescription.distinctPropositionFactorColor, 'Leaf', 'distinctBenefits', 2, null, null, null, true, 4);
        categoryDistinction[0].children.push(distinctPropositionFactorColor[0]);
      }
      if (headerList.indexOf('categoryDistinctionCrossCategoryInteraction') > -1) {
        const categoryDistinctionInteraction = this.activationProfileService.buildMeasures(measureDescription.categoryDistinctionCrossCategoryInteraction, 'Leaf', 'crossCategoryInteraction', 2, null, null, null, true, 2);
        categoryDistinction[0].children.push(categoryDistinctionInteraction[0]);
      }
      if (headerList.indexOf('categoryDistinctionCategoryPremium') > -1) {
        const categoryDistinctionCategoryPremium = this.activationProfileService.buildMeasures(measureDescription.categoryDistinctionCategoryPremium, 'Leaf', 'categoryPremium', 2, null, null, null, true, 2);
        categoryDistinction[0].children.push(categoryDistinctionCategoryPremium[0]);
      }
    }
    return categoryDistinction;
  }
}
