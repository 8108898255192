<div class="deliverable-container">
  <div *ngIf="!scoreCardConcept" class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
      <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
          <ns-kebab-menu class="kabab-menu">
              <ul class="kebab-menu-list">
                  <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
              </ul>
          </ns-kebab-menu>
          <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{ 'disable-insight-btn': disableBtn }"
                class="insight-icon sif sif-insight" (click)="openInsightCreationForm()">
          </span>
          <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="report.id"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
      </ns-deliverable-nav-header>
  </div>
  <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableInsight" [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()">
  </ns-deliverable-insight>
  <div id="deliverable-container">
    <!--Sorting Matrix filter-->
    <div class="filters-sticky-container" [ngClass]="{'hide': isInsightEnable}">
      <qs-filter (filterUpdates)="getFilterStatus($event)"></qs-filter>
    </div>
    <div class="sorting-matrix-content deliverable-insight-sorting-matrix insight-html-root" *ngIf="axisData">
      <div class="row deliverable-insight-sorting-matrix-row">
        <!--Chart component-->
        <div class="col-md-5 pr-10 deliverable-insight-sorting-matrix-chart-col">
          <qs-sm-chart [conceptsStream]="concepts$" [axisData]="axisData" [selectedConcept]="selectedConcept"
            (conceptSelected)="highlightConcept($event)" [filterUpdates]="filterStatus"></qs-sm-chart>
        </div>
        <!--Table Component-->
        <div class="col-md-6 pl-10 deliverable-insight-sorting-matrix-table-col">
          <qs-sm-table [concepts$]="concepts$" [axisData]="axisData" (hovered)="highlightConcept($event)"
            [selectedConcept]="selectedConcept"></qs-sm-table>
        </div>
      </div>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
