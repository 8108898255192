import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {WordCloudFilter} from "./filter.model";
import {DeliverableViewType} from "@app/deliverables/word-cloud/models/deliverable-view-type.enum";

/**
 * Default filter values for word-cloud.
 *
 * @export
 * @property
 */
export const defaultWordCloudFilter: WordCloudFilter = {
    deliverableType: DeliverableType.WORD_CLOUD.type,
    deliverableViewType: DeliverableViewType.WORD_CLOUD_CONCEPTS,
    compare: [
        {
            name: 'Concepts',
            id: 'WordCloudConcepts',
            isSelected: true
        },
        {
            name: 'Subgroups',
            id: 'WordCloudSubgroups',
            isSelected: false
        }
    ],
};

