<div class="sticky-container facet-container" *ngIf="filter">
    <div class="air-filters-bar clearfix">
        <div class="float-left">
          <ng-container *ngIf="!concept">
              <ns-dropdown id="conceptsFilter"
                           class="filter-item"
                           [dropDownData]="regConceptsListData"
                           (selectionChange)="selectConcept($event.data)">
              </ns-dropdown>
            <ns-dropdown id="compConceptsFilter"
                         class="filter-item"
                         [dropDownData]="compConceptsListData"
                         (selectionChange)="selectConcept($event.data)">
            </ns-dropdown>

            <ns-dropdown id="countryFilter"
              class="filter-item"
              [dropDownData]="countryListData">
            </ns-dropdown>
            <ns-dropdown id="subgroupsFilter"
              class="filter-item"
              [dropDownData]="subgroupListData"
              (selectionChange)="selectSubgroup($event.data)">
            </ns-dropdown>
          </ng-container>
        </div>
    </div>
</div>
