<ns-deliverable-configuration-header
    [headerText]="'Factors for Success'"
    [showBackButton]=true
    (backBtnClickEvent)="returnBack()"
    (closeBtnClickEvent)="onCloseButtonClick()"></ns-deliverable-configuration-header>
<ng-container  *ngFor="let item of flyoutConfigItems">
    <div *ngIf="item.showInFlyout" class="item-row border-bottom main-menu-item">
        <span class="display-name-span">{{item.displayName}}</span>
        <button mat-icon-button
                class="visibility-button text-radioBtn-visbility">
                <span class="sif sif-gray cursor-pointer" (click)="toggleVisibility(item)"
                      [ngClass]="item.isSelected? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
        </button>
    </div>
</ng-container>
