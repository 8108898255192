import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { DeliverablesComponent } from './deliverables.component';
import { DeliverablesGuard } from '@platform/guards/deliverables.guard';

// Components Import

/**
 * All routes for the forecasting module.
 *
 * @property routes
 * @private
 */
const routes: Routes = [
    {
        path: '',
        component: DeliverablesComponent,
        canActivate: [DeliverablesGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DeliverablesRoutingModule {
}
