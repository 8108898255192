import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StrengthWatchoutsConcept} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';

@Component({
  selector: 'shuffle-concept-image',
  templateUrl: './shuffle-concept-image.component.html',
  styleUrls: ['./shuffle-concept-image.component.scss']
})
export class ShuffleConceptImageComponent implements OnInit, OnDestroy {

  interval: any;
  concept: StrengthWatchoutsConcept;
  currentIndex: number;
  @Input() currentZone: string;
  @Input() concepts: StrengthWatchoutsConcept[];
  @Output() conceptEvent: EventEmitter<StrengthWatchoutsConcept> = new EventEmitter<StrengthWatchoutsConcept>();


  ngOnInit() {
    this.currentIndex = 0;
    this.concept = this.concepts[0];
    this.conceptEvent.emit(this.concept);
    this.interval = setInterval(() => {
      if (this.currentIndex < this.concepts.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
      this.concept = this.concepts[this.currentIndex];
      this.conceptEvent.emit(this.concept);
    }, 4000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.interval = 0;
  }
}
