import { Action, createReducer, on } from '@ngrx/store';
import { FilterState } from '@platform/store/state/filter.state';
import { addFilter, updateFilter } from '@platform/store/actions/filter.actions';

/**
 * Reducer for all filter actions.
 */
const filterReducer = createReducer(
  {},
  on(addFilter, (state, { filter }) => {
    const newFilter = {...state};
    newFilter[filter.deliverableType] = filter;
    return newFilter;
  }),
  on(updateFilter, (state, { filter }) => {
    const newFilter = {...state};
    newFilter[filter.deliverableType] = filter;
    return newFilter;
  })
);

/**
 * Filters `NgRx` reducer.
 *
 * @export
 * @param {FilterState} state
 * @param {FilterActions} action
 * @returns {FilterState}
 */
export function reducer(state: FilterState | undefined, action: Action): FilterState {
  return filterReducer(state, action);
}
