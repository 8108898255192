<div *ngIf="expansion">
    <div  class="name-container image-padding">
        <span>{{datasetName}}</span>
        <br>
        <activation-profile-item-image [locale]="locale" [concept] = "concept"></activation-profile-item-image>
    </div>
</div>
<div *ngIf="!expansion" class="name-container">
    <span class="cursor-pointer" [matTooltipClass]="'activation-profile-tooltip'" [matTooltip]="tooltipText" [matTooltipPosition]="'above'">{{datasetName}}</span>
</div>
