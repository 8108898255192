import {Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';
import {ConfirmationDialogService} from '@app/common/confirmation-dialog/confirmation-dialog.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DeliverableConfigurationUtilService} from '@platform/services/deliverable-configuration-util.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {ReportService} from '@platform/services/report.service';


@Component({
    selector: 'ns-financial-potential-configuration',
    templateUrl: './financial-potential-configuration.component.html',
    styleUrls: ['./financial-potential-configuration.component.scss']
})
export class FinancialPotentialConfigurationComponent implements OnInit {
    /**
     * The initial financial potential deliverable configuration passed from ReportSettingsComponent.
     */
    @Input() deliverableConfiguration: DeliverableConfiguration;
    /**
     * Event trigger to move to the previous menu.
     */
    @Output() moveToPreviousMenuEvent = new EventEmitter();
    /**
     * Event trigger to close the flyout menu.
     */
    @Output() closeFlyoutMenuEvent = new EventEmitter();
    /**
     * Event trigger after changes are saved.
     */
    @Output() configUpdated = new EventEmitter();
    /**
     * Object to store the relevant metaInfo data. Will be updated with data from the deliverable configuration .config.
     */
    configMap: Array<any> = [];
    /**
     * Array of keys created from the deliverableConfiguration.config, used to display each option's text.
     */
    configKeys = [];
    clonedDeliverableConfiguration: DeliverableConfiguration;

    constructor(
        private deliverableConfigurationService: DeliverableConfigurationService,
        private confirmationDialogService: ConfirmationDialogService,
        private dialog: MatDialog,
        private reportService: ReportService,
        private spinnerService: SpinnerService,
        private deliverableConfigurationUtilService: DeliverableConfigurationUtilService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initialize();
    }

    /**
     * Initialize the Financial Potential configuration component view.
     * @memberof DeliverableConfigurationComponent
     */
    ngOnInit(): void {
        this.initialize();
    }

    /**
     * Initialize values needed to view and update the Financial Potential Flyout.
     */
    initialize(): void {
        this.clonedDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(this.deliverableConfiguration);
        this.setColumnInfo();
    }

    /**
     * Method to get the names and boolean values for the columnHeaders.
     */
    setColumnInfo(): void {
        this.configMap = {
            ...this.clonedDeliverableConfiguration.config
        };
        this.configKeys = ['purchaseIntent', 'trialUnits', 'frequency', 'conceptPotentialScore'];

    }

    /**
     * Method to handle onClick.
     * @param column the columnHeader value the user is changing.
     */
    toggleView(column): void {
        if (column === 'units') {
            column = 'trialUnits';
        }
        this.configMap = {
            ...this.configMap,
            [column]: !this.configMap[column]
        };
        this.clonedDeliverableConfiguration = {
            ...this.clonedDeliverableConfiguration,
            config: this.configMap
        };
        this.configUpdated.emit(this.clonedDeliverableConfiguration);
    }

    /**
     * Method to trigger navigation back to the previous flyout page.
     */
    returnBack(): void {
        this.moveToPreviousMenuEvent.emit();
    }

    /**
     * Method to trigger closing of the flyout menu.
     */
    onCloseButtonClick(): void {
        this.closeFlyoutMenuEvent.emit();
    }

    /**
     * Handles clicking off of the flyout, either closing the flyout or bringing up the discard dialog.
     * @param event click off of flyout detected.
     */
    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.onCloseButtonClick();
        }
    }
    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.onCloseButtonClick();
        }
    }
}
