<div class="filter-section">
  <div class="flex-search-filter">
    <div class="search-bar align-items-center">
      <span class="sif sif-search"></span>
      <ns-search-input class="search-input" [searchInputValue]="searchPhase" [matTooltip]="searchTooltipText"
                       [matTooltipClass]="['custom-tooltip', 'pre-tooltip']" [appendPlaceholder]="'Enter project name or study number'" (searchText)="setSearchText($event)">
      </ns-search-input>
    </div>
    <div class="search-input-container">
      <div class="air-filters-bar clearfix" *ngIf="organizationListData">
        <div class="dropdown-float-left">
          <div class="dropdown-input">
                <ns-benchmark-dropdown [displayType]="'regular'" [displayOptions]="organizationListData" [arrayName]="'organizations'"
                           [flagKey]="'isUserPreference'" [displayKey]="'name'" [addRadioIndent]="false"  [displayChildKey]="'name'" [childKey]="'subtasks'"
                           (userSelections)="setSelectedFilterOption($event, 'organization')" (ddMenuClosed)="menuClosed = $event">
            </ns-benchmark-dropdown>
          </div>

          <div class="dropdown-input">
            <ns-benchmark-dropdown [displayType]="'checkbox'" [displayOptions]="countryListData" [arrayName]="'locales'"
                                   [flagKey]="'isUserPreference'" [displayKey]="'name'" [displayChildKey]="'name'" [childKey]="'subtasks'"
                                   (userSelections)="setSelectedFilterOption($event, 'locales')" (nullSelections)="isLocalesNull = $event"
                                   (ddMenuClosed)="menuClosed = $event">
            </ns-benchmark-dropdown>
          </div>

          <div class="dropdown-input">
            <ns-benchmark-dropdown [displayType]="'checkbox'" [displayOptions]="solutionListData" [arrayName]="'solutions'"
                                   [flagKey]="'isUserPreference'" [displayKey]="'name'" [displayChildKey]="'name'" [childKey]="'subtasks'"
                                   (userSelections)="setSelectedFilterOption($event, 'solutions')" (ddMenuClosed)="menuClosed = $event">
            </ns-benchmark-dropdown>
          </div>

        </div>
        <div class="controller-btn">
          <button mat-button mat-flat-button color="secondary" class="mat-flat-button mat-secondary hover-btn" (click)="applyFilters()">Apply & Search</button>
        </div>
      </div>
    </div>
    <div class="result-count-container" *ngIf="totalReportsSearched > 0">
      <span matTooltipPosition="above" matTooltip="{{'platform.import.benchmark.totalreportsearch.text' | translate }}" class="count">{{totalReportsSearched}} Result(s)</span>
    </div>
  </div>

</div>
