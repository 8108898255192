import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'bases-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})

export class PromptComponent implements OnInit, OnChanges {
  primary: boolean = false;
  secondary: boolean = false;
  hero: boolean = false;
  tertiary: boolean = false;

  isSuccess: boolean = false;
  isError: boolean = false;
  isInformation: boolean = false;
  isWarn: boolean = false;
  isDefault: boolean = false;

  show: boolean = false;
  @Input() isExpanded: boolean = false;

  @Input() color: 'error' | 'information' | 'success' | 'warn' | 'default' = 'default';
  @Input() duration: number = 15;
  @Input() showPrompt: boolean = false;
  @Input() messageList: string[] = [];
  @Input() hideDismiss: boolean = false;
  @Input() headerText: string = '';
  @Output() promptClosed = new EventEmitter<boolean>();

  @ViewChild('prompt')
  promptTemplate!: TemplateRef<any>;
  cachedHeaderText:any;
  routerSubscription: any;

  constructor(
    private elementRef: ElementRef,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.initializeAttributes();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._snackBar.dismiss();
      }
    });
  }

  ngOnInit(): void {
    this.setColor();
    this.updatePromptDisplay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showPrompt'] || changes['messageList']) {
      this.updatePromptDisplay();
      this.cachedHeaderText = this.getHeaderText();
    }
  }

  private initializeAttributes(): void {
    const element = this.elementRef.nativeElement;
    if (element.hasAttribute('secondary')) this.secondary = true;
    else if (element.hasAttribute('hero')) this.hero = true;
    else if (element.hasAttribute('tertiary')) this.tertiary = true;
    else this.primary = true;
  }

  private setColor(): void {
    switch (this.color) {
      case 'success':
        this.isSuccess = true;
        break;
      case 'error':
        this.isError = true;
        break;
      case 'information':
        this.isInformation = true;
        break;
      case 'warn':
        this.isWarn = true;
        break;
      default:
        this.isDefault = true;
        break;
    }
  }

  private updatePromptDisplay(): void {
    this.show = this.showPrompt;
    if (this.show && this.primary) {
      this._snackBar.dismiss();
      setTimeout(() => {
        this._snackBar
        .openFromTemplate(this.promptTemplate, {
          duration: this.duration * 1000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: 'common-modalbox',
        })
        .afterDismissed()
        .subscribe(() => {
          this.closePrompt();
          this.cd.detectChanges();
        });
      }, 100);
      
    }
  }

  closePrompt(): void {
    if (this.primary) this._snackBar.dismiss();
    else {
      this.show = false;
    }
    this.promptClosed.emit(true);
  }

  getHeaderText() {
    
    if (this.headerText) {
      let headerStr = this.headerText.replace('{{count}}', this.messageList.length.toString());
      return headerStr;
    } else if (this.messageList.length > 1) {
      const count = this.messageList.length.toString();
      if (this.isError) return `${count} Errors found.`;
      else if (this.isWarn) return `${count} Warnings found.`;
      else if (this.isInformation) return `${count} Informations found.`;
      else if (this.isSuccess) return `${count} Success found.`;
      else return `${count} found.`;
    } else {
      return '';
    }
  }

  toggleExpansion(): void {
    this.isExpanded = !this.isExpanded;
  }
}
