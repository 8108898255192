import { Subgroup } from '@platform/models/subgroup.model';
import { FilterService } from '@platform/services/filter.service';
import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { DeliverableViewService } from '@platform/services/deliverable-view.service';
import { take, switchMap } from 'rxjs/operators';
import { ReportService } from '@platform/services/report.service';
import { SubgroupService } from '@platform/services/subgroup.service';
import { ConceptService } from '@platform/services/concept.service';
import { Concept } from '@platform/models/concept.model';
import { PerformanceDeliverableView } from '../models/performance.model';
import { FilterItem, PerformanceFilter } from '../models/filter.model';
import { defaultPerformanceFilter } from '../models/default-performance-filter.model';
import {Deliverable} from "@platform/models/deliverable.model";
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';

/**
 * `PerformanceService` has all data operations for fetching performance deliverables
 * and performance deliverables filter data.
 *
 * @export
 * @class PerformanceService
 */
@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  /**
   * Creates an instance of PerformanceService.
   *
   * @constructor
   * @param {DeliverableViewService} deliverableViewService
   * @param {ReportService} reportService
   * @param {ConceptService} conceptService
   * @param {SubgroupService} subgroupService
   * @param {FilterService} filterService
   * @memberof PerformanceService
   */
  constructor(
    private deliverableViewService: DeliverableViewService,
    private reportService: ReportService,
    private conceptService: ConceptService,
    private subgroupService: SubgroupService,
    private filterService: FilterService) { }

  /**
   * Returns an observable of `PerformanceDeliverableView` data.
   *
   * @returns {Observable<PerformanceDeliverableView>}
   * @memberof PerformanceService
   */
  public getPerformance(): Observable<PerformanceDeliverableView> {
    const deliverableType = DeliverableType.PERFORMANCE.type;
    const filter$ = this.getPerformanceFilter();
    const performance$: Observable<PerformanceDeliverableView> = filter$.pipe(
      switchMap(filter => {
        return this.deliverableViewService.filter<PerformanceDeliverableView, PerformanceFilter>(
          filter.deliverableViewType,
          deliverableType,
          this.filter.bind(this)
        );
      })
    );
    return performance$;
  }

  /**
   * Filter projection function for filtering the performance data and returns
   * filtered `PerformanceDeliverableView` data.
   *
   * @private
   * @param {PerformanceFilter} filter
   * @param {PerformanceDeliverableView} data
   * @returns {PerformanceDeliverableView}
   * @memberof PerformanceService
   */
  private filter(filter: PerformanceFilter, data: PerformanceDeliverableView): PerformanceDeliverableView {
    const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
    const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
    const filteredSubgroups = [];
    let filteredSubgroup;
    let filteredDataItems;
    let filteredView;
    for (const subgroup of data.subgroups) {
      if (selectedSubgroups.includes(subgroup.segmentId)) {
        filteredDataItems = subgroup.conceptData.filter(d => selectedConcepts.includes(d.conceptId));
        filteredSubgroup = {
          conceptData: filteredDataItems,
          segmentId: subgroup.segmentId,
          baseSize: subgroup.baseSize,
          position: filter.subgroups.find( it => it.id === subgroup.segmentId ).position
        };
        filteredSubgroups.push(filteredSubgroup);
      }
    }
    filteredSubgroups.sort(function (a, b) {
      return a.position - b.position;
    });
    filteredView = Object.assign({}, data, { subgroups: filteredSubgroups });
    return filteredView;
  }

  /**
   * Returns observable of performance filter data.
   *
   * @returns {Observable<PerformanceFilter>}
   * @memberof PerformanceService
   */
  public getPerformanceFilter(): Observable<PerformanceFilter> {
    const deliverableType = DeliverableType.PERFORMANCE.type;
    const filter$ = this.filterService.get<PerformanceFilter>(deliverableType);
    return filter$;
  }

  /**
   * Loads default performance filter data.
   *
   * @memberof PerformanceService
   */
  public loadDefaultFilter(): void {
    const defaultFilter: PerformanceFilter = Object.assign({}, defaultPerformanceFilter);
    const report$ = this.reportService.get();
    const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.PERFORMANCE.type);
    const subgroups$ = this.subgroupService.getReportSubgroups();
    zip(report$, concepts$, subgroups$).pipe(take(1)).subscribe(result => {
      defaultFilter.countries = [result[0].country];
      defaultFilter.concepts = this.getConceptFilters(result[1]);
      defaultFilter.subgroups = this.getSubgroupFilters(result[2]);
      this.filterService.update(defaultFilter);
    });
  }

  /**
   * Returns concept filter items.
   *
   * @private
   * @param {Array<Concept>} concepts
   * @returns {Array<FilterItem>}
   * @memberof PerformanceService
   */
  private getConceptFilters(concepts: Array<Concept>): Array<FilterItem> {
    const conceptFilterItems: Array<FilterItem> = [];
    for (const item of concepts) {
      conceptFilterItems.push({
        name: item.name,
        id: item.exerciseConceptId,
        isSelected: true,
        position: item.position
      });
    }
    return conceptFilterItems;
  }

  /**
   * Returns subgroup filter items.
   *
   * @private
   * @param {Array<Subgroup>} subgroups
   * @returns {Array<FilterItem>}
   * @memberof PerformanceService
   */
  private getSubgroupFilters(subgroups: Array<Subgroup>): Array<FilterItem> {
    const subgroupFilterItems: Array<FilterItem> = [];
    for (const item of subgroups) {
      subgroupFilterItems.push({
        name: item.name,
        id: item.segmentId,
        isSelected: true,
        position: item.position
      });
    }
    return subgroupFilterItems;
  }

  /**
   * Returns updated map of Selected filter items for insights.
   *
   * @private
   * @param filter
   * @returns {Array<FilterItem>}
   * @memberof PerformanceService
   */
  public getSelectedFilterItem(filterItem) {
    const selectedFilterItems = [];
    for (const item of filterItem) {
      selectedFilterItems.push({
        name: item.name,
        id: item.id,
      });
    }
    return selectedFilterItems;
  }

  /**
   * Returns Selected show filter items for insights.
   *
   * @private
   * @param filter
   * @returns {Array<FilterItem>}
   * @memberof PerformanceService
   */
  public getSelectedShowFilter(filterItem) {
    const insight = {};
    for (const [k, v] of Object.entries(filterItem)) {
      if (k === 'percents' && v['isSelected'] === true) {
        for (const [key, val] of Object.entries(v)) {
          if (val === true) {
            insight[key] = val;
          }
        }
      }
      if (v === true) {
        insight[k] = v;
      }
    }
    return insight;
  }

}
