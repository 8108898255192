import { createAction, props } from '@ngrx/store';
import { UserInfo } from '@platform/models/user-info.model';

/**
 * Action for adding a userInfo.
 * 
 * @export
 */
export const addUserInfo = createAction(
  '[UserInfo] Add UserInfo',
  props<{ userInfo: UserInfo }>()
);
