
import { DeliverableType } from "@app/deliverables/deliverable-type.enum";
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, SimpleChanges, OnChanges, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import {combineLatest, fromEvent, Observable, Subscription, take} from 'rxjs';
import {FilterItem, ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {
  CombinationDataItem,
  ReachAnalysisDeliverableView
} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';
import {ReachAnalysisService} from '@app/deliverables/reach-analysis/services/reach-analysis.service';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ReachAnalysisMetaInfo} from '@app/deliverables/reach-analysis/models/view-meta-info.model';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {FilterService} from '@platform/services/filter.service';
import { MixpanelService } from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelReach} from '@src/assets/utils/mixpanel-enum';
import {Report} from '@platform/models/report.model';

@Component({
  selector: 'ns-alternative-combination',
  templateUrl: './alternative-combination.component.html',
  styleUrls: ['./alternative-combination.component.scss']
})
export class AlternativeCombinationComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() reachAnalysis: ReachAnalysisDeliverableView;
  @Input() displaySpinner: boolean;

  public sets: Array<number> = [];

  /**
   * Meta info for Attributes
   *
   * @type {AttributesDeliverableView}
   * @memberOf AlternativeCombinationComponent
   */
  public viewReachAnalysisMetaInfo: ReachAnalysisMetaInfo;

  public filteredVarieties = [];
  public trimmedNames = [];
  /**
   * ReachAnalysis deliverable view filter object.
   *
   * @type {AttributesFilter}
   * @member SummaryViewComponent
   */
  public filter: ReachAnalysisFilter;
  public possibleCombinations;
  sortVarietiesSetting = 'desc';
  public basesLabel: string;
  offset = 0;
  public combinations: Array<CombinationDataItem>;
  public selectedSet = 1;
  combinationsCount = 0;
  public percentageText : string;

  /**
   * pages paginator instance
   *
   * @type {MatPaginator}
   * @memberOf GapAnalysisComponent
   */
  @ViewChild('alternativePagination', {static: false}) alternativePagination: MatPaginator;

  combinations$: Observable<CombinationDataItem[]>;
  dataSource: MatTableDataSource<CombinationDataItem>;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  @ViewChild('box') box: ElementRef;
  topLegendWidth = 0;
  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member SummaryViewComponent
   */
  public subscriptions: Array<Subscription>;
  constructor(
    private reachAnalysisService: ReachAnalysisService,
    private filterService: FilterService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewMetaInfoService: ViewMetaInfoService,
    private mixpanelService: MixpanelService
  ) {
    this.subscriptions = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const reachAnalysis = changes.reachAnalysis;
    if (reachAnalysis && reachAnalysis.currentValue) {
      this.refresh();
    }
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(e => {
      this.onResize();
    });
    this.subscriptions.push(this.resizeSubscription$);
  }

  refresh(): void {
    const filter$ = this.reachAnalysisService.getReachAnalysisFilter();
    const deliverableType = DeliverableType.REACH_ANALYSIS.type;
    const viewMetaInfo$ = this.viewMetaInfoService.get<ReachAnalysisMetaInfo>(deliverableType);
    const subscription = combineLatest([filter$, viewMetaInfo$])
      .pipe(take(1)).subscribe(([filters, viewMetaInfo]) => {
      const combinations = this.reachAnalysis.combinations;
      const totalVarieties = filters.concepts.find(it => it.isSelected).varieties.length;
      this.filteredVarieties = this.reachAnalysisService
        .getFilteredVarieties(totalVarieties, combinations, filters, 'desc');
      this.setTrimmedVarietyNames();
      if (this.reachAnalysis.viewType === 'set') {
        this.combinations = this.reachAnalysisService.getFilteredCombinations(combinations, filters);
      }
      this.sets = this.getSets(filters.show.combinationSets);
      this.filter = filters;
      this.percentageText = this.reachAnalysis.basesLabel === 'OBC' ? 'shared.deliverables.reach.filter.benefit.chart.label' : 'shared.deliverables.reach.filter.varieties.chart.label';
      this.viewReachAnalysisMetaInfo = {...viewMetaInfo};
      this.selectedSet = filters.show.combinationSets.find(it => it.isSelected === true).id;
      const selectedVarietiesCount = this.filter.concepts.find(it => it.isSelected).varieties.filter(it => it.isSelected === true).length;
      const setNumber = this.filter.show.combinationSets.find(it => it.isSelected === true).id;
      this.possibleCombinations = this.reachAnalysisService.calculateCombinations(filters, selectedVarietiesCount, setNumber, combinations);
      this.dataSource = new MatTableDataSource<CombinationDataItem>(this.combinations);
      this.combinations$ = this.dataSource.connect();
      this.combinationsCount = this.combinations?.length;
      this.displaySpinner = combinations[0]?(combinations[0].setNumber !== setNumber):false
      this.onResize();
      this.sortVarietiesSetting = 'desc';
        this.setPageIndex();
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Check the pagelength and set to zero
   *  */

  setPageIndex() {
    if (this.filter.show.pageNumber && this.alternativePagination) {
      this.alternativePagination.pageIndex = this.filter.show.pageNumber - 1;
      // this.alternativePagination.pageIndex = 0;
    }
  }

  /**
   * Triggered when window is resized
   * Sets the width of top legend section of template
   */
  private onResize() {
    this.topLegendWidth = (this.combinationsCount <= 1 && this.box == undefined) ? 45 :this.box?.nativeElement.offsetWidth * this.combinationsCount;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * After view init hook
   */
  ngAfterViewInit() {
    this.onResize();
    if(this.alternativePagination){
      this.alternativePagination._intl.previousPageLabel = 'Previous Set';
      this.alternativePagination._intl.nextPageLabel = 'Next Set';
    }
  }

  /**
   * Sets the Variety Name with Cropped Text
   */
  setTrimmedVarietyNames() {
    const trimmedNames = [];
    this.filteredVarieties.forEach( variety => {
      trimmedNames.push({
        fullName: variety.name,
        name: variety.name,
        croppedName: (variety.name.length > 45) ? variety.name.substring(50, variety.name.length) + '...' : ''
      });
    });
    this.trimmedNames = trimmedNames;
  }

  /**
   * Clones filter component.
   */
  private cloneFilter(): ReachAnalysisFilter {
    const filter = this.filter;
    const newShowFilter = {...filter.show};
    return {...filter, show: newShowFilter};
  }

  selectSetNumber(filterName: any) {
    this.mixpanelService.track(MixpanelLabel.reach, MixpanelReach.changeCombinations);
    const filter = this.cloneFilter();
    const show = filter.show;
    show.setNumber = filterName;
    show.combinationSets = JSON.parse(JSON.stringify(show.combinationSets));
    if (filterName) {
      show.combinationSets.forEach(set => {
        set.isSelected = set.id === filterName;
      });
    }
    show.paging = 0;
    show.reload = true;
    show.pageNumber = 0;
    this.offset = 0;
    this.alternativePagination.pageIndex = 0;
    this.sortVarietiesSetting = 'desc';
    this.viewReachAnalysisMetaInfo.sortOrder = this.sortVarietiesSetting;
    this.resetLockedVarieties(filter);
  }

  /**
  * reset locked varieties.
  */
  resetLockedVarieties(filter) {
      this.filterService.update({
        ...filter,
        show: {
          ...filter.show,
          reload: true
        },
        concepts: filter.concepts.map((concept) => {
          return {
            ...concept,
            varieties: concept.varieties.map((variety) => {
              if (concept.isSelected === true) {
                return {
                  ...variety,
                  isLocked: false
                };
              } else {
                return {...variety};
              }
            })
          };
        })
      });
  }


  /**
   * Update row highlights information in view meta info store.
   *
   * @memberof AlternativeCombinationComponent
   */
  setUserView(selectedSet: number) {
    const deliverableType = DeliverableType.REACH_ANALYSIS.type;
    this.viewMetaInfoService.update({ deliverableType, setNumber: selectedSet }, deliverableType);
  }

  /**
   * Set sets value for dropdown.
   *
   * @memberof AlternativeCombinationComponent
   */
  getSets(selectedSets: Array<FilterItem>): Array<number> {
    const selectedSetsValue = selectedSets.filter(it => it.isSelected === true);
    const sets: Array<number> = [];
    selectedSetsValue.forEach(it => {
      sets.push(it.id);
    });
    if (selectedSetsValue.length === 0) {
      sets.push(this.selectedSet);
    }
    return sets;
  }

  /**
   * Pagination changed
   * @param evt {PageEvent}
   */
  pageChanged(evt: PageEvent) {
    const filter = this.cloneFilter();
    const show = filter.show;
    if (evt.pageIndex > 0 && evt.previousPageIndex < evt.pageIndex) {
      this.offset = show.paging + 20;
      show.pageNumber = evt.pageIndex + 1;
    }
    if (evt.previousPageIndex > evt.pageIndex) {
      this.offset = show.paging - 20 ;
      show.pageNumber = show.pageNumber - 1;
    }
    show.paging = this.offset ;
    show.reload = true;
    this.sortVarietiesSetting = 'desc';
    this.viewReachAnalysisMetaInfo.sortOrder = this.sortVarietiesSetting;
    this.filterService.update(filter);
  }

  /**
   * Cleanup hook.
   *
   * @memberOf SummaryViewComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * set view with sorted varieties.
   *
   * @private
   * @returns {Array<any>}
   * @param sortVarietiesSetting
   */
  sortView(sortVarietiesSetting): void {
    const filteredVarieties = this.filter.concepts.find(it => it.isSelected).varieties;
    const totalVarieties = this.filter.concepts.find(it => it.isSelected).varieties.length;
    if (sortVarietiesSetting === 'asc') {
      this.filteredVarieties = this.reachAnalysisService
      .getFilteredVarieties(totalVarieties, this.combinations, this.filter, 'alternativeView');
      this.sortVarietiesSetting = null;
    } else if (sortVarietiesSetting === 'desc') {
      this.filteredVarieties = this.reachAnalysisService
      .getSortedVarietiesWithCombinations(filteredVarieties, this.combinations, 'asc');
      this.sortVarietiesSetting = 'asc';
    } else {
      this.filteredVarieties = this.reachAnalysisService
      .getSortedVarietiesWithCombinations(filteredVarieties, this.combinations, 'desc');
      this.sortVarietiesSetting = 'desc';
    }
    this.viewReachAnalysisMetaInfo = {
      ...this.viewReachAnalysisMetaInfo,
      sortOrder: this.sortVarietiesSetting
    };
    const deliverableType = DeliverableType.REACH_ANALYSIS.type;
    this.viewMetaInfoService.update({ deliverableType, sortOrder: this.sortVarietiesSetting }, deliverableType);
    this.setTrimmedVarietyNames();
  }
}
