<div class="row" *ngIf="!setNumberIsSame; else progressSpinner">
  <div id="summary-view-chart" class="col-md-6" *ngIf="(combinations.length > 0 && totalVarieties > 0); else noData">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="chartOptions"
        [callbackFunction]="chartCallback"
        style="width: 100%; height: 500px; display: block;"
      ></highcharts-chart>
  </div>
  <div class="col-md-6 custom-legends" *ngIf="(combinations.length > 0 && totalVarieties > 0)">
    <div *ngIf="!showLegend">
      <p><span><b>{{totalVarieties}}</b>{{tableTitle | translate}} Tested</span></p>
      <p *ngFor="let varietyName of filteredVarieties">{{varietyName}}</p>
    </div>
    <div *ngIf="showLegend">
        <p><span class="variety-name"><b>{{legendHeader.value}}</b>{{ hoverStateTableTitle | translate : {index:legendHeader.index} }}</span></p>
        <p *ngFor="let varietyName of filteredVarieties">{{varietyName}}</p>
    </div>
  </div>

  <ng-template #noData>
    <div class="col no-data">
      <span class="data sif sif-no-results"></span>
      <h3>There are no Results</h3>
      <p>Try refining the filters to populate data.</p>
    </div>
  </ng-template>
</div>

<ng-template #progressSpinner>
  <mat-progress-spinner style="margin:0 auto;" color="secondary" diameter="100" mode="indeterminate" value="50"></mat-progress-spinner>
</ng-template>


