import {Injectable} from '@angular/core';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';

@Injectable({
  providedIn: 'root'
})
export class ExecutionalPotentialService {

  constructor(private activationProfileService: ActivationProfileService) {
  }

  /**
   * set executional potential tree
   *
   * @returns {TreeNodeData}
   * @memberof ExecutionalPotentialService
   */
  setExecutionalPotential(headerList: string | string[], measureDescription: MeasureDescription, executionalPotential: TreeNodeData[]): TreeNodeData[] {
    if (headerList.indexOf('executionalElements') > -1) {
      executionalPotential = this.activationProfileService.buildMeasures(measureDescription.executionalElements, 'Parent', 'executionalPotential', 1, [], 'executionalPotential', this.activationProfileService.getTooltip('ep.' + measureDescription.executionalElements), false);
      if (headerList.indexOf('acceptableCostsFactorColor') > -1) {
        const acceptableCostsFactorColor = this.activationProfileService.buildMeasures(measureDescription.acceptableCostsFactorColor, 'Leaf', 'acceptableCosts', 2, null, null, null, true, 4);
        executionalPotential[0].children.push(acceptableCostsFactorColor[0]);
      }
      if (headerList.indexOf('attentionCatchingFactorColor') > -1) {
        const attentionCatchingFactorColor = this.activationProfileService.buildMeasures(measureDescription.attentionCatchingFactorColor, 'Leaf', 'attentionCatching', 2, null, null, null, true, 4);
        executionalPotential[0].children.push(attentionCatchingFactorColor[0]);
      }
      if (headerList.indexOf('credibilityFactorColor') > -1) {
        const credibilityFactorColor = this.activationProfileService.buildMeasures(measureDescription.credibilityFactorColor, 'Leaf', 'credibility', 2, null, null, null, true, 3);
        executionalPotential[0].children.push(credibilityFactorColor[0]);
      }
      if (headerList.indexOf('messageConnectionFactorColor') > -1) {
        const messageConnectionFactorColor = this.activationProfileService.buildMeasures(measureDescription.messageConnectionFactorColor, 'Leaf', 'messageConnection', 2, null, null, null, true, 4);
        executionalPotential[0].children.push(messageConnectionFactorColor[0]);
      }
      if (headerList.indexOf('clearConciseMessageFactorColor') > -1) {
        const clearConciseMessageFactorColor = this.activationProfileService.buildMeasures(measureDescription.clearConciseMessageFactorColor, 'Leaf', 'clearConciseMessage', 2, null, null, null, true, 4);
        executionalPotential[0].children.push(clearConciseMessageFactorColor[0]);
      }
      if (headerList.indexOf('findabilityFactorColor') > -1) {
        const findabilityFactorColor = this.activationProfileService.buildMeasures(measureDescription.findabilityFactorColor, 'Leaf', 'findability', 2, null, null, null, true, 3);
        executionalPotential[0].children.push(findabilityFactorColor[0]);
      }
    }
    return executionalPotential;
  }
}
