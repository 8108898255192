import {EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {Organization} from "@platform/models/organization.model";
import {Benchmark} from "@platform/models/benchmark.model";

/**
 * Benchmark entity adapter.
 *
 * @export
 * @property {EntityAdapter<Organization>} organizationAdapter - The organization adapter.
 */
export const benchmarkAdapter: EntityAdapter<Benchmark> = createEntityAdapter<Benchmark>();
