export const ProductType = {
    QUICK_PREDICT: {
        type: 'quick-predict',
        labelKey: 'product.label.quick.predict'
    },
    QUICK_SCREEN: {
        type: 'quick-screen',
        labelKey: 'product.label.quick.screen'
    },
    QUICK_QUESTION: {
        type: 'quick-question',
        labelKey: 'product.label.quick.question'
    },
    RESTAGE: {
        type: 'restage',
        labelKey: 'product.label.restage'
    },
    PRICE_ADVISOR: {
        type: 'price-advisor',
        labelKey: 'product.label.price.advisor'
    },
    INNOVATION_SCREENER: {
        type: 'innovation-screener',
        labelKey: 'product.label.innovation.screener'
    },
    NIELSEN_OTHER: {
        type: 'nielsen-other',
        labelKey: 'product.label.nielsen.other'
    },
    DESIGN_DIAGNOSTICS: {
        type: 'design-diagnostics',
        labelKey: 'product.label.quick.predict'
    }
};
