import { ReportFile } from '@platform/report-files/report-file.model';
import { ReportFileState } from '@platform/store/state/report-file.state';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

/**
 * ReportFiles entity adapter.
 *
 * @export
 * @property {EntityAdapter<ReportFile>} reportFileAdapter - The reportFile adapter.
*/
export const reportFileAdapter: EntityAdapter<ReportFile> = createEntityAdapter<ReportFile>();

/**
 * Initial state.
 */
export const initialState: ReportFileState = reportFileAdapter.getInitialState({});
