/**
 * Default filter values for attributes.
 *
 * @export
 * @property
 */
import {DeliverableViewType} from "@app/deliverables/strengths-watchouts/models/deliverable-view-type-enum";
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {StrengthWatchoutsFilter} from "@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model";

export const defaultStrengthWatchoutsFilter: StrengthWatchoutsFilter = {
    deliverableType: DeliverableType.STRENGTH_WATCHOUTS.type,
    deliverableViewType: DeliverableViewType.CLICK_DATA,
    compare: [
        {
            name: 'Click Data',
            id: 'clickData',
            isSelected: true
        },
        {
            name: 'Verbatims',
            id: 'verbatims',
            isSelected: false
        },
        {
            name: 'Element Engagement',
            id: 'elementEngagements',
            isSelected: false
        }
    ],
    show: {
        options: [
            {
                name: 'Zones',
                id: 'zones',
                isSelected: true
            },
            {
                name: 'Likes',
                id: '1',
                isSelected: true
            },
            {
                name: 'Dislikes',
                id: '0',
                isSelected: true
            },
            {
                name: 'Neutral',
                id: '2',
                isSelected: true
            }
        ],
        zone: [
            {
                name: 'Always Present',
                id: 'alwaysPresent',
                isSelected: true
            },
            {
                name: 'Hover',
                id: 'hover',
                isSelected: false
            }
        ],
        dataView: [
            {
                name: 'List',
                id: 'list',
                isSelected: true
            },
            {
                name: 'Summary',
                id: 'summary',
                isSelected: false
            }
        ],
        chartMetric: [
            {
                name: 'Clicks',
                id: 'clicks',
                isSelected: true
            },
            {
                name: 'Percent of Total Clicks',
                id: 'percent',
                isSelected: false
            }
        ],
        activatedZoneImageLabel: ''
    }
};
