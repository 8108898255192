import {Injectable} from '@angular/core';
import {
    AxisPlotData, AxisValue,
    QuadMapPlotData,
    PlotDataPoint
} from "@app/deliverables/correlations/models/quad-map-plot-data.model";
import {CorrelationsDeliverableView} from "@app/deliverables/correlations/models/correlations.model";
import {CorrelationsFilter} from "@app/deliverables/correlations/models/filter.model";
import {keyMeasureMap} from "@app/deliverables/correlations/filter/show/filter-names";
import {CorrelationsService} from './correlation.service';

@Injectable({
    providedIn: 'root'
})

/**
 * `QuadMapPlotService` has all data operations for preparing chart data
 * and computing each metric of the chart
 *
 * @export
 * @class QuadMapPlotService
 */
export class QuadMapPlotService {

    keyMeasureMap: any;

    /**
     * Creates an instance of QuadMapPlotService.
     */
    constructor(private correlationsService: CorrelationsService) {
        this.keyMeasureMap = keyMeasureMap;
    }

    /**
     * Returns scatterplot data.
     *
     * @param {CorrelationsDeliverableView} correlationsDeliverableData
     * @param {CorrelationsFilter} filter
     * @returns QuadMapPlotData
     */
    public prepareQuadMapPlotData(correlationsDeliverableData: CorrelationsDeliverableView, filter: CorrelationsFilter): QuadMapPlotData {
        const selectedConcept = filter.concepts.find(concept => concept.isSelected);
        const selectedSubgroup = filter.subgroups.find(subgroup => subgroup.isSelected);
        const selectKeyMeasure = filter.show.keyMeasures[this.correlationsService.viewName].find(keyMeasure => keyMeasure.isSelected);
        const rankValueKeys = this.keyMeasureMap[selectKeyMeasure.id]

        let quadMapPlotData: QuadMapPlotData;
        const dataPoints: Array<PlotDataPoint> = [];
        let totalAttributeMean = 0;
        let totalCorrelationMean = 0;
        let dataPointIndex = 0;
        correlationsDeliverableData.statements.forEach((statement) => {
            if (statement.statementData.length) {
                const statementData = statement.statementData;
                const statementDataMatch = statementData.find((dataMatch) => dataMatch.conceptId === selectedConcept.id && dataMatch.segmentId === selectedSubgroup.id)
                if (statementDataMatch) {
                    const plotDataPoint = this.createPlotDataPoint(dataPointIndex, statement.label, statementDataMatch, rankValueKeys);

                    totalCorrelationMean = this.getTotalCorrelationMean(totalCorrelationMean, plotDataPoint.x);
                    totalAttributeMean = this.getTotalAttributeMean(totalAttributeMean, plotDataPoint.y);

                    dataPoints.push(plotDataPoint);
                    dataPointIndex++;
                }
            }
        });

        const axisPlotData: AxisPlotData = this.setAxisPlotData(totalCorrelationMean, totalAttributeMean, dataPoints);

        quadMapPlotData = this.setQuadMapPlotData(dataPoints, axisPlotData);
        return quadMapPlotData;
    }

    /**
     *
     * @param dataPointIndex
     * @param label
     * @param statementDataMatch
     * @param rankValueKeys
     */
    public createPlotDataPoint(dataPointIndex, label, statementDataMatch, rankValueKeys): PlotDataPoint {
        const datapoint: PlotDataPoint = new PlotDataPoint();
        datapoint.id = dataPointIndex;
        datapoint.questionName = label;
        datapoint.label = label;
        datapoint.x = statementDataMatch[rankValueKeys.value];
        datapoint.y = statementDataMatch.attributeMean;
        datapoint.baseSize = statementDataMatch.baseSize;
        datapoint.rank = statementDataMatch[rankValueKeys.rank];
        datapoint.type = 'Concept';
        datapoint.quadrantName = "";
        return datapoint;
    }

    /**
     *
     * @param totalCorrelationMean
     * @param currentCorrelationMean
     */
    getTotalCorrelationMean(totalCorrelationMean, currentCorrelationMean): number {
        return totalCorrelationMean + currentCorrelationMean
    }

    /**
     *
     * @param totalAttributeMean
     * @param currentAttributeMean
     */
    getTotalAttributeMean(totalAttributeMean, currentAttributeMean): number {
        return totalAttributeMean + currentAttributeMean;
    }

    /**
     *
     * @param totalCorrelationMean
     * @param totalAttributeMean
     * @param dataPoints
     */
    public setAxisPlotData(totalCorrelationMean, totalAttributeMean, dataPoints): AxisPlotData {
        const axisPlotData: AxisPlotData = new AxisPlotData()
        axisPlotData.xDividerAxis = totalCorrelationMean / dataPoints.length;
        axisPlotData.yDividerAxis = totalAttributeMean / dataPoints.length;
        axisPlotData['axisValue'] = this.setAxisValue(dataPoints);
        return axisPlotData;
    }

    /**
     *
     * @param dataPoints
     */
    public setAxisValue(dataPoints): AxisValue {
        const axisValue: AxisValue = new AxisValue();
        let xCoordinateSet = (dataPoints.map(function (o) {
            return o.x;
        }));
        let yCoordinateSet = (dataPoints.map(function (o) {
            return o.y;
        }));

        axisValue['xMin'] = Math.min.apply(null, xCoordinateSet);
        axisValue['xMax'] = Math.max.apply(null, xCoordinateSet);
        axisValue['yMin'] = Math.min.apply(null, yCoordinateSet);
        axisValue['yMax'] = Math.max.apply(null, yCoordinateSet);

        return axisValue;
    }

    /**
     *
     * @param dataPoints
     * @param axisPlotData
     */
    public setQuadMapPlotData(dataPoints, axisPlotData): QuadMapPlotData {
        const quadMapPlotData: QuadMapPlotData = new QuadMapPlotData();
        quadMapPlotData.dataPoints = dataPoints;
        quadMapPlotData.axisPlotData = axisPlotData;
        return quadMapPlotData;
    }

}
