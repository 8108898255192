<mat-tree #tree [dataSource]="datasource" [treeControl]="treeControl">

    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="10" class="leaf-node">
      <ap-item-types [node]="node"></ap-item-types>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="parent-node-container">
        <div class="mat-tree-node parent-node" [class]="node.class">
            <div class="deliverable-card-image">
                <img *ngIf="node.image && node.name" mat-card-image src="assets/images/deliverables/ActivationProfile/{{node.bar + node.name | removeWhiteSpace | lowercase}}.svg" alt="Icon">
            </div>
            <span class="collapse-ellipses" [matTooltip]="node.tooltip" matTooltipShowDelay="500"> {{node.name}}</span>
        </div>
        <div  *ngIf="treeControl.isExpanded(node)" class="child-node-container">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
  </mat-tree>
