import { TagProcessor } from './tag-processor';
import { CSSNode } from './css-node';
import { ClassProcessor } from './class-processor';
import { StyleProcessor } from './style-processor';
import { AttributeProcessor } from './attribute-processor';
import { CaptureOptions } from './capture-options';

/**
 * Processor for DOM nodes.
 */
export class NodeProcessor {

  private attributeProcessor: AttributeProcessor;
  private styleProcessor: StyleProcessor;
  private classProcessor: ClassProcessor;
  private tagProcessor: TagProcessor;

  /**
   * Constructor
   */
  constructor() {
    this.attributeProcessor = new AttributeProcessor();
    this.styleProcessor = new StyleProcessor();
    this.classProcessor = new ClassProcessor();
    this.tagProcessor = new TagProcessor();
  }

  /**
   * Traverses every descendant on the element to update the styles and remove
   * encapsulation attribute.
   *
   * @param clone the cloned element
   * @param original the original element on the dom
   * @param parentCSSNode
   * @param options
   */
  public process(clone: Element, original: Element, parentCSSNode: CSSNode, options: CaptureOptions): void {
    if (this.ignore(original, options.node.remove)) {
      return;
    }
    const cssNode = new CSSNode(parentCSSNode);
    // Process attribute and update the encapsulationAttrMap
    this.attributeProcessor.process(clone, options);
    this.styleProcessor.process(clone, original, cssNode, options);
    this.classProcessor.process(clone, cssNode, options);
    this.tagProcessor.process(clone, original, options);
    this.removeComments(clone);
    // Process child nodes.
    const children: HTMLCollection = clone.children;
    const originalChildren: HTMLCollection = original.children;
    let child: Element;
    let originalChild: Element;
    let i = 0;
    while (i < children.length) {
      child = children.item(i);
      originalChild = originalChildren.item(i);
      i++;
      this.process(child, originalChild, cssNode, options);
    }
  }

  /**
   * Ignore node if the ignore class is available.
   *
   * @param element the node
   * @param ignoreClasses the ignore classes.
   * @returns true if node needs to be ignored.
   */
  private ignore(element: Element, ignoreClasses: string[]): boolean {
    const hasIgnoreClass: boolean = ignoreClasses.some(ignoreClass => element.classList.contains(ignoreClass));
    return hasIgnoreClass;
  }

  /**
   * Removes comment children.
   *
   * @param clone the cloned node.
   */
  private removeComments(clone: Element): void {
    const childNodes: NodeList = clone.childNodes;
    let child: Node;
    const comments: Node[] = [];
    for (let i = 0; i < childNodes.length; i++) {
      child = childNodes.item(i);
      if (child.nodeType === Node.COMMENT_NODE) {
        comments.push(child);
      }
    }
    comments.forEach(comment => clone.removeChild(comment));
  }

}
