<div class="image-carousel-navigation-container" [style.width]="imageWidth + 20 +'px'">
  <div class="btn-container" [style.bottom]="getBottomHeight(imageHeight)">
    <button class="carousel-btn icon-btn left-carousel-btn" mat-button id="left-carousel-btn" role="button" rel="tooltip" matTooltipPosition="above"
            matTooltip=" {{'platform.carousel.table.previous.arrow' | translate: {value: tooltipDescription} }}"
            (click)="previousPage($event)" *ngIf="isPreviousEnabled">
      <span class="sif sif-chevron-w"></span>
    </button>
    <button class="carousel-btn icon-btn right-carousel-btn" mat-button id="right-carousel-btn" role="button" rel="tooltip" matTooltipPosition="above"
            matTooltip=" {{'platform.carousel.table.next.arrow' | translate: {value: tooltipDescription} }}"
            (click)="nextPage($event)" *ngIf="isNextEnabled">
      <span class="sif sif-chevron-e"></span>
    </button>    
  </div>
  <div class="page-bullets-container" *ngIf="visibleBullets">
    <span class="page-bullet" *ngFor="let img of imageList" [ngClass]="{'active': img.isSelected}"></span>
  </div>
</div>

