import { State } from '@platform/store/state/app.state';
import { AppConfigService } from '@app/core/config/app-config.service';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take, concatMap } from 'rxjs/operators';
import { UserInfo } from '@platform/models/user-info.model';
import { addUserInfo } from '@platform/store/actions/user-info.actions';
import { selectUserInfo } from '@platform/store/selectors/user-info.selectors';
import { IntegrationService } from '@platform/services/integration.service';

/**
 * User service provides information about current logged-in user
 *
 * @example
 * constructor(private userService: UserService) { }
 *
 * @export
 * @class UserService
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Loading flag to prevent multiple server calls.
   *
   * @private
   */
  private isLoading = false;

  /**
   * Creates an instance of UserService.
   *
   * @constructor
   * @param {Store<State>} store The application state
   * @param {HttpClient} httpClient The HttpClient.
   * @memberof UserService
   */
  constructor(
    private store: Store<State>,
    private httpClient: HttpClient,
    private appConfig: AppConfigService,
    private integrationService: IntegrationService) { }

  /**
   * Returns the current logged-in user observable. This method tries to fetch the user from store.
   * If the user object is not available in store then it will be fetched from the API.
   *
   * @example
   * const user$ = userService.getUser();
   *
   * @returns {Observable<UserInfo>}
   * @memberof UserService
   */
  getUser(): Observable<UserInfo> {
    let user$: Observable<UserInfo> = this.fetchUserFromStore();
    user$.pipe(take(1)).subscribe(user => {
      if (this.isLoading !== true && !user) {
        this.isLoading = true;
        user$ = this.fetchUserFromAPI();
      }
    });
    return user$;
  }

  /**
   * Fetch user object from the API.
   *
   * @private
   * @returns {Observable<UserInfo>} The user observable
   * @memberof UserService
   */
  public fetchUserFromAPI(): Observable<UserInfo> {
    const url = `${this.appConfig.config.user.url}/userInfo`;
    return this.httpClient.get<UserInfo>(url).pipe(
      concatMap(result => {
        this.loadUserOnStore(result);
        this.isLoading = false;
        return this.fetchUserFromStore();
      })
    );
  }

  /**
   * Loads user object into the store.
   *
   * @private
   * @param {UserInfo} userInfo The user object
   * @memberof UserService
   */
  public loadUserOnStore(userInfo: UserInfo): void {
    this.store.dispatch(addUserInfo({ userInfo }));
  }

  /**
   * Fetches user object from the store.
   *
   * @private
   * @returns {Observable<UserInfo>} The user observable.
   * @memberof UserService
   */
  private fetchUserFromStore(): Observable<UserInfo> {
    return this.store.pipe(select(selectUserInfo));
  }

  /**
   * Returns the current logged-in user observable from Studio.
   *
   * @private
   * @returns {Observable<UserInfo>} The user observable
   * @memberof UserService
   */
  getStudioUserInfo(): Observable<UserInfo> {
    const url = this.integrationService.getUserInfoURL();
    return this.httpClient.get<UserInfo>(url);
  }

}
