import {Injectable} from '@angular/core';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import * as numeral from "numeral";

@Injectable({
  providedIn: 'root'
})
export class BroadPotentialService {

  constructor(
    private activationProfileService: ActivationProfileService
  ) {
  }

  /**
   * set broad potential tree node.
   *
   * @returns {TreeNodeData}
   * @memberof BroadPotentialService
   */
  public setBroadPotential(headerList: string | string[], measureDescription: MeasureDescription, broadPotential: TreeNodeData[], basesLabel: string): TreeNodeData[] {
    if (headerList.indexOf('broadPotential') > -1) {
      const isBasesLabel = basesLabel ? basesLabel?.toLowerCase() + '.' : '';
      broadPotential = this.activationProfileService.buildMeasures(measureDescription.broadPotential, 'Parent', 'broadPotential', 1, [], null, this.activationProfileService.getTooltip(isBasesLabel + 'bp.' + measureDescription.broadPotential), true);
      if (headerList.indexOf('WPIQuintile') > -1) {
        const trialInterest = this.activationProfileService.buildMeasures(measureDescription.WPIQuintile, 'Parent', 'trialInterest', 2, [], null, null, true, 5);
        broadPotential[0].children.push(trialInterest[0]);
        // const definitelyWouldBuy = formatNumber(measureDescription.definitelyWouldBuy, 'en_US')
        // const definitelyWouldBuyP = formatPercent(measureDescription.definitelyWouldBuy, 'en_US')
        const definitely = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.definitelyWouldBuy), 'Leaf', 'definitely', 3, null, null, null, false);
        const probably = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.probablyWouldBuy), 'Leaf', 'probably', 3, null, null, null, false);
        const might = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.mightorMightNotBuy), 'Leaf', 'might', 3, null, null, null, false);
        const probablyNot = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.probablyWouldNotBuy), 'Leaf', 'probablyNot', 3, null, null, null, false);
        const definitelyNot = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.definitelyWouldNotBuy), 'Leaf', 'definitelyNot', 3, null, null, null, false);
        trialInterest[0].children.push(definitely[0]);
        trialInterest[0].children.push(probably[0]);
        trialInterest[0].children.push(might[0]);
        trialInterest[0].children.push(probablyNot[0]);
        trialInterest[0].children.push(definitelyNot[0]);
      }
      if (headerList.indexOf('needDesireFactorColor') > -1) {
        const needDesire = this.activationProfileService.buildMeasures(measureDescription.needDesireFactorColor, 'Leaf', 'desire', 2, null, null, null, true, 4);
        broadPotential[0].children.push(needDesire[0]);
      }
      if (headerList.indexOf('desireFactorColor') > -1) {
        const desire = this.activationProfileService.buildMeasures(measureDescription.desireFactorColor, 'Leaf', 'desire', 2, null, null, null, true, 4);
        broadPotential[0].children.push(desire[0]);
      }
      if (headerList.indexOf('acceptableCostsFactorColor') > -1) {
        const costs = this.activationProfileService.buildMeasures(measureDescription.acceptableCostsFactorColor, 'Leaf', 'costs', 2, null, null, null, true, 4);
        broadPotential[0].children.push(costs[0]);
      }
    }
    return broadPotential;
  }
}

