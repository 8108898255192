import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ReportService} from '@platform/services/report.service';
import {ImageFile, ImageView} from '@products/shared/deliverables/image-view/image-view.model';
import {ImageViewService} from '@products/shared/deliverables/image-view/image-view.service';
import {UserService} from '@platform/services/user.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {MatLegacyPaginator as MatPaginator, MatLegacyPaginatorIntl as MatPaginatorIntl, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {switchMap} from 'rxjs/operators';
import {AdditionalInfoMetaInfo} from '@app/deliverables/additional-info/models/view-meta-info.model';
import {
    ActivationProfileLegacyMetaInfo
} from '@products/quick-predict/activation-profile-legacy/models/view-meta-info.model';
import {ScoreCardMetaInfo} from '@app/deliverables/score-card/models/view-meta-info.model';
import {Report} from '@platform/models/report.model';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';

@Component({
    selector: 'ns-image-view',
    templateUrl: './image-view.component.html',
    styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit, AfterViewInit, OnDestroy, ScoreCardView {

    @Input() deliverableType: string;
    @Input() metaInfo: AdditionalInfoMetaInfo | ActivationProfileLegacyMetaInfo | ScoreCardMetaInfo;

    /**
     * The ImageView.
     * @type {ImageView} imageView
     */
    public imageView: ImageView;
    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof FilterComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * Array of Image files
     *
     * @property
     * @typedef {Array<ImageFile>}
     * @memberof AdditionalInfoComponent
     */
    public imageFiles: Array<ImageFile>;

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     */
    public isInsightEnable = false;

    /**
     * Internal user check
     *
     * @type {boolean}
     * @public
     */
    public isInternalUser = false;

    /**
     * button state check
     *
     * @type {boolean}
     * @public
     */
    public disableBtn = false;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     */
    public deliverableData: DeliverableInsight;
    /**
     * Spinner.
     *
     * @type {Array<any>}
     */
    public displayProgressSpinner = false;

    public report: Report;

    /**
     * Image Paginator Instance
     *
     * @type {MatPaginator}
     */
    @ViewChild('imageViewPagination', {static: true}) imageViewPagination: MatPaginator;

    /**
     * Datasource object of type ImageFile
     *
     * @property
     * @public
     * @type {ImageFile}
     */
    public dataSource: MatTableDataSource<ImageFile>;

    /**
     * Current page index value
     *
     * @property
     * @public
     * @type {number}
     */
    currentPage = 0;

    /**
     *  Page index value
     *
     * @property
     * @public
     * @type {number}
     */
    public pageIndexNum = 0;

    /**
     * ScoreCard Concept object for image view.
     *
     * @type {Concept}
     */
    @Input() scoreCardConcept: Concept;

    public viewMetaInfo: AdditionalInfoMetaInfo | ActivationProfileLegacyMetaInfo | ScoreCardMetaInfo;

    /**
     * Holds the deliverable view.
     * NOTE: additional info has only one deliverable info even if we have multiple images.
     * The images locations are stored in product deliverable view.
     * */
    public deliverableView: DeliverableView;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    /**
     * Take the insight in the HTML format for this Deliverable
     *
     * @type {Boolean}
     */
    public addHTMLToInsight = false;

    constructor(
        private reportService: ReportService,
        private elementRef: ElementRef,
        private imageViewService: ImageViewService,
        private userService: UserService,
        private exportPNGService: ExportPngService,
        private spinnerService: SpinnerService,
        private viewMetaInfoService: ViewMetaInfoService,
        private deliverableInsightService: DeliverableInsightService,
        private mixpanelService: MixpanelService,
        private deliverableViewService: DeliverableViewService,
        private productDeliverableViewService: ProductDeliverableViewService
    ) {
        this.subscriptions = [];
    }

    ngOnInit(): void {
        const user$ = this.userService.getUser();
        const imageView$ = this.deliverableViewService.getDeliverableViews(this.deliverableType);
        const viewMetaInfo$ = this.viewMetaInfoService.get<AdditionalInfoMetaInfo | ActivationProfileLegacyMetaInfo | ScoreCardMetaInfo>(this.deliverableType);
        const report$ = this.reportService.get();
        const subscription = combineLatest([imageView$, report$, user$, viewMetaInfo$]).pipe(switchMap(results => {
            const user = results[2];
            this.viewMetaInfo = results[3];
            this.report = results[1];
            this.deliverableView = results[0][0];
            this.isInternalUser = user.isInternalUser;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            return this.productDeliverableViewService.get<ImageView>(this.report.id, this.deliverableView.productViewId);
        })).subscribe(
            (view) => {
                if (view.type === 'additional.info' || view.type === 'score.card') {
                    view.imageFile = this.sortImageFileArray(view.imageFile);
                }
                this.imageView = view;
                this.imageFiles = this.imageViewService.getImagePath(this.imageView);
                this.dataSource = new MatTableDataSource<ImageFile>(this.imageFiles);
                this.dataSource.paginator = this.imageViewPagination;
                this.currentPage = this.viewMetaInfo && this.viewMetaInfo.page ? this.viewMetaInfo.page.pageNumber : 0;
            });
        this.subscriptions.push(subscription);
    }

    ngAfterViewInit() {
        const paginationIntl = new MatPaginatorIntl();
        paginationIntl.nextPageLabel = 'Next Page';
        paginationIntl.previousPageLabel = 'Previous Page';
        this.imageViewPagination._intl = paginationIntl;
        if (this.viewMetaInfo && this.viewMetaInfo.page) {
            this.currentPage = this.viewMetaInfo.page.pageNumber;
            this.dataSource.paginator.pageIndex = this.currentPage;
        }
    }

    /**
     * Pagination changed
     * @param evt {PageEvent}
     */
    pageChanged(evt: PageEvent) {
        if (evt.pageIndex > this.pageIndexNum) {
            // Clicked on next button
            this.mixpanelService.track(this.mixpanelService.getMixpanelLabel(this.deliverableType), MixpanelEvent.nextPage);
        } else {
            // Clicked on previous button
            this.mixpanelService.track(this.mixpanelService.getMixpanelLabel(this.deliverableType), MixpanelEvent.previousPage);
        }
        const deliverableType = this.deliverableType;
        this.currentPage = evt.pageIndex;
        const viewInfo = {deliverableType, page: {}};
        viewInfo.page = {
            pageNumber: this.currentPage,
            imageName: this.dataSource.data[this.currentPage].fileName
        };
        this.viewMetaInfoService.update(viewInfo, deliverableType);
        if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
            this.openInsightCreationForm();
        }
    }

    /**
     * set meta info
     */
    setMetaInfo() {
        const deliverableType = this.deliverableType;
        const viewMetaInfo = this.viewMetaInfo.page;
        const viewInfo = {deliverableType, page: {}};
        const pageNumber = viewMetaInfo ? viewMetaInfo.pageNumber : 0;
        viewInfo.page = {
            pageNumber: pageNumber,
            imageName: this.dataSource.data[pageNumber].fileName
        };
        return viewInfo;
    }

    /**
     * Cleanup hook.
     *
     * @memberof SurveyQuestionComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Export PNG Images
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        if (this.imageView.type === 'additional.info' || this.imageView.type === 'score.card') {
            this.exportImagesAsZip();
        } else {
            this.exportImageAsPNG();
        }
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportImageAsPNG() {
        const selectors = [];
        const elements: Array<Element> = this.elementRef.nativeElement.querySelectorAll('.image-view-png');
        elements.forEach((element: Element) => {
            const selector = `#${element.id}`;
            selectors.push(selector);
        });
        this.exportPNGService.exportPNG(selectors);
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    }


    /**
     * Export the Zip of the PNG images
     */
    exportImagesAsZip() {
        let imageFileNames: string = null;
        this.imageFiles.forEach(image => {
            imageFileNames = !imageFileNames ? image.fileName : `${imageFileNames},${image.fileName}`;
        });
        this.exportPNGService.exportPNGAsZip(this.report.id, this.report.name, imageFileNames);

    }


    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        const viewInfo = this.setMetaInfo();
        this.isInsightEnable = true;
        this.addHTMLToInsight = true;
        const deliverableLbl = this.imageView.type === 'score.card' ? 'Score Card' : 'Additional Information';
        this.deliverableData = {
            title: this.isAutomatedHeadlinesEnabled ? this.deliverableInsightService.generateInsightTitle(deliverableLbl) : '',
            deliverable: {
                deliverableViewId: this.deliverableView.id,
                insightHTML: '',
                metaInfo: viewInfo,
                filter: {deliverableType: this.deliverableType, deliverableViewType: 'image'},
            }
        };
    }


    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Set Views
     * @param evt { String }
     */
    setViews(evt: any) {
        /**
         * ToDo:
         * Create a data model for views which has all details (sorting, highlights, filters, Insights ... )
         * Store the view in the DB
         * Create a Default View (set to defaults)
         * Set the view data on load
         */
        console.log(evt);
    }

    /**
     * Method to sort an ImageFile array by increasing number.
     * @param imageFileArray the array to be sorted.
     */
    sortImageFileArray (imageFileArray: Array<ImageFile>): Array<ImageFile> {
        return imageFileArray.sort((a, b) => {
            const intA = parseInt(a.name.match(/-(\d+)\.png$/)?.[1] || "0", 10);
            const intB = parseInt(b.name.match(/-(\d+)\.png$/)?.[1] || "0", 10);

            return intA - intB;
        });
    }

}
