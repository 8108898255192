import { DeliverableType } from '@products/innovation-screener/deliverable-type.enum';
/**
 * Default filter values for SCORE_CARD.
 *
 * @export
 * @property
*/
export const defaultScoreCardFilter = {
  deliverableType: DeliverableType.SCORE_CARD.type,
  deliverableViewType: 'image'
};
