import { ArchiveState } from "@platform/store/state/archive.state";
import { State } from '@platform/store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {archiveAdapter} from "@platform/store/adapters/archive.adapter";
import {Archive} from "@platform/models/archive.model";


/**
 * Default selectors from adapter.
 *
 * @property
 */
const {
  selectEntities,
  selectAll
} = archiveAdapter.getSelectors();

/**
 * Archive feature selector.
 *
 * @export
 * @property
*/
export const selectArchiveFeature = createFeatureSelector< ArchiveState>('archive');
/**
 * Archvie selector.
 *
 * @export
 * @property
*/
export const selectArchiveState = createSelector(
  selectArchiveFeature,
  (state: ArchiveState) => state
);

/**
 * Archive entity selector.
 *
 * @export
 * @property
 */
export const selectArchiveEntities = createSelector(
  selectArchiveState,
  selectEntities
);

/**
 * Selector for selecting all archives.
 *
 * @export
 * @property
 */
export const selectAllArchives = createSelector(
  selectArchiveState,
  selectAll
);
/**
 * Selector for selecting userViews for a report object.
 *
 * @export
 * @property
 */
export const selectReportArchive = createSelector(
  selectAllArchives,
  (archives: Archive[], props: { reportId: string }) => {
    return archives.find(ar => ar.reportId === props.reportId);
  }
);
