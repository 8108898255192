import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import {
    Click,
    ClickZone,
    StrengthWatchoutsConcept,
} from "@app/deliverables/strengths-watchouts/models/strength-watchouts.model";
import { ConceptService } from "@platform/services/concept.service";
import { Concept } from "@platform/models/concept.model";
import { AppConfigService } from "@app/core/config/app-config.service";
import { StrengthWatchoutsService } from "@app/deliverables/strengths-watchouts/strength-watchouts.service";
import { combineLatest, ReplaySubject, Subscription } from "rxjs";
import { StrengthWatchoutsFilter } from "@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model";
import { FilterService } from "@platform/services/filter.service";

@Component({
    selector: "sw-concept-image",
    templateUrl: "./concept-image.component.html",
    styleUrls: ["./concept-image.component.scss"],
})
export class ConceptImageComponent implements OnInit, OnDestroy, OnChanges {
    @Output() zonehover: EventEmitter<ClickZone> = new EventEmitter();
    @Output() zoneclick: EventEmitter<ClickZone> = new EventEmitter();
    @Output() deselectAllZones: EventEmitter<boolean> = new EventEmitter();
    @Input() clickedActivatedZones: string;
    @Input() selectedClick: Click;
    @Input() index: number;

    subscription: Subscription;

    concept$: ReplaySubject<StrengthWatchoutsConcept> =
        new ReplaySubject<StrengthWatchoutsConcept>();

    @Input("selectedZoneLabel")
    set selectedZoneLabel(value: string) {
        if (value && value !== "Unassigned") {
            this.selectedZones = this.concept.zones.filter(
                (zone) => zone.label === value
            );
        } else {
            this.selectedZones = null;
        }
    }

    @Input()
    set concept(value: StrengthWatchoutsConcept) {
        this._concept = value;
        this.conceptImageUrl = value.imagePath;
        this.concept$.next(value);
    }

    get concept(): StrengthWatchoutsConcept {
        return this._concept;
    }

    private _concept;

    selectedZones: ClickZone[];
    isZoneSelected: boolean = false;
    likes: Click[];
    dislikes: Click[];
    neutral: Click[];
    namedZones: ClickZone[];
    conceptImageUrl: String;
    conceptImage: Concept;
    concepts: Concept[];
    public filter: StrengthWatchoutsFilter;
    showAlways: boolean;
    imageHover: boolean = false;
    hoveredZone: String;
    activatedZone: String;
    currentActiveZone: string;

    constructor(
        private conceptService: ConceptService,
        private cs: AppConfigService,
        private strengthWatchoutsService: StrengthWatchoutsService,
        private filterService: FilterService
    ) {}

    ngOnInit() {
        const filter$ =
            this.strengthWatchoutsService.getStrengthWatchoutsFilter();
        const concept$ = this.concept$;
        this.subscription = combineLatest([concept$, filter$]).subscribe(
            ([concept, filter]) => {
                this.filter =
                    this.strengthWatchoutsService.updateFilterOptions(filter);
                this.likes = StrengthWatchoutsService.getLikes(
                    filter,
                    concept.clicks
                );
                this.neutral = StrengthWatchoutsService.getNeutral(
                    filter,
                    concept.clicks
                );
                this.dislikes = StrengthWatchoutsService.getDislikes(
                    filter,
                    concept.clicks
                );
                this.namedZones = StrengthWatchoutsService.getClickZones(
                    filter,
                    concept
                );
                this.selectedZones =
                    this.namedZones && this.namedZones.length
                        ? this.selectedZones
                        : null;
            }
        );
        this.setZonePresence();
        this.activatedZone = this.filter.show.activatedZoneImageLabel;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            this.clickedActivatedZones ||
            this.activatedZone != this.filter?.show.activatedZoneImageLabel
        ) {
            this.activatedZone = this.filter.show.activatedZoneImageLabel;
        }
        this.selectedZones =
            this.namedZones && this.namedZones.length
                ? this.selectedZones
                : null;
        this.setZonePresence();
    }

    onHoverZone(zone?: ClickZone) {
        if (zone && this.activatedZone != zone.label) {
            this.hoveredZone = zone.label;
            if (this.isZoneSelected) this.deselectZone();
            this.zonehover.emit(zone);
        } else {
            this.hoveredZone = "";
            this.zonehover.emit(zone);
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * zone click event
     * @param zone
     */
    selectZone(zone: ClickZone) {
        this.isZoneSelected = true;
        if (
            this.filter.compare.find((option) => option.isSelected).id ===
            "verbatims"
        ) {
            this.selectedZones = [zone];
            this.zoneclick.emit(zone);
        }
    }
    /**
     * zone click event
     * @param zone
     */
    activateZone(event, zone: ClickZone) {
        event.stopPropagation();
        if (this.activatedZone == zone.label) {
            this.zoneclick.emit();
            this.deselectAllZones.emit(true);
            this.activatedZone = "";
            this.filter.show.activatedZoneImageLabel = "";
        } else {
            this.activatedZone = zone.label;
            this.currentActiveZone = zone.label;
            this.zoneclick.emit(zone);
            this.onHoverZone(zone);
        }
        this.filter.show.activatedZoneImageLabel =
            this.activatedZone.toString();
        this.filterService.update(this.filter);
    }

    /**
     * deselecting all zones when the concept image is clicked outside of zone area
     * concept svg element click event
     */
    deselectZone() {
        if (!this.isZoneSelected) {
            this.selectedZones = null;
            this.deselectAllZones.emit(true);
        } else {
            this.isZoneSelected = false;
        }
        if (!this.activatedZone) {
            this.activatedZone = "";
        }
        this.hoveredZone = "";
        this.zonehover.emit();
    }

    calcWidthForName(zone: ClickZone) {
        let width =
            zone.name.length >= 2
                ? zone.name.length * 10
                : zone.name.length * 20 + 10;
        return zone.label === this.hoveredZone ||
            (this.selectedZones &&
                zone.label === this.selectedZones[0].label) ||
            zone.label === this.activatedZone
            ? `width:${width}px`
            : "";
    }

    /**
     * Set Zone Presence Always_Present Or On_Hover
     */
    setZonePresence() {
        this.showAlways = this.filter?.show.zone.find(
            (zone) => zone.id === "alwaysPresent"
        ).isSelected;
    }

    /**
     * Set onHover Image will visible the zones.
     */
    onHoverImage(value: boolean) {
        this.imageHover = value;
    }

    isOnHoverEnabled(zone) {
        return (
            this.showAlways ||
            this.imageHover ||
            zone.label === this.activatedZone ||
            (!this.showAlways &&
                (zone.label === this.hoveredZone ||
                    (this.selectedZones &&
                        zone.label === this.selectedZones[0].label)))
        );
    }
}
