<div class="click-zone" *ngIf="checkNodata()==true; else noData">
    <div class="deliverable-insight-sw-zone">
        <table class="table table-total-clicks table-hover deliverable-insight-sw-table">
            <thead class="deliverable-insight-sw-table">
            <tr class="deliverable-insight-sw-table">
                <th class="table-head tier-one table-head-total-clicks deliverable-insight-sw-table" colspan="1">
                    <h4 class="zone-data-title deliverable-insight-sw-table">
                        <span *ngIf="!chartMetricClicks" class="text-purple">% of </span>
                        <span
                            class="zone-data-title-count zone-data-total-clicks deliverable-insight-sw-table text-purple">{{concept.totalClicks}}</span>
                        <span class="text-purple"> Clicks</span>
                    </h4>
                </th>
                <!-- Options[2].id will be always either 0 or 2, Dislike or Neutral -->
                <th colspan="2" class="table-head tier-one deliverable-insight-cc-table">
          <span class="child-sp table-head-dislikes text-align-right"
                *ngIf="filter.show.options[2].id === '0' && secondSentiment.isSelected">
              <h4 class="zone-data-title text-danger zone-data-title-dislikes deliverable-insight-cc-table">
                  <span
                      class="zone-data-total-dislike-percent deliverable-insight-cc-table"> {{chartMetricClicks?concept.totalDislikes:(concept.percentageDislikes | percent )}}</span>&nbsp;
                  <span class="text-bold">DISLIKES</span>
              </h4>
          </span>
                    <span class="child-sp table-head-neutral text-align-right"
                          *ngIf="filter.show.options[2].id === '2' && secondSentiment.isSelected && firstSentiment.isSelected">
              <h4 class="zone-data-title text-neutral zone-data-title-dislikes">
                  <span class="zone-data-total-neutral-percent"> {{chartMetricClicks?concept.totalNeutrals:concept.percentageNeutrals | percent }}</span>&nbsp;
                  <span class="text-bold">NEUTRAL</span>
              </h4>
          </span>
                    <span class="child-sp table-head-neutral text-align-center"
                          *ngIf="filter.show.options[2].id === '2' && secondSentiment.isSelected && !firstSentiment.isSelected">
              <h4 class="zone-data-title text-neutral zone-data-title-dislikes">
                  <span class="zone-data-total-neutral-percent"> {{chartMetricClicks?concept.totalNeutrals:concept.percentageNeutrals | percent }}</span>&nbsp;
                  <span class="text-bold">NEUTRAL</span>
              </h4>
          </span>
                    <!-- Options[1].id will be always either 1 or 2, Likes or Neutral -->
                    <span class="child-sp table-head-likes text-left"
                          *ngIf="filter.show.options[1].id === '1' && firstSentiment.isSelected && secondSentiment.isSelected">
              <h4 class="zone-data-title text-success zone-data-title-likes">
                  <span class="zone-data-total-like-precent">{{chartMetricClicks?concept.totalLikes:concept.percentageLikes | percent}}</span>&nbsp;
                  <span class="text-bold">LIKES</span>
              </h4>
          </span>
                    <span class="child-sp table-head-likes text-center"
                          *ngIf="filter.show.options[1].id === '1' && firstSentiment.isSelected && !secondSentiment.isSelected">
              <h4 class="zone-data-title text-success zone-data-title-likes">
                  <span class="zone-data-total-like-precent">{{chartMetricClicks?concept.totalLikes:concept.percentageLikes | percent}}</span>&nbsp;
                  <span class="text-bold">LIKES</span>
              </h4>
          </span>
                    <span class="child-sp table-head-neutral text-left"
                          *ngIf="filter.show.options[1].id === '2' && firstSentiment.isSelected && secondSentiment.isSelected">
              <h4 class="zone-data-title text-neutral zone-data-title-likes ">
                  <span class="zone-data-total-like-precent ">{{chartMetricClicks?concept.totalNeutrals:concept.percentageNeutrals | percent}}</span>&nbsp;
                  <span class="text-bold">NEUTRAL</span>
              </h4>
          </span>
                    <span class="child-sp table-head-neutral text-center"
                          *ngIf="filter.show.options[1].id === '2' && firstSentiment.isSelected && !secondSentiment.isSelected">
              <h4 class="zone-data-title text-neutral zone-data-title-likes ">
                  <span class="zone-data-total-like-precent ">{{chartMetricClicks?concept.totalNeutrals:concept.percentageNeutrals | percent}}</span>&nbsp;
                  <span class="text-bold">NEUTRAL</span>
              </h4>
          </span>
                </th>
            </tr>
            <!-- Future implementation as per figma design not part of S2-3831 -->
            <tr class="deliverable-insight-sw-table">
                <th class="table-head" colspan="1"></th>
                <th class="table-head tier-one deliverable-insight-cc-table line-clicks-th" colspan="2">
                    <div class="line-clicks line-clicks-in-center"
                         *ngIf="firstSentiment.isSelected && secondSentiment.isSelected">
                        <span class="start-number">{{chartMetricClicks?maxWidth:maxWidthPercentage+"%"}}</span>
                        <span class="mid-number">{{chartMetricClicks?0:"0%"}}</span>
                        <span class="end-number">{{chartMetricClicks?maxWidth:maxWidthPercentage+"%"}}</span>
                        <div class="start"></div>
                        <div class="end"></div>
                    </div>
                    <div class="line-clicks line-clicks-in-left"
                         *ngIf="!firstSentiment.isSelected && secondSentiment.isSelected">
                        <span class="start-number">{{chartMetricClicks?maxWidth:maxWidthPercentage+"%"}}</span>
                        <span class="end-number">0</span>
                        <div class="start"></div>
                        <div class="end"></div>
                    </div>
                    <div class="line-clicks line-clicks-in-right"
                         *ngIf="firstSentiment.isSelected && !secondSentiment.isSelected">
                        <span class="start-number">0</span>
                        <span class="end-number">{{chartMetricClicks?maxWidth:maxWidthPercentage+"%"}}</span>
                        <div class="start"></div>
                        <div class="end"></div>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody class="deliverable-insight-sw-table">
            <ng-container *ngFor="let zone of concept.uniqueLabelZones;">
                <tr class="deliverable-insight-sw-table" (mouseenter)="onMouseEnter(zone.label)"
                    (mouseleave)="onMouseLeave()"
                    [ngClass]="{'highLightZone': zone.label === selectedZoneImageLabel, 'highLightActiveZone': activatedZoneImageLabel === zone.label}"
                    (click)="toggleExpansion(zone)">
                    <td class="table-col table-col-zone deliverable-insight-sw-table-td">
                         <span *ngIf="zone.label!=='Unassigned'"
                               [ngClass]="{'activeZone': activatedZoneImageLabel === zone.label}" class="circle">{{zone.label}}</span>
                        <span class="zone-name" rel="tooltip"
                              matTooltipPosition="above" matTooltip="{{zone.name}}"
                              matTooltipClass="sw_zone"
                              >{{zone.name}} </span>
                        <span class="counter-value percent-total-clicks text-purple text-bold">{{chartMetricClicks?zone.totalClicks:(roundto3Digit(zone.totalClicks/concept.totalClicks)+"%")}}</span>
                    </td>
                    <!-- Options[2].id will be always either 0 or 2, Dislikes or Neutral -->
                    <td class="table-col text-center table-col-dislikes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[2].id === '0' && secondSentiment.isSelected && firstSentiment.isSelected">
                        <sw-horizontal-bar [percentage]="zone.dislikes" [reverse]="true"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1" [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-right bar-dislike deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>
                    <td class="table-col text-center table-col-dislikes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[2].id === '0' && secondSentiment.isSelected && !firstSentiment.isSelected"
                        colspan="2">
                        <sw-horizontal-bar [percentage]="zone.dislikes" [reverse]="true"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1"
                                           [doubleWidth]="2" [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-right bar-dislike deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>
                    <td class="table-col text-center table-col-dislikes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[2].id === '2' && firstSentiment.isSelected && secondSentiment.isSelected">
                        <sw-horizontal-bar [percentage]="zone.neutral" [reverse]="true"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1"
                                           [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-right bar-neutral deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>
                    <td class="table-col text-center table-col-dislikes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[2].id === '2' && !firstSentiment.isSelected && secondSentiment.isSelected"
                        colspan="2">
                        <sw-horizontal-bar [percentage]="zone.neutral" [reverse]="true"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1"
                                           [doubleWidth]="2" [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-right bar-neutral deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>
                    <!-- Options[1].id will be always either 1 or 2, Likes or Neutral -->
                    <td class="table-col text-center table-col-likes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[1].id === '1' && firstSentiment.isSelected && secondSentiment.isSelected">
                        <sw-horizontal-bar [percentage]="zone.likes" [reverse]="false"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1"
                                           [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-left bar-like deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>
                    <td class="table-col text-center table-col-likes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[1].id === '1' && firstSentiment.isSelected && !secondSentiment.isSelected"
                        colspan="2">
                        <sw-horizontal-bar [percentage]="zone.likes" [reverse]="false"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1"
                                           [doubleWidth]="2" [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-left bar-like deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>

                    <td class="table-col text-center table-col-likes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[1].id === '2' && firstSentiment.isSelected && secondSentiment.isSelected">
                        <sw-horizontal-bar [percentage]="zone.neutral" [reverse]="false"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1"
                                           [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-left bar-neutral deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>
                    <td class="table-col text-center table-col-likes deliverable-insight-sw-table-td"
                        *ngIf="filter.show.options[1].id === '2' && firstSentiment.isSelected && !secondSentiment.isSelected"
                        colspan="2">
                        <sw-horizontal-bar [percentage]="zone.neutral" [reverse]="false"
                                           [maxPercentage]="maxWidth !== 0 ? maxWidth : 1"
                                           [doubleWidth]="2"
                                           [dataMetricClick]="chartMetricClicks" [totalCLicks]="concept.totalClicks"
                                           class="float-left bar-neutral deliverable-insight-sw-table"></sw-horizontal-bar>
                    </td>
                </tr>
                <tr *ngIf="activatedZoneImageLabel === zone.label && isAISummaryFeatureEnabled && aiZoneSummaries ">
                    <td *ngIf="zoneSummary" colspan="3" class="zone-summary"
                        style="background: #F9F9F9 url(assets/images/deliverables/ai-summaries-enabled-pattern.svg) no-repeat right">
                        <div class="summary-name">{{ zone.name }} Summary</div>
                        <div class="summary-brief">{{zoneSummary}}</div>
                        <div class="footer-text">
                            {{"shared.deliverables.strengthsWatchouts.summaries.powered.text" | translate}}
                        </div>
                    </td>
                    <td *ngIf="!zoneSummary" colspan="3" class="zone-summary" style="background: #F9F9F9 url(assets/images/deliverables/ai-summaries-enabled-pattern.svg) no-repeat right">
                        <ns-empty-page type="empty" pageSize="small" [leadText]="mainEmptyStateMessage"
                                       [supportText]="secondaryEmptyStateMessage"></ns-empty-page>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
</div>
<ng-template #noData>
    <div class="concept-data-container clearfix">
        <div class="noData {{wideConcept ? 'wide-concept' : 'not-wide-concept'}} {{imageHeight=='Landscape' && isCarouselDisplay ? 'landscapeCarousel':''}}">
            <ns-empty-page [leadText]="mainEmptyStateMessage" [supportText]="secondaryEmptyStateMessage"
                           pageSize="medium"
                           type="empty"></ns-empty-page>
        </div>
    </div>
</ng-template>
