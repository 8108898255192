
export class SearchModel {
  meta: any;
  filters: FilterModel;
  searchPhrase: string;
  data: [];

  constructor() {
    this.filters = null;
    this.searchPhrase = '';
    this.data = [];
  }
}

export class DropDownModel {
  name: string;
  identifier: string;
  isUserPreference: boolean;
}

export class FilterModel {
  solutionsFilter: DropDownModel[];
  countriesFilter: DropDownModel[];
  organizationFilter: DropDownModel[];
  searchPhrase: string;
  page?: number
  offset?: number
}
