import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {UserView} from "@platform/models/user-view.model";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {UserViewService} from "@platform/services/user-view.service";
import {MixpanelService} from "@platform/services/mixpanel.service";
import {MixpanelViews} from "@src/assets/utils/mixpanel-enum";
import {TranslateService} from "@ngx-translate/core";
import {Filter} from '@platform/models/filter.model';
import {forkJoin} from "rxjs";

@Component({
    selector: 'ns-save-user-view',
    templateUrl: './save-user-view.component.html',
    styleUrls: ['./save-user-view.component.scss']
})
export class SaveUserViewComponent implements OnInit, OnChanges {

    /**
     * List of user views created by user including the default view.
     * Default view doesn't exist in db but rather a placeholder view for default filters.
     * */
    @Input() views: UserView[];

    /**
     * Current deliverable filters.
     * NOTE: this is needed to create new views
     * */
    @Input() filter: Filter;

    /**
     * Report identifier
     * */
    @Input() reportId: string;

    /**
     * Deliverable type
     * */
    @Input() deliverableType: string;

    /**
     * Event that is emitted when views get modified
     * */
    @Output() viewsChange = new EventEmitter<UserView[]>();

    /**
     * Event that is emitted when view is selected
     * */
    @Output() selectUserView = new EventEmitter<UserView>();


    @ViewChild('createNewViewDialog') createNewViewDialog;
    @ViewChild('renameViewDialog') renameViewDialog;

    /**
     * View that is currently being selected.
     * NOTE: this doesn't mean the view with isSelected set to true.
     * That property in UserView model is a misnomer and should have been called showUpOnPageLoad
     * */
    selectedView: UserView;

    showOnPageLoadView: UserView;

    deliverableLabel: string;

    /**
     * Name of the view that is currently being selected.
     */
    selectedViewName: string;

    inputViewName: string;

    /**
     * View that is named "Default View"
     */
    defaultView: UserView;

    /**
     * Flag that show is currently selected view is default view or not
     */
    selectedViewIsDefaultView: boolean;

    /**
     * Constructor
     *
     * @param dialog
     * @param filterService
     * @param userViewService
     */
    constructor(
        private dialog: MatDialog,
        private userViewService: UserViewService,
        private translateService: TranslateService,
        private mixpanelService: MixpanelService
    ) {
    }

    /**
     * Hook that is triggered when inputs to the component is changed.
     */
    ngOnChanges(changes: SimpleChanges) {
        this.showOnPageLoadView = this.views?.find(it => it.isSelected);
        this.selectedView = !this.selectedView ? this.showOnPageLoadView : this.selectedView;
        this.deliverableLabel = this.mixpanelService.getMixpanelLabel(this.deliverableType);
        this.defaultView = this.views?.find(it => it.name === this.userViewService.defaultViewName);
        this.selectedViewName = this.selectedView?.name;
        this.selectedViewIsDefaultView = this.selectedView?.name === this.userViewService.defaultViewName;
    }

    /**
     * Initialize the component.
     */
    ngOnInit(): void {

    }

    /**
     * Opens Save Dialog
     */
    openCreateNewViewDialog(): void {
        this.inputViewName = '';
        this.dialog.open(this.createNewViewDialog);
    }

    /**
     * Creates a new view
     */
    createNewView(userViewName: string): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelViews.createView);
        this.dialog.closeAll();
        if (!userViewName || userViewName.length < 0) {
            return;
        }
        const userView = {
            name: userViewName,
            deliverableType: this.deliverableType,
            reportId: this.reportId,
            filter: this.filter,
            metaInfo: null,
            isSelected: false
        } as UserView;
        this.userViewService.saveUserView(userView).subscribe((savedUserView) => {
            this.views = [...this.views, savedUserView];
            this.viewsChange.emit(this.views);
            this.selectView(savedUserView);
        });
    }

    renameView(): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelViews.renameView);
        this.dialog.closeAll();
        this.selectedView.name = this.inputViewName;
        this.userViewService.updateUserView(this.selectedView).subscribe((savedUserView) => {
            const index = this.views.findIndex(it => it.id === savedUserView.id);
            this.views.splice(index, 1, savedUserView);
            this.viewsChange.emit(this.views);
        });
    }

    /**
     * Selects an user view.
     *
     * @param  { UserView } the selected user view.
     */
    selectView(userView: UserView) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelViews.changeView);
        this.selectedView = userView;
        this.selectUserView.emit(this.selectedView);
    }

    /**
     * Deletes a view
     */
    deleteView() {
        this.mixpanelService.track(this.deliverableLabel, MixpanelViews.deleteView);
        const selectedView = this.selectedView;
        if (selectedView && selectedView.id && !this.selectedViewIsDefaultView) {
            this.userViewService.deleteUserView(selectedView).subscribe(() => {
                this.views.splice(this.views.indexOf(selectedView), 1);
                this.views = [...this.views];
                this.selectedView = this.defaultView;
                this.viewsChange.emit(this.views);
                this.selectUserView.emit(this.selectedView);
            });
        }
    }

    /**
     * Opens rename view dialog
     */
    openRenameViewDialog() {
        if (this.selectedView && !this.selectedViewIsDefaultView) {
            this.inputViewName = this.selectedViewName;
            this.dialog.open(this.renameViewDialog);
        }
    }

    /**
     * Sets selected view.
     */
    setShowOnPageLoad(checked: boolean): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelViews.updateView);
        if (!this.selectedViewIsDefaultView) {
            const priorSelectedView = this.views.find(it => it != this.defaultView && it.isSelected);
            const currentSelectedView = this.selectedView;

            currentSelectedView.isSelected = checked;
            const promises = [this.userViewService.updateUserView(currentSelectedView)];
            if (priorSelectedView) {
                priorSelectedView.isSelected = false;
                promises.push(this.userViewService.updateUserView(priorSelectedView));
            }

            forkJoin(promises).subscribe((updatedViews) => {
                updatedViews.forEach(savedUserView => {
                    const index = this.views.findIndex(it => it.id === savedUserView.id);
                    this.views.splice(index, 1, savedUserView);
                });
                this.viewsChange.emit(this.views);
            });
        }
    }

}
