import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';

import * as d3 from 'd3';
import {Subscription} from 'rxjs';

@Component({
    selector: 'sw-horizontal-bar',
    templateUrl: './horizontal-bar.component.html',
    styleUrls: ['./horizontal-bar.component.scss']
})
export class HorizontalBarComponent implements OnChanges {

    @Input() percentage: number;

    @Input() reverse: boolean;

    @Input() maxPercentage: number;

    @Input() doubleWidth: number = 1;

    @Input() dataMetricClick: boolean ;

    @Input() totalCLicks: number;

    @Input() barText: string = "default";

    @ViewChild('svgContainer', {static: true}) svgContainer: ElementRef;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberof ClickDataComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * Observe barChart input changes.
     *
     * @memberof HorizontalBarComponent
     * @param {SimpleChanges} changes all changes detected by angular
     */
    ngOnChanges(changes: SimpleChanges): void {
        const container: HTMLElement = this.svgContainer.nativeElement;
        this.buildBar(container);
    }

    private buildBar(container: HTMLElement) {
        const reverse = this.reverse;
        const containerWidth = 200 * this.doubleWidth;
        const containerHeight = 20;
        const scale = d3.scaleLinear().domain([0, this.maxPercentage]).range([0, containerWidth]);
        const width = scale(this.percentage);
        const svg = d3.select(container).text('').append('svg').attr('width', containerWidth + 36)
            .attr('height', containerHeight);
        const bar = svg.selectAll('g').data([this.percentage]).enter().append('g')
            .attr('transform', function () {
                const position = reverse ? containerWidth + 36 : 0;
                return `translate(${position} 0)`;
            });

        const startPath = reverse ? this.leftRoundedRectangle(0, containerHeight, 0, containerHeight, 3) :
            this.rightRoundedRectangle(0, 0, 0, containerHeight, 3);

        const pathValue = reverse ? this.leftRoundedRectangle(0, containerHeight, -width, containerHeight, 3) :
            this.rightRoundedRectangle(0, 0, width, containerHeight, 3);
        bar.append('path').attr('d', startPath).transition().duration(1000).attr('d', pathValue);
        if(this.barText == "default") {
            bar.append("text").text(this.dataMetricClick?this.percentage: this.roundto3Digit(this.percentage/this.totalCLicks)+'%')
                .attr("x", () => {
                    return !reverse ? width + 17 : -(width + 17);
                })
                .attr("y", () => {
                    return 17;
                }).attr("font-size", "14px").attr("font-weight", "700").attr("text-anchor", "middle");
        }else if (this.barText == "elementEngagement") {
            bar.append("text").text(this.percentage)
                .attr("x", () => {
                    return !reverse ? width + 15 : -(width + 15);
                })
                .attr("y", () => {
                    return 15;
                }).attr("font-size", "14px").attr("font-weight", "700").attr("text-anchor", "middle");
        }

    }

    /**
     * Creates a rectangle with left rounded corners.
     * @param {number} x The x coordinate to start.
     * @param y The y coordinate to start.
     * @param width The width of the rectangle.
     * @param height The height of the rectangle.
     * @param radius The radius of the rounded corners.
     * @memberOf HorizontalBarComponent
     */
    private leftRoundedRectangle(x: number, y: number, width: number, height: number, radius: number) {
        return 'M' + x + ',' + y
            + 'h' + (width + radius)
            + 'a' + radius + ',' + radius + ' 0 0 1 ' + (-radius) + ',' + (-radius)
            + 'v' + (2 * radius - height)
            + 'a' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + (-radius)
            + 'h' + ((-width) - radius)
            + 'v' + height
            + 'z';
    }

    /**
     * Creates a rectangle with right rounded corners.
     * @param {number} x The x coordinate to start.
     * @param y The y coordinate to start.
     * @param width The width of the rectangle.
     * @param height The height of the rectangle.
     * @param radius The radius of the rounded corners.
     * @memberOf HorizontalBarComponent
     */
    private rightRoundedRectangle(x: number, y: number, width: number, height: number, radius: number) {
        return 'M' + x + ',' + y
            + 'h' + (width - radius)
            + 'a' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + radius
            + 'v' + (height - 2 * radius)
            + 'a' + radius + ',' + radius + ' 0 0 1 ' + -radius + ',' + radius
            + 'h' + (radius - width)
            + 'z';
    }

    /**
     * To get the rounded values till 3rd decimal points
     */
    roundto3Digit(num : number) {
        const factor = Math.pow(10,3);
        const roundValue = Math.round(num*factor)/factor;
        return Math.round(roundValue*100);
    }
}
