import {Injectable} from '@angular/core';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';

@Injectable({
  providedIn: 'root'
})
export class BrandIncrementalService {

  constructor(private activationProfileService: ActivationProfileService) {}

  /**
   * set brand Incrementality tree
   *
   * @returns {TreeNodeData}
   * @memberof BrandIncrementalService
   */
  setBrandIncrementality(headerList: string | string[], measureDescription: MeasureDescription, brandIncrementality: TreeNodeData[]): TreeNodeData[] {
    if (headerList.indexOf('brandIncrementality') > -1) {
      brandIncrementality = this.activationProfileService.buildMeasures(measureDescription.brandIncrementality, 'Parent', 'brandIncrementality', 1, [], null, this.activationProfileService.getTooltip('bi.' + measureDescription.brandIncrementality), true);
      if (headerList.indexOf('brandIncrementalityCompetitorSourcing') > -1) {
        const brandIncrementalitySourcing = this.activationProfileService.buildMeasures(measureDescription.brandIncrementalityCompetitorSourcing, 'Leaf', 'competitorSourcing', 2, null, null, null, true, 2);
        brandIncrementality[0].children.push(brandIncrementalitySourcing[0]);
      }
      if (headerList.indexOf('brandIncrementalityBrandPremium') > -1) {
        const brandIncrementalityBrandPremium = this.activationProfileService.buildMeasures(measureDescription.brandIncrementalityBrandPremium, 'Leaf', 'brandPremium', 2, null, null, null, true, 2);
        brandIncrementality[0].children.push(brandIncrementalityBrandPremium[0]);
      }
    }
    return brandIncrementality;

  }
}
