<div class="dropdown-container">
  <div class="select-box" [matMenuTriggerFor]="menu" (menuOpened)="toggle = true; emitMenuClosed(false)"
       (menuClosed)="toggle = false; emitMenuClosed(true)">
    <input [value]="selectedOption" [matTooltip]="
        displayType === 'checkbox'
          ? displayOptions[0].tooltipText
          : selectedOption
      " matTooltipClass="custom-tooltip" readonly />
    <span class="chevron" *ngIf="!toggle">
      <span class="sif sif-chevron-s"></span>
    </span>
    <span class="chevron" *ngIf="toggle">
      <span class="sif sif-chevron-n"></span>
    </span>
  </div>
  <mat-menu #menu="matMenu" class="drop-down-mat-menu">
    <div class="list" (click)="$event.stopPropagation()" (keydown.esc)="$event.stopPropagation(); closeDDOptions($event)">
      <div class="type-ahead-search"
           *ngIf="displayType === 'checkbox' ? copyOriginal[0][childKey].length > 10 : copyOriginal.length > 10">
        <ns-search-input (typeAheadText)="typeAheadText = $event; filterData($event)" [appendPlaceholder]="arrayName" #searchText>
        </ns-search-input>
      </div>
      <p *ngIf="displayOptions.length === 0" class="error-msg">{{ "project.validations.filter_error_msg" | translate }}</p>
      <!-- code for radio type dropdown options -->
      <mat-radio-group aria-labelledby="radio-group-label" [(ngModel)]="selectedOption"
                       [class]="{'add-indent': addRadioIndent}" [hidden]="displayType === 'checkbox'"
                       *ngIf="displayOptions.length > 0">
        <li mat-menu-item role="menuitemradio" *ngFor="let option of displayOptions" (click)="setValue(displayOptions, option)"
            [class]="{'selection': selectedOption === option[displayKey]}" class="parent-option">
          <mat-radio-button [value]="option[displayKey]" [checked]="option[displayKey] === selectedOption">
            <span style="visibility: hidden; margin-left: -8px">*</span>{{option[displayKey]}}</mat-radio-button>
        </li>
      </mat-radio-group>
      <!-- code for checkbox type dropdown options -->
      <div [hidden]="displayType !== 'checkbox'" *ngFor="let item of displayOptions">
        <li mat-menu-item role="menuitemcheckbox" [class]="item.allComplete ? 'selection' : ''" class="parent-option checkbox-options">
          <mat-checkbox [checked]="item.allComplete" [indeterminate]="partialComplete(item)"
                        (change)="setAll($event.checked, item)" #parent>
            <span style="visibility: hidden; margin-left: -8px">*</span>{{ item[displayKey] }}
          </mat-checkbox>
        </li>
        <div class="sub-list">
          <li mat-menu-item role="menuitemcheckbox" *ngFor="let subItem of item[childKey]" [class]="subItem[flagKey] ? 'selection' : ''" class="checkbox-options">
            <mat-checkbox [(ngModel)]="subItem[flagKey]" (ngModelChange)="updateAllComplete(item)">
              <span style="visibility: hidden; margin-left: -8px">*</span>{{ subItem[displayChildKey] }}
            </mat-checkbox>
          </li>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
