<div  class="tab-content reports-deliverables-content">
    <div class="deliverable-cards-group" cdkDropListGroup>
      <div *ngFor="let deliverableInfo of currentDeliverableInfos;let i=index">
        <div *ngIf="deliverableInfo.showDeliverable || undefined === deliverableInfo.showDeliverable" class="deliverable-card-drag" cdk-drag-animating cdkDropList  cdk-drag-placeholder  cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="{item:deliverableInfo,index:i}" (cdkDropListDropped)="dragDropCards($event)" [cdkDropListDisabled]="!isInternalUser || deliverablesInReport?.length === 1">
          <div class="deliverable-card" cdkDrag (cdkDragEntered)="dragEntered($event)" (cdkDragStarted)="dragStarted($event)">
            <ns-deliverable-card [isInternalUser]="isInternalUser" [deliverableInfo]="deliverableInfo" [deliverablesInReport]="deliverablesInReport"></ns-deliverable-card>
          </div>
        </div>
      </div>
    </div>
</div>
