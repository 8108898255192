<mat-card class="concept-card" *ngIf="concept" [ngClass]="{'imported disabled': isCardDisabled}">
  <div class="concept-card-header">
    <h3 class="concept-name">{{concept?.name}}</h3>
    <div class="sample-group text-grey-2">{{subgroup?.name}}</div>
  </div>
  <mat-card-content>
    <div class="card-content">
      <mat-checkbox class="toggle-concepts" [checked]="conceptAdded || isImported(importedConceptIds, concept.exerciseConceptId)" (change)="toggleSelect()"
        [disabled]="isCardDisabled"
        [matTooltip]="disabledCheckboxIncompleteDetailsTooltip" [matTooltipClass]="['custom-tooltip', 'pre-tooltip']"></mat-checkbox>
      <img [src]="conceptImageUrl" alt="Icon" class="concept-image-thumbnail padding-left-1-point-5 padding-right-1-point-5" (mouseenter) = "showFullSizeConcept()" (mouseleave)="closeFullSizeConcept()">
      <div *ngIf="!isAllDataAvailable();else factor_fp">
            <div>No Deliverable Data Found </div>
        </div>
        <ng-template #factor_fp>
            <div class="activation-no-data" *ngIf="!measureDataForConcept || !activationProfileShowDeliverable">
                <h1 class="title">Activation Profile</h1>
                <p>No deliverable data found </p>
            </div>
            <ns-benchmark-concept-activation-profile *ngIf="activationProfileShowDeliverable"
                                          class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
                                          [measureDataForConcept]="measureDataForConcept"
                                          [concept]="concept"
                                          [report]="report"></ns-benchmark-concept-activation-profile>
            <div  class="separator"></div>
            <div class="no-data" *ngIf="!factorsShowDeliverable">
                <h1 class="title">Factors for Success</h1>
                <p>No deliverable data Found </p>
            </div>
      <ns-benchmark-concept-factors *ngIf="factorsShowDeliverable"
        class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
        [factorsDataForConcept]="factorsDataForConcept"
        [concept]="concept"
        [report]="report"></ns-benchmark-concept-factors>
      <div class="separator"></div>
      <div class="no-data" *ngIf="!fpShowDeliverable">
          <h1 class="title">Financial Potential</h1>
          <p>No deliverable data Found </p>
      </div>
      <ns-benchmark-concept-financial-potential *ngIf="fpShowDeliverable"
        class="padding-top-1 padding-left-1-point-5 padding-right-1-point-5"
        [measureDataForConcept]="fpMeasureDataForConcept"
        [showPurchaseIntent]="fpShowPurchaseIntent"
        [showFrequency]="fpShowFrequency"
        [showUnits]="fpShowUnits"
        [concept]="concept"
        [report]="report"></ns-benchmark-concept-financial-potential>
  </ng-template>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #fullSizeConceptImageDialog>
  <img [src]="conceptImageUrl" alt="full size concept image" class="concept-image-full">
</ng-template>
