<div class="report-settings-container">
    <ng-container *ngIf="showMainMenu">
        <ns-report-settings-configuration
                [flyoutMainMenu]="flyoutMainMenu"
                (toggleOnclickEvent)="toggleOnclick($event)"
                (moveToPreviousMenuEvent)="returnBack()"
                (closeFlyoutMenuEvent)="closeFlyoutMenu()"></ns-report-settings-configuration>
    </ng-container>
    <div id="selected-menu" *ngIf="!showMainMenu">
        <ns-deliverable-configuration-body>
            <ng-container [ngSwitch]="selectedMenu.name">
                <div *ngSwitchCase="'deliverableConfiguration'">
                    <ns-deliverable-configuration
                            [deliverablesInReport]="deliverablesInReport"
                            [report]="report"
                            [defaultCardsViewExist]="defaultCardsViewExist"
                            [userInfo] = userInfo
                            [deliverableConfigurationsInReport]="deliverableConfigurationsInReport"
                            (changeOfShoeHideIndicator)="changeOfShowHideIndicator($event)"
                            (onFlyoutMenuClick)="selectDeliverableConfigurationChild($event)"
                            (moveToPreviousMenuEvent)="returnBack()"
                            (discardChangesInMainMenu)="discardChangesInMainMenu()"
                            (closeFlyoutMenuEvent)="closeFlyoutMenu()"></ns-deliverable-configuration>
                </div>
                <div *ngSwitchCase="'forecastEstimate'">
                    <ns-forecast-estimate
                            [deliverablesConfig]="deliverableConfigurationsInReport"
                            [dataList]="clonedForecastEstimateConfiguration"
                            [reportId]="report.id"
                            (moveToPreviousMenuEvent)="returnBack()"
                            (closeFlyoutMenuEvent)="closeFlyoutMenu()"></ns-forecast-estimate>
                </div>
                <div *ngSwitchCase="'financial.potential'">
                    <ns-financial-potential-configuration
                        [deliverableConfiguration]="clonedFinancialPotentialConfiguration"
                        (moveToPreviousMenuEvent)="returnBack()"
                        (closeFlyoutMenuEvent)="closeFlyoutMenu()"
                        (configUpdated)="updateFinancialPotentialConfig($event)"></ns-financial-potential-configuration>
                </div>
                <div *ngSwitchCase="'forecast.volume.revenue'">
                    <ns-volume-estimate-configuration
                            [deliverablesConfig]="deliverableConfigurationsInReport"
                            [dataList]="clonedVolumeEstimateConfiguration?.estimates"
                            [mboList]="clonedVolumeEstimateConfiguration?.mboList"
                            [tableData]="clonedVolumeEstimateConfiguration?.tableData"
                            [formatData]="clonedVolumeEstimateConfiguration?.formatData" [reportId]="report.id"
                            (configUpdated)="updateVolumeConfig($event)"
                            (moveToPreviousMenuEvent)="returnBack()"
                            (closeFlyoutMenuEvent)="closeFlyoutMenu()"></ns-volume-estimate-configuration>
                </div>
                <div *ngSwitchCase="'forecast.marketing.plan.summary'">
                    <ns-marketing-plan-configuration
                            [deliverablesConfig]="deliverableConfigurationsInReport"
                            [dataList]="clonedMarketingPlanConfiguration?.estimates"
                            [tableData]="clonedMarketingPlanConfiguration?.tableData"
                            [headerTableData]="clonedMarketingPlanConfiguration?.headerTableData"
                            [reportId]="report.id"
                            [marketingPlanConfig]="clonedMarketingPlanConfiguration"
                            (moveToPreviousMenuEvent)="returnBack()"
                            (closeFlyoutMenuEvent)="closeFlyoutMenu()"></ns-marketing-plan-configuration>
                </div>
                <div *ngSwitchCase="'forecast.variety.split'">
                    <ns-variety-split-deliverable-configuration
                            [estimates]="clonedVarietySplitConfiguration?.estimates"
                            [rowConfig]="clonedVarietySplitConfiguration?.summaryConfig?.rowsConfig"
                            [columnConfig]="clonedVarietySplitConfiguration?.summaryConfig?.columnsConfig"
                            [varietySplitDeliverableConfig]="clonedVarietySplitConfiguration"
                            [deliverablesConfig]="deliverableConfigurationsInReport" [report]="report"
                            (configUpdated)="updateVarietyConfig($event)"
                            (moveToPreviousMenuEvent)="returnBack()"
                            (closeFlyoutMenuEvent)="closeFlyoutMenu()"></ns-variety-split-deliverable-configuration>
                </div>
                <div *ngSwitchCase="'correlations'">
                    <ns-correlations-configuration [report]="report"
                                                   [dataList] = "clonedCorrelationsConfigurations"
                                                   [toggleDataTableAllValues]= "toggleDataTableAllValues"
                                                   [toggleQuadTableAllValues] = "toggleQuadTableAllValues"
                                                   (moveToPreviousMenuEvent)="returnBack()"
                                                   (closeFlyoutMenuEvent)="closeFlyoutMenu()"
                    ></ns-correlations-configuration>
                </div>
                <div *ngSwitchCase="'custom.question'">
                    <ns-survey-questions-configuration
                            [deliverableConfiguration]="surveyQuestionsConfiguration"
                            (moveToPreviousMenuEvent)="moveToPreviousMenu()"
                            (closeFlyoutMenuEvent)="closeFlyoutMenu()"
                            (saveEvent)="onSurveyQuestionConfigurationSave($event)">
                    </ns-survey-questions-configuration>
                </div>
                <div *ngSwitchCase="'purchase.preference'">
                    <ns-purchase-preference
                            [deliverablesConfig]="deliverableConfigurationsInReport"
                            [purchasePreferenceShareConfiguration]="this.clonedPurchasePreferenceShareConfiguration"
                            [report]="report"
                            (moveToPreviousMenuEvent)="returnBack()"
                            (closeFlyoutMenuEvent)="closeFlyoutMenu()"></ns-purchase-preference>
                </div>
                <div *ngSwitchCase="'factors'">
                    <ns-factors-configuration [report]="report"
                                              [deliverableConfigurationsInReport]="deliverableConfigurationsInReport"
                                              [factorsConfiguration]="clonedFactorsConfiguration"
                                              (moveToPreviousMenuEvent)="returnBack()"
                                              (closeFlyoutMenuEvent)="closeFlyoutMenu()"
                                              (configUpdated)="updateFactorsConfig($event)"
                    (setInitialConfigInReportEvent)="setInitialFactorsConfig($event)"></ns-factors-configuration>
                </div>
            </ng-container>
            <div id="subgroups" *ngIf="selectedMenu.name == 'subgroups'">
                Subgroups
            </div>
            <div id="items-ordering" *ngIf="selectedMenu.name == 'itemsAndOrdering'">
                Concepts
            </div>
        </ns-deliverable-configuration-body>
        <ns-deliverable-configuration-footer
                *ngIf="displayButtonGroup()"
                [enableReset]="isResetButtonEnabled()"
                [enableSave]="!isDisableButtons()"
                (resetBtnClickEvent)="resetToDefault()"
                (saveBtnClickEvent)="onSave()"
                (saveAndCloseBtnClickEvent)="onSaveAndClose()"></ns-deliverable-configuration-footer>
    </div>
</div>
