import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from '@app/core/config/app-config.service';
import {DeliverableInsightService} from './deliverable-insight.service';
import {ReportService} from './report.service';
import {ToasterService} from './toaster.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportPngService {

  private readonly baseURI: string;

  constructor(
    private deliverableInsightService: DeliverableInsightService,
    private httpClient: HttpClient,
    private reportService: ReportService,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private cs: AppConfigService) {
    this.baseURI = `${this.cs.config.reporting.url}`;
  }

  /**
   * export to png.
   *
   * @param
   * @memberOf ExportPngService
   */
  public exportPNG(selectors?: Array<string>,removeFlyoutClass=false): void {
    this.spinnerService.setSpinnerObs();
    const imageSelectors = selectors && selectors.length > 0 ? selectors : ['#deliverable-container'];
    const getImages = this.deliverableInsightService.getCleanHTML(removeFlyoutClass);
    const report$ = this.reportService.get().pipe(take(1));
    report$.subscribe(report => {
      const fileDetails = {
        sourceHTML: getImages,
        fileName: report.name,
        imageSelectors: imageSelectors,
        host: window.location.origin
      };
      const options = this.exportAPIOptionDetails();
      const url = `${this.baseURI}/reports/${report.id}/image`;
      return this.httpClient.post(url, fileDetails, options).subscribe(data => {
        this.downloadExportFile(report.name, data, selectors)
      }, (err: HttpErrorResponse) => {
        if (err instanceof Error) {
          console.log(`An error occurred ${err.error.message}`);
        } else {
          console.log(`${err}`);
        }
        this.spinnerService.spinnerObs$.next(false);
        this.toasterService.openSnackBar('Error while saving the file', 'error');
      });
    });
  }

  /**
   * Export all images as Zip
   * @param reportId
   * @param fileNames seperated by ',' example: 'additional-info.png, additional-info-1.png, additional-info-2.png'
   */
  exportPNGAsZip(reportId: string, reportName: string, fileNames: string){
    this.spinnerService.setSpinnerObs();
    const names = fileNames.split(',');
    const options = this.exportAPIOptionDetails();
    const url = `${this.baseURI}/reports/${reportId}/inputs?fileNames=${fileNames}`;
    return this.httpClient.get(url,options).subscribe(data =>{
      this.downloadExportFile(reportName, data, names)
    }, (err: HttpErrorResponse) => {
      if (err instanceof Error) {
        console.log(`An error occurred ${err.error.message}`);
      } else {
        console.log(`${err}`);
      }
      this.spinnerService.spinnerObs$.next(false);
      this.toasterService.openSnackBar('Error while saving the file', 'error');
    })
  }

  /**
   * Export API requires options which are returned from this method
   */
  exportAPIOptionDetails(): any {
    const contentType = 'application/octet-stream';
    const headers = new HttpHeaders({
      Accept: contentType
    });
    const options = {
      responseType: 'blob' as 'blob',
      headers: headers
    };
    return options;
  }

  /**
   *
   * @param reportName
   * @param data
   * @param fileNames
   */
  downloadExportFile(reportName: string, data: any, fileNames: Array<string>) {
    this.spinnerService.spinnerObs$.next(false);
    const downloadURL = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileNames && fileNames.length > 1 ? `${reportName}.zip` : `${reportName}.png`;
    link.click();
    link.remove();
  }


}
