import { Injectable } from '@angular/core';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';

@Injectable({
  providedIn: 'root'
})
export class IndustryDistinctionService {

  constructor(private activationProfileService: ActivationProfileService) { }

  /**
   * set industry distinction tree
   *
   * @returns {TreeNodeData}
   * @memberof IndustryDistinctionService
   */
  setIndustryDistinction(headerList: string|string[], measureDescription: MeasureDescription, industryDistinction: TreeNodeData[]): TreeNodeData[] {
    if (headerList.indexOf('industryDistinction') > -1) {
      industryDistinction = this.activationProfileService.buildMeasures(measureDescription.industryDistinction, 'Parent', 'industryDistinction', 1, [], null, this.activationProfileService.getTooltip('id.'+ measureDescription.industryDistinction), true);
      if (headerList.indexOf('advantageFactorColor') > -1) {
        const advantageFactorColor = this.activationProfileService.buildMeasures(measureDescription.advantageFactorColor, 'Leaf', 'advantage', 2, null, null, null, true, 4);
        industryDistinction[0].children.push(advantageFactorColor[0]);
      }
      if (headerList.indexOf('distinctPropositionFactorColor') > -1) {
        const distinctPropositionFactorColor = this.activationProfileService.buildMeasures(measureDescription.distinctPropositionFactorColor, 'Leaf', 'distinctBenefits', 2, null, null, null, true, 4);
        industryDistinction[0].children.push(distinctPropositionFactorColor[0]);
      }
    }
    return industryDistinction;
  }
}
