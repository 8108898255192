import {ProductDeliverableType} from '@platform/models/product-deliverable-type';

const imageBasePath = 'assets/images/deliverables';

/**
 * Lists all the deliverable types supported in reporting.
 */
export const DeliverableType: Record<string, ProductDeliverableType> = {
    ACTIVATION_PROFILE: {
        type: 'activation.profiles',
        path: 'activation-profile',
        labelKey: 'quick.predict.deliverables.activation.profile.label',
        descriptionKey: 'quick.predict.deliverables.activation.profile.description',
        imagePath: `${imageBasePath}/activation-profiles.svg`,
        position: 1,
        showDeliverable: true
    },
    SCORE_CARD: {
        type: 'score.card',
        path: 'score-card',
        labelKey: 'innovation.screener.deliverables.score-card.label',
        descriptionKey: 'innovation.screener.deliverables.score-card.description',
        imagePath: `${imageBasePath}/score-card.svg`,
        position: 2,
        showDeliverable: true
    },
    SORTING_MATRIX: {
        type: 'sorting.matrix',
        path: 'sorting-matrix',
        labelKey: 'quick.screen.deliverables.sortingMatrix.label',
        descriptionKey: 'quick.screen.deliverables.sorting-matrix.description',
        imagePath: `${imageBasePath}/sorting-matrix.svg`,
        position: 3,
        showDeliverable: true
    },
    PRICE_MENTIONS: {
        type: 'mention.price',
        path: 'price-mentions',
        labelKey: 'price.advisor.deliverables.price.mentions.label',
        descriptionKey: 'price.advisor.deliverables.price.mentions.description',
        imagePath: `${imageBasePath}/price-mentions.svg`,
        position: 4,
        showDeliverable: true
    },
    PRICE_ELASTICITY: {
        type: 'optimal.price',
        path: 'price-elasticity',
        labelKey: 'price.advisor.deliverables.price.elasticity.label',
        descriptionKey: 'price.advisor.deliverables.price.elasticity.description',
        imagePath: `${imageBasePath}/price-elasticity.svg`,
        position: 5,
        showDeliverable: true
    },
    PERFORMANCE: {
        type: 'performance',
        path: 'performance',
        labelKey: 'restage.deliverable.performance.label',
        descriptionKey: 'restage.deliverable.performance.description',
        imagePath: `${imageBasePath}/performance.svg`,
        position: 6,
        showDeliverable: true
    },
    INTERACTIONS: {
        type: 'interactions',
        path: 'interactions',
        labelKey: 'restage.deliverable.interactions.label',
        descriptionKey: 'restage.deliverable.interactions.description',
        imagePath: `${imageBasePath}/interactions.svg`,
        position: 7,
        showDeliverable: true
    },
    FACTORS: {
        type: 'factors',
        path: 'factors',
        labelKey: 'quick.predict.deliverables.factors.label',
        descriptionKey: 'quick.predict.deliverables.factors.description',
        imagePath: `${imageBasePath}/factors-for-success.svg`,
        position: 8,
        showDeliverable: true
    },
    TARGET_GROUP_ANALYSIS: {
        type: 'target.group.analysis',
        path: 'target-group-analysis',
        labelKey: 'shared.deliverables.tgpa.label',
        descriptionKey: 'shared.deliverables.tgpa.description',
        imagePath: `${imageBasePath}/target-group-analysis.svg`,
        position: 9,
        showDeliverable: true
    },
    FINANCIAL_POTENTIAL: {
        type: 'financial.potential',
        path: 'financial-potential',
        labelKey: 'shared.deliverables.financial-potential.label',
        descriptionKey: 'shared.deliverables.financial-potential.description',
        imagePath: `${imageBasePath}/financial-potential.svg`,
        position: 10,
        showDeliverable: true
    },
    GAP_ANALYSIS: {
        type: 'gap.analysis',
        path: 'gap-analysis',
        labelKey: 'restage.deliverable.gap.analysis.label',
        descriptionKey: 'restage.deliverable.gap.analysis.description',
        imagePath: `${imageBasePath}/gap-analysis.svg`,
        position: 11,
        showDeliverable: true
    },
    ATTRIBUTES: {
        type: 'attributes',
        path: 'attributes',
        labelKey: 'shared.deliverables.attributes.label',
        descriptionKey: 'shared.deliverables.attributes.description',
        imagePath: `${imageBasePath}/attributes.svg`,
        position: 12,
        showDeliverable: true
    },
    CORRELATIONS: {
        type: 'correlations',
        path: 'correlations',
        labelKey: 'shared.deliverables.correlations.label',
        descriptionKey: 'shared.deliverables.correlations.description',
        imagePath: `${imageBasePath}/correlations.svg`,
        position: 13,
        showDeliverable: true
    },
    CLICK_COMMENT: {
        type: 'click.comment',
        path: 'click-comment',
        labelKey: 'quick.question.deliverables.click.comment.label',
        descriptionKey: 'quick.question.deliverables.click.comment.description',
        imagePath: `${imageBasePath}/click-comment.svg`,
        position: 14,
        showDeliverable: true
    },
    STRENGTH_WATCHOUTS: {
        type: 'strengths.watchouts',
        path: 'strength-watchouts',
        labelKey: 'shared.deliverables.strengthsWatchouts.label',
        descriptionKey: 'shared.deliverables.strength-watchouts.description',
        imagePath: `${imageBasePath}/strength-watchouts.svg`,
        position: 15,
        showDeliverable: true
    },
    SURVEY_QUESTION: {
        type: 'custom.question',
        path: 'survey-question',
        configurationName: 'surveyquestions',
        labelKey: 'shared.deliverables.survey-question.label',
        descriptionKey: 'shared.deliverables.survey-question.description',
        imagePath: `${imageBasePath}/survey-question.svg`,
        position: 16,
        showDeliverable: true
    },
    WORD_CLOUD: {
        type: 'word.cloud',
        path: 'word-cloud',
        labelKey: 'deliverables.wordcloud.label',
        descriptionKey: 'deliverables.word-cloud.description',
        imagePath: `${imageBasePath}/word-cloud.svg`,
        position: 17,
        showDeliverable: true
    },
    REACH_ANALYSIS: {
        type: 'reach.analysis',
        path: 'reach-analysis',
        labelKey: 'shared.deliverables.deliverables.reach.label',
        descriptionKey: 'shared.deliverables.deliverables.reach.description',
        imagePath: `${imageBasePath}/reach-analysis.svg`,
        position: 18,
        showDeliverable: true
    },
    ADDITIONAL_INFO: {
        type: 'additional.info',
        path: 'additional-info',
        labelKey: 'shared.deliverables.additionalInfo.label',
        descriptionKey: 'shared.deliverables.additionalInfo.description',
        imagePath: `${imageBasePath}/additional-info.svg`,
        position: 19,
        showDeliverable: true
    },
    ACTIVATION_PROFILE_LEGACY: {
        type: 'activation.profile.legacy',
        path: 'activation-profile-legacy',
        labelKey: 'quick.predict.deliverables.activation.profile.label',
        descriptionKey: 'quick.predict.deliverables.activation.profile.description',
        imagePath: `${imageBasePath}/activation-profiles.svg`,
        position: 20,
        showDeliverable: true
    },
    FORECAST_ASSUMPTIONS: {
        type: 'forecast.assumption',
        path: 'assumptions',
        labelKey: 'platform.forecasting.assumptions.label',
        descriptionKey: 'platform.forecasting.assumptions.description',
        imagePath: `${imageBasePath}/assumptions.svg`,
        position: 21,
        showDeliverable: true
    },
    FORECAST_MARKETING_SUMMARY: {
        type: 'forecast.marketing.plan.summary',
        path: 'marketing-summary',
        labelKey: 'platform.forecasting.marketing.summary.label',
        descriptionKey: 'platform.forecasting.marketing.summary.description',
        imagePath: `${imageBasePath}/marketing-summary.svg`,
        position: 22,
        showDeliverable: true
    },
    FORECAST_RESULTS: {
        type: 'forecast.volume.revenue',
        path: 'results',
        labelKey: 'platform.forecasting.results.label',
        descriptionKey: 'platform.forecasting.results.description',
        imagePath: `${imageBasePath}/forecasting-results.svg`,
        position: 23,
        showDeliverable: true
    },
    FORECAST_VARIETY_SPLIT: {
        type: 'forecast.variety.split',
        path: 'variety-split',
        labelKey: 'platform.forecasting.variety.split.label',
        descriptionKey: 'platform.forecasting.variety.split.description',
        imagePath: `${imageBasePath}/variety-split.svg`,
        position: 24,
        showDeliverable: true
    },
    EASY_FGA: {
        type: 'forecast.easy.fga',
        path: 'easy-fga',
        labelKey: 'platform.forecasting.easy.fga.label',
        descriptionKey: 'platform.forecasting.easy.fga.description',
        imagePath: `${imageBasePath}/easy-fga.svg`,
        position: 25,
        showDeliverable: true
    },
    PURCHASE_PREFERENCE_SHARE: {
        type: 'purchase.preference',
        path: 'purchase-preference',
        labelKey: 'platform.deliverables.purchase.preference.label',
        descriptionKey: 'platform.deliverables.purchase.preference.description',
        imagePath: `${imageBasePath}/purchase-preference.svg`,
        position: 1,
        showDeliverable: true
    },
};
