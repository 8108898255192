import {createAction, props} from '@ngrx/store';
import {Organization} from "@platform/models/organization.model";

/**
 * Action for adding list of organizations to state.
 *
 * @export
 */
export const addOrganizations = createAction(
  '[Organization] Add Organizations',
  props<{ organizations: Organization[] }>()
);
