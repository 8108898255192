<div class="deliverable-container factors-content">
  <ng-container *ngIf="!scoreCardConcept">
    <!--Deliverable headers -->
    <div class="sticky-header-container header-filter-container" *ngIf="!scoreCardConcept" [ngClass]="{'hide': isInsightEnable}">
          <ns-deliverable-nav-header [deliverableInfos]="deliverableInfos" (deliverableChanged)="onDeliverableChange($event)">
          <ns-kebab-menu>
            <ul class="kebab-menu-list">
              <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
            </ul>
          </ns-kebab-menu>
              <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}" class="insight-icon sif sif-insight" (click)="openInsightCreationForm()">
            </span>
      <span saveUserView>
                <ns-save-user-view class="save-views"
                                   [(views)]="userViews"
                                   [filter]="filter"
                                   [reportId]="reportId"
                                   [deliverableType]="deliverableType"
                                   (viewsChange)="userViews=$event"
                                   (selectUserView)="selectUserView($event)"></ns-save-user-view>
            </span>
          </ns-deliverable-nav-header>
    </div>
      <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData"
                              [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()"></ns-deliverable-insight>
  </ng-container>

  <div id="deliverable-container">
    <div class="filters-sticky-container">
      <!--factors filters -->
      <ns-factors-filter *ngIf="deliverableViews" [deliverableViews]="deliverableViews" [concept]="scoreCardConcept" [isConceptView]="isConceptView"></ns-factors-filter>
    </div>

    <div class="factors-for-success-table-container deliverable-insight-factors insight-html-root carousel-container">
      <ng-container *ngIf="filter">
        <ng-container [ngSwitch]="filter.deliverableViewType">
          <ns-concepts-view *ngSwitchDefault [reportId]="reportId" [deliverableViews]="deliverableViews" [conceptsDeliverableView]="factors" (hasDeliverableData)="checkDeliverableDataPresence($event)"></ns-concepts-view>
          <ns-carousel-table *ngSwitchCase="'subgroup'" class="carousel-panel" [isManaged]="false" [staticColumns]="staticColumns" [columnWidthList]="columnWidths" (change)="update($event)"
                             (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>
          <ns-subgroup-means *ngSwitchCase="'subgroup'" [subGroupView]="subgroupViews" (hasDeliverableData)="checkDeliverableDataPresence($event)"></ns-subgroup-means>
        </ng-container>
      </ng-container>

      <!--Factors - Footer legend Section-->
      <div class="legend" *ngIf="hasData && filter">
        <ns-factors-legend class="legend-container"></ns-factors-legend>
      </div>

    </div>

  </div>
</div>
