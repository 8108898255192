import { DeliverableInfo } from '@platform/models/deliverable-info.model';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Deliverable } from '@platform/models/deliverable.model';

@Component({
  selector: 'ns-deliverable-card',
  templateUrl: './deliverable-card.component.html',
  styleUrls: ['./deliverable-card.component.scss']
})
export class DeliverableCardComponent implements OnInit {
  @Input() deliverableInfo: DeliverableInfo;
  @Input() deliverablesInReport: Deliverable[];
  @Input() isInternalUser: boolean;


  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

}
