/**
 * Compare view for Correlations.
 *
 * @export
 * @enum {string}
 */
 export enum CompareView {
    /**
      * DATATABLE deliverable view type.
      */
     DATA_TABLE = 'dataTable',
     /**
      * QUADMAP deliverable view type.
      */
     QUAD_MAP = 'quadMap'
}
