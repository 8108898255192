import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {Observable, of, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterService} from '@platform/services/filter.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';

/**
 * This service provides operations for accessing product deliverable views.
 *
 * @example
 * constructor(private productDeliverableViewService: ProductDeliverableViewService) { }
 *
 * @export
 * @class ProductDeliverableViewService
 */
@Injectable({
    providedIn: 'root'
})
export class ProductDeliverableViewService {

    readonly cache: Record<string, any>;

    /**
     * Creates an instance of ProductDeliverableViewService.
     *
     * @constructor
     * @param {HttpClient} httpClient
     * @param {FilterService} filterService
     * @param {ReportService} reportService
     * @param cs
     * @memberOf DeliverableViewService
     */
    constructor(
        private httpClient: HttpClient,
        private filterService: FilterService,
        private reportService: ReportService,
        private cs: AppConfigService) {
        this.cache = {};
    }

    clearCache(): void {
        Object.keys(this.cache).forEach(it => {
            delete this.cache[it];
        });
    }

    /**
     * Fetches a product deliverable view using the API. A product deliverable
     * view is a subclass of {@link DeliverableView}.
     *
     * @private
     * @template T extends {@link DeliverableView}
     * @param {string} id The deliverable view id.
     * @param {[key: string]: string} params Optional query
     * params object.
     * @param reportId
     * @returns {Observable<T>} The observable of T
     * @memberOf DeliverableViewService
     */
    public get<T>(reportId: string, id: string): Observable<T> {
        if (this.cache[id]) {
            return of(this.cache[id]);
        }
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/productDeliverableViews/${id}`;
        return this.httpClient.get<T>(url).pipe(switchMap(view => {
            this.cache[id] = view;
            return of(view);
        }));
    }

    /**
     * Fetches a product deliverable view based on deliverable view type and view name
     *
     * @param {string} reportId.
     * @param {string} type
     * @param {string} viewName
     * @returns {Observable<T>} The observable of T
     */
    public findByTypeAndName<T>(reportId: string, type: string, viewName: string): Observable<T> {
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/productDeliverableViews?type=${type}&viewName=${viewName}`;
        return this.httpClient.get<T>(url);
    }

}
