import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DeliverablesListComponent } from './deliverables-list.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';


@NgModule({
  declarations: [
    DeliverablesListComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    MatInputModule,
    FormsModule,
    MatTreeModule,
    DragDropModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  exports: [
    DeliverablesListComponent
  ],
  providers: [],
  bootstrap: [DeliverablesListComponent]
})
export class DeliverablesListModule { }
