import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewType} from '@app/deliverables/target-group-analysis/models/deliverable-view-type-enum';

/**
 * Default filter values for attributes.
 *
 * @export
 * @property
 */
export const defaultTGAFilter = {
    deliverableType: DeliverableType.TARGET_GROUP_ANALYSIS.type,
    deliverableViewType: DeliverableViewType.TARGETGROUP,
    compare: [
        {
            name: 'Target Group',
            id: 'concept',
            isSelected: true,
            position: 1
        }
    ]
};
