import { DeliverableType } from "@products/restage/deliverable-type.enum";
import { DeliverableViewType } from "./deliverable-view-type-enum";

/**
 * Default filter values for performance.
 *
 * @export
 * @property
*/
export const defaultGapAnalysisFilter = {
  deliverableType: DeliverableType.GAP_ANALYSIS.type,
  deliverableViewType: DeliverableViewType.CONCEPT,
  show: {
    meanIndex: true,
    topBoxIndex: false,
    topTwoBoxIndex: false,
  }
};
