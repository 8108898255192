import { State } from '@platform/store/state/app.state';
import { AppConfigService } from '@app/core/config/app-config.service';
import { ReportService } from '@platform/services/report.service';
import { Observable } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Subgroup } from '@platform/models/subgroup.model';
import { addSubgroups } from '@platform/store/actions/subgroup.actions';
import { selectReportSubgroups } from '@platform/store/selectors/subgroup.selectors';

/**
 * This service provides operations for fetching and loading report subgroups.
 *
 * @example
 * constructor(private subgroupService: SubgroupService) { }
 *
 * @export
 * @class SubgroupService
 */
@Injectable({
  providedIn: 'root'
})
export class SubgroupService {

  /**
   * Loading flag to prevent multiple server calls.
   *
   * @private
   */
  private isLoading = false;

  /**
   * Creates an instance of SubgroupService.
   *
   * @param {Store<State>} store The application state.
   * @param {HttpClient} httpClient
   * @param {ReportService} reportService
   * @memberof SubgroupService
   */
  constructor(
    private store: Store<State>,
    private httpClient: HttpClient,
    private reportService: ReportService,
    private cs: AppConfigService ) { }

  /**
   * Returns observable of subgroups for the current report object
   * from the store if available else fetch it using API.
   *
   * @example
   * const subgroups$ = subgroupService.getReportSubgroups();
   *
   * @returns {Observable<Array<Subgroup>>} The subgroups observable.
   * @memberof SubgroupService
   */
  public getReportSubgroups(): Observable<Array<Subgroup>> {
    const report$ = this.reportService.get();
    const subgroups$ = report$.pipe(
      switchMap(report => {
        this.load(report.id);
        return this.fetchReportSubgroupsFromStore(report.id);
      }),
      skipWhile(subgroups => subgroups.length === 0)
    );
    return subgroups$;
  }

  /**
   * Loads all subgroups associated with the current report into
   * the store.
   *
   * @example
   * subgroupService.load('1');
   *
   * @param {string} reportId The report id.
   * @memberof SubgroupService
   */
  public load(reportId: string): void {
    const subgroups$ = this.fetchReportSubgroupsFromStore(reportId);
    subgroups$.pipe(take(1)).subscribe(subgroups => {
      if (this.isLoading !== true && subgroups.length === 0) {
        this.isLoading = true;
        this.fetchFromAPI(reportId).subscribe(result => {
          this.loadOnStore(result);
          this.isLoading = false;
        });
      }
    });
  }

  /**
   * Returns an observable of subgroups fetched using the API.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Array<Subgroup>>} The subgroups observable.
   * @memberof SubgroupService
   */
  public fetchFromAPI(reportId: string): Observable<Array<Subgroup>> {
    const url = `${this.cs.config.reporting.url}/reports/${reportId}/subgroups`;
    return this.httpClient.get<Array<Subgroup>>(url);
  }

  /**
   * Loads a list of subgroups into the store.
   *
   * @private
   * @param {Array<Subgroup>} subgroups Array of subgroups
   * @memberof SubgroupService
   */
  public loadOnStore(subgroups: Array<Subgroup>): void {
    this.store.dispatch(addSubgroups({ subgroups }));
  }

  /**
   * Fetch all subgroups associated with the current report
   * from the store.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Array<Subgroup>>} The subgroups observable.
   * @memberof SubgroupService
   */
  private fetchReportSubgroupsFromStore(reportId: string): Observable<Array<Subgroup>> {
    return this.store.pipe(
      select(selectReportSubgroups, { reportId })
    );
  }

}
