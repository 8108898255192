import {Injectable} from '@angular/core';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';

@Injectable({
  providedIn: 'root'
})
export class TargetPotentialService {

  constructor(private activationProfileService: ActivationProfileService) {
  }

  /**
   * set target potential tree node.
   *
   * @returns {TreeNodeData}
   * @memberof TargetPotentialService
   */
  setTargetPotential(headerList: string | string[], measureDescription: MeasureDescription, targetPotential: TreeNodeData[]): TreeNodeData[] {
    if (headerList.indexOf('targetedPotential') > -1) {
      targetPotential = this.activationProfileService.buildMeasures(measureDescription.targetedPotential, 'Parent', 'targetPotential', 1, [], null, this.activationProfileService.getTooltip('tp.' + measureDescription.targetedPotential), true);
      if (headerList.indexOf('tgName') > -1) {
        const tgName = this.activationProfileService.buildMeasures(measureDescription.tgName, 'Leaf', 'group.name', 2, null, null, null, false);
        targetPotential[0].children.push(tgName[0]);
      }
      if (headerList.indexOf('tgWPITertile') > -1) {
        const tgWPITertile = this.activationProfileService.buildMeasures(measureDescription.tgWPITertile, 'Parent', 'targetTrialInterest', 2, [], null, null, true, 3);
        targetPotential[0].children.push(tgWPITertile[0]);

        //S2-1707 AP: Missed requirement - temporarily suppress TG trial interest dropdown from AP deliverable
        // const definitely = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.tgDefinitelyWouldBuy), 'Leaf', 'targetDefinitely', 3, null, null, null, false);
        // const probably = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.tgProbablyWouldBuy), 'Leaf', 'targetProbably', 3, null, null, null, false);
        // const might = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.tgMightorMightNotBuy), 'Leaf', 'targetMight', 3, null, null, null, false);
        // const probablyNot = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.tgProbablyWouldNotBuy), 'Leaf', 'targetProbablyNot', 3, null, null, null, false);
        // const definitelyNot = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.tgDefinitelyWouldNotBuy), 'Leaf', 'targetDefinitelyNot', 3, null, null, null, false);
        // tgWPITertile[0].children.push(definitely[0]);
        // tgWPITertile[0].children.push(probably[0]);
        // tgWPITertile[0].children.push(probablyNot[0]);
        // tgWPITertile[0].children.push(might[0]);
        // tgWPITertile[0].children.push(definitelyNot[0]);
      }
      if (headerList.indexOf('tgNeedDesireTertile') > -1) {
        const needDesire = this.activationProfileService.buildMeasures(measureDescription.tgNeedDesireTertile, 'Leaf', 'targetDesire', 2, null, null, null, true, 3);
        targetPotential[0].children.push(needDesire[0]);
      }
      if (headerList.indexOf('tgDesireTertile') > -1) {
        const desire = this.activationProfileService.buildMeasures(measureDescription.tgDesireTertile, 'Leaf', 'targetDesire', 2, null, null, null, true, 3);
        targetPotential[0].children.push(desire[0]);
      }
      if (headerList.indexOf('tgAcceptableCostsTertile')) {
        const costs = this.activationProfileService.buildMeasures(measureDescription.tgAcceptableCostsTertile, 'Leaf', 'targetCosts', 2, null, null, null, true, 3);
        targetPotential[0].children.push(costs[0]);
      }
      return targetPotential;
    }
  }
}
