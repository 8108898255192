import {Component, Input, OnInit} from '@angular/core';
import {CombinationDataItem} from '@app/deliverables/reach-analysis/models/reach-anaylsis.model';
import {FilterService} from '@platform/services/filter.service';
import {FilterItem} from '@app/deliverables/reach-analysis/models/filter.model';
import {ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {ReachAnalysisService} from "@app/deliverables/reach-analysis/services/reach-analysis.service";

@Component({
  selector: 'ns-highlight-cell',
  templateUrl: './highlight-cell.component.html',
  styleUrls: ['./highlight-cell.component.scss']
})
export class HighlightCellComponent implements OnInit {

  @Input() public combination: CombinationDataItem;
  @Input() public variety;
  @Input() public index;
  @Input() public numbers: boolean;
  @Input() public filter: ReachAnalysisFilter;

  public isIncluded: boolean;

  constructor(
    private filterService: FilterService,
    private reachAnalysisService: ReachAnalysisService,
  ) { }

  ngOnInit(): void {
    this.isIncluded = !!this.combination.varieties.find(it => it.position === this.variety.position);
  }

  /**
   * this method will load alternative combination view with specific set value
   * */
  changeView(numbers: boolean, index: number) {
    if (numbers) {
      const filter = this.reachAnalysisService.selectSetNumber(this.filter, index)
      this.filterService.update({
        ...this.filter,
        compare: this.filter.compare.map((item: FilterItem) => {
          return {
            ...item,
            isSelected: (item.id === 'alternative-combination')
          };
        }),
        show: {
          ...filter.show,
          reload: true
        }
      });
    }
  }
}
