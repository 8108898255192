import {Concept} from '@platform/models/concept.model';
import {FinancialPotentialFilter, FilterItem} from '../models/filter.model';
import {FinancialPotentialService} from '../services/financial-potential.service';
import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';
import {Subscription, combineLatest} from 'rxjs';
import {FilterService} from '@platform/services/filter.service';
import {ReportService} from '@platform/services/report.service';
import {TranslateService} from '@ngx-translate/core';
import {Report} from '@platform/models/report.model';
import {DropdownData, DataType, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {take} from 'rxjs/operators';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {SimpleChanges} from '@angular/core';

/**
 * `FilterComponent` creates the Financial Potential filter options like compare,
 * concepts, country, subgroups, and show filter.
 *
 * @example
 *   <ns-financial-potential-filter></ns-financial-potential-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-fp-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Set the concept to set single concept filter.
     */
    @Input() concept: Concept;

    @Input() public alcoholStudy: boolean;

    @Input() deliverableViews: Array<DeliverableView>;

    /**
     * Financial Potential filter object.
     *
     * @property
     * @type {FinancialPotentialFilter}
     * @memberof FilterComponent
     */
    public filter: FinancialPotentialFilter;
    public defaultFilter: FinancialPotentialFilter;

    /**
     * List of concept dropdown items.
     *
     * @property
     * @type {Array<DropdownData>}
     * @memberof FilterComponent
     */
    public conceptsListData: DropdownData<string>;

    /**
     * List of subgroup dropdown items.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public subgroupListData: DropdownData<string>;

    /**
     * List of deliverable view dropdown items.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public compareListData: DropdownData<string>;

    /**
     * List of countries on the report.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public countryListData: DropdownData<string>;

    /**
     * List of datasets on the report.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public datasetListData: DropdownData<string>;

    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof FilterComponent
     */
    private subscriptions: Array<Subscription>;
    /**
     * Mixpanel label for the deliverable
     *
     * @property
     * @private
     * @type {string}
     * @memberof FilterComponent
     */
    private deliverableLabel: string;

    /**
     * Creates an instance of FilterComponent.
     *
     * @constructor
     * @param financialPotentialService
     * @param {FilterService} filterService
     * @param {ReportService} reportService
     * @param translate
     * @param {MixpanelService} mixpanelService
     * @memberof FilterComponent
     */
    constructor(
        private financialPotentialService: FinancialPotentialService,
        private filterService: FilterService,
        private reportService: ReportService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService
    ) {
        this.subscriptions = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.financialPotentialService.loadDefaultFilter(this.concept);
    }

    /**
     * Initialize the filter component. Sets filter and report object
     * for the template.
     *
     * @memberof FilterComponent
     */
    ngOnInit(): void {
        this.deliverableLabel = this.concept ? MixpanelLabel.concepts : MixpanelLabel.finance;
        const filter$ = this.financialPotentialService.getFinancialPotentialFilter();
        const report$ = this.reportService.get();
        const subscription = combineLatest([filter$, report$]).subscribe(([filter, report]) => {
            this.filter = filter;
            this.setFilters(filter, report);
        });
        const filterSubscription = filter$.pipe(take(1)).subscribe((filter: FinancialPotentialFilter) => this.defaultFilter = filter);
        this.subscriptions.push(subscription, filterSubscription);
    }

    /**
     * Cleanup the component on removing from the UI.
     *
     * @memberof FilterComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Sets the filter options for concepts, subgroups, compareList, countries.
     *
     * @private
     * @param {FinancialPotentialFilter} filter
     * @param {Report} report
     * @memberof FilterComponent
     */
    private setFilters(filter: FinancialPotentialFilter, report: Report): void {
        this.conceptsListData = this.getConceptFilterOptions(filter);
        this.subgroupListData = this.getSubGroupFilterOptions(filter);
        this.compareListData = this.getCompareOptions(filter.compare);
        this.countryListData = this.getCountryOptions(filter.countries[0]);
        this.datasetListData = this.getDataSetsFilterOptions(filter);
    }

    /**
     * Returns the compare filter options.
     *
     * @private
     * @returns {DropdownData}
     * @memberof FilterComponent
     * @param items
     */
    private getCompareOptions(items: Array<FilterItem>): DropdownData<string> {
        const dropdownData: DropdownItem<string>[] = items.map(item => {
            return {
                value: item.id,
                label: item.name,
                selected: item.isSelected
            };
        });
        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.financial-potential.filter.compare.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('quick.predict.deliverables.financial-potential.filter.compare.itemsTitle'),
            data: dropdownData
        };
        return dropdownItems;
    }

    /**
     * Returns the country filter options.
     *
     * @private
     * @param {string} country
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getCountryOptions(country: string): DropdownData<string> {
        return {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.financial-potential.filter.country.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('quick.predict.deliverables.financial-potential.filter.country.itemsTitle'),
            data: [
                {
                    value: country,
                    label: country,
                    selected: true
                }
            ]
        };
    }

    /**
     * Returns concepts filter options.
     *
     * @private
     * @returns {DropdownData}
     * @memberof FilterComponent
     * @param filter
     */
    private getConceptFilterOptions(filter: FinancialPotentialFilter): DropdownData<string> {
        const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.CHECKBOX : DataType.RADIO;
        const dropdownData: DropdownItem<string>[] = filter.concepts.map(item => {
            return {
                value: item.name,
                label: item.name,
                selected: item.isSelected
            };
        });
        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.financial-potential.filter.concepts.label'),
            dataType: dataType,
            contentTitle: this.translate.instant('quick.predict.deliverables.financial-potential.filter.concepts.itemsTitle'),
            groupSelect: this.translate.instant('quick.predict.deliverables.financial-potential.filter.concepts.allSelectedLabel'),
            data: dropdownData
        };

        return dropdownItems;
    }

    /**
     * Returns sub groups filter options.
     *
     * @private
     * @returns {DropdownData}
     * @memberof FilterComponent
     * @param filter
     */
    private getSubGroupFilterOptions(filter: FinancialPotentialFilter): DropdownData<string> {
        const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.RADIO : DataType.CHECKBOX;
        const groupSelect = (dataType === DataType.RADIO ? '' : this.translate.instant('quick.predict.deliverables.financial-potential.filter.subgroups.allSubgroups'));
        const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
            return {
                value: item.name,
                label: item.name,
                selected: item.isSelected
            };
        });
        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.financial-potential.filter.subgroups.label'),
            dataType: dataType,
            groupSelect: groupSelect,
            contentTitle: this.translate.instant('quick.predict.deliverables.financial-potential.filter.subgroups.itemsTitle'),
            data: dropdownData
        };

        return dropdownItems;
    }

    /**
     * Returns datasets filter options.
     *
     * @private
     * @returns {DropdownData}
     * @memberof FilterComponent
     * @param filter
     */
    private getDataSetsFilterOptions(filter: FinancialPotentialFilter): DropdownData<string> {
        const dataType = DataType.RADIO;
        const groupSelect = '';
        const dropdownData: DropdownItem<string>[] = filter.datasets.map(item => {
            return {
                value: item.name,
                label: item.name,
                selected: item.isSelected
            };
        });
        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.financial-potential.filter.datasets.label.datasets'),
            dataType: dataType,
            groupSelect: groupSelect,
            contentTitle: this.translate.instant('quick.predict.deliverables.financial-potential.filter.datasets.label.datasets.itemsTitle'),
            data: dropdownData
        };

        return dropdownItems;
    }

    /**
     * Event listener for concept selection change event.
     *
     * @memberof FilterComponent
     * @param allConcepts
     */
    selectConcept(allConcepts: Array<DropdownItem<string>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            concepts: this.filter.concepts.map((concept) => {
                return {
                    ...concept,
                    isSelected: allConcepts.find(selectedConcept => selectedConcept.value === concept.name).selected
                };
            })
        });
    }

    /**
     * Event listener for subgroup selection change event.
     *
     * @param {Array<DropdownItem>} subgroups
     * @memberof FilterComponent
     */
    selectSubgroup(subgroups: Array<DropdownItem<string>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            subgroups: this.filter.subgroups.map((subgroup) => {
                return {
                    ...subgroup,
                    isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
                };
            })
        });
    }

    /**
     * Event listener for datasets selection change event.
     *
     * @memberof FilterComponent
     * @param datasets
     */
    selectDataset(datasets: Array<DropdownItem<string>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            datasets: this.filter.datasets.map((dataset) => {
                return {
                    ...dataset,
                    isSelected: datasets.find(selectedDataset => selectedDataset.value === dataset.name).selected
                };
            })
        });
    }

    /**
     * Emits event of the selected filter menu item.
     */
    selectedEvent(event): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        console.log(event);
    }

    /**
     * Event listener  Sets the new filter entries when changes are made to compare drop down.
     *
     * @param compare The list of compare options.
     */
    selectCompare(compare: any) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            compare: this.filter.compare.map((item: FilterItem) => {
                return {
                    ...item,
                    isSelected: compare.find(selected => selected.value === item.id).selected
                };
            }),
            concepts: this.filter.concepts.map((concept, index) => {
                return {
                    ...concept,
                    isSelected: compare.find(selected => selected.selected === true && selected.value === 'subgroup') ? index === 0 : true
                };
            }),
            subgroups: this.filter.subgroups.map((subgroup, index) => {
                return {
                    ...subgroup,
                    isSelected: compare.find(selected => selected.selected === true && selected.value === 'concept') ? index === 0 : true
                };
            }),
            show: {
                ...this.defaultFilter.show,
                topTwoBox: compare.find(selected => selected.selected === true && selected.value === 'subgroup'),
                allFiveBoxes: !compare.find(selected => selected.selected === true && selected.value === 'subgroup')
            }
        });
    }

}
