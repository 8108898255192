import {Injectable} from '@angular/core';
import {
  StrengthWatchoutsConcept,
  StrengthWatchoutsDeliverableView
} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {Concept} from '@platform/models/concept.model';
import {AppConfigService} from '@app/core/config/app-config.service';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';

@Injectable({
  providedIn: 'root'
})
export class ClickDataService {

  constructor(private appConfigService: AppConfigService) {
  }

  /**
   * Calculates the zone percentages for likes and dislikes.
   * @param {StrengthWatchoutsConcept} concept
   * @memberOf ClickDataService
   */
  private static calculateZoneData(concept: StrengthWatchoutsConcept): StrengthWatchoutsConcept {
    let likes = 0;
    let dislikes = 0;
    let neutral = 0;
    let maxPercent = 0;
    let totalClicks = 0;
    const keys = [];
    const uniqueLabelZones = [];
    for (const zone of concept.zones) {
      const isLabelExists = keys.includes(zone.label);
      if (!isLabelExists) {
        keys.push(zone.label);
        const maxLikeDislikePercent: number = zone.percentageDislikers > zone.percentageLikers ? zone.percentageDislikers : zone.percentageLikers;
        maxPercent = maxPercent > maxLikeDislikePercent ? maxPercent : maxLikeDislikePercent;
        likes = likes + zone.likes;
        dislikes = dislikes + zone.dislikes;
        neutral = neutral + zone.neutral;
        totalClicks = totalClicks + zone.totalClicks;
        uniqueLabelZones.push(zone);
      }
    }
    if (keys.includes("Unassigned") && uniqueLabelZones[uniqueLabelZones.length - 1].label !== "Unassigned") {
        uniqueLabelZones.push(uniqueLabelZones.splice(keys.indexOf("Unassigned"), 1)[0]);
    }
    concept.totalLikes = likes > 0 ? likes : 0;
    concept.totalDislikes = dislikes > 0 ? dislikes : 0;
    concept.totalNeutrals = neutral > 0 ? neutral : 0;
    concept.uniqueLabelZones = uniqueLabelZones;
    concept.totalClicks = totalClicks;
    concept.percentageDislikes = dislikes > 0 ? Math.round(dislikes / totalClicks * 1000)/1000 : 0;
    concept.percentageLikes = likes > 0 ? Math.round(likes / totalClicks * 1000)/1000 : 0;
    concept.percentageNeutrals = neutral > 0? Math.round(neutral / totalClicks * 1000)/1000 : 0;
    concept.maxPercentLikesDislikes = maxPercent;
    return concept;
  }

  /**
   * Calculates data for displaying on UI.
   * @param {StrengthWatchoutsDeliverableView} clickData
   * @param {Array<Concept>} concepts
   * @memberOf ClickDataService
   */
  public getClickData(clickData: StrengthWatchoutsDeliverableView, concepts: Array<Concept>): StrengthWatchoutsDeliverableView {
    const clickDataConcepts = [];
    for (let dataConcept of clickData.concepts as Array<StrengthWatchoutsConcept>) {
      const concept: Concept = concepts.find((item: Concept) => item.exerciseConceptId === dataConcept.exerciseConceptId);
      const dataConceptOptionalProperties = {
        label: concept.name,
        imageHeight: concept.imageHeight,
        imageWidth: concept.imageWidth,
        imagePath: `${this.appConfigService.config.reporting.url}/reports/${concept.reportId}/concepts/${concept.id}?imageFiles=true&locale=${dataConcept.locale}`,
        position: concept.position
      };
      dataConcept = Object.assign({}, dataConcept, dataConceptOptionalProperties);
      dataConcept = ClickDataService.calculateZoneData(dataConcept);
      clickDataConcepts.push(dataConcept);
    }
    clickData.concepts = StrengthWatchoutsService.sortConcepts(clickDataConcepts);
    return clickData;
  }
}
