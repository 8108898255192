import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliverablesEmptyStateComponent } from './deliverables-empty-state.component';



@NgModule({
  declarations: [
    DeliverablesEmptyStateComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DeliverablesEmptyStateComponent
  ],
})
export class DeliverablesEmptyStateModule { }
