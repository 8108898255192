/**
 * Concept types for Gap Analysis.
 *
 * @export
 * @enum {string}
 */
export enum GapAnalysisConceptType {
  CURRENT = 'current',
  RESTAGE = 'relaunch',
  COMPETITOR = 'competitor'
}
