import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ns-target-group-label',
  templateUrl: './target-group-label.component.html',
  styleUrls: ['./target-group-label.component.scss']
})
export class TargetGroupLabelComponent implements OnInit {

  @Input() element: any;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * return class as per value
   * @param value
   */
  public getTargetGroupClass(value: string): string{
    let className = "";
    switch (value) {
      case "High Risk":
        className = "probability-red";
        break;
      case "Risky":
        className = "probability-yellow";
        break;
      case "Ready":
        className = "probability-green";
        break;
      case "Outstanding":
        className = "probability-blue";
        break;
      case 'Top':
        className =  "sif-circle-fill";
        break;
      case 'Middle':
        className =  "sif-circle-half";
        break;
      case 'Bottom':
        className =  "sif-circle-outline";
        break;
      default:
        className =  "";
        break;
    }
    return className;
  }

}
