import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ConceptService} from '@platform/services/concept.service';
import {DeliverableViewType} from '../models/deliverable-view-type.enum';
import {Concept} from '@platform/models/concept.model';
import {WordCloudFilter} from '../models/filter.model';
import {QuestionByConcept, WordCloudDeliverableView} from '../models/word-cloud.model';
import {WordCloudService} from "@app/deliverables/word-cloud/services/word-cloud.service";

@Component({
    selector: 'ns-word-cloud-by-concepts',
    templateUrl: './compare-by-concepts.component.html',
    styleUrls: ['./compare-by-concepts.component.scss']
})
export class CompareByConceptsComponent implements OnInit, OnDestroy {

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member CompareByConceptsComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * Word Cloud filter object.
     *
     * @type {WordCloudFilter}
     * @member CompareByConceptsComponent
     */
    public filter: WordCloudFilter;

    /**
     * The deliverable view object.
     *
     * @type {WordCloudDeliverableView}
     * @member CompareByConceptsComponent
     */
    public wordCloud: WordCloudDeliverableView;

    /***
     * Concept Subgroups Verbatims object
     *
     * @member CompareByConceptsComponent
     */
    public questionConcepts: Array<QuestionByConcept>;

    public showWordCloud: Boolean = false;

    /**
     * Creates an instance of CompareByConceptsComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {WordCloudService} wordCloudService
     * @param {ConceptService} conceptService
     * @param {ChangeDetectorRef} cdr
     * @memberof CompareByConceptsComponent
     */
    constructor(
        private wordCloudService: WordCloudService,
        private conceptService: ConceptService,
        private cdr: ChangeDetectorRef
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize component and load data
     *
     * @member CompareByConceptsComponent
     */
    ngOnInit(): void {
        const wordCloud$ = this.wordCloudService.getWordCloud();
        const concepts$ = this.conceptService.getReportConcepts();
        const subscription = combineLatest([concepts$, wordCloud$])
            .subscribe(([concepts, wordCloud]) => {
                if (wordCloud.viewName === DeliverableViewType.WORD_CLOUD_CONCEPTS) {
                    if (this.wordCloud && this.wordCloud.questionsByConcept.length !== wordCloud.questionsByConcept.length) {
                        this.showWordCloud = false;
                    }
                    this.wordCloud = wordCloud;
                    this.questionConcepts = this.addConceptName(concepts, wordCloud.questionsByConcept);
                    this.cdr.detectChanges();
                    this.showWordCloud = true;
                }
            });
        this.subscriptions.push(subscription);
    }

    /**
     * Adds concept name and position to the questions by concept array.
     *
     * @member CompareByConceptsComponent
     */
    addConceptName(concepts: Array<Concept>, questionsByConcept: Array<QuestionByConcept>) {
        const data = questionsByConcept.reduce((acc, question: QuestionByConcept) => {
            acc.push(Object.assign({}, question, {
                conceptName: concepts.find(it => it.exerciseConceptId === question.conceptId).name,
                position: concepts.find(it => it.exerciseConceptId === question.conceptId).position
            }))
            return acc;
        }, []);
        data.sort(function (a, b) {
            return a.position - b.position;
        });
        return data;
    }

    /**
     * Returns unique id for the loop to be refreshed.
     *
     * @param {number} index the performance table column for loop index
     * @param {any} item the colHeader object
     * @member CompareByConceptsComponent
     */
    public trackItem(index: number, item: any): string {
        return `${index}-${item.conceptId ? item.conceptId : 0}`;
    }

    /**
     * Cleanup the component on removing from the UI.
     *
     * @member CompareByConceptsComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
