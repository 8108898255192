<div id="{{'concept-container-'+index}}" class="deliverable-insight-sw-concept-image">
  <div id="svgContainer" class="deliverable-insight-sw-svg" #svgContainer>
    <img class="insight-image" crossorigin="use-credentials" [src]="conceptImageUrl" [attr.height]="concept.imageHeight"
      [attr.width]="concept.imageWidth" />

    <svg (click)="deselectZone()" class="insight-image-svg" [attr.height]="concept.imageHeight"
      [attr.width]="concept.imageWidth" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g *ngFor="let zone of namedZones" [attr.transform]='"translate("+zone.x+" "+zone.y+")"'
        [attr.for-cluster]="zone.label" (mouseenter)="onHoverZone(zone)" (mouseleave)="onHoverZone()"
        (click)="activateZone($event, zone);" [ngClass]="
        {'hovered-zone': zone.label === hoveredZone || (selectedZones && zone.label === selectedZones[0].label),
        'activated-zone': zone.label === activatedZone}
      ">
        <rect class="blue-zone-rect" (mouseenter)="onHoverImage(true)"
          (mouseleave)="onHoverImage(false)" [attr.width]="zone.width" [attr.height]="zone.height" 
          [ngClass]="{'visibility-visible': isOnHoverEnabled(zone), 'visibility-hidden': !showAlways }" />
        <rect rx="5" ry="5" class="rect-tab" x="-1" y="-14" [style]="calcWidthForName(zone)"></rect>
        <text class="zone-tab-label" x="2" y="-3">{{zone.label}}</text>
        <text class="zone-tab-name" x="14" y="-3">{{zone.name}}</text>
      </g>

      <circle *ngFor="let click of likes" name="like-click" [attr.cx]="click.x" [attr.cy]="click.y" r="2" opacity="1"
        fill=#678500 />
      <circle *ngFor="let click of neutral" name="neutral-click" [attr.cx]="click.x" [attr.cy]="click.y" r="2"
        opacity="1" fill=#96949c />
      <circle *ngFor="let click of dislikes" name="dislike-click" [attr.cx]="click.x" [attr.cy]="click.y" r="2"
        opacity="1" fill=#952606 />
      <circle *ngIf="selectedClick && selectedClick.isLike" #highlightClick class="highlight-click"
        name="highlight-circle" [style.transformOrigin]="selectedClick.x +'px '+selectedClick.y +'px'"
        [attr.cx]="selectedClick.x" [attr.cy]="selectedClick.y" stroke="#678500" r="5" opacity="1" fill="none"></circle>
      <circle *ngIf="selectedClick && !selectedClick.isLike" #highlightClick class="highlight-click"
        name="highlight-circle" [style.transformOrigin]="selectedClick.x +'px '+selectedClick.y +'px'"
        [attr.cx]="selectedClick.x" [attr.cy]="selectedClick.y" stroke="#952606" r="5" opacity="1" fill="none"></circle>
    </svg>
  </div>
</div>