import { TemplateRef } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AppConfigService } from '@app/core/config/app-config.service';
import { Concept } from '@platform/models/concept.model';

@Component({
  selector: 'activation-profile-item-image',
  templateUrl: './activation-profile-item-image.component.html',
  styleUrls: ['./activation-profile-item-image.component.scss']
})
export class ActivationProfileItemImageComponent implements OnInit {
  @Input() locale: Array<string>;
  @Input()
  set concept(value: Concept) {
    this.conceptValue = value;
    const [locale] = this.locale;
    this.conceptImageUrl = `${this.cs.config.reporting.url}/reports/${value.reportId}/concepts/${value.id}?imageFiles=true&locale=${locale}`;
  }


  get concept(): Concept {
    return this.conceptValue;
  }

  private conceptValue;
  public selectedImagePath: string;
  conceptImageUrl: string;
  constructor(
    private cs: AppConfigService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  enlargeConceptImage(imagePath: string, conceptEnlargeModal: TemplateRef<any>) {
    this.selectedImagePath = imagePath;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(conceptEnlargeModal, dialogConfig);

  }
}
