import {AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {HeaderService} from '@platform/services/header.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {combineLatest, Observable} from 'rxjs';
import {SpinnerService} from "@platform/services/spinner.service";
import {map} from "rxjs/operators";
import {NavigationService} from "@platform/services/navigation.service";


/**
 * Root component of the reporting app.
 *
 * @export
 * @class AppComponent
 */
@Component({
    selector: 'ns-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {

    /**
     * Indicator to show header only when the route loads successfully
     *
     * @type {Observable<boolean>}
     * @memberof AppComponent
     */
    isRouteLoading$: Observable<boolean>;

    displayProgressSpinner: boolean;

  /**
   * Constructor
   *
   * @param headerService
   * @param mixpanelService
   */
  constructor(private headerService: HeaderService,
              private navigationService: NavigationService,
              private mixpanelService: MixpanelService,
              private spinnerService: SpinnerService,
              private cdRef: ChangeDetectorRef) {
  }

    @HostListener('window:beforeunload')
    leaveReportingApp() {
        let currentFeatureLabel = this.mixpanelService.getCurrentFeatureLabel();
        this.mixpanelService.track(currentFeatureLabel, MixpanelEvent.leaveReporting);
    }

  /**
   * Initialize the App component view.
   * - Listens to the route load action and displays header accordingly
   *
   * @memberof AppComponent
   */
  ngOnInit(): void {
      this.displayProgressSpinner = false;
      this.isRouteLoading$ = combineLatest([this.headerService.showBrandBar$, this.headerService.showApplicationBar$]).pipe(map(results => results[0] || results[1]));
      this.spinnerService.getSpinnerObs().subscribe(it =>  this.displayProgressSpinner = it);
  }

    /**
     * Fixes ExpressionChangedAfterItHasBeenCheckedError issue with headerService.headerObs$ observer
     * @see https://stackoverflow.com/questions/39787038/how-to-manage-angular2-expression-has-changed-after-it-was-checked-exception-w
     * */
    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
}
