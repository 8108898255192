import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {Concept} from '@platform/models/concept.model';
import {AdditionalInfoMetaInfo} from '@app/deliverables/additional-info/models/view-meta-info.model';
import {Subscription} from 'rxjs';
import {FilterService} from '@platform/services/filter.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {UserService} from '@platform/services/user.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {debounceTime, skipWhile, take} from 'rxjs/operators';
import {AdditionalInfoFilter} from '@app/deliverables/additional-info/models/filter.model';
import {defaultAdditionalInfoFilter} from '@app/deliverables/additional-info/models/default-additional-info-filter';

@Component({
  selector: 'ns-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent implements OnInit, ScoreCardView, OnDestroy {

    /**
     * Deliverable type.
     *
     * @type {string}
     */
    deliverableType: string;

    /**
     * ScoreCard Concept object for image view.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    /**
     * Meta info for Additional Info
     *
     * @type {AdditionalInfoMetaInfo}
     * @memberOf AdditionalInfoComponent
     */
    public viewAdditionalInfoMetaInfo: AdditionalInfoMetaInfo;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberOf AdditionalInfoComponent
     */
    public subscriptions: Array<Subscription> = [];

    public isInternalUser: Boolean;

    constructor(
        private filterService: FilterService,
        private viewMetaInfoService: ViewMetaInfoService,
        private userService: UserService
    ) { }

    ngOnInit(): void {
        const user$ = this.userService.getUser();
        this.deliverableType = DeliverableType.ADDITIONAL_INFO.type;
        const viewMetaInfo$ = this.viewMetaInfoService.get<AdditionalInfoMetaInfo>(this.deliverableType);
        const userSubscription = user$.pipe(take(1))
            .subscribe((user) => this.isInternalUser = user.isInternalUser);
        const subscription = viewMetaInfo$
            .pipe(
                debounceTime(1),
                skipWhile((viewMetaInfo) => viewMetaInfo == null)
            ).subscribe((viewMetaInfo) => {
                    this.viewAdditionalInfoMetaInfo = viewMetaInfo;
                }
            );
        this.subscriptions.push(subscription);
        this.subscriptions.push(userSubscription);
        this.loadDefaultFilter();
    }

    /**
     * Loads default AdditionalInfoComponent filter data.
     *
     * @memberof AdditionalInfoComponent
     */
    public loadDefaultFilter(): void {
        const defaultFilter: AdditionalInfoFilter = Object.assign({}, defaultAdditionalInfoFilter);
        this.filterService.update(defaultFilter);
    }

    /**
     * Cleanup hook.
     *
     * @memberOf SurveyQuestionComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
