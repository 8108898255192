<div class="collapse-image"><img crossorigin="use-credentials" class="insight-image" [src]="conceptImageUrl" (click)="enlargeConceptImage(conceptImageUrl, conceptEnlargeModal)" />
</div>

<ng-template #conceptEnlargeModal>
    <mat-dialog-actions align="end">
        <div class="buttons">
          <button class="button" mat-button matDialogClose> <mat-icon class="close-icon">close</mat-icon></button>
        </div>
    </mat-dialog-actions>
    <mat-dialog-title><span class="title">Concept Detail</span></mat-dialog-title>
    <mat-dialog-content class="enlarge-modal">
      <div class="concept-modal-container">
        <img *ngIf="selectedImagePath" class="img-responsive center-block" [src]="selectedImagePath" alt="Concept Image">
      </div>
    </mat-dialog-content>
</ng-template>
