import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNodeData } from '../models/tree-node-data.interface';
import { ActivationProfileService } from '../services/activation-profile.service';
import { NodeToggleEvent } from './header-section/header-section.component';

@Component({
  selector: 'ap-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() nodeToggled = new EventEmitter<NodeToggleEvent>();
  @Input() headerList: Array<string>;
  @Input() basesLabel: string;

  constructor(
    private translate: TranslateService, private activationProfileService: ActivationProfileService
  ) {}

  ngOnInit(): void {
    const headerList = this.headerList;
    this.activationProfileService.basesLabel = this.basesLabel;
    this.activationProfileService.setActivationProfile(headerList);
    this.activationProfileService.setbroadPotential(headerList);
    this.activationProfileService.setTargetPotential(headerList);
    this.activationProfileService.setBrandIncrementality(headerList);
    this.activationProfileService.setRestaurantIncrementality(headerList);
    this.activationProfileService.setCategoryDistinction(headerList);
    this.activationProfileService.setIndustryDistinction(headerList);
    this.activationProfileService.setEndurance(headerList);
    this.activationProfileService.setYearOneVolume(headerList);
    this.activationProfileService.setLaunchVolume(headerList);
    this.activationProfileService.setexecutionalPotential(headerList);
  }

  buildMeasures(name: any, type: any, key: any, level: any, children: any, classValue: any, tooltip: any): TreeNodeData[] {
    return [{
      name: name,
      type: type,
      key: key,
      level: level,
      children: children,
      class: classValue,
      tooltip: tooltip
    }];
  }


  toggleNode(event: NodeToggleEvent) {
    this.nodeToggled.emit(event);
  }

}
