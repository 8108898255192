
<div class = "btn-group">
    <button class="sif share-btn" *ngIf="userInfo.isInternalUser && archiveData" [disableRipple]="true" [matMenuTriggerFor]="dropdownMenu" mat-button><span class="share-btn sif sif-ellipsis-vertical"></span></button>
  <button *ngIf="isAdmin" class="sif share-btn" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'platform.share.download' | translate }}"  (click)="downloadReportZipFile()" mat-button><span class="share-btn sif sif-download"></span></button>
  <button rel="tooltip" class="sif share-btn" matTooltipPosition="above" matTooltip=" {{'platform.share.result' | translate }}" (click)=showShareResultsDialog() mat-button><span class="share-btn sif sif-share share-results"></span></button>
  <mat-menu #dropdownMenu="matMenu">
    <section class="filter-menu">
      <ul class="dropdown-menu-list">
        <li class="list-item" (click)="enlargeArchiveSettingsDialog(archiveSettingsModal)">{{ 'platform.report.archive.settings.title' | translate }}</li>
      </ul>
    </section>
  </mat-menu>
</div>


<ng-template id="shareModal" #shareResultsDialog>
  <div class="deliverables-header-save-modal share-results-content">
    <h2 mat-dialog-title>{{ 'platform.report.share.title' | translate }}</h2>
      <!-- Display a message when no collaborators are available -->
    <div class="alert alert-info" role="alert" *ngIf="!viewCollaborators">Please add collaborators to share the results.</div>

    <mat-dialog-content class="save-modal" *ngIf="viewCollaborators">
      <div *ngIf="collaborators && collaborators.length > 0" class="share-model-content m-0">
        <div class="collaborators-section">
          <table id="collaborators-table" class="collaborators-table ">
            <thead>
              <tr id="share-description-label">
                <td></td>
                <td id="project-description-label" *ngIf="showDeliverableColumn"><strong>{{productName | titlecase}}</strong></td>
                <td id="project-forecast" *ngIf="showForecastColumn"><strong>{{'platform.report.share.forecast' | translate}}</strong></td>
              </tr>
              <tr id="share-with-all-label">
                <td></td>
                <td id="share-project-results-with-all" *ngIf="showDeliverableColumn">
                  <mat-checkbox [checked]="deliverableSharedUsers.size == clientUsers.length" (change)="selectShareAllCoreDeliverables($event.checked)"></mat-checkbox>
                </td>
                <td id="share-forecast-results-with-all" *ngIf="showForecastColumn">
                    <mat-checkbox [checked]="forecastSharedUsers.size == clientUsers.length" (change)="selectShareAllForecastDeliverables($event.checked)"></mat-checkbox>
                </td>
              </tr>
          </thead>
            <tbody>
              <ng-container *ngFor="let collaborator of collaborators">
                  <tr *ngIf="collaborator.isClient && collaborator.userManagementId != userInfo.userId">
                      <td class="left-align">{{collaborator.displayName ? collaborator.displayName : collaborator.userName}}
                          <span class="collaborator-role note">({{collaborator.role}})</span></td>
                      <td *ngIf="showDeliverableColumn">
                          <mat-checkbox [checked]="deliverableSharedUsers.has(collaborator)"
                                        [value]="collaborator.userName"
                                        (change)="toggleSharePermission($event.checked, collaborator)"
                                        #clientList></mat-checkbox>
                      </td>
                      <td *ngIf="showForecastColumn">
                          <mat-checkbox
                              [checked]="forecastSharedUsers.has(collaborator)"
                              [value]="collaborator.userName"
                              (change)="toggleForecastPermission($event.checked, collaborator)"
                              #forecastClientList></mat-checkbox>

                      </td>
                  </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="cc-content mt-2" *ngIf=userInfo.isInternalUser>
          <p class="notify-collaborators">
            <span class="link-text" (click)="showNotifiersList = !showNotifiersList">{{selectionLabel}}
              <span *ngIf="!showNotifiersList" class="sif sif-10 sif-chevron-s"></span>
              <span *ngIf="showNotifiersList" class="sif sif-10 sif-chevron-n"></span>
            </span>
            {{ 'platform.report.share.message.notification.suffix' | translate }}
          </p>
          <ul *ngIf="showNotifiersList" class="list-unstyled collaborators-list">
            <li>
              <mat-checkbox class="internaluser" rounded (click)="selectNotifyAll(notifyAllElement, internalUsersList)" #notifyAllElement>{{ 'platform.report.share.label.all.nielsen.collaborators' | translate }}</mat-checkbox>
            </li>
            <ul class="list-unstyled ml-4">
              <ng-container *ngFor="let collaborator of collaborators">
                <li *ngIf="!collaborator.isClient">
                  <mat-checkbox class="internaluser" rounded [checked]="collaborator.userManagementId == userInfo.userId" [name]="collaborator.firstName ? collaborator.firstName : collaborator.displayName" [value]="collaborator.userName" (change)="toggleNotifySelection()" #internalUsersList>{{collaborator.displayName ? collaborator.displayName :collaborator.userName}}</mat-checkbox>
                  <span *ngIf="collaborator.isOwner" class="collaborator-role note">{{ 'platform.report.share.label.co.owner' | translate }}</span>
                </li>
              </ng-container>
            </ul>
          </ul>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions  class="save-modal-actions" align="end">
      <button class="cancel-button" mat-button mat-dialog-close (click)="closeModal()">{{ 'platform.report.share.label.button.cancel' | translate }}</button>
      <button class="save-button" mat-button [disabled]="!viewCollaborators" (click)="shareProject()">{{ 'platform.report.share.label.button.share' | translate }}</button>
    </mat-dialog-actions>
</div>
</ng-template>

<!-- Archive Settings Feature -->
<ng-template id="archiveModal" #archiveSettingsModal>
  <div *ngIf="archiveData" >
    <ns-archive-settings [report]="report" [archive] = "archiveData"></ns-archive-settings>
  </div>
</ng-template>



