/**
 * Deliverable view types for correlations.
 *
 * @export
 * @enum {string}
 */
export enum DeliverableViewType {
  /**
   * DATATABLE deliverable view type.
   */
  DATA_TABLE = 'dataTable',
  /**
   * QUADMAP deliverable view type.
   */
  QUAD_MAP = 'quadMap'
}
