import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';

/**
 * DropdownMenuComponent for menu dropdowns.
 *
 * @example
 *   <ns-dropdown-menu></ns-dropdown-menu>
 *
 * @export
 * @class DropdownMenuComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ns-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})

export class DropdownMenuComponent implements OnInit {
  /**
   * Label for the menu.
   *
   * @type {string}
   * @memberof DropdownMenuComponent
   */
  @Input() label: string;

  @Input() subLabel: string;
  /**
   * True if the menu is disabled.
   *
   * @type {boolean}
   * @memberof DropdownMenuComponent
   */
  @Input() isDisable = false;
  /**
   * Content title.
   *
   * @type {string}
   * @memberof DropdownMenuComponent
   */
  @Input() contentTitle: string;
  /**
   * Translation message to be display when no label is available.
   *
   * @type {string}
   * @memberof DropdownMenuComponent
   */
  noLabel: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.noLabel = this.translate.instant('shared.dropdown.menu.no.label');
  }

}
