import { addViewMetaInfo, updateViewMetaInfo } from '@platform/store/actions/view-meta-info.actions';
import { selectViewMetaInfoByType } from '@platform/store/selectors/view-meta-info.selectors';
import { ViewMetaInfo } from '@platform/models/view-meta-info.model';
import { State } from '@platform/store/state/app.state';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

/**
 * This service has operations for managing view meta info.
 *
 *
 * @export
 * @class ViewMetaInfoService
 */
@Injectable({
  providedIn: 'root'
})
export class ViewMetaInfoService {

  /**
   * Creates an instance of ViewMetaInfoService.
   *
   * @constructor
   * @param {Store<State>} store
   * @memberof ViewMetaInfoService
   */
  constructor(private store: Store<State>) {}

  /**
   * Adds viewMetaInfo to the store.
   *
   * @param viewMetaInfo
   * @param deliverableType
   */
  public add(viewMetaInfo: ViewMetaInfo, deliverableType: string): void {
    if (viewMetaInfo) {
      viewMetaInfo = JSON.parse(JSON.stringify(viewMetaInfo));
      viewMetaInfo.deliverableType = deliverableType;
    } else {
      viewMetaInfo = { deliverableType };
    }
    this.store.dispatch(addViewMetaInfo({ viewMetaInfo }));
  }

  /**
   * Returns view meta info of the deliverable type.
   *
   * @param {string} deliverableType
   * @returns {Observable<T extends ViewMetaInfo>} Observable of view meta info.
   */
  public get<T extends ViewMetaInfo>(deliverableType: string): Observable<T> {
    const metaInfo$ = this.store.pipe(
      select(selectViewMetaInfoByType<T>(), { type: deliverableType}),
      switchMap(metaInfo => {
        let result$: Observable<T>;
          result$ = of(metaInfo as T);
        return result$;
      })
    );
    return metaInfo$;
  }

  /**
   * Updates and returns view meta info of the deliverable type
   *
   * @param {T} updated viewMetaInfo.
   * @param {string} deliverableType
   * @returns {Observable<T extends ViewMetaInfo>} Observable of view meta info.
   */
  public update<T extends ViewMetaInfo>(viewMetaInfo: T, deliverableType: string): Observable<T> {
    if (viewMetaInfo) {
      viewMetaInfo = JSON.parse(JSON.stringify(viewMetaInfo));
      viewMetaInfo.deliverableType = deliverableType;
    } else {
      viewMetaInfo = { deliverableType } as T;
    }
    this.store.dispatch(updateViewMetaInfo({viewMetaInfo: viewMetaInfo}));
    const metaInfo$ = this.get<T>(deliverableType);
    return metaInfo$;
  }

}
