import {
    ActivationProfileLegacyComponent
} from '@products/quick-predict/activation-profile-legacy/activation-profile-legacy.component';
import {ActivationProfileComponent} from '@app/deliverables/activation-profile/activation-profile.component';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {ConfigurableComponents} from '@app/deliverables/configurable-components.enum';

const deliverableComponents: { [type: string]: any } = Object.assign({}, ConfigurableComponents);
deliverableComponents[DeliverableType.ACTIVATION_PROFILE_LEGACY.type] = ActivationProfileLegacyComponent;

export {deliverableComponents};
