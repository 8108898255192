import {filterNames as filterNameSource} from './filter-names';
import {FilterService} from '@platform/services/filter.service';
import {FactorsService} from '@app/deliverables/factors/services/factors.service';
import {FactorsFilter, FilterItem, ShowFilter} from '@app/deliverables/factors/models/filter.model';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription, take} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DeliverableViewType} from '@app/deliverables/factors/models/deliverable-view-type.enum';
import {ReportService} from '@platform/services/report.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';

/**
 * `<ns-factors-filter-show>` component builds show filter for the
 * `ShowComponent`.
 *
 * @examplethis.filterNames = filterNameSource;
 * <ns-factors-filter-show>
 * </ns-factors-filter-show>
 *
 * @export
 * @class ShowComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-factors-filter-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

    @Input() deliverableLabel: string;
    /**
     * Factors For Success filter object.
     *
     * @property
     * @type {FactorsFilter}
     * @memberof ShowComponent
     */
    public filter: FactorsFilter;

    /**
     * Filter name object.
     *
     * @property
     * @type {*}
     * @memberof ShowComponent
     */
    public filterNames: any;

    /**
     * Filter label.
     *
     * @property
     * @type {string}
     * @memberof ShowComponent
     */
    public filterLabel: string;

    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof ShowComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * FFS has 2 views: (1) Concepts view (2) Subgroup Means view
     * @type {boolean}
     * @memberof ShowComponent
     */
    public isConceptView = true;

    /**
     * Boolean to check all factors selected
     * @type {boolean}
     * @memberof ShowComponent
     */
    public allFactorsChecked: boolean;

    /**
     * Boolean to check all thresholds selected
     * @type {boolean}
     * @memberof ShowComponent
     */
    public allThresholdsChecked: boolean;

    /**
     * Is the Report having any Alcohol DataSet information
     *
     * @type {boolean}
     * @memberof ShowComponent
     */
    public isAlcoholStudy: boolean;

    /**
     * Is the Report having any Cannabis DataSet information
     *
     * @type {boolean}
     * @memberof ShowComponent
     */
    public isCannabisStudy: boolean;


    /**
     * Creates an instance of ShowComponent.
     *
     * @constructor
     * @param {FactorsService} factorsService
     * @param {FilterService} filterService
     * @param {TranslateService} translate
     * @param {ReportService} reportService
     * @param {MixpanelService} mixpanelService
     * @memberof ShowComponent
     */
    constructor(
        private factorsService: FactorsService,
        private filterService: FilterService,
        private translate: TranslateService,
        private reportService: ReportService,
        private mixpanelService: MixpanelService
    ) {
        this.filterNames = filterNameSource;
        this.subscriptions = [];
    }

    /**
     * Initializes the show filter component.
     *
     * @memberof ShowComponent
     */
    ngOnInit() {
        const filter$ = this.factorsService.getFactorsFilter();
        const factors$ = this.factorsService.getFactors();
        const report$ = this.reportService.get();
        const filterSubscription = filter$.subscribe(filter => {
            this.filter = filter;
            this.filter.deliverableViewType === DeliverableViewType.CONCEPT ? this.isConceptView = true : this.isConceptView = false;
            this.setFilterLabel();
        });
        const subscription = combineLatest([filter$, factors$, report$]).pipe(take(1)).subscribe(([filters, factors, report]) => {
            const filter: FactorsFilter = JSON.parse(JSON.stringify(filters));
            filters.deliverableViewType === DeliverableViewType.CONCEPT ? this.isConceptView = true : this.isConceptView = false;
            report.projectType && report.projectType.toLowerCase() === 'alcohol' ? this.isAlcoholStudy = true : this.isAlcoholStudy = false;
            report.projectType && report.projectType.toLowerCase() === 'cannabis' ? this.isCannabisStudy = true : this.isCannabisStudy = false;
            filter.show = this.factorsService.modifyShowFilter(filter, this.filter.deliverableViewType, factors, this.isAlcoholStudy, this.isCannabisStudy);
            filter.subgroups = this.factorsService.filterSubgroups(filter.subgroups, filters.deliverableViewType);
            this.filterService.update(filter);
            this.factorsService.deliverableHeaderSubject.next(true);
        });
        this.subscriptions.push(filterSubscription, subscription);
    }

    /**
     * Sets filter label for show filter.
     *
     * @private
     * @memberof ShowComponent
     */
    private setFilterLabel(): void {
        const showFilter = this.filter.show;
        const selectedFactors = showFilter.factorsOptions.filter(factorData => factorData.isSelected);
        const allFactorsSelected = showFilter.factorsOptions.every(factorData => factorData.isSelected);
        const factor = selectedFactors.length === 1 ? selectedFactors.map(factorData => factorData.name)[0] : selectedFactors.length + ' ' + this.translate.instant('quick.predict.deliverables.factors.filter.label.factors');
        this.allFactorsChecked = allFactorsSelected;
        if (this.isConceptView) {
            this.setLabelConceptsView(showFilter, factor, selectedFactors, allFactorsSelected);
        } else {
            this.setLabelSubgroupsView(showFilter, factor, selectedFactors, allFactorsSelected);
        }
    }

    /**
     * Sets filter label for show filter in subgroup means view.
     *
     * @private
     * @memberof ShowComponent
     */
    private setLabelSubgroupsView(showFilter: ShowFilter, factor, selectedFactors, allFactorsSelected) {
        if (allFactorsSelected && showFilter.statTesting) {
            this.filterLabel = this.translate.instant('quick.predict.deliverables.factors.filter.label.all.factors') + ', ' + this.translate.instant('quick.predict.deliverables.factors.label.stat.testing');
        } else if (allFactorsSelected && !showFilter.statTesting) {
            this.filterLabel = this.translate.instant('quick.predict.deliverables.factors.filter.label.all.factors');
        } else if (showFilter.statTesting && selectedFactors.length === 0) {
            this.filterLabel = this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Factor'}) + ', ' + this.translate.instant('quick.predict.deliverables.factors.label.stat.testing');
        } else if (!showFilter.statTesting && selectedFactors.length === 0) {
            this.filterLabel = this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Factor'});
        } else if (selectedFactors.length !== 0 && showFilter.statTesting) {
            this.filterLabel = factor + ', ' + this.translate.instant('quick.predict.deliverables.factors.label.stat.testing');
        } else {
            this.filterLabel = factor;
        }
    }


    /**
     * Sets filter label for show filter in concepts view.
     *
     * @private
     * @memberof ShowComponent
     */
    private setLabelConceptsView(showFilter, factor, selectedFactors, allFactorsSelected) {
        const selectedThresholds = showFilter.thresholdOptions.filter(thresholdData => thresholdData.isSelected);
        const allThresholdsSelected = showFilter.thresholdOptions.every(thresholdData => thresholdData.isSelected);
        const threshold = selectedThresholds.length === 1 ? selectedThresholds.map(thresholdData => thresholdData.name)[0] : selectedThresholds.length + ' ' + this.translate.instant('quick.predict.deliverables.factors.filter.label.thresholds');
        this.allThresholdsChecked = allThresholdsSelected;
        if (allFactorsSelected && allThresholdsSelected) {
            this.filterLabel = this.translate.instant('quick.predict.deliverables.factors.filter.label.all.factors.thresholds');
        } else if (allFactorsSelected && selectedThresholds.length === 0) {
            this.filterLabel = this.translate.instant('quick.predict.deliverables.factors.filter.label.all.factors') + ', ' + this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Threshold'});
        } else if (allThresholdsSelected && selectedFactors.length === 0) {
            this.filterLabel = this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Factor'}) + ', ' + this.translate.instant('quick.predict.deliverables.factors.filter.label.all.thresholds');
        } else if (allFactorsSelected) {
            this.filterLabel = this.translate.instant('quick.predict.deliverables.factors.filter.label.all.factors') + ', ' + threshold;
        } else if (allThresholdsSelected) {
            this.filterLabel = factor + ', ' + this.translate.instant('quick.predict.deliverables.factors.filter.label.all.thresholds');
        } else if (selectedThresholds.length === 0 && selectedFactors.length !== 0) {
            this.filterLabel = factor + ', ' + this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Threshold'});
        } else if (selectedFactors.length === 0 && selectedThresholds.length !== 0) {
            this.filterLabel = this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Factor'}) + ', ' + threshold;
        } else if (selectedThresholds.length === 0 && selectedFactors.length === 0) {
            this.filterLabel = this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Factor'}) + ', ' + this.translate.instant('shared.dropdown.menu.no.selection', {value: 'Threshold'});
        } else {
            this.filterLabel = factor + ', ' + threshold;
        }
    }

    /**
     * Returns selected decimal place value.
     *
     * @returns {string}
     * @memberof ShowComponent
     */
    public getSelectedDecimalPlace(): string {
        const filterNames = this.filterNames;
        const showFilter: ShowFilter = this.filter.show;
        let decimalPlace = '';
        decimalPlace = showFilter.decimals.noDecimal ? filterNames.noDecimals : decimalPlace;
        decimalPlace = showFilter.decimals.oneDecimal ? filterNames.oneDecimalPlace : decimalPlace;
        decimalPlace = showFilter.decimals.twoDecimal ? filterNames.twoDecimalPlace : decimalPlace;
        return decimalPlace;
    }

    /**
     * Event listener for decimal place filter change.
     *
     * @listens event:change
     * @param {*} event
     * @memberof ShowComponent
     */
    public changeDecimalPlace(event): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const filterNames = this.filterNames;
        const decimalPlace = event.value;
        const filter: FactorsFilter = JSON.parse(JSON.stringify(this.filter));
        filter.show.decimals.noDecimal = decimalPlace === filterNames.noDecimals;
        filter.show.decimals.oneDecimal = decimalPlace === filterNames.oneDecimalPlace;
        filter.show.decimals.twoDecimal = decimalPlace === filterNames.twoDecimalPlace;
        this.filterService.update(filter);
    }

    /**
     * Toggles the selection for checkboxes.
     *
     * @param {string} filterName
     * @memberof ShowComponent
     */
    public toggleSelection(filterName: string): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const filterNames = this.filterNames;
        const filter: FactorsFilter = JSON.parse(JSON.stringify(this.filter));
        const show = filter.show;
        switch (filterName) {
            case filterNames.statTesting:
                show.statTesting = !show.statTesting;
                break;
        }
        this.filterService.update(filter);
    }

    /**
     * Toggles the select all checkboxes.
     *
     * @private
     * @memberof ShowComponent
     */
    public changeAllOptions(checked, type) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const filter = JSON.parse(JSON.stringify(this.filter));
        type === 'factor' ? filter.show.factorsOptions.map(factor => factor.isSelected = checked) :
            filter.show.thresholdOptions.map(threshold => threshold.isSelected = checked);
        this.filterService.update(filter);
    }

    /**
     * Toggles the selection for checkboxes.
     *
     * @private
     * @memberof ShowComponent
     */
    public changeOption(item: FilterItem, type) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const filter = JSON.parse(JSON.stringify(this.filter));
        type === 'factor' ? filter.show.factorsOptions.map(factor => factor.isSelected = item.name === factor.name ? !factor.isSelected : factor.isSelected) :
            filter.show.thresholdOptions.map(threshold => threshold.isSelected = item.name === threshold.name ? !threshold.isSelected : threshold.isSelected);
        this.filterService.update(filter);
    }

    /**
     * Cleans up the show filter component on removing from UI.
     *
     * @memberof ShowComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
