import { createAction, props } from '@ngrx/store';
import { Report } from '@platform/models/report.model';

/**
 * Action for adding a report.
 * 
 * @export
 */
export const addReport = createAction(
  '[Report] Add Report',
  props<{ report: Report }>()
);

/**
 * Action for updating a report.
 * 
 * @export
 */
export const updateReport = createAction(
  '[Report] Update Report',
  props<{ report: Report }>()
);
