import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';
import {DeliverableConfigurationState} from '@platform/store/deliverable-configuration/deliverable-configuration.state';

/**
 * DeliverableConfiguration entity adapter.
 *
 * @export
 * @property {EntityAdapter<DeliverableConfiguration>} adapter - The organization adapter.
 */
export const deliverableConfigurationAdapter: EntityAdapter<DeliverableConfiguration> = createEntityAdapter<DeliverableConfiguration>();

export const initialState: DeliverableConfigurationState = deliverableConfigurationAdapter.getInitialState({});

