/**
 * Default colors for all stat types.
 *
 * @export
 * @property
 */
 export const defaultPerformanceColors = {
    aboveCurrent: '#30CD34',
    belowCurrent: '#373737',
    current: '#999999',
  };
  