<div class="concept-image-container">
  <div id="{{'concept-container-'+index}}" class="deliverable-insight-sw-concept-image">
    <div id="svgContainer" class="deliverable-insight-sw-svg">
      <img class="insight-image" crossorigin="use-credentials" [src]="conceptImageUrl" [attr.height]="concept.imageHeight" [attr.width]="concept.imageWidth" />
    </div>
  </div>
  <ns-carousel-image class="carousel-panel" [imageHeight]="concept.imageHeight" [imageWidth]="concept.imageWidth"
  [visibleBullets]="true" [imageList]="imageList" [tooltipDescription]="'Language'"
  (next)="nextImage()" (previous)="previousImage()" *ngIf="isNavigation" [isPreviousEnabled]="isPreviousEnabled" [isNextEnabled]="isNextEnabled"></ns-carousel-image>
</div>
