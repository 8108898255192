<ns-dropdown-menu [label]="filterLabel" rel="tooltip" matTooltipPosition="above" matTooltip=" {{'tooltip.filter' | translate: { viewComparison: 'Options selected', viewComparisonName: filterLabel } }}" >
  <section class="dropdown-filter-menu">
    <ul class="dropdown-menu-list">
      <h3 class="menu-title">{{ 'shared.deliverables.attributes.filter.label.metrics' | translate }}</h3>
      <mat-radio-group [ngModel]="metric" (change)="changeMetricType($event)">
        <div class="menu-list-content">
          <mat-radio-button color="primary" class="list-item" [value]="filterNames.mean"><li class="list-item">{{ 'shared.deliverables.attributes.filter.label.mean' | translate }}</li>
          </mat-radio-button>
          <mat-radio-button color="primary" (click)="$event.stopPropagation()" class="group-list-item" [value]="filterNames.percents"><li class="list-item">{{ 'shared.deliverables.attributes.filter.label.percents' | translate }}</li>
          </mat-radio-button>
          <mat-radio-group [ngModel]="getSelectedPercentsType()" (change)="changePercentType($event)">
            <mat-radio-button color="primary" class="list-item padding-left-2" [value]="filterNames.topBox" [disabled]="!filter.show.percents.isSelected"><li class="list-item">{{ 'shared.deliverables.attributes.filter.label.top.box' | translate }}</li></mat-radio-button>
            <mat-radio-button color="primary" class="list-item padding-left-2" [value]="filterNames.topTwoBox" [disabled]="!filter.show.percents.isSelected"><li class="list-item">{{ 'shared.deliverables.attributes.filter.label.top.two.box' | translate }}</li></mat-radio-button>
            <mat-radio-button color="primary" class="list-item padding-left-2" [value]="filterNames.bottomThreeBox" [disabled]="!filter.show.percents.isSelected"><li class="list-item">{{ 'shared.deliverables.attributes.filter.label.bottom.three.box' | translate }}</li></mat-radio-button>
            <mat-radio-button color="primary" class="list-item padding-left-2" [value]="filterNames.allFiveBoxes"  [disabled]="!filter.show.percents.isSelected"><li class="list-item">{{ 'shared.deliverables.attributes.filter.label.all.five.boxes' | translate }}</li></mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-radio-group>
    </ul>
  </section>
  <section class="dropdown-filter-menu">
    <ul class="dropdown-menu-list">
      <h3 class="menu-title">Decimals</h3>
      <mat-radio-group [ngModel]="getSelectedDecimalPlace()" (change)="changeDecimalPlace($event)">
          <div class="menu-list-content">
              <mat-radio-button color="primary" class="list-item" [value]="filterNames.noDecimals">
                <li class="list-item">{{ 'shared.deliverables.attributes.filter.label.no.decimals' | translate }}</li>
              </mat-radio-button>
              <mat-radio-button color="primary" class="list-item" [value]="filterNames.oneDecimalPlace">
                <li class="list-item">{{ 'shared.deliverables.attributes.filter.label.one.decimal' | translate }}</li>
              </mat-radio-button>
              <mat-radio-button color="primary" class="list-item" [value]="filterNames.twoDecimalPlace">
                <li class="list-item">{{ 'shared.deliverables.attributes.filter.label.two.decimal' | translate }}</li>
              </mat-radio-button>
          </div>
      </mat-radio-group>
    </ul>
  </section>
  <section class="dropdown-filter-menu">
      <h3 class="menu-title">{{ 'shared.deliverables.attributes.filter.label.options' | translate }}</h3>
      <div (click)="$event.stopPropagation()">
        <mat-checkbox id="statTesting" color="primary" rounded flat class="list-item" [value]="filterNames.statTesting" [checked]="filter.show.statTesting" (change)="toggleSelection(filterNames.statTesting)" noink>{{ 'shared.deliverables.attributes.filter.label.stat.testing' | translate }}</mat-checkbox>
        <mat-checkbox id="dataLabels" color="primary" [disabled]="disableDataLabel" rounded flat class="list-item"
                      [value]="filterNames.dataLabels" [checked]="filter.show.dataLabels" (change)="toggleSelection(filterNames.dataLabels)" noink>{{ 'shared.deliverables.attributes.filter.label.data.labels' | translate }}</mat-checkbox>
        <mat-checkbox id="barCharts" color="primary" rounded flat class="list-item" [value]="filterNames.barCharts" [checked]="filter.show.barCharts" (change)="toggleSelection(filterNames.barCharts)" noink>{{ 'shared.deliverables.attributes.filter.label.bar.charts' | translate }}</mat-checkbox>
      </div>
  </section>
</ns-dropdown-menu>
