import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';
import {Report} from '@platform/models/report.model';

@Component({
  selector: 'ns-purchase-preference',
  templateUrl: './purchase-preference.component.html',
  styleUrls: ['./purchase-preference.component.scss']
})
export class PurchasePreferenceComponent implements OnInit {

    @Output() configUpdated: EventEmitter<any>;
    @Input() report: Report;
    @Input() deliverablesConfig: any;
    @Input() purchasePreferenceShareConfiguration: any;
    @Output() closeFlyoutMenuEvent = new EventEmitter();
    @Output() moveToPreviousMenuEvent = new EventEmitter();

    constructor(private deliverableConfigurationService: DeliverableConfigurationService) {
        this.configUpdated = new EventEmitter<any>();
    }

    ngOnInit() {
        let purchasePreferenceShareConfiguration;
        if (!this.deliverablesConfig.find(c => c.name === this.deliverableConfigurationService.PURCHASE_PREFERENCE_SHARE)) {
            purchasePreferenceShareConfiguration = {
                reportId: this.report.id,
                name: this.deliverableConfigurationService.PURCHASE_PREFERENCE_SHARE,
                config: {
                    'analyticsOptionSelected' : 'purchasePreference'
                }
            };
            this.purchasePreferenceShareConfiguration = purchasePreferenceShareConfiguration.config;
            this.deliverableConfigurationService.updateConfigForPurchasePreference(purchasePreferenceShareConfiguration);
        }
    }

    valueChanged(value: string) {
        this.purchasePreferenceShareConfiguration.analyticsOptionSelected = value;
    }

    onCloseButtonClick(): void {
        this.closeFlyoutMenuEvent.emit();
    }

    returnBack(): void {
        this.moveToPreviousMenuEvent.emit();
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.closeFlyoutMenuEvent.emit();
        }
    }
}
