<div class="deliverable-container performance-content">
  <div *ngIf="!scoreCardConcept" class="sticky-header-container header-filter-container" [ngClass]="{ 'hide': isInsightEnable }">
    <div class="sticky-header-container">
      <ns-deliverables-header (selectionChange)="setViews($event)">
        <ns-kebab-menu>
          <ul class="kebab-menu-list">
            <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
          </ul>
        </ns-kebab-menu>
        <span rel="tooltip" matTooltipPosition="above" matTooltip=" {{'create.insights' | translate }}" *ngIf="isInternalUser" [ngClass]="{ 'disable-insight-btn': disableBtn }"
          class="insight-icon sif sif-insight" (click)="openInsightCreationForm()">
        </span>
      </ns-deliverables-header>
    </div>
  </div>
  <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" [isHTMLInsight]="addHTMLToInsight" (close)="closeInsight()">
  </ns-deliverable-insight>

  <div id="deliverable-container">
    <div class="filters-sticky-container" [ngClass]="{ 'hide': isInsightEnable }">
      <!--performance filter-->
      <ns-performance-filter></ns-performance-filter>
    </div>
    <div class="table-wrapper deliverable-insight-performance insight-html-root">
      <!--Performance chart table-->
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--Create dynamic columns from colHeaders-->
        <ng-container *ngFor="let colHeader of colHeaders;let i = index;trackBy: trackItem"
          matColumnDef="{{ colHeader.name }}">
          <ng-container *ngIf="i === 0">
            <!--First column static header-->
            <mat-header-cell class="statement-header" *matHeaderCellDef>
              <div class="index">
                <span class="upper">Relaunch Potential Score
                  <img class="information-icon cursor-pointer" [matTooltip]="relaunchScoreTooltip" matTooltipPosition="above"
                    matTooltipClass="performance-information-tooltip"
                    src="assets/images/deliverables/information-icon.svg" />
                </span>
                <span class="lower">Current index<span class="count">100</span></span>
              </div>
            </mat-header-cell>
            <mat-cell class="statement-header" *matCellDef="let element; let j = index">
              <div *ngIf="j === 0" class="statement-second-header">
                <span class="preference-index">Relaunch Target index
                  <img class="information-icon cursor-pointer" [matTooltip]="('restage.deliverable.performance.relaunch.index.tooltip' | translate)" matTooltipPosition="above"
                    matTooltipClass="performance-information-tooltip"
                    src="assets/images/deliverables/information-icon.svg" />
                </span>
                <div class="second-row-lower">
                  <span class="segment-name">{{ element.subgroup[0] }}</span>
                  <span class="total-count">n={{ element.subgroup[1] }}</span>
                </div>
              </div>
              <div *ngIf="j !== 0" class="segment-row-header">
                <span class="segment-name">{{ element.subgroup[0] }}</span>
                <span class="total-count">n={{ element.subgroup[1] }}</span>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="i !== 0">
            <mat-header-cell #columnHeader class="statement-header" *matHeaderCellDef>
              <div class="column-header">
                <span>{{ colHeader.name }}</span>
                <span class="text" [ngClass]="{
                    profit: colHeader.restagePurchaseIndex > 100,
                    neutral: colHeader.restagePurchaseIndex == 100,
                    loss: colHeader.restagePurchaseIndex < 100
                  }">

                    {{ colHeader.restagePurchaseIndex }}
                </span>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="statement-second-header segment-header">
                <ns-chart-horizontal-bar *ngIf="displayBarchart" [barChart]="element[colHeader.name].barChart"></ns-chart-horizontal-bar>
              </div>
            </mat-cell>
          </ng-container>
        </ng-container>
        <!--Row highlight-->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          [ngClass]="{ 'second-row': i === 0 }">
        </mat-row>
      </mat-table>
      <!-- Footer -->
      <div class="footer-text">
        <p class="footer-right">
          <span class="above">above current</span>
          <span class="current">neutral</span>
          <span class="below">below current</span>
        </p>
      </div>
    </div>
  </div>
</div>
<ns-progress-spinner class="example-margin" [display]="displayProgressSpinner">
</ns-progress-spinner>
