import {Pipe, PipeTransform} from '@angular/core';

/**
 * Truncate a word wrt count and add (...)
 *
 * @example
 * <ns-dropdown [label]="label | truncateLabel : 10"></span>
 */
@Pipe({
  name: 'truncateLabel'
})
export class TruncateLabelPipe implements PipeTransform {

  /**
   * Highlight the search text in the value provided.
   * @param value The string to truncate.
   * @param count The count of truncate value.
   * @return Truncate value wrt count along with (...).
   */
  transform(value: string, count: number): string {
    if (!count || (value.length <= count*2)) {
      return value;
    }

    const subString1 = value.substring(0, count)
    const subString2 = value.substring(value.length - count, value.length)

    return  subString1.concat("...", subString2);
  }

}
