import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DropdownData} from '@products/shared/dropdown/dropdown.data.model';
import {DropdownFilterService} from '@products/shared/dropdown-filter.service';
import { TranslateService } from '@ngx-translate/core';
import {VarietyDropdownData} from '@app/deliverables/reach-analysis/models/variety-filter.model';
import {MatLegacySlideToggle as MatSlideToggle} from '@angular/material/legacy-slide-toggle';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';

@Component({
  selector: 'ns-variety-filter',
  templateUrl: './variety-filter.component.html',
  styleUrls: ['./variety-filter.component.scss']
})

export class VarietyFilterComponent<T> implements OnChanges {

  @Input() deliverableLabel: string;
  @Input() dropDownData: VarietyDropdownData<T>;
  @Input() disable = false;
  @Input() disabled;
  @Output() selectionChange = new EventEmitter<VarietyDropdownData<T>>();

  /**
   * Individual item selection inside a group checkbox.
   * @memberof DropdownComponent
   */
  isAllCompleted = false;
  newLabel: string;
  isDropDownData = false;
  isCheckboxes = false;
  defaultLabel: string;
  onloadDefaultLabel: string;
  source: MatSlideToggle;


  constructor(
    private dropdownService: DropdownFilterService<T>,
    private translate: TranslateService,
    private mixpanelService: MixpanelService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    const dropDownData = changes.dropDownData;
    let current: VarietyDropdownData<T>;
    if (dropDownData && dropDownData.currentValue && dropDownData.currentValue.data) {
      current = dropDownData.currentValue;
      this.isDropDownData = true;
      this.defaultLabel = this.translate.instant('shared.dropdown.menu.no.selection', { value: current.dropdownLabel});
      this.isCheckboxes = true;
      if (current.groupSelect) {
        this.setCheckboxGroupDefaultLabels(current);
        this.onloadDefaultLabel = current.groupSelect;
      } else {
        this.setLabels(current);
      }
    }
  }

  /**
   * Setting the default labels for the checkbox group dropdown
   * @param { DropdownData } item
   * @memberof DropdownComponent
   */
  setCheckboxGroupDefaultLabels(item: VarietyDropdownData<T>) {
    this.isAllCompleted = item != null && item.data.every(t => t.selected);
    this.setLabels(item);
  }

  /**
   * Setting the labels for the dropdown
   * @param { DropdownData } item
   * @memberof DropdownComponent
   */
  setLabels(item: VarietyDropdownData<T>) {
    this.newLabel = this.getLabels(item);
  }

  /**
   * get labels for the dropdown
   * @memberof DropdownComponent
   * @param dropdown
   */
  public getLabels(dropdown: VarietyDropdownData<T>): string {
    return this.setCheckboxLabels(dropdown);
  }

  /**
   * Setting the labels for the dropdown
   * @memberof DropdownComponent
   * @param CheckboxItem
   */
  private setCheckboxLabels(CheckboxItem: VarietyDropdownData<T>): string {
    let label: string;
    this.isAllCompleted = CheckboxItem != null && CheckboxItem.data.every(t => t.selected);
    if (this.isAllCompleted === true) {
      label = CheckboxItem.groupSelect;
    } else {
      label = this.getCheckboxLabels(CheckboxItem);
    }
    return label + (' (' + this.getLockedCount(CheckboxItem) + ')');
  }

  /**
   * Getting the labels for the dropdown
   * @param { DropdownData } item
   * @memberof DropdownComponent
   */
  private getCheckboxLabels(item: VarietyDropdownData<T>): string {
    let label = '';
    const datasetLength = item.data.length;
    const labelsArray: Array<string> = [];
    item.data.forEach(dataItem => {
      if (dataItem.selected === true) {
        labelsArray.push(dataItem.label);
      }
    });
    if (labelsArray.length) {
      if (labelsArray.length === 1) {
        label = labelsArray[0];
      } else {
        label = labelsArray.length + ' ' + item.dropdownLabel;
      }
    } else {
      label = 'No Varieties';
    }
    if (labelsArray.length === datasetLength ) {
      labelsArray.length = 0;
    }
    return label;
  }
   /**
   * locked count for Varieties
   * @param { DropdownData } item
   * @memberof DropdownComponent
   **/

  private getLockedCount(item: VarietyDropdownData<T>): string {
    let count = 0;
    item.data.forEach(element => {
      element.isLocked ? count ++ : '';
    });
    return  count + ' locked';
  }

  /**
   * Select All of group checkbox
   * @param {boolean} isCompleted
   */
  selectAllCheckboxes(isCompleted: boolean) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.isAllCompleted = isCompleted;
    if (this.dropDownData == null) {
      return;
    }
    this.dropDownData.data.forEach(i => {
      !i.isLocked ? i.selected = isCompleted : '';
    });
    this.setLabels(this.dropDownData);
    this.selectionChange.emit(this.dropDownData);
  }

  /**
   * update all selected checkboxes
   * @memberof DropdownComponent
   */
  updateAllSelectCheckboxes() {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.setCheckboxGroupDefaultLabels(this.dropDownData);
    this.selectionChange.emit(this.dropDownData);
  }

  /**
   * update locked varieties for the dropdown
   * @memberof DropdownComponent
   */
  public updateLockedVarieties() {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.dropDownData.data.forEach(it => {
      if (it.isLocked) {
        it.selected = true;
      }
    });
    this.selectionChange.emit(this.dropDownData);
  }

}
