export class GapAnalysisChartData {
  /**
   * this is static value for horizontal line across the chart
   *
   * @type {number}
   * @memberOf GapAnalysisChartData
   */
  lineChartValue: number = 100;

  /**
   * statementGroups is the string array of statement labels
   * each string represents a group of bars in the chart
   *
   * @type {string[]}
   * @memberOf GapAnalysisChartData
   */
  statementGroups: string[];

  /**
   * restageSubgroups is the number array of restage conceptType positions
   * each number represents restage bar position in each bar group of the chart
   * bars are ordered in asc order of positions
   * there can be gaps between positions, if the study has lesser restage concepts
   *
   * @type {string[]}
   * @memberOf GapAnalysisChartData
   */
  restageSubGroups: number[];

  /**
   * Chart data for each statement
   *
   * @type {Array<StatementChartData>}
   * @memberOf GapAnalysisChartData
   */
  statementGroupsChartData: Array<StatementChartData>;

  //y axis values
  yAxisRange: YAxisRange;
}

/**
 * Chart data for each bar in the chart
 */
export class BarChartData{
  /**
   * conceptId
   * id of the bar
   *
   * @type {number}
   * @memberOf BarChartData
   */
  id: number; //conceptId

  /**
   * conceptName
   * name to show on the bar
   *
   * @type {string}
   * @memberOf BarChartData
   */
  barName: string;

  /**
   * conceptType
   * this can be any of 1, 2, 3, ...
   * this represents bar position in each grouped bar chart series
   *
   * @type {string}
   * @memberOf BarChartData
   */
  restageGroupPosition: number;

  /**
   * percentage value of conceptMeanValue
   * this value is 0, if respective concept is unselected
   * this is bar y-axis value
   *
   * @type number
   * @memberOf BarChartData
   */
  value: number;
}

/**
 * Chart data for a statement that is a group(group of bars) in the chart
 */
export class StatementChartData {
  /**
   * statement label
   * this is group name for bar group in the grouped bar chart
   *
   * @type string
   * @memberOf StatementChartData
   */
  statementGroupName: string;

  /**
   * bar chart data for each bar in the bar group
   *
   * @type {Array<BarChartData>}
   * @memberOf StatementChartData
   */
  restageGroupsData: Array<BarChartData>;

  /**
   * line chart y-axis value for bar group
   * percentage conceptMeanValue of a concept with conceptType 'current'
   *
   * @type number
   * @memberOf StatementChartData
   */
  statementValue: number;
}

/**
 * y-axis values for the chart
 */
export class YAxisRange {
  yMinValue: number = 0;
  yMaxValue: number;
  yStaticValue: number = 100;
  yCurrentMinValue: number;
  yCurrentMaxValue: number;
}

