/**
 * Filter name keys and values.
 *
 * @export
 * @property
*/
export const filterNames = {
  noDecimals: 'no-decimals',
  oneDecimalPlace: 'one-decimal-place',
  twoDecimalPlace: 'two-decimal-place',
  upToTwentyAlternatives: 'up-to-twenty',
  allAlternatives: 'all'
};
