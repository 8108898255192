<div id="survey-question-legend" *ngIf="filter">
  <div class="section text-center padding-1" *ngIf="filter.show.aggregate && isConceptView">
    <span class="legend-part-1">
        <span class="text-highlight text-accent">%</span>
        <span class="legend-text margin-0-point-5" test-selector="deliverable-footnote">{{ "shared.deliverables.survey.question.legend.questions.advantageLegend" | translate}}</span>
    </span>
    <span>
        <span class="text-highlight text-muted">%</span>
        <span class="legend-text margin-0-point-5" test-selector="deliverable-footnote">{{ "shared.deliverables.survey.question.legend.questions.disadvantageLegend" | translate}}</span>
    </span>
</div>
  <div id="legend-footnote" *ngIf="filter.show.statTesting" class="section text-center padding-1">
    <span>{{ 'shared.deliverables.attributes.legend.footnote.stat.testing' | translate }}</span>
  </div>

</div>
