import { iterateStyleDeclaration } from './style-util';
import { CSSNodeDeclaration } from './css-node-declaration';

export class TagDeclarationList {

  /**
   * Tag defaults.
   *
   * @private
   * @type {{ [tagName: string]: CSSNodeDeclaration }}
   * @memberof TagDeclarationList
   */
  private defaults: { [tagName: string]: CSSNodeDeclaration };

  /**
   * Initialize.
   */
  constructor() {
    this.defaults = {};
  }

  /**
   * Returns the CSSNodeDeclaration if the tag defaults are already loaded. If it is not
   * loaded then it will load and return it.
   *
   * @param tagName the tag name
   * @returns the default CSSNodeDeclaration for the tag.
   */
  public get(tagName: string): CSSNodeDeclaration {
    let cssNodeDeclaration: CSSNodeDeclaration = this.defaults[tagName];
    if (!cssNodeDeclaration) {
      this.add(tagName);
      cssNodeDeclaration = this.defaults[tagName];
    }
    return cssNodeDeclaration;
  }

  /**
   * Adds the default styles for the tag to the map for lookup.
   *
   * @param tagName the element tag name.
   */
   private add(tagName: string): void {
    // Skip if default styles already added
    if (this.defaults[tagName]) {
      return;
    }
    // Create a temp element with the same tag.
    const tagElement = document.createElement(tagName);
    document.body.appendChild(tagElement);
    // Set all default styles of the tag to the default Styles object.
    this.defaults[tagName] = this.getCSSNodeDeclaration(tagElement);
    // Remove the temp element.
    document.body.removeChild(tagElement);
  }

  /**
   * Returns CSSNodeDeclaration for the element.
   *
   * @param element the element.
   * @returns the CSSNodeDeclaration.
   */
  private getCSSNodeDeclaration(element: Element): CSSNodeDeclaration  {
    // Get styles object of the temp element.
    const styleDeclaration: CSSStyleDeclaration = getComputedStyle(element);
    const beforeDeclaration: CSSStyleDeclaration = getComputedStyle(element, ':before');
    const afterDeclaration: CSSStyleDeclaration = getComputedStyle(element, ':after');
    const cssNodeDeclaration: CSSNodeDeclaration = new CSSNodeDeclaration();
    iterateStyleDeclaration(styleDeclaration, (property, value) => {
      if (value) {
        cssNodeDeclaration.declaration.set(property, value);
      }
    });
    iterateStyleDeclaration(beforeDeclaration, (property, value) => {
      if (value) {
        cssNodeDeclaration.beforeDeclaration.set(property, value);
      }
    });
    iterateStyleDeclaration(afterDeclaration, (property, value) => {
      if (value) {
        cssNodeDeclaration.afterDeclaration.set(property, value);
      }
    });
    return cssNodeDeclaration;
  }

}
