<div class="deliverable-container">
  <ng-container *ngIf="!scoreCardConcept">
    <div class="sticky-header-container header-filter-container" [ngClass]="{'hide': isInsightEnable}">
      <div class="sticky-header-container">
        <!--Swipe -->
        <ns-deliverables-header (selectionChange)="setViews($event)">
          <ns-kebab-menu>
            <ul class="kebab-menu-list">
              <li><span (click)="exportAsPNG()">{{ 'export.as.png' | translate }}</span></li>
            </ul>
          </ns-kebab-menu>
          <span *ngIf="isInternalUser" [ngClass]="{'disable-insight-btn': disableBtn}"
            class="insight-icon sif sif-insight" (click)="openInsightCreationForm()"></span>
        </ns-deliverables-header>
      </div>
    </div>
    <ns-deliverable-insight [enabled]="isInsightEnable" [deliverableData]="deliverableData" (close)="closeInsight()" [isHTMLInsight]="addHTMLToInsight">
    </ns-deliverable-insight>
  </ng-container>
    <div class="filters-sticky-container">
        <ns-carousel-table class="carousel-panel" [tooltipDescription]= "tooltipForConceptNavigation" [isManaged]="false" [staticColumns]= "[0]" [columnWidthList]= "columnWidths" (change)="updateConceptCardVisibility($event)"
                           (next)="nextPage()" (previous)="previousPage()"></ns-carousel-table>
    </div>
  <div class="ap-container insight-html-root carousel-container" id="deliverable-container">
      <div class="activation-profile-container" *ngIf="headerList">
          <div class="activation-profile-header">
              <ap-header *ngIf="headerList" [headerList]="headerList" [basesLabel]="basesLabel"
                         (nodeToggled)="onNodeToggle($event)"></ap-header>
          </div>
      <div class="activation-profile-item" *ngFor="let concept of displayedConcepts">
        <activation-profile-item (nodeToggled)="getToggledNode($event)" [locale]="locale" [concept]="concept" [benchmarks]="benchmarks" [headerList]="headerList" [basesLabel]="basesLabel"
          [measureDescription]=getProfileList(concept)></activation-profile-item>
      </div>
    </div>
    <div class="footer" *ngIf="!measureLength">
      {{"Data not found"}}
    </div>
  </div>
</div>
