import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FilterService} from '@platform/services/filter.service';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {Subscription} from 'rxjs';
import {FilterItem, WordCloudFilter} from '../models/filter.model';
import {QuestionByConcept, QuestionBySubgroup} from '../models/word-cloud.model';
import {WordCloudService} from '../services/word-cloud.service';
import {Verbatim} from "@products/shared/verbatims-list/verbatim.model";
import {MixpanelLabel} from '@src/assets/utils/mixpanel-enum';

@Component({
    selector: 'ns-word-cloud-card',
    templateUrl: './word-cloud-card.component.html',
    styleUrls: ['./word-cloud-card.component.scss']
})
export class WordCloudCardComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * path for translations icon image
     */
    imageBasePath = 'assets/images/deliverables';
    imagePath = `${this.imageBasePath}/icon_language.svg`;

    @Input() index: number;
    /**
     * Concept object.
     *
     * @property
     * @type {QuestionByConcept}
     * @memberof WordCloudCardComponent
     */
    @Input() concept: QuestionByConcept;

    /**
     * Subgroup object.
     *
     * @property
     * @type {QuestionBySubgroup}
     * @memberof WordCloudCardComponent
     */
    @Input() subgroup: QuestionBySubgroup;

    /**
     * Mixpanel name of the component.
     *
     * @property
     * @type {string}
     * @memberof WordCloudCardComponent
     */
    public mixpanelLabel: string;

    /**
     * WordCloud filter object.
     *
     * @property
     * @type {WordCloudFilter}
     * @memberof WordCloudCardComponent
     */
    public filter: WordCloudFilter;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberof WordCloudCardComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * List of translations on the report.
     *
     * @property
     * @type {DropdownData}
     * @memberof WordCloudCardComponent
     */
    public translationsListData: DropdownData<string>;

    /**
     * Concept or Subgroup header.
     *
     * @property
     * @type {string}
     * @memberof WordCloudCardComponent
     */
    public header: string;

    /**
     * List of filtered verbatims.
     *
     * @property
     * @type {Array<String>}
     * @memberof WordCloudCardComponent
     */
    public filteredVerbatims: Array<Verbatim>;

    /**
     * Array of words and their weight collected by the deliverables input
     *
     * @property
     * @type {Record<string, number>}
     * @memberof WordCloudCardComponent
     */
    public wordCount: Record<string, number>;

    /**
     * elementId is unique id supplied by the conceptId or subgroupId
     *
     * @property
     * @type {number}
     * @memberof WordCloudCardComponent
     */
    public elementId: number;

    /**
     * Selected translation
     *
     * @property
     * @type {FilterItem}
     * @memberof WordCloudCardComponent
     */
    public selectedTranslation: FilterItem;

    /**
     * list of translations for each concept or subgroup
     *
     * @property
     * @type {Array<FilterItem>}
     * @memberof WordCloudCardComponent
     */
    public translations: Array<FilterItem>;

    /**
     * search text entered by user
     *
     * @property
     * @type {String}
     * @memberof WordCloudCardComponent
     */
    public searchText: String;

    /**
     * List of all verbatims.
     *
     * @property
     * @type {Array<String>}
     * @memberof WordCloudCardComponent
     */
    public verbatimsList: Array<string>;

    /**
     * Creates an instance of WordCloudCardComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {WordCloudService} wordCloudService
     * @param {FilterService} filterService
     * @param {TranslateService} translate
     * @param {LocaleService} localeService
     * @member WordCloudCardComponent
     */
    constructor(
        private translate: TranslateService,
        private wordCloudService: WordCloudService,
        private filterService: FilterService) {
        this.subscriptions = [];
    }

    /**
     * Initialize component and load data
     *
     * @member WordCloudCardComponent
     */
    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges(): void {
        this.initialize();
    }

    initialize(): void {
        this.mixpanelLabel = MixpanelLabel.wordCloud;
        const filter$ = this.wordCloudService.getWordCloudFilter();
        const subscription = filter$.subscribe((filter) => {
            this.filter = filter;
            this.concept ? this.getConceptWordCloud(filter) : this.getSubgroupWordCloud(filter);
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Word cloud for concepts view.
     *
     * @member WordCloudCardComponent
     * @param filter
     */
    getConceptWordCloud(filter: WordCloudFilter): void {
        this.header = this.concept.conceptName;
        const concept = filter.concepts.filter(c => c.id === this.concept.conceptId);
        this.translations = concept[0].translations;
        this.selectedTranslation = this.translations.filter(locale => locale.isSelected)[0];
        this.translationsListData = this.getTranslationOptions(this.translations);
        if (this.concept.subgroupsVerbatims.length > 0 ) {
            const translationsData =  this.getTranslationsForLocale(this.concept.subgroupsVerbatims, this.selectedTranslation.id);
            this.verbatimsList = translationsData.verbatimsList;
            const wordCount = translationsData.verbatimWords;
            this.wordCount = Object.assign({}, wordCount);
            this.elementId = this.concept.conceptId;
            this.getFilteredVerbatims(this.verbatimsList);
        }
    }

    /**
     * Word cloud for subgroup view
     *
     * @member WordCloudCardComponent
     * @param filter
     */
    getSubgroupWordCloud(filter: WordCloudFilter): void {
        this.header = this.subgroup.subgroupName;
        const subgroup = filter.subgroups.filter(s => s.id === this.subgroup.subgroupId);
        this.translations = subgroup[0].translations;
        this.selectedTranslation = this.translations.filter(locale => locale.isSelected)[0];
        this.translationsListData = this.getTranslationOptions(this.translations);
        if(this.subgroup.conceptsVerbatims.length > 0 ) {
            const translationsData =  this.getTranslationsForLocale(this.subgroup.conceptsVerbatims, this.selectedTranslation.id);
            this.verbatimsList = translationsData.verbatimsList;
            const wordCount =  translationsData.verbatimWords;
            this.wordCount = Object.assign({}, wordCount);
            this.elementId = this.subgroup.subgroupId;
            this.getFilteredVerbatims(this.verbatimsList);
        }
    }

    /**
     * Function to get verbatims and words for a given locale
     *
     * @param verbatimsData
     * @param {string} locale
     */
    getTranslationsForLocale(verbatimsData:  any, locale: string) {
        const verbatimsList: string[] = [];
        const verbatimWords: { [key: string]: number } = {};
        const uniqueVerbatimsSet = new Set();
        verbatimsData.forEach(group => {
            group.forEach(entry => {
                if (Array.isArray(entry.translations)) {
                    const translation = entry.translations.find(t => t.locale === locale);
                    if (translation) {
                        translation.respondentVerbatims.forEach(verbatim => {
                            if (!uniqueVerbatimsSet.has(verbatim)) {
                                verbatimsList.push(verbatim);
                                uniqueVerbatimsSet.add(verbatim);
                            }
                        });
                        Object.keys(translation.verbatimWords).forEach(word => {
                            if (!verbatimWords[word]) {
                                verbatimWords[word] = 0;
                            }
                            verbatimWords[word] += translation.verbatimWords[word];
                        });
                    }
                }
            });
        });
        return { verbatimsList, verbatimWords };
    }
    /**
     * Search text entered by user in search bar
     *
     * @param {String} text
     * @member WordCloudCardComponent
     */
    getSearchText(text: String) {
        this.searchText = text;
    }

    /**
     * Filtered verbatims list based on search
     *
     * @param {Array<String>} verbatims
     * @member WordCloudCardComponent
     */
    getFilteredVerbatims(verbatims: Array<string>) {
        this.filteredVerbatims = verbatims.map((verbatim) => {
            const verbatimObject: Verbatim = {value: ''};
            verbatimObject.value = verbatim;
            return verbatimObject;
        });
    }

    /**
     * Returns the translation filter options.
     *
     * @private
     * @param {string} country
     * @returns {DropdownData}
     * @member WordCloudCardComponent
     */
    public getTranslationOptions(translations: Array<FilterItem>): DropdownData<string> {
        const languages: Array<DropdownItem<string>> = [];
        translations.forEach(locale => {
            languages.push({
                value: locale.name,
                label: locale.name,
                selected: locale.isSelected
            });
        });

        return {
            dropdownLabel: this.translate.instant('quick.question.deliverables.wordcloud.filter.country.label'),
            dataType: DataType.RADIO,
            data: languages
        };
    }

    /**
     * Event listener for translation Filter selection change event.
     * @listens {event} selectionChange
     * @param {Array<DropdownItem<string>>} translations
     * @member WordCloudCardComponent
     */
    selectTranslation(translations: Array<DropdownItem<string>>): void {
        this.concept ? this.selectConceptTranslation(translations) : this.selectSubgroupTranslation(translations);
    }

    /**
     * concept translation change.
     * @param {Array<DropdownItem<string>>} translations
     * @member WordCloudCardComponent
     */
    selectConceptTranslation(translations: Array<DropdownItem<string>>) {
        const selectedItem = this.filter.concepts.filter((concept) => concept.id === this.concept.conceptId)[0];
        this.filterService.update({
            ...this.filter,
            concepts: this.filter.concepts.map((concept) => {
                if (selectedItem.id === concept.id) {
                    return {
                        ...concept,
                        translations: this.translations.map((locale) => {
                            return {
                                ...locale,
                                isSelected: translations.find(it => it.value === locale.name).selected
                            };
                        })
                    };
                } else {
                    return concept;
                }
            })
        });
    }

    /**
     * subgroup translation change.
     * @param {Array<DropdownItem<string>>} translations
     * @member WordCloudCardComponent
     */
    selectSubgroupTranslation(translations: Array<DropdownItem<string>>) {
        const selectedItem = this.filter.subgroups.filter((subgroup) => subgroup.id === this.subgroup.subgroupId)[0];
        this.filterService.update({
            ...this.filter,
            subgroups: this.filter.subgroups.map((subgroup) => {
                if (selectedItem.id === subgroup.id) {
                    return {
                        ...subgroup,
                        translations: this.translations.map((locale) => {
                            return {
                                ...locale,
                                isSelected: translations.find(it => it.value === locale.name).selected
                            };
                        })
                    };
                } else {
                    return subgroup;
                }
            })
        });
    }

    /**
     * Cleanup the component on removing from the UI.
     *
     * @member WordCloudCardComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
