import { Concept } from '@platform/models/concept.model';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Report } from '@platform/models/report.model';
import { FilterService } from '@platform/services/filter.service';
import { ReportService } from '@platform/services/report.service';
import { DataType, DropdownData, DropdownItem } from '@products/shared/dropdown/dropdown.data.model';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FilterItem, SurveyQuestionFilter } from '../models/filter.model';
import { SurveyQuestionService } from '../services/survey-question.service';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent } from '@src/assets/utils/mixpanel-enum';

/**
 * `FilterComponent` creates the custom question filter options like compare,
 * concepts, country, subgroups, and show filter.
 *
 * @example
 *   <ns-survey-question-filter></ns-survey-question-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */

@Component({
  selector: 'ns-survey-question-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  /**
   * Set the concept to set single concept filter.
   *
   * @memberof FilterComponent
   */
  @Input() concept: Concept;

  /**
   * survey-question filter object.
   *
   * @property
   * @type {SurveyQuestionFilter}
   * @memberof FilterComponent
   */
  public filter: SurveyQuestionFilter;
  public defaultFilter: SurveyQuestionFilter;

  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {Array<DropDownItem>}
   * @memberof FilterComponent
   */
  public conceptsListData: DropdownData<number>;

  /**
   * List of subgroup dropdown items.
   *
   * @property
   * @type {Array<DropDownItem>}
   * @memberof FilterComponent
   */
  public subgroupListData: DropdownData<number>;

  /**
   * List of deliverable view dropdown items.
   *
   * @property
   * @type {Array<DropDownItem>}
   * @memberof FilterComponent
   */
  public compareListData: DropdownData<string>;

  /**
 * List of countries on the report.
 *
 * @property
 * @type {Array<DropDownItem>}
 * @memberof FilterComponent
 */
  public countryListData: DropdownData<string>;
  /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberof FilterComponent
   */
  private subscriptions: Array<Subscription>;
  /**
   * Mixpanel label for the deliverable
   *
   * @property
   * @private
   * @type {string}
   * @memberof FilterComponent
   */
  private deliverableLabel: string;

  constructor(
    private surveyQuestionService: SurveyQuestionService,
    private filterService: FilterService,
    private reportService: ReportService,
    private translate: TranslateService,
    private mixpanelService: MixpanelService
  ) {
    this.subscriptions = [];
  }


  /**
   * Event listener for concept selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropDownItem>} concepts
   * @memberof FilterComponent
   */
  selectConcept(concepts: Array<DropdownItem<number>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: concepts.find(selectedConcept => selectedConcept.value === concept.id).selected
        };
      })
    });
  }

  /**
   * Event listener for subgroup selection change event.
   *
   * @listens event:selectionChange
   * @param {Array<DropDownItem>} subgroups
   * @memberof FilterComponent
   */
  selectSubgroup(subgroups: Array<DropdownItem<number>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.id).selected
        };
      })
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.deliverableLabel = this.concept ? MixpanelLabel.concepts : MixpanelLabel.surveyQuestions;
    const filter$ = this.surveyQuestionService.getSurveyQuestionFilter();
    const report$ = this.reportService.get();
    const subscription = combineLatest([filter$, report$]).subscribe(([filter, report]) => {
      this.filter = filter;
      this.setFilters(filter, report);
    });
    const filterSubscription = filter$.pipe(take(1)).subscribe(
      (filter: SurveyQuestionFilter) => {
        this.defaultFilter = filter;
      }
    );
    this.subscriptions.push(filterSubscription);
    this.subscriptions.push(subscription);
  }

  /**
 * Sets the filter options for concepts, subgroups, compareList, countries.
 *
 * @private
 * @param {AttributesFilter} filter
 * @param {Report} report
 * @memberof FilterComponent
 */
  private setFilters(filter: SurveyQuestionFilter, report: Report): void {
    this.conceptsListData = this.getConceptFilterOptions(filter);
    this.subgroupListData = this.getSubGroupFilterOptions(filter);
    this.compareListData = this.getCompareFilterOptions(filter.compare);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
 * Returns concepts filter options.
 *
 * @private
 * @param {Array<FilterItem>} items
 * @returns {Array<DropdownData>}
 * @memberof FilterComponent
 */
  private getConceptFilterOptions(filter: SurveyQuestionFilter): DropdownData<number> {
    const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.CHECKBOX : DataType.RADIO;
    const items = filter.concepts;
    const dropdownData: DropdownItem<number>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<number> = {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.concepts.label'),
      dataType: dataType,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.concepts.itemsTitle'),
      groupSelect: this.translate.instant('shared.deliverables.attributes.filter.concepts.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns sub groups filter options.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {Array<DropdownData>}
   * @memberof FilterComponent
   */
  private getSubGroupFilterOptions(filter: SurveyQuestionFilter): DropdownData<number> {
    const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.RADIO : DataType.CHECKBOX;
    const groupSelect = (dataType === DataType.RADIO ? '' : this.translate.instant('shared.deliverables.survey-question.filter.subgroups.allSubgroups'));
    const items = filter.subgroups;
    const dropdownData: DropdownItem<number>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<number> = {
      dropdownLabel: this.translate.instant('shared.deliverables.survey-question.filter.subgroups.label'),
      dataType: dataType,
      groupSelect: groupSelect,
      contentTitle: this.translate.instant('shared.deliverables.survey-question.filter.subgroups.itemsTitle'),
      data: dropdownData
    };
    return dropdownItems;
  }

  private getCompareFilterOptions(items: Array<FilterItem>): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = items.map(item => {
      return {
        value: item.id,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.compare.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.compare.itemsTitle'),
      data: dropdownData
    };
    return dropdownItems;
  }

  private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('shared.deliverables.attributes.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('shared.deliverables.attributes.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }

  /**
   * Sets the new filter entries when changes are made to compare drop down.
   *
   * @listens event:selectionChange
   * @param {Array<DropDownItem>}compare The list of compare options.
   */
  selectCompare(compare: any) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
          ...this.filter,
          compare: this.filter.compare.map((item: FilterItem) => {
            return {
              ...item,
              isSelected: compare.find(selected => selected.value === item.id).selected
            };
          }),
          concepts: this.filter.concepts.map((concept, index) => {
            return {
              ...concept,
              isSelected: compare.find(selected => selected.selected === true && selected.value === 'subgroup') ? (index === 0 ? true : false) : true
            };
          }),
          subgroups: this.filter.subgroups.map((subgroup, index) => {
            return {
              ...subgroup,
              isSelected: compare.find(selected => selected.selected === true && selected.value === 'concept') ? (index === 0 ? true : false) : true
            };
          }),
          show: {
            ...this.defaultFilter.show,
            aggregate: compare.find(selected => selected.selected === true && selected.value === 'concept' && this.defaultFilter.show.aggregate) ? true : false
          }
        });
  }

  /**
 * Emits event of the selected filter menu item.
 */
  selectedEvent(event): void {
    console.log(event);
  }

}
