import {EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {Organization} from "@platform/models/organization.model";
import {OrganizationState} from "@platform/store/organization/organization.state";

/**
 * Organization entity adapter.
 *
 * @export
 * @property {EntityAdapter<Organization>} organizationAdapter - The organization adapter.
 */
export const organizationAdapter: EntityAdapter<Organization> = createEntityAdapter<Organization>();

/**
 * Initial state.
 */
export const initialState: OrganizationState = organizationAdapter.getInitialState({});
