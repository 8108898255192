<div class="alternative-combinations-content" *ngIf="filter && !displaySpinner && combinationsCount !== undefined; else progressSpinner">
  <div *ngIf="possibleCombinations && combinationsCount; else noData">
    <div class="sticky-dropdown deliverable-content">
      <div class="set-number-dropdown-field">
        <mat-form-field>
          <span class="dropdown-icon sif sif-chevron-s"></span>
          <mat-select [disableOptionCentering]="true" [(ngModel)]="selectedSet" disableRipple (selectionChange)="selectSetNumber($event.value)">
            <mat-option *ngFor="let set of filter.show.combinationSets" [value]="set.id">
              {{set.id}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        Combinations Sets
        <mat-paginator (page)="pageChanged($event)" #alternativePagination class="pagination" [length]="possibleCombinations" [hidePageSize]="true" [pageSize]="20"></mat-paginator>
      </div>
    </div>
    <div class="table-responsive">
      <div class="table">
        <div class="table-container container-varieties row">
          <div class="col-varieties col-set col-md-5">
            <div></div>
            <div (click)="sortView(sortVarietiesSetting)">{{ 'shared.deliverables.reach.sort.varieties' | translate }}
              <span  *ngIf="sortVarietiesSetting == 'asc'"><b>{{ 'shared.deliverables.reach.ascending' | translate }}<span class="title-sort-arrow-asc"></span></b></span>
              <span  *ngIf="sortVarietiesSetting == 'desc'"><b>{{ 'shared.deliverables.reach.descending' | translate }}<span class="title-sort-arrow-desc"></span></b></span>
              <span  *ngIf="sortVarietiesSetting == null"><b>{{ 'shared.deliverables.reach.natural' | translate }}</b></span>
            </div>
            <div class="variety-name" *ngFor="let variety of trimmedNames">
              <div matTooltipPosition="above" [matTooltip]="variety.fullName" class="cropped-name" [attr.full-name]="variety.fullName">
                <p>{{variety.name}}</p>
              </div>
            </div>
          </div>
          <div class="col-md-5 combination-section">
            <div class="top-legend-section">
              <div class="seperators">
                <span class="line"></span>
                <div class="separator" >{{ percentageText | translate }}</div>
              </div>
            </div>
            <div class="col-set" *ngFor="let combination of combinations$ |async">
              <div></div>
              <div class="weights">{{combination.weight}}</div>
              <div #box *ngFor="let variety of filteredVarieties; let i = index">
                <ns-highlight-cell [combination] = "combination" [variety]=variety [index]="i" [numbers]="false"></ns-highlight-cell>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #noData>
  <div class="no-combination">
    <span class="data sif sif-no-results"></span>
    <h3>There are no Results</h3>
    <p>Try refining the filters to populate data.</p>
  </div>
</ng-template>

<ng-template #progressSpinner>
  <mat-progress-spinner style="margin:0 auto;" color="secondary" diameter="100" mode="indeterminate" value="50"></mat-progress-spinner>
</ng-template>
