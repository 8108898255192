import { Injectable } from '@angular/core';
import * as numeral from 'numeral';

/**
 * This service provides helper methods for string operations.
 *
 * @example
 * constructor(private stringHelper: StringHelper) { }
 *
 * @export
 * @class StringHelper
 */
@Injectable({
  providedIn: 'root'
})
export class StringHelper {

  /**
  * Formats number with the numeral.js. Please refer http://numeraljs.com/
  * for all supported format patterns

  * @param value - Number to be formatted
  * @param formatPattern - Format pattern in which number should be formatted.
  * @param noDataString - If the value is null, this string will be
  * displayed and default value is an empty string.
  * @returns {string} The format number value as string.
  */
  public formatNumber(value: string | number, formatPattern: string, noDataString: string): string {
    value = value.toString();
    if (value && value.length > 0) {
      if (formatPattern) {
        value = numeral(value).format(formatPattern);
      }
    } else {
        // Check for noDataString parameter.
        if (noDataString) {
          value = noDataString;
        } else {
          value = '';
        }
    }
    return value;
  }

  /**
   * Transforms an index number (starting from 0) to corresponding alphabet.
   *
   * @param {number} index The index
   * @returns {string} The alphabet equivalent of the index.
   * @memberof StringHelper
   */
  public alphabetSeries(index: number): string {
    if (index < 0) {
        return '' + index;
    }
    const numberOfAlphabets = 26;
    const charCodeA = 'A'.charCodeAt(0);
    const label = String.fromCharCode( charCodeA + (index % numberOfAlphabets));
    const suffix = Math.floor(index / numberOfAlphabets);
    return label + (suffix !== 0 ? suffix : '');
  }

  /**
   * Returns file extension from the filename.
   *
   * @param {string} filename
   * @returns {string} the file extension
   */
  public extension(filename: string): string {
    const pattern = /(?:\.([^.]+))?$/;
    const extension = pattern.exec(filename)[1];
    return extension;
  }

  /**
   * sort Alphabetic and number combination series like A, A1,B, B1
   *
   * @param {Array<string>} array
   * @returns {Array<string>} array of string
   */
  public sortAlphabeticSeries(array: Array<any>): Array<string> {
    let compareFunction = this.getBadgeCompareFunction();
    return array.sort(compareFunction);
  }

  /**
   * return method to sort alphabet and number combination
   */
  public getBadgeCompareFunction() : any{
    return function(a, b) {
        let aNum = 0,
            bNum = 0;
        if (a === null || a === '') {
            return -1;
        } else if (b === null || b === '') {
            return 1;
        }
        if (a.length > 1) {
            aNum = +a.substring(1);
        }
        if (b.length > 1) {
            bNum = +b.substring(1);
        }
        if (bNum !== aNum) {
            return aNum - bNum;
        }
        return a.charCodeAt(0) - b.charCodeAt(0);
    };
  }
}
