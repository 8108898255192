import {select, Store} from "@ngrx/store";
import {State} from "@platform/store/state/app.state";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ReportService} from "@platform/services/report.service";
import {Injectable} from "@angular/core";
import {AppConfigService} from "@app/core/config/app-config.service";
import {Observable} from "rxjs";
import {concatMap, take} from "rxjs/operators";
import {Archive} from "@platform/models/archive.model";
import {selectReportArchive} from "@platform/store/selectors/archive.selectors";
import {addArchive, updateArchive} from "@platform/store/actions/archive.action";

/**
 * This service provides operations for fetching and loading report subgroups.
 *
 * @example
 * constructor(private archiveService: ArchiveService) { }
 *
 * @export
 * @class ArchiveService
 */
@Injectable({
  providedIn: 'root'
})
export class ArchiveService {


  /**
   * Loading flag to prevent multiple server calls.
   *
   * @private
   */
  private isLoading = false;

  constructor(
    private store: Store<State>,
    private httpClient: HttpClient,
    private reportService: ReportService,
    private appConfigService: AppConfigService
  ) {
  }

  /**
   * Returns observable of Archive for the current report object
   * from the store if available else fetch it using API.
   *
   * @returns {Observable<Array<Insight>>}
   * @memberOf ArchiveService
   */
  public getReportArchive(reportId: string): Observable<Archive> {
    let archive$ = this.fetchReportArchiveFromStore(reportId);
    archive$.pipe(take(1)).subscribe(archive => {
      if (this.isLoading !== true && !archive) {
        this.isLoading = true;
        archive$ = this.fetchFromAPI(reportId);
      }
    });
    return archive$
  }

  /**
   * Fetch all Insights associated with the current report
   * from the store.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Archive>}
   * @memberOf ArchiveService
   */
  public fetchReportArchiveFromStore(reportId: string): Observable<Archive> {
    return this.store.pipe(select(selectReportArchive, {reportId}));
  }

  /**
   * Returns an observable of Archive fetched using the API.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Archive>}
   * @memberOf ArchiveService
   */
  private fetchFromAPI(reportId: string): Observable<Archive> {
    const url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/archive`;
    return this.httpClient.get<Archive>(url).pipe(
      concatMap(result => {
        this.loadOnStore(result);
        this.isLoading = false;
        return this.fetchReportArchiveFromStore(reportId);
      })
    );
  }

  /**
   * Loads Archive into the store.
   *
   * @private
   * @memberOf ArchiveService
   * @param archive
   */
  private loadOnStore(archive: Archive): void {
    this.store.dispatch(addArchive({archive}));
  }

  /**
   * Update Archive using the api and update store
   *
   * @param archive
   */
  public updateArchive(archive: Archive) {
    const baseURL = this.appConfigService.config.reporting.url;
    const url = `${baseURL}/reports/${archive.reportId}/archive/${archive.id}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.httpClient.put<Archive>(url, archive, {headers}).subscribe((updatedArchive => {
        this.updateStore(updatedArchive);
        this.isLoading = false;
      })
    );
  }

  /**
   * Updates an archive into the store.
   *
   * @private
   * @param {Archive} archive
   * @memberOf ArchiveService
   */
  private updateStore(archive: Archive): void {
    this.store.dispatch(updateArchive({archive}));
  }

}
