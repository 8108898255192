export function createOptions(context) {
    return {
        credits: {
            enabled: false
        },
        title: '',
        legend: {
            enabled: false
        },
        chart: {
            resetZoomButton: {
                theme: {
                    display: 'none'
                }
            },
            type: 'scatter',
            zoomType: 'xy',
            events: {
                load: context.onChartLoad,
                redraw: context.onChartRedraw,
                selection: context.onChartSelection
            }
        },
        xAxis: {
            title: {
                enabled: true,
                text: context.getAxisLabel('x'),
                y: 15,
                style: {
                    'text-transform': 'uppercase',
                    'fontSize': 12
                }
            },
            tickLength: 30,
            tickWidth: 1,
            startOnTick: false,
            endOnTick: false,
            showLastLabel: true,
            gridLineWidth: 1,
            plotLines: [{
                color: '#EEEEEE',
                width: 4,
                value: 100
            }],
            min: 0,
            allowDecimals: false,
            labels: {
                y: 50,
                style: {
                    'text-transform': 'uppercase',
                    'fontSize': 12
                }
            }
        },
        yAxis: {
            title: {
                enabled: true,
                text: context.getAxisLabel('y'),
                x: -15,
                style: {
                    'text-transform': 'uppercase',
                    'fontSize': 12
                }
            },
            tickLength: 30,
            tickWidth: 1,
            startOnTick: false,
            endOnTick: false,
            showLastLabel: true,
            plotLines: [{
                color: '#EEEEEE',
                width: 4,
                value: 100
            }],
            min: 0,
            allowDecimals: false,
            labels: {
                x: -50,
                style: {
                    'text-transform': 'uppercase',
                    'fontSize': 12
                }
            }
        },
        tooltip: {
            borderRadius: 4,
            useHTML: true,
            style: {
                padding: 0
            },
            hideDelay: 0,
            valueDecimals: 2,
            headerFormat: '',
            pointFormatter: function () {
                return context.getTooltipFormatter(this);
            }
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 7,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    },
                    symbol: 'circle'
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                stickyTracking: false,
                tooltip: {
                    shared: false
                },
                dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    useHTML: false,
                    formatter: function () {
                        return context.getDataLabelsFormatter(this);
                    },
                    style: {
                        fontSize: 11
                    }
                }
            },
            series: {
                point: {
                    events: {
                        click: context.onPointClick
                    }
                },
                events: {
                    mouseOut: context.onMouseOutPoint
                }
            },
        },
        series: context.getSeriesData()
    };

}
