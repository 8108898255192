import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { forecastVolumeEstimateMapData, TreeNode } from '@app/deliverables/forecast-volume-estimate/forecast-volume-estimate-map-data';
import { DeliverableConfiguration } from '@platform/models/deliverable-configuration.model';
import { DeliverableConfigurationService } from '@platform/services/deliverable-configuration.service';
import { DeliverableViewMatTreeService } from '@platform/services/deliverable-view-mat-tree.service';
import { DeliverablesListItem } from '@products/shared/deliverables-list/deliverables-list-item.data.model';

@Component({
  selector: 'ns-volume-estimate-configuration',
  templateUrl: './volume-estimate-configuration.component.html',
  styleUrls: ['./volume-estimate-configuration.component.scss']
})
export class VolumeEstimateConfigurationComponent implements OnInit, OnChanges {

  @Input() deliverablesConfig: DeliverableConfiguration[];
  @Input() reportId: string;
  @Input() dataList: DeliverablesListItem[];
  @Input() mboList: DeliverablesListItem[];
  @Input() tableData: DeliverablesListItem[];
  @Input() formatData: { formatSectionExpanded: boolean, unit: string, noOfDecimals: string };
  @Output() configUpdated: EventEmitter<any>;
  @Output() closeFlyoutMenuEvent = new EventEmitter();
  @Output() moveToPreviousMenuEvent = new EventEmitter();

  expandedRowsStoreCollection: any;
  estimateExpandedRowsStoreCollection: any;
  radioFlags = { formatSectionExpanded: true, millions: true, thousands: false, zero: false, one: true, two: false };

  originalAssumptionOptions = ['Meets Expectations', 'Top', 'Above Average', 'Average', 'Below Average', 'Bottom'];
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  formGroups: any[] = [];

  assumptionOptions = [...this.originalAssumptionOptions]
  assumptionMapData = [
    {
      name: 'productAssumption',
      label: 'report.settings.deliverables.volume.estimate.config.estimate.product.assumption',
    },
    {
      name: 'consumerAssumption',
      label: 'report.settings.deliverables.volume.estimate.config.estimate.consumer.assumption',
    },
    {
      name: 'tradeAssumption',
      label: 'report.settings.deliverables.volume.estimate.config.estimate.trade.assumption',
    },
  ];
  selectedDecimal: any;
  selectedUnit: any;

  constructor(private deliverableConfigurationService: DeliverableConfigurationService,
    private deliverableViewMatTreeService: DeliverableViewMatTreeService) {
    this.configUpdated = new EventEmitter<any>();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataList && this.dataList.length > 0) {
      this.formGroups = [];
      const allProductAssumption = this.dataList.map(e => {
        return e.data.productAssumption;
      });
      const allConsumerAssumption = this.dataList.map(e => {
        return e.data.consumerAssumption;
      });
      const allTradeAssumption = this.dataList.map(e => {
        return e.data.tradeAssumption;
      });
      const customAssumptions = [...new Set([...allProductAssumption, ...allConsumerAssumption, ...allTradeAssumption])]
        .filter(d => d && d.trim() != "")
        .sort((a, b) => { return a > b ? 1 : (b > a ? -1 : 0) });
      this.assumptionOptions = [...new Set([...this.originalAssumptionOptions, ...customAssumptions])];
      this.dataList.forEach(estimate => {
        const formGroup = new FormGroup({
          productAssumption: new FormControl(this.getValue(estimate?.data?.productAssumption), [Validators.required]),
          consumerAssumption: new FormControl(this.getValue(estimate?.data?.consumerAssumption), [Validators.required]),
          tradeAssumption: new FormControl(this.getValue(estimate?.data?.tradeAssumption), [Validators.required]),
        });

        this.formGroups.push(
          {
            id: estimate.id,
            formGroup: formGroup,
            filteredAssumptionOptions: {
              productAssumption: [...this.assumptionOptions],
              consumerAssumption: [...this.assumptionOptions],
              tradeAssumption: [...this.assumptionOptions]
            }
          }
        );

        this.assumptionMapData.forEach(assumption => {
          formGroup.controls[assumption.name].valueChanges.subscribe(value => {
            const filteredAssumptionOptions = this.filterListForAutoComplete(
              value || '',
              this.assumptionOptions,
            );
            this.setfilteredAssumptionOptions(assumption.name, estimate, filteredAssumptionOptions)
          });
        });

      });
    }
  }

  ngOnInit(): void {

    this.expandedRowsStoreCollection = this.deliverableViewMatTreeService.forecastVolumeEstimateConfigurationExpandedTableRows;
    this.estimateExpandedRowsStoreCollection = this.deliverableViewMatTreeService.forecastVolumeEstimateConfigurationExpandedEstimateRows;
    if (!this.tableData) {
      this.tableData = this.initializeTableData(forecastVolumeEstimateMapData)
    }
    if (!this.mboList) {
      this.mboList = [{
        id: 'mbo',
        displayName: 'Minimun Business Objective (MBO)',
        originalName: 'Minimun Business Objective (MBO)',
        isVisible: true,
        position: 0,
        originalPosition: 0,
        children: [],
      }];
    }
    if (!this.formatData) {
      this.formatData = { formatSectionExpanded: true, unit: 'millions', noOfDecimals: 'one' };
    }
    let deliverableConfigurationsInReport: any[];
    if (!(this.deliverablesConfig && this.deliverablesConfig.length > 0)) {
      this.deliverablesConfig = [];
    }
    deliverableConfigurationsInReport = JSON.parse(JSON.stringify(this.deliverablesConfig));
    if (!deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE)) {
      deliverableConfigurationsInReport.push({
        reportId: this.reportId,
        name: this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE,
        config: { 'mboList': this.mboList, 'tableData': this.tableData, 'estimates': [], 'formatData': this.formatData }
      });
      this.deliverableConfigurationService.isDeliverablesConfigReGenerated = false;
    }
    if (!deliverableConfigurationsInReport.find(c => c.name === this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE).config.formatData) {
      deliverableConfigurationsInReport.find(c => c.name === this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE).config.formatData = { formatSectionExpanded: true, unit: 'millions', noOfDecimals: 'one' };
      this.deliverableConfigurationService.isDeliverablesConfigReGenerated = false;
    }
    this.deliverableConfigurationService.updateConfigForForecastEstimate(deliverableConfigurationsInReport, this.reportId);
  }

  initializeTableData(mapData: TreeNode[]): DeliverablesListItem[] {
    const tableData: DeliverablesListItem[] = []
    mapData.forEach((element, index) => {
      const tableElement: DeliverablesListItem = {
        id: element.key,
        displayName: element.name,
        originalName: element.name,
        isVisible: true,
        position: index,
        originalPosition: index,
        children: [],
      };
      if (element.children && element.children.length > 0) {
        tableElement.children = this.initializeTableData(element.children);
      }
      tableData.push(tableElement);
    });
    return tableData;
  }

  assumptionAutocompleteOpened(assumption, item, trigger: any) {
    const filteredAssumptionOptions = [...this.assumptionOptions];
    this.setfilteredAssumptionOptions(assumption, item, filteredAssumptionOptions)
  }

  isDeliverableFromForecasting() {
    return this.deliverableConfigurationService.isVolumeDeliverableFromForecasting?.toString();
  }

  filterListForAutoComplete(value: string, filterFromData: string[]): string[] {
    const filterValue = value.toLowerCase();
    return filterFromData.filter(option => option.toLowerCase().includes(filterValue));
  }

  assumptionSelected(event: any, formControlName: string, item: DeliverablesListItem) {
    let assumption;
    const formGroup = this.getFormGroup(item);
    if (event) {
      if (event.option && event.option.value) {
        assumption = event.option.value.trim();
      } else if (event.target && event.target.value) {
        assumption = event.target.value.trim();
      }
    }
    if (formGroup) {
      formGroup.controls[formControlName].setValue(assumption);
      if (!item.data) {
        item.data = {};
      }
      if (assumption)
        item.data[formControlName] = assumption;
    }
  }

  clearData(formControlName: string, item: DeliverablesListItem) {
    const formGroup = this.getFormGroup(item);
    formGroup.controls[formControlName].setValue(null);
  }

  getFormGroup(item: DeliverablesListItem): FormGroup {
    return this.formGroups.find(fg => fg.id == item.id)?.formGroup;
  }

  getfilteredAssumptionOptions(formControlName: string, item: DeliverablesListItem) {
    if (this.formGroups?.find(fg => fg.id == item.id)?.filteredAssumptionOptions && this.formGroups.find(fg => fg.id == item.id)?.filteredAssumptionOptions[formControlName])
      return this.formGroups.find(fg => fg.id == item.id).filteredAssumptionOptions[formControlName];
    return [...this.assumptionOptions];
  }

  setfilteredAssumptionOptions(formControlName: string, item: DeliverablesListItem, filteredAssumptionOptions) {
    this.formGroups.find(fg => fg.id == item.id).filteredAssumptionOptions[formControlName] = filteredAssumptionOptions;
  }

  getValue(value: string) {
    return value ? value : '--';
  }

  getFormControl(formControlName: string, item: DeliverablesListItem) {
    const formGroupControls = this.getFormGroup(item)?.controls;
    return formGroupControls ? formGroupControls[formControlName] : null;
  }

  valueChanged(key: string, value: string) {
    switch (key) {
      case 'unit':
        this.formatData.unit = value;
        break;
      case 'decimal':
        this.formatData.noOfDecimals = value;
        break;
      case 'expand':
        this.formatData.formatSectionExpanded = !this.formatData.formatSectionExpanded;
        break;
    }
    this.configUpdated.emit(this.formatData);
  }

  onCloseButtonClick(): void {
    this.closeFlyoutMenuEvent.emit();
  }

  returnBack(): void {
    this.moveToPreviousMenuEvent.emit();
  }

  @HostListener('document:click', ['$event']) clickOut(event) {
    if (event.target.className.includes('mat-drawer-backdrop')) {
      event.stopPropagation();
      event.preventDefault();
      this.closeFlyoutMenuEvent.emit();
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
    if (event.keyCode === 27) {
      this.closeFlyoutMenuEvent.emit();
    }
  }
}
