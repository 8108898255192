/**
 * Compare view for Factors For Success
 *
 * @export
 * @enum {string}
 */
export enum CompareView {
 /**
   * Concept deliverable view type.
   */
  CONCEPT = 'concept',

  /**
   * subgroup deliverable view type.
   */
  SUBGROUP = 'subgroup'
}
