import {Component, ContentChild, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDrawerMode, MatSidenav} from "@angular/material/sidenav";
import {Connectable, connectable, Subject} from "rxjs";

/**
 * `<ns-flyout-menu> </ns-flyout-menu>` component creates Flyout.
 * @example
 * <ns-flyout
 * [flyoutEvents]="flyoutEvents">
 * <ng-template #sidenavContent> CONTENT HERE </ng-template>
 * <ng-template #sidenav> NAVIGATION MENU HERE </ng-template>
 * </ns-flyout>
 */
@Component({
    selector: 'ns-flyout-menu',
    templateUrl: './flyout-menu.component.html',
    styleUrls: ['./flyout-menu.component.scss']
})
export class FlyoutMenuComponent implements OnInit {

    /**
     * nsFlyout - element ref for Flyout input.
     */
    @ViewChild('nsFlyout') nsFlyout: MatSidenav;

    /**
     * flyoutEvents - Events the component will listen to and react to.
     */
    @Input() flyoutEvents: Subject<string>;

    /**
     * mode - Provides the mode to open Flyout.
     */
    @Input() mode: MatDrawerMode = 'over';

    /**
     * layoutTypeClass - Provides the layout to open Flyout.
     * Options : 'vertical' or 'horizontal' Default set to 'vertical'
     */
    @Input() layoutTypeClass: string = 'vertical';

    /**
     * layoutTypeClass - Provides the position to open Flyout start = left of screen and end = right of the screen.
     * Options : 'start' or 'end' Default set to 'end'
     */
    @Input() position: 'start' | 'end' = 'end';

    @Input() fixedTopGap: number = 100;

    @Input() fixedInViewport: boolean = true;

    /**
     * hasBackdrop - Important property to have backdrop for trigger to external click events.
     * Note: No change required for this value
     */
    public hasBackdrop: boolean = true;

    @ContentChild('sidenav') sidenav: TemplateRef<any>;

    @ContentChild('sidenavContent') sidenavContent: TemplateRef<any>;

    multicastedFlyoutEvents: Connectable<string>;

    ngOnInit(): void {
        const subject = new Subject<string>();
        this.multicastedFlyoutEvents = connectable(this.flyoutEvents, {
            connector: () => subject
        });
        this.multicastedFlyoutEvents.subscribe((evnt) => {
            switch (evnt) {
                case 'open':
                    this.nsFlyout.open();
                    break;
                case 'close':
                    this.nsFlyout.close();
                    break;
            }
        })
        this.multicastedFlyoutEvents.connect();
    }

}