<div class="benchmark-container">
  <div class="tool-bar">
    <button mat-icon-button aria-label="back-button" class="back-btn" (click)="returnBack()">
      <span class="sif sif-arrow-w"></span>
    </button>
    <h2>Import Benchmark Results</h2>
  </div>
  <div class="sticky-header-container header-filter-container">
    <div class="sticky-header-container">
      <div class="tool-bar-container align-items-center">
        <ns-benchmark-filters-section
          (benchmarkList)="benchmarkList = $event;searchTriggerd()" (addToBenchmarkResultList)="appendResultAfterPagination($event)"></ns-benchmark-filters-section>
      </div>
    </div>
  </div>

  <ng-container #emptyPageContainer *ngIf="isEmptyVisible == true">
    <ns-empty-page [leadText]="emptyResultMessages.leadText" [supportText]="emptyResultMessages.supportText" type="empty">
    </ns-empty-page>
  </ng-container>

  <ng-container #searchResultContainer *ngIf="isResultsVisible == true">
    <div class="scroll-area-search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false" [alwaysCallback]="true" (scrolled)="onScroll()" >
        <ng-container *ngFor="let benchmark of benchmarkList">
          <ns-add-benchmark-concepts [report]="benchmark" [importedConceptIds]="importedConceptsId" [isExpanded]="benchmark.isExpanded"
                                    [preExistingBenchmarks]="benchmarks" (conceptsToggled)="conceptsToggled($event)" (reportToggled)="reportToggled($event)">
          </ns-add-benchmark-concepts>
      </ng-container>
    </div>
  </ng-container>

  <ng-container #importedBenchmarksContainer *ngIf="isImportedVisible == true">
    <div class="warning-container">
      <bases-prompt secondary color="warn"
        [showPrompt]="isNonAccessibleReportWarning"
        [messageList]="nonAccessibleReportWarningList"
        [isExpanded]="false" [hideDismiss]="true"
        [headerText]="nonAccessibleReportWarningHeader">
        <ng-container actions>
          <button mat-button small color="primary" (click)="redirectToRequestAccess()" matTooltip="Opens the Project Access page in a new tab" matTooltipClass="center-tooltip">REQUEST ACCESS</button>
        </ng-container>
    </bases-prompt>
    </div>

      <div *ngFor="let benchmark of benchmarksListBybenchmarkReportId">
        <ns-imported-benchmark [benchmarkReportId]="benchmark" [benchmarks]="benchmarks" (reportIdEmitted)="onReportIdEmitted($event)"></ns-imported-benchmark>
      </div>
  </ng-container>

  <div class="controller-btn" *ngIf="benchmarkList?.length > 0">
    <button mat-button [disabled]="(selectedConceptList.length == 0)" class="save-button"
      (click)="importBenchMark()">Import Concept</button>
    <button mat-button class="cancel-btn" (click)="returnBack()">Cancel</button>
  </div>
</div>
