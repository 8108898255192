import { Action, createReducer, on } from '@ngrx/store';
import { ViewMetaInfoState } from '@platform/store/state/view-meta-info.state';
import { addViewMetaInfo, updateViewMetaInfo } from '@platform/store/actions/view-meta-info.actions';

const initialState: ViewMetaInfoState = {};
/**
 * Reducer for all view meta info actions.
 */
const viewMetaInfoReducer = createReducer(
  initialState,
  on(addViewMetaInfo, (state, { viewMetaInfo }) => {
    const newViewMetaInfo = {...state};
    newViewMetaInfo[viewMetaInfo.deliverableType] = viewMetaInfo;
    return newViewMetaInfo;
  }),
  on(updateViewMetaInfo, (state, { viewMetaInfo }) => {
    const newViewMetaInfo = {...state};
    newViewMetaInfo[viewMetaInfo.deliverableType] = viewMetaInfo;
    return newViewMetaInfo;
  })
);

/**
 * ViewMetaInfo `NgRx` reducer.
 *
 * @export
 * @param {ViewMetaInfoState} state
 * @param {ViewMetaInfoActions} action
 * @returns {ViewMetaInfoState}
 */
export function reducer(state: ViewMetaInfoState | undefined, action: Action): ViewMetaInfoState {
  return viewMetaInfoReducer(state, action);
}
