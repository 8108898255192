import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';
import {StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {StrengthWatchoutsDeliverableView} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {ClickDataService} from '@app/deliverables/strengths-watchouts/click-data/click-data.service';
import {ConceptService} from '@platform/services/concept.service';

@Component({
    selector: 'sw-click-data',
    templateUrl: './click-data.component.html',
    styleUrls: ['./click-data.component.scss']
})
export class ClickDataComponent implements OnInit, OnDestroy {

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberOf ClickDataComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * Strength and Watch outs filter object.
     *
     * @type {StrengthWatchoutsFilter}
     * @memberof ClickDataComponent
     */
    public filter: StrengthWatchoutsFilter;

    /**
     * The deliverable view object
     *
     * @type {StrengthWatchoutsDeliverableView}
     * @memberOf ClickDataComponent
     */
    public strenghtWatchouts: StrengthWatchoutsDeliverableView;

    public currentZone = '';

    @Input() aiZoneSummaries:boolean = false;

    @Input() aiSubgroupSummaries:any = null;

    /**
     * Creates an instance of AttributesComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {StrengthWatchoutsService} strengthWatchoutsService
     * @param {ClickDataService} clickDataService
     * @param {ConceptService} conceptService
     * @param {ElementRef} elementRef
     * @memberof ClickDataComponent
     */
    constructor(
        private strengthWatchoutsService: StrengthWatchoutsService,
        private clickDataService: ClickDataService,
        private conceptService: ConceptService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize component and load data
     *
     * @memberof ClickDataComponent
     */
    ngOnInit(): void {
        const strengthWatchouts$ = this.strengthWatchoutsService.getStrengthWatchouts();
        const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
        const concepts$ = this.conceptService.getReportConcepts();
        const subscription = combineLatest([strengthWatchouts$, filter$, concepts$]).subscribe(([strengthWatchouts, filter, concepts]) => {
            this.filter = this.strengthWatchoutsService.updateFilterOptions(filter);
            // update the strengthWatchoutsDeliverableView to ensure only concepts with the selected locale are displayed.
            const filteredStrengthWatchout = this.strengthWatchoutsService.updateConceptsByLocale(filter, strengthWatchouts);
            this.strenghtWatchouts = this.clickDataService.getClickData(filteredStrengthWatchout, concepts);
        });
        this.subscriptions.push(subscription);
    }

    /**
     *
     * @param index
     * @param item
     * @returns
     */
    identify(index, item) {
        return item.label;
    }

    /**
     * Cleanup hook.
     *
     * @memberof ClickDataComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
