import { Injectable } from '@angular/core';
import { GapAnalysisDeliverableView, Statement } from '@products/restage/gap-analysis/models/gap-analysis.model';
import { GapAnalysisFilter } from '@products/restage/gap-analysis/models/filter.model';
import {
  BarChartData,
  GapAnalysisChartData,
  StatementChartData, YAxisRange
} from '@products/restage/gap-analysis/models/gap-analysis-chart-data.model';
import { GapAnalysisConceptType } from '@products/restage/gap-analysis/models/gap-analysis-concept-type-enum';

@Injectable({
  providedIn: 'root'
})
export class GapAnalysisChartService {

  constructor() { }

  /**
   * Returns gap analysis chart data.
   *
   * @param {GapAnalysisDeliverableView} gapAnalysisDeliverableView
   * @param {GapAnalysisFilter} filter
   * @returns GapAnalysisChartData
   */
  public prepareGapAnalysisChartData(gapAnalysisDeliverableView: GapAnalysisDeliverableView, filter: GapAnalysisFilter): GapAnalysisChartData {
    let yMaxValue = 0;
    const currentValuesArray = [];

    const selectedSubgroup = filter.subgroups.find(subgroup => subgroup.isSelected);

    const gapAnalysisChartData: GapAnalysisChartData = new GapAnalysisChartData();
    const [restageConcepts, restageChartSubGroupPositions] = this.getRestageConceptsAndPositions(filter.concepts);

    const statementGroups = [];
    const statementGroupDataArray = [];

    const statements = gapAnalysisDeliverableView.statements;
    this.sortStatements(statements);
    statements.forEach((statement) => {
      if (statement.statementData.length) {
        statementGroups.push(statement.label); // groups array
        const statementData = statement.statementData;
        const statementDataMatches = statementData.filter((dataMatch) => dataMatch.segmentId === selectedSubgroup.id);

        const restageGroupDataArray = [];
        restageConcepts.forEach((restageConcept, index) => {
          const subGroupData = statementDataMatches.find((eachStatementData) => {
            return eachStatementData.conceptType === GapAnalysisConceptType.RESTAGE && eachStatementData.position === restageConcept.position;
          });

          const barChartData = this.createBarChartData(restageConcept, subGroupData, index, filter);

          // calculating maximum y value to show on axis
          yMaxValue = barChartData.value > yMaxValue ? barChartData.value : yMaxValue;
          restageGroupDataArray.push(barChartData);
        });

        const statementLineChartValue = statement.currentStatementValue * 100;

        // current Array
        currentValuesArray.push(statementLineChartValue);

        const statementGroupData = this.createStatementGroupData(statement.label, restageGroupDataArray, statementLineChartValue);
        statementGroupDataArray.push(statementGroupData);
      }
    });

    // setting y axis values
    gapAnalysisChartData.yAxisRange = this.createYAxisRange(yMaxValue, currentValuesArray, gapAnalysisChartData.lineChartValue);

    gapAnalysisChartData.restageSubGroups = restageChartSubGroupPositions;
    gapAnalysisChartData.statementGroups = statementGroups;
    gapAnalysisChartData.statementGroupsChartData = statementGroupDataArray;

    return gapAnalysisChartData;
  }

  /**
   * Setting yAxis Range Values and bumo min/max values to show on the chart
   * @param yMaxValue
   * @param currentValuesArray
   * @param lineChartValue
   * @private
   */
  private createYAxisRange(yMaxValue: number, currentValuesArray, lineChartValue: number) {
    const yAxisRange = new YAxisRange();
    yAxisRange.yMaxValue = yMaxValue;

    // Current BUMO Min
    if (currentValuesArray.length && currentValuesArray[currentValuesArray.length - 1] < 100) {
      yAxisRange.yCurrentMinValue = currentValuesArray[currentValuesArray.length - 1];
    } else {
      yAxisRange.yCurrentMinValue = lineChartValue - 10;
    }
    // Current BUMO MAX
    if (currentValuesArray.length && currentValuesArray[0] > 100) {
      yAxisRange.yCurrentMaxValue = currentValuesArray[0];
    } else {
      yAxisRange.yCurrentMaxValue = lineChartValue + 10;
    }
    return yAxisRange;
  }

  /**
   *
   * @param label
   * @param restageGroupDataArray
   * @param statementLineChartValue
   * @returns StatementChartData
   */
  private createStatementGroupData(label, restageGroupDataArray, statementLineChartValue: number): StatementChartData {
    const statementChartData: StatementChartData = new StatementChartData();
    statementChartData.statementGroupName = label;
    statementChartData.restageGroupsData = restageGroupDataArray;
    statementChartData.statementValue = statementLineChartValue;
    return statementChartData;
  }

  /**
   *
   * @param restageConcept
   * @param restageSubGroupData
   * @param index
   * @param filter
   * @returns BarChartData
   */
  private createBarChartData(restageConcept, restageSubGroupData, index, filter: GapAnalysisFilter): BarChartData {
    const barChartData = new BarChartData();
    if (restageSubGroupData) {
      barChartData.id = restageSubGroupData.conceptId;
      barChartData.barName = restageConcept.name;
      barChartData.value = filter.show.meanIndex ? restageSubGroupData.conceptMeanValue * 100 : filter.show.topBoxIndex ?
                           restageSubGroupData.conceptTopBoxValue * 100 : restageSubGroupData.conceptTopTwoValue * 100;
    } else {
      barChartData.id = index;
      barChartData.barName = '';
      barChartData.value = 0;
    }
    barChartData.restageGroupPosition = restageConcept.position;
    return barChartData;
  }

  /**
   * get restage concepts and restage positions respective to each bar
   * restageSubGroupPositions are dynamic as series need to be updated on concepts filters
   * @returns array of restage concepts and restage positions
   */
  private getRestageConceptsAndPositions(concepts): any[] {
    const restageChartSubGroupPositions = [];
    const restageConcepts = concepts.filter((concept) => {
      // series is made of restage concept positions
      if (concept.position > 0 && concept.isSelected) {
        restageChartSubGroupPositions.push(concept.position);
        return concept;
      }
    });
    return [restageConcepts, restageChartSubGroupPositions];
  }

  /**
   * sorts statements based on currentMeanStatementValue that is conceptMeanValue of conceptType current
   * sorts in desc order
   *
   * @param statements
   * @private
   */
  private sortStatements(statements: Array<Statement>) {
    return statements.sort(function (a, b) {
      return b.currentStatementValue - a.currentStatementValue;
    });
  }
}
