import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }


  /*
  * opens toaster with passed params
  */
 openSnackBar(message: string, className: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 3000,
      panelClass: className
    });
  }
}
