import { switchMap, skipWhile, take } from 'rxjs/operators';
import { AppConfigService } from '@app/core/config/app-config.service';
import { HeartBeatService } from '@basesstudio/ngx-components';
import { interval, of } from 'rxjs';

/**
 * Heartbeat service implementation.
 */
 export class HeartBeatServiceImpl implements HeartBeatService {

  constructor(private appConfigService: AppConfigService) { }

  /**
   * Returns hearbeat url promise.
   *
   * @returns heartbeat url
   */
  getHeartBeatURL(): string | Promise<string> {
    const promise = interval(1000).pipe(
      skipWhile(() => !this.appConfigService.config),
      take(1),
      switchMap(() => of(this.appConfigService.config.heartbeat.url))
    ).toPromise();
    return promise;
  }
}
