import { DeliverableType } from "@app/deliverables/deliverable-type.enum";
import { DeliverableViewType } from './deliverable-view-type.enum';
import { CompareView } from './compare-view-enum';
/**
 * Default filter values for Correlations.
 *
 * @export
 * @property
*/
export const defaultCorrelationsFilter = {
  deliverableType: DeliverableType.CORRELATIONS.type,
  deliverableViewType: DeliverableViewType.DATA_TABLE,
  compare: [
    {
      name: 'Data Table',
      id: CompareView.DATA_TABLE,
      isSelected: true,
      position: 1
    },
    {
      name: 'Quad Map',
      id: CompareView.QUAD_MAP,
      isSelected: false,
      position: 1
    }],

  show: {
    allKeyMeasures: true,
    keyMeasures: [],
    tertile: true,
    tertileMean: {
      isSelected: false,
      noDecimal: false,
      oneDecimal: false,
      twoDecimal: false,
    },
    aboveAverage: true,
    average: true,
    belowAverage: true
  }
};
