import {AfterViewInit, Component, Input, NgZone, OnChanges, SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Options} from 'highcharts';

declare var require: any;

const Wordcloud = require('highcharts/modules/wordcloud');
Wordcloud(Highcharts);

const greyScale = [
    '#374052',
    '#5C6674',
    '#858C96',
    '#ADB1B9',
    '#D5D8DD'
];

/**
 * `WordCloudMapComponent` creates the word cloud map image
 *
 * @example
 *  <ns-wordcloud-map [wordCount]="wordCount" [elementId]="conceptId"></ns-wordcloud-map>
 *
 * @export
 * @class WordCloudMapComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'ns-wordcloud-map',
    templateUrl: './wordcloud-map.html',
    styleUrls: ['./wordcloud-map.component.scss']
})
export class WordCloudMapComponent implements OnChanges, AfterViewInit {

    /**
     * Array of words, their weight and color to be presented in the word cloud
     *
     * @property
     * @type {Array<object>}
     * @memberof WordCloudMapComponent
     */
    public words: Array<object>;

    /**
     * Array of words and their weight collected by the deliverables input
     *
     * @property
     * @Input()
     * @type {Array<[String, number]>}
     * @memberof WordCloudMapComponent
     */
    @Input() wordCount: Record<string, number>;

    /**
     * elementId is unique id supplied by the conceptId or subgroupId
     *
     * @property
     * @Input()
     * @type {number}
     * @memberof WordCloudMapComponent
     */
    @Input() elementId: number;

    /**
     * High Chart Options
     *
     * @property
     * @type {Options}
     * @memberof WordCloudMapComponent
     */
    public options: Options;

    /**
     * Creates an instance of WordCloudMapComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {NgZone} ngZone
     * @member WordCloudMapComponent
     */
    constructor(private ngZone: NgZone) {
    }

    /**
     * Observe wordCount input changes.
     *
     * @member WordCloudMapComponent
     * @param {SimpleChanges} changes all changes detected by angular
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.wordCount.firstChange) {
            this.buildWordCloud();
        }
    }

    ngAfterViewInit(): void {
        this.buildWordCloud();
    }

    /**
     * Builds the word cloud using high charts inside the selector node
     *
     * @member WordCloudMapComponent
     */
    buildWordCloud(): void {
        this.ngZone.runOutsideAngular(() => {
            this.words = this.getWordsData(this.wordCount);
            this.options = {
                chart: {
                    height: 550,
                    spacingLeft: 100,
                    spacingRight: 100,
                    spacingTop: 80,
                },
                series: [{
                    type: 'wordcloud',
                    data: this.words,
                    name: 'Occurrences',
                    rotation: {
                        from: 0,
                        to: 0
                    },
                    minFontSize: 5,
                    style: {
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'inherit'
                    },
                    enableMouseTracking: false,
                }],
                tooltip: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
            };
            Highcharts.chart(`wordcloud-map-${this.elementId}`, this.options);
        });
    }

    /**
     * convert array of words and their appearance count to array of objects to be consumed by the word cloud map (objects with text, weight and color properties)
     *
     * @param {Array<[String, number]>} data is array of words and the number of occurrences to be presented on the word cloud map
     * @memberof WordCloudMapComponent
     */
    private getWordsData(data: object, wordsPerCloud = 50): Array<object> {
        const words: Array<object> = [];
        if (data) {
            const filteredWords = Object.entries(data)
                .sort((a, b) => b[1] - a[1])
                .slice(0, wordsPerCloud);
            filteredWords.forEach((entry, index) => {
                words.push({
                    name: entry[0],
                    weight: entry[1],
                    color: greyScale[this.colorPicker(index)]
                });
            });
        }
        return words;
    }

    colorPicker(index) {
        return Math.floor(index / 10);
    }

}
