import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

/**
 * highlight words in a line of text.
 *
 * @example
 * [innerHTML]="text | highlight : searchText"
 */
@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }

  /**
   * Highlight the search text in the value provided.
   * @param value The string to search for given text.
   * @param searchText The text to highlight.
   * @return The html for the value with highlighted text if search text exists.
   */
  transform(value: string, searchText: string): any {
    if (!searchText) {
      return value;
    }
    const re = new RegExp(searchText, 'gi');  // 'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    const html = value.replace(re, `<span class="padding-0 search-text" style="background-color: #FFF8AE">${searchText}</span>`);
    return this.sanitized.bypassSecurityTrustHtml(html);
  }

}
