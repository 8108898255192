import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';
import {StrengthWatchoutsConcept, Summary} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {FilterItem} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {TranslateService} from '@ngx-translate/core';
import { UserInfo } from '@platform/models/user-info.model';
import { UserService } from '@platform/services/user.service';

@Component({
    selector: 'sw-verbatims-summary',
    templateUrl: './verbatims-summary.component.html',
    styleUrls: ['./verbatims-summary.component.scss']
})

export class VerbatimsSummaryComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * The strength and watchout concept data.
     * @type {StrengthWatchoutsConcept}
     * @memberOf VerbatimsSearchComponent
     */
    @Input() concept: StrengthWatchoutsConcept;

    public summaryDetails: Summary;

    public summaryText: String;

    public imageHeight: String;

    public mainEmptyStateMessage = this.translate.instant('shared.deliverables.strengthsWatchouts.verbatims.concept.summary.empty.state');

    public secondaryEmptyStateMessage = this.translate.instant('shared.deliverables.strengthsWatchouts.verbatims.concept.summary.empty.secondary.state');

    imageBasePath = 'assets/images/deliverables';
    public imagePath = `${this.imageBasePath}/ai-summaries-enabled-pattern.svg`;
    private subscriptions: Array<Subscription> = [];

    /**
     * Currently logged in user.
     *
     * @type {UserInfo}
     * @memberof ReportComponent
     */
    public userInfo: UserInfo;

    public isAISummaryFeatureEnabled: boolean = false;

    /**
     * Contructor for services used in this class
     * @param strengthWatchoutsService
     * @param translate
     */
    constructor(private strengthWatchoutsService: StrengthWatchoutsService,
                private translate: TranslateService,
                private userService: UserService) {
    }

    /**
     * ngOnInit
     */
    ngOnInit() {
        this.setDisplayData();
    }

    /**
     * ngOnChanges
     */
    ngOnChanges() {
        this.setDisplayData();
    }

    /**
     * display of concept summary data based on filter changes
     */
    setDisplayData() {
        const userInfoSubscription = this.userService.getUser().subscribe(result => {
            this.userInfo = result;
            this.isAISummaryFeatureEnabled = this.userInfo.featureFlags.includes('REPORTING_AI_SUMMARIES');
        });
        const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
        const filterSubscription = filter$.subscribe((filter) => {
            const selectedClickOption = filter.show.options.filter(it => it.isSelected && it.id !== 'zones');
            const selectedLocal = filter.show.languages.find(it => it.isSelected);
            const selectedSubgroup = filter.subgroups.find(it => it.isSelected);
            this.summaryText = this.getConceptSummary(this.concept, selectedSubgroup, selectedClickOption, selectedLocal);
        });
        this.subscriptions.push(filterSubscription);
        this.subscriptions.push(userInfoSubscription);
        this.imageHeight = this.concept && this.concept.imageHeight == 450 ? "Landscape":(this.concept && this.concept.imageHeight == 540 ? "Widescreen" : "Portrait");
    }

    /**
     * fetch the correct summary data from the concept data
     * @param conceptData
     * @param subgroup
     * @param clickOption
     * @param local
     */
    getConceptSummary(conceptData, subgroup, clickOption, local) {
        if (this.concept.locale === local.id && this.concept.summary) {
            this.summaryDetails = this.concept.summary.find(it => it.segmentId === subgroup.id);
            return this.getClickWiseSummary(this.summaryDetails, clickOption);
        }
    }

    /**
     * fetch the correct summary text based on clicks likes,dislikes,neutral or total
     * @param summary
     * @param clickOption
     */
    getClickWiseSummary(summary: Summary, clickOption: Array<FilterItem>) {
        let summaryData: string;
        if (clickOption.length == 0) {
            summaryData = null;
        } else if (clickOption.length > 1) {
            summaryData = summary?.totalSummary;
        } else {
            const clickSelected = clickOption ? clickOption[0].id : null;
            switch (clickSelected) {
                case 'likes':
                case '1':
                    summaryData = summary?.likeSummary;
                    break;
                case 'dislikes':
                case '0':
                    summaryData = summary?.dislikeSummary;
                    break;
                case 'nuetral':
                case '2':
                    summaryData = summary?.neutralSummary;
                    break;
                default:
                    summaryData = null;
            }
        }
        return summaryData;

    }

    /**
     * destroy subscription
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

}
