<ns-dropdown-menu [label]="filterLabel" [label]="filterLabel" rel="tooltip" matTooltipPosition="above" matTooltip="{{tooltipLabel}}">
  <section class="dropdown-filter-menu">
    <ul class="dropdown-menu-list">
      <h3 class="menu-title">{{ 'quick.predict.deliverables.financial-potential.filter.label.metrics' | translate }}</h3>
        <div>
          <mat-radio-group  [ngModel]="getSelectedMeasureTypeName()" (change)="changeAndUpdateType($event)" >
            <li class="list-item"><mat-radio-button color="primary" [value]="filterNames.topBox" >{{ 'quick.predict.deliverables.financial-potential.filter.label.top.box' | translate }}</mat-radio-button></li>
            <li class="list-item"><mat-radio-button color="primary" [value]="filterNames.topTwoBox" >{{ 'quick.predict.deliverables.financial-potential.filter.label.top.two.box' | translate }}</mat-radio-button></li>
            <li class="list-item"><mat-radio-button color="primary" [value]="filterNames.allFiveBoxes" >{{ 'quick.predict.deliverables.financial-potential.filter.label.all.five.boxes' | translate }}</mat-radio-button></li>
          </mat-radio-group>
        </div>
    </ul>
  </section>
  <section class="dropdown-filter-menu">
      <h3 class="menu-title">{{ 'quick.predict.deliverables.financial-potential.filter.label.options' | translate }}</h3>
      <div class="menu-list-content" (click)="$event.stopPropagation()">
        <mat-checkbox id="statTesting" color="primary" rounded flat class="list-item" [value]="filterNames.statTesting"  (change)="toggleSelection(filterNames.statTesting)" [checked]="filter.show.statTesting" noink>{{ 'quick.predict.deliverables.financial-potential.filter.label.stat.testing' | translate }}</mat-checkbox>
        <mat-checkbox id="dataLabels" color="primary" [disabled]="disableDataLabel" rounded flat class="list-item" [value]="filterNames.dataLabels" [checked]="filter.show.dataLabels" (change)="toggleSelection(filterNames.dataLabels)" noink>{{ 'quick.predict.deliverables.financial-potential.filter.label.data.labels' | translate }}</mat-checkbox>
      </div>
  </section>
</ns-dropdown-menu>
