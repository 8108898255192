import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {TargetGroupAnalysisComponent} from "@app/deliverables/target-group-analysis/target-group-analysis.component";
import {FactorsComponent} from "@app/deliverables/factors/factors.component";
import {AdditionalInfoComponent} from "@app/deliverables/additional-info/additional-info.component";
import {WordCloudComponent} from "@app/deliverables/word-cloud/word-cloud.component";
import {ReachAnalysisComponent} from '@app/deliverables/reach-analysis/reach-analysis.component';
import {AttributesComponent} from '@app/deliverables/attributes/attributes.component';
import {CorrelationsComponent} from '@app/deliverables/correlations/correlations.component';
import {FinancialPotentialComponent} from '@app/deliverables/financial-potential/financial-potential.component';
import {StrengthsWatchoutsComponent} from '@app/deliverables/strengths-watchouts/strengths-watchouts.component';
import {SurveyQuestionComponent} from "@app/deliverables/survey-question/survey-question.component";
import {ActivationProfileComponent} from '@app/deliverables/activation-profile/activation-profile.component';
import {PriceMentionsComponent} from '@app/deliverables/price-mentions/price-mentions.component';
import {PriceElasticityComponent} from '@app/deliverables/price-elasticity/price-elasticity.component';
import {ScoreCardComponent} from '@app/deliverables/score-card/score-card.component';
import {GapAnalysisComponent} from '@app/deliverables/gap-analysis/gap-analysis.component';
import {PerformanceComponent} from '@app/deliverables/performance/performance.component';
import {InteractionsComponent} from '@app/deliverables/interactions/interactions.component';
import {SortingMatrixComponent} from '@app/deliverables/sorting-matrix/sorting-matrix.component';

const ConfigurableComponents: { [type: string]: any} = {};
ConfigurableComponents[DeliverableType.ADDITIONAL_INFO.type] = AdditionalInfoComponent;
ConfigurableComponents[DeliverableType.ACTIVATION_PROFILE.type] = ActivationProfileComponent;
ConfigurableComponents[DeliverableType.FACTORS.type] = FactorsComponent;
ConfigurableComponents[DeliverableType.TARGET_GROUP_ANALYSIS.type] = TargetGroupAnalysisComponent;
ConfigurableComponents[DeliverableType.WORD_CLOUD.type] = WordCloudComponent;
ConfigurableComponents[DeliverableType.REACH_ANALYSIS.type] = ReachAnalysisComponent;
ConfigurableComponents[DeliverableType.ATTRIBUTES.type] = AttributesComponent;
ConfigurableComponents[DeliverableType.CORRELATIONS.type] = CorrelationsComponent;
ConfigurableComponents[DeliverableType.FINANCIAL_POTENTIAL.type] = FinancialPotentialComponent;
ConfigurableComponents[DeliverableType.STRENGTH_WATCHOUTS.type] = StrengthsWatchoutsComponent;
ConfigurableComponents[DeliverableType.SURVEY_QUESTION.type] = SurveyQuestionComponent;
ConfigurableComponents[DeliverableType.PRICE_MENTIONS.type] = PriceMentionsComponent;
ConfigurableComponents[DeliverableType.PRICE_ELASTICITY.type] = PriceElasticityComponent;
ConfigurableComponents[DeliverableType.SCORE_CARD.type] = ScoreCardComponent;
ConfigurableComponents[DeliverableType.GAP_ANALYSIS.type] = GapAnalysisComponent;
ConfigurableComponents[DeliverableType.PERFORMANCE.type] = PerformanceComponent;
ConfigurableComponents[DeliverableType.INTERACTIONS.type] = InteractionsComponent;
ConfigurableComponents[DeliverableType.SORTING_MATRIX.type] = SortingMatrixComponent;


export { ConfigurableComponents };
