import { Action, createReducer, on } from '@ngrx/store';
import { ReportState } from '@platform/store/state/report.state';
import { addReport, updateReport } from '@platform/store/actions/report.actions';

/**
 * Reducer for all report actions.
 */
const reportReducer = createReducer(
  null,
  on(addReport, (state, { report }) => {
    const newReport = {...report};
    return newReport;
  }),
  on(updateReport, (state, { report }) => {
    const newReport = {...report};
    return newReport;
  })
);

/**
 * Report `NgRx` reducer.
 *
 * @export
 * @param {ReportState} state
 * @param {ReportActions} action
 * @returns {ReportState}
 */
export function reducer(state: ReportState | undefined, action: Action): ReportState {
  return reportReducer(state, action);
}
