export enum MixpanelLabel {
  insights = 'Reporting-Insights',
  files = 'Reporting-Files',
  concepts = 'Reporting-Concepts',
  deliverables = 'Reporting-Deliverables',
  forecasting = 'Reporting-Forecasting',
  attributes = 'AttributesDeliverable',
  forecastEasyFgaDeliverable = 'ForecastEasyFgaDeliverable',
  forecastVarietySplitDeliverable = 'ForecastVarietySplitDeliverable',
  strengthsWatchouts = 'StrengthsAndWatchoutsDeliverable',
  surveyQuestions = 'SurveyQuestionsDeliverable',
  sortingMatrix = 'SortingMatrixDeliverable',
  reach = 'ReachAnalysisDeliverable',
  finance = 'FinancialPotentialDeliverable',
  factors = 'FactorsForSuccessDeliverable',
  activation = 'ActivationProfilesDeliverable',
  activationLegacy = 'ActivationProfileLegacyDeliverable',
  gapAnalysis = 'GapAnalysisDeliverable',
  performance = 'PerformanceDeliverable',
  interactions = 'InteractionsDeliverable',
  wordCloud = 'WordCloudDeliverable',
  correlations = 'CorrelationsDeliverable',
  targetGroup = 'TargetGroupAnalysisDeliverable',
  scoreCard = 'ScoreCardDeliverable',
  additionalInfo = 'AdditionalInformationDeliverable'
}

export enum MixpanelEvent {
  exportToPPT = 'Export to PPT',
  exportAsPNG = 'Export as PNG',
  exportAsExcel = 'Export as Excel',
  navigateToInsights ='Navigate to Insights',
  navigateToFiles ='Navigate to Files',
  navigateToConcepts ='Navigate to Concepts',
  navigateToDeliverables= 'Navigate to Deliverables',
  leaveReporting = 'Leave reporting',
  navigateDropdown = 'Move to deliverable-Dropdown',
  navigateArrows = 'Move to deliverable-Arrows',
  navigateCard = 'Return to card screen',
  tableSort = 'Sort Table',
  tableHighlightPositive =  'Highlight row green',
  tableHighlightNegative = 'Highlight row red',
  enterTitle= 'Enter title',
  enterDescription= 'Enter description',
  createCancel= 'Cancel create',
  editTitle= 'Edit title',
  editDescription= 'Edit description',
  editCancel= 'Cancel edit',
  shareResults= 'Share results',
  createInsight = 'Create Insight',
  filterCard = 'Filter on card',
  editFilter = 'Edit filters',
  expandRows = 'Expand rows',
  collapseRows = 'Collapse rows',
  factorClick = 'Click on Factor',
  nextConcept = 'Next concept',
  previousConcept = 'Previous concept',
  nextPage = 'Next page',
  previousPage = 'Previous page',
  verbatimSearch = 'Search for verbatim',
  verbatimsExport = 'Export verbatims'
}

export enum MixpanelViews {
  createView  = 'Create view',
  updateView  = 'Change landing view',
  renameView  = 'Rename view',
  changeView  = 'Change view',
  deleteView  = 'Delete view',
}

export enum MixpanelInsights {
  moveCard = 'Move card',
  resizeCard = 'Resize card',
  createConceptInsight = 'Create concepts insight',
  saveInsight = 'Save new insight',
  updateInsight = 'Save edited insight',
  editInsight = 'Edit Insight',
  deleteInsight = 'Delete Insight',
  attachImage = 'Attach image to insight'
}

export enum MixpanelFiles {
   uploadFile = 'Upload file',
   downloadFile = 'Download file',
   deleteFile = 'Delete file',
}

export enum MixpanelSortingMatrix {
  chartZoom = 'Zoom sorting matrix',
  chartReset = 'Reset sorting matrix'
}

export enum MixpanelReach {
  changeChart = 'Change chart type',
  changeCombinations = 'Change number of combinations'
}


export const mixpanelPathType = {
  'attributes': MixpanelLabel.attributes,
  'custom.question': MixpanelLabel.surveyQuestions,
  'survey-question': MixpanelLabel.surveyQuestions,
  'sorting.matrix': MixpanelLabel.sortingMatrix,
  'sorting-matrix': MixpanelLabel.sortingMatrix,
  'strengths.watchouts': MixpanelLabel.strengthsWatchouts,
  'strength-watchouts': MixpanelLabel.strengthsWatchouts,
  'score-cards': MixpanelLabel.concepts,
  'forecasting': MixpanelLabel.forecasting,
  'deliverables': MixpanelLabel.deliverables,
  'insights': MixpanelLabel.insights,
  'report-files': MixpanelLabel.files,
  'reach.analysis': MixpanelLabel.reach,
  'reach-analysis': MixpanelLabel.reach,
  'financial.potential': MixpanelLabel.finance,
  'financial-potential': MixpanelLabel.finance,
  'factors': MixpanelLabel.factors,
  'activation.profiles': MixpanelLabel.activation,
  'activation.profile.legacy': MixpanelLabel.activationLegacy,
  'activation-profile': MixpanelLabel.activation,
  'performance': MixpanelLabel.performance,
  'interactions': MixpanelLabel.interactions,
  'gap-analysis': MixpanelLabel.gapAnalysis,
  'gap.analysis': MixpanelLabel.gapAnalysis,
  'word.cloud': MixpanelLabel.wordCloud,
  'word-cloud': MixpanelLabel.wordCloud,
  'score.card': MixpanelLabel.scoreCard,
  'additional.info': MixpanelLabel.additionalInfo
};
