import { TranslateService } from '@ngx-translate/core';
import { AttributesFilter } from '../models/filter.model';
import { AttributesService } from '../services/attributes.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StringHelper } from '@platform/services/string-helper.service';
import { Subscription } from 'rxjs';

/**
 * `ChartHeaderComponent` creates attributes column header with title,
 * size and range.
 *
 * @example
 *   <ns-attributes-chart-header
 *     [title]="title"
 *     [index]="columnIndex"
 *     [size]="nSize" >
 *   </ns-attributes-chart-header>
 *
 * @param {string} title - The title of the column header.
 * @param {number} index - The index of the column.
 * @param {number} size - The n size.
 *
 * @export
 * @class ChartHeaderComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ns-attributes-chart-header',
  templateUrl: './chart-header.component.html',
  styleUrls: ['./chart-header.component.scss']
})
export class ChartHeaderComponent implements OnInit, OnDestroy {

  /**
   * The title of the column header.
   *
   * @property
   * @type {string}
   * @memberof ChartHeaderComponent
   */
  @Input() title: string;

  /**
   * Index of the current column.
   *
   * @property
   * @type {number}
   * @memberof ChartHeaderComponent
   */
  @Input() index: number;

  /**
   * N size.
   *
   * @property
   * @type {number}
   * @memberof ChartHeaderComponent
   */
  @Input() size: number;

  /**
   * Attributes filter object.
   *
   * @property
   * @type {AttributesFilter}
   * @memberof ChartHeaderComponent
   */
  public filter: AttributesFilter;

  /**
   * Bound object with upper and lower bounds.
   *
   * @property
   * @type {{ lower: string, upper: string}}
   * @memberof ChartHeaderComponent
   */
  public bound: { lower: string, upper: string};

  /**
   * Array of subscriptions for cleanup.
   *
   * @private
   * @type {Array<Subscription>}
   * @memberof ChartHeaderComponent
   */
  private subscriptions: Array<Subscription>;

  /**
   * Translation key for mean lower.
   *
   * @private
   * @type {string}
   * @memberof ChartHeaderComponent
   */
  private meanLowerLocaleKey: string;

  /**
   * Translation key for mean upper.
   *
   * @private
   * @type {string}
   * @memberof ChartHeaderComponent
   */
  private meanUpperLocaleKey: string;

  /**
   * Translation key for percents lower.
   *
   * @private
   * @type {string}
   * @memberof ChartHeaderComponent
   */
  private percentsLowerLocaleKey: string;

  /**
   * Translation key for percents upper.
   *
   * @private
   * @type {string}
   * @memberof ChartHeaderComponent
   */
  private percentsUpperLocaleKey: string;

  /**
   * Creates an instance of ChartHeaderComponent.
   *
   * @constructor
   * @param {FormatterService} formatterService
   * @param {AttributesService} attributesService
   * @param {TranslateService} translate
   * @memberof ChartHeaderComponent
   */
  constructor(
    private stringHelper: StringHelper,
    private attributesService: AttributesService,
    private translate: TranslateService
  ) {
    this.subscriptions = [];
    this.meanLowerLocaleKey = 'shared.deliverables.attributes.filter.label.mean.lower';
    this.meanUpperLocaleKey = 'shared.deliverables.attributes.filter.label.mean.upper';
    this.percentsLowerLocaleKey = 'shared.deliverables.attributes.filter.label.percents.lower';
    this.percentsUpperLocaleKey = 'shared.deliverables.attributes.filter.label.percents.upper';
  }

  /**
   * Returns label alphabet from index.
   *
   * @returns {string}
   * @memberof ChartHeaderComponent
   */
  getColumnLabel(): string {
    const index = this.index;
    return this.stringHelper.alphabetSeries(index);
  }

  /**
   * Initialize the component.
   *
   * @memberof ChartHeaderComponent
   */
  ngOnInit(): void {
    const filter$ = this.attributesService.getAttributesFilter();
    const meanLowerLabel = this.translate.instant(this.meanLowerLocaleKey);
    const meanUpperLabel = this.translate.instant(this.meanUpperLocaleKey);
    const percentsLowerLabel = this.translate.instant(this.percentsLowerLocaleKey);
    const percentsUpperLabel = this.translate.instant(this.percentsUpperLocaleKey);
    const subscription = filter$.subscribe(filter => {
      this.filter = filter;
      this.bound = {
        lower: filter.show.mean ? meanLowerLabel : percentsLowerLabel,
        upper: filter.show.mean ? meanUpperLabel : percentsUpperLabel
      };
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Cleanup the component after its removed from the UI.
   *
   * @memberof ChartHeaderComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
