import * as Highcharts from 'highcharts';
import {Options} from 'highcharts';
import HighchartsPatternFill from 'highcharts/modules/pattern-fill';

HighchartsPatternFill(Highcharts);

export function createOptions(context, basesLabel): Options {

    const titleStyles = {
        display: 'block',
        color: '#999999',
        'fontSize': '12px'
    };
    const labelStyles = {
        color: '#999999',
        'text-transform': 'uppercase',
        'fontSize': '11px'
    };

    return {
        chart: {
            type: 'line'
        },
        subtitle: {
            text: '"Best of" Combinations',
            style: titleStyles
        },
        xAxis: {
            min: 1,
            max: context.getXAxisValue(context),
            tickInterval: 1,
            lineWidth: 3,
            lineColor: '#dedede',
            endOnTick: true,
            visible: true,
            showLastLabel: true,
            title: {
                align: 'middle',
                style: titleStyles,
                text: '<span>Best set of</span>'
            },
            crosshair: {
                width: 37,
                color: '#DEDEDE'
            },
            labels: {
                style: labelStyles
            },
        },
        yAxis: {
            min: context.getYAxisMin(context),
            max: 100,
            tickInterval: 10,
            lineWidth: 3,
            lineColor: '#dedede',
            title: {
                align: 'middle',
                style: titleStyles,
                text: basesLabel === 'OBC' ? 'Percentage of all respondents' : 'Percentage of Likely Triers'
            },
            labels: {
                formatter: function () {
                    return this.value + '%';
                },
                style: labelStyles
            }
        },
        plotOptions: {
            series: {
                states: {
                    hover: {
                        halo: {
                            size: 9,
                            attributes: {
                                fill: '#10568B',
                                'stroke-width': 2,
                                stroke: '#10568B'
                            }
                        }

                    }
                },
                dataLabels: {
                    enabled: true,
                },
                cursor: 'pointer',
                point: {
                    events: {
                        mouseOver: function () {
                            return context.showLegends(context, true, this.y, this.index);
                        },
                        mouseOut: function () {
                            return context.showLegends(context, false, this.y, this.index);
                        },
                        click: function () {
                            return context.changeViews(this.index);
                        }
                    }
                }
            },
            line: {
                color: '#2298F4',
                dataLabels: {
                    enabled: false
                },
                enableMouseTracking: true
            }
        },
        tooltip: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [
            {
                type: 'line',
                name: 'Top Combinations',
                data: context.getSeriesData(context),
            }
        ]
    };
}
