import { ArchiveState} from "@platform/store/state/archive.state";
import { Action, createReducer, on } from '@ngrx/store';
import { initialState, archiveAdapter } from '../adapters/archive.adapter';
import * as fromArchiveActions from '../actions/archive.action'
import {addConcept} from "@platform/store/actions/concept.actions";
import {conceptAdapter} from "@platform/store/adapters/concept.adapter";
import {addArchive} from "../actions/archive.action";

/**
 * Reducer for all Archive actions.
 */
const ArchiveReducer = createReducer(
  initialState,
  on(addArchive, (state, { archive }) => {
    return archiveAdapter.addOne(archive, state);
  }),
  on(fromArchiveActions.updateArchive, (state, { archive }) => {
    return archiveAdapter.upsertOne( archive, state);
  })
);

/**
 * Archive `NgRx` reducer.
 *
 * @export
 * @param {ArchiveState} state
 * @param {Action} action
 * @returns {ArchiveState}
 */
export function reducer(state: ArchiveState | undefined, action: Action): ArchiveState {
  return ArchiveReducer(state, action);
}
