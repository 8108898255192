<!--<input matInput placeholder="Search for  projects or concepts"  icon="nlsn:search" class="verbatims-search-bar filter-verbatims-btn"-->
<!--       raised tertiary noink>-->

<div class="search-text-container align-items-center">
  <input matInput #searchInput="ngModel" type="text" class="search-input" [(ngModel)]="searchInputValue"
         [placeholder]="appendPlaceholder ? '' + appendPlaceholder: 'project.toolbar.search_placeholder' | translate"
         (blur)="searchHandler($event)" (input)="typeAheadHandler()" (keypress)="enterHandler($event)" />
  <button [hidden]="
      !(searchInput && searchInput.value ? searchInput.value.length : 0)
    " (click)="clear()" name="search-delete-icon">
    <span class="sif sif-delete"></span>
  </button>
</div>

