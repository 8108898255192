import { Action, createReducer, on } from '@ngrx/store';
import { reportFileAdapter, initialState } from '@platform/store/adapters/report-file.adapter';
import { ReportFileState } from '@platform/store/state/report-file.state';
import { addReportFile, addReportFiles, deleteReportFile } from '@platform/store/actions/report-file.actions';

/**
 * Reducer for all reportFile actions.
 */
const reportFileReducer = createReducer(
  initialState,
  on(addReportFile, (state, { reportFile }) => {
    return reportFileAdapter.addOne(reportFile, state);
  }),
  on(addReportFiles, (state, { reportFiles }) => {
    return reportFileAdapter.addMany(reportFiles, state);
  }),
  on(deleteReportFile, (state, { id }) => {
    return reportFileAdapter.removeOne(id, state);
  })
);

/**
 * ReportFiles `NgRx` reducer.
 *
 * @export
 * @param {ReportFileState} state
 * @param {ReportFileActions} action
 * @returns {ReportFileState}
 */
export function reducer(state: ReportFileState | undefined, action: Action): ReportFileState {
  return reportFileReducer(state, action);
}
