import { Injectable } from '@angular/core';
import { StringHelper } from '@platform/services/string-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterItem, PerformanceFilter } from '../models/filter.model';
import { ConceptDataItem, PerformanceDeliverableView, Subgroup } from '../models/performance.model';
import { defaultBarChartOptions } from '../models/default-performance-barchart-options';
import { defaultPerformanceColors } from '../models/default-performance-colors';
import { Metric } from '../models/metric.enum';
import { BarChart, BarChartData } from '@products/shared/chart-horizontal-bar/bar-chart.model';


/**
 * `PerformanceChartDataService` has operations for data tranformation for
 * performance chart.
 *
 * @export
 * @class PerformanceChartDataService
 */
@Injectable({
  providedIn: 'root'
})
export class PerformanceChartDataService {

  /**
   * Creates an instance of PerformanceChartDataService.
   *
   * @constructor
   * @param {FormatterService} formatterService
   * @param {TranslateService} translate
   * @memberof PerformanceChartDataService
   */
  constructor(
    private stringHelper: StringHelper,
    private translate: TranslateService) { }

  /**
   * Returns a tuple of colheaders and chart data for angular material table.
   *
   * @param {PerformanceFilter} filter
   * @param {PerformanceDeliverableView} performance
   * @returns {[Array<any>, Array<any>]} - The tuple of column headers and chart data.
   * @memberof PerformanceChartDataService
   */
  public getChartData(filter: PerformanceFilter, performance: PerformanceDeliverableView): [Array<any>, Array<any>] {
    const data = [];
    const nColHeaders = [];
    if (performance.subgroups.every(item => item.conceptData.length === 0) || performance.subgroups.length === 0) {
      return [nColHeaders, data];
    }
    const selectedConcepts = this.selectedFilterItems(filter.concepts);
    selectedConcepts.sort(function (a, b) {
      return a.position - b.position;
    });
    const conceptsMap = this.getFilterItemMap(selectedConcepts);
    let rowData, cellData, cellDataKey, colHeader;
    const colHeaders: Array<any> = [...selectedConcepts];
    const maximumFairShareIndex = this.findMaxIndexValue(performance.subgroups, conceptsMap); 
    performance.subgroups.forEach((subgroup, index) => {
      rowData = {};
        rowData.subgroup = filter.subgroups.filter(c => c.id === subgroup.segmentId).map(c => c.name);
        rowData.subgroup.push(subgroup.baseSize);
        subgroup.conceptData.forEach((dataItem, i) => {
          cellData = {};
          cellDataKey = conceptsMap[dataItem.conceptId];
          cellData.barChart = this.getBarChart(filter, dataItem, subgroup, maximumFairShareIndex);
          cellData.preferenceIndex = dataItem.preferenceIndex ? dataItem.preferenceIndex : '' ;
          if (cellDataKey?.name) {
            rowData[cellDataKey?.name] = cellData;
            colHeader = colHeaders.find((header) => header.id === cellDataKey.id);
          }
          if (cellDataKey && index === 0 && !nColHeaders.find(col => col.id === cellDataKey.id)) {
            colHeader = colHeaders.find(ch => ch.id === cellDataKey.id);
            const newValue = Object.assign({}, colHeader, { restagePurchaseIndex: dataItem.restagePurchaseIndex });
            nColHeaders.push(newValue);
          }
        });
        data.push(rowData);
    });
    nColHeaders.sort((a, b) => {
      return a.position - b.position;
    });
    nColHeaders.unshift({name: 'subgroup'});
    return [nColHeaders, data];
  }

  /**
   * Returns the bar chart object for the statement data item.
   *
   * @private
   * @param {PerformanceFilter} filter
   * @param {ConceptDataItem} conceptDataItem
   * @param {Subgroup} subgroup
   * @returns {BarChart}
   * @memberof PerformanceChartDataService
   */
  private getBarChart(filter: PerformanceFilter, conceptDataItem: ConceptDataItem, subgroup: Subgroup, maximumFairShare: number): BarChart {
    const options: any = JSON.parse(JSON.stringify(defaultBarChartOptions));
    let barChart: BarChart;
    options.bar.tooltip = true;
    options.aggregateLabel.fill = this.getAggregateLabelColor(conceptDataItem);
    options.bar.domain.max = maximumFairShare
    barChart = {
      colors: Object.assign({}, defaultPerformanceColors),
      options: options,
      series: [this.filterDataPoints(filter, conceptDataItem, subgroup)]
    };
    return barChart;
  }

  /**
   * This will find the Maximum Value from array of data items and round it up to next big 10s digit
   * Example: 10,20,30,40 etc
   * @param item
   * @private
   */
  private findMaxIndexValue(subgroups: Subgroup[], conceptsMap: { [key: number]: FilterItem }): number {
    let maximumFairShare = 0;
    subgroups.forEach(subgroup => {
      subgroup.conceptData.forEach((conceptDataItem: ConceptDataItem) => {
        if (!!conceptsMap[conceptDataItem.conceptId]) {
          maximumFairShare = conceptDataItem.preferenceIndex > maximumFairShare ? conceptDataItem.preferenceIndex : maximumFairShare;
        }
      });
    })
    return Math.ceil(maximumFairShare / 10.0) * 10;
  }

  /**
   * Returns the Aggregate Label Color.
   *
   * @private
   * @param {ConceptDataItem} conceptDataItem
   * @memberof PerformanceChartDataService
   */
  private getAggregateLabelColor(conceptDataItem: ConceptDataItem) {
    return conceptDataItem.preferenceIndex === 100 ?
    defaultPerformanceColors[Metric.CURRENT] : conceptDataItem.preferenceIndex > 100 ?
    defaultPerformanceColors[Metric.ABOVE_CURRENT] : defaultPerformanceColors[Metric.BELOW_CURRENT];
  }


  /**
   * Filters a statement data item for data points.
   *
   * @private
   * @param {PerformanceFilter} filter
   * @param {ConceptDataItem} dataItem
   * @param {Subgroup} subgroup
   * @returns {BarChartData}
   * @memberof PerformanceChartDataService
   */
  private filterDataPoints(filter: PerformanceFilter, dataItem: ConceptDataItem, subgroup: Subgroup): BarChartData {
    const result = {};
    const id = `${dataItem.conceptId}-${subgroup.segmentId}`;
    if (dataItem.preferenceIndex === 100 ) {
      this.addDataPoint(Metric.CURRENT, id, dataItem.preferenceIndex, result, filter);
    } else if ( dataItem.preferenceIndex > 100) {
      this.addDataPoint(Metric.ABOVE_CURRENT, id, dataItem.preferenceIndex, result, filter);
    } else {
      this.addDataPoint(Metric.BELOW_CURRENT, id, dataItem.preferenceIndex, result, filter);
    }
    return result;
  }

  /**
   * Adds a data point.
   *
   * @private
   * @param {string} key
   * @param {string} id
   * @param {number} value
   * @param {*} data
   * @param {PerformanceFilter} filter
   * @memberof PerformanceChartDataService
   */
  private addDataPoint(key: string, id: string, value: number, data: any, filter: PerformanceFilter): void {
      value = value ? value : 0;
      data[key] = {
        id: `${key}-${id}`,
        value: value,
        tooltip: this.getTooltip(key, value, filter)
      };
  }

  /**
   * Returns tooltip text based on the filter and value.
   *
   * @param {string} metric the metric key
   * @param {number} value the value
   * @param {PerformanceFilter} filter the filter
   * @private
   * @method
   */
  private getTooltip(metric: string, value: number, filter: PerformanceFilter): string {
    let tooltip: string;
    switch (metric) {
      case Metric.ABOVE_CURRENT:
       tooltip = this.translate.instant('restage.deliverable.performance.tooltip.above.current', { value });
       break;
      case Metric.CURRENT:
       tooltip = this.translate.instant('restage.deliverable.performance.tooltip.current', { value });
       break;
      case Metric.BELOW_CURRENT:
       tooltip = this.translate.instant('restage.deliverable.performance.tooltip.below.current', { value });
       break;
      default:
        tooltip = '';
    }
    return tooltip;
  }

  /**
   * Returns map of filter items.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {{[key: number]: FilterItem}}
   * @memberof PerformanceChartDataService
   */
  private getFilterItemMap(items: Array<FilterItem>): {[key: number]: FilterItem} {
    const itemsMap: {[key: number]: FilterItem } = {};
    items.forEach(item => {
      itemsMap[item.id] = item;
    });
    return itemsMap;
  }

  /**
   * Returns selected filter items.
   *
   * @private
   * @param {Array<FilterItem>} items
   * @returns {Array<FilterItem>}
   * @memberof PerformanceChartDataService
   */
  private selectedFilterItems(items: Array<FilterItem>): Array<FilterItem> {
    const selectedItems = items ? items.filter(item => item.isSelected) : [];
    return selectedItems;
  }

}
