import { DeliverableType } from '@products/quick-predict/deliverable-type.enum';
/**
 * Default filter values for attributes.
 *
 * @export
 * @property
*/
export const defaultActivationProfileLegacyFilter = {
  deliverableType: DeliverableType.ACTIVATION_PROFILE_LEGACY.type,
  deliverableViewType: "image"
};
