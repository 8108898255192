<div id="quad-map-view" class="quad-map-view">
  <!-- D3 Scatter Plot -->
  <div id="scatter-plot-chart" class="scatter-plot-chart">
    <ns-correlations-chart [scatterPlotData] = quadMapPlotData [plotOptions] = quadMapPlotOptions [selectedKeyMeasureName]=selectedKeyMeasureName [selectedQuad]=selectedQuad></ns-correlations-chart>
  </div>

  <!--Footer legend-->
  <div id="quad-map-legend">
    <div id="legend-footnote" class="quad-map-footnote section text-center padding-4">
      <span>{{'shared.deliverables.correlations.quadMap.footnote.base.size' | translate : {baseSize:baseSize} }}</span>
    </div>
  </div>
</div>



