import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {FilterService} from '@platform/services/filter.service';
import {TranslateService} from '@ngx-translate/core';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {Concept} from '@platform/models/concept.model';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {FilterItem, WordCloudFilter} from '../models/filter.model';
import {WordCloudService} from '../services/word-cloud.service';

/**
 * `FilterComponent` creates the word cloud filter options like compare,
 * concepts, subgroups, and show filter.
 *
 * @example
 *   <ns-word-cloud-filter></ns-word-cloud-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-word-cloud-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

    /**
     * Set the concept to set single concept filter.
     *
     * @memberof FilterComponent
     */
    @Input() concept: Concept;

    /**
     * WordCloud filter object.
     *
     * @property
     * @type {WordCloudFilter}
     * @memberof FilterComponent
     */
    public filter: WordCloudFilter;


    /**
     * questions list dropdown data.
     *
     * @property
     * @type {DropdownData<number>}
     * @memberof FilterComponent
     */
    public questionsListData: DropdownData<number>;

    /**
     * concepts list dropdown data.
     *
     * @property
     * @type {DropdownData<number>}
     * @memberof FilterComponent
     */
    public conceptsListData: DropdownData<number>;

    /**
     * List of countries on the report.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public countryListData: DropdownData<string>;

    /**
     * subgroups list dropdown data.
     *
     * @property
     * @type {DropdownData<number>}
     * @memberof FilterComponent
     */
    public subgroupsListData: DropdownData<number>;

    /**
     * List of deliverable view dropdown items.
     *
     * @property
     * @type {DropdownData<string>}
     * @memberof FilterComponent
     */
    public compareListData: DropdownData<string>;

    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof FilterComponent
     */
    private subscriptions: Array<Subscription>;
    /**
     * Mixpanel label for the deliverable
     *
     * @property
     * @private
     * @type {string}
     * @memberof FilterComponent
     */
    private deliverableLabel: string;

    /**
     * Creates an instance of FilterComponent.
     *
     * @constructor
     * @param {WordCloudService} wordCloudService
     * @param {FilterService} filterService
     * @param {TranslateService} translate
     * @memberof FilterComponent
     */
    constructor(
        private wordCloudService: WordCloudService,
        private filterService: FilterService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize the filter component. Sets filter for the template.
     *
     * @memberof FilterComponent
     */
    ngOnInit(): void {
        this.deliverableLabel = this.concept ? MixpanelLabel.concepts : MixpanelLabel.wordCloud;
        const filter$ = this.wordCloudService.getWordCloudFilter();
        const subscription = combineLatest([filter$]).subscribe(([filter]) => {
            this.filter = filter;
            this.setFilters(this.filter);
        });
        this.wordCloudService.loadDefaultFilter(this.concept);
        this.subscriptions.push(subscription);
    }

    /**
     * Event listener for compareFilter selection change event.
     *
     * @listens {event} selectionChange
     * @param {Array<DropdownData<string>>} compare:
     * @memberof FilterComponent
     */
    selectCompare(compare: any) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const selectedCompare: DropdownItem<string> = compare.find((option: DropdownItem<string>) => {
            return option.selected;
        });
        this.filterService.update({
            ...this.filter,
            deliverableViewType: selectedCompare.value.toString(),
            compare: this.filter.compare.map((item: FilterItem) => {
                return {
                    ...item,
                    isSelected: compare.find(selected => selected.value === item.id).selected
                };
            }),
            concepts: this.filter.concepts.map((concept, index) => {
                return {
                    ...concept,
                    isSelected: (selectedCompare.label === 'Concepts') ? true : (index === 0)
                };
            }),
            subgroups: this.filter.subgroups.map((subgroup, index) => {
                return {
                    ...subgroup,
                    isSelected: (selectedCompare.label === 'Subgroups') ? true : (index === 0)
                };
            })
        });
    }

    /**
     * Event listener for conceptsFilter selection change event.
     *
     * @listens {event} selectionChange
     * @param {Array<DropdownData<number>>} concepts
     * @memberof FilterComponent
     */
    selectedConcept(concepts: Array<DropdownItem<number>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            concepts: this.filter.concepts.map((concept) => {
                return {
                    ...concept,
                    isSelected: concepts.find(it => it.value === concept.id).selected
                };
            })
        });
    }

    /**
     * Event listener for subgroupsFilter selection change event.
     *
     * @listens {event} selectionChange
     * @param {Array<DropdownItem<number>>} subgroups
     * @memberof FilterComponent
     */
    selectedSubgroup(subgroups: Array<DropdownItem<number>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            subgroups: this.filter.subgroups.map((subgroup) => {
                return {
                    ...subgroup,
                    isSelected: subgroups.find(it => it.value === subgroup.id).selected
                };
            })
        });
    }

    /**
     * Event listener for questionsFilter selection change event.
     *
     * @listens {event} selectionChange
     * @param {Array<DropdownItem>} questions
     * @memberof FilterComponent
     */
    selectedQuestion(questions: Array<DropdownItem<number>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            questions: this.filter.questions.map((question) => {
                return {
                    ...question,
                    isSelected: questions.find(it => it.value === question.id).selected
                };
            })
        });
    }

    /**
     * Emits event of the selected filter menu item.
     */
    selectedEvent(event): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        console.log(event);
    }


    /**
     * Sets the filter options for concepts, subgroups, compareList and questionsList.
     *
     * @private
     * @param {WordCloudFilter} filter
     * @memberof FilterComponent
     */
    private setFilters(filter: WordCloudFilter): void {
        this.conceptsListData = this.getConceptFilterOptions(filter);
        this.subgroupsListData = this.getSubGroupFilterOptions(filter);
        this.compareListData = this.getCompareFilterOptions(filter.compare);
        this.questionsListData = this.getQuestionFilterOptions(filter.questions);
        this.countryListData = this.getCountryOptions(filter.countries[0]);
    }

    /**
     * Returns the country filter options.
     *
     * @private
     * @param {string} country
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getCountryOptions(country: string): DropdownData<string> {
        return {
            dropdownLabel: this.translate.instant('quick.question.deliverables.wordcloud.filter.country.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('quick.question.deliverables.wordcloud.filter.country.itemsTitle'),
            data: [
                {
                    value: country,
                    label: country,
                    selected: true
                }
            ]
        };
    }

    /**
     * Returns Concepts filter options.
     *
     * @private
     * @param {WordCloudFilter} filter
     * @returns {DropdownData<number>}
     * @memberof FilterComponent
     */
    private getConceptFilterOptions(filter: WordCloudFilter): DropdownData<number> {
        const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.CHECKBOX : DataType.RADIO;
        const items = filter.concepts;
        const dropDownData: DropdownItem<number>[] = items.map(item => {
            return {
                value: item.id,
                label: item.name,
                selected: item.isSelected
            };
        });

        return {
            dropdownLabel: this.translate.instant('quick.question.deliverables.wordcloud.filter.concepts.label'),
            dataType: dataType,
            contentTitle: this.translate.instant('quick.question.deliverables.wordcloud.filter.concepts.itemsTitle'),
            groupSelect: this.translate.instant('quick.question.deliverables.wordcloud.filter.concepts.allSelectedLabel'),
            data: dropDownData
        };
    }

    /**
     * Returns subgroups filter options.
     *
     * @private
     * @param {WordCloudFilter} filter
     * @returns {DropdownData<number>}
     * @memberof FilterComponent
     */
    private getSubGroupFilterOptions(filter: WordCloudFilter): DropdownData<number> {
        const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.RADIO : DataType.CHECKBOX;
        const items = filter.subgroups;
        const dropdownData: DropdownItem<number>[] = items.map(item => {
            return {
                value: item.id,
                label: item.name,
                selected: item.isSelected
            };
        });

        return {
            dropdownLabel: this.translate.instant('quick.question.deliverables.wordcloud.filter.subgroups.label'),
            dataType: dataType,
            contentTitle: this.translate.instant('quick.question.deliverables.wordcloud.filter.subgroups.itemsTitle'),
            groupSelect: this.translate.instant('quick.question.deliverables.wordcloud.filter.subgroups.allSelectedLabel'),
            data: dropdownData
        };
    }

    /**
     * Returns the compare filter options.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {DropdownData<string>}
     * @memberof FilterComponent
     */
    private getCompareFilterOptions(items: Array<FilterItem>): DropdownData<string> {
        const compareListItemData: Array<DropdownItem<string>> = [];

        for (const compareby of items) {
            compareListItemData.push({
                value: compareby.id,
                label: compareby.name,
                selected: compareby.isSelected
            });
        }

        return {
            dropdownLabel: this.translate.instant('quick.question.deliverables.wordcloud.filter.compare.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('quick.question.deliverables.wordcloud.filter.compare.itemsTitle'),
            data: compareListItemData
        };
    }

    /**
     * Returns the questions filter options.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {DropdownData<number>}
     * @memberof FilterComponent
     */
    private getQuestionFilterOptions(items: Array<FilterItem>): DropdownData<number> {
        const questionList: Array<DropdownItem<number>> = [];

        for (const question of items) {
            questionList.push({
                value: question.id,
                label: question.name,
                selected: question.isSelected
            });
        }

        return {
            dropdownLabel: this.translate.instant('quick.question.deliverables.wordcloud.filter.questions.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('quick.question.deliverables.wordcloud.filter.question.itemsTitle'),
            data: questionList
        };
    }

    /**
     * Cleanup the component on removing from the UI.
     *
     * @memberof FilterComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
