/**
 * Compare view for Survey Question.
 *
 * @export
 * @enum {string}
 */
export enum CompareView {
  /**
   * Concept deliverable view type.
   */
  SUMMARY = 'summary-view',

  DETAILED = 'detailed-view',

  ALTERNATIVE = 'alternative-combination'
}
