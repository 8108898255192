import { CaptureOptions } from './capture-options';

/**
 * Processor for attributes.
 */
export class AttributeProcessor {

  /**
   * Process attributes by removing using the configuration.
   *
   * @param clone the cloned node.
   * @param options the options.
   */
  public process(clone: Element, options: CaptureOptions): void {
    this.remove(clone, options.attribute.remove);
  }

  /**
   * Remove attributes.
   *
   * @param element the node
   * @param prefixes the remove prefixes.
   */
  private remove(element: Element, prefixes: string[]): void {
    const attributes: NamedNodeMap = element.attributes;
    let attribute: Attr;
    let i = 0;
    let shouldRemove;
    while (i < attributes.length) {
      attribute = attributes.item(i);
      i++;
      shouldRemove = prefixes.some(att => attribute.name.indexOf(att) >= 0);
      if (shouldRemove) {
        // Remove attribute after its added.
        attributes.removeNamedItem(attribute.name);
        i--;
      }
    }
  }
}
