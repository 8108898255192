/**
 * Default colors for all stat types.
 *
 * @export
 * @property
 */
export const defaultAttributeColors = {
  mean: '#009DD9',
  agree: '#009DD9',
  stronglyAgree: '#3A73CA',
  disagree: '#999999',
  stronglyDisagree: '#CCCCCC',
  neither: '#707276'
};
