import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Report} from '@platform/models/report.model';
import {switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {State} from '@platform/store/state/app.state';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '@app/core/config/app-config.service';
import {Concept} from '@platform/models/concept.model';
import {ReportService} from '@platform/services/report.service';
import {Benchmark} from '@platform/models/benchmark.model';

@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {

  constructor( private store: Store<State>,
               private httpClient: HttpClient,
               private reportService: ReportService,
               private appConfigService: AppConfigService) { }

  /**
   * Fetched report object from the API.
   *
   * @private
   * @param {string} reportId The report id.
   * @returns {Observable<Report>} The report observable
   * @memberof ReportService
   */
  public getBenchMark(): Observable<Array<Benchmark>> {
      const report$ = this.reportService.get();
      return report$.pipe(
          switchMap(report => {
              const url = `${this.appConfigService.config.reporting.url}/reports/${report.id}/benchmarks`;
              return this.httpClient.get<Array<Benchmark>>(url);
          })
      );
  }
}
