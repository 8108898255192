/**
 * Factor and Measure type mappings
 *
 * @export
 * @enum {string}
 */
export enum FactorMeasuretypeMapping {

  ALTMT = 'quick.predict.deliverables.factors.label.subgroup.distinct.proposition',

  NAD = 'quick.predict.deliverables.factors.label.attention.catching.uniqueness',

  REL3MT = 'quick.predict.deliverables.factors.label.need.desire',

  ADV3MT = 'quick.predict.deliverables.factors.label.advantage',

  BELM = 'quick.predict.deliverables.factors.label.credibility',

  PV = 'quick.predict.deliverables.factors.label.acceptable.costs',

  SIMT = 'quick.predict.deliverables.factors.label.subgroup.message.connection',

  CLRT = 'quick.predict.deliverables.factors.label.subgroup.clear.concise.message'
}
