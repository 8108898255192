import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
/**
 * Default filter values for attributes.
 *
 * @export
 * @property
*/
export const defaultAdditionalInfoFilter = {
  deliverableType: DeliverableType.ADDITIONAL_INFO.type,
  deliverableViewType: 'image'
};
