import {Component, Input, OnInit} from '@angular/core';
import {ReachAnalysisService} from '@app/deliverables/reach-analysis/services/reach-analysis.service';
import {FilterService} from '@platform/services/filter.service';
import {Subscription} from 'rxjs';
import {ReachAnalysisFilter} from '@app/deliverables/reach-analysis/models/filter.model';
import {filterNames as filterNameSource} from '@app/deliverables/reach-analysis/filter/show/filter-names';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';

@Component({
  selector: 'ns-reach-analysis-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {

  @Input() deliverableLabel: string;
  /**
   * Filter label.
   *
   * @property
   * @type {string}
   * @memberof ShowComponent
   */
  public filterLabel: string;

  public filter: ReachAnalysisFilter;

  private subscription: Subscription;
  /**
   * Filter name object.
   *
   * @property
   * @type {*}
   * @memberof ShowComponent
   */
  public filterNames: any;
  public compareView: string;

  constructor(private reachAnalysisService: ReachAnalysisService,
              private filterService: FilterService,
              private mixpanelService: MixpanelService) {
    this.filterNames = filterNameSource;
  }

  ngOnInit(): void {
    const filter$ = this.reachAnalysisService.getReachAnalysisFilter();
    this.subscription = filter$.subscribe(filter => {
      this.filter = filter;
      this.compareView = filter.compare.find(it => it.isSelected === true).id;
      this.setFilterLabel();
    });
  }

  /**
   * Sets filter value property.
   *
   * @private
   * @memberof ShowComponent
   */
  private setFilterLabel(): void {
    const showFilter = this.filter.show;
    let label = '';
    if (showFilter.noDecimal) {
      label = 'No decimals';
    }
    if (showFilter.oneDecimal) {
      label = '1 decimal place';
    }
    if (showFilter.twoDecimal) {
      label = '2 decimal places';
    }
    this.filterLabel = label;
  }

  /**
   * Event listener for decimal place filter change.
   *
   * @param {*} event
   * @memberof ShowComponent
   */
  public changeDecimalPlace(event): void {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    const filterNames = this.filterNames;
    const decimalPlace = event.value;
    const filter = this.cloneFilter();
    filter.show.noDecimal = decimalPlace === filterNames.noDecimals;
    filter.show.oneDecimal = decimalPlace === filterNames.oneDecimalPlace;
    filter.show.twoDecimal = decimalPlace === filterNames.twoDecimalPlace;
    filter.show.reload = true;
    this.filterService.update(filter);
  }

  /**
   * Event listener for alternative filter change.
   *
   * @memberof ShowComponent
   */

  public getSelectedAlternatives(): string {
    const filterNames = this.filterNames;
    const showFilter = this.filter.show;
    let alternative = '';
    alternative = showFilter.upToTwentyAlternatives ? filterNames.upToTwentyAlternatives : alternative;
    alternative = showFilter.allAlternatives ? filterNames.allAlternatives : alternative;
    return alternative;
  }

  /**
   * Event listener for decimal place filter change.
   *
   * @param {*} event
   * @memberof ShowComponent
   */
  public changeAlternatives(event): void {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    const filterNames = this.filterNames;
    const decimalPlace = event.value;
    const filter = this.cloneFilter();
    filter.show.upToTwentyAlternatives = decimalPlace === filterNames.upToTwentyAlternatives;
    filter.show.allAlternatives = decimalPlace === filterNames.allAlternatives;
    this.filterService.update(filter);
  }

  /**
   * Returns selected decimal place value.
   *
   * @returns {string}
   * @memberof ShowComponent
   */
  public getSelectedDecimalPlace(): string {
    const filterNames = this.filterNames;
    const showFilter = this.filter.show;
    let decimalPlace = '';
    decimalPlace = showFilter.noDecimal ? filterNames.noDecimals : decimalPlace;
    decimalPlace = showFilter.oneDecimal ? filterNames.oneDecimalPlace : decimalPlace;
    decimalPlace = showFilter.twoDecimal ? filterNames.twoDecimalPlace : decimalPlace;
    return decimalPlace;
  }

  /**
   * Clones filter component.
   */
  private cloneFilter(): ReachAnalysisFilter {
    const filter = this.filter;
    const newShowFilter = {...filter.show};
    return {...filter, show: newShowFilter};
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * toggle combination sets filter change.
   *
   * @memberof ShowComponent
   * @param filterName
   */
  public toggleCombinationSets(filterName: any) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    const filter = this.cloneFilter();
    const show = filter.show;
    show.combinationSets = JSON.parse(JSON.stringify(show.combinationSets));
    if (filterName instanceof Object) {
      show.combinationSets.filter(set => {
        if (set.name === filterName.name) {
          set.isSelected = !set.isSelected;
          return set;
        }
      });
      show.allCombinations = show.combinationSets.every(set => set.isSelected);
    } else {
      filterName = !filterName;
      show.allCombinations = !show.allCombinations;
      show.combinationSets.forEach(item => {
        item.isSelected = filterName;
      });
    }
    this.filterService.update(filter);
  }

}
