import {createFeatureSelector, createSelector} from '@ngrx/store';
import {deliverableConfigurationAdapter} from '@platform/store/deliverable-configuration/deliverable-configuration.adapter';
import {State} from '@platform/store/state/app.state';
import {DeliverableConfigurationState} from '@platform/store/deliverable-configuration/deliverable-configuration.state';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';
import {Dictionary} from '@ngrx/entity';


/**
 * Default selectors from adapter.
 *
 * @property
 */
const {
    selectEntities,
    selectAll
} = deliverableConfigurationAdapter.getSelectors();


/**
 * DeliverableConfiguration feature selector.
 *
 * @export
 * @property
 */
export const selectDeliverableConfigurationFeature = createFeatureSelector<State, DeliverableConfigurationState>('deliverableConfiguration');
export const selectDeliverableConfigurationState = createSelector(
    selectDeliverableConfigurationFeature,
    (state: DeliverableConfigurationState) => state
);

export const selectDeliverableConfigurationEntities = createSelector(
    selectDeliverableConfigurationState,
    selectEntities
);
export const selectDeliverableConfigurations = createSelector(
    selectDeliverableConfigurationState,
    selectAll
);

export const selectDeliverableConfigurationById =
    createSelector(
        selectDeliverableConfigurationEntities,
        (entities: Dictionary<DeliverableConfiguration>, props: { id: string }) => {
            return entities[props.id];
        }
    );


export const selectDeliverableConfigurationByName = (name: string) => createSelector(selectDeliverableConfigurations, (deliverableConfigurations: DeliverableConfiguration[]) => {
    return deliverableConfigurations;
});
