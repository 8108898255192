import { Filter } from '@platform/models/filter.model';
import { FilterState } from '@platform/store/state/filter.state';
import { State } from '@platform/store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * Filter feature selector.
 *
 * @export
 * @property
*/
export const selectFilterFeature = createFeatureSelector< FilterState>('filter');

/**
 * Filter selector.
 *
 * @export
 * @property
*/
export const selectFilter = createSelector(
  selectFilterFeature,
  (state: FilterState) => state
);

/**
 * Selector for selecting filter by deliverable view type.
 *
 * @export
 * @property
*/
export const selectFilterByType = <T extends Filter>() => {
  return createSelector(
    selectFilter,
    (state: FilterState, props: { type: string }) => {
      return <T>state[props.type];
    }
  );
};
