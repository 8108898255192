
/**
 * static chart options.
 *
 * @export
 * @property
*/
//yet to add more
export const gapAnalysisChartOptions = {
  margin: {
    top: 20,
    right: 20,
    bottom: 50,
    left: 50
  },

  width: 1500,
  height: 400,

  xAxis: {
    class: "x axis",
    color: '#000',
    fillColor: '#ddd',
    strokeWidth: 0
  },
  yAxis: {
    class: "y axis",
    color: '#ddd',
    fillColor: 'none',
    strokeWidth: 1,
    stroke: '#ddd'
  },

  staticLine: {
    class: "static-line",
    strokeWidth: 2,
    stroke: '#888'
  },

  backgroundLine: {
    class: 'background-line',
    strokeWidth: 5,
    stroke: 'rgba(255,255,255,0.5)'
  },

  currentLine: {
    class: "statement-group-line",
    strokeWidth: 3,
    stroke: "#000"
  },

  //max four bars per group
  barColorsRange: ['#333','#666','#999', '#ccc']
};
