import { DeliverableType } from "@app/deliverables/deliverable-type.enum";
import { DeliverableViewType } from '@app/deliverables/attributes/models/deliverable-view-type.enum';
import { CompareView } from './compare-view-enum';

/**
 * Default filter values for survey question.
 *
 * @export
 * @property
*/
export const defaultSurveyQuestionFilter = {
  deliverableType: DeliverableType.SURVEY_QUESTION.type,
  deliverableViewType: DeliverableViewType.CONCEPT,
  compare: [
  {
    name: 'Concepts',
    id: CompareView.CONCEPT,
    isSelected: true,
    position: 1
  },
  {
    name: 'Subgroups',
    id: CompareView.SUBGROUP,
    isSelected: false,
    position: 0
  }],

  show: {
    noDecimal: true,
    oneDecimal: false,
    twoDecimal: false,
    statTesting: true,
    aggregate: false,
    mention: true
  }
};
