<div cdkDropList class="deliverables-list-container" (cdkDropListDropped)="drop($event)">
    <div *ngFor="let item of dataList">

        <div *ngIf="hideDragAndDroplist.includes(item.displayName)" class="item-row">
            <div class="fixed-row">
                <div *ngIf="item.type=='text'" [ngClass]="{'disabled-text':!item.isVisible}">
                    <h4 class="group-title title-input">{{item.displayName}}</h4>
                    <div class="content-input" *ngIf="item.displayName==='Currency';else tradePromotion">
                        <span class="{{ currencyType }} currency-icon">&nbsp;MM</span>
                    </div>
                    <ng-template #tradePromotion>
                        <div class="content-input"><span>%Distribution</span></div>
                    </ng-template>
                </div>
                <div class="radio-input" *ngIf="item.type=='radioGroup'" [ngClass]="{'disabled-text':!item.isVisible}">
                    <h4 class="group-title">{{item.displayName}}</h4>
                    <mat-radio-group (change)="changeMetricType($event,item)" [disabled]="!item.isVisible">
                        <mat-radio-button *ngFor="let item of item.data" color="primary" [name]="item.displayName"
                            [value]="item.displayName" [checked]="item.checked">{{item.displayName}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <button mat-icon-button (click)="toggleVisibility(item)" *ngIf="showVisibilityToggleIcon"
                class="visibility-button text-radioBtn-visbility">
                <span class="sif sif-gray cursor-pointer"
                    [ngClass]="item.isVisible? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
            </button>
        </div>

        <div *ngIf="!hideDragAndDroplist.includes(item.displayName)">
            <div class="parent-node" cdkDrag [cdkDragDisabled]="!enableDragAndDrop">


                <form class="filter-input-form">
                    <div class="cdk-custom-placeholder" *cdkDragPlaceholder></div>
                    <div [ngClass]="{'item-row-dragndrop':!hideDragAndDroplist.includes(item.displayName)}">
                        <div class="item-row" [ngClass]="{'border-bottom':!editable}">
                            <button mat-icon-button cdkDragHandle *ngIf="enableDragAndDrop" class="drag-n-drop-button">
                                <span class="sif sif-drag-handler sif-12 sif-gray"></span>
                            </button>
                            <span class="drag-handler-placeholder" *ngIf="!enableDragAndDrop "></span>
                            <span class="no-expansion-placeholder"
                                *ngIf="!anyOneItemHasChild() && !hasExpandableData"></span>
                            <button mat-icon-button (click)="toggleNode(item)" [disabled]="!item.isVisible"
                                *ngIf="hasChildren(item) || hasExpandableData" class="toggle-button">
                                <span class="sif sif-12 expand-collapse sif-gray cursor-pointer"
                                    [ngClass]="isExpanded(item) && item.isVisible? 'sif-chevron-s':'sif-chevron-e'"></span>
                            </button>
                            <span class="expand-collapse-placeholder"
                                *ngIf="!hasChildren(item) && anyOneItemHasChild()"></span>
                            <span class="display-name-span" *ngIf="!editable">{{item.displayName}}</span>
                            <input matInput class="filter-input" *ngIf="!item.type && editable"
                                [disabled]="!item.isVisible" [formControl]="getFormControl(item)"
                                (change)="displayNameChange($event,item)" />

                            <mat-slide-toggle [id]="'deliverableViewToggle'+item.id" [(ngModel)]="item.isVisible"
                                [disabled]=false *ngIf="showVisibilityToggleSlider" class="pl-2">
                            </mat-slide-toggle>
                            <button mat-icon-button (click)="toggleVisibility(item)" *ngIf="showVisibilityToggleIcon"
                                class="visibility-button">
                                <span class="sif sif-gray cursor-pointer"
                                    [ngClass]="item.isVisible? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
                            </button>
                            <button mat-icon-button (click)="emitNavigation(item)" *ngIf="displayChildPageNavigation">
                                <span class="sif sif-12 expand-collapse sif-gray sif-chevron-e cursor-pointer"
                                    (click)="emitNavigation(item)"></span>
                            </button>

                        </div>
                    </div>
                </form>
                <div class="child-parent-node" *ngIf="hasChildren(item)"
                    [ngClass]="{'hidden':!(isExpanded(item) && item.isVisible),'child-node':!anyChildHasGrandChild(item)}">
                    <ns-deliverables-list [dataList]="item.children" [editable]="editable"
                        [enableDragAndDrop]="enableDragAndDrop" [currencyType]="currencyType"
                        [showVisibilityToggleIcon]="showVisibilityToggleIcon"
                        [showVisibilityToggleSlider]="showVisibilityToggleSlider"
                        [displayChildPageNavigation]="displayChildPageNavigation"
                        [expandedRowsStoreCollection]="expandedRowsStoreCollection"></ns-deliverables-list>
                </div>
                <div class="custom-content-div" *ngIf="hasExpandableData && !hasChildren(item)"
                    [ngClass]="{'hidden':!(isExpanded(item) && item.isVisible)}">
                    <ng-template [ngTemplateOutlet]="templateRef"
                        [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                </div>
            </div>
        </div>

    </div>
</div>