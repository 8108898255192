import { DeliverableType } from '@app/deliverables/deliverable-type.enum';
import { CompareView } from './compare-view-enum';
import { PriceElasticityFilter } from './filter.model';


/**
 * Default filter values for price elasticity.
 *
 * @export
 * @property
*/
export const defaultPriceElasticityFilter: PriceElasticityFilter = {
  deliverableType: DeliverableType.PRICE_ELASTICITY.type,
  deliverableViewType: 'image',
  compare: [
    {
      name: 'Concepts',
      id: CompareView.CONCEPT,
      isSelected: true,
      position: 1
    }],
};

