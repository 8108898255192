<div class="container">
    <button class="chart-option-icon sif sif-gray sif-options" [matMenuTriggerFor]="menuChartOption"></button>
    <mat-menu #menuChartOption="matMenu" [overlapTrigger]="true">
        <h3 class="chart-title">Chart Options</h3>
        <ng-template mat-tab-label>
            <span class="tab-icons sif sif-comment-quote-filled"></span>
        </ng-template>
        <ng-template [ngTemplateOutlet]="zones"></ng-template>
        <div *ngIf="viewName==='clickData'" class="text-options chart-options-data-view">
            <h3 class="menu-title">CHART METRIC</h3>
            <mat-radio-group [ngModel]="getSelectedchartMetrics()" (change)="changeChartMetric($event)" (click)="$event.stopPropagation();">
                <div class="menu-list-content" *ngFor="let metric of filter?.show.chartMetric">
                    <mat-radio-button color="accent" class="list-item" [value]="metric.id">
                        <li class="list-item {{metric.id}}">{{ metric.name }}</li>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
        <div *ngIf="viewName==='verbatims' && isAISummaryFeatureEnabled && aiConceptSummaries && isSubgroupSummaryVisible" class="text-options chart-options-data-view">
            <h3 class="menu-title">DATA VIEW</h3>
            <mat-radio-group [ngModel]="getSelectedDataView()" (change)="changeDataView($event)" (click)="$event.stopPropagation();">
                <div class="menu-list-content" *ngFor="let dataview of filter?.show.dataView">
                    <mat-radio-button color="accent" class="list-item" [value]="dataview.id">
                        <li class="list-item {{dataview.id}}">{{ dataview.name }}</li>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </mat-menu>
</div>

<ng-template #zones>
    <div class="text-options chart-options-zones">
        <h3 class="menu-title">ZONES</h3>
        <mat-radio-group [ngModel]="getSelectedZones()"
                         (change)="changeZones($event)" (click)="$event.stopPropagation();">
            <div class="menu-list-content" *ngFor="let zone of filter?.show.zone">
                <mat-radio-button color="accent" class="list-item" [value]="zone.id">
                    <li class="list-item">{{ zone.name }}</li>
                </mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
</ng-template>
