import { createAction, props } from '@ngrx/store';
import {Archive} from "@platform/models/archive.model";



/**
 * Action for adding an Archive.
 *
 * @export
 */
export const addArchive = createAction(
  '[Archive] Add Archive View',
  props<{ archive: Archive }>()
);


/**
 * Action for updating an Archive.
 *
 * @export
 */
export const updateArchive = createAction(
  '[Archive] Update Archive',
  props<{ archive: Archive }>()
);



