import {
  Component,
  Input,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Subscription, fromEvent, Subject } from 'rxjs';

/**
 * `<ns-carousel-table>` component adds carousel buttons on hover and responsiveness for tables.
 *
 * There are couple of ways you could use this component.
 *
 * 1. When `isManaged = true`, this component manages hide/show of columns based on the
 * available screen space for the table.
 * 2. When `isManaged = false`, consumer of this component should handle hide/show of the
 * table with the `change` event emitted value. `columnWidthList` input parameter is
 * required
 *
 * Id reference of the table element, which needs to be paginated and responsive, should
 * be passed to the component along with total number of columns in the table.
 *
 * An array of static columns index can be passed to the component. Static columns will be
 * visible always.
 *
 * This component emits:
 *  1. `change` event with array of visible columns indexes, `number[]`.
 *  2. `next` event to notify when next button is clicked.
 *  3. `previous` event to notify when previous button is clicked.
 *
 * @example
 *   <ns-carousel-table
 *     [isManaged]="true"
 *     [tableId]="data-table"
 *     [refresh$]="refreshSubject"
 *     [columnWidthList]="widthList"
 *     [staticColumns]="staticColumnIndexes" >
 *   </ns-carousel-table>
 *
 * @param {boolean} isManaged - True, if CarouselTable component need to manage columns. False,
 * if columns need to be managed externally. Default true.
 * @param {string} tableId - Table Element Id, , required if `isManaged=true`.
 * @param {Subject<boolean>} refresh$ - Subject for listening changes, required if `isManaged=true`.
 * @param {number[]} columnWidthList - Array of all min column widths, required if `isManaged=false`.
 * @param {number[]} staticColumns - Array of static columns index (Optional).
 *
 * @export
 * @class CarouselTableComponent
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'ns-carousel-table',
  templateUrl: './carousel-table.component.html',
  styleUrls: ['./carousel-table.component.scss']
})
export class CarouselTableComponent implements OnDestroy, AfterViewInit, OnInit, OnChanges {

  /**
   * Emits visible column changes.
   *
   * @type {EventEmitter<number[]>}
   * @memberof CarouselTableComponent
   */
  @Output() change: EventEmitter<number[]> = new EventEmitter<number[]>();

  /**
   * Emits next button event.
   *
   * @type {EventEmitter<Event>}
   * @memberof CarouselTableComponent
   */
  @Output() next: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Emits previous button event.
   *
   * @type {EventEmitter<Event>}
   * @memberof CarouselTableComponent
   */
  @Output() previous: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Flag to define if table pagination should be managed by the component.
   *
   * @type {boolean}
   * @memberof CarouselTableComponent
   */
  @Input() isManaged: boolean;

  /**
   * Array of static column indexes which needs to be displayed always.
   *
   * @type {number[]}
   * @memberof CarouselTableComponent
   */
  @Input() staticColumns: number[];

  /**
   * Array of all column widths in pixel.
   *
   * @type {number[]}
   * @memberof CarouselTableComponent
   */
  @Input() columnWidthList: number[];

  /**
   * Table element id, which needs to be made responsive using this
   * component.
   *
   * @type {string}
   * @memberof CarouselTableComponent
   */
  @Input() targetId: string;

  /**
   * Subject for triggering `update` externally.
   *
   * @type {Subject<boolean>}
   * @memberof CarouselTableComponent
   */
  @Input() refresh$: Subject<boolean>;

  /**
   * Table arrow tooltip added description
   *
   * @type {string}
   * @memberof CarouselTableComponent
   */
  @Input() tooltipDescription: string = '';

  /**
   * Boolean array representing visible and hidden columns by index.
   *
   * @type {Array <boolean>}
   * @memberof CarouselTableComponent
   */
  public visibleDots: Array <boolean>;

  /**
   * Flag to enable/disable previous button.
   *
   * @type {boolean}
   * @memberof CarouselTableComponent
   */
  public isPreviousEnabled: boolean;

  /**
   * Flag to enable/disable next button.
   *
   * @type {boolean}
   * @memberof CarouselTableComponent
   */
  public isNextEnabled: boolean;

  /**
   * Current page of the table columns.
   *
   * @private
   * @type {number}
   * @memberof CarouselTableComponent
   */
  private currentPage: number;

  /**
   * Hidden column class is added to a cell when it is not visible.
   *
   * @private
   * @type {string}
   * @memberof CarouselTableComponent
   */
  private hiddenColumnClass: string;

  /**
   * Array of subscriptions for cleanup.
   *
   * @private
   * @type {Array<Subscription>}
   * @memberof CarouselTableComponent
   */
  private subscriptions: Array<Subscription>;

  /**
   * Constructor.
   *
   * @constructor
   * @memberof CarouselTableComponent
   */
  constructor(private elementRef: ElementRef) {
    this.hiddenColumnClass = 'swipe-hidden';
  }

  /**
   * Initialization.
   *
   * @memberof CarouselTableComponent
   */
  ngOnInit(): void {
    this.reset();
  }

  reset(): void {
    const isManaged = this.isManaged;
    // Initialize the component.
    this.subscriptions = [];
    this.visibleDots = [];
    this.currentPage = 0;
    this.isPreviousEnabled = false;
    this.isNextEnabled = false;
    this.staticColumns = this.staticColumns ? this.staticColumns : [];
    this.isManaged = isManaged === null || isManaged === undefined ? true : isManaged;
  }

  /**
   * Detects changes and updates the view.
   *
   * @param {SimpleChanges} changes Simple changes.
   * @memberof CarouselTableComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    const columnListChange = changes.columnWidthList;
    if (columnListChange && columnListChange.currentValue) {
      this.reset();
      this.update();
    }
  }

  /**
   * Initialization after view is rendered.
   *
   * @memberof CarouselTableComponent
   */
  ngAfterViewInit(): void {
    const refresh$ = this.refresh$;
    // Set resize observable
    this.setResizeObservable();
    if (refresh$) {
      const subscription = refresh$.subscribe(() => this.update());
      this.subscriptions.push(subscription);
    }
  }

  /**
   * Cleanup after view is destroyed.
   *
   * @memberof CarouselTableComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Sets the observable for screen resize and re-rendering table with new
   * set of visible columns.
   *
   * @private
   * @memberof CarouselTableComponent
   */
  private setResizeObservable(): void {
    const resize$ = fromEvent(window, 'resize');
    const subscription = resize$.subscribe(() => {
      // Number visible columns change with screen size.
      this.update();
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Previous button `Event` listener.
   *
   * @listens event:click
   * @param {Event} event
   * @memberof CarouselTableComponent
   */
  public previousPage(event: Event): void {
    this.previous.emit(event);
    event.preventDefault();
    event.stopPropagation();
    const currentPage = this.currentPage;
    this.currentPage = currentPage > 0 ? currentPage - 1 : currentPage;
    this.update();
  }

  /**
   * Next button `Event` listener.
   *
   * @listens event:click
   * @param {Event} event
   * @memberof CarouselTableComponent
   */
  public nextPage(event: Event): void {
    this.next.emit(event);
    event.preventDefault();
    event.stopPropagation();
    this.currentPage += 1;
    this.update();
  }

  /**
   * Updates the table columns based on the screen width.
   *
   * @private
   * @memberof CarouselTableComponent
   */
  private update(): void {
    const change = this.change;
    const currentPage = this.currentPage;
    const columnCount = this.getColumnCount();
    const visibleColumnCount: number = this.getVisibleColumnCount();
    const staticColumnCount = this.staticColumns.length;
    const visibleColumns: number[] = this.getVisibleColumns(visibleColumnCount, columnCount, currentPage);
    // Set
    this.isPreviousEnabled = currentPage > 0;
    this.isNextEnabled = currentPage + staticColumnCount + visibleColumnCount < columnCount;
    this.visibleDots = this.convertIndexToBoolean(columnCount, visibleColumns);
    this.setSwipeHiddenClass(visibleColumns);
    // Send new visible column indexes
    change.emit(visibleColumns);
  }

  /**
   * Converts visible columns array to an array of boolean values
   * for swipe dots display.
   *
   * @private
   * @param {number} columnCount
   * @param {number[]} visibleColumns
   * @returns {boolean[]}
   * @memberof CarouselTableComponent
   */
  private convertIndexToBoolean(columnCount: number, visibleColumns: number[]): boolean[] {
    const staticColumns = this.staticColumns;
    const booleanColumns: boolean[] = [];
    for (let i = 0; i < columnCount; i++) {
      if (staticColumns.indexOf(i) >= 0 || visibleColumns.indexOf(i) >= 0) {
        // sets visible column index to true.
        booleanColumns.push(true);
      } else {
        // sets hidden column index to false.
        booleanColumns.push(false);
      }
    }
    return booleanColumns;
  }

  /**
   * Returns an array of visible column indexes excluding static columns.
   *
   * @private
   * @param {number} visible
   * @param {number} page
   * @returns {number[]} Visible column indexes
   * @memberof CarouselTableComponent
   */
  private getVisibleColumns(visible: number, all: number, page: number): number[] {
    const staticColumns = this.staticColumns;
    const visibleColumns: number[] = [];
    // Start index for current column
    let start = page;
    // Remaining columns
    const available = all - start;
    // Last column index
    let end = available < visible ? all : start + visible;
    // Move start index backwards for last page if necesaary
    const indexDiff = end - start;
    start = indexDiff === visible ? start : start - (visible - indexDiff + 1);
    for (let i = 0; i < all; i++) {
      if (staticColumns.indexOf(i) >= 0) {
        visibleColumns.push(i);
        // Move the pointers to one step if static column
        start++;
        end++;
      } else if (i >= start && i < end) {
        visibleColumns.push(i);
      }
    }
    return visibleColumns;
  }

  /**
   * Sets visible column count based on the screen size available columns and
   * static columns.
   *
   * @private
   * @memberof CarouselTableComponent
   */
  private getVisibleColumnCount(): number {
    const staticColumns = this.staticColumns;
    const tableWidth = this.getMaxTableWidth();
    const columnWidths: number[] = this.getColumnWidthList();
    let count = 0;
    let staticColumnsWidth = 0;
    let currentWidth = 0;
    let availableWidth = 0;
    // Calculate static columns total width
    staticColumns.forEach((colIndex) => {
      staticColumnsWidth += columnWidths[colIndex];
    });
    availableWidth = tableWidth - staticColumnsWidth;
    columnWidths.forEach((width) => {
      currentWidth += width;
      if (currentWidth < availableWidth) {
        count++;
      }
    });
    return count;
  }

  /**
   * Returns array of column widths.
   *
   * @private
   * @returns {number[]} column count.
   * @memberof CarouselTableComponent
   */
  private getColumnWidthList(): number[] {
    const isManaged = this.isManaged;
    let columnWidthList: number[];
    let table: HTMLElement;
    let headerCells: NodeList;
    if (isManaged) {
      columnWidthList = [];
      table = this.getTableElement();
      headerCells = table.querySelectorAll('th');
      headerCells.forEach(item => columnWidthList.push((<HTMLElement>item).offsetWidth));
    } else {
      columnWidthList = this.columnWidthList;
    }
    return columnWidthList;
  }

  /**
   * Sets the `hiddenColumnClass` on hidden columns.
   *
   * @param visibleColumns Array of visible column indexes.
   * @memberof CarouselTableComponent
   */
  private setSwipeHiddenClass(visibleColumns: number[]): void {
    const isManaged = this.isManaged;
    let hiddenClass: string;
    let table: HTMLElement;
    let rows: NodeList;
    let cells: NodeList;
    if (isManaged) {
      hiddenClass = this.hiddenColumnClass;
      table = this.getTableElement();
      rows = table.querySelectorAll('tr');
      rows.forEach((row: HTMLElement) => {
        cells = row.querySelectorAll('th, td');
        cells.forEach((cell: HTMLElement, i: number) => {
          if (visibleColumns.indexOf(i) >= 0) {
            cell.classList.remove(hiddenClass);
          } else {
            cell.classList.add(hiddenClass);
          }
        });
      });
    }
  }

  /**
   * Resets hidden columns.
   *
   * @private
   * @memberof CarouselTableComponent
   */
  private removeSwipeHiddenClass(): void {
    const hiddenClass = this.hiddenColumnClass;
    const table: HTMLElement = this.getTableElement();
    const rows: NodeList = table.querySelectorAll('tr');
    let cells: NodeList;
    rows.forEach((row: HTMLElement) => {
      cells = row.querySelectorAll('th, td');
      cells.forEach((cell: HTMLElement) => {
        cell.classList.remove(hiddenClass);
      });
    });
  }

  /**
   * Returns `HTMLTableElement` object with the id.
   *
   * @private
   * @returns {HTMLElement}
   * @memberof CarouselTableComponent
   */
  private getTableElement(): HTMLElement {
    const tableId: string = this.targetId;
    const table: HTMLElement = document.getElementById(tableId);
    return table;
  }

  /**
   * Returns number of columns in the table.
   *
   * @private
   * @returns {number} column count.
   * @memberof CarouselTableComponent
   */
  private getColumnCount(): number {
    let size: number;
    let table: HTMLElement;
    let headers: NodeList;
    if (this.isManaged) {
      table = this.getTableElement();
      headers = table.querySelectorAll('th');
      size = headers.length;
    } else {
      size = this.columnWidthList.length;
    }
    return size;
  }

  /**
   * Returns max table width.
   *
   * @private
   * @returns {number} max table width.
   * @memberof CarouselTableComponent
   */
  private getMaxTableWidth(): number {
    const maxWidth: number = this.elementRef.nativeElement.parentElement.offsetWidth;
    return maxWidth;
  }

}
