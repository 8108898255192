import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'ns-input-with-counter',
    templateUrl: './input-with-counter.component.html',
    styleUrls: ['./input-with-counter.component.scss']
})
export class InputWithCounterComponent implements OnInit {

    @Input() label: string;
    @Input() value: string;
    @Input() placeholder: string;
    @Input() maxLength: number;
    @Input() errorMsg: string;
    @Output() valueChange = new EventEmitter<string>();
    @Output() focus = new EventEmitter<void>();

    titleBorderClass: string;
    titleTextClass: string;

    constructor() {
    }

    ngOnInit(): void {
        this.validateInputValue();
    }

    onValueChange($event: KeyboardEvent) {
        this.validateInputValue();
        this.valueChange.emit(this.value);
        if($event.code == "KeyC"){
            $event.stopPropagation();
        }
    }

    onFocus() {
        this.focus.emit();
    }

    validateInputValue() {
        const valueLength = this.value?.length;
        switch (true) {
            case (valueLength > this.maxLength):
                this.titleBorderClass = 'max-text';
                this.titleTextClass = 'red-color';
                break;
            case (valueLength > (this.maxLength / 2)):
                this.titleBorderClass = 'min-text';
                this.titleTextClass = 'yellow-color';
                break;
            case (valueLength > 0):
                this.titleBorderClass = 'highlight-border';
                this.titleTextClass = 'blue-color';
                break;
            default:
                this.titleBorderClass = '';
                this.titleTextClass = '';
        }
    }
}
