export enum deliverableInsightAH{
    CompareFilter = 'compareFilter',
    ConceptsFilter = 'conceptsFilter',
    ParentBrandsFilter = 'parentBrandsFilter',
    VarietyFilter = 'varietyFilter',
    CompConceptsFilter = 'compConceptsFilter',
    DatasetsFilter = 'datasetsFilter',
    ValuesFilter = 'valuesFilter',
    AttributesFilter = 'attributesFilter',
    SubgroupsFilter = 'subgroupsFilter',
    SubgroupFilter = 'subgroupFilter',
    PlansFilter = 'plansFilter',
    EstimatesFilter = 'estimatesFilter',
}
export enum InvalidSubgroupLabels {
    AllSubgroups = "All Subgroups",
    NoSubgroupsSelected = "No Subgroups Selected",
    AllPlans = "All Plans",
    NoPlansSelected = "No Plans Selected",
    AllEstimates = "All Estimates",
    NoEstimatesSelected = "No Estimates Selected"
}

export enum InvalidConceptLabels {
    AllConcepts = "All Concepts",
    NoConceptsSelected = "No Concepts Selected",
    AllVarietyContributions = "All Variety Contributions",
    NoVarietyContributionsSelected = "No Variety Contributions Selected",
    AllDesigns = "All Designs",
    NoDesignsSelected = "No Designs Selected"
}

export enum InvalidCompetitorLabels {
    AllCompetitors = "All Competitors",
    NoCompetitorsSelected = "No Competitors Selected"
}

export enum InvalidAttributeLabels {
    AllAttributes = "All Attributes",
    NoAttributesSelected = "No Attributes Selected"
}
