import {CaptureOptions} from './../node-capture/capture-options';
import {NodeCaptureService} from '@platform/node-capture/node-capture.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from '@app/core/config/app-config.service';
import {Store} from '@ngrx/store';
import {Filter} from '@platform/models/filter.model';
import {State} from '@platform/store/state/app.state';
import {Observable} from 'rxjs';
import {FilterService} from './filter.service';
import {ReportService} from './report.service';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {
    deliverableInsightAH,
    InvalidAttributeLabels,
    InvalidCompetitorLabels,
    InvalidConceptLabels,
    InvalidSubgroupLabels
} from "@platform/deliverable-insight/deliverable-insight-title.enum";

@Injectable({
    providedIn: 'root'
})
export class DeliverableInsightService {

    constructor(
        private store: Store<State>,
        private filterService: FilterService,
        private httpClient: HttpClient,
        private reportService: ReportService,
        private nodeCaptureService: NodeCaptureService,
        private cs: AppConfigService) {
    }

    /**
     * Return filter object from the store.
     *
     * @param deliverableType
     * @memberOf DeliverableInsightService
     */
    getFilters(deliverableType: string): Observable<Filter> {
        return this.filterService.get<Filter>(deliverableType);
    }

    /**
     * Create deliverable insight.
     *
     * @param insightDetails
     * @memberOf DeliverableInsightService
     */
    public createInsight(insightDetails: DeliverableInsight): Observable<any> {
        let reportId = null;
        const report$ = this.reportService.get();
        report$.subscribe(
            report => {
                reportId = report.id;
            }
        );
        const url = `${this.cs.config.reporting.url}/reports/${reportId}/insights`;
        return this.httpClient.post(url, insightDetails);
    }

    /**
     * Clone current page content and remove script,
     * fix image path.
     * @memberOf DeliverableInsightService
     */
    public getCleanHTML(removeFlyoutClass = false): string {
        let markup = <HTMLElement>this.getClonedHTML();
        const base = markup.getElementsByTagName('base')[0];
        base.setAttribute('href', '{host-url}');
        markup = this.hideMenuPanel(markup);
        markup = this.hideStickyHeader(markup);
        markup = this.hideNavBar(markup);
        markup = this.hideInsightCreationForm(markup);
        markup = this.fixedFilterPosition(markup);
        markup = this.hideSwipeTablePosition(markup);
        markup = this.greyedOutFilter(markup);
        markup = this.removeScripts(markup);
        markup = this.fixImageSrcPath(markup);

        if (removeFlyoutClass) {
            markup = this.removeFlyoutMenuClass(markup);
        }
        let html = this.getDocType() + markup.innerHTML.replace(/\n|\t/g, ' ');
        html = html.replace(/\x3C!--.*?-->/g, '');//remove comments
        return html;
    }

    /**
     * Return insight section of page based that has class 'insight-html-root'.
     * This will save the html as a string to display on insights dashboard.
     * @memberOf DeliverableInsightService
     */
    public getInsightHTML(nodeProcessor?): string {
        const selector = '.insight-html-root';
        const removeClass = 'remove-from-insights';
        const ignoreDeliverableClass = 'deliverable-insight-';
        const ignoreForecastClass = 'forecast-insight-';
        const options: CaptureOptions = new CaptureOptions();
        options.node.remove.push(removeClass);
        options.cssClass.ignore.push(ignoreDeliverableClass);
        options.cssClass.ignore.push(ignoreForecastClass);
        const nodeString = this.nodeCaptureService.capture(selector, options);
        return nodeString;
    }

    /**
     * To generate automated titles for the automated headlines in insights
     * @param deliverableType string
     * @param compareFilter default false
     * @param conceptLabelFromDeliverable default null only applies to PurchasePreference as of now
     * @param subgroupLabelFromDeliverable default null only applies to PurchasePreference as of now
     */
    public generateInsightTitle(deliverableType: string, compareFilter: boolean = false, conceptLabelFromDeliverable = null, subgroupLabelFromDeliverable = null) {
        const getLabel = (id) => document.getElementById(id)?.querySelector('div')?.dataset?.labelText || null;

        const compareLabel = getLabel(deliverableInsightAH.CompareFilter);
        const conceptLabel: any = !conceptLabelFromDeliverable ? getLabel(deliverableInsightAH.ConceptsFilter) || getLabel(deliverableInsightAH.ParentBrandsFilter) || getLabel(deliverableInsightAH.VarietyFilter) : conceptLabelFromDeliverable;
        const competitorConceptsLabel: any = getLabel(deliverableInsightAH.CompConceptsFilter);
        const datasetsLabel = getLabel(deliverableInsightAH.DatasetsFilter) || getLabel(deliverableInsightAH.ValuesFilter);
        const attributeLabel: any = getLabel(deliverableInsightAH.AttributesFilter);
        const subgroupLabel: any = !subgroupLabelFromDeliverable ? getLabel(deliverableInsightAH.SubgroupFilter) || getLabel(deliverableInsightAH.SubgroupsFilter) || getLabel(deliverableInsightAH.PlansFilter) || getLabel(deliverableInsightAH.EstimatesFilter) : subgroupLabelFromDeliverable;

        const subgroupInsightTitle = subgroupLabel && !Object.values(InvalidSubgroupLabels).includes(subgroupLabel) ? subgroupLabel : '';
        let conceptsInsightTitle = conceptLabel && !Object.values(InvalidConceptLabels).includes(conceptLabel) ? (subgroupInsightTitle ? `${conceptLabel} for ` : conceptLabel) : '';

        deliverableType = compareFilter ? `${deliverableType} ${compareLabel}` : deliverableType;
        deliverableType = deliverableType.replace(' View of Top Combinations', '');
        const deliverableTitle = (conceptsInsightTitle || subgroupInsightTitle) ? `${deliverableType}:` : deliverableType;

        const datasetsFilterTitle = datasetsLabel ? `in ${datasetsLabel}` : '';
        conceptsInsightTitle = competitorConceptsLabel && !Object.values(InvalidCompetitorLabels).includes(competitorConceptsLabel)
            ? (conceptsInsightTitle ? `${conceptLabel} and ${competitorConceptsLabel} for ` : `${competitorConceptsLabel} for `)
            : conceptsInsightTitle;
        const attributesFilterTitle = attributeLabel && !Object.values(InvalidAttributeLabels).includes(attributeLabel) ? `for ${attributeLabel}` : '';

        const finalTitle = `${deliverableTitle} ${conceptsInsightTitle}${subgroupInsightTitle} ${datasetsFilterTitle || attributesFilterTitle}`.trim();
        return this.cleanHTMLString(finalTitle);
    }

    cleanHTMLString(str) {
        const symbols = {
            "&amp;": "&",
            "&lt;": "<",
            "&gt;": ">",
            "&quot;": "\"",
            "&apos;": "'"
        }
        let newStr = str;
        for (const symbol in symbols) {
          if (str.indexOf(symbol) >= 0) {
            newStr = str.replaceAll(symbol, symbols[symbol])
          }
        }
        return newStr.replace(/\s{2,}/g,' ');
      }

    /**
     * Gets doc type of the page.
     * @memberOf DeliverableInsightService
     */
    public getDocType(): string {
        const node = document.doctype;
        return '<!DOCTYPE ' +
            node.name +
            (node.publicId ? ' PUBLIC "' + node.publicId + '"' : '') +
            (!node.publicId && node.systemId ? ' SYSTEM' : '') +
            (node.systemId ? ' "' + node.systemId + '"' : '') + '>';
    }

    /**
     * return cloned html.
     *
     * @memberOf DeliverableInsightService
     */
    public getClonedHTML(): Node {
        // const removalClassName = 'screen-capture-marked-for-removal';
        const html = document.getElementsByTagName('html')[0];
        return html.cloneNode(true);
    }

    /**
     * Removes all the script tags from the DOM
     *
     * @param markup This is cloned HTML
     * @memberOf DeliverableInsightService
     */
    public removeScripts(markup) {
        const elements = markup.getElementsByTagName('script');
        while (elements[0]) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        return markup;
    }

    /**
     * Replaces image src with base64 encoded value of the image.
     *
     * @param markup This is cloned HTML
     * @memberOf DeliverableInsightService
     */
    public fixImageSrcPath(markup) {
        const nodes = markup.getElementsByClassName('insight-image');
        for (let i = 0, len = nodes.length; i !== len; ++i) {
            nodes[i].setAttribute('src', 'data:image/png;base64,' + this.imageToBase64(nodes[i]));
        }
        return markup;
    }

    /**
     * Returns base64 encoded value for given image
     *
     * @param img This is cloned HTML
     * @memberOf DeliverableInsightService
     */
    public imageToBase64(img) {
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, '').replace('data:,', '');
    }

    /**
     * Hide menu panel
     * @param markup
     */
    public hideMenuPanel(markup: HTMLElement) {
        const matMenuPanelDiv = markup.getElementsByClassName('mat-menu-panel');
        if (matMenuPanelDiv[0]) {
            matMenuPanelDiv[0].classList.add('hide');
        }
        return markup;
    }

    /**
     * Hide sticky headers
     * @param markup
     */
    public hideStickyHeader(markup: HTMLElement) {
        const stickHeaderDiv = markup.getElementsByClassName('sticky-header-container');
        if (stickHeaderDiv.length > 0) {
            stickHeaderDiv[0].classList.add('hide');
        }
        return markup;
    }

    /**
     * Hide Nav bar
     * @param markup
     */
    public hideNavBar(markup: HTMLElement) {
        const navBarDiv: HTMLCollectionOf<Element> = markup.getElementsByClassName('insights-hide-element');
        if (navBarDiv) {
            for (let i = 0; i < navBarDiv.length; i++) {
                navBarDiv[i].classList.add('hidden');
            }
        }
        return markup;
    }

    /**
     * Hide Nav bar
     * @param markup
     */
    public hideInsightCreationForm(markup: HTMLElement) {
        const navBarDiv = markup.getElementsByClassName('insight-creation-form');
        navBarDiv[0]?.classList.add('hidden');
        return markup;
    }

    /**
     * fixed filter position
     * @param markup
     */
    public fixedFilterPosition(markup: HTMLElement) {
        const navBarDiv = markup.getElementsByClassName('filters-sticky-container');
        if (navBarDiv.length > 0) {
            navBarDiv[0].classList.remove('filters-sticky-container');
        }
        return markup;
    }

    /**
     * Hide swipe table
     * @param markup
     */
    public hideSwipeTablePosition(markup: HTMLElement) {
        const swipeTableDiv = markup.getElementsByClassName('sticky-swipe-table-container');
        if (swipeTableDiv.length > 0) {
            swipeTableDiv[0].classList.add('hidden');

        }
        return markup;
    }

    /**
     * grey out filter
     * @param markup
     */
    public greyedOutFilter(markup: HTMLElement) {
        const filterDiv = markup.getElementsByClassName('air-filters-bar');
        if (filterDiv.length > 0) {
            const matBtn = filterDiv[0].getElementsByClassName('mat-button');
            for (let i = 0, len = matBtn.length; i !== len; ++i) {
                const span = matBtn[i].getElementsByTagName('span');
                span[0].classList.add('c-gray-2');
            }
        }

        return markup;
    }

    /**
     * Fix for the issue cause in exporting as .png
     * while content is greater than a certain height
     * Removing flyourMenu's fixed Height for export
     * @param markup
     */
    public removeFlyoutMenuClass(markup: HTMLElement) {
        const fluidContainerDiv = markup.getElementsByClassName('fluid-container');
        if (fluidContainerDiv.length > 0) {
            fluidContainerDiv[0].classList.remove('fluid-container');
        }
        return markup;
    }
}
