import { ReportState } from '@platform/store/state/report.state';
import { State } from '@platform/store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * Report feature selector.
 *
 * @export
 * @property
*/
export const selectReportFeature = createFeatureSelector< ReportState>('report');
/**
 * Report selector.
 *
 * @export
 * @property
*/
export const selectReport = createSelector(
  selectReportFeature,
  (state: ReportState) => state
);
