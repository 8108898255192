import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {GapAnalysisComponent} from '@products/restage/gap-analysis/gap-analysis.component';
import {InteractionsComponent} from '@products/restage/interactions/interactions.component';
import {PerformanceComponent} from '@products/restage/performance/performance.component';
import {ConfigurableComponents} from '@app/deliverables/configurable-components.enum';

const deliverableComponents: { [type: string]: any } = Object.assign({}, ConfigurableComponents);
deliverableComponents[DeliverableType.GAP_ANALYSIS.type] = GapAnalysisComponent;
deliverableComponents[DeliverableType.INTERACTIONS.type] = InteractionsComponent;
deliverableComponents[DeliverableType.PERFORMANCE.type] = PerformanceComponent;

export {deliverableComponents};
