import { Action, createReducer, on } from '@ngrx/store';
import { subgroupAdapter, initialState } from '@platform/store/adapters/subgroup.adapter';
import { SubgroupState } from '@platform/store/state/subgroup.state';
import { addSubgroup, addSubgroups, updateSubgroup } from '@platform/store/actions/subgroup.actions';

/**
 * Reducer for all subgroup actions.
 */
const subgroupReducer = createReducer(
  initialState,
  on(addSubgroup, (state, { subgroup }) => {
    return subgroupAdapter.addOne(subgroup, state);
  }),
  on(addSubgroups, (state, { subgroups }) => {
    return subgroupAdapter.addMany(subgroups, state);
  }),
  on(updateSubgroup, (state, { subgroup }) => {
    return subgroupAdapter.upsertOne(subgroup, state);
  })
);

/**
 * Subgroups `NgRx` reducer.
 *
 * @export
 * @param {SubgroupState} state
 * @param {SubgroupActions} action
 * @returns {SubgroupState}
 */
export function reducer(state: SubgroupState | undefined, action: Action): SubgroupState {
  return subgroupReducer(state, action);
}
