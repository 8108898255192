import {skipWhile} from 'rxjs/operators';
import {
    addInsight,
    updateInsight,
    updateInsights,
    resetInsights,
    deleteInsights
} from './../store/actions/insight.action';
import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Attachment, Insight} from '@platform/models/insight.model';
import {select, Store} from '@ngrx/store';
import {State} from '@platform/store/state/app.state';
import {switchMap} from 'rxjs/operators';
import {selectReportInsights} from '@platform/store/selectors/insight.selector';
import {deleteInsight, addInsights} from '@platform/store/actions/insight.action';
import {ToasterService} from '@platform/services/toaster.service';
import {Report} from '@platform/models/report.model';
import {SpinnerService} from '@platform/services/spinner.service';
import {AppAsyncTask} from "@platform/models/app-async-task.model";
import {AppAsyncTaskService} from "@platform/services/app-async-task.service";
import {PrivilegeService} from '@platform/services/privilege.service';
import {UserInfo} from '@platform/models/user-info.model';

/**
 * This service has operations for fetching, creating, updating and loading Insights.
 *
 * @example
 * constructor(private insightService: InsightService) { }
 *
 * @export
 * @class InsightService
 */
@Injectable({
    providedIn: 'root'
})
export class InsightService {

    /**
     * Creates an instance of InsightService.
     *
     * @constructor
     * @param {Store<State>} store
     * @param {HttpClient} httpClient
     * @param {ReportService} reportService
     * @param {AppConfigService} appConfigService
     * @param {ToasterService} toasterService
     * @param spinnerService
     * @param appAsyncTaskService
     * @param privilegeService
     * @memberOf InsightService
     */
    constructor(
        private store: Store<State>,
        private httpClient: HttpClient,
        private reportService: ReportService,
        private appConfigService: AppConfigService,
        private toasterService: ToasterService,
        private spinnerService: SpinnerService,
        private appAsyncTaskService: AppAsyncTaskService,
        private privilegeService: PrivilegeService
    ) {
    }

    /**
     * Returns observable of Insights for the current report object
     * from the store if available else fetch it using API.
     *
     * @example
     * const Insights$ = InsightService.getReportInsights();
     *
     * @returns {Observable<Array<Insight>>}
     * @memberOf InsightService
     */
    public getReportInsights(): Observable<Array<Insight>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                this.load(report.id);
                return this.fetchReportInsightsFromStore(report.id);
            }),
            skipWhile(insights => insights.length === 0)
        );
    }

    /**
     * Loads all Insights associated with the current report into
     * the store.
     *
     * @example
     * InsightService.load('1');
     *
     * @param {string} reportId The report id
     * @memberOf InsightService
     */
    private load(reportId: string): void {
        const insights$ = this.fetchReportInsightsFromStore(reportId);
        insights$.subscribe(insights => {
            if (!insights || insights.length === 0) {
                this.fetchFromAPI(reportId).subscribe((result: Array<Insight>) => {
                    if (result.length > 0) {
                        this.loadOnStore(result);
                    }
                });
            }
        });
    }

    /**
     * Resets Insights State in Store.
     *
     * @public
     * @memberOf InsightService
     */
    public resetInsightState() {
        this.store.dispatch(resetInsights());
    }

    /**
     * Returns an observable of Insights fetched using the API.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<Insight>>}
     * @memberOf InsightService
     */
    fetchFromAPI(reportId: string, id?: string): Observable<Array<Insight> | Insight> {
        if (id) {
            const url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/insights/${id}`;
            return this.httpClient.get<Insight>(url);
        } else {
            const url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/insights`;
            return this.httpClient.get<Array<Insight>>(url);
        }
    }

    /**
     * Loads a list of Insights into the store.
     *
     * @private
     * @param {Array<Insight>} insights Array of Insights.
     * @memberOf InsightService
     */
    private loadOnStore(insights: Array<Insight>): void {
        this.store.dispatch(addInsights({insights}));
    }

    /**
     * Fetch all Insights associated with the current report
     * from the store.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<Insight>>}
     * @memberOf InsightService
     */
    private fetchReportInsightsFromStore(reportId: string): Observable<Array<Insight>> {
        return this.store.pipe(
            select(selectReportInsights, {reportId})
        );
    }

    /**
     * Save a single Insight using the api and update the store.
     *
     * @param {Insight} insight
     * @memberOf InsightService
     */
    public addInsight(insight: Insight): void {
        this.spinnerService.setSpinnerObs();
        const baseURL = this.getBaseURL();
        const url = `${baseURL}/insights`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const insights$ = this.httpClient.post<Insight>(url, insight, {headers});
        insights$.subscribe(
            (insightRes: Insight) => {
                this.resetInsightState();
                this.spinnerService.spinnerObs$.next(false);
                console.log('Successfully Added');
            },
            (err: HttpErrorResponse) => {
                this.spinnerService.spinnerObs$.next(false);
                this.showError(err);
                this.removeFromStore(insight.id);
            }
        );
    }

    /**
     * Add insight into the store.
     *
     * @private
     * @param {Insight} insight
     * @memberOf InsightService
     */
    private addInsightInStore(insight: Insight): void {
        this.store.dispatch(addInsight({insight}));
    }

    /**
     * Update all Insights using the api and update store
     *
     * @param oldInsights {Array<Insight>}
     * @param newInsights {Array<Insight>}
     */
    public updateAllInsights(oldInsights: Array<Insight>, newInsights: Array<Insight>, isManageInsights: boolean = false) {
        this.spinnerService.setSpinnerObs();
        const baseURL = this.getBaseURL();
        const url = `${baseURL}/insights`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const insights$ = this.httpClient.patch(url, {insights: newInsights}, {headers});
        insights$.subscribe(
            (insightRes: string) => {
                if (isManageInsights) {
                    this.resetInsightState();
                } else {
                    this.updateAllInsightsInStore(newInsights);
                }
                this.spinnerService.spinnerObs$.next(false);
                console.log('Insights Successfully Updated: ' + insightRes);
            },
            (err: HttpErrorResponse) => {
                this.spinnerService.spinnerObs$.next(false);
                this.showError(err);
                if (oldInsights) {
                    this.loadOnStore(oldInsights);
                }
            }
        );
    }

    /**
     * Updates all Insights into the store.
     *
     * @private
     * @param {Array<Insight>} insights
     * @memberOf InsightService
     */
    private updateAllInsightsInStore(insights: Array<Insight>): void {
        this.store.dispatch(updateInsights({insights}));
    }

    /**
     * Update Insight using the api and update store
     *
     * @param insight {Insight}
     */
    public updateInsight(insight: Insight) {
        this.spinnerService.setSpinnerObs();
        const baseURL = this.getBaseURL();
        const url = `${baseURL}/insights/${insight.id}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const insights$ = this.httpClient.put<Insight>(url, insight, {headers});
        insights$.subscribe(
            (insightRes: Insight) => {
                this.spinnerService.spinnerObs$.next(false);
                this.updateStore(insight);
            },
            (err: HttpErrorResponse) => {
                this.spinnerService.spinnerObs$.next(false);
                this.showError(err);
            }
        );
    }

    /**
     * Updates a Insight into the store.
     *
     * @private
     * @param {Insight} insight
     * @memberOf InsightService
     */
    private updateStore(insight: Insight): void {
        this.store.dispatch(updateInsight({insight}));
    }

    /**
     * Deletes Insight object through api and store.
     *
     * @param {string} deleteInsightId the Insight.
     * @param oldInsights {Array<Insight>}
     * @param newInsights {Array<Insight>}
     */
    public deleteInsight(oldInsights: Array<Insight>, newInsights: Array<Insight>, deleteInsightId: string): void {
        this.spinnerService.setSpinnerObs();
        const baseURL = this.getBaseURL();
        const url = `${baseURL}/insights/${deleteInsightId}`;
        const response$ = this.httpClient.delete(url);
        response$.subscribe(
            () => {
                this.spinnerService.spinnerObs$.next(false);
                this.removeFromStore(deleteInsightId);
                console.log('Insight Successfully Deleted');
                if (newInsights.length > 0) {
                    this.updateAllInsights(oldInsights, newInsights);
                }
            },
            (err: HttpErrorResponse) => {
                this.spinnerService.spinnerObs$.next(false);
                this.showError(err);
                this.loadOnStore(oldInsights);
            }
        );
    }

    /**
     * Deletes Insight object through api and store.
     *
     * @param oldInsights {Array<Insight>}
     * @param newInsights {Array<Insight>}
     * @param deletedInsightsId
     */
    public deleteInsights(oldInsights: Array<Insight>, newInsights: Array<Insight>, deletedInsightsId: Array<string>): void {
        this.spinnerService.setSpinnerObs();
        const baseURL = this.getBaseURL();
        const url = `${baseURL}/insights/deletes`;
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        const response$ = this.httpClient.patch(url, {'ids': deletedInsightsId}, httpOptions);
        response$.subscribe(
            () => {
                this.spinnerService.spinnerObs$.next(false);
                this.removeInsightsFromStore(deletedInsightsId);
                console.log('Insight Successfully Deleted');
                if (newInsights.length > 0) {
                    const updatedInsights = this.updatePositions(newInsights);
                    this.updateAllInsights(oldInsights, updatedInsights, true);
                }
            },
            (err: HttpErrorResponse) => {
                this.spinnerService.spinnerObs$.next(false);
                this.showError(err);
                this.loadOnStore(oldInsights);
            }
        );
    }

    /**
     * Deletes the Insight from store.
     *
     * @private
     * @param {string} id
     * @memberOf InsightService
     */
    private removeFromStore(id: string): void {
        this.store.dispatch(deleteInsight({id: id}));
    }


    /**
     * Deletes the Insight from store.
     *
     * @private
     * @param {string} id
     * @memberOf InsightService
     */
    private removeInsightsFromStore(id: string[]): void {
        this.store.dispatch(deleteInsights({id: id}));
    }

    public setAttachmentURLs(insight: Insight): Array<Attachment> {
        const attachments: Array<Attachment> = insight.attachments ? insight.attachments : [];
        const attachmentData: Array<Attachment> = [];
        if (attachments.length > 0) {
            attachments.forEach((attachment: Attachment) => {
                const attachmentObject: Attachment = {
                    imageURL: `${this.getBaseURL()}/insights/${insight.id}?fileName=${attachment.name}`,
                    name: attachment.name,
                    dataPath: attachment.dataPath
                };
                attachmentData.push(attachmentObject);
            });
        }
        return attachmentData;
    }

    /**
     * Returns base URL
     *
     * @memberOf InsightService
     */
    private getBaseURL(): string {
        let reportId = null;
        const report$ = this.reportService.get();
        report$.subscribe(
            report => {
                reportId = report.id;
            }
        );
        return `${this.appConfigService.config.reporting.url}/reports/${reportId}`;
    }

    /**
     * Show Error Response in snack bar
     *
     * @param err {HttpErrorResponse}
     * @memberOf InsightService
     */
    private showError(err: HttpErrorResponse) {
        if (err instanceof Error) {
            console.log(`An error occurred ${err.error.message}`);
        } else {
            console.log(`${err.status}, body is: ${err.message}`);
        }
        this.toasterService.openSnackBar('Error in Insights', 'error');
    }

    /**
     *Get deliverable view html
     * @param insight {Insight}
     * @memberOf InsightService
     */
    public getDeliverableViewHtml(insight: Insight): Observable<string> {
        const htmlFileName = insight?.deliverable?.htmlFile?.fileName;
        if (htmlFileName) {
            const baseURL = this.getBaseURL();
            const url = `${baseURL}/insights/${insight.id}?fileName=${htmlFileName}`;
            const headers = new HttpHeaders({'Content-Type': 'text/html'});
            return this.httpClient.get(url, {headers, responseType: 'text'});
        }
        return of("");
    }

    /**
     *Get forecast view html
     * @param insight {Insight}
     * @memberOf InsightService
     */
    public getForecastViewHtml(insight) {
        const baseURL = this.getBaseURL();
        const url = `${baseURL}/insights/${insight.id}?fileName=${insight.forecast.htmlFile?.fileName}`;
        const headers = new HttpHeaders({'Content-Type': 'text/html'});
        return this.httpClient.get(url, {headers, responseType: 'text'});
    }

    public exportInsightPresentation(report: Report, user: UserInfo, isForecastingExists: Boolean): void {
        this.spinnerService.setSpinnerObs();
        const baseURI = this.getBaseURL();
        if (report) {
            let url = `${baseURI}/insights?async=1&fileName=${report.name}`;
            if (isForecastingExists) {
                const canViewForecasting = this.privilegeService.canViewForecasting(user, report.collaborators);
                const canViewDeliverables: boolean = this.privilegeService.canViewDeliverables(user, report.collaborators);
                url = `${baseURI}/insights?async=1&viewForecasting=${canViewForecasting}&viewDeliverables=${canViewDeliverables}&fileName=${report.name}`;
            }
            this.httpClient.get<AppAsyncTask>(url).subscribe({
                next: (task: AppAsyncTask) => {
                    this.appAsyncTaskService.pollUntilTaskFinished(task.id, task.reportId).subscribe((task: AppAsyncTask) => {
                        this.appAsyncTaskService.download(task).subscribe({
                            next: (response) => {
                                this.appAsyncTaskService.downloadFile(response, `${report.name}.pptx`, 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
                                this.spinnerService.spinnerObs$.next(false);
                            }, error: (err: HttpErrorResponse) => {
                                if (err instanceof Error) {
                                    console.log(`An error occurred ${err.error.message}`);
                                } else {
                                    console.log(`${err.status}, body was: ${err.message}`);
                                }
                                this.spinnerService.spinnerObs$.next(false);
                                this.toasterService.openSnackBar('Error while saving the file', 'error');
                            }
                        });
                    })
                }
            });

        }
    }

    /**
     * Updates the new index value.
     *
     * @param insights {Array of Insight}
     * @memberOf InsightsComponent
     */
    public updatePositions(insights: Array<Insight>): Array<Insight> {
        const updatedInsights: Array<Insight> = [];
        insights.forEach((insight: Insight, index: number) => {
            const updatedInsight = {...insight};
            updatedInsight.position = index;
            updatedInsights.push(updatedInsight);
        });
        return updatedInsights;
    }

    /**
     * Deep Copy of insights.
     * Returns a copy in a new object
     *
     * @param insights {Array<insights>}
     * @memberof InsightsComponent
     */
    public cloneInsights(insights: Array<Insight>): Array<Insight> {
        return JSON.parse(JSON.stringify(insights));
    }

    public getInsightFilterData<T>(reportId: string, insightId: string): Observable<T> {
        if (insightId) {
            return this.fetchFromAPI(reportId, insightId).pipe(
                switchMap((insight: Insight) => {
                    return of(insight.deliverable.filter as T);
                }));
        } else {
            return of(null);
        }
    }

    showDeliverableChangeError() {
        this.toasterService.openSnackBar('Error to edit insight due to change in deliverable data. Please delete and recreate the insight.', 'error');
    }
}
