/**
 * Deliverable view types for Survey Question.
 *
 * @export
 * @enum {string}
 */
export enum DeliverableViewType {
  /**
   * Concept deliverable view type.
   */
  SUMMARY = 'summary-view',

  DETAILED = 'detailed-view',

  ALTERNATIVE = 'alternative-combination'
}
