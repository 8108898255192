import { NgModule } from '@angular/core';
import { Routes, RouterModule, ResolveStart, Router } from '@angular/router';
import { ReportComponent } from '@platform/report/report.component';
import { PageNotFoundComponent } from '@basesstudio/ngx-components';
import { AccessForbiddenComponent } from '@basesstudio/ngx-components';
import { ProductServiceFactory } from '@platform/services/product-factory.service';
import { RouterService } from '@platform/services/router.service';
import { filter } from 'rxjs';

/**
 * App level routes.
 *
 * @property routes
 * @private
 */
const routes: Routes = [
  {
    path: ':productType/reports/:reportId',
    component: ReportComponent
  },
  { path: 'forbidden', component: AccessForbiddenComponent },
  { path: '**', component: PageNotFoundComponent }
];

/**
 * Platform routes module.
 *
 * @export
 * @class AppRoutingModule
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(
    private router: Router,
    private productServiceFactory: ProductServiceFactory,
    private routerService: RouterService
  ) {
    // Register subscriber for updating product module routes.
    this.router.events.pipe(
      filter(evt => evt instanceof ResolveStart)
    ).subscribe(() => this.loadProductModule());
  }

  /**
   * Loads product module based on the route.
   *
   * @private
   */
  private loadProductModule(): void {
    const router = this.router;
    const url = this.routerService.getCurrentURL();
    const navigationState = router.getCurrentNavigation()?.extras.state;
    const product = this.routerService.getPathParam('productType');
    const productServiceFactory = this.productServiceFactory;
    const productService = productServiceFactory.getService();

    const config = router.config;
    const reportsRoute = config.find(route => route.path.indexOf('reports') > -1);
    // Return if the current module is loaded.
    if (navigationState && navigationState.product === product) {
      return;
    }
    reportsRoute.loadChildren = productService.getModule;
    reportsRoute.data = { preload: true };
    router.resetConfig(config);
    router.navigateByUrl(url, { state: { product }});
  }
}
