<ns-deliverable-configuration-header
    [headerText]="'platform.deliverable.financial-potential.label' | translate"
    [showBackButton]=true
    (backBtnClickEvent)="returnBack()"
    (closeBtnClickEvent)="onCloseButtonClick()"></ns-deliverable-configuration-header>
<ng-container  *ngFor="let item of configKeys">
    <div  class="item-row border-bottom main-menu-item">
        <span *ngIf="item === 'trialUnits'" class="display-name-span">Units</span>
        <span *ngIf="item !== 'trialUnits'" class="display-name-span">{{item | keyTitleCase}}</span>
        <button mat-icon-button
                class="visibility-button text-radioBtn-visbility"
                (click)="toggleView(item)">
                <span class="sif sif-gray cursor-pointer"
                      [ngClass]="configMap[item] === true ? 'sif-eye-enabled sif-12' : 'sif-eye-disabled sif-15'"></span>
        </button>
    </div>
</ng-container>
