import { ViewMetaInfo } from '@platform/models/view-meta-info.model';
import { createAction, props } from '@ngrx/store';

/**
 * Action for adding the viewMetaInfo.
 *
 * @export
 */
export const addViewMetaInfo = createAction(
  '[ViewMetaInfo] Add ViewMetaInfo',
  props<{ viewMetaInfo: ViewMetaInfo }>()
);

/**
 * Action for updating the viewMetaInfo.
 *
 * @export
 */
export const updateViewMetaInfo = createAction(
  '[ViewMetaInfo] Update ViewMetaInfo',
  props<{ viewMetaInfo: ViewMetaInfo }>()
);
