import { Component, OnInit } from '@angular/core';
import {FilterService} from '@platform/services/filter.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {UserService} from '@platform/services/user.service';
import {Concept} from '@platform/models/concept.model';
import {Subscription} from 'rxjs';
import {ScoreCardMetaInfo} from '@products/innovation-screener/score-card/models/view-meta-info.model';
import {DeliverableType} from '@products/innovation-screener/deliverable-type.enum';
import {debounceTime, skipWhile, take} from 'rxjs/operators';
import {defaultScoreCardFilter} from '@products/innovation-screener/score-card/models/default-additional-info-filter';
import {ScoreCardFilter} from '@products/innovation-screener/score-card/models/filter.model';

@Component({
  selector: 'ecs-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss']
})
export class ScoreCardComponent implements OnInit {

  /**
   * Deliverable type.
   *
   * @type {string}
   */
  deliverableType: string;

  /**
   * ScoreCard Concept object for image view.
   *
   * @type {Concept}
   */
  public scoreCardConcept: Concept;

  /**
   * Meta info for Additional Info
   *
   * @type {ScoreCardMetaInfo}
   * @memberOf ScoreCardComponent
   */
  public viewAScoreCardMetaInfo: ScoreCardMetaInfo;

  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @memberOf ScoreCardComponent
   */
  public subscriptions: Array<Subscription> = [];

  public isInternalUser: Boolean;

  constructor(
    private filterService: FilterService,
    private viewMetaInfoService: ViewMetaInfoService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const user$ = this.userService.getUser();
    this.deliverableType = DeliverableType.SCORE_CARD.type;
    const viewMetaInfo$ = this.viewMetaInfoService.get<ScoreCardMetaInfo>(this.deliverableType);
    const userSubscription = user$.pipe(take(1))
      .subscribe((user) => this.isInternalUser = user.isInternalUser);
    const subscription = viewMetaInfo$
      .pipe(
        debounceTime(1),
        skipWhile((viewMetaInfo) => viewMetaInfo == null)
      ).subscribe((viewMetaInfo) => {
          this.viewAScoreCardMetaInfo = viewMetaInfo;
        }
      );
    this.subscriptions.push(subscription);
    this.subscriptions.push(userSubscription);
    this.loadDefaultFilter();
  }

  /**
   * Loads default ScoreCardComponent filter data.
   *
   * @memberof ScoreCardComponent
   */
  public loadDefaultFilter(): void {
    const defaultFilter: ScoreCardFilter = Object.assign({}, defaultScoreCardFilter);
    this.filterService.update(defaultFilter);
  }

  /**
   * Cleanup hook.
   *
   * @memberOf ScoreCardComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
