<ns-dropdown-menu [label]="filterLabel">
  <section *ngIf="filter.show.factorsOptions.length > 0" class="dropdown-filter-menu">
    <ul class="dropdown-menu-list">
      <h3 class="menu-title">{{'quick.predict.deliverables.factors.filter.thresholds.itemsTitle' | translate }}</h3>
      <mat-checkbox class="menu-title" [checked]="allFactorsChecked"
        (change)="changeAllOptions($event.checked, 'factor')" (click)="$event.stopPropagation()">
        {{ 'quick.predict.deliverables.factors.filter.show.all.factors' | translate }}</mat-checkbox>
      <div class="menu-list-content">
        <mat-checkbox *ngFor="let factor of filter.show.factorsOptions" color="primary" class="factor-name list-item padding-left-2"
          [value]="factor.name" [checked]="factor.isSelected" (change)="changeOption(factor, 'factor')"
          (click)="$event.stopPropagation()">
          <li class="list-item">{{ factor.name }}</li>
        </mat-checkbox>
      </div>
    </ul>
  </section>
  <section class="dropdown-filter-menu" *ngIf="isConceptView">
    <mat-checkbox class="menu-title" [checked]="allThresholdsChecked"
      (change)="changeAllOptions($event.checked, 'threshold')" (click)="$event.stopPropagation()">
      {{ 'quick.predict.deliverables.factors.filter.show.all.thresholds' | translate }}</mat-checkbox>
    <mat-checkbox *ngFor="let threshold of filter.show.thresholdOptions" color="primary" rounded flat
      class="threshold-name list-item padding-left-2" [value]="threshold.name" [checked]="threshold.isSelected"
      (change)="changeOption(threshold, 'threshold')" (click)="$event.stopPropagation()">
      <li class="list-item">{{ threshold.name }}</li>
    </mat-checkbox>
  </section>
  <section class="dropdown-filter-menu" *ngIf="!isConceptView">
    <ul class="dropdown-menu-list">
      <h3 class="menu-title">{{ 'quick.predict.deliverables.factors.filter.label.decimals' | translate }}</h3>
      <mat-radio-group [ngModel]="getSelectedDecimalPlace()" (change)="changeDecimalPlace($event)">
        <li class="list-item">
          <mat-radio-button color="primary" class="list-item" [value]="filterNames.noDecimals">
            {{ 'quick.predict.deliverables.factors.filter.label.no.decimals' | translate }}
          </mat-radio-button>
        </li>
        <li class="list-item">
          <mat-radio-button color="primary" class="list-item" [value]="filterNames.oneDecimalPlace">
            {{ 'quick.predict.deliverables.factors.filter.label.one.decimal' | translate }}
          </mat-radio-button>
        </li>
        <li class="list-item">
          <mat-radio-button color="primary" class="list-item" [value]="filterNames.twoDecimalPlace">
            {{ 'quick.predict.deliverables.factors.label.two.decimal' | translate }}
          </mat-radio-button>
        </li>
      </mat-radio-group>
    </ul>
  </section>
  <section class="dropdown-filter-menu" *ngIf="!isConceptView">
    <ul class="dropdown-menu-list">
      <h3 class="menu-title">{{ 'quick.predict.deliverables.factors.filter.label.options' | translate }}</h3>
      <div class="menu-list-content" (click)="$event.stopPropagation()">
        <mat-checkbox id="statTesting" color="primary" rounded flat class="list-item" [value]="filterNames.statTesting"
          (change)="toggleSelection(filterNames.statTesting)" [checked]="filter.show.statTesting" noink>{{
          'quick.predict.deliverables.factors.label.stat.testing' | translate }}</mat-checkbox>
      </div>
    </ul>
  </section>
</ns-dropdown-menu>
