import { Injectable } from '@angular/core';

import { AppConfigService } from '@app/core/config/app-config.service';
import { UserInfo } from '@platform/models/user-info.model';
import { UserService } from '@platform/services/user.service';
import { environment } from '@src/environments/environment';

/**
 * This service has operations for Pendo implementation
 *
 * @example
 * constructor(private pendoService: PendoService) { }
 *
 * @export
 * @class PendoService
 */

declare let  pendo: any;

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  pendoAPIKey: string;
  PENDO_FEATURE_FLAG = 'PENDO';
  PROD_ENVIRONMENT = 'production';
  DEV_ENVIRONMENT = 'development';
  PROD_PREFIX = 'prod';
  private isInitialized = false;

  /**
   * Constructor.
   *
   * @param AppConfigService
   * @param UserService
   * @memberof PendoService
   */
  constructor(private appConfigService: AppConfigService,
              private userService: UserService) {}

  /**
   * Initializing pendo
   * @param user
   */
  initializePendo(user: UserInfo): void {
    this.pendoAPIKey = this.appConfigService.config?.pendoAPIKey;
    const isPendoEnabled =
      user.featureFlags.findIndex(featureFlag => featureFlag.toLowerCase() === this.PENDO_FEATURE_FLAG.toLowerCase()) !== -1;
    if (this.pendoAPIKey && isPendoEnabled) {
      this.initializePendoWithKey();
    }
  }

  /**
   * Initializing pendo with current user profile
   */
  initializePendoWithKey(): void {
    this.userService.getStudioUserInfo().subscribe((studioUserInfo: UserInfo) => {
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
          for (w = 0, x = v.length; w < x; ++w) (function (m) {
            o[m] = o[m] || function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
          })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
      })(this.pendoAPIKey);
      this.setProfileInPendo(studioUserInfo.id, studioUserInfo.organizationId);
    });
  }

  /**
   * Setting current user profile
   * @param userId
   * @param organizationId
   */
  setProfileInPendo(userId: string, organizationId: string): void {
    if (this.isInitialized) {
      return;
    }
    const envIdentifier = window.location.host.split('.')[0],
    env = environment.production ? this.PROD_ENVIRONMENT : this.DEV_ENVIRONMENT,
    prefix = environment.production
      ? `${this.PROD_PREFIX}-${envIdentifier}-`
      : `${env}-${envIdentifier}-`;

    pendo.initialize({
      visitor: {
        id: `${prefix}${userId}`, // Required if user is logged in
      },
      account: {
        // You can add any additional account level key-values here
        id: `${prefix}${organizationId}`,
      },
    });
    this.isInitialized = true;
  }
}
