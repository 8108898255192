import {
  Component,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * `<ns-carousel-image>` component adds carousel buttons on hover for images.
 *
 *
 * This component emits:
 *  2. `next` event to notify when next button is clicked.
 *  3. `previous` event to notify when previous button is clicked.
 *
 * @example
 *   <ns-carousel-image
 *     [imageList]="imageList">
 *   </ns-carousel-image>
 *

 * @param {any[]} imageList - Array of item List
 *
 * @export
 * @class CarouselImageComponent
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'ns-carousel-image',
  templateUrl: './carousel-image.component.html',
  styleUrls: ['./carousel-image.component.scss']
})
export class CarouselImageComponent implements OnDestroy, OnInit, OnChanges {

  /**
   * Emits next button event.
   *
   * @type {EventEmitter<Event>}
   * @memberof CarouselImageComponent
   */
  @Output() next: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Emits previous button event.
   *
   * @type {EventEmitter<Event>}
   * @memberof CarouselImageComponent
   */
  @Output() previous: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Array of all images.
   *
   * @type {number[]}
   * @memberof CarouselImageComponent
   */
  @Input() imageList: any[];

  /**
   * Image arrow tooltip added description
   *
   * @type {string}
   * @memberof CarouselImageComponent
   */
  @Input() tooltipDescription: string = '';

  /**
   * Image Height
   *
   * @type {string}
   * @memberof CarouselImageComponent
   */
  @Input() imageHeight: string = '';
  /**
   * Image Width
   *
   * @type {string}
   * @memberof CarouselImageComponent
   */
  @Input() imageWidth: string = '';

  /**
   * Boolean flag to show or hide bullets
   *
   * @type {boolean}
   * @memberof CarouselImageComponent
   */
  @Input() visibleBullets: boolean;

  /**
   * Flag to enable/disable previous button.
   *
   * @type {boolean}
   * @memberof CarouselImageComponent
   */
  @Input() isPreviousEnabled: boolean = true;

  /**
   * Flag to enable/disable next button.
   *
   * @type {boolean}
   * @memberof CarouselImageComponent
   */
  @Input() isNextEnabled: boolean  = true;

  /**
   * Array of subscriptions for cleanup.
   *
   * @private
   * @type {Array<Subscription>}
   * @memberof CarouselImageComponent
   */
  private subscriptions: Array<Subscription>;


  /**
   * Initialization.
   *
   * @memberof CarouselImageComponent
   */
  ngOnInit(): void {
    this.reset();
  }

  reset(): void {
    // Initialize the component.
    this.subscriptions = [];
    this.imageList = this.imageList ? this.imageList : [];
  }

  /**
   * Detects changes and updates the view.
   *
   * @param {SimpleChanges} changes Simple changes.
   * @memberof CarouselImageComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    const imageListChange = changes.imageListChange;
    if (imageListChange && imageListChange.currentValue) {
      this.reset();
    }
  }

  /**
   * Cleanup after view is destroyed.
   *
   * @memberof CarouselImageComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Previous button `Event` listener.
   *
   * @listens event:click
   * @param {Event} event
   * @memberof CarouselImageComponent
   */
  public previousPage(event: Event): void {
    this.previous.emit(event);
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Next button `Event` listener.
   *
   * @listens event:click
   * @param {Event} event
   * @memberof CarouselImageComponent
   */
  public nextPage(event: Event): void {
    this.next.emit(event);
    event.preventDefault();
    event.stopPropagation();
  }

  getBottomHeight(imageHeight){
    return (imageHeight / 100) * 50 + 'px';
  }

}
