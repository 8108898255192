import { createAction, props } from '@ngrx/store';
import { Subgroup } from '@platform/models/subgroup.model';

/**
 * Action for adding a subgroup.
 * 
 * @export
 */
export const addSubgroup = createAction(
  '[Subgroup] Add Subgroup',
  props<{ subgroup: Subgroup }>()
);

/**
 * Action for an array of subgroup.
 * 
 * @export
 */
export const addSubgroups = createAction(
  '[Subgroup] Add Subgroups',
  props<{ subgroups: Subgroup[] }>()
);

/**
 * Action for adding a subgroup.
 * 
 * @export
 */
export const updateSubgroup = createAction(
  '[Subgroup] Update Subgroup',
  props<{ subgroup: Subgroup }>()
);
