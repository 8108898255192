<mat-card>
    <activation-profile-item-name (click)="toggleNode('concept')" [locale]="locale" [concept] = "concept" [benchmarks] = "benchmarks" [measureDescription]="measureDescription"></activation-profile-item-name>
    <div class="name-container profile-name">
        <div class="card-image">
            <img mat-card-image src="assets/images/deliverables/ActivationProfile/{{activationProfile | removeWhiteSpace | lowercase}}.svg" alt="Icon">
        </div>
        <span class="text-name" [matTooltip]="activationProfileTooltip" matTooltipShowDelay="500">{{activationProfile}}</span>
    </div>
    <activation-profile-item-tree [items] = "broadPotential"></activation-profile-item-tree>
    <activation-profile-item-tree [items] = "targetPotential"></activation-profile-item-tree>
    <activation-profile-item-tree [items] = "brandIncrementality"></activation-profile-item-tree>
    <activation-profile-item-tree [items] = "restaurantIncrementality"></activation-profile-item-tree>
    <activation-profile-item-tree [items] = "categoryDistinction"></activation-profile-item-tree>
    <activation-profile-item-tree [items] = "industryDistinction"></activation-profile-item-tree>
    <activation-profile-item-tree  [items] = "endurance"></activation-profile-item-tree>
    <div *ngIf=yearOneVolumne class="name-container yearVolume"><span [matTooltip]="yearOneVolumneTooltip" matTooltipShowDelay="500">{{yearOneVolumne}}</span></div>
    <div *ngIf=launchVolumne class="name-container yearVolume"><span [matTooltip]="launchVolumneTooltip" matTooltipShowDelay="500">{{launchVolumne}}</span></div>
    <activation-profile-item-tree [items] = "executionalPotential"></activation-profile-item-tree>
</mat-card>
