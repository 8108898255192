import { Dictionary } from '@ngrx/entity';
import { DeliverableView } from '@platform/models/deliverable-view.model';
import { DeliverableViewState } from '@platform/store/state/deliverable-view.state';
import { State } from '@platform/store/state/app.state';
import { deliverableViewAdapter } from '@platform/store/adapters/deliverable-view.adapter';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * Default selectors from adapter.
 *
 * @property
*/
const {
  selectEntities
} = deliverableViewAdapter.getSelectors();

// Deliverable view Selectors
/**
 * Deliverable view feature selector.
 *
 * @export
 * @property
*/
export const selectDeliverableViewsFeature = createFeatureSelector< DeliverableViewState>('deliverableViews');
/**
 * Deliverable view selector.
 *
 * @export
 * @property
*/
export const selectDeliverableViews = createSelector(
  selectDeliverableViewsFeature,
  (state: DeliverableViewState) => state
);
/**
 * Deliverable view adapter entity selector.
 *
 * @export
 * @property
*/
export const selectDeliverableViewEntities = createSelector(
  selectDeliverableViews,
  selectEntities
);
/**
 * Selector for selecting deliverable view by id.
 *
 * @export
 * @property
*/
export const selectDeliverableViewById = <T extends DeliverableView>() => {
  return createSelector(
    selectDeliverableViewEntities,
    (entities: Dictionary<DeliverableView>, props: { id: string }) => {
      return <T>entities[props.id];
    }
  );
};
