import { CaptureOptions } from './capture-options';
import { CSSNode } from './css-node';
import { NodeProcessor } from './node-processor';
import { Injectable } from '@angular/core';
import csso from 'csso';

/**
 * This service can be used to capture DOM nodes with styles.
 *
 * @export
 * @class NodeCaptureService
 */
@Injectable()
export class NodeCaptureService {

  /**
   * The node processor instance.
   *
   * @private
   * @type {NodeProcessor}
   * @memberof NodeCaptureService
   */
  private nodeProcessor: NodeProcessor;

  /**
   * Attributes including style encapsulation attributes to be removed.
   *
   * @private
   */
  private attributePrefixes = ['_ng', 'mat', 'ng-', 'id', 'role'];

  /**
   * Initialize.
   */
  constructor() {
    this.nodeProcessor = new NodeProcessor();
  }

  /**
   * Extracts node as string along with the computed styles for the node based on the options.
   *
   * @param selector the selector to select the nodes
   * @param options the CaptureOptions for capturing the node.
   * @returns the node string.
   */
  public capture(selector: string, options: CaptureOptions): string {
    // Create root node.
    const parentNode: HTMLDivElement = document.createElement('div');
    const element: Element = document.querySelector(selector);
    const clone: Element = element.cloneNode(true) as Element;
    // Create root CSS Node
    const cssNode: CSSNode = new CSSNode(null);
    // Add remove attribute prefixes.
    options.attribute.remove.push(...this.attributePrefixes);
    // Process the node and its descendants.
    this.nodeProcessor.process(clone, element, cssNode, options);
    parentNode.appendChild(clone);
    // Add root node classes.
    options.cssClass.root.forEach(cssClass => parentNode.classList.add(cssClass));
    parentNode.classList.add(cssNode.className);
    // Build node string.
    const cssRules = cssNode.getRulesList();
    const cssRulesString = this.minify(cssRules.join(' '));
    const styleString = `<style>${cssRulesString}</style>`;
    const nodeString = `${styleString} ${parentNode.outerHTML}`;
    return nodeString;
  }

  /**
   * Minimizes the CSS sheet string.
   *
   * @param cssRules the array of CSS rule strings.
   * @returns the minified CSS string.
   */
  private minify(cssRules: string): string {
    const output = csso.minify(cssRules).css;
    return output;
  }

}
