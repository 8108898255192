import { DeliverableView } from '@platform/models/deliverable-view.model';
import { DeliverableViewState } from '@platform/store/state/deliverable-view.state';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';


/**
 * Deliverable views entity adapter.
 *
 * @export
 * @property {EntityAdapter<DeliverableView>} adapter - The deliverable view adapter.
*/
export const deliverableViewAdapter: EntityAdapter<DeliverableView> = createEntityAdapter<DeliverableView>();

/**
 * Initial state.
 */
export const initialState: DeliverableViewState = deliverableViewAdapter.getInitialState({});
