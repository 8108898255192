import { DeliverablesListItem } from "@products/shared/deliverables-list/deliverables-list-item.data.model";
import { ChartOption } from "../forecast-variety-split-deliverable/forecast-variety-split-deliverable-data-map";

export enum TreeNodeDataType {
    number,
    string,
    text,
    radioBtn
}

export interface TreeNode {
    name: string;
    key: string;
    children?: TreeNode[];
    value?: any;
    level: number;
    dataType: TreeNodeDataType;
    class?: string;
    showCurrency?: boolean
    showPercentage?: boolean
    fractionDigits?: number,
}

export const forecastMarketingPlanSummaryMapData: TreeNode[] = [
    {
        key: 'totalYearOneSpend.value',
        name: 'TOTAL YEAR 1 SPEND',
        level: 0,
        dataType: TreeNodeDataType.number,
        class: "",
        showCurrency: true,
        showPercentage: false,
        fractionDigits: 1,
        children: [
            {
                key: 'totalYearOneSpend.advertising.value',
                name: 'Advertising',
                level: 1,
                dataType: TreeNodeDataType.number,
                class: "",
                showCurrency: true,
                showPercentage: false,
                fractionDigits: 1,
                children: [
                    {
                        key: 'totalYearOneSpend.advertising.TV',
                        name: 'TV',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'totalYearOneSpend.advertising.Online',
                        name: 'Digital',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'totalYearOneSpend.advertising.Other',
                        name: 'Other',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    }
                ],
                value: ""
            },
            {
                key: 'totalYearOneSpend.consumerPromotionSpend.value',
                name: 'Consumer Promotion',
                level: 1,
                dataType: TreeNodeDataType.number,
                class: "",
                showCurrency: true,
                showPercentage: false,
                fractionDigits: 1,
                children: [
                    {
                        key: 'totalYearOneSpend.consumerPromotionSpend.Coupons',
                        name: 'Coupons',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'totalYearOneSpend.consumerPromotionSpend.Sampling',
                        name: 'Sampling',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    }
                ],
                value: ""
            },
            {
                key: 'totalYearOneSpend.tradePromotionSpend.value',
                name: 'Trade Promotion',
                level: 1,
                dataType: TreeNodeDataType.number,
                class: "",
                showCurrency: true,
                showPercentage: false,
                fractionDigits: 1,
                children: [
                    {
                        key: 'totalYearOneSpend.displaySpend.value',
                        name: 'Display',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'totalYearOneSpend.featureSpend.value',
                        name: 'Feature',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    },
                    {
                        key: 'totalYearOneSpend.tprSpend.value',
                        name: 'TPR',
                        level: 2,
                        dataType: TreeNodeDataType.number,
                        showCurrency: true,
                        showPercentage: false,
                        fractionDigits: 1,
                        children: [],
                        value: ""
                    }
                ],
                value: ""
            }
        ],
        value: ""
    },
    {
        key: 'Advertising',
        name: 'ADVERTISING',
        level: 0,
        dataType: TreeNodeDataType.string,
        showCurrency: false,
        class: "",
        showPercentage: false,
        fractionDigits: 0,
        children: [
            {
                key: 'advertising.TV GRPs',
                name: 'TV GRPs',
                level: 1,
                dataType: TreeNodeDataType.string,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            },

        ],
        value: ""
    },
    {
        key: 'distributionEndOfYearValue',
        name: 'DISTRIBUTION',
        level: 0,
        dataType: TreeNodeDataType.string,
        showCurrency: false,
        showPercentage: false,
        class: "",
        fractionDigits: 0,
        children: [

        ],
        value: ""
    },
    {
        key: 'ConsumerPromotion',
        name: 'CONSUMER PROMOTION',
        level: 0,
        dataType: TreeNodeDataType.number,
        showCurrency: false,
        class: "",
        showPercentage: false,
        fractionDigits: 0,
        children: [
            {
                key: 'consumerPromotion.Coupons Dropped (MM)',
                name: 'Coupon Circulation',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            },
            {
                key: 'consumerPromotion.Samples (MM)',
                name: 'Samples Dropped',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            }
        ],
        value: ""
    },
    {
        key: 'tradePromotionDistribution',
        name: 'TRADE PROMOTION',
        level: 0,
        dataType: TreeNodeDataType.number,
        class: "",
        showCurrency: false,
        showPercentage: true,
        fractionDigits: 0,
        children: [
            {
                key: 'tradePromotion.Display',
                name: 'Display',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: true,
                fractionDigits: 0,
                children: [],
                value: ""
            },
            {
                key: 'tradePromotion.Feature',
                name: 'Feature',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: true,
                fractionDigits: 0,
                children: [],
                value: ""
            },
            {
                key: 'tradePromotion_TPR_Savings',
                name: 'TPR',
                level: 1,
                dataType: TreeNodeDataType.number,
                showCurrency: false,
                showPercentage: false,
                fractionDigits: 0,
                children: [],
                value: ""
            }
        ],
        value: ""
    },
]

export const forecastMarketingPlanSummaryHeaderMapData: TreeNode[] = [
    {
        key: 'launchDate.value',
        name: 'LAUNCH DATE',
        level: 0,
        dataType: TreeNodeDataType.string,
        showCurrency: false,
        showPercentage: false,
        class: "",
        fractionDigits: 0,
        children: [],
        value: ""
    },
    {
        key: 'planName',
        name: 'MARKETING PLAN',
        level: 0,
        dataType: TreeNodeDataType.string,
        showCurrency: false,
        showPercentage: false,
        class: "",
        fractionDigits: 0,
        children: [],
        value: ""
    },
    {
        key: 'conceptName',
        name: 'CONCEPT',
        level: 0,
        dataType: TreeNodeDataType.string,
        showCurrency: false,
        showPercentage: false,
        class: "",
        fractionDigits: 0,
        children: [],
        value: ""
    }
]

export const MarketingPlanChartOptionMap: ChartOption[] = [
    {
        "id": 1,
        "displayName": "Density",
        "elementType": "radioBtn",
        "originalName": "density",
        "isVisible": true,
        "children": [{
            "id": 1,
            "displayName": "Default",
            "isVisible": true,
            "checked": true,
            "value": "default"
        }, {
            "id": 2,
            "displayName": "Large",
            "isVisible": true,
            "checked": false,
            "value": "large"
        }],
    },
]

export const currency: DeliverablesListItem = {
    id: 'totalYearOneSpend.currency',
    displayName: 'Currency',
    originalName: 'Currency',
    isVisible: true,
    position: 0,
    originalPosition: 0,
    type: 'text',
    children: [],
};

export const distributionValue: DeliverablesListItem = {
    id: 'distributionEndOfYearValue.distributionValue',
    displayName: 'Distribution Values',
    originalName: 'Distribution Values',
    isVisible: true,
    position: 0,
    originalPosition: 0,
    type: 'radioGroup',
    data: [
        {
            id: 'endOfYear',
            displayName: 'End of Year - Model',
            originalName: 'End of Year - Model',
            isVisible: true,
            position: 0,
            originalPosition: 0,
            type: 'radioBtn',
            checked: true,
            children: []
        },
        {
            id: 'average',
            displayName: 'Average - Model',
            originalName: 'Average - Model',
            isVisible: true,
            position: 1,
            originalPosition: 1,
            type: 'radioBtn',
            checked: false,
            children: []
        },
        {
            id: 'endOfYearClient',
            displayName: 'End of Year - Client Input',
            originalName: 'End of Year - Client Input',
            isVisible: true,
            position: 2,
            originalPosition: 2,
            type: 'radioBtn',
            checked: false,
            children: []
        }
    ],
};

export const tradePromotion: DeliverablesListItem = {
    id: 'tradePromotionDistribution.activity',
    displayName: 'Activity Level',
    originalName: 'Activity Level',
    isVisible: true,
    position: 0,
    originalPosition: 0,
    type: 'text',
    children: []
};