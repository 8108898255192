<div class="table-wrapper-subgroup-means"  *ngIf="hasData; else noData">
  <mat-table matSortStart="asc" matSort [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container *ngFor="let colHeader of colHeaders;let i = index;trackBy: trackItem" matColumnDef="{{ colHeader.name }}">
          <ng-container *ngIf="i === 0">
            <mat-header-cell class="statement-header" *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell class="statement-header" *matCellDef="let element">
              <span class="factor-name">{{ element.factor }}</span>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="i !== 0">
            <mat-header-cell rel="tooltip" matTooltipPosition="above" matTooltip = "{{this.getSortOrder(colHeader.name)}}" class="statement-header" *matHeaderCellDef mat-sort-header
              (click)="onClickHeader($event,colHeader.id,colHeader.name)">
              <qp-factors-subgroup-means-table-header class="factors-subgroup-means-table-header" [title]="colHeader.name" [index]="i-1" [id]="colHeader.id" [size]="colHeader.size"></qp-factors-subgroup-means-table-header>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="threshold-value" [ngClass]="element[colHeader.name]?.color">{{ element[colHeader.name]?.mean }}</span>
              <span class="stat-testing">{{ element[colHeader.name]?.statTesting }}</span>
            </mat-cell>
          </ng-container>
        </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-row class="row-data" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

<ng-template #noData>
  <div *ngIf="subgroupViewIsReady" class="no-data">
      <span class="data sif sif-no-results"></span>
      <h3>There are no Results</h3>
      <p>Try refining the filters to populate data.</p>
  </div>
</ng-template>
