import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@platform/store/state/app.state';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ReportService } from '@platform/services/report.service';
import { AppConfigService } from '@app/core/config/app-config.service';
import { DeliverableType } from "@app/deliverables/deliverable-type.enum";
import { SpinnerService } from "@platform/services/spinner.service";
import { AppAsyncTaskService } from "@platform/services/app-async-task.service";
import { AppAsyncTask } from "@platform/models/app-async-task.model";

@Injectable()
export class SurveyQuestionFileService {

  private baseURI: string;

  constructor(private store: Store<State>,
              private httpClient: HttpClient,
              private reportService: ReportService,
              private spinnerService: SpinnerService,
              private cs: AppConfigService,
              private appAsyncTaskService: AppAsyncTaskService) {
    this.baseURI = `${this.cs.config.reporting.url}`;
  }

  /**
   * Downloads the file from api.
   *
   * @param {string} reportFileId the report file id.
   */
  public download(): void {
    this.spinnerService.setSpinnerObs();
    const report$ = this.reportService.get();
    report$.subscribe(report => {
      const headers = new HttpHeaders({
        Accept: 'application/octet-stream'
      });
      // use - instead of . for the type.  if . is used everything after . is removed.
      const uri = `${this.baseURI}/reports/${report.id}/deliverables/${DeliverableType.SURVEY_QUESTION.type.replace('.', '-')}?async=1`;
      return this.httpClient.get<AppAsyncTask>(uri, {headers}).subscribe((task: AppAsyncTask) => {
        this.appAsyncTaskService.pollUntilTaskFinished(task.id, task.reportId).subscribe((task: AppAsyncTask) => {
          if (task.status === this.appAsyncTaskService.STATUS_FAILED) {
            console.log(`Async download task failed`);
            this.spinnerService.spinnerObs$.next(false);
            return;
          }

          this.appAsyncTaskService.download(task).subscribe({
            next: (response) => {
              this.appAsyncTaskService.downloadFile(response, `${report.name} Survey Questions Export.xlsx`);
              this.spinnerService.spinnerObs$.next(false);
            }, error: (err: HttpErrorResponse) => {
              if (err instanceof Error) {
                console.log(`An error occurred ${err.error.message}`);
              } else {
                console.log(`${err.status}, body was: ${err.message}`);
              }
              this.spinnerService.spinnerObs$.next(false);
            }
          });
        });
      });
    });
  }
}
