import { Concept } from '@platform/models/concept.model';
import { ConceptState } from '@platform/store/state/concept.state';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

/**
 * Concepts entity adapter.
 *
 * @export
 * @property {EntityAdapter<Concept>} conceptAdapter - The concept adapter.
*/
export const conceptAdapter: EntityAdapter<Concept> = createEntityAdapter<Concept>();

/**
 * Initial state.
 */
export const initialState: ConceptState = conceptAdapter.getInitialState({});
