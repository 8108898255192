import { Injectable } from '@angular/core';
import {ActivationProfileService} from '@app/deliverables/activation-profile/services/activation-profile.service';
import {MeasureDescription, Measures} from '@app/deliverables/activation-profile/models/activationProfile.model';
import {TreeNodeData} from '@app/deliverables/activation-profile/models/tree-node-data.interface';

@Injectable({
  providedIn: 'root'
})
export class EnduranceService {

  constructor(private activationProfileService: ActivationProfileService) { }

  /**
   * set endurance tree
   *
   * @returns {TreeNodeData}
   * @memberof EnduranceService
   */
  setEndurance(headerList: string | string[], measureDescription: MeasureDescription, endurance: TreeNodeData[]): TreeNodeData[] {
    if (headerList.indexOf('endurance') > -1) {
      endurance = this.activationProfileService.buildMeasures(measureDescription.endurance, 'Parent', 'endurance', 1, [], 'endurance', this.activationProfileService.getTooltip('endurance.' + measureDescription.endurance), true);
      if (headerList.indexOf('productWPIQuintile') > -1) {
        const productWPIQuintile = this.activationProfileService.buildMeasures(measureDescription.productWPIQuintile, 'Parent', 'repeatInterest', 2, [], null, null, true, 5);
        endurance[0].children.push(productWPIQuintile[0]);
        const definitely = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.productDefinitelyWouldBuy), 'Leaf', 'repeatInterestDefinitely', 3, null, null, null, false);
        const probably = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.productProbablyWouldBuy), 'Leaf', 'repeatInterestProbably', 3, null, null, null, false);
        const might = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.productMightorMightNotBuy), 'Leaf', 'repeatInterestMight', 3, null, null, null, false);
        const probablyNot = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.productProbablyWouldNotBuy), 'Leaf', 'repeatInterestProbablyNot', 3, null, null, null, false);
        const definitelyNot = this.activationProfileService.buildMeasures(this.activationProfileService.getFormattedValue(measureDescription.productDefinitelyWouldNotBuy), 'Leaf', 'repeatInterestDefinitelyNot', 3, null, null, null, false);
        productWPIQuintile[0].children.push(definitely[0]);
        productWPIQuintile[0].children.push(probably[0]);
        productWPIQuintile[0].children.push(probablyNot[0]);
        productWPIQuintile[0].children.push(might[0]);
        productWPIQuintile[0].children.push(definitelyNot[0]);
      }
      if (headerList.indexOf('productDeliveryFactorColor') > -1) {
        const productDeliveryFactorColor = this.activationProfileService.buildMeasures(measureDescription.productDeliveryFactorColor, 'Leaf', 'productDelivery', 2, null, null, null, true, 4);
        endurance[0].children.push(productDeliveryFactorColor[0]);
      }
      if (headerList.indexOf('productLoyaltyFactorColor') > -1) {
        const productLoyaltyFactorColor = this.activationProfileService.buildMeasures(measureDescription.productLoyaltyFactorColor, 'Leaf', 'productLoyalty', 2, null, null, null, true, 4);
        endurance[0].children.push(productLoyaltyFactorColor[0]);
      }
    }
    return endurance;
  }
}
