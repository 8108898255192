import {Injectable} from '@angular/core';
import {UserInfo} from '@platform/models/user-info.model';
import {Collaborator} from '@platform/models/collaborator.model';
import {Feature} from '@platform/models/feature.model';
import {Report} from "@platform/models/report.model";
import {CollaboratorService} from "@platform/services/collaborator.service";

/**
 * This service provides privileges based on user information.
 *
 * @example
 * constructor(private privilegeService: PrivilegeService) { }
 *
 * @export
 * @class PrivilegeService
 */
@Injectable({
    providedIn: 'root'
})
export class PrivilegeService {

    /**
     * Check if the logged-in user is a collaborator of that report
     *
     * @param {UserInfo} user Currently logged in user
     * @param {Collaborator[]} collaborators collaborators of the report
     * @returns {Collaborator} Currently logged in user whose role is a collaborator.
     * @memberof PrivilegeService
     */
    public getUserRole(user: UserInfo, collaborators: Collaborator[]): Collaborator {
        let collaboratedUser: Collaborator;
        if (collaborators.length > 0) {
            collaboratedUser = collaborators.find(collaborator => {
                return collaborator.userManagementId === user.userId;
            });
        }
        return collaboratedUser;
    }

    /**
     * Checks permission to view report as per user's privilege
     *
     * @param {UserInfo} user details of logged-in user
     * @param {Collaborator} collaboratedUser details of logged-in user who is a collaborator
     * @returns {boolean} permission to view report
     * @memberof PrivilegeService
     */
    public canViewReport(user: UserInfo, collaboratedUser: Collaborator): boolean {
        let viewPrivilege = false;
        if (user.roles.includes('ROLE_SYSTEM_ADMINISTRATOR') || user.roles.includes('ROLE_Nielsen') || user.roles.includes('ROLE_All Project Access')) {
            viewPrivilege = true;
            return viewPrivilege;
        }
        if (collaboratedUser) {
            if (!collaboratedUser.isClient) {
                viewPrivilege = true;
            } else if (collaboratedUser.isClient && (collaboratedUser.privileges.includes('SHARE') || collaboratedUser.privileges.includes('FORECAST_SHARE'))) {
                viewPrivilege = true;
            }
        }
        return viewPrivilege;
    }

    /**
     * Checks permission to share report as per user's privilege
     *
     * @param {UserInfo} user details of logged-in user
     * @param {Collaborator} collaboratedUser details of logged-in user who is a collaborator
     * @returns {boolean} permission to share report
     * @memberof PrivilegeService
     */
    public canShareReport(user: UserInfo, collaboratedUser: Collaborator): boolean {
        let sharePrivilege = false;
        if (user.roles.includes('ROLE_SYSTEM_ADMINISTRATOR') || user.roles.includes('ROLE_Nielsen') || user.roles.includes('ROLE_All Project Access')) {
            sharePrivilege = true;
            return sharePrivilege;
        }
        if (collaboratedUser) {
            if (!collaboratedUser.isClient) {
                sharePrivilege = true;
            } else if (collaboratedUser.isClient && (collaboratedUser.privileges.includes('SHARE') || collaboratedUser.privileges.includes('FORECAST_SHARE')) && collaboratedUser.isOwner) {
                sharePrivilege = true;
            }
        }
        return sharePrivilege;
    }

    /**
     * Returns true if user is internal/nielsen user
     *
     * @param user
     * */
    isInternalUser(user: UserInfo): boolean {
        return (user.roles.includes('ROLE_SYSTEM_ADMINISTRATOR') || user.roles.includes('ROLE_Nielsen') || user.roles.includes('ROLE_All Project Access'));
    }

    /**
     * Checks the permission of given user
     * @param report
     * @param user
     * @param permission
     */
    hasPermission(report: Report, user: UserInfo, permission: string): boolean {
        if (this.isInternalUser(user)) {
            return true;
        } else {
            const collaboratedUser = this.getUserRole(user, report.collaborators);
            if (collaboratedUser) {
                return collaboratedUser.isClient && collaboratedUser.privileges.includes(permission)
            }
            return false;
        }
    }

    /**
     * Checks if user has deliverable share permission
     * @param report
     * @param user
     */
    hasDeliverableSharePermission(report: Report, user: UserInfo) {
        return this.hasPermission(report, user, CollaboratorService.DELIVERABLE_SHARE_PERMISSION);
    }

    /**
     * Checks if user has forecast share permission
     * @param report
     * @param user
     */
    hasForecastSharePermission(report: Report, user: UserInfo) {
        return this.hasPermission(report, user, CollaboratorService.FORECAST_SHARE_PERMISSION);
    }

    /**
     * Checks the permission to view the Forecasting Deliverables
     * @param user
     * @param collaboratedUser
     * @param features
     * @param isForecasting
     */
    public canViewFeaturesBasedOnPermissions(report: Report, user: UserInfo, features: Feature[]): Feature[] {
        let updatedFeatures: Feature[] = [...features];
        const hasSharePermission = this.hasDeliverableSharePermission(report, user);
        const hasForecastPermission = this.hasForecastSharePermission(report, user);

        if (!hasSharePermission) {
            if (updatedFeatures.find(it => it.name === 'Concepts')) {
                updatedFeatures.splice(updatedFeatures.findIndex(it => it.name === 'Concepts'), 1);
            }
            if (updatedFeatures.find(it => it.name === 'Deliverables')) {
                updatedFeatures.splice(updatedFeatures.findIndex(it => it.name === 'Deliverables'), 1);
            }
        }
        if (!hasForecastPermission) {
            if (updatedFeatures.find(it => it.name === 'Forecasting')) {
                updatedFeatures.splice(updatedFeatures.findIndex(it => it.name === 'Forecasting'), 1);
            }
            if (!hasSharePermission) {
                if (updatedFeatures.find(it => it.name === 'Insights')) {
                    updatedFeatures.splice(updatedFeatures.findIndex(it => it.name === 'Insights'), 1);
                }
            }
        }
        return updatedFeatures;
    }

    /**
     * @param user
     * @param collaborators
     */
    public canViewForecasting(user: UserInfo, collaborators: Collaborator[]) {
        let canView = user.isInternalUser;
        const collaboratedUser = this.getUserRole(user, collaborators);
        if (!canView) {
            if (collaboratedUser.isClient && collaboratedUser.privileges?.length > 0) {
                canView = collaboratedUser.privileges.includes('FORECAST_SHARE');
            }
        }
        return canView;
    }

    public canViewDeliverables(user: UserInfo, collaborators: Collaborator[]) {
        let canView = user.isInternalUser;
        if (!canView) {
            const collaboratedUser = this.getUserRole(user, collaborators);
            if (collaboratedUser.isClient && collaboratedUser.privileges?.length > 0) {
                canView = collaboratedUser.privileges.includes('SHARE');
            }
        }
        return canView;
    }

}
