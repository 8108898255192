import { CSSNodeDeclaration } from './css-node-declaration';

/**
 * A CSSNode represents a styles applied at a node.
 */
export class CSSNode extends CSSNodeDeclaration {

  /**
   * Auto generated className for the node.
   *
   * @type {string}
   */
  className: string;
  /**
   * Children CSSNodes of the current node.
   *
   * @type {CSSNode[]}
   */
  children: CSSNode[];
  /**
   * Parent CSSNode of the current node.
   *
   * @type {CSSNode}
   */
  parent: CSSNode;
  /**
   * Root CSSNode of the tree.
   *
   * @type {CSSNode}
   */
  root: CSSNode;
  /**
   * Node count in the tree.
   *
   * @type {number}
   * @memberof CSSNode
   */
  count: number;

  /**
   * Initialize the CSSNode with the parent.
   *
   * @param parent
   */
  constructor(parent: CSSNode) {
    super();
    this.parent = parent;
    if (parent) {
      // Increase root node counter.
      parent.root.count = ++parent.root.count;
      parent.children.push(this);
    }
    this.count = parent ? parent.root.count : 1;
    this.className = `x${Math.random().toString(36).substring(9)}-${this.count}`;
    // Set root node.
    this.root = parent ? parent.root : this;
    this.children = [];

  }

  /**
   * Returns CSS Rule string array of the current and its children nodes recursively.
   *
   * @returns the array rule string.
   */
  public getRulesList(): string[] {
    return this.getCSSRules(this);
  }

  /**
   * Returns CSS Rule string array of the cssNode and its children nodes recursively.
   *
   * @param cssNode the CSS Node
   * @returns the array rule string.
   */
  private getCSSRules(cssNode: CSSNode): string[] {
    let currentRule = null;
    const rules = [];
    // Build current rule.
    currentRule = this.getDeclarationString(cssNode.declaration);
    // Push current rule to the array.
    if (currentRule) {
      rules.push(`.${cssNode.className} {${currentRule}}`);
    }

    currentRule = this.getDeclarationString(cssNode.beforeDeclaration);
    // Push current rule to the array.
    if (currentRule) {
      rules.push(`.${cssNode.className}::before {${currentRule}}`);
    }

    currentRule = this.getDeclarationString(cssNode.afterDeclaration);
    // Push current rule to the array.
    if (currentRule) {
      rules.push(`.${cssNode.className}::after {${currentRule}}`);
    }

    // Add child rules to the rules array.
    cssNode.children.forEach(childNode => {
      rules.push(...this.getCSSRules(childNode));
    });
    return rules;
  }

  /**
   * Returns declaration string for all properties in the declaration.
   *
   * @param declaration the declarations map.
   * @returns the declaration string.
   */
  private getDeclarationString(declaration: Map<string, string>): string {
    let output = null;
    declaration.forEach((value, property) => {
      output = `${output ? output : ''}${property}:${value};`;
    });
    return output;
  }

}
