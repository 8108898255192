import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {

    /**
     * spinner subject for show/hide
     */
    spinnerObs$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * spinner observable to update component
     */
    getSpinnerObs(): Observable<boolean> {
        return this.spinnerObs$.asObservable();
    }

    /**
     * set spinner to update component
     */
    setSpinnerObs(): void {
        return this.spinnerObs$.next(true);
    }

    showSpinner(): void {
        this.spinnerObs$.next(true);
    }

    hideSpinner(): void {
        this.spinnerObs$.next(false);
    }
}
