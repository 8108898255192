import {Component, Input, OnInit} from '@angular/core';
import {SubgroupService} from '@platform/services/subgroup.service';
import {Subgroup} from '@platform/models/subgroup.model';

@Component({
    selector: 'ns-target-group-header',
    templateUrl: './target-group-header.component.html',
    styleUrls: ['./target-group-header.component.scss']
})
export class TargetGroupHeaderComponent implements OnInit {

    @Input() element: any;
    subgroups: Array<Subgroup>;

    constructor(private subgroupService: SubgroupService,) {
    }

    ngOnInit(): void {
        const subgroups$ = this.subgroupService.getReportSubgroups();
        subgroups$.subscribe(subgroups => {
            this.subgroups = subgroups;
        });
    }

    isDataBaseAble(segmentId: number): boolean {
        return this.subgroups.find(it => it.segmentId === segmentId).isDatabaseable;
    }
}
