import { Action, createReducer, on } from '@ngrx/store';
import { deliverableViewAdapter, initialState } from '@platform/store/adapters/deliverable-view.adapter';
import { DeliverableViewState } from '@platform/store/state/deliverable-view.state';
import { addDeliverableView, updateDeliverableView } from '@platform/store/actions/deliverable-view.actions';

/**
 * Reducer for all deliverableView actions.
 */
const deliverableViewReducer = createReducer(
  initialState,
  on(addDeliverableView, (state, { deliverableView }) => {
    return deliverableViewAdapter.upsertOne(deliverableView, state);
  }),
  on(updateDeliverableView, (state, { deliverableView }) => {
    return deliverableViewAdapter.upsertOne(deliverableView, state);
  })
);

/**
 * Deliverable views `NgRx` reducer.
 *
 * @export
 * @param {DeliverableViewState} state
 * @param {DeliverableViewActions} action
 * @returns {DeliverableViewState}
 */
export function reducer(state: DeliverableViewState | undefined, action: Action): DeliverableViewState {
  return deliverableViewReducer(state, action);
}
