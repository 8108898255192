import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {showText as showOptionsSource} from '@app/deliverables/strengths-watchouts/filter/show/show-text';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';
import {FilterItem, StrengthWatchoutsFilter} from '@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model';
import {Subscription} from 'rxjs';
import {FilterService} from '@platform/services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelEvent } from '@src/assets/utils/mixpanel-enum';

@Component({
  selector: 'sw-filter-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

  @Input() deliverableLabel: string;

  /**
   * The show options text.
   * @type {any}
   * @memberOf ShowComponent
   */
  showText: any = showOptionsSource;

  filter: StrengthWatchoutsFilter;

  subscriptions: Array<Subscription>;

  filterLabel: string;

  showOptions: boolean;

  allOptionsChecked: boolean;

  tooltipLabel: string;

  sentimentFilterLabel: string;

  sentimenttooltipLabel: string;

  languagelabel: string;

  constructor(private strengthWatchoutsService: StrengthWatchoutsService,
              private filterService: FilterService,
              private translate: TranslateService,
              private mixpanelService: MixpanelService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    const filter$ = this.strengthWatchoutsService.getStrengthWatchoutsFilter();
    const filterSubscription = filter$.subscribe(filter => {
      this.filter = this.strengthWatchoutsService.updateFilterOptions(filter);
      const compareOption = filter.compare.find((filterItem) => {
        return filterItem.isSelected;
      });
      this.showOptions = compareOption.id !== this.showText.elementEngagement;
      this.setSelectAll();
      this.setFilterLabel();
    });
    this.subscriptions.push(filterSubscription);
  }

  /**
   * Sets filter value property.
   *
   * @private
   * @memberof ShowComponent
   */
  private setFilterLabel(): void {
    const showFilter = this.filter.show;
    let label = '';
    if (showFilter.options.every((option) => option.isSelected)) {
        label = this.filter.show.options[1].name+' & '+this.filter.show.options[2].name;
    } else {
      showFilter.options.forEach((option) => {
        if (option.isSelected && option.id !== 'zones') {
          label = label ? `${label}, ${option.name}` : option.name;
        }
      });
        label = label.length == 0? this.showText.noSentiment : label;
    }

    const selectedLanguage = showFilter.languages.find((language) => language.isSelected);
      this.languagelabel = this.showText.languages;
      this.filterLabel = selectedLanguage.name;
      this.sentimentFilterLabel = label;
      this.sentimenttooltipLabel = this.translate.instant('tooltip.filter', { viewComparison: 'Sentiment Options selected', viewComparisonName: this.sentimentFilterLabel });
      this.tooltipLabel = this.translate.instant('tooltip.filter', { viewComparison: 'Languages selected', viewComparisonName: selectedLanguage.name });

  }

  private setSelectAll(): void {
    const filter = this.filter;
    this.allOptionsChecked = filter.show.options.every((option) => option.isSelected);
  }

  getSelectedLanguage(): string | number {
    return this.filter.show.languages.find((language) => language.isSelected).id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  changeAllOptions(event): void {
    const checked: boolean = event
    const filter = JSON.parse(JSON.stringify(this.filter));
    filter.show.options.forEach((option) => {
      option.isSelected = checked;
    });
    this.allOptionsChecked = checked;
    this.filterService.update(filter);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

  changeOption(item: FilterItem): void {
    const filter = JSON.parse(JSON.stringify(this.filter));
    filter.show.options.forEach((option) => {
      option.isSelected = (option.id === item.id) ? !option.isSelected : option.isSelected;
    });
    this.setSelectAll();
    this.filterService.update(filter);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

  changeLanguage(event): void {
    const selected = event.value;
    const filter = JSON.parse(JSON.stringify(this.filter));
    filter.show.languages.forEach((language) => {
      language.isSelected = (language.id === selected);
    });

    this.filterService.update(filter);
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
  }

}
