import {Component, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Report} from '@platform/models/report.model';
import {FilterService} from '@platform/services/filter.service';
import {ReportService} from '@platform/services/report.service';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {combineLatest, Subscription} from 'rxjs';
import {GapAnalysisService} from '@products/restage/gap-analysis/services/gap-analysis.service';
import {GapAnalysisFilter} from '@products/restage/gap-analysis/models/filter.model';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';

/**
 * `FilterComponent` creates the gap analysis filter options like concepts,
 * country, subgroups.
 *
 * @example
 *   <ns-gap-analysis-filter></ns-gap-analysis-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */

@Component({
  selector: 'ns-gap-analysis-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  /**
   * Gap Analysis filter object.
   *
   * @property
   * @type {GapAnalysisFilter}
   * @memberOf FilterComponent
   */
  public filter: GapAnalysisFilter;

  /**
   * List of concept dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberOf FilterComponent
   */
  public conceptsListData: DropdownData<string>;

  /**
   * List of attribute dropdown items.
   *
   * @property
   * @type {Array<DropdownData>}
   * @memberOf FilterComponent
   */
  public attributesListData: DropdownData<string>;

  /**
   * List of countries on the report.
   *
   * @property
   * @type {DropdownData}
   * @memberOf FilterComponent
   */
  public countryListData: DropdownData<string>;

  /**
   * List of subgroup dropdown items.
   *
   * @property
   * @type {DropdownData}
   * @memberOf FilterComponent
   */
  public subgroupListData: DropdownData<string>;

  /**
   * Array of subscriptions for cleanup.
   *
   * @property
   * @private
   * @type {Array<Subscription>}
   * @memberOf FilterComponent
   */
  private subscriptions: Array<Subscription>;
  /**
   * Mixpanel label for the deliverable
   *
   * @property
   * @private
   * @type {string}
   * @memberof FilterComponent
   */
  private deliverableLabel: string;

  constructor(
    private filterService: FilterService,
    private reportService: ReportService,
    private translate: TranslateService,
    private gapAnalysisService: GapAnalysisService,
    private mixpanelService: MixpanelService
  ) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.deliverableLabel = MixpanelLabel.gapAnalysis;
    const filter$ = this.gapAnalysisService.getGapAnalysisFilter();
    const report$ = this.reportService.get();
    const subscription = combineLatest([filter$, report$]).subscribe(([filter, report]) => {
      this.filter = filter;
      this.setFilters(this.filter, report);
    });

    this.gapAnalysisService.loadDefaultFilter();
    this.subscriptions.push(subscription);
  }

  /**
   * Event listener for concept selection change event.
   *
   * @listens event: selectionChange
   * @param {Array<DropdownItem>} allConcepts
   * @memberOf FilterComponent
   */
  selectConcept(allConcepts: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      concepts: this.filter.concepts.map((concept) => {
        return {
          ...concept,
          isSelected: allConcepts.find(selectedConcept => selectedConcept.value === concept.name).selected
        };
      })
    });
  }

  selectAttribute(allAttributes: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      attributes: this.filter.attributes.map((attribute) => {
        return {
          ...attribute,
          isSelected: allAttributes.find(selectedAttribute => selectedAttribute.value === attribute.name).selected
        };
      })
    });
  }

  /**
   * Event listener for subgroup selection change event.
   *
   * @listens event: selectionChange
   * @param {Array<DropdownItem>} subgroups
   * @memberOf FilterComponent
   */
  selectSubgroup(subgroups: Array<DropdownItem<string>>) {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    this.filterService.update({
      ...this.filter,
      subgroups: this.filter.subgroups.map((subgroup) => {
        return {
          ...subgroup,
          isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
        };
      })
    });
  }

  /**
   * Sets the filter options for concepts, countries, subgroups.
   *
   * @private
   * @param {GapAnalysisFilter} filter
   * @param {Report} report
   * @memberOf FilterComponent
   */
  private setFilters(filter: GapAnalysisFilter, report: Report): void {
    this.conceptsListData = this.getConceptFilterOptions(filter);
    this.attributesListData = this.getAttributeFilterOptions(filter);
    this.subgroupListData = this.getSubGroupFilterOptions(filter);
    this.countryListData = this.getCountryOptions(filter.countries[0]);
  }

  /**
   * Returns restage related concepts filter options.
   *
   * @private
   * @param filter
   * @returns {DropdownData}
   * @memberOf FilterComponent
   */
  private getConceptFilterOptions(filter: GapAnalysisFilter): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = filter.concepts.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('restage.deliverable.gap.analysis.filter.concepts.label'),
      dataType: DataType.CHECKBOX,
      contentTitle: this.translate.instant('restage.deliverable.gap.analysis.filter.concepts.itemsTitle'),
      groupSelect: this.translate.instant('restage.deliverable.gap.analysis.filter.concepts.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns restage related attribute filter options.
   *
   * @private
   * @param filter
   * @returns {DropdownData}
   * @memberOf FilterComponent
   */
  private getAttributeFilterOptions(filter: GapAnalysisFilter): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = filter.attributes.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('restage.deliverable.gap.analysis.filter.attributes.label'),
      dataType: DataType.CHECKBOX,
      contentTitle: this.translate.instant('restage.deliverable.gap.analysis.filter.attributes.itemsTitle'),
      groupSelect: this.translate.instant('restage.deliverable.gap.analysis.filter.attributes.allSelectedLabel'),
      data: dropdownData
    };

    return dropdownItems;
  }

  /**
   * Returns subgroups filter options.
   *
   * @private
   * @param filter
   * @returns {DropdownData}
   * @memberOf FilterComponent
   */
  private getSubGroupFilterOptions(filter: GapAnalysisFilter): DropdownData<string> {
    const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
      return {
        value: item.name,
        label: item.name,
        selected: item.isSelected
      };
    });

    const dropdownItems: DropdownData<string> = {
      dropdownLabel: this.translate.instant('restage.deliverable.gap.analysis.filter.subgroups.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('restage.deliverable.gap.analysis.filter.subgroups.itemsTitle'),
      data: dropdownData
    };

    return dropdownItems;
  }



  /**
   * Returns the country filter options.
   *
   * @private
   * @param {string} country
   * @returns {DropdownData}
   * @memberOf FilterComponent
   */
  private getCountryOptions(country: string): DropdownData<string> {
    return {
      dropdownLabel: this.translate.instant('restage.deliverable.gap.analysis.filter.country.label'),
      dataType: DataType.RADIO,
      contentTitle: this.translate.instant('restage.deliverable.gap.analysis.filter.country.itemsTitle'),
      data: [
        {
          value: country,
          label: country,
          selected: true
        }
      ]
    };
  }

  /**
   * Emits event of the selected filter menu item.
   */
  selectedEvent(event): void {
    this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
    console.log(event);
  }

  /**
   * Cleanup the component on removing from the UI.
   *
   * @memberOf FilterComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
