import {Injectable} from '@angular/core';
import {StrengthWatchoutsDeliverableView} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';
import {Concept} from '@platform/models/concept.model';
import {AppConfigService} from '@app/core/config/app-config.service';
import {StrengthWatchoutsService} from '@app/deliverables/strengths-watchouts/strength-watchouts.service';

@Injectable({
  providedIn: 'root'
})
export class VerbatimsService {

  /**
   *
   * @param {AppConfigService} appConfigService
   */
  constructor(private appConfigService: AppConfigService) {
  }

  /**
   * Sets the verbatims data.
   * @param {StrengthWatchoutsDeliverableView} verbatimsData
   * @param {Array<Concept>} concepts
   */
  getVerbatimsData(verbatimsData: StrengthWatchoutsDeliverableView, concepts: Array<Concept>): StrengthWatchoutsDeliverableView {
    const verbatimsDataConcepts = [];
    for (const verbatimConcept of verbatimsData.concepts) {
      const concept: Concept = concepts.find((item: Concept) => item.exerciseConceptId === verbatimConcept.exerciseConceptId);
      const dataConceptOptionalProperties = {
        label: concept.name,
        imageHeight: concept.imageHeight,
        imageWidth: concept.imageWidth,
        imagePath: `${this.appConfigService.config.reporting.url}/reports/${concept.reportId}/concepts/${concept.id}?imageFiles=true&locale=${verbatimConcept.locale}`,
        position: concept.position
      };
      const verbatimsDataConcept = Object.assign({}, verbatimConcept, dataConceptOptionalProperties);
      verbatimsDataConcepts.push(verbatimsDataConcept);
    }
    verbatimsData.concepts = StrengthWatchoutsService.sortConcepts(verbatimsDataConcepts);
    return verbatimsData;
  }
}
