import {AppConfigService} from '@app/core/config/app-config.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Collaborator} from '@platform/models/collaborator.model';
import {IntegrationService} from './integration.service';

/**
 * Collaborator service provides operations on Collaborators
 *
 * @example
 * constructor(private collaboratorService: CollaboratorService) { }
 *
 * @export
 * @class CollaboratorService
 */
@Injectable({
    providedIn: 'root'
})
export class CollaboratorService {

    static readonly FORECAST_SHARE_PERMISSION = 'FORECAST_SHARE';
    static readonly DELIVERABLE_SHARE_PERMISSION = 'SHARE';

    /**
     * Creates an instance of CollaboratorService.
     *
     * @constructor
     * @param {HttpClient} httpClient The HttpClient.
     * @param {AppConfigService} appConfigService Application configuration service
     * @memberOf CollaboratorService
     */
    constructor(
        private httpClient: HttpClient,
        private appConfigService: AppConfigService,
        private integrationService: IntegrationService) {
    }

    /**
     * Fetch Collaborators from studio service
     *
     * @public
     * @param {string} reportId The report id.
     * @returns {Observable<Collaborator[]>} List of Collaborators
     * @memberOf CollaboratorService
     */
    public get(reportId): Observable<any> {
        const collaboratorUrl = this.integrationService.getCollaboratorsURL();
        const url = `${collaboratorUrl}?assetId=${reportId}&assetType=project`;
        return this.httpClient.get<any>(url);
    }

    /**
     * Fetch Collaborators from Report
     *
     * @public
     * @param {string} reportId The report id.
     * @returns {Observable<Collaborator[]>} List of Collaborators
     * @memberOf CollaboratorService
     */
    public fetchReportCollaborators(reportId: string): Observable<Array<Collaborator>> {
        const url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/collaborators`;
        return this.httpClient.get<Array<Collaborator>>(url);
    }

    /**
     * Update the Collaborators properties
     *
     * @public
     * @param {collaborators} collaborators details of collaborators
     * @param {string} reportId The report id.
     * @returns {Observable<any>} observable
     * @memberOf ReportService
     */
    public update(collaborators, reportId): Observable<any> {
        const url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/collaborators/all`;
        return this.httpClient.put(url, collaborators);
    }

    /**
     * Update the Collaborators properties
     *
     * @public
     * @param {collaborators} collaborators details of collaborators
     * @param {string} reportId The report id.
     * @returns {Observable<any>} observable
     * @memberOf ReportService
     */
    public updatePermissions(reportId: string, permissionChanges: Array<{ collaborator: Collaborator, permission: string, add: boolean }>, notifyUsers: Array<number>): Observable<Array<Collaborator>> {
        const url = `${this.appConfigService.config.reporting.url}/reports/${reportId}/collaborators/trigger/updatePermissions`;
        const changes = permissionChanges.reduce((acc, permissionChange) => {
            let match = acc.find(it => it.userManagementId === permissionChange.collaborator.userManagementId);
            if (!match) {
                acc.push({
                    userManagementId: permissionChange.collaborator.userManagementId,
                    permissionsToAdd: permissionChange.add ? [permissionChange.permission] : [],
                    permissionsToRemove: !permissionChange.add ? [permissionChange.permission] : [],
                });
            } else {
                if (permissionChange.add) {
                    match.permissionsToAdd.push(permissionChange.permission);
                } else {
                    match.permissionsToRemove.push(permissionChange.permission);
                }
            }
            return acc;
        }, []);
        return this.httpClient.put<Array<Collaborator>>(url, {changes, notifyUsers});
    }
}
