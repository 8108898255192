import {InsightService} from '@platform/insights/insights.service';
import {RouterService} from '@platform/services/router.service';
import {Filter} from '@platform/models/filter.model';
import {ViewMetaInfo} from '@platform/models/view-meta-info.model';
import {Update} from '@ngrx/entity';
import {TranslateService} from '@ngx-translate/core';
import {FilterService} from '@platform/services/filter.service';
import {selectUserViewById} from '@platform/store/selectors/user-view.selectors';
import {State} from '@platform/store/state/app.state';
import {AppConfigService} from '@app/core/config/app-config.service';
import {ReportService} from '@platform/services/report.service';
import {Observable, of, zip} from 'rxjs';
import {skipWhile, switchMap, take, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {UserView} from '@platform/models/user-view.model';
import {
    addUserView,
    addUserViews,
    updateUserView,
    deleteUserView,
    updateManyUserViews
} from '@platform/store/actions/user-view.actions';
import {selectReportUserViews} from '@platform/store/selectors/user-view.selectors';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';

/**
 * This service has operations for fetching and loading report userViews.
 *
 * @example
 * constructor(private userViewService: UserViewService) { }
 *
 * @export
 * @class UserViewService
 */
@Injectable({
    providedIn: 'root'
})
export class UserViewService {

    /**
     * UserView API path template.
     *
     * @private
     * @type {string}
     * @memberof UserViewService
     */
    private apiPathTemplate: string;

    /**
     * Default view name.
     *
     * @private
     * @type {string}
     * @memberof UserViewService
     */
    public readonly defaultViewName: string;

    public readonly insightViewName = 'New View';

    public readonly insightViewId = 'newView';

    /**
     * Loading flag to prevent multiple server calls.
     *
     * @private
     * @memberof UserViewService
     */
    private isLoading = false;

    /**
     * Creates an instance of UserViewService.
     *
     * @constructor
     * @param {Store<State>} store
     * @param {HttpClient} httpClient
     * @param {ReportService} reportService
     * @param {FilterService} filterService
     * @param {ViewMetaInfoService} viewMetaInfoService
     * @param {AppConfigService} appConfigService
     * @memberof UserViewService
     */
    constructor(
        private store: Store<State>,
        private httpClient: HttpClient,
        private reportService: ReportService,
        private filterService: FilterService,
        private viewMetaInfoService: ViewMetaInfoService,
        private appConfigService: AppConfigService,
        private routeService: RouterService,
        private insightService: InsightService,
        private translate: TranslateService) {
        this.apiPathTemplate = `${this.appConfigService.config.reporting.url}/reports/$reportId/userViews`;
        this.defaultViewName = translate.instant('shared.deliverable.header.save.view.default.name');
    }

    /**
     * Returns observable of userViews for the current report object
     * from the store if available else fetch it using API.
     *
     * @example
     * const userViews$ = userViewService.getReportUserViews();
     *
     * @returns {Observable<Array<UserView>>}
     * @memberof UserViewService
     */
    public getReportUserViews(): Observable<Array<UserView>> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                this.load(report.id);
                return this.fetchReportUserViewsFromStore(report.id);
            }),
            skipWhile(userViews => userViews.length < 1)
        );
    }

    /**
     * filters the deliverables based on the type.
     *
     * @param {string} deliverableType
     * @returns {Observable<Array<UserView>>} the user views for the deliverables.
     */
    public getByDeliverable(deliverableType: string): Observable<Array<UserView>> {
        const filtered$: Observable<Array<UserView>> = this.getReportUserViews().pipe(
            map(userViews => userViews.filter(uv => uv.filter.deliverableType === deliverableType))
        );
        return filtered$;
    }

    /**
     * Updates an userView object on the server and the local store. Returns the updated
     * userView from store.
     *
     * @param {UserView} userView
     * @returns {Observable<UserView>} observable of userView
     */
    public update(userView: UserView): Observable<UserView> {
        const userView$ = this.updateUserView(userView).pipe(
            switchMap(updatedUserView => {
                this.store.dispatch(updateUserView({userView: updatedUserView}));
                return this.store.pipe(select(selectUserViewById, {id: updatedUserView.id}));
            })
        );
        return userView$;
    }

    /**
     * Updates an userView object on the server and the local store. Returns the updated
     * userView from store.
     *
     * @param {UserView} userView
     * @returns {Observable<UserView>} observable of userView
     */
    public updateUserView(userView: UserView): Observable<UserView> {
        const url = `${this.getAPIPath(userView.reportId)}/${userView.id}`;
        return this.httpClient.put<UserView>(url, userView);
    }

    /**
     * Set the userView object as default. Returns the updated
     * userView from store.
     *
     * @param {UserView} selectedUserView
     * @param {UserView[]} userViews
     */
    public setSelectedView(selectedUserView: UserView, userViews: UserView[]): void {
        let userView: UserView;
        if (selectedUserView.id === this.defaultViewName) {
            // Get selected view other than the default if available as we need to set it to false.
            userView = userViews.find(view => view.isSelected && view.id !== this.defaultViewName);
            // unselected the view
            selectedUserView = {...userView, isSelected: false};
        } else {
            // Clone and set selected to true
            selectedUserView = {...selectedUserView, isSelected: true};
        }
        this.updateAllViews(selectedUserView);
    }

    /**
     * Updates all the user views on the store
     * @param selectedUserView
     */
    public updateAllViews(selectedUserView: UserView) {
        const update$ = this.update(selectedUserView).pipe(take(1));
        const views$ = this.getByDeliverable(selectedUserView.filter.deliverableType);
        update$.pipe(
            switchMap(() => views$),
            take(1)
        ).subscribe(views => {
            const updatedViews: Update<UserView>[] = [];
            let updatedView: Update<UserView>;
            views.forEach(view => {
                const isSelected = view.id === selectedUserView.id ? selectedUserView.isSelected : false;
                updatedView = {id: view.id, changes: {isSelected}};
                updatedViews.push(updatedView);
            });
            this.store.dispatch(updateManyUserViews({userViews: updatedViews}));
        });
    }

    /**
     * Sets the filter and metaInfo from the view.
     *
     * @param {UserView} userView
     */
    public setFilterAndMetaInfo(userView: UserView): void {
        let metaInfo = userView.metaInfo;
        if (metaInfo) {
            metaInfo = JSON.parse(JSON.stringify(metaInfo));
            metaInfo.deliverableType = userView.deliverableType;
        } else {
            metaInfo = {deliverableType: userView.deliverableType};
        }
        this.filterService.update(userView.filter);
        this.viewMetaInfoService.update(userView.metaInfo, userView.deliverableType);
    }

    /**
     * Returns a transient user view built from the insights if its available.
     * If insight is not available then it returns null.
     *
     * @returns {Observable<UserView>} the userView observable.
     */
    public getInsightUserView(): Observable<UserView> {
        const userView$: Observable<UserView> = this.routeService.getQueryParams$().pipe(
            switchMap(params => {
                const insightId = params.insightId;
                const result$ = insightId ? this.insightService.getReportInsights() : of(null);
                return result$;
            }),
            switchMap(insights => {
                let insightUserView: UserView;
                if (insights) {
                    const insightId = this.routeService.getQueryParam('insightId');
                    const insight = insights.find(item => item.id === insightId);
                    insightUserView = this.getTransientView(
                        insight.reportId,
                        insight.deliverable.deliverableType,
                        insight.deliverable.metaInfo,
                        insight.deliverable.filter);
                }
                return [insightUserView];
            }),
            take(1)
        );
        return userView$;
    }

    /**
     * userView Object for setting a new temporary view which will not require any storing.
     *
     * @param reportId
     * @param deliverableType
     * @param metaInfo
     * @param filter
     */
    public getTransientView(reportId: string, deliverableType: string, metaInfo: ViewMetaInfo, filter: Filter): UserView {
        const transientView: UserView = {
            id: this.insightViewId,
            name: this.insightViewName,
            reportId: reportId,
            filter: filter,
            metaInfo: metaInfo,
            deliverableType: deliverableType,
            isSelected: false
        };
        return transientView;
    }

    /**
     * Returns the selected view in the list if a non default view is selected or
     * returns the default view as selected.
     *
     * @param {UserView[]} userViews
     * @returns { UserView } the selected user view
     */
    public getSelectedView(userViews: UserView[]): UserView {
        const defaultView = userViews.find(view => view.id === this.defaultViewName);
        const userView = userViews.find(view => view.isSelected && view?.id !== this.defaultViewName);
        const selectedView = userView ? userView : defaultView;
        return selectedView;
    }

    /**
     * Creates an userView object on the server and the local store. Returns the new
     * userView from store.
     *
     * @param {string} name
     * @param {deliverableType} type
     * @returns {Observable<UserView>} observable of userView
     */
    public save(name: string, deliverableType: string): Observable<UserView> {
        const report$ = this.reportService.get();
        const currentFilter$ = this.filterService.get(deliverableType);
        const metaInfo$ = this.viewMetaInfoService.get(deliverableType);
        const userView: any = {name};
        const userView$ = zip(report$, currentFilter$, metaInfo$).pipe(
            take(1),
            switchMap(result => {
                const url = this.getAPIPath(result[0].id);
                userView.reportId = result[0].id;
                userView.filter = {...result[1], userView: true};
                userView.deliverableType = result[1].deliverableType;
                userView.metaInfo = result[2];
                userView.isSelected = false;
                return this.httpClient.post<UserView>(url, userView).pipe(
                    switchMap(newUserView => {
                        this.store.dispatch(addUserView({userView: newUserView}));
                        return this.store.pipe(select(selectUserViewById, {id: newUserView.id}));
                    }));
            })
        );
        return userView$;
    }

    public createInsightView(reportId: string, deliverableType: string, insightFilter: Filter): UserView {
        if (insightFilter) {
            return {
                id: this.insightViewId,
                name: this.insightViewName,
                reportId: reportId,
                filter: insightFilter,
                metaInfo: null,
                deliverableType: deliverableType,
                isSelected: true
            };
        }
        return null;
    }

    public createDefaultView(reportId: string, deliverableType: string, defaultFilter: Filter, userViews: Array<UserView>): UserView {
        return {
            id: this.defaultViewName,
            name: this.defaultViewName,
            reportId: reportId,
            filter: {...defaultFilter},
            metaInfo: null,
            deliverableType: deliverableType,
            isSelected: !userViews.find(it => it.isSelected)
        };
    }

    public setupUserViews(reportId: string, deliverableType: string, userViews: Array<UserView>, defaultFilter: Filter, insightFilter: Filter): Array<UserView> {
        const _views = [...userViews.filter(it => it.deliverableType === deliverableType || it.filter.deliverableType === deliverableType)];
        const insightView = this.createInsightView(reportId, deliverableType, insightFilter);
        if (insightView) {
            //If insight view is set then it takes precedence and set every other view as unselected except for insight viewz.
            _views.forEach(it => it.isSelected = false);
            _views.push(insightView);
        }
        _views.splice(0, 0, this.createDefaultView(reportId, deliverableType, defaultFilter, _views));
        return _views;
    }

    /**
     * Creates an userView object on the server and the local store. Returns the new
     * userView from store.
     *
     * @param {string} name
     * @param {deliverableType} type
     * @returns {Observable<UserView>} observable of userView
     */
    public saveUserView(userView: UserView): Observable<UserView> {
        const url = this.getAPIPath(userView.reportId);
        return this.httpClient.post<UserView>(url, userView);
    }

    /**
     * Deletes an userView object on the server and the local store. Returns the response.
     *
     * @param {UserView} userView
     * @returns {Observable<UserView>} observable of userView
     */
    public deleteUserView(userView: UserView): Observable<any> {
        const url = `${this.getAPIPath(userView.reportId)}/${userView.id}`;
        return this.httpClient.delete<any>(url);
    }

    /**
     * Deletes an userView object on the server and the local store. Returns the response.
     *
     * @param {UserView} userView
     * @returns {Observable<UserView>} observable of userView
     */
    public delete(userView: UserView): Observable<any> {
        const url = `${this.getAPIPath(userView.reportId)}/${userView.id}`;
        const completed$ = this.deleteUserView(userView).pipe(
            switchMap((response) => {
                this.store.dispatch(deleteUserView({userView: userView}));
                return of([response]);
            }),
            take(1)
        );
        return completed$;
    }

    /**
     * Loads all userViews associated with the current report into
     * the store.
     *
     * @example
     * userViewService.load('1');
     *
     * @param {string} reportId The report id
     * @memberof UserViewService
     */
    public load(reportId: string): void {
        const userViews$ = this.fetchReportUserViewsFromStore(reportId);
        userViews$.pipe(take(1)).subscribe(userViews => {
            if (this.isLoading !== true && userViews.length === 0) {
                this.isLoading = true;
                this.fetchReportUserViewsFromAPI(reportId).subscribe(result => {
                    this.store.dispatch(addUserViews({userViews: result}));
                    this.isLoading = false;
                });
            }
        });
    }

    /**
     * Returns an observable of userViews for the report fetched using the API.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<UserView>>}
     * @memberof UserViewService
     */
    public fetchReportUserViewsFromAPI(reportId: string): Observable<Array<UserView>> {
        const url = this.getAPIPath(reportId);
        return this.httpClient.get<Array<UserView>>(url);
    }

    /**
     * Fetch all userViews associated with the current report
     * from the store.
     *
     * @private
     * @param {string} reportId The report id.
     * @returns {Observable<Array<UserView>>}
     * @memberof UserViewService
     */
    private fetchReportUserViewsFromStore(reportId: string): Observable<Array<UserView>> {
        return this.store.pipe(
            select(selectReportUserViews, {reportId})
        );
    }

    /**
     * Returns API url.
     *
     * @param reportId the report id
     */
    private getAPIPath(reportId: string): string {
        return this.apiPathTemplate.replace('$reportId', reportId);
    }

    /**
     * Returns the default view. This is not not persisted.
     *
     * @param {string} deliverableType
     * @returns {Observable<UserView>} the default user view for the deliverable type.
     */
    public getDefaultView(deliverableType: string): Observable<UserView> {
        const filter$ = this.filterService.get(deliverableType);
        const report$ = this.reportService.get();
        const metaInfo$ = this.viewMetaInfoService.get(deliverableType);
        const defaultView$ = zip(report$, filter$, metaInfo$)
            .pipe(
                switchMap(([report, filter, metaInfo]) => {
                    const defaultView: UserView = {
                        id: this.defaultViewName,
                        name: this.defaultViewName,
                        reportId: report.id,
                        filter: filter,
                        metaInfo: metaInfo,
                        deliverableType: deliverableType,
                        isSelected: true
                    };
                    return of(defaultView);
                }),
                take(1)
            );
        return defaultView$;
    }

}
