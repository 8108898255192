import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DeliverableConfiguration} from "@platform/models/deliverable-configuration.model";
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';
import {
    SurveyQuestionAnswerConfiguration,
    SurveyQuestionConfiguration
} from "@platform/models/survey-question-configuration.model";
import {DeliverableConfigurationUtilService} from "@platform/services/deliverable-configuration-util.service";
import {ConfirmationDialogComponent} from "@app/common/confirmation-dialog/confirmation-dialog.component";
import {ConfirmationDialogService} from "@app/common/confirmation-dialog/confirmation-dialog.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {SpinnerService} from "@platform/services/spinner.service";
import {ReportService} from "@platform/services/report.service";

@Component({
    selector: 'ns-survey-questions-configuration',
    templateUrl: './survey-questions-configuration.component.html',
    styleUrls: ['./survey-questions-configuration.component.scss']
})
export class SurveyQuestionsConfigurationComponent implements OnInit, OnChanges {

    /**
     * survey questions deliverable configuration
     * */
    @Input() deliverableConfiguration: DeliverableConfiguration;

    /**
     * Event that is triggered when configuration values changes
     * */
    @Output() deliverableConfigurationChangedEvent = new EventEmitter<DeliverableConfiguration>();

    /**
     * Event that is triggered to move the configuration menu to previous menu.
     * Signals parent component to move to previous menu screen.
     * */
    @Output() moveToPreviousMenuEvent = new EventEmitter();

    @Output() closeFlyoutMenuEvent = new EventEmitter();

    /**
     * Event that is triggered after configuration is saved.
     * */
    @Output() saveEvent = new EventEmitter<DeliverableConfiguration>();

    clonedDeliverableConfiguration: DeliverableConfiguration;

    surveyQuestionConfigurations: Array<SurveyQuestionConfiguration>;

    expandedSurveyQuestionConfiguration: Set<SurveyQuestionConfiguration> = new Set([]);

    enableReset: boolean = false;

    enableSave: boolean = false;

    /**
     * In some case the question positions are not continuous numbers for eg position could be 1,3,7,12 etc.
     * In such case when we move the question, we still want to maintain the actual question position even though
     * they are not continuous numbers. Hence we maintain the sorted list of positions so that when questions are moved
     * we will set the position from this property based on natural position of the question in the parent array.
     * */
    questionPositions: Array<number>;

    constructor(private deliverableConfigurationService: DeliverableConfigurationService,
                private confirmationDialogService: ConfirmationDialogService,
                private dialog: MatDialog,
                private spinnerService: SpinnerService,
                private reportService: ReportService,
                private deliverableConfigurationUtilService: DeliverableConfigurationUtilService) {
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize(): void {
        this.clonedDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(this.deliverableConfiguration);
        const questions = this.clonedDeliverableConfiguration.config.questions;
        this.surveyQuestionConfigurations = [...questions].sort((a, b) => {
            return a.position - b.position;
        });
        this.enableReset = !!this.findUpdatedSurveyQuestionConfiguration();
        this.enableSave = false;
        this.questionPositions = this.surveyQuestionConfigurations.map(it => it.position);
    }

    /**
     * Toggle visibility of question
     * */
    toggleVisibility(surveyQuestionConfiguration: SurveyQuestionConfiguration): void {
        surveyQuestionConfiguration.selected = !surveyQuestionConfiguration.selected;
        this.toggleResetAndSave();
    }

    /**
     * Event that is triggered when the display name is updated/changed
     * */
    displayNameChange(event, surveyQuestionConfiguration: SurveyQuestionConfiguration) {
        if (event.target && event.target.value) {
            surveyQuestionConfiguration.questionName = event.target.value.trim();
        } else {
            surveyQuestionConfiguration.questionName = surveyQuestionConfiguration.originalName;
        }
        this.toggleResetAndSave();
    }

    toggleResetAndSave(): void {
        const configurationHasNotChanged = this.deliverableConfigurationUtilService.compareJson(this.deliverableConfiguration, this.clonedDeliverableConfiguration);
        this.enableReset = !!this.findUpdatedSurveyQuestionConfiguration();
        this.enableSave = !configurationHasNotChanged;
        this.deliverableConfigurationChangedEvent.emit(configurationHasNotChanged ? null : this.clonedDeliverableConfiguration);
    }

    /**
     * Returns first matching config whose original values have differed from assigned values to indicate if
     * "rest to default" should be enabled.
     */
    findUpdatedSurveyQuestionConfiguration(): SurveyQuestionConfiguration {
        const updatedConfiguration = this.clonedDeliverableConfiguration.config.questions.find(it => {
            return it.originalPosition !== it.position ||
                it.questionName !== it.originalName ||
                it.selected !== it.originalSelected ||
                it.answers.some(ansConf => {
                    return ansConf.originalPosition !== ansConf.position ||
                        ansConf.answerText !== ansConf.editedAnswerText ||
                        ansConf.selected !== ansConf.originalSelected;
                });
        });
        return updatedConfiguration;
    }

    /**
     * Sets original values to all the configuration and then triggers save action
     */
    resetToDefault(): void {
        this.clonedDeliverableConfiguration.config.questions.forEach(question => {
            question.position = question.originalPosition;
            question.selected = question.originalSelected;
            question.questionName = question.originalName;

            question.answers.forEach(answer => {
                answer.position = answer.originalPosition;
                answer.selected = answer.originalSelected;
                answer.editedAnswerText = answer.answerText;
            });
        });
        this.toggleResetAndSave();
    }

    onSave() {
        this.spinnerService.showSpinner();
        this.deliverableConfigurationService.updateReportDeliverableConfiguration(this.clonedDeliverableConfiguration, this.clonedDeliverableConfiguration.reportId).subscribe(deliverableConfiguration => {
            this.saveEvent.emit(deliverableConfiguration);
            this.spinnerService.hideSpinner();
            this.reportService.reloadDeliverable.next(null);
        });
    }

    onSaveAndClose() {
        this.spinnerService.showSpinner();
        this.deliverableConfigurationService.updateReportDeliverableConfiguration(this.clonedDeliverableConfiguration, this.clonedDeliverableConfiguration.reportId).subscribe(deliverableConfiguration => {
            this.saveEvent.emit(deliverableConfiguration);
            this.closeFlyoutMenuEvent.emit();
            this.spinnerService.hideSpinner();
            this.reportService.reloadDeliverable.next(null);
        });
    }

    returnBack() {
        if (this.enableSave) {
            const dialogConfig = this.confirmationDialogService.getContinueWithoutSavingConfig();
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(value => {
                if (value === 'CANCEL') {
                    this.dialog.closeAll();
                } else {
                    this.clonedDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(this.deliverableConfiguration);
                    this.moveToPreviousMenuEvent.emit();
                }
            });
        } else {
            this.moveToPreviousMenuEvent.emit();
        }
    }

    /**
     * Action that is triggered when question DOM is moved to change position
     */
    dropQuestion(event: CdkDragDrop<SurveyQuestionConfiguration[]>) {
        moveItemInArray(this.surveyQuestionConfigurations, event.previousIndex, event.currentIndex);
        this.surveyQuestionConfigurations.forEach((it, index) => {
            it.position = this.questionPositions[index];
        });
        this.toggleResetAndSave();
    }

    showAnswers(surveyQuestionConfiguration: SurveyQuestionConfiguration): void {
        if (this.expandedSurveyQuestionConfiguration.has(surveyQuestionConfiguration)) {
            this.expandedSurveyQuestionConfiguration.delete(surveyQuestionConfiguration);
        } else {
            this.expandedSurveyQuestionConfiguration.add(surveyQuestionConfiguration);
        }
    }

    questionAnswerConfigurationChanged(surveyQuestionAnswerConfiguration: SurveyQuestionAnswerConfiguration): void {
        this.toggleResetAndSave();
    }

    onCloseButtonClick(): void {
        if (this.enableSave) {
            const dialogConfig = this.confirmationDialogService.getContinueWithoutSavingConfig();
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(value => {
                if (value === 'CANCEL') {
                    this.dialog.closeAll();
                } else {
                    this.initialize();
                    this.closeFlyoutMenuEvent.emit();
                }
            });
        } else {
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.onCloseButtonClick();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.onCloseButtonClick();
        }
    }
}
