<div class="deliverable-insight-sorting-matrix-chart-container" id="sorting-matrix-chart-1" [style.opacity]="showChart ? 1 : 0">
  <button class="float-right btn btn-flat uppercase highcharts-reset-zoom" [disabled]="disableZoom" (click)="resetZoom();trackReset();">
    <span class="sif sif-refresh sif-14 mr-1"></span>
    <span>{{ 'deliverable.sortingMatrix.reset.zoom' | translate }}</span>
  </button>
  <highcharts-chart
    #container
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [callbackFunction]="chartCallback"
    style="width: 100%; height: 500px; display: block; margin-bottom: 50px;">
  </highcharts-chart>
</div>
