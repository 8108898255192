import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StrengthWatchoutsDeliverableView} from '@app/deliverables/strengths-watchouts/models/strength-watchouts.model';

@Component({
  selector: 'sw-element-engagement-zone',
  templateUrl: './zone-aggregate.component.html',
  styleUrls: ['./zone-aggregate.component.scss']
})
export class ZoneAggregateComponent implements OnInit, OnDestroy {

  @Input() elementEngagement: StrengthWatchoutsDeliverableView;

  @Output() zoneEvent: EventEmitter<string> = new EventEmitter<string>();

  maxTotalClicks:number = 0;

  /**
   * Creates an instance of AttributesComponent and initialize
   * the component data.
   *
   * @constructor
   * @memberof ZoneAggregateComponent
   */
  constructor() {
  }

  /**
   * Initialize component and load data
   *
   * @memberof ElementEngagementComponent
   */
  ngOnInit(): void {
    if(this.elementEngagement && this.elementEngagement.zones)
    {this.maxTotalClicks = Math.max(...this.elementEngagement.zones.map(o=> o.totalClicks),0);}
  }

  onMouseEnter(zoneLabel: string): void {
    this.zoneEvent.emit(zoneLabel);
  }

  onMouseLeave(): void {
    this.zoneEvent.emit('');
  }

  /**
   * Cleanup hook.
   *
   * @memberof StrengthWatchoutsComponent
   */
  ngOnDestroy(): void {

  }
}
