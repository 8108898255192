import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
    selector: 'ns-report-settings-configuration',
    templateUrl: './report-settings-configuration.component.html',
    styleUrls: ['./report-settings-configuration.component.scss']
})
export class ReportSettingsConfigurationComponent {

    @Input() flyoutMainMenu: Array<any>;
    @Output() closeFlyoutMenuEvent = new EventEmitter();
    @Output() moveToPreviousMenuEvent = new EventEmitter();
    @Output() toggleOnclickEvent = new EventEmitter<any>();

    closeFlyoutMenu(): void {
        this.closeFlyoutMenuEvent.emit();
    }

    returnBack(): void {
        this.moveToPreviousMenuEvent.emit();
    }

    toggleOnclick(menuItem): void {
        this.toggleOnclickEvent.emit(menuItem);
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className instanceof String && event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.closeFlyoutMenuEvent.emit();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.closeFlyoutMenuEvent.emit();
        }
    }
}
