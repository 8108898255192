import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Verbatim} from "@products/shared/verbatims-list/verbatim.model";

@Component({
    selector: 'ns-verbatims-list',
    templateUrl: './verbatims-list.component.html',
    styleUrls: ['./verbatims-list.component.scss']
})
export class VerbatimsListComponent {

    /**
     * List of Verbatims
     *
     * @type {Array<string>}
     * @memberof VerbatimsListComponent
     */
    @Input() verbatims: Array<Verbatim>;

    /**
     * Booelan to enable hovering for list
     *
     * @type {boolean}
     * @memberof VerbatimsListComponent
     */
    @Input() hoverEnabled: boolean;

    /**
     * Maximun Height of the list container
     *
     * @type {Array<string>;}
     * @memberof VerbatimsListComponent
     */
    @Input() maxHeight: number;

    /**
     * Search text entered by user
     *
     * @type {Array<string>;}
     * @memberof VerbatimsListComponent
     */
    @Input() searchText: String;

    /**
     * Event for hovering over verbatims.
     * @type {EventEmitter<String>}
     * @memberOf VerbatimsListComponent
     */
    @Output() hoverEvent: EventEmitter<Verbatim> = new EventEmitter<Verbatim>();

    constructor() {
    }

    onMouseEnter(verbatim: Verbatim): void {
        this.hoverEvent.emit(verbatim);
    }

    onMouseLeave(): void {
        this.hoverEvent.emit(null);
    }

}
