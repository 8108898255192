import {deliverableComponents} from '@products/price-advisor/deliverable-component.enum';
import {ProductService} from '@platform/services/product.service';
import {DeliverableType} from "@app/deliverables/deliverable-type.enum";
import {switchMap, take} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {TranslateService} from '@ngx-translate/core';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {Feature} from '@platform/models/feature.model';
import {ProductType} from '@products/product-type.enum';
import {ForecastingService} from '@platform/services/forecasting.service';

export class PriceAdvisorService implements ProductService {

    constructor(private translate: TranslateService,
                private deliverableInfoService: DeliverableInfoService,
                private forecastingService: ForecastingService) {
    }

    public getDeliverableComponents(): { [type: string]: any } {
        return deliverableComponents;
    }

    public getDeliverableInfos(isDefault: boolean): Observable<DeliverableInfo[]> {
        return this.deliverableInfoService
            .mapFromType(DeliverableType, isDefault)
            .pipe(take(1));
    }

    public getFeatures(): Observable<Feature[]> {
        let features: Feature[];
        const isDeliverableExists$ = this.deliverableInfoService.isDeliverableExists();
        const isForecastingExists$ = this.forecastingService.isForecastingExists();
        return combineLatest([isDeliverableExists$, isForecastingExists$]).pipe(switchMap(([isDeliverableExists, isForecastingExists]) => {
            features = [];
            if (isDeliverableExists) {
                features.push({
                    name: this.translate.instant('product.feature.insights'),
                    path: 'insights'
                }, {
                    name: this.translate.instant('product.feature.concepts'),
                    path: 'score-cards'
                }, {
                    name: this.translate.instant('product.feature.deliverables'),
                    path: 'deliverables'
                }, {
                    name: this.translate.instant('product.feature.files'),
                    path: 'report-files'
                });
            } else {
                features.push({
                    name: this.translate.instant('product.feature.files'),
                    path: 'report-files'
                });
            }
            if (isForecastingExists) {
                features.splice(3, 0, {
                    name: this.translate.instant('product.feature.forecasting'),
                    path: 'forecasting'
                });
            }
            return [features];
        }));
    }

    getModule() {
        return import('@products/price-advisor/price-advisor.module').then(m => m.PriceAdvisorModule);
    }

    public getProductName(): string {
        return this.translate.instant(ProductType.PRICE_ADVISOR.labelKey);
    }
}
