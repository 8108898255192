<ns-brand-bar *ngIf="showBrandBar | async" class="insights-hide-element"
              [showComment]="true"
              [userInfo]="userInfo"
              [navigationServiceURL]="userNavigationServiceURL"
              [logoutURL]="logoutURL">
</ns-brand-bar>
<ns-application-bar *ngIf="showApplicationBar | async" class="platform-application-bar"
                    [selectedItemKey]="applicationBarModuleKey"
                    [apiPath]="projectNavigationAPI"
                    [baseURL]="studioURL">
</ns-application-bar>
