import {ProductServiceFactory} from '@platform/services/product-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,} from '@angular/core';
import {ReportService} from '@platform/services/report.service';
import {Report} from '@platform/models/report.model';
import {UserInfo} from '@platform/models/user-info.model';
import {ToasterService} from '@platform/services/toaster.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {Deliverable} from '@platform/models/deliverable.model';
import {Subscription} from 'rxjs';
import {UserService} from '@platform/services/user.service';
import {NavigationService} from '@platform/services/navigation.service';
import {ConfirmationDialogComponent} from '@app/common/confirmation-dialog/confirmation-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConfirmationDialogService} from '@app/common/confirmation-dialog/confirmation-dialog.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableConfigurationService} from '@platform/services/deliverable-configuration.service';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';
import {DeliverablesListItem} from '@products/shared/deliverables-list/deliverables-list-item.data.model';
import {Feature} from '@platform/models/feature.model';
import {DeliverableConfigurationUtilService} from '@platform/services/deliverable-configuration-util.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';

@Component({
    selector: 'ns-report-settings',
    templateUrl: './report-settings.component.html',
    styleUrls: ['./report-settings.component.scss'],
})
export class ReportSettingsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() report: Report;
    @Input() userInfo: UserInfo;
    @Input() showMainMenu: boolean;
    @Input() defaultCardsViewExist: boolean;
    @Output() onFlyoutMenuClick = new EventEmitter<any>();
    @Output() flyoutMenuClose = new EventEmitter();
    @Input() features: Feature[];
    deliverablesInReport: Deliverable[];
    isInternalUser = false;
    disableButtons = true;
    disableResetButton = false;
    defaultDeliverableConfiguration: DeliverableConfiguration;

    initialDeliverableConfiguration: DeliverableConfiguration;
    alteredDeliverableConfiguration: DeliverableConfiguration;
    deliverableConfigurationsInReport: DeliverableConfiguration[];
    clonedForecastEstimateConfiguration: any;
    forecastEstimateConfiguration: any;
    financialPotentialConfiguration: DeliverableConfiguration;
    clonedFinancialPotentialConfiguration: DeliverableConfiguration;
    clonedVolumeEstimateConfiguration: any;
    volumeEstimateConfiguration: any;
    clonedMarketingPlanConfiguration: any;
    marketingPlanConfiguration: any;
    clonedVarietySplitConfiguration: any;
    varietySplitConfiguration: any;
    surveyQuestionsConfiguration: DeliverableConfiguration;
    factorsConfiguration: any;
    clonedFactorsConfiguration: any;
    flyoutMainMenu = [];
    selectedMenu = {name: '', displayName: '', isActive: true, position: 0, cssClassName: ''};
    /**
     * Subscription for deliverableInfo
     *
     * @type {Subscription}
     * @memberof DeliverablesComponent
     */
    subscriptions: Array<Subscription>;
    purchasePreferenceShareConfiguration: any;
    clonedPurchasePreferenceShareConfiguration: any;
    correlationsConfiguration: DeliverableConfiguration;
    clonedCorrelationsConfigurations: DeliverableConfiguration;
    toggleQuadTableAllValues = false;
    toggleDataTableAllValues = false;
    isDefaultConfigUsedForCorrelations = false;

    constructor(
        private reportService: ReportService,
        private toasterService: ToasterService,
        private translate: TranslateService,
        private productServiceFactory: ProductServiceFactory,
        private deliverableInfoService: DeliverableInfoService,
        private userService: UserService,
        private navigationService: NavigationService,
        private confirmationDialogService: ConfirmationDialogService,
        private deliverableViewService: DeliverableViewService,
        private deliverableConfigurationService: DeliverableConfigurationService,
        private dialog: MatDialog,
        private deliverableConfigurationUtilService: DeliverableConfigurationUtilService,
        private spinnerService: SpinnerService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize the ReportSettings component view.
     * @memberof DeliverableConfigurationComponent
     */
    ngOnInit(): void {
        this.showMainMenu = true;
        this.disableResetButton = this.report.deliverables.some((d) => d.showDeliverable === undefined ? false : !d.showDeliverable);
        this.setDeliverableInReportWithFeatureFlag(this.deliverablesInReport);
        this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
        this.deliverableConfigurationService.getAllDeliverableConfigurations().subscribe(config => {
            this.deliverableConfigurationsInReport = config;
            this.getDeliverablesConfigFromStore();
        });


        // Todo enable later
        // this.flyoutMainMenu.push({name: 'itemsAndOrdering',  displayName: 'Items & Ordering', isActive: false, position: 1, cssClassName: 'sif sif-edit'});
        // this.flyoutMainMenu.push({name: 'subgroups', displayName: 'Subgroups', isActive: false, position: 2, cssClassName: 'sif sif-filter'});
        this.flyoutMainMenu.push({
            name: 'deliverableConfiguration',
            show: true,
            displayName: 'Deliverable Configuration',
            isActive: false,
            position: 3,
            cssClassName: 'sif sif-report mainmenu-icons',
            children: [
                {
                    name: 'financial.potential',
                    show: true,
                    displayName: 'Financial Potential',
                    isActive: false,
                    position: 3,
                    cssClassName: '',
                    children: []
                },
                {
                    name: 'forecast.volume.revenue',
                    show: true,
                    displayName: this.translate.instant('platform.forecasting.results.label'),
                    isActive: false,
                    position: 5,
                    cssClassName: '',
                    children: []
                },
                {
                    name: 'forecast.marketing.plan.summary',
                    show: true,
                    displayName: this.translate.instant('platform.forecasting.marketing.summary.label'),
                    isActive: false,
                    position: 6,
                    cssClassName: '',
                    children: []
                },
                {
                    name: 'forecast.variety.split',
                    show: true,
                    displayName: this.translate.instant('platform.forecasting.variety.split.label'),
                    isActive: false,
                    position: 7,
                    cssClassName: '',
                    children: []
                },
                {
                    name: 'purchase.preference',
                    show: true,
                    displayName: this.translate.instant('platform.purchase.preference.share.label'),
                    isActive: false,
                    position: 8,
                    cssClassName: '',
                    children: []
                },
                {
                    name: 'correlations',
                    show: true,
                    displayName: 'Correlations',
                    isActive: false,
                    position: 8,
                    cssClassName: '',
                    children: []
                },
                {
                    name: DeliverableType.SURVEY_QUESTION.type,
                    show: true,
                    displayName: this.translate.instant(DeliverableType.SURVEY_QUESTION.labelKey),
                    isActive: false,
                    position: 9,
                    cssClassName: '',
                    children: []
                },
                {
                    name: 'factors',
                    show: true,
                    displayName: 'factors',
                    isActive: false,
                    position: 10,
                    cssClassName: '',
                    children: []
                }
            ]
        });
        if (this.features.find(f => f.name == 'Forecasting')) {
            this.flyoutMainMenu.push({
                name: 'forecastEstimate',
                show: true,
                displayName: this.translate.instant('platform.forecasting.forecast.estimate.label'),
                isActive: false,
                position: 4,
                cssClassName: 'sif sif-analyze mainmenu-icons'
            });
        }
        const reportUpdateSubscription = this.reportService.fetchReportFromStore().subscribe(result => {
            this.report = result;
            this.setDeliverableInReportWithFeatureFlag(this.report.deliverables);
            this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
        });
        this.subscriptions.push(reportUpdateSubscription);
    }

    returnBack(close = false) {
        if (this.isDeliverablesDirty()) {
            const dialogConfig = this.confirmationDialogService.getContinueWithoutSavingConfig();
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(value => {
                if (value === 'CANCEL') {
                    this.dialog.closeAll();
                } else {
                    switch (this.selectedMenu.name) {
                        case 'forecastEstimate':
                            this.clonedForecastEstimateConfiguration = this.deliverableConfigurationUtilService.deepClone(this.forecastEstimateConfiguration);
                            break;
                        case 'forecast.volume.revenue':
                            this.clonedVolumeEstimateConfiguration = this.deliverableConfigurationUtilService.deepClone(this.volumeEstimateConfiguration);
                            break;
                        case 'forecast.marketing.plan.summary':
                            this.clonedMarketingPlanConfiguration = this.deliverableConfigurationUtilService.deepClone(this.marketingPlanConfiguration);
                            break;
                        case 'forecast.variety.split':
                            this.clonedVarietySplitConfiguration = this.deliverableConfigurationUtilService.deepClone(this.varietySplitConfiguration);
                            break;
                        case 'purchase.preference':
                            this.clonedPurchasePreferenceShareConfiguration = this.deliverableConfigurationUtilService.deepClone(this.purchasePreferenceShareConfiguration);
                            break;
                        case 'correlations':
                            this.clonedCorrelationsConfigurations = this.deliverableConfigurationUtilService.deepClone(this.correlationsConfiguration);
                            break;
                        case 'financial.potential':
                            this.clonedFinancialPotentialConfiguration = this.deliverableConfigurationUtilService.deepClone(this.financialPotentialConfiguration);
                            break;
                        case 'factors':
                            this.clonedFactorsConfiguration = this.deliverableConfigurationUtilService.deepClone(this.factorsConfiguration);
                    }
                    this.setDeliverableInReportWithFeatureFlag(this.report.deliverables);
                    this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
                    this.moveToPreviousMenu();
                    if (close) {
                        this.flyoutMenuClose.emit();
                    }
                }
            });
        } else {
            this.moveToPreviousMenu();
        }
    }

    moveToPreviousMenu() {
        if (this.flyoutMainMenu.find(it => it.name === this.selectedMenu.name)) {
            this.showMainMenu = true;
            this.selectedMenu.isActive = false;
            this.flyoutMainMenu.forEach(it => it.isActive = false);
            this.onFlyoutMenuClick.emit(this.selectedMenu);
        } else {
            this.flyoutMainMenu.forEach(mainMenu => {
                if (mainMenu.children?.find(it => it.name === this.selectedMenu.name)) {
                    this.selectedMenu.isActive = false;
                    this.selectedMenu = mainMenu;
                    this.selectedMenu.isActive = true;
                    this.showMainMenu = false;
                    this.onFlyoutMenuClick.emit(this.selectedMenu);
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isDeliverableConfigurationFlagEnabled()) {
            this.deliverablesInReport = this.report.deliverables.filter(d => d.isComplete || d.type === 'correlations').map(u => Object.assign({}, u, {showDeliverable: (u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' || u.type === 'correlations') ? u.showDeliverable : true}));
        } else {
            this.deliverablesInReport = this.report.deliverables.filter(d => d.isComplete).map(u => Object.assign({}, u, {showDeliverable: u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' ? u.showDeliverable : true}));
        }
        this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
        const showMainMenu = changes.showMainMenu;
        if (showMainMenu && showMainMenu.currentValue) {
            this.flyoutMainMenu.forEach(it => it.isActive = false);
            this.selectedMenu.isActive = false;
        }
    }

    toggleOnclick(menuItem) {
        this.showMainMenu = false;
        this.flyoutMainMenu.forEach(it => it.isActive = false);
        this.selectedMenu = this.flyoutMainMenu.find(it => it.name === menuItem.name);
        this.selectedMenu.isActive = true;
        this.onFlyoutMenuClick.emit(menuItem);
        this.disableButtons = true;
    }

    ngOnDestroy(): void {
        this.selectedMenu.isActive = false;
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onSaveAndClose() {
        this.save();
        this.toasterService.openSnackBar(this.translate.instant('report.settings.deliverables.save.success'), 'success');
        this.flyoutMenuClose.emit();
    }

    onSave() {
        this.save();
        this.toasterService.openSnackBar(this.translate.instant('report.settings.deliverables.save.success'), 'success');
    }

    resetToDefault() {
        switch (this.selectedMenu.name) {
            case 'forecastEstimate':
                this.clonedForecastEstimateConfiguration = this.resetEstimateData(this.clonedForecastEstimateConfiguration);
                break;
            case 'forecast.volume.revenue':
                this.clonedVolumeEstimateConfiguration.estimates = this.resetEstimateData(this.clonedVolumeEstimateConfiguration.estimates);
                this.clonedVolumeEstimateConfiguration.formatData = {
                    formatSectionExpanded: true,
                    unit: 'millions',
                    noOfDecimals: 'one'
                };
                this.clonedVolumeEstimateConfiguration.mboList[0].isVisible = true;
                this.clonedVolumeEstimateConfiguration.tableData = this.resetTableData(this.clonedVolumeEstimateConfiguration.tableData);
                break;
            case 'forecast.marketing.plan.summary':
                this.clonedMarketingPlanConfiguration.estimates = this.resetEstimateData(this.clonedMarketingPlanConfiguration.estimates);
                this.clonedMarketingPlanConfiguration.tableData = this.resetTableData(this.clonedMarketingPlanConfiguration.tableData);
                this.clonedMarketingPlanConfiguration.headerTableData = this.resetTableData(this.clonedMarketingPlanConfiguration.headerTableData);
                this.clonedMarketingPlanConfiguration['decimals'] = '1';
                break;
            case 'forecast.variety.split':
                this.resetVarietySplitConfig();
                break;
            case 'purchase.preference':
                this.clonedPurchasePreferenceShareConfiguration.analyticsOptionSelected = 'purchasePreference';
                break;
            case 'correlations':
                this.clonedCorrelationsConfigurations.config.measures.every((measure) => measure.dataTable = measure.quadMap = measure.selected = true);
                this.toggleQuadTableAllValues = true;
                this.toggleDataTableAllValues = true;
                this.disableResetButton = true;
                this.disableButtons = false;
                break;
            case 'financial.potential':
                for (const key in this.clonedFinancialPotentialConfiguration.config) {
                    let resetConfig = this.clonedFinancialPotentialConfiguration.config;
                    if (key === 'conceptPotentialScore') {
                        resetConfig[key] = false;
                    } else {
                        resetConfig[key] = true;
                    }
                    this.clonedFinancialPotentialConfiguration = {
                        ...this.clonedFinancialPotentialConfiguration,
                        config: resetConfig,
                    };
                }
                break;
            case 'factors':
                this.clonedFactorsConfiguration = this.deliverableConfigurationUtilService.deepClone(this.factorsConfiguration);
                for(let key in this.clonedFactorsConfiguration) {
                    if(key.toString() === 'probabilityOfSuccess') {
                        this.clonedFactorsConfiguration['probabilityOfSuccess'] = false;
                        continue;
                    }
                    if(key.toString() === 'messageConnection' || key.toString() === 'clearConciseMessage' || key.toString() === 'messagingCommunicationsFactors') {
                        this.clonedFactorsConfiguration['messageConnection'] = this.clonedFactorsConfiguration['clearConciseMessage'] = this.clonedFactorsConfiguration['messagingCommunicationsFactors'];
                        continue;
                    }
                    this.clonedFactorsConfiguration[key.toString()] = true;
                }
                this.clonedFactorsConfiguration = this.deliverableConfigurationUtilService.deepClone(this.clonedFactorsConfiguration);
                this.disableResetButton = true;
                this.disableButtons = false;
                break;
            default:
                this.alteredDeliverableConfiguration = this.defaultDeliverableConfiguration;
                this.deliverablesInReport = this.report.deliverables.filter(d => d.isComplete || d.type === 'correlations').map(u => Object.assign({}, u, {showDeliverable: true}));
        }
        this.selectedMenu.isActive = false;
        this.disableButtons = false;
    }

    save() {
        if (this.isDeliverableConfigurationFlagEnabled() && this.clonedCorrelationsConfigurations && !this.clonedCorrelationsConfigurations?.config?.measures.find(it => it.dataTable || it.quadMap)) {
            this.deliverablesInReport.find(it => it.type === 'correlations').showDeliverable = false;
        }
        this.reportService.updateReportDeliverables(this.deliverablesInReport.map(u => Object.assign({}, u)), this.report.id).subscribe(result => {
            if (this.isDeliverableConfigurationFlagEnabled()) {
                this.deliverablesInReport = result.filter(d => d.isComplete || d.type === 'correlations').map(u => Object.assign({}, u));
            } else {
                this.deliverablesInReport = result.filter(d => d.isComplete).map(u => Object.assign({}, u));
            }
            this.report = {
                ...this.report,
                deliverables: result
            };
            this.reportService.update(this.report);
            if (this.isDeliverableConfigurationFlagEnabled() && this.isDefaultConfigUsedForCorrelations) {
                 const correlationDeliverable = result.find(d => d.type === 'correlations');
                 if (correlationDeliverable && correlationDeliverable.showDeliverable) {
                     this.saveCorrelationConfiguration();
                 }
            }
            this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
            this.disableButtons = true;
            this.disableResetButton = this.report.deliverables.some((d) => d.showDeliverable === undefined ? false : !d.showDeliverable);
        });
        let alteredDeliverableConfiguration;
        switch (this.selectedMenu.name) {
            case 'forecastEstimate':
                const forecastEstimateConfigurationFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_ESTIMATE);
                alteredDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(forecastEstimateConfigurationFromStore);
                alteredDeliverableConfiguration.config.estimates = this.clonedForecastEstimateConfiguration;
                this.updateL2DeliverableConfiguration(alteredDeliverableConfiguration);
                this.deliverableConfigurationService.updateReportDeliverableConfiguration(alteredDeliverableConfiguration, this.report.id).subscribe(view => {
                });
                break;
            case 'forecast.volume.revenue':
                const volumeConfigFromStrore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE);
                alteredDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(volumeConfigFromStrore);
                alteredDeliverableConfiguration.config = this.clonedVolumeEstimateConfiguration;
                this.deliverableConfigurationService.updateReportDeliverableConfiguration(alteredDeliverableConfiguration, this.report.id).subscribe(view => {
                });
                break;
            case 'forecast.marketing.plan.summary':
                const mpConfigFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY);
                alteredDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(mpConfigFromStore);
                alteredDeliverableConfiguration.config = this.clonedMarketingPlanConfiguration;
                this.deliverableConfigurationService.updateReportDeliverableConfiguration(alteredDeliverableConfiguration, this.report.id).subscribe(view => {
                });
                break;
            case 'forecast.variety.split':
                const varietyConfigFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT);
                alteredDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(varietyConfigFromStore);
                alteredDeliverableConfiguration.config = this.clonedVarietySplitConfiguration;
                this.deliverableConfigurationService.updateReportDeliverableConfiguration(alteredDeliverableConfiguration, this.report.id).subscribe(view => {
                });
                break;
            case 'purchase.preference':
                const purchasePreferenceConfigFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.PURCHASE_PREFERENCE_SHARE);
                alteredDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(purchasePreferenceConfigFromStore);
                alteredDeliverableConfiguration.config = this.clonedPurchasePreferenceShareConfiguration;
                this.deliverableConfigurationService.updateReportDeliverableConfiguration(alteredDeliverableConfiguration, this.report.id).subscribe(view => {
                });
                break;
            case 'financial.potential':
                this.saveFinancialPotentialConfiguration();
                break;
            case 'correlations':
                  this.saveCorrelationConfiguration();
                  break;
            case 'factors':
                this.saveFactorsConfiguration();
                break;
            default:
                if (this.alteredDeliverableConfiguration) {
                    this.deliverableConfigurationService.updateReportDeliverableConfiguration(this.alteredDeliverableConfiguration, this.report.id).subscribe(view => {
                        this.deliverableConfigurationService.update(this.alteredDeliverableConfiguration);
                        this.disableResetButton = true;
                    });
                }
        }
    }

    saveFactorsConfiguration() {
        const factorsConfigFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FACTORS);
        this.alteredDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(factorsConfigFromStore);
        this.alteredDeliverableConfiguration.config = this.clonedFactorsConfiguration;
        this.deliverableConfigurationService.updateReportDeliverableConfiguration(this.alteredDeliverableConfiguration, this.report.id).subscribe(view => {
            this.reportService.factorsConfigChanged$.next(this.alteredDeliverableConfiguration.config);
        });
    }

    saveFinancialPotentialConfiguration() {
        this.spinnerService.showSpinner();
        const financialPotentialConfigFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FINANCIAL_POTENTIAL);
        this.alteredDeliverableConfiguration = this.deliverableConfigurationUtilService.deepClone(financialPotentialConfigFromStore);
        this.alteredDeliverableConfiguration.config = this.clonedFinancialPotentialConfiguration.config;
        this.deliverableConfigurationService.updateReportDeliverableConfiguration(this.alteredDeliverableConfiguration, this.report.id).subscribe(view => {
            this.reportService.financialPotentialConfigChanged$.next(true);
            this.spinnerService.hideSpinner();

        });
    }

    saveCorrelationConfiguration() {
        this.deliverableConfigurationService.updateReportDeliverableConfiguration(this.clonedCorrelationsConfigurations, this.report.id).subscribe(configuration => {
            this.deliverableConfigurationService.loadOnStore(configuration);
            this.correlationsConfiguration = configuration;
            this.clonedCorrelationsConfigurations = this.deliverableConfigurationUtilService.deepClone(this.correlationsConfiguration);
            this.toggleQuadTableAllValues = configuration.config.measures.find((item) => item.dataTable) ? false : true;
            this.toggleDataTableAllValues = configuration.config.measures.find((item) => item.quadMap) ? false : true;
            this.isResetButtonEnabled();
            this.disableButtons = true;
            this.isDefaultConfigUsedForCorrelations = false;
            this.reportService.correlationConfigChanged$.next(this.correlationsConfiguration);
        });
    }

    /**
     * Update the level 2 deliverable configuration when level 1 is updated.
     * @param alteredDeliverableConfiguration updated Level1 deliverable configuration.
     */
    updateL2DeliverableConfiguration(alteredDeliverableConfiguration: any) {
        if (this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE)) {
            const volumeConfigFromStrore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE);
            const volumeConfig = this.deliverableConfigurationUtilService.deepClone(volumeConfigFromStrore);
            volumeConfig.config.estimates = this.deliverableConfigurationService.updateLevel2Config(alteredDeliverableConfiguration.config.estimates, volumeConfig.config.estimates, this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE);
            this.deliverableConfigurationService.updateReportDeliverableConfiguration(volumeConfig, this.report.id).subscribe(view => {
            });
        }
        if (this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY)) {
            const mpConfigFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY);
            const mpConfig = this.deliverableConfigurationUtilService.deepClone(mpConfigFromStore);
            mpConfig.config.estimates = this.deliverableConfigurationService.updateLevel2Config(alteredDeliverableConfiguration.config.estimates, mpConfig.config.estimates, this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY);
            this.deliverableConfigurationService.updateReportDeliverableConfiguration(mpConfig, this.report.id).subscribe(view => {
            });
        }
        if (this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT)) {
            const varietyConfigFromStore = this.deliverableConfigurationsInReport.find(c => c.name == this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT);
            const varietyConfig = this.deliverableConfigurationUtilService.deepClone(varietyConfigFromStore);
            varietyConfig.config.estimates = this.deliverableConfigurationService.updateLevel2Config(alteredDeliverableConfiguration.config.estimates, varietyConfig.config.estimates, this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT);
            this.deliverableConfigurationService.updateReportDeliverableConfiguration(varietyConfig, this.report.id).subscribe(view => {
            });
        }
    }

    /**
     * Method to check if any options have been changed in the flyout menu. initialDeliverablesInReport takes the baseline information from report.deliverables, while alteredDeliverablesInReport is the working copy taken from this.deliverablesInReport.
     * If initialDeliverablesInReport is different from this.deliverablesInReport, the user has changed something in the flyout and the method will return true.
     * Both arrays DO NOT check for position, as that is not considered to be an option that should determine if the array is considered "dirty."
     */
    isDeliverablesDirty() {

        switch (this.selectedMenu.name) {
            case 'forecastEstimate':
                return !this.deliverableConfigurationUtilService.compareJson(this.forecastEstimateConfiguration, this.clonedForecastEstimateConfiguration);
            case 'forecast.volume.revenue':
                return !this.deliverableConfigurationUtilService.compareJson(this.volumeEstimateConfiguration, this.clonedVolumeEstimateConfiguration);
            case 'forecast.marketing.plan.summary':
                return !this.deliverableConfigurationUtilService.compareJson(this.marketingPlanConfiguration, this.clonedMarketingPlanConfiguration);
            case 'forecast.variety.split':
                return !this.deliverableConfigurationUtilService.compareJson(this.varietySplitConfiguration, this.clonedVarietySplitConfiguration);
            case 'purchase.preference':
                return !this.deliverableConfigurationUtilService.compareJson(this.purchasePreferenceShareConfiguration, this.clonedPurchasePreferenceShareConfiguration);
            case 'correlations':
                return !this.deliverableConfigurationUtilService.compareJson(this.correlationsConfiguration.config, this.clonedCorrelationsConfigurations.config);
            case 'factors':
                return !this.deliverableConfigurationUtilService.compareJson(this.factorsConfiguration, this.clonedFactorsConfiguration);
            case 'financial.potential':
                return !this.deliverableConfigurationUtilService.compareJson(this.financialPotentialConfiguration, this.clonedFinancialPotentialConfiguration);

            default:
                if (this.initialDeliverableConfiguration && this.alteredDeliverableConfiguration) {
                    return !this.deliverableConfigurationUtilService.compareJson(this.initialDeliverableConfiguration, this.alteredDeliverableConfiguration);
                } else {
                    let initialDeliverablesInReport = [];
                    if (this.isDeliverableConfigurationFlagEnabled()) {
                        initialDeliverablesInReport = this.report.deliverables.filter(d => d.isComplete || d.type === 'correlations').map(u => Object.assign({}, {type: u.type}, {views: u.views}, {isComplete: u.isComplete}, {showDeliverable: (u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' || u.type === 'correlations') ? u.showDeliverable : true}));
                    } else {
                        initialDeliverablesInReport = this.report.deliverables.filter(d => d.isComplete).map(u => Object.assign({}, {type: u.type}, {views: u.views}, {isComplete: u.isComplete}, {showDeliverable: u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' ? u.showDeliverable : true}));
                    }
                    this.sortDeliverables(initialDeliverablesInReport);
                    let alteredDeliverablesInReport = [];
                    if (this.isDeliverableConfigurationFlagEnabled()) {
                        alteredDeliverablesInReport = this.deliverablesInReport.filter(d => d.isComplete || d.type === 'correlations').map(u => Object.assign({}, {type: u.type}, {views: u.views}, {isComplete: u.isComplete}, {showDeliverable: (u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' || u.type === 'correlations') ? u.showDeliverable : true}));
                    } else {
                        alteredDeliverablesInReport = this.deliverablesInReport.filter(d => d.isComplete).map(u => Object.assign({}, {type: u.type}, {views: u.views}, {isComplete: u.isComplete}, {showDeliverable: u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' ? u.showDeliverable : true}));
                    }
                    alteredDeliverablesInReport = this.sortDeliverables(alteredDeliverablesInReport);
                    this.sortDeliverables(alteredDeliverablesInReport);
                    return this.deliverableConfigurationUtilService.isDeliverablesVisibilityChanged(initialDeliverablesInReport, alteredDeliverablesInReport);
                }
        }
    }

    changeOfShowHideIndicator(deliverableInfo: DeliverableInfo) {
        // Check if the last visibility change to FP was made before FF was turned on. If so, this will sync the visibility choice and use showDeliverable to determine visibility moving forward.
        const fpConfig = this.deliverableConfigurationsInReport.find((config) => config.name === 'fp');
        if (deliverableInfo.type === 'financial.potential' && fpConfig && fpConfig.config.financialPotential === false) {
            this.clonedFinancialPotentialConfiguration = {
                ...fpConfig,
                config: {
                    ...fpConfig.config,
                    financialPotential: deliverableInfo.showDeliverable
                }
            };
            this.saveFinancialPotentialConfiguration();
        }
        this.disableButtons = !this.isDeliverablesDirty();
    }

    /**
     * Method to sort array by the order the deliverables are organized on the deliverables page.
     * @param array the array of objects to be sorted.
     */
    sortDeliverables(array) {
        const deliverables = array.filter(it => it && ((it.type && !it.type.startsWith('forecast'))));
        const forecastDeliverables = array.filter(it => it && (it.type && it.type.startsWith('forecast')));
        const sortFunction = (a, b) => {
            return a.position - b.position;
        };
        const sortedDeliverables = [...deliverables].sort(sortFunction);
        const sortedForecastingDeliverables = [...forecastDeliverables].sort(sortFunction);
        array = [...sortedDeliverables, ...sortedForecastingDeliverables];
        return array;
    }

    updateDeliverableConfiguration(alteredDeliverableView) {
        this.alteredDeliverableConfiguration = alteredDeliverableView;
    }

    selectedDeliverableConfiguration(selectedConfig) {
        this.initialDeliverableConfiguration = selectedConfig;
    }

    getDefaultDeliverableConfiguration(defaultConfig) {
        this.defaultDeliverableConfiguration = defaultConfig;
    }

    isDisableButtons() {
        switch (this.selectedMenu.name) {
            case 'forecastEstimate':
                return this.deliverableConfigurationUtilService.compareJson(this.forecastEstimateConfiguration, this.clonedForecastEstimateConfiguration);
            case 'forecast.volume.revenue':
                if (this.clonedVolumeEstimateConfiguration) {
                    return this.deliverableConfigurationUtilService.compareJson(this.volumeEstimateConfiguration, this.clonedVolumeEstimateConfiguration);
                }
                return true;
            case 'forecast.marketing.plan.summary':
                if (this.clonedMarketingPlanConfiguration) {
                    return this.deliverableConfigurationUtilService.compareJson(this.marketingPlanConfiguration, this.clonedMarketingPlanConfiguration);
                }
                return true;
            case 'forecast.variety.split':
                if (this.clonedVarietySplitConfiguration) {
                    return this.deliverableConfigurationUtilService.compareJson(this.varietySplitConfiguration, this.clonedVarietySplitConfiguration);
                }
                return true;
            case 'purchase.preference':
                if (this.clonedPurchasePreferenceShareConfiguration) {
                    return this.deliverableConfigurationUtilService.compareJson(this.purchasePreferenceShareConfiguration, this.clonedPurchasePreferenceShareConfiguration);
                }
                return true;
            case 'financial.potential':
                if (this.clonedFinancialPotentialConfiguration) {
                    return this.deliverableConfigurationUtilService.compareJson(this.financialPotentialConfiguration, this.clonedFinancialPotentialConfiguration);
                }
                return true;
            case 'correlations':
                if (this.clonedCorrelationsConfigurations) {
                    return this.deliverableConfigurationUtilService.compareJson(this.correlationsConfiguration.config, this.clonedCorrelationsConfigurations.config);
                }
                return true;
            case 'factors':
                return this.deliverableConfigurationUtilService.compareJson(this.factorsConfiguration, this.clonedFactorsConfiguration);
            default:
                return this.disableButtons;
        }
    }

    isResetButtonEnabled() {
        switch (this.selectedMenu.name) {
            case 'forecastEstimate':
                if (this.forecastEstimateConfiguration) {
                    return this.isEstimateDataUpdated(this.forecastEstimateConfiguration);
                }
                return true;
            case 'forecast.volume.revenue':
                if (this.volumeEstimateConfiguration) {
                    return !this.volumeEstimateConfiguration.mboList[0].isVisible ||
                        this.isEstimateDataUpdated(this.volumeEstimateConfiguration.estimates) ||
                        this.isFormatDataUpdated(this.volumeEstimateConfiguration.formatData) ||
                        this.isTableDataUpdated(this.volumeEstimateConfiguration.tableData);
                }
                return true;
            case 'forecast.marketing.plan.summary':
                if (this.marketingPlanConfiguration) {
                    return this.isEstimateDataUpdated(this.marketingPlanConfiguration.estimates) ||
                        this.isTableDataUpdated(this.marketingPlanConfiguration.tableData) ||
                        this.isTableDataUpdated(this.marketingPlanConfiguration.headerTableData) ||
                        this.marketingPlanConfiguration['decimals'] !== '1';
                }
                return true;
            case 'forecast.variety.split':
                if (this.varietySplitConfiguration) {
                    return this.isVarietySplitConfigUpdated();
                }
                return true;
            case 'purchase.preference':
                if (this.purchasePreferenceShareConfiguration) {
                    return !(this.purchasePreferenceShareConfiguration.analyticsOptionSelected === 'purchasePreference');
                }
                return true;
            case 'correlations':
                if (this.clonedCorrelationsConfigurations) {
                    return this.clonedCorrelationsConfigurations?.config?.measures?.find((m) => !m.dataTable || !m.quadMap) ? true : false;
                }
                return true;
            case 'factors':
                return this.isFactorsConfigUpdated();
            case 'financial.potential':
                return this.isFinancialPotentialUpdated();
            default:
                return this.disableResetButton;
        }
    }

    getDeliverablesConfigFromStore() {
        this.deliverableConfigurationService.getAllDeliverableConfigurationsFromStore(this.report.id).subscribe(config => {
            this.deliverableConfigurationsInReport = config;
            if (this.deliverablesInReport.find(del => del.type === 'financial.potential')) {
                const financialPotentialConfiguration = config.find(c => c.name === this.deliverableConfigurationService.FINANCIAL_POTENTIAL);
                this.financialPotentialConfiguration = financialPotentialConfiguration;
                if (!financialPotentialConfiguration) {
                    this.createFinancialPotentialConfig();
                }
                // Check if the last visibility change to FP was made before FF was turned on. If so, this will sync the visibility choice and use showDeliverable to determine visibility moving forward.
                const fpConfig = this.deliverableConfigurationsInReport.find((config) => config.name === 'fp');
                let fpDeliverable = this.deliverablesInReport.find((deliverable) => deliverable.type === 'financial.potential');
                if (fpConfig && fpConfig.config.financialPotential === false && fpDeliverable.showDeliverable === true) {
                    fpDeliverable.showDeliverable = false;
                    this.financialPotentialConfiguration = {
                        ...this.financialPotentialConfiguration,
                        config: {
                            ...this.financialPotentialConfiguration.config,
                            financialPotential: false
                        }
                    };
                    this.save();
                }
                this.clonedFinancialPotentialConfiguration = this.deliverableConfigurationUtilService.deepClone(this.financialPotentialConfiguration);
            }
            if (config.find(c => c.name == this.deliverableConfigurationService.FORECAST_ESTIMATE)) {
                this.forecastEstimateConfiguration = config.find(c => c.name == this.deliverableConfigurationService.FORECAST_ESTIMATE).config?.estimates;
                this.clonedForecastEstimateConfiguration = this.deliverableConfigurationUtilService.deepClone(this.forecastEstimateConfiguration);
            }
            if (config.find(c => c.name == this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE)) {
                this.volumeEstimateConfiguration = config.find(c => c.name == this.deliverableConfigurationService.FORECAST_VOLUME_ESTIMATE).config;
                this.clonedVolumeEstimateConfiguration = this.deliverableConfigurationUtilService.deepClone(this.volumeEstimateConfiguration);
            }
            if (config.find(c => c.name == this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY)) {
                this.marketingPlanConfiguration = config.find(c => c.name == this.deliverableConfigurationService.FORECAST_MARKETING_PLAN_SUMMARY).config;
                this.clonedMarketingPlanConfiguration = this.deliverableConfigurationUtilService.deepClone(this.marketingPlanConfiguration);
            }
            if (config.find(c => c.name == this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT)) {
                this.varietySplitConfiguration = config.find(c => c.name == this.deliverableConfigurationService.FORECAST_VARIETY_SPLIT).config;
                this.clonedVarietySplitConfiguration = this.deliverableConfigurationUtilService.deepClone(this.varietySplitConfiguration);
            }
            if (config.find(c => c.name === this.deliverableConfigurationService.PURCHASE_PREFERENCE_SHARE)) {
                this.purchasePreferenceShareConfiguration = config.find(c => c.name === this.deliverableConfigurationService.PURCHASE_PREFERENCE_SHARE).config;
                this.clonedPurchasePreferenceShareConfiguration = this.deliverableConfigurationUtilService.deepClone(this.purchasePreferenceShareConfiguration);
            }
            this.correlationsConfiguration = config.find(c => c.name === this.deliverableConfigurationService.CORRELATIONS);
            if (this.correlationsConfiguration && this.correlationsConfiguration.config && this.correlationsConfiguration.config.measures.length) {
                this.clonedCorrelationsConfigurations = this.deliverableConfigurationUtilService.deepClone(this.correlationsConfiguration);
                this.isDefaultConfigUsedForCorrelations = false;
            } else {
                const measures = [];
                measures.push(
                    {
                        'correlationLabel': 'Advantage',
                        'basesLabel': 'CADV3',
                        'dataTable': true,
                        'quadMap': true,
                        'type': 'Concept',
                        'selected': true
                    },
                    {
                        'correlationLabel': 'Credibility',
                        'basesLabel': 'CBEL',
                        'dataTable': true,
                        'quadMap': true,
                        'type': 'Concept',
                        'selected': true
                    },
                    {
                        'correlationLabel': 'Uniqueness (Trial)',
                        'basesLabel': 'CNAD',
                        'dataTable': true,
                        'quadMap': true,
                        'type': 'Concept',
                        'selected': true
                    },
                    {
                        'correlationLabel': 'Price / Value (Trial)',
                        'basesLabel': 'CPV',
                        'dataTable': true,
                        'quadMap': true,
                        'type': 'Concept',
                        'selected': true
                    },
                    {
                        'correlationLabel': 'Need / Desire',
                        'basesLabel': 'CREL3',
                        'dataTable': true,
                        'quadMap': true,
                        'type': 'Concept',
                        'selected': true
                    }
                );
                const builder: Partial<DeliverableConfiguration> = {};
                builder.reportId = this.report.id;
                builder.name = 'correlations';
                builder.config = {};

                const correlationsConfiguration: DeliverableConfiguration = builder as DeliverableConfiguration;
                this.correlationsConfiguration = correlationsConfiguration;
                this.correlationsConfiguration.config.measures = measures;
                this.correlationsConfiguration.config.isDefault = true;
                this.isDefaultConfigUsedForCorrelations = true;
                this.clonedCorrelationsConfigurations = this.deliverableConfigurationUtilService.deepClone(this.correlationsConfiguration);
            }
            const surveyQuestionsConfiguration = config.find(it => it.name === DeliverableType.SURVEY_QUESTION.configurationName);
            if (surveyQuestionsConfiguration) {
                this.surveyQuestionsConfiguration = surveyQuestionsConfiguration;
            }
            if (config.find(c => c.name === this.deliverableConfigurationService.FACTORS)) {
                this.factorsConfiguration = config.find(c => c.name === this.deliverableConfigurationService.FACTORS).config;
                this.clonedFactorsConfiguration = this.deliverableConfigurationUtilService.deepClone(this.factorsConfiguration);
            }
        });
    }

    displayButtonGroup() {
        switch (this.selectedMenu.name) {
            case 'forecastEstimate':
                return this.deliverableConfigurationService.isDeliverableFromForecasting;
            case 'forecast.volume.revenue':
                return this.deliverableConfigurationService.isVolumeDeliverableFromForecasting;
            case 'forecast.marketing.plan.summary':
                return this.deliverableConfigurationService.isMarketingPlanDeliverableFromForecasting;
            case 'forecast.variety.split':
                return this.deliverableConfigurationService.isVarietySplitDeliverableFromForecasting;
            case DeliverableType.SURVEY_QUESTION.type:
                return false;
            default:
                return true;
        }
    }

    selectDeliverableConfigurationChild(selectedMenuName) {
        this.showMainMenu = false;
        this.flyoutMainMenu.forEach(it => it.isActive = false);
        this.selectedMenu = this.flyoutMainMenu.find(it => it.name === 'deliverableConfiguration')?.children.find(it => it.name === selectedMenuName);
        this.selectedMenu.isActive = true;
        this.onFlyoutMenuClick.emit(this.selectedMenu);
        this.disableButtons = true;
    }

    discardChangesInMainMenu() {
        this.setDeliverableInReportWithFeatureFlag(this.report.deliverables);
        this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
    }

    updateVarietyConfig(config: any) {
        this.clonedVarietySplitConfiguration.summaryConfig.isVisible = config.summaryConfig.isVisible;
        this.clonedVarietySplitConfiguration.chartConfig.isExpanded = config.chartConfig.isExpanded;
        this.clonedVarietySplitConfiguration.chartConfig.isVisible = config.chartConfig.isVisible;
        this.clonedVarietySplitConfiguration.summaryConfig.isExpanded = config.summaryConfig.isExpanded;
        this.clonedVarietySplitConfiguration.chartConfig.unitContribution.isSelected = config.chartConfig.unitContribution.isSelected;
        this.clonedVarietySplitConfiguration.chartConfig.revenueContribution.isSelected = config.chartConfig.revenueContribution.isSelected;
        this.clonedVarietySplitConfiguration['decimals'] = config.decimals;
    }

    resetTableData(tableData: any) {
        tableData.forEach(item => {
            item.displayName = item.originalName;
            item.isVisible = true;
            item.position = item.originalPosition;
            item.data?.forEach(element => {
                element.checked = (element.id === 'endOfYear') ? true : false;
            });

            if (item.children && item.children.length > 0) {
                this.resetTableData(item.children);
                item.children.sort((a, b) => {
                    return a.position - b.position;
                });
            }
        });
        return this.deliverableConfigurationUtilService.deepCloneAndSort(tableData);
    }

    resetEstimateData(estimates: any[]) {
        estimates.forEach(item => {
            item.displayName = item.originalName;
            item.isVisible = true;
            item.position = item.originalPosition;
            if (item.data) {
                if (item.data.productAssumption) {
                    item.data.productAssumption = item.data.originalProductAssumption;
                }
                if (item.data.consumerAssumption) {
                    item.data.consumerAssumption = item.data.originalConsumerAssumption;
                }
                if (item.data.tradeAssumption) {
                    item.data.tradeAssumption = item.data.originalTradeAssumption;
                }
            }
        });

        return this.deliverableConfigurationUtilService.deepCloneAndSort(estimates);
    }

    resetVarietySplitConfig() {
        this.clonedVarietySplitConfiguration.chartConfig.isVisible = true;
        this.clonedVarietySplitConfiguration.chartConfig.isExpanded = true;
        this.clonedVarietySplitConfiguration.chartConfig.unitContribution.isSelected = true;
        this.clonedVarietySplitConfiguration.chartConfig.revenueContribution.isSelected = false;
        this.clonedVarietySplitConfiguration.summaryConfig.isVisible = true;
        this.clonedVarietySplitConfiguration.summaryConfig.isExpanded = true;
        this.clonedVarietySplitConfiguration['decimals'] = '0';
        this.clonedVarietySplitConfiguration.summaryConfig.rowsConfig = this.resetEstimateData(this.clonedVarietySplitConfiguration.summaryConfig.rowsConfig);
        this.clonedVarietySplitConfiguration.summaryConfig.columnsConfig = this.resetEstimateData(this.clonedVarietySplitConfiguration.summaryConfig.columnsConfig);
        this.clonedVarietySplitConfiguration.estimates = this.resetEstimateData(this.clonedVarietySplitConfiguration.estimates);
    }

    isVarietySplitConfigUpdated() {
        return this.varietySplitConfiguration.chartConfig.isVisible !== true ||
            this.varietySplitConfiguration.chartConfig.isExpanded !== true ||
            this.varietySplitConfiguration.chartConfig.unitContribution.isSelected !== true ||
            this.varietySplitConfiguration.chartConfig.revenueContribution.isSelected === true ||
            this.varietySplitConfiguration.summaryConfig.isVisible !== true ||
            this.varietySplitConfiguration.summaryConfig.isExpanded !== true ||
            this.isTableDataUpdated(this.varietySplitConfiguration.summaryConfig.rowsConfig) ||
            this.isTableDataUpdated(this.varietySplitConfiguration.summaryConfig.columnsConfig) ||
            this.isEstimateDataUpdated(this.varietySplitConfiguration.estimates) ||
            this.varietySplitConfiguration.decimals !== '0';
    }

    isDistributionValueChanged(item): boolean {
        return item?.data?.some(element => element.id === 'endOfYear' && !element.checked);
    }

    isTableDataUpdated(tableData: DeliverablesListItem[]): boolean {
        if (tableData.some(item =>
            item.displayName != item.originalName ||
            item.isVisible != true ||
            item.position != item.originalPosition ||
            this.isDistributionValueChanged(item))) {
            return true;
        } else {
            let returnFlag = false;
            tableData.forEach(item => {
                if (item.children && item.children.length > 0) {
                    returnFlag = returnFlag || this.isTableDataUpdated(item.children);
                }
            });
            return returnFlag;
        }
    }

    isEstimateDataUpdated(estimates: any[]): boolean {
        if (estimates.some(item =>
            item.displayName != item.originalName ||
            item.isVisible != true ||
            item.position != item.originalPosition ||
            (item.data && item.data.productAssumption && item.data.productAssumption != item.data.originalProductAssumption) ||
            (item.data && item.data.consumerAssumption && item.data.consumerAssumption != item.data.originalConsumerAssumption) ||
            (item.data && item.data.tradeAssumption && item.data.tradeAssumption != item.data.originalTradeAssumption))) {
            return true;
        }
        return false;
    }

    isFormatDataUpdated(formatData: any) {
        if (formatData) {
            return formatData.formatSectionExpanded !== true ||
                formatData.unit !== 'millions' ||
                formatData.noOfDecimals !== 'one';
        }
        return false;
    }

    updateVolumeConfig(config: any) {
        this.clonedVolumeEstimateConfiguration.formatData = config;
    }

    onSurveyQuestionConfigurationSave(deliverableConfiguration: DeliverableConfiguration): void {
        this.surveyQuestionsConfiguration = deliverableConfiguration;
        this.toasterService.openSnackBar(this.translate.instant('report.settings.deliverables.save.success'), 'success');
    }

    closeFlyoutMenu(): void {
        if (this.isDeliverablesDirty() &&
            (this.selectedMenu.name === 'correlations' || this.selectedMenu.name === 'factors' || this.selectedMenu.name === 'financial.potential')) {
            this.returnBack(true);
        } else {
            this.flyoutMenuClose.emit();
        }
    }

    setDeliverableInReportWithFeatureFlag(reportDeliverables) {
        if (this.isDeliverableConfigurationFlagEnabled()) {
            this.deliverablesInReport = reportDeliverables.filter(d => d.isComplete || d.type === 'correlations').map(u => Object.assign({}, u, {showDeliverable: (u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' || u.type === 'correlations') ? u.showDeliverable : true}));
        } else {
            this.deliverablesInReport = reportDeliverables.filter(d => d.isComplete).map(u => Object.assign({}, u, {showDeliverable: u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' ? u.showDeliverable : true}));
        }
    }
    isDeliverableConfigurationFlagEnabled() {
        return this.userInfo.featureFlags.includes('REPORTING_SETTINGS_DELIVERABLE_CONFIGURATION_SPECIFICS');
    }

    updateFactorsConfig(changedConfig: any) {
        this.clonedFactorsConfiguration[changedConfig.id] = changedConfig.isSelected;
        this.isDisableButtons();
    }

    updateFinancialPotentialConfig(changedConfig: DeliverableConfiguration) {
        this.clonedFinancialPotentialConfiguration = this.deliverableConfigurationUtilService.deepClone(changedConfig);
        this.isDisableButtons();
    }

    isFinancialPotentialUpdated() {
        for (const key in this.clonedFinancialPotentialConfiguration.config) {
            if (key === 'conceptPotentialScore' && this.clonedFinancialPotentialConfiguration.config[key] === true) {
                return true;
            } else if (key !== 'conceptPotentialScore' && this.clonedFinancialPotentialConfiguration.config[key] === false) {
                return true;
            }
        }
    }
    setInitialFactorsConfig(config: any) {
        this.clonedFactorsConfiguration = this.deliverableConfigurationUtilService.deepClone(config);
        this.factorsConfiguration = this.deliverableConfigurationUtilService.deepClone(config);
    }

    private isFactorsConfigUpdated() {
        if((this.clonedFactorsConfiguration.probabilityOfSuccess !== undefined) && this.clonedFactorsConfiguration.probabilityOfSuccess == true) {
            return true;
        }

        if((this.clonedFactorsConfiguration.clearConciseMessage !== undefined) &&
            (this.clonedFactorsConfiguration.clearConciseMessage !== this.clonedFactorsConfiguration?.messagingCommunicationsFactors)) {
            return true;
        }

        if((this.clonedFactorsConfiguration.messageConnection !== undefined) &&
            (this.clonedFactorsConfiguration.messageConnection !== this.clonedFactorsConfiguration?.messagingCommunicationsFactors)) {
            return true;
        }
        if(this.clonedFactorsConfiguration.desire !== undefined &&
            this.clonedFactorsConfiguration.desire === false) {
            return true;
        }

        if(this.clonedFactorsConfiguration.needDesire !== undefined &&
            this.clonedFactorsConfiguration.needDesire === false) {
            return true;
        }

        return !(this.clonedFactorsConfiguration?.distinctProposition &&
                    this.clonedFactorsConfiguration?.acceptableCosts &&
                    this.clonedFactorsConfiguration?.attentionCatching &&
                    this.clonedFactorsConfiguration?.advantage &&
                    this.clonedFactorsConfiguration?.credibility)
    }
    /**
     * Method to create FP config file if one isn't present in the project.
     */
    private createFinancialPotentialConfig() {
        const financialPotentialConfig: DeliverableConfiguration = new DeliverableConfiguration();
        financialPotentialConfig.reportId = this.report.id;
        financialPotentialConfig.name = 'fp';
        if (this.report.productType === 'PA') {
            financialPotentialConfig.config = {
                'purchaseIntent': false,
                'trialUnits': false,
                'conceptPotentialScore': false,
                'financialPotential': false,
                'frequency': false,
            };
        } else {
            financialPotentialConfig.config = {
                'purchaseIntent': true,
                'trialUnits': true,
                'conceptPotentialScore': false,
                'financialPotential': true,
                'frequency': true,
            };
        }
        this.financialPotentialConfiguration = financialPotentialConfig;
        this.deliverableConfigurationService.updateReportDeliverableConfiguration(this.financialPotentialConfiguration, this.report.id).subscribe(view => {
            this.reportService.financialPotentialConfigChanged$.next(true);
            this.spinnerService.hideSpinner();
            this.deliverableConfigurationsInReport.push(view);
        });
    }
}
