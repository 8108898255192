import {FinancialPotentialFilter} from '../models/filter.model';
import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {FinancialPotentialService} from "@app/deliverables/financial-potential/services/financial-potential.service";

/**
 * `<ns-fp-legend>` creates the financial potential chart legend based
 * on the filter selections.
 *
 * @example
 * <ns-fp-legend></ns-fp-legend>
 *
 * @export
 * @class LegendComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-fp-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit, OnDestroy {

    @Input() public categoriesData: Array<String>;
    @Input() public isConceptView: boolean;
    @Input() public areRankScoresNull: boolean;

    /**
     * FinancialPotentialFilter filter object.
     *
     * @type {FinancialPotentialFilter}
     * @memberof LegendComponent
     */
    public filter: FinancialPotentialFilter;

    /**
     * List of subscriptions for cleanup.
     *
     * @private
     * @type {Array<Subscription>}
     * @memberof LegendComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * Creates an instance of LegendComponent.
     *
     * @constructor
     * @param {financialPotentialService} financialPotentialService
     * @memberof LegendComponent
     */
    constructor(private financialPotentialService: FinancialPotentialService) {
        this.subscriptions = [];
    }

    /**
     * Initializes the component with filter data.
     *
     * @memberof LegendComponent
     */
    ngOnInit(): void {
        const filter$ = this.financialPotentialService.getFinancialPotentialFilter();
        const subscription = filter$.subscribe(filter => {
            this.filter = filter;
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Cleans up the component on removing from the UI.
     *
     * @memberof LegendComponent
     */
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
