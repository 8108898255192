import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';

/**
 * This service has operations for setting and getting header observable.
 *
 * @example
 * constructor(private headerService: HeaderService) { }
 *
 * @export
 * @class HeaderService
 */

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  /**
   * Subject to show/hide brand bar
   */
  readonly showBrandBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Subject to show/hide application bar
   */
  readonly showApplicationBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Show headers
   */
  showApplicationHeaders(): void {
    this.showBrandBar = true;
    this.showApplicationBar = true;
  }

  /**
   * Toggles visibility of brand bar
   *
   * @param show
   */
  set showBrandBar(show: boolean) {
    this.showBrandBar$.next(show);
  }

  /**
   * Toggles visibility of application bar
   *
   * @param show
   */
  set showApplicationBar(show: boolean) {
    this.showApplicationBar$.next(show);
  }
}
