import { RouterUrlState } from '@platform/store/state/router.state';
import * as fromRouter from '@ngrx/router-store';
import { Params, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

/**
 * CustomRouterSerializer for storing router state on the store.
 *
 * @export
 * @class CustomRouterSerializer
 * @implements {fromRouter.RouterStateSerializer<RouterUrlState>}
 */
export class CustomRouterSerializer implements fromRouter.RouterStateSerializer<RouterUrlState> {

  /**
   * Serialize the default router object into a simpler object.
   *
   * @param {RouterStateSnapshot} routerState
   * @returns {RouterUrlState}
   * @memberof CustomRouterSerializer
   */
  serialize(routerState: RouterStateSnapshot): RouterUrlState {
    const {url} = routerState;
    const { queryParams } = routerState.root;
    const params: Params = {};

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      const stateParams: Params = state.firstChild.params;
      for (const key in stateParams) {
        if (stateParams[key]) {
          params[key] = stateParams[key];
        }
      }
      state = state.firstChild;
    }
    // Create a smaller memory footprint object for the router.
    return { url, queryParams, params };
  }
}
