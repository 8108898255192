import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { UserView } from '@platform/models/user-view.model';

/**
 * Action for adding an userView.
 *
 * @export
 */
export const addUserView = createAction(
  '[UserView] Add User View',
  props<{ userView: UserView }>()
);

/**
 * Action for adding many userView.
 *
 * @export
 */
export const addUserViews = createAction(
  '[UserView] Add User Views',
  props<{ userViews: UserView[] }>()
);

/**
 * Action for deleting an userView.
 *
 * @export
 */
export const deleteUserView = createAction(
  '[UserView] Delete User View',
  props<{ userView: UserView }>()
);

/**
 * Action for updating an userView.
 *
 * @export
 */
export const updateUserView = createAction(
  '[UserView] Update User View',
  props<{ userView: UserView }>()
);

/**
 * Action for updating many userViews.
 *
 * @export
 */
export const updateManyUserViews = createAction(
  '[UserView] Update Many User Views',
  props<{ userViews: Update<UserView>[] }>()
);
