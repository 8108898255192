/**
 * Filter name keys and values.
 *
 * @export
 * @property
*/
export const filterNames = {
  topBox: 'top-box',
  topTwoBox: 'top-two-box',
  allFiveBoxes: 'all-five-boxes',
  statTesting: 'stat-testing',
  dataLabels: 'data-labels',
};
