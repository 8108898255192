import {Subgroup} from '@platform/models/subgroup.model';
import {FilterService} from '@platform/services/filter.service';
import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {switchMap} from 'rxjs/operators';
import {ReportService} from '@platform/services/report.service';
import {SubgroupService} from '@platform/services/subgroup.service';
import {ConceptService} from '@platform/services/concept.service';
import {Concept} from '@platform/models/concept.model';
import {ImagePath, PriceElasticityDeliverableView} from '../models/price-elasticity.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {FilterItem, PriceElasticityFilter} from '../models/filter.model';
import {defaultPriceElasticityFilter} from '../models/default-price-elasticity-filter';
import {AppConfigService} from '@app/core/config/app-config.service';
import {Report} from '@platform/models/report.model';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {PriceMentionsDeliverableView} from '@app/deliverables/price-mentions/models/price-mentions.model';

/**
 * `PriceElasticityService` has all data operations
 * for fetching the price elasticity filter data
 *
 * @export
 * @class PriceElasticityService
 */

@Injectable({
    providedIn: 'root'
})
export class PriceElasticityService {

    /**
     * Creates an instance of PriceElasticityService.
     *
     * @constructor
     * @param {DeliverableViewService} deliverableViewService
     * @param {ReportService} reportService
     * @param {ConceptService} conceptService
     * @param {SubgroupService} subgroupService
     * @param {FilterService} filterService
     * @param productDeliverableViewService
     * @param {AppConfigService} appConfigService
     * @memberof PriceElasticityService
     */
    constructor(
        private deliverableViewService: DeliverableViewService,
        private reportService: ReportService,
        private conceptService: ConceptService,
        private subgroupService: SubgroupService,
        private filterService: FilterService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private appConfigService: AppConfigService) {
    }

    /**
     * Returns an observable of `PriceElasticityDeliverableView` data.
     *
     * @returns {Observable<PriceElasticityDeliverableView>}
     * @memberof PriceElasticityService
     */
    public getPriceElasticity(): Observable<PriceElasticityDeliverableView> {
        const deliverableType = DeliverableType.PRICE_ELASTICITY.type;
        const filter$ = this.getPriceElasticityFilter();
        const priceElasticity$: Observable<PriceElasticityDeliverableView> = filter$.pipe(
            switchMap(filter => {
                return this.deliverableViewService.filter<PriceElasticityDeliverableView, PriceElasticityFilter>(
                    filter.deliverableViewType,
                    deliverableType,
                    this.filter.bind(this)
                );
            })
        );
        return priceElasticity$;
    }

    /**
     * Returns an observable of `PriceElasticityDeliverableView` data.
     *
     * @returns {Observable<PriceElasticityDeliverableView>}
     */
    public getDefaultPriceElasticity(deliverableViews: Array<DeliverableView>): Observable<PriceElasticityDeliverableView> {
        const report$ = this.reportService.get();
        return report$.pipe(
            switchMap(report => {
                const viewId = deliverableViews[0].id;
                return this.deliverableViewService.fetch<PriceElasticityDeliverableView>(
                    viewId,
                    report.id
                );
            })
        );
    }

    /**
     * Filter for filtering the price elasticity data and returns
     * filtered `PriceElasticityDeliverableView` data.
     *
     * @private
     * @param {PriceElasticityFilter} filter
     * @param {PriceElasticityDeliverableView} data
     * @returns {PriceElasticityDeliverableView}
     * @memberof PriceElasticityService
     */
    private filter(filter: PriceElasticityFilter, data: PriceElasticityDeliverableView): PriceElasticityDeliverableView {
        const selectedConcepts = filter.concepts.filter(c => c.isSelected).map(c => c.id);
        const selectedSubgroups = filter.subgroups.filter(s => s.isSelected).map(s => s.id);
        const filteredData = data.optimalPriceFiles.filter(file => selectedConcepts.includes(file.conceptId) && selectedSubgroups.includes(file.subgroupId));
        const filteredView = Object.assign({}, data, {optimalPriceFiles: filteredData});
        return filteredView;
    }

    /**
     * Returns observable of Price Elasticity filter data.
     *
     * @returns {Observable<PriceElasticityFilter>}
     * @memberof PriceElasticityService
     */
    public getPriceElasticityFilter(): Observable<PriceElasticityFilter> {
        const deliverableType = DeliverableType.PRICE_ELASTICITY.type;
        const filter$ = this.filterService.get<PriceElasticityFilter>(deliverableType);
        return filter$;
    }

    /**
     * Returns an observable that resolves to default filter for the Price Elasticity deliverable.
     */
    public getDefaultFilterData(deliverableView ?: Array<DeliverableView>, concept ?: Concept): Observable<PriceElasticityFilter> {
        const defaultFilter: PriceElasticityFilter = Object.assign({}, defaultPriceElasticityFilter);
        const report$ = this.reportService.get();
        const concepts$ = this.conceptService.getReportDeliverableConcepts(DeliverableType.PRICE_ELASTICITY.type);
        const subgroups$ = this.subgroupService.getReportSubgroups();
        const deliverableView$ = this.getDefaultPriceElasticity(deliverableView);
        return zip(report$, concepts$, subgroups$, deliverableView$).pipe(switchMap(result => {
            defaultFilter.countries = [result[0].country];
            const concepts = concept ? [concept] : result[1];
            defaultFilter.concepts = this.getConceptFilters(concepts as Array<Concept>, result[2] as Array<Subgroup>, result[3]);
            defaultFilter.subgroups = this.getSubgroupFilters(result[2] as Array<Subgroup>, defaultFilter.concepts, result[3]);
            return of(defaultFilter);

        }));
    }

    /**
     * Returns concept filter items.
     *
     * @private
     * @param {Array<Concept>} concepts
     * @param subgroups
     * @param deliverableView
     * @returns {Array<FilterItem>}
     * @memberof PriceElasticityService
     */
    private getConceptFilters(concepts: Array<Concept>, subgroups: Array<Subgroup>, deliverableView: PriceElasticityDeliverableView): Array<FilterItem> {
        const conceptFilterItems = [];
        const conceptSubgroupsMap = new Map();
        deliverableView.metaInfo.concepts.forEach(conceptId => {
            conceptSubgroupsMap.set(conceptId, []);
        });
        deliverableView.optimalPriceFiles.forEach(file => {
            const conceptId = file.conceptId;
            const subgroupId = file.subgroupId;
            if (conceptSubgroupsMap.has(conceptId)) {
                if (!conceptSubgroupsMap.get(conceptId).includes(subgroupId)) {
                    conceptSubgroupsMap.get(conceptId).push(subgroupId);
                }
            }
        });

        concepts.forEach(concept => {
            if (conceptSubgroupsMap.has(concept.exerciseConceptId)) {
                const relevantSubgroupIds = conceptSubgroupsMap.get(concept.exerciseConceptId);
                const filteredSubgroup = subgroups
                    .filter(item => relevantSubgroupIds.includes(item.segmentId))
                    .map(subgroup => ({
                        name: subgroup.name,
                        id: subgroup.segmentId,
                        isSelected: false,
                        position: subgroup.position
                    }));
                if (filteredSubgroup.length > 0) {
                    filteredSubgroup[0].isSelected = true;
                    conceptFilterItems.push({
                        name: concept.name,
                        id: concept.exerciseConceptId,
                        isSelected: false,
                        position: concept.position,
                        subgroups: filteredSubgroup
                    });
                }
            }
        });
        conceptFilterItems[0].isSelected = true;
        return conceptFilterItems;
    }

    /**
     * Returns subgroup filter items.
     *
     * @private
     * @param {Array<Subgroup>} subgroups
     * @param concepts
     * @param deliverableView
     * @returns {Array<FilterItem>}
     * @memberof PriceElasticityService
     */
    private getSubgroupFilters(subgroups: Array<Subgroup>, concepts: any, deliverableView: PriceElasticityDeliverableView): Array<FilterItem> {
        const subgroupFilterItems: Array<FilterItem> = [];
        const selectedSubgroup = concepts.find(concept => concept.isSelected).subgroups.find(subgroup => subgroup.isSelected);
        const filteredSubgroups = subgroups.filter(s => deliverableView.metaInfo.subgroups.includes(s.segmentId));
        for (const item of filteredSubgroups) {
            subgroupFilterItems.push({
                name: item.name,
                id: item.segmentId,
                isSelected: false,
                position: item.position,
            });
        }
        subgroupFilterItems.forEach(item => {
            item.isSelected = item.id === selectedSubgroup.id;
        });
        return subgroupFilterItems;
    }


    /**
     * Returns image file path.
     *
     * @returns {ImagePath}
     */
    public getImagePath(priceElasticityDeliverableView: PriceElasticityDeliverableView): ImagePath {
        if (priceElasticityDeliverableView.optimalPriceFiles.length === 0) {
            return;
        }
        const imagePath = {
            fileName: priceElasticityDeliverableView.optimalPriceFiles[0].name,
            name: `${this.appConfigService.config.reporting.url}/reports/${priceElasticityDeliverableView.reportId}/inputs/${priceElasticityDeliverableView.optimalPriceFiles[0].name}?imageFiles=true`
        };
        return imagePath;
    }
}
