
/**
 * Iterates over style declaration.
 *
 * @param styleDeclaration the CSS style declaration.
 * @param callback the iteration callback.
 */
export const iterateStyleDeclaration = (styleDeclaration: CSSStyleDeclaration,
  callback: (property: string, value: string) => void) => {
  let i = 0;
  let property: string;
  let value: string;
  while (i < styleDeclaration.length) {
    i++;
    property = styleDeclaration[i];
    value = styleDeclaration.getPropertyValue(property);
    callback(property, value);
  }
};
