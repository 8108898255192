<ul class="list-unstyled">
    <ng-scrollbar [autoHeightDisabled]="false">
        <div *ngIf="verbatims && verbatims.length > 0; else noVerbatim" [ngStyle]="{'max-height.px': maxHeight}">
            <ng-container *ngFor="let verbatim of verbatims">
                <li  *ngIf="verbatim.value" class="verbatim-content" (mouseenter)="hoverEnabled && onMouseEnter(verbatim)" (mouseleave)="hoverEnabled && onMouseLeave()">
                    <span class="verbatim-text" [innerHTML]="verbatim.value | highlight : searchText"></span>
                </li>
            </ng-container>
        </div>
    </ng-scrollbar>
</ul>

<ng-template #noVerbatim>
    <span class="verbatim-text">There are no verbatim entries available.</span>
</ng-template>
