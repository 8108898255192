import { DeliverableType } from '@app/deliverables/deliverable-type.enum';

/**
 * Default filter values for attributes.
 *
 * @export
 * @property
*/
export const defaultActivationProfileFilter = {
  deliverableType: DeliverableType.ACTIVATION_PROFILE.type,
  deliverableViewType: "concept"
};
