/**
 * Deliverable view types for attributes.
 *
 * @export
 * @enum {string}
 */
export enum DeliverableViewType {
  /**
   * Concept deliverable view type.
   */
  CONCEPT = 'concept',

  SUBGROUP = 'subgroup'
}
