import { AppConfigService } from '@app/core/config/app-config.service';
import { environment } from '@src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  /**
   * Constructor.
   *
   * @param appConfigService
   */
  constructor(
    private appConfigService: AppConfigService
  ) { }

  /**
   * Returns project navigation URL for the project id.
   *
   * @param {number} projectId
   * @returns {string} the project navigation url
   */
  getProjectNavigationAPI(projectId: number): string {
    let serviceURL = `${environment.projectNavigationServiceURL}`;
    serviceURL = serviceURL.replace('$projectId', projectId.toString());
    return serviceURL;
  }

  /**
   * Returns user navigation URL.
   *
   * @returns {string} the user navigation url
   */
  getUserNavigationURL(): string {
    const studioBaseUrl = this.appConfigService.config.studio.url;
    const serviceURL = `${studioBaseUrl}${environment.userNavigationURL}`;
    return serviceURL;
  }

  /**
   * Returns comments API URL.
   *
   * @returns {string} the comments API URL
   */
  getCommentsURL(): string {
    const studioBaseUrl = this.appConfigService.config.studio.url;
    const serviceURL = `${studioBaseUrl}${environment.commentsURL}`;
    return serviceURL;
  }

  /**
   * Returns collaborators API URL.
   *
   * @returns {string} the collaborators studio API URL
   */
  getCollaboratorsURL(): string {
    const studioBaseUrl = this.appConfigService.config.studio.url;
    const serviceURL = `${studioBaseUrl}${environment.collaboratorsURL}`;
    return serviceURL;
  }

  /**
   * Returns userinfo API URL.
   *
   * @returns {string} the userinfo studio API URL
   */
  getUserInfoURL(): string {
    const studioBaseUrl = this.appConfigService.config.studio.url;
    const serviceURL = `${studioBaseUrl}${environment.userInfoURL}`;
    return serviceURL;
  }

  /**
   * Returns logout API URL.
   *
   * @returns {string} the logout authproxy URL
   */
  getLogoutURL(): string {
    const serviceURL = this.appConfigService.config.logout.url;
    return serviceURL;
  }

}
